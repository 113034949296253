import Vue from "vue";
import { mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import StatusInventario from "@/models/enum/statusInventario";
import TipoInventario from "@/models/enum/tipoInventario";
import TiposMovimentacaoEstoque from "@/models/enum/tiposMovimentacaoEstoque";
import InventarioModel from "@/models/inventarioModel";
import MovimentacaoEstoqueItemModel from "@/models/movimentacaoEstoqueItemModel";
import MovimentacaoEstoqueProdutoInventarioModel from "@/models/movimentacaoEstoqueProdutoInventarioModel";
import PaginationModel from "@/models/paginationModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import InventarioService from "@/services/inventarioService";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { AppState } from "@/store/store";

import "./edit.scss";

@Component({
    components: {
        actionBarComponent,
        checkboxComponent,
        comboComponent,
        fieldsetComponent,
        gridComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
    },
})
export default class InventarioEditComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;

    private colapsed = true;
    private desabilita = false;
    private totalProdutos = null;
    private totalLotes = null;
    private iniciado = false;
    private consulta = false;
    private finalizado = false;
    private movimentacaoEntrada;
    private movimentacaoSaida;
    private unidadeMedida;
    private gridcomponent: Grid = null;
    private ignorarLotesZerados = true;

    private service = new InventarioService();
    modelInventario = new InventarioModel();
    modelProduto = new ProdutoModel();

    tipoInventarioOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TipoInventario);
    produtoGrupoOptions: Array<Object> = [];
    produtosOptions: Array<Object> = [];
    produtosFiltrados: Array<Object> = [];
    produtosMovimentacaoOptions: Array<Object> = [];
    produtosMovimentacaoFiltrados: Array<Object> = [];
    modelId = 0;
    pageSize = 20;
    total = 0;
    statusInventario: string;
    totalMovimentacoes = 0;
    codigos: Array<string> = [];
    gridData: Array<ProdutoModel> = [];
    extraSubActions: Array<object> = [];
    gridExtraSubActionsConfirmar: Array<GridAction> = [];
    //Produtos de inventario ativo
    produtosClasses: Array<string> = [];
    pags: boolean = null;
    tipoDocGerar: number = null;

    get produtoGridDataColumns() {
        return [
            new GridColumn("codigo", "C\u00f3d.", GridColumnType.String),
            new GridColumn("descricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("quantidade", "Qtd. Disponivel", GridColumnType.Decimal),
            new GridColumn("quantidadeVencida", "Qtd. Vencido", GridColumnType.Decimal),
            new GridColumn("unidade", "Unid.", GridColumnType.String),
        ];
    }

    get loteGridDataColumns() {
        return [
            new GridColumn("descricao", "Lote", GridColumnType.String),
            new GridColumn("sequencialCompra", "Seq.", GridColumnType.String),
            new GridColumn("dataFabricacao", "Data de Fabricação", GridColumnType.Date),
            new GridColumn("dataValidade", "Data de Validade", GridColumnType.Date),
            new GridColumn("quantidadeAtual", "Quantidade", GridColumnType.Decimal, true),
        ];
    }

    subDataName = "listaProdutoLote";

    conditionShowSubData(it) {
        if (it["listaProdutoLote"] != null) return it["listaProdutoLote"]["length"] > 0;
        else return false;
    }
    //Produtos com movimentacao inventario finalizado
    produtosMovimentacaoClasses: Array<string> = [];

    get produtoFinalizadoGridColumns() {
        return [
            new GridColumn("codigo", "C\u00f3d.", GridColumnType.String),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("quantidadeAtual", "Qtd. Atual", GridColumnType.Decimal),
            new GridColumn("quantidadeMovimentada", "Qtd. Movimentada", GridColumnType.Decimal),
            new GridColumn("unidadeMedidaDescricao", "Unid.", GridColumnType.String),
        ];
    }

    subMovimentacaoDataName = "listaMovimentacaoProdutoLote";

    get loteFinalizadoGridColumns() {
        return [
            new GridColumn("loteDescricao", "Lote", GridColumnType.String),
            new GridColumn("sequencialCompra", "Seq.", GridColumnType.String),
            new GridColumn("dataCriacao", "Data de Criação", GridColumnType.Date),
            new GridColumn("dataValidade", "Validade do Lote", GridColumnType.Date),
            new GridColumn("quantidade", "Quantidade", GridColumnType.Decimal),
            new GridColumn("unidadeMedidaDescricao", "Unidade", GridColumnType.String),
            new GridColumn("tiposMovimentacao", "Tipo Movimentação", GridColumnType.String),
        ];
    }

    conditionMovimentacaoShowSubData(it) {
        if (it["listaMovimentacaoProdutoLote"] != null) return it["listaMovimentacaoProdutoLote"]["length"] > 0;
        else return false;
    }

    /////////////////////////////////////////////////
    constructor() {
        super();
        this.modelProduto = new ProdutoModel();
    }

    private async iniciar() {
        if (this.modelInventario.tipoInventario == null) {
            return this.$showWarning(this.$t("__.ts.campoVazio"), this.$t("__.ts.campoInventDeve"));
        } else if (
            this.modelInventario.tipoInventario == TipoInventario.Parcial &&
            this.modelInventario.grupoInventarioId == null
        ) {
            return this.$showWarning(this.$t("__.ts.campoVazio"), this.$t("__.ts.campoGrupoProdsDeve"));
        } else {
            try {
                const data = await this.service
                    .insertInventario(this.modelInventario)
                    .withLoading()
                    .resolveWithJSON<any>();

                if (data != null) {
                    this.modelId = data.id;
                    this.movimentacaoEntrada = data.movimentacaoEstoqueEntradaId;
                    this.movimentacaoSaida = data.movimentacaoEstoqueSaidaId;
                    this.listaProdutos();
                } else {
                    await this.$showError(this.$t("__.ts.jaExisteInvent"), this.$t("__.ts.naoPossivIniciarDois"));
                    return this.$router.back();
                }
            } catch {}
        }
    }

    private onFiltrar(textoBusca: string) {
        this.produtosFiltrados = this.produtosOptions.filter(produto => {
            return produto["descricao"].toLowerCase().includes(textoBusca.toLocaleLowerCase());
        });
    }

    private onFiltrarConcluidos(textoBusca: string) {
        this.produtosMovimentacaoFiltrados = this.produtosMovimentacaoOptions.filter(produto => {
            return produto["produtoDescricao"].toLowerCase().includes(textoBusca.toLocaleLowerCase());
        });
    }

    private async listaProdutos() {
        this.iniciado = true;
        this.desabilita = true;

        try {
            const data = await this.service
                .listProdutoLotes(
                    this.ignorarLotesZerados,
                    this.modelInventario.tipoInventario == 1 ? this.modelInventario.grupoInventarioId : 0,
                )
                .withLoading()
                .resolveWithJSON<ProdutoModel[]>();

            this.extraSubActions.splice(0);
            this.gridExtraSubActionsConfirmar.splice(0);

            data.forEach(p => {
                if (p.listaProdutoLote.length > 0) {
                    p.listaProdutoLote.forEach(l => {
                        this.gridExtraSubActionsConfirmar.push(
                            new GridAction(
                                "aprovar-orcamento",
                                this.$t("__.ts.aprovOrcamento").toString(),
                                "fa fa-check",
                                GridColors.GREEN,
                            ),
                        );
                    });
                } else {
                    this.gridExtraSubActionsConfirmar.push(
                        new GridAction(
                            "aprovar-orcamento",
                            this.$t("__.ts.aprovOrcamento").toString(),
                            "fa fa-check",
                            GridColors.GREEN,
                        ),
                    );
                }
            });

            this.produtosOptions = data.map(n => {
                this.unidadeMedida = n.unidadeMedidaEstoqueId;

                return {
                    codigo: n.id,
                    descricao: n.descricao,
                    unidade: this.preLoadList.unidadesMedida.find(p => p.id == n.unidadeMedidaEstoqueId)?.descricao,
                    quantidade: n.listaProdutoLote
                        .filter(p =>
                            p.dataValidade != undefined ? new Date(p.dataValidade.toString()) >= new Date() : true,
                        )
                        .reduce((acc, x) => {
                            return acc + x.quantidadeAtual;
                        }, 0)
                        .toFixed(4),
                    quantidadeVencida: n.listaProdutoLote
                        .filter(p =>
                            p.dataValidade != undefined ? new Date(p.dataValidade.toString()) <= new Date() : true,
                        )
                        .reduce((acc, x) => {
                            return acc + x.quantidadeAtual;
                        }, 0)
                        .toFixed(4),
                    listaProdutoLote: n.listaProdutoLote,
                };
            });

            this.extraSubActions.push(this.gridExtraSubActionsConfirmar);

            this.produtosFiltrados = this.produtosOptions;
            this.totalProdutos = data.length;
            this.colapsed = false;
        } catch {}
    }

    private async listaMovimentacoes(id: number) {
        try {
            const data = await this.service
                .getFinalizado(id)
                .withLoading()
                .resolveWithJSON<PaginationModel<MovimentacaoEstoqueProdutoInventarioModel>>();

            this.produtosMovimentacaoOptions = data.list.map(n => ({
                codigo: n.id,
                produtoDescricao: n.produtoDescricao,
                unidadeMedidaDescricao: n.unidadeMedidaDescricao,
                quantidadeAtual: n.quantidadeAtual.toFixed(4),
                quantidadeMovimentada: (
                    n.movimentacaoEstoqueItens
                        .filter(p => p.tiposMovimentacao == TiposMovimentacaoEstoque.Entrada)
                        .reduce((acc, x) => acc + x.quantidade, 0) -
                    n.movimentacaoEstoqueItens
                        .filter(p => p.tiposMovimentacao == TiposMovimentacaoEstoque.Saida)
                        .reduce((acc, x) => acc + x.quantidade, 0)
                ).toFixed(4),
                listaMovimentacaoProdutoLote: n.movimentacaoEstoqueItens.map(m => ({
                    loteDescricao: m.loteDescricao,
                    sequencialCompra: m.produtoLote.sequencialCompra,
                    dataCriacao: m.dataCriacao,
                    quantidade: m.quantidade.toFixed(4),
                    unidadeMedidaDescricao: this.preLoadList.unidadesMedida.find(p => p.id == m.unidadeMedidaEstoqueId)
                        ?.descricao,
                    tiposMovimentacao: TiposMovimentacaoEstoque[m.tiposMovimentacao],
                    dataValidade: m.produtoLote.dataValidade,
                })),
            }));

            if (this.pags) {
                this.gridcomponent = this.$refs.gridcomponent as Grid;

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
            }

            this.totalMovimentacoes = data.list.length;
            this.produtosMovimentacaoFiltrados = this.produtosMovimentacaoOptions;

            this.colapsed = false;
        } catch {}
    }

    private onExtraSubAction(name: string, model: ProdutoLoteModel) {
        this.onAjustar(model);
    }

    private async cancel() {
        const msg = this.$t("__.ts.todasAltRevertidas").toString();
        const question = this.$t("__.ts.desejaReverter").toString();

        const response = await this.$showQuestion(this.$t("__.ts.atencao"), msg + question, {
            cancelButtonText: this.$t("__.Crud.fechar") as string,
            confirmButtonText: this.$t("__.ts.reverter") as string,
        });

        if (response) {
            this.modelInventario.movimentacaoEntrada = this.movimentacaoEntrada;
            this.modelInventario.movimentacaoSaida = this.movimentacaoSaida;
            this.modelInventario.id = this.modelId;

            const responseCancelar = await this.service
                .cancelarInventario(this.modelInventario)
                .withLoading()
                .resolveWithResponse();
            if (responseCancelar.ok) {
                await this.$showSuccess(this.$t("__.ts.inventCancelado"), this.$t("__.ts.inventCanceladoSucess"));
                this.$router.push("/inventario-lista");
            } else {
                await this.$showWarning(this.$t("__.ts.erro"), this.$t("__.ts.naoPossivCancelar"));
            }
        }
    }

    private voltarLista() {
        this.$router.push("/inventario-lista");
    }

    private async finalizar() {
        const msg = this.$t("__.ts.opcIraFinaliz").toString();
        const question = this.$t("__.ts.desejaProsseg").toString();

        const response = await this.$showQuestion(this.$t("__.ts.atencao"), msg + question, {
            cancelButtonText: this.$t("__.Crud.fechar") as string,
            confirmButtonText: this.$t("__.ts.finalizar") as string,
        });

        if (response) {
            this.modelInventario.status = StatusInventario.Concluido;
            this.modelInventario.id = this.modelId;

            const responseUpdate = await this.service
                .finalizarInventario(this.modelInventario)
                .withLoading()
                .resolveWithResponse();
            if (responseUpdate.ok) {
                await this.$showSuccess(this.$t("__.ts.inventFinalizado"), this.$t("__.ts.inventFinalizadoSucess"));
                this.$router.push("/inventario-lista");
            } else {
                await this.$showWarning(this.$t("__.ts.erro"), this.$t("__.ts.naoPossivFinalizar"));
                this.$router.push("/inventario-lista");
            }
        }
    }

    private async onAjustar(lote: ProdutoLoteModel) {
        const msg = this.$t("__.ts.alteraQtdLote").toString();
        const question = this.$t("__.ts.desejaProsseg").toString();

        const response = await this.$showQuestion(this.$t("__.ts.atencao"), msg + question);
        if (response) {
            const data = await new ProdutoLoteService().get(lote.id).withLoading().resolveWithJSON<ProdutoLoteModel>();
            this.criarMovimentaacaoEstoque(data.quantidadeAtual, lote);
        }
    }

    private async criarMovimentaacaoEstoque(quantidade: number, model: ProdutoLoteModel) {
        const movimentacaoItem = new MovimentacaoEstoqueItemModel();
        const quantidadeAjuste = model.quantidadeAtual - quantidade;

        movimentacaoItem.produtoLoteId = model.id;
        movimentacaoItem.produtoId = model.produtoId;
        movimentacaoItem.quantidade = Math.abs(quantidadeAjuste);
        movimentacaoItem.loteDescricao = model.descricao;

        movimentacaoItem.produtoDescricao = model.produtoDescricao;
        movimentacaoItem.valorUnitario = 0;
        movimentacaoItem.unidadeMedidaEstoqueId = this.unidadeMedida;

        movimentacaoItem.produtoLote = model;
        movimentacaoItem.dataCriacao = new Date();
        if (quantidadeAjuste >= 0) {
            // entrada
            movimentacaoItem.tiposMovimentacao = TiposMovimentacaoEstoque.Entrada;
            movimentacaoItem.movimentacaoEstoqueId = this.movimentacaoEntrada;
        } else {
            //saida
            movimentacaoItem.tiposMovimentacao = TiposMovimentacaoEstoque.Saida;
            movimentacaoItem.movimentacaoEstoqueId = this.movimentacaoSaida;
        }

        const response = await this.service.insertAjusteLote(movimentacaoItem);
        if (response.ok) {
            await this.$showSuccess(this.$t("__.ts.sucesso"), "Lote Alterado!");
        } else {
            await this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.tenteNovamente"));
        }
    }

    @Watch("pags")
    private onPags() {
        if (this.pags == null || this.pags == true) this.load();
    }

    private async load() {
        this.ignorarLotesZerados = false;
        this.desabilita = true;
        this.modelId = +this.$route.params.id;

        try {
            const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<InventarioModel>();

            if (data.status == StatusInventario.Ativo) {
                this.movimentacaoEntrada = data.movimentacaoEntrada;
                this.movimentacaoSaida = data.movimentacaoSaida;
                this.modelInventario.tipoInventario = data.tipoInventario;
                this.modelInventario.grupoInventarioId = data.grupoInventarioId;

                await this.listaProdutos();
            } else {
                this.consulta = true;
                this.finalizado = true;
                const status = StatusInventario[data.status];
                this.statusInventario = StatusInventario[data.status];

                if (!this.pags) {
                    this.$showWarning(
                        this.$t("__.ts.inventario") + status + "!",
                        this.$t("__.ts.inventFinalizadoSoConsul"),
                    );
                }

                await this.listaMovimentacoes(this.modelId);
            }
        } catch {}
    }

    private async loadOptions() {
        const data = await new GrupoProdutoService()
            .combo()
            .withLoading()
            .resolveWithJSON<PaginationModel<GrupoProdutoModel>>();

        this.produtoGrupoOptions = data.list.map(p => ({ text: p.descricao, value: p.id }));
    }

    private async mounted() {
        this.loadOptions();

        if (this.$route.params.id) {
            this.load();
        }
    }
}
