import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import multiSelectComponent from "@/components/child/multiSelect/multiSelect.vue";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import TipoVenda from "@/models/enum/tipoVenda";
import GradePCPRequestModel from "@/models/gradePCPRequestModel";
import GradePCPService from "@/services/gradePCPService";
import { AppState, Getters } from "@/store/store";
import { getFormaFarmaceuticaCombo } from "@/utils/common/combo/combotext";
import { getTipoVendaCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import "../crud/crud.scss";
import "./view.scss";

class GradePCPType {
    dias: string[];
    capacidades: {
        descricao: string;
        horario: string;
        capacidadesDia: {
            capacidade: number;
            totalVendas: number;
            pendente: number;
        };
    }[];
}

@Component({
    components: {
        dataTooltipComponent,
        actionBarComponent,
        multiSelectComponent,
        comboComponent,
        dateTimePickerComponent,
    },
    computed: {
        ...mapState({
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
        }),
        ...mapGetters(["GET_IS_ESTEREIS", "GET_IS_FRACIONAMENTO"] as Getters),
    },
})
export default class GradePCPComponent extends Vue {
    // State computed props
    preLoadPack: PreLoadPackModel;
    loadedList: boolean;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;

    private service = new GradePCPService();
    private model = new GradePCPType();
    private requestModel = new GradePCPRequestModel();
    formasFarmaceuticaOptions: Array<Object> = [];
    formasFarmaceuticasIds: Array<Object> = [];
    tiposVendasOptions = getTipoVendaCombo();
    tiposVendas: Array<TipoVenda> = [];
    defaultTipoVenda: Array<Object> = [];

    isFracionamento = false;
    isEstereis = false;
    isLoading = false;
    setorOptions: Array<Object> = [];

    capacidades = [];
    total: GradePCPType = null;

    @Watch("requestModel.dataInicial")
    @Watch("requestModel.dataFinal")
    @Watch("requestModel.setorId")
    @Watch("formasFarmaceuticasIds")
    @Watch("tiposVendas")
    private onChangeFilters() {
        this.load();
    }

    private async load() {
        if (!this.isLoading) {
            this.isLoading = true;

            try {
                this.requestModel.formasFarmaceuticasIds = this.formasFarmaceuticasIds.map(p => p["value"]);
                this.requestModel.tipoVendas = this.tiposVendas.map(p => p["value"]);
                this.model = await this.service
                    .getGradePCP(this.requestModel)
                    .withLoading()
                    .resolveWithJSON<GradePCPType>();

                this.capacidades = [...this.model.capacidades];
                this.total = this.capacidades.pop();
            } catch {
            } finally {
                this.isLoading = false;
            }
        }
    }

    private async mounted() {
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();
        while (!this.loadedList) await Delay(100);

        const currentFranquia = this.preLoadPack.currentFranquiaId;
        this.setorOptions = this.preLoadPack.setoresCombo();
        this.formasFarmaceuticaOptions = this.preLoadPack.formasFarmaceuticas
            .filter(p => p.ativo && (p.franquiaId == currentFranquia || p.franquiaId == null))
            .map(getFormaFarmaceuticaCombo);
        this.defaultTipoVenda.push(this.tiposVendasOptions.find(p => p["value"] === TipoVenda.Transferencia));
    }
}
