import { mapMutations } from "vuex";

import { Component } from "@/decorators";
import SetorModel from "@/models/setorModel";
import SetorService from "@/services/setorService";
import { LoadListProps, Mutations } from "@/store/store";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {},
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class SetorEditComponent extends EditComponentBase<SetorModel> {
    // State computed props
    LOAD_LIST: (props: LoadListProps) => void;

    private async beforeMount() {
        await this.setProps(new SetorService(), "setor", new SetorModel());
    }

    public async onSucessoSave() {
        this.LOAD_LIST({ loadSetores: true });
    }
}
