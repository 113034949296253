import { ActionTree } from "vuex";

import { setAtendimentoNome, sortDataUltimaMensagem } from "@/components/parent/atendimento/atendimentoUtils";
import StatusMensagem from "@/models/enum/statusMensagem";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";
import AtendimentoService from "@/services/atendimentos/atendimentoService";
import MensagemService from "@/services/atendimentos/mensagemService";
import WhatsAppService from "@/services/atendimentos/whatsAppService";

import { AppState } from "../store";

import { AtendimentoContextActions, AtendimentoContextMutations, AtendimentoContextState } from "./types";

const atendimentoService = new AtendimentoService();
const mensagemService = new MensagemService();
const whatsAppService = new WhatsAppService();

export class MessagesFile {
    file?: File;
    message?: MensagemAtendimentoWhatsAppModel;
    telefone?: string;
}

const actions: ActionTree<AtendimentoContextState, AppState> = {
    async CREATE_ATENDIMENTO_BY_NUMERO(context, numeroContato: string) {
        const atendimento = await atendimentoService.criarAtendimento(numeroContato);

        if (!context.state.atendimentos.some(x => x.id.toString() == atendimento.id)) {
            context.commit(AtendimentoContextMutations.CREATE_ATENDIMENTO_BY_NUMERO, atendimento);
            context.commit(AtendimentoContextMutations.SET_CURRENT_MESSAGES, []);
            context.commit(AtendimentoContextMutations.SET_CURRENT_ATENDIMENTO, atendimento.id);
        } else {
            this.dispatch(AtendimentoContextActions.SET_CURRENT_ATENDIMENTO, atendimento.id);
        }

        context.commit(AtendimentoContextMutations.ORDER_ATENDIMENTOS);
    },
    async RECEIVE_MESSAGE(context, message: MensagemAtendimentoWhatsAppModel) {
        if (!context.state.atendimentos.some(x => x.id.toString() == message.atendimentoId)) {
            context.commit(AtendimentoContextMutations.CREATE_ATENDIMENTO_BY_ID, message.atendimentoId);
            const atendimento = await atendimentoService.getAtendimentoById(message.atendimentoId);
            if (atendimento) {
                context.commit(AtendimentoContextMutations.UPDATE_ATENDIMENTO, setAtendimentoNome(atendimento));
            }
        }

        context.commit(AtendimentoContextMutations.UPDATE_ATENDIMENTO_LAST_MESSAGE, message);
        context.commit(AtendimentoContextMutations.ORDER_ATENDIMENTOS);

        context.commit(AtendimentoContextMutations.RECEIVE_MESSAGE, message);
        context.commit(AtendimentoContextMutations.NOTIFICATION_ADD, message.atendimentoId);
        context.commit(AtendimentoContextMutations.NOTIFICATION_SET_ALL);
    },
    async SEND_MESSAGE(context, object: MessagesFile) {
        let messageId: string;
        if (object.file) {
            if (object.file.isImage()) {
                messageId = await whatsAppService.enviarImagem(
                    object.telefone,
                    object.message.texto,
                    object.file,
                    object.message.mensagemRespondidaId,
                );
            } else if (object.file.isVideo()) {
                messageId = await whatsAppService.enviarVideo(
                    object.telefone,
                    object.message.texto,
                    object.file,
                    object.message.mensagemRespondidaId,
                );
            } else {
                messageId = await whatsAppService.enviarDocumento(
                    object.telefone,
                    object.message.texto,
                    object.file,
                    object.message.mensagemRespondidaId,
                );
            }
        } else {
            messageId = await whatsAppService.enviarMensagemTexto(
                object.telefone,
                object.message.texto,
                object.message.mensagemRespondidaId,
            );
        }

        if (messageId) {
            object.message.id = messageId;

            context.commit(AtendimentoContextMutations.SEND_MESSAGE, object.message);
            context.commit(AtendimentoContextMutations.UPDATE_ATENDIMENTO_LAST_MESSAGE, object.message);
            context.commit(AtendimentoContextMutations.ORDER_ATENDIMENTOS);
        }
    },
    async SEND_MESSAGE_OBS(context, object: MessagesFile) {
        context.commit(AtendimentoContextMutations.SEND_MESSAGE, object.message);
        context.commit(AtendimentoContextMutations.UPDATE_ATENDIMENTO_LAST_MESSAGE, object.message);
        context.commit(AtendimentoContextMutations.ORDER_ATENDIMENTOS);
    },
    async SET_CURRENT_ATENDIMENTO(context, atendimentoId: string) {
        context.commit(AtendimentoContextMutations.SET_CURRENT_ATENDIMENTO, atendimentoId);
        context.commit(AtendimentoContextMutations.SET_CURRENT_MESSAGES, []);

        const listMessages = await mensagemService.getMensagensByAtendimento(atendimentoId);
        if (listMessages === undefined) {
            return;
        }

        for (const msg of listMessages) {
            msg.statusMensagem = StatusMensagem.Enviada;
        }

        context.commit(AtendimentoContextMutations.SET_CURRENT_MESSAGES, listMessages);
        context.commit(AtendimentoContextMutations.NOTIFICATION_CLEAN, atendimentoId);

        context.commit(
            AtendimentoContextMutations.UPDATE_ATENDIMENTO_LAST_MESSAGE,
            listMessages[listMessages.length - 1],
        );
        context.commit(AtendimentoContextMutations.ORDER_ATENDIMENTOS);
        context.commit(AtendimentoContextMutations.NOTIFICATION_SET_ALL);
    },
    SET_ATENDIMENTOS(context, atendimentos: AtendimentoWhatsAppModel[]) {
        atendimentos = sortDataUltimaMensagem(atendimentos);

        for (let atendimento of atendimentos) {
            const message = context.state.messagesAtendimentos.find(x => x.id == atendimento.id.toString());
            if (message) {
                atendimento.unreadCount = message.messages.length;
            } else {
                atendimento.unreadCount = 0;
            }
            atendimento = setAtendimentoNome(atendimento);
        }

        context.commit(AtendimentoContextMutations.NOTIFICATION_SET_ALL);
        context.commit(AtendimentoContextMutations.SET_ATENDIMENTOS, atendimentos);
    },
    SET_ATENDIMENTOS_ENCERRADOS(context, atendimentos: AtendimentoWhatsAppModel[]) {
        atendimentos = sortDataUltimaMensagem(atendimentos);

        for (let atendimento of atendimentos) {
            atendimento = setAtendimentoNome(atendimento);
        }

        context.commit(AtendimentoContextMutations.SET_ATENDIMENTOS_ENCERRADOS, atendimentos);
    },
    async UPDATE_ATENDIMENTO(context, atendimento: AtendimentoWhatsAppModel) {
        context.commit(AtendimentoContextMutations.UPDATE_ATENDIMENTO, atendimento);
    },
};

export default actions;
