// English

const __ = {
    obrigatorio: "* this field is mandatory",

    ts: {
        macho: "Male",
        femea: "Female",
        endeInfoJaCadast: "The address entered is already registered",
        preenchCorretCamposAddLista: "Fill in the fields correctly to add to the list",
        endeNaoRemovPoisVendasEntreg: "The address cannot be removed because it already has sales delivered!",
        visuVendasClient: "View Customer Sales",
        verClient: "See Customer",
        verPontos: "View customer's accumulated points",
        regisRemovSucess: "Registration was removed successfully",
        parcelando: "Splitting...",
        vlrFormaFarmaceu: "Pharmaceutical Form Value",
        replicando: "Replicating...",
        vincularKitEmb: "<label>Bind Packaging Kit:</label>",
        atualizacao: "Update!",
        tamanhFontExcedRotulo: "Font size exceeds the size of the label.",
        quantidadeColunasInvalida: "Number of invalid columns. Field: ",
        registNaoInseridExcedRotulo: "Record not inserted as it exceeds the label size.",
        copiaa: "Copy!",
        regisCopiadoSucess: "Registration was copied successfully.",
        foiAltQtdeParaMaxDisp: " was changed the amount to the maximum available.",
        codVenda: "Sale Code",
        obss: "Note",
        manipuConclSucesso: "Compounding conclude successfully.",
        orcamento: "Budget",
        volPCaps: "Vol. Caps.",
        qtdeCaps: "Qty Caps.",
        unidMedCaps: "Unity Med. Caps.",
        consolidado: "Consolidated",
        estaComQtdAbaixoMinCad: "is with stock quantity below the registered minimum.",
        avisEstqBaixo: "Low Stock Warning",
        semEstoque: "Lack of Stock",
        estoqueAtual: "Current Quantity",
        estoqueMinimo: "Minimum Stock",
        segProdsQtdsEstqAbaixMin: "Following products have their stock quantities below their minimum registration:",
        naoPossQtdEstqSufic: "does not have enough stock to meet this demand.",
        segProdsNaoPossQtdEstqSufic: "Following products do not have enough stock to meet this demand:",
        substituirPorFormulaBase: "Do you want the system to substitute for formula base",
        printRotuloManipu: "Print Compounding Label",
        pure: "pure",
        diluted: "diluted",
        calc: "Calculation",
        volDose: "Vol. Dose",
        concentr: "Concentration",
        cor: "Color",
        estqPadrao: "Default Stock",
        fabEstrang: "Foreign Manufacturer",
        atencao: "Attention!",
        aprovado: "Approved!",
        buscarEm: "Search in...",
        desejaContinuar: "Do you want to continue printing from the browser?",
        obrigatorioinformarLoteParaProduto: "It is mandatory to inform the batch for the product",
        dataInvalida: "Invalid Date",
        erroConectarWhatsapp: "WhatsApp connection error, please check if the system is connected to your device",

        // Impressão e impressoras
        // =====================================================================
        imprimir: "Print",
        impressora: "Printer",
        copias: "Copies",
        selecioneZeroNaoImprimir: 'Select "0" to not print',
        impressoraPadraoFoiUsadaEmUmaOuMaisPaginas:
            "Any printer is offline or no printer has been defined in the model.",
        impressoraNull: "(printer undefined)",
        erroNenhumaImpressoraDisponivel: "No printer available.",
        coleta: "Collection",

        // Upload de arquivo
        // =====================================================================
        tipoArquivoInvalido: "Invalid file type.",
        exemplosTipoArquivoPermitido: "Allowed file types:",
        arquivosSalvos: "Saved files",

        //..child/form/combo.ts
        addNovo: "Add New",

        //..child/grid/grid.ts
        sttsAFaturar: "Status: A Billing",
        sttsFaturado: "Status: Billed",
        sttsConcluido: "Status: Completed",
        sttsCancelado: "Status: Canceled",
        sttsVencidos: "Status: Due",
        conta: "Account",
        categoria: "Category",
        de: "From:",
        ate: "Until:",
        pgtoDe: "Payment From:",
        fornecedor: "Supplier:",

        //..child/impressaoComponent/impressaoComponent.ts
        muitosRotulos:
            "The system identified a very large number of labels, so they will be printed in a paginated form after the models are printed!",
        salvarRotulo: "Save Label",
        salvarRotuloSucesso: "Label saved successfully!",

        //..child/loadingmodal/loadingmodal.ts
        carregandooo: "Loading ...",
        salvando: "Saving ...",
        excluindo: "Excluindo...",

        //..child/logEntidade/logEntidade.ts
        historicoAlteracoes: "History of alterations",

        //..parent/app/app.ts
        sessaoExp: "Your session will expire at ",
        seg: " <strong> seconds <br/>",
        pressSimParaLogado: "Press <b>YES</b> to stay logged in",
        pressNaoParaDesconect: "or press <b>NO</b> to disconnect.<br/><br/>",
        obs: "Obs .: When disconnecting six dadps from the session will be lost.",
        tempoParaExp: "Time to expire Session!",
        sim: "Yes",
        nao: "No",
        min: " minutes",
        nfe: "Nota Fiscal de Serviço",
        cf: "Cupom Fiscal",
        vendaCod: " - Sale Cód: ",
        errReq: "Error in requesting registration for querying invoice issuance. ",
        aindaPend: "Still Pending, try to order again later due to the sales routine.",
        pendente: "Pending",
        reprovada: "Disapproved",
        emissaoConfPrefe: "Invoice issue confirmed by the city hall.",

        //..parent/atendimento
        franquiaNaoModuloContratado: "Franchise does not have this module contracted",
        historicoContato: "Contact History",

        //..parent/atualizacaoValores
        grupoProduto: "Product Group",
        markupAtual: "Current Markup",
        valorMinimoAtual: "Current Minimum Value",
        valorAdicionalAtual: "Current Additional Value",
        informePercentual: "Enter an update percentage!",
        sucessoAtualizacao: "Value update successful!",

        //..parent/crud/all
        inclusao: "Inclusion!",
        alt: "Change!",
        erro: "Error!",
        camposNaoVazios: "Fields cannot be empty!",
        senhasDif: "Different passwords!",
        regisSalvoSucess: "Registration was successfully saved.",

        //..parent/crud/all
        codigo: "Code",
        nome: "Name",
        ativo: "Active",
        desejaExcl: "Do you want to delete ",
        acaoNaoRevert: "This action cannot be reversed!",
        exclusao: "Exclusion!",
        regisExcluSucess: "Registry was successfully deleted.",

        //../parent/crud/beneficio/edit.ts
        faixaInic: "Banner Home",
        faixaFim: "End Track",
        vlr: "Value",

        //../parent/crud/beneficio/list.ts
        descr: "Description",

        //../parent/crud/campanha/edit.ts
        premio: "Prize",
        aviso: "Warning!",
        dtFimMaiorIncio: "End Date must be greater than Start Date!",

        //../parent/crud/campanha/list.ts
        dtInicio: "Start Date",
        dtFim: "End Date",

        //../parent/crud/capacidadeProducao/edit.ts
        sucesso: "Success",
        capacidadeProducaoAtualizada: "Updated production capacity!",

        //../parent/crud/capsula/list.ts
        nro: "Number",

        //../parent/crud/configuracaoAtendimento/list.ts
        voceJaPossuiHorario: "You already have this schedule registered!",

        //../parent/crud/cliente/edit.ts
        cliente: "Customer",
        localiz: "Location",
        endeEntrega: "Delivery Address",
        contato: "Contact",
        alertImpressao: "To print you must save the registration",

        //../parent/crud/cliente/pontos.ts
        nenhumaPontuacao: "No scores found!",

        //../parent/crud/duplicacoes
        cadastrosNaoResolvidos:
            "The conflicts have NOT been resolved, it will be necessary to change the registration data so that there is no duplication in the system",
        cadastroResolvidos: "The registration conflicts were resolved successfully",
        unificacaoConfirmacao:
            "As you continue, the system will remove the source record, transferring all its links to the destination record. This action is irreversible, are you sure you want to continue?",
        unificacaoErro: "Error unifying records! Try again!",
        unificacaoSucesso: "Registrations unified successfully!",
        nenhumManterCadastro: "Please select a registration to keep",
        maisDeUmManterCadastro: "Please select only one registration to keep",
        razaoSocial: "Company Name",
        cpf: "CPF",
        cpfExistente: "The CPF entered is already registered",
        cnpj: "CNPJ",
        email: "Email",
        rg: "RG",
        telefone: "Phone",

        //../parent/crud/cliente/enderecoEntregaEdit.ts
        pesqCEP: "Search by zip code",
        pesqNaoEncResul: "The search did not find any results",

        //../parent/crud/cliente/list.ts
        celular: "Cell",
        editCampos: "Edit Fields",
        fechar: "Close",
        editar: "Edit",
        vendasCliente: "Customer Sales ",

        //../parent/crud/cliente/listVendas.ts
        situacao: "Situation",
        data: "Data",
        dataConclusao: "Conclusion Date",
        dataAprovacao: "Approval Date",
        dataEntrega: "Delivery Date",

        //../parent/crud/compras/edit.ts
        grupo: "Group",
        estoqueMin: "Min Stock",
        estoqueMax: "Max Stock",
        qtdVendida: "Qty Sold",
        valorVendido: "Sold Value",
        consumoDiario: "Daily intake",
        qtdComprar: "Quantity to buy",
        necessarioSelec: "It is necessary to select the unit of measurement for the product:",
        necessarioSelecData: "It is necessary to select the product validity:",
        necessarioAddValorUnitario: "It is necessary to add the unit value of the product:",
        solicitarCotacaoForn: "Request a quote from suppliers",
        desejaEnviarEmail: "Do you want to send an email requesting a quote from suppliers?",
        emailEnviadoForn: "Email sent to suppliers",
        cancelarCompra: "Cancel Purchase",
        desejaCancelar: "Do you want to cancel this purchase ",
        alterar: "To alter",

        //../parent/crud/compras/cotacao.ts
        historicoComprasProduto: "Product purchase history",

        //../parent/crud/compras/melhorCompra.ts
        melhorCompraSucesso: "Best purchase generated successfully",
        desejaImprimir: "Do you want to print the PDF?",

        //../parent/crud/configuracaofranquia/edit.ts
        soma: "Sum",
        mult: "Multiplication",
        nenhum: "None",
        franq: "Franchise",
        user: "User",
        homolog: "Homologation",
        prod: "Product",
        avisoSNGPCControlado:
            "Be aware that by not providing the customer's RG details in controlled sales, problems may arise when automatically sending information to SNGPC.",
        updateMarkupPadrao:
            "You are updating the franchise's standard Markup, do you want to recalculate the sales value of all products that are using this Markup?",
        updateMarkupPadraoSucesso: "Sales value of successfully updated products",

        //../parent/crud/configuracaoWhatsApp/edit.ts
        desconectarIntegracao: "Disconnect Integration",
        confirmarDesconectarIntegracao: "Are you sure you want to disconnect integration with WhatsApp?",

        //../parent/crud/contagerencial/edit.ts
        entrada: "Entry",
        saida: "Output",
        transferecia: "Transfer",

        //../parent/crud/contagerencial/list.ts
        classif: "Classification",

        //../parent/crud/coleta/list.ts
        localEntrega: "delivery's place",

        //../parent/crud/contapaga/list.ts
        venc: "Due",
        vlrOrig: "Original Value",
        vlrPago: "Amount Paid",
        pagamento: "Payment",

        //../parent/crud/contapagar/edit.ts
        pago: "Paid",
        descont: "Discount",
        juros: "Interest",
        forma: "Shape",

        //../parent/crud/contapagarmultiplas/edit.ts
        vencimento: "Expiration",
        saldo: "Balance",

        //../parent/crud/contareceber/edit.ts
        tarifa: "Tariff",
        vlrOriginal: "Original Value",

        //../parent/crud/contareceber/listOperadoras.ts
        operadora: "Operator",
        autoriz: "Authorization",

        //../parent/crud/contareceber/recebimentoconta.ts
        dinheiro: "Money",
        cheque: "Check",
        boleto: "Boleto",
        cardDebito: "Debit Card",
        cardCredito: "Credit Card",
        vlrNaoPodeSerMaior: "Amount to be paid cannot be greater than balance receivable (R $",
        vlrDescMuitoAlto: "Discount amount too high, discount amount cannot be greater than balance + interest.",
        opNaoPermitida: "Operation not allowed!",

        //../parent/crud/contarecebermultiplas/edit.ts
        recebido: "Received",
        recebimento: "Receipt",

        //../parent/crud/contarecebida/list.ts
        vlrReceb: "Received Amount",

        //../parent/crud/devolucaCompra/consulta.ts
        notaEntrada: "Entry Note",
        notaDevolucao: "Return Note",

        //../parent/crud/etapaPCP/edit.ts
        descricao: "Description",
        ordem: "Order",
        obrigatorio: "Mandatory",

        //../parent/crud/etiquetas/list.ts
        erroGerarEtiq: "Error generating labels",
        nenhumaEtiqueta: "No labels available for printing",

        //../parent/crud/formafarmaceutica/edit.ts
        dose: "Dose",
        percentual: "Percentage",
        unitario: "Unitary",
        subGrupo: "Subgroup",
        tipoCalculo: "Calculation Type",
        quantidade: "Quantity",
        desmembrado: "dismembered",
        sistemico: "Systemic",
        topico: "Topic",
        total: "Total",
        homeopatia: "Homeopathy",
        papel: "Paper",
        volQtdeMg: "Volume x Qty (mg)",
        volQtdePorcent: "Volume x Qty (%)",
        volume: "Volume",
        replicou: "Replicated",
        regisSalvosSucess: "Records have been saved successfully",
        frmFarmaceutica: "PHARMACEUTICAL FORM",

        //../parent/crud/formafarmaceutica/item.ts
        qsp: "Qsp",
        falvoriz: "Flavoring",
        veicuExcip: "Vehicles / Excipients",

        //../parent/crud/formafarmaceutica/list.ts
        vlrMin: "Minimum value",
        vlrAdd: "Additional value",

        //../parent/crud/formarecebimento/edit.ts
        metodo: "Method",
        bandeiras: "Flags",
        obgAoMenosUma: "The selection of at least one Flag is mandatory.",
        avis: "Warning",
        mtdNaoPodeSerRemov: "The Method cannot be removed as it already has accounts received!",
        desejaReplFrma: "Do you want to Replicate the form",
        casoAltRecomendSalvar: "If this record has changed, we advise you to Save before proceeding!",
        replicado: "replicated",
        registroSalvSucess: "Registration was saved successfully",
        nenhumaBandeiraAdd: "No flag was added.",

        //../parent/crud/formulapadrao/edit.ts
        tipoProd: "Product Type",
        qtde: "Qty",
        qtdePesagem: "Weighing Qty",
        unMedida: "Un. Measure ",
        unManipulacao: "Un. Compounding",
        fase: "Phase",
        desejaRealmDesab: "Do you really want to disable",
        replicAtualiz: "Replicated / Updated",
        qtdeFormPadrao: "Standard Formula Quantity",

        //../parent/crud/formulapadrao/item.ts
        escolhaKitParaVincu:
            "This product is linked with a packaging kit, choose the kit you want to link to this formula.",
        itensKitAddAuto:
            "The items that make up this kit will be automatically added to this default formula, do you want to link this kit?",
        casoNaoQueiraKit: "If you do not want to link a kit to this formula, click NO to proceed without links.",
        prodFinal: "Final Product",
        tipoFormula: "Formula Type",
        verFrmlPadrao: "View Default Formula",
        frmlPadrao: "Default Formula",
        recalcValores: "Recalculation of Values",
        nenhumRegisSelec: "No record selected.",

        //../parent/crud/formulapadraomanipulacao/edit.ts
        acaoRealizSucess: "Action performed successfully.",
        manipulacao: "Compounding",

        //../parent/crud/formulapadraovalorproduto/list.ts
        formula: "Formula",
        custoCalculado: "Calculated Cost",
        refeAtual: "Current Reference",
        valorVendaCalc: "Calculated Sales Value",
        valorVendaAtual: "Current Sale Value",

        //../parent/crud/fornecedor/edit.ts
        fisica: "Physics",
        juridica: "Legal",

        //../parent/crud/franquia/edit.ts
        replicaFranquia: "Replica Franchise!",
        replicasBaseSalvas: "Base replica saved successfully.",
        msgExcluirDados: "Are you sure you want to delete the Franchise Records?",
        msgFalhaExclusao: "Failed when trying to delete data from franchise.",

        //../parent/crud/inutilizacaoNotaFiscal/edit.ts
        inutilizacao: "Destruction",
        inutilizacaoSucesso: "Invoices successfully destroyed",

        //../parent/crud/inventario/edit.ts
        cod: "Cod.",
        qtdDisponivel: "Qty. Available",
        qtdVencido: "Qty. Beaten ",
        unid: "unity",
        lote: "Lot",
        dtFabric: "Date of Manufacture",
        dtVal: "Expiration Date",
        qtdAtual: "Current Qty.",
        qtdMovimentada: "Qty. Moved",
        dtCriacao: "Creation Date",
        valLote: "Lot Validity",
        unidade: "Unit",
        tipoMoviment: "Movement type",
        campoVazio: "Empty Field!",
        campoInventDeve: "The inventory type field must be filled.",
        campoGrupoProdsDeve: "The Product Group field must be filled.",
        jaExisteInvent: "There is already an inventory in progress!",
        naoPossivIniciarDois: "It is not possible to start two inventories at the same time.",
        aprovOrcamento: "Approve Budget.",
        todasAltRevertidas: "This option will cause all changes made to be reverted.",
        desejaReverter: "Do you want to revert all settings?",
        reverter: "Revert",
        inventCancelado: "Inventory Canceled!",
        inventCanceladoSucess: "Inventory canceled successfully. All changes have been rolled back ",
        naoPossivCancelar: "It was not possible to cancel the inventory at this time, please try again.",
        opcIraFinaliz: "This option will finalize the Inventory.",
        desejaProsseg: " Do you want to proceed?",
        finalizar: "Finalize",
        inventFinalizado: "Inventory Finished!",
        inventFinalizadoSucess: "Inventory completed successfully.",
        naoPossivFinalizar: "It was not possible to finalize the inventory at this time, try again.",
        alteraQtdLote: "This option will change the quantity of this batch and cannot be reversed.",
        loteAlterado: "Lot Changed!",
        tenteNovamente: "Try again.",
        inventario: "Inventory",
        inventFinalizadoSoConsul: "Inventory finalized, only consultation available",
        venda: "Sale",

        //../parent/crud/inventario/list.ts
        tipoInventario: "Inventory Type",
        status: "Status",
        desejaExclInventario: "Do you want to delete Inventory",

        //../parent/crud/inventario/produtos.ts
        precMaxConsumidor: "Maximum Consumer Price",
        todosProdsVerifSucess: "All products successfully verified.",
        naoEncontProdsDiverg: "Divergent products not found.",

        //../parent/crud/kitembalagem/edit.ts
        embalagem: "Packing",
        matPrima: "Raw Material",
        semiAcab: "Semi-Finished",
        acabado: "Finished",
        capsula: "Capsule",
        floral: "Floral",
        recipiente: "Container",
        tampa: "Tampa",
        aplicador: "Applicator",
        outrosEmb: "Others (Packaging)",
        priorizar: "Prioritize",

        //../parent/crud/kitembalagem/edit.ts
        antimicrobiano: "Antimicrobial",
        sujeitoControlEsp: "Subject to Special Control",
        titulo: "Title",
        msg: "Message",
        erroFormaFarmaceutica: "You must have at least one registered Pharmaceutical Form.",
        erroProduto: "You must have at least one registered product.",

        //../parent/crud/meta/edit.ts
        jan: "January",
        fev: "February",
        mar: "March",
        abr: "April",
        mai: "May",
        jun: "Junho",
        jul: "July",
        ago: "August",
        set: "September",
        out: "October",
        nov: "November",
        dez: "December",
        dadosInval: "Invalid data",
        dataFimMiorInicio: "The End Date must be greater than the Start Date!",
        periodo: "Period",
        diasUteis: "Business Days",

        //../parent/crud/metodoPagamento/list.ts
        nroParcelas: "Number of Plots",
        intervalEntreParc: "Interval (days) between parcels",
        diasPrimeiraParc: "Days for First Installment",

        //../parent/crud/molde/edit.ts
        sistApenasMl: "The system is only prepared for a unit of measurement equal to Milliliter!",

        //../parent/crud/movimentacaoconta/list.ts
        cntCorrente: "Current Account",
        cntGerencial: "Managerial Account",

        //../parent/crud/movimentacaoestoque/edit.ts
        vlrUnit: "Unit Value",
        estqDestinoNaoPodeMesmoOrig: "The target stock cannot be the same as the origin stock",
        incluaUmLote: "Include at least one batch of product",
        loteJaExist: "Existing batch",
        oLote: "The lot",
        jaInclNessaMoviment: "has already been included in this inventory movement. Do you want to replace it? ",

        //../parent/crud/movimentacaoestoque/edit.ts
        qtdDisponivelInsulf: "Insufficient quantity available",
        naoPossuiQtdSufic: "there is not enough available quantity. Do you wish to continue?",
        msgStatusQuarentena: "Batch is quarantined and cannot be moved",
        msgLoteEmFracionamento: "Batch is in fractionation and cannot be moved.",

        //../parent/crud/anturezaoperacao/list.ts
        dentroEstado: "State State",

        //../parent/crud/paciente/list.ts
        especie: "Species",

        //../parent/crud/paciente/edit.ts
        atencaoPacientePossui: "Attention, this patient has special conditions:",
        atencaoPacientePossuiAlergias: "Attention, this patient has allergy(ies):",

        //../parent/crud/pcp/edit.ts
        dataInicio: "Start Date",
        dataFim: "End Date",
        motivoEtapaPCP: "Reason",
        usuarioInicio: "Start user",
        usuarioFim: "End user",
        manipulacaoNaoEncontrada: "Compounding Order not found!",

        //../parent/crud/PDV/list.ts
        sldCaixa: "Cash Balance",
        sldAtulCaixa: "Current Cash Balance",

        //../parent/crud/posologia/list.ts
        dosesSemana: "Doses per week",
        posologiaPadraoAlerta:
            'By marking the dosage as "Default", know that it will be automatically loaded into all finished and handling items. The exchange can be made at any time, but remember: Only one dosage should be marked as default. Otherwise, the system will select the first one it finds.',

        //../parent/crud/prescritor/edit.ts
        masc: "Male",
        fem: "Female",
        faixaFinalMaior: "No end range less than or equal to the start range is allowed.",

        //../parent/crud/produto/edit.ts
        verPrescr: "See Prescriber",
        liberado: "Released",
        formfarmceu: "Forma Farm.",
        excipiente: "Excipient",
        fc: "F.C.",
        cstUnit: "Unit cost",
        val: "Validity",
        fab: "Manufacturing",
        sinonimo: "Synonym",
        ver: "See",
        verLote: "View Lot",
        printRotulo: "Print label",
        defLotePadr: "Set as default batch",
        estNaoSeraControl:
            "The system will no longer control the stock of this product and warnings about it will no longer be displayed!",
        desejaRealmRemoverContr: " Do you really want to remove inventory control for this product?",
        atualizVlrVenda: "Update sale value?",
        desejRecalcVlrVenda: "Do you want to recalculate the sale value of this product?",
        desejRecalcMarkup: "Do you want to recalculate the markup for this product?",
        logAltValores: "Record of change of financial value of the product",
        jaExisteAssocExcipiente: "There is already an excipient association for this pharmaceutical form",

        //../parent/crud/produto/list.ts
        vlrsVendaAtualizSucess: "Sales figures updated successfully.",

        //../parent/crud/produtologfinanceiro/list.ts
        rotina: "Routine",
        vlrCusto: "Cost value",
        cstRefe: "Reference cost",
        cstMedio: "Average cost",
        vlrVenda: "Sales value",
        logAlt: "Change log!",
        naoHouveAltRegis: "There were no changes posted to this product!",

        //../parent/crud/produto/replicarProduto.ts
        selecItensProd: "Product Item Selection",

        //../parent/crud/recompensa/list.ts
        dias: "days",
        diasVencimento: "Days to expiration",
        percentualValorAPagar: "Percentage of the amount payable",
        somaValoresPercentualTotal: "Sum of total percentages {replace}% does not close at 100%",

        //../parent/crud/recompensa/list.ts
        ptsNecess: "Points needed",

        //../parent/crud/rede/edit.ts
        naoInfo: "Not informed",
        tributMunicipio: "1 Taxation in the Municipality",
        tributForaMunicipio: "2 Taxation outside the municipality",
        isencao: "3 Exemption",
        imune: "4 Immune",
        exigiSuspensJudic: "5 Requirement Suspended Judicial Decision",
        exigiSuspensAdminis: "6 Requirement Suspended Administrative Procedure",
        baseModelRede: "Base network model!",
        replicaBaseSucess: "Successfully generated model-based replica, franchises can be replicated.",

        //../parent/crud/replicarSelecaoFranquia/edit.ts
        nomeFantasia: "Fancy name",
        selecFranq: "Franchise selection",

        //../parent/crud/replicarSelecaoFranquiaFiliais/edit.ts
        selecFranqFiliais: "Selection of Branch Franchises",
        replicarFiliais: "Replicate between branches",

        //../parent/crud/rotulopersonalizado/edit.ts
        semLogo: "No logo",
        esquerda: "Left",
        direita: "Right",
        semQR: "No QR code",
        esqBaixo: "Left down",
        dirBaixo: "Right down",
        codBarras: "Bar code",
        loteEstoque: "Stock lot",
        campo: "Field",
        tamFonte: "Font size",
        negrito: "Bold",
        confImpress: "Print settings",
        visuImpress: "Print preview",
        tipoUso: "Type of use",
        colocarCima: "Put",
        colocarBaixo: "Put",
        dimensoes: "Dimensions",
        msgLoteNaoEncontrado: "Batch not found, unable to load label template",

        //../parent/crud/separacao/edit.ts
        itensEmb: "Packaging items",
        unidadeMedida: "Unit of measure",
        necSepararTodosItens: "It is necessary to separate all items to continue",
        necSenhaParaSepararTodosItens: "Administrator password required to separate all items.",

        //../parent/statusPcp/view.ts
        apontarEmLote: "Batch register",
        sucessoApontarEmLote: "Status changes have been successfully saved",

        //../parent/crud/separacao/list.ts
        dtInclusao: "Date of inclusion",
        dtAprov: "Approval date",

        //../parent/crud/servico/list.ts
        atividade: "Activity",
        aliq: "Aliquot",

        //../parent/crud/transportador/edit.ts
        transport: "Transporter",

        //../parent/crud/transportadora/edit.ts
        transporta: "Shipping Company",

        //../parent/crud/templateWhatsApp/list.ts
        texto: "Text",

        //../parent/crud/templateWhatsapp/tags.ts
        addTags: "Add Tags",

        //../parent/crud/unidademedida/edit.ts
        massa: "Mass",
        multiplicador: "Multiplier",
        dividir: "Divide",
        gram: "Gram (g)",
        exKg: "KG: Divide by 1000. Microliter: Multiply by 1000.",
        mililitro: "Milliliter (ml)",
        exMl: "L: Divide by 1000. Microliter: Multiply by 1000",
        un: "Unity (un)",
        cxUn: "Cx: Multiply by 12",
        ui: "International Unity (UI)",
        exUI: "Millions IU: Multiply by 1000000",
        ufc: "Colony Formation Unit (CFU)",
        exUFC: "Millions CFU: Multiply by 1000000",
        utr: "Turbidity Unit (TU)",
        exUTR: "Millions TU: Multiply by 1000000",
        naoNecessiOpConversUI:
            "It does not need a pre-parameterized conversion operator, it will come from the Factor UI field of the Batch register or Product register.",
        naoNecessiOpConversUFC:
            "It does not need a pre-parameterized conversion operator, it will come from the Factor UFC field of the Batch register or Product register.",
        naoNecessiOpConversUTR:
            "It does not need a pre-parameterized conversion operator, it will come from the Factor UTR field of the Batch register or Product register.",
        unMedidaPadrDe: "The default unit of measure",
        eh: "are",
        informOpEValor: ". Inform the operator and the value to convert. Ex: ",

        //../parent/crud/usuario/edit.ts
        padr: "Default",
        franquador: "Franchisor",
        franqueado: "Franchisee",
        atendente: "Attendant",
        prescritor: "Prescriber",
        adminEstoque: "Inventory Management",
        comercial: "Commercial",
        histAlterUsuario: "User modification history",

        //../parent/crud/vendaContinuo/list.ts
        vendaContinuos: "Continuous sale",
        paciente: "Patient",
        vlrVendido: "Quantity sold",
        vlrAtualizado: "Updated value",
        detVendaOrca: "Sales details / Budget",
        adiarAviso: "Postpone post",
        arqAviso: "File Warning",
        orcarEnviarMsgWpp: "Budget and send a message by Whatsapp",
        avisoFoiArqSucess: "The notice has been submitted successfully.",
        adiarAvisoCont: "Postpone continuous warning",
        adiar: "Postpone",
        qtdDiasAdiarMaiorZero: "The number of days to snooze must be greater than zero.",
        avisoFoiAdiadoSucess: "The warning has been successfully postponed.",
        orcaCriadoSucess: "Budget created successfully.",
        enviarOrca: "Send quote",
        enviarMsgOrcaCliente: "Send quote message to client",

        //../parent/dashboard/charts/orders.ts
        carregando: "Loading",

        //../parent/dashboard/charts/previsaoRoyalties.ts
        previsao: "Forecast",
        taxaPublic: "Advertising fee",

        //../parent/dashboard/evolucaoVendas.ts
        vendasDiario: "Sales Approved Today",
        somaVendasMensal: "Amount of Sales this Month",
        qtdeVendasMensal: "Sales Quantity for this Month",
        somaVendasAnualAprovadas: "Amount of Approved Sales (period)",
        qtdeVendasAnualAprovadas: "Quantity of Approved Sales (period)",
        somaVendasAnualRejeitadas: "Amount of Rejected Sales (period)",
        qtdeVendasAnualRejeitadas: "Quantity of Rejected Sales (period)",
        qtdeAtendimentos: "Number of Attendances",
        ticketMedio: "Average Ticket",
        qtdeOrcamentosRejeitados: "Number of Rejected Budgets in %",

        //../parent/dashboard/manipulacoes.ts
        somaPorFormaFarmaceutica: "Amount of Manipulations by Pharmaceutical Form",
        qtdePorFormaFarmaceutica: "Manipulations Quantity by Pharmaceutical Form",
        somaPorEspecie: "Amount of Manipulations by Patient Species",
        qtdePorEspecie: "Manipulations Quantity by Patient Species",

        //../parent/dashboard/totaisPorCategoria.ts
        vendasVendedorAnual: "Sales Value per Seller",
        percVendasPrescritorMensal: "% of Sales per Prescriber",
        percOrigemVendas: "% of Service Source",

        //../parent/fichaTecnica/edit.ts
        ensaios: "Tests",
        especific: "Specifications",
        validado: "Validated",
        resultados: "Results",
        selecLoteAddResult: "Select batch to add result",
        impFichaTecnica: "Printing of technical sheet",
        desejaImp: "Do you want to print the test results?",
        nenhumLoteDisp: "No batches available",

        //../parent/fracionaEstoque/kitEmbalagem.ts
        outro: "Other",
        naoSeraInclVenda: "will not be included in the sale.",
        naoHaLotes: "There are no product lots available! ",
        naoHaQtd: "There is no product quantity available in the selected batch! ",
        desejaRemov: "Do you want to remove",
        ErroAoRemov: "Error removing item, try again.",
        desejaAlter: "Do you want to change",
        regisAltSucess: "Registration has been successfully changed.",

        //../parent/historico/vendaHistorico/list.ts
        vendaHist: "Historic Sale",

        //../parent/home/fastaction/continuos/continuos.ts
        enviar: "Send",
        avisosContinuo: "Notices of Continuous",

        //../parent/home/fastaction/entregas/entregas.ts
        entregas: "Deliveries",
        vendasEntrega: "Sales with Deliveries",
        marcarEntregue: "Mark as delivered",
        codigoRastreio: "Tracking Code",
        desejaGerarCod: "Do you want to generate the tracking code?",
        temCertezaDesejaGerarCod: "Are you sure you want to regenerate the tracking code?",

        //../parent/home/fastaction/estoqueFracão/estoqueFracão.ts
        separacaoItensVenda: "Separation of sale items",
        conclSeparacItensApagados:
            "Upon completion of separation, all items will be separated. Do you wish to continue?",

        //../parent/home/fastaction/expedicao/expedicao.ts
        infoEntrega: "Delivery Information",
        expedFinalizMesmoSemDados:
            "The shipment will be completed even if the shipping details are not filled out. Do you wish to continue?",
        expedFoiFinaliz: "The expedition has ended.",
        ocorrErroAoInclRegis: "An error occurred while trying to include the records, please try again.",
        motivoObrigatorioEtapaPcp: "Mandatory reason when skipping step",
        vendasEmExped: "Shipping Sales",
        tentativasEntrega: "Delivery attempts",
        tresTentativasEntrega: "The customer has at least one sale with 3 delivery attempts",

        //../parent/home/fastaction/financeiro/financeiro.ts
        gerarReforco: "Generate Reinforcement",
        gerarSangria: "Generate Cash Out",
        reforcoGeradSucess: "Reinforcement successfully generated.",
        sangriaGeradSucess: "Sangria successfully generated.",
        caixaFechado: "The cashier is closed! First you need to open the cashier to make the move",

        //../parent/home/fastaction/manipulacao/list.ts
        visuManipu: "View Compounding",
        confLoteEstqManipu: "Check Batch / Stock Compounding",
        printManipu: "Print Compounding",
        conclManipu: "Complete Compounding",
        conclManipuMsg: "The selected manipulations completed successfully!",
        conclManipuErr: "Processing error when completing manipulations",

        //../parent/home/fastaction/manipulacao/manipulacao.ts
        estqNaoVerif: "Inventory not verified",
        estqQtdBaixaDiverg: "Inventory with diverging low quantity",
        estqVerif: "Inventory checked",
        estqAindaNaoVerif: "Stock not yet verified",
        manipulacoes: "Compoundings",
        lotesEstqManipul: "Lots / Handling Stock",
        modelImpressNaoEncont: "Print model not found!",
        manipPossuiDiverg: "Compounding has divergences in your stock.",
        desejaConfLotes: " Do you want to check the lots?",

        //../parent/home/fastaction/orcamentos/orcamentos.ts
        dtValOrcaVenc: "Expired Budget Expiration Date",
        valOrcaEmDia: "Budget validity up to date",
        valOrcaLenos10DiasVenc: "Budget validity with less than 10 days to expire",
        vendas: "Sales",
        orcamentos: "Budgets",
        opcIraArquivOrca:
            "This option will archive this budget and it can only be accessed through the advanced budget listing filters.",
        revPreenchCampos: "Field Fill Review",
        informeMotivoRejeicaoCampos: "Please state the reason for the rejection",
        enviarVendParaFrac: "Do you want to send the sale to Fractionation?",
        vendaEmSeparFrac: "The sale is separated by fractionation.",
        codzin: "Code:",
        masOcorrAlert: ", but alerts have occurred!",
        salvoSucess: "Saved successfully",
        adiarOrca: "Postpone Budget",
        adiarDtValOrca: "Defer budget expiration date",
        orcaAdiadoSucess: "Budget has been successfully postponed.",
        rotuloManipu: "Compounding Label",
        printRegistros: "Print Records",

        //../parent/home/fastaction/produtos/produtos.ts
        comAlertasEstq: "with stock alerts",
        comEstqBaixo: "low stock",
        semEstq: "out of stock",
        proxDaDataVenc: "close to the due date",
        prods: "Products",

        //../parent/home/fastaction/vendas/list.ts
        cancelarVenda: "Cancel Sale",
        visuVenda: "View Sale",
        printVenda: "Print Sale",
        faturarVenda: "Bill Sell",

        //../parent/home/fastaction/vendas/vendas.ts
        vendaSemPgtosRealiz: "Sale without payments made",
        envioNFS: "Service Invoice Shipping",
        envioCFPend: "Pending Tax Coupon Sending",
        eEnvioCFPends: "and Pending Tax Coupon Sending",
        pend: "Pending",
        nfEnvAgRetornoPref: "Invoice sent, awaiting return from the city hall",
        financCntsReceber: "Financial - Accounts Receivable",
        novoPgto: "New Payment",
        pgtos: "Payments",
        rotulo: "Label",

        //../parent/importacaonfe/edit.ts
        erroDadosXml: "XML data error",
        regisNaoPodeSalvo: "Record cannot be saved.",
        printRotulos: "Print Labels",
        itensNaoAssociados: "Every item on the tax bill must be associated with a product.",
        itensNaoConferidos: "Every item on the tax bill must be checked.",
        imprimirFichas: "Do you want to print the technical sheets of the imported batches?",

        //../parent/importacaonfe/produtos.ts
        necessAssociarProds: "It is necessary to associate all products for import.",
        necessAssociarLotes: "It is necessary to associate all batches to products for import.",
        necessAssociarUmProd: "It is necessary to associate a product for import",
        necessAssociarUmLot: "It is necessary to associate a batch to the product for import",
        verifics: "Checks",

        //../parent/manipulacao/detalhesitem.ts
        tipo: "Type",
        valorCusto: "Value Cost",
        valorVenda: "Sale Value",
        diferencaCustoVenda: "Cost / Sale Difference",

        //../parent/manipulacao/edit.ts
        qtdDeveMaiorZero: "Quantity must be greater than zero.",
        naoEncontrAtivosManipu: "No assets were found in this Compounding,",
        naoForamEncontr: "Not found",
        embalagens: "Packaging",
        capsulas: "Capsules",
        paraEstaManip: "for this Compounding,",
        desejaConsolid: "Do you want to consolidate before proceeding?",
        naoEncontrAtivosManipul: "No assets were found in this Compounding!",
        identAltVolumManipu:
            "The system has identified a change in the total volume of this Compounding and will consolidate before proceeding!",
        e: " and ",
        osProds: "The products <b>",
        naoPossVlr: "</b> have no defined value.",
        naoPossuiVlr: "</b> has no defined value.",
        desejProssegAssim: "Do you want to proceed anyway?",
        regSalvoMasAlert: "Registration was saved successfully, but alerts occurred!",
        regSalvoSucess: "Registration has been saved successfully.",
        qtdMinPermitida: "Minimum amount allowed for this pharmaceutical form is",
        qtdMaxPermitida: "Maximum amount allowed for this pharmaceutical form is",
        carregandoAtivos: "Loading Assets",
        carregandoEmbal: "Loading Packaging",
        carregandoCaps: "Loading Capsules",
        selecModelImpress: "Select a Print Model",
        jaExistCapsula: "There is already a capsule in this Compounding",
        jaExistEmbal: "There is already packaging in this handling",
        detalhCustosManipu: "Details of Compounding Costs",
        consolidouSucess: "Consolidated successfully!",
        consolidouSucessEmbalagem: "Consolidated successfully! But it is necessary to add packaging manually",
        consolidNaoSucess: "Consolidation not completed successfully!",
        validacaoVolume:
            "The sum of the volume of assets is greater than the total volume of manipulation. Try consolidating again or make necessary adjustments to avoid errors",
        sisteVerifKit: "The system has verified that the <b> packaging kit",
        sisteVerifKitt: "The system verified that the packaging kit",
        maiorCompatibEmbalagem: "</b> is more compatible with this shape than your current packaging kit (<b>",
        maiorCompatibEmbalagemm: "has greater compatibility with this shape than your current packaging kit (",
        maiorCompatibForma: "</b> is more compatible with this dosage form.",
        maiorCompatibFormaa: "has greater compatibility with this dosage form.",
        desejSubstKit: "Do you want the system to replace the packaging kit?",
        sisteVerifCapsula: "The system has verified that the <b> capsule",
        systemVerifCapsulaa: "The system has verified that the capsule",
        volMaisAdeqCaps: "</b> has the most suitable volume for this shape than the current capsule (<b>",
        volMaisAdeqCapss: "has the volume more suitable for this shape than the current capsule (",
        volMaisAdeqForma: "</b> has the most suitable volume for this dosage form.",
        volMaisAdeqFormaa: "it has the most suitable volume for this dosage form.",
        desejSubstCaps: "Do you want the system to replace the capsule?",
        sisteVerifMolde: "The system verified that the mold <b>",
        sisteVerifMoldee: "The system verified that the mold",
        volMaisAdeqMolde: "</b> has the most suitable volume for this shape than the current mold (<b>",
        volMaisAdeqMoldee: "</b> has a more suitable volume for this shape than the current mold (",
        desejSubstMolde: "Do you want the system to replace the mold?",
        altManualVolDose: "Manual dose volume change <br/>",
        aoAltSistDeixaCalc:
            "When manually changing the dose volume, the system stops calculating automatically according to the parameterization of the maximum concentration of the asset. <br/> ",
        desejProssegAltManual: "<br> Do you want to proceed with the manual change? </br>",
        produtoInativoFormaFarmaceutica:
            "Product {produto} linked to pharmaceutical form is inactive and will not be added to manipulation",
        produtoInativoPreVenda:
            "Product {produto} linked to pre sale is inactive and will not be added to manipulation",
        produtoInativoMolde:
            "Product {produto} linked to the template is inactive and will not be added to manipulation",
        necessarioConsolidar: "It is necessary to consolidate to update values!",
        desejRemoverAssocAnterior: "Do you want to remove the associations from the previous Farmaceutica Form?",
        valorMinimoFormaFarmAtingido:
            "Total value is less than the minimum value of the pharmaceutical form, values ​​lower than the minimum registered value will not be allowed",
        ativoQtdZerada:
            "Active {itemZerado} with zero quantity, please consolidate again or make the necessary adjustments!",

        //../parent/manipulacao/list.ts
        qtdTotal: "Total Qty",
        qtdTotalPesado: "Total Heavy Qty",
        naoHaEstqDisp: "There is not enough stock to serve! Do you wish to continue?",
        sisteIdentifProd: "The system has identified a product",
        paraEssaQtdDose: "for that amount of dose! Do you want to replace with ",
        fatorUI: "Factor UI!",
        naoHaFtrUI: "There is no UI factor reported in this product!",
        qtdCapsMultQtdForma:
            "Quantity of Capsules multiplied by the value of the field 'Quantity' of the Pharmaceutical Form.",
        qtdPercentVolForma: "Percentage Quantity over the Total Volume of the Pharmaceutical Form.",
        qtdSuficComplForma: "Amount sufficient to complete the Pharmaceutical Form",
        qtdTotalRepresMultQtd:
            "Total quantity represents the multiplication of the dose quantity by the total volume divided by the base dose.",
        semCalcEspecifQtdIgualQtd: "No specific calculation, total quantity equals quantity",
        // Pay attention here
        obsPossuiFtrCorrec: " Obs. Has correction factor equal to ",
        eP: " and h",
        obsP: " Obs. H",
        ossuiFtrIgual: "as factor UI equal to ",
        //He gave

        //../parent/manipulacao/item.ts
        ocorreuTrocaManual: "A manual dose change occurred, check the need to change the volume as well",
        trocaDose: "Manual dose change",

        //../parent/manipulacao/loteItem.ts
        houveAltProdsManiSalv: "There was a change in handling products, save before proceeding!",
        loteJaInfo: "This Lot has already been informed for this product!",
        qtdBaixaDeveMenorAtual: "Quantity to Low must be less than or equal to the current quantity in stock!",
        aoConfAltAlteradasNoEstq:
            "When confirming, the changes will be changed directly in stock. Do you want to proceed? ",

        //../parent/nfe/edit.ts
        entrd: "-Entry",
        said: "-Out",
        nfeNormal: "1-NF-e Normal",
        pgtoAVist: "0-Cash Payment",
        pgtoPrazo: "1-Term Payment",
        outros: "2-Others",
        nfe55: "55-NF-e",
        nfc: "65-NFC-e",
        normal0: "0-Normal",
        consumFinal: "1-Final Consumer",
        naoSeAplica: "0-Not Applicable",
        opPresenc: "1-In-person Operation",
        opNaoPresencInternet: "2-Operation not in person, via Internet",
        opNaoPresencTele: "3-Operation not in person, Call Center",
        nfceOpEntregaDomic: "4-NFC-e in operation with home delivery",
        opNaoPresencOutros: "9-Operation not in person, others",
        nfee: "NF-e",
        nfeEnvSucess: "NF-e sent successfully.",

        //../parent/notafiscalentrada/edit.ts
        descNaoPodeMaiorVlr: "Discount cannot be greater than the total amount",

        //../parent/notafiscalsaida/edit.ts
        erroEmiss: "1 - Emission Error",
        servNaoPrest: "2 - Service Not Provided",
        erroAss: "3 - Signature Error",
        duplicNota: "4 - Note Duplicity",
        servic: "Service",
        cupomFisc: "Tax Coupon",
        precUnit: "Unit Price",
        nota: "Note",
        cupom: "Coupon",
        //pay attention
        a: "the",
        o: "the",
        atualiz: "Updating ",
        fiscal: " Fiscal ...",
        enviandReqCancel: "Sending request for Cancellation of ",
        enviandConsultPara: "Sending Query to ",
        fiscalEletr: " Electronic tax ...",
        confirmad: "Confirmed!",
        consultDe: "Consultation ",
        fiscalRetornSucess: " Tax returned successfully, ",
        fiscalAprov: " Fiscal was approved",
        retorn: "Return!",
        requisDeAlter: "Change request for ",
        fiscalEnv: " Fiscal sent",
        comSucess: "successfully.",
        requisDeCancel: "Request to cancel ",
        //He gave  dlç
        prossegRotinEnviaRequisCancelNFEscolh:
            "When proceeding, the routine will send an invoice cancellation request. To proceed, choose a reason for cancellation. ",
        motivCancel: "Reason for Cancellation:",
        obgInfoMotivoCancel: "Mandatory to inform the reason for the cancellation.",
        prossegRotinEnviaRequisCancelNFInsira:
            "When proceeding, the routine will send an invoice cancellation request. To proceed please enter a cancellation reason. ",
        motivMtLongo: "Reason too long, use a maximum of 1000 characters.",
        motivMtCurto: "Reason too short, use at least 15 characters for description.",
        prossegAlterSeraoAtualizNF:
            "When proceeding, changes made in the sale will be updated in the invoice sent. Do you want to proceed? ",

        //../parent/notafiscalsaida/list.ts
        ambas: "Both",

        //../parent/printModel/condicao.ts
        dadosPrinc: "Main Data",

        //../parent/printModel/edit.ts
        normal: "Normal",
        repeticao: "repetition",
        vlrCampo: "Field Value",
        complementVlr: "Complement of Value",
        posicCompl: "Complement Position:",
        anteriorVlr: "Previous to Value",
        posteriorVlr: "After the Value",
        corText: "Text Color:",
        corFundText: "Text background color:",
        altLabelExib: "Change Display Label",
        altLabelInclInfoPerson: "Change Label to include personalized information in the printout",
        nomeCampo: "Field Name:",
        criarNovoLabelExib: "Create new Display Label",
        criarNovoLabelInclInfos: "Create new Label to include personalized information in print",

        //../parent/printModel/sessao.ts
        modelImpressSalvoSucess: "Print model saved successfully.",
        novaSessaoModel: "New Model Session",
        condicaoModel: "Model condition",
        modelAlterSucess: "Model changed successfully.",
        copiaDe: "Copy From",
        modelImpressPara: "Print Model for",

        //../parent/produtoLote/edit.ts
        dataMovimentacao: "Movement Date",
        valorUnitario: "Unit Value",
        tipoMovimentacao: "Movement Type",
        quantidadeEfetivaEstoque: "Effective quantity in stock",
        usuario: "User",
        statusAnterior: "Previous Status",
        statusAtualizado: "Updated to",
        sucessArquivo: "The file upload was successful!",

        //../parent/crud/pontuacaoManual/list.ts
        alertaTansmIrrever:
            "The system will transmit the Dotz score, this process is IRREVERSIBLE. Do you wish to proceed?",
        franquiaNaoUsaDotz: "This franchise is not configured to use Dotz.",
        franquiaConfigOutraFranquia:
            "This franchise is configured to ship together with another franchise and manual scoring is not possible",
        franquiaNaoEnviaManual: "This franchise is not configured to send Manual Dotz.",
        pontuacaoEnviada: "Dotz score sent!",

        //../parent/sessaoCaixa/aberturaCaixa.ts
        abertCaixa: "Cashier Opening!",
        abertCaixaRegSucess: "Cashier registered successfully.",

        //../parent/sessaoCaixa/fechamentoCaixa.ts
        contagem: "Count",
        validacao: "Validation",
        conclusa: "Conclusion",
        contagCaixa: "Count in the Cashier",
        diferencs: "Differences",
        clientForneced: "Customer / Supplier",
        dtHra: "Date / Time",
        gerarReforcDif: "Generate Difference Reinforcement",
        gerarSangrDif: "Generate Sangria of Difference",
        devidDifFechCaixa: "due to the difference in closing cash",
        ajustDif: "Adjust Difference",
        justif: "Justify",
        vlrCorretoJustif: "Correct and / or Justified Value",
        justifiq: "Justify!",
        justifDifVlr: "Justify differences in value",
        justifDifEntrCalcCaixa: "Justify difference between the system calculation and the sum of the cash",
        justificativDifVlres: "Justification of the difference in values",
        justifMtLonga: "Justification too long, use a maximum of 1000 characters.",
        justifMtCurta: "Justification too short, use at least 15 characters for description.",
        fechament: "Closing!",
        fechamentSalvoSucess: "Closure was saved successfully.",
        caixJaFechad: "The box is already closed!",

        //../parent/sessaoCaixa/list.ts
        sucessoReabertura: "Case reopened successfully",

        //../parent/sessaoCaixa/reforco.ts
        reforcSaldoPDV: "Reinforcement of balance for POS:",
        vlrTotalCalcNaoMenorSaldo: "Total amount calculated cannot be less than or equal to the current balance.",

        //../parent/sessaoCaixa/sangria.ts
        sangriaPDV: "Sangria de POS",
        sldCalcNaoMenorZero: "Calculated balance cannot be less than zero.",
        sangriaNaoMaiorSaldPDV: "Sangria cannot be greater than the current balance of the POS.",

        //../parent/sessaoCaixa/listMovimentacoesPendente.ts
        pagamentoReprocessado: "Requested payments have been successfully reprocessed.",

        //../parent/sngpc/list.ts
        visuProds: "View Products",
        enviarXML: "Send XML",
        remover: "Remove",
        desejExcluirr: "Do you want to exclude?",
        regEnviadoSucess: "Registration was sent successfully.",

        //../parent/topmenu/topmenu.ts
        conexaoInternet: "Conception of the Internet",
        conexaoRestSucess: "Your connection has been successfully restored!",
        aparentInternetInterr: "Apparently your internet connection has been interrupted",
        conexaoInternetPerd: "Lost Internet Connection",

        //../parent/venda/cancelamento.ts
        valorTotal: "Amount",
        quantidadeADevolver: "Quantity to be returned",
        quantidadeDevolvida: "Quantity returned",

        //../parent/venda/edit.ts
        criandOrca: "Creating Budget ...",
        orcaNovo: "New Budget **",
        copiand: "Copying ...",
        concl: "Completed",
        faturad: "Billed",
        cancelad: "Canceled",
        novo: "New **",
        orcaCod: "Budget code:",

        //../parent/venda/editSingle.ts
        revisPreenchCampos: "Field Fill Review",
        acresc: "Addition",
        continu: "Continuous",
        fiscl: "Fiscal",
        dtReceb: "Dt. Receivement",
        formPgto: "Form Pgto",
        sttsNota: "Note Status",
        tipoNota: "Note Type",
        model: "Model",
        visuPDF: "View PDF",
        cpfAtingPtsPremio: "The CPF reached the score necessary to win a prize!",
        naoReceber: "Don't Receive",
        abrirPDF: "Open PDF",
        abrirPDFSimplificado: "Open Simplified PDF",
        detalhNota: "Note Details",
        aoConclNaoPossivEmit: "Upon completion, it will not be possible to issue an Electronic Invoice",
        desejSistemEfetConcl: "Do you want the system to complete the sale?",
        enviandNF: "Sending Invoices ...",
        enviado: "Sent!",
        foiEnviadSucess: "was sent successfully.",
        vendCod: "- Sale Code:",
        nfReqSttsEnviad: "Nota Fiscal and invoice status request have been sent.",
        copia: "Copy",
        doOrca: "Budget",
        daVend: "da Venda",
        emailEnviSucess: "Email sent successfully.",
        obgSelecEndeEntr: "Mandatory to select the shipping address",
        editDetalhNF: "Edit NF-e details",
        desejEnviNotifPronto: "Do you want to send notification that the budget is ready?",
        orcaConcl: "Budget completed",
        orcaReqFoiConclAcessLink: "Requested budget has been completed. Please access the link above to approve it. ",
        desejEnviarVendaPFracion: "Do you want to send the sale to Fractionation?",
        acaoIraFinalizVenda: "This action will finalize the sale, do you want to continue?",
        concluido: "Done!",
        vendaFoiFinaliz: "The sale has ended.",
        msgCpfNaoInformado: "CPF not informed in the customer's record.",
        vendaReplicadaComManipulacao: "Replicated Sale with Manipulations",
        vendaReplicadaComManipulacaoConsolidar:
            "There was a replication of manipulations in this sale, so it is necessary to enter each manipulation and make the consolidation for the system to update the information correctly",
        vendaReplicadaComManipulacaoSemConsolidar: "Consolidate all manipulation items to be able to continue",
        observacao: "observation",
        altVendaFaturada: "Changes to invoiced sales can only be made with the approval of a supervisor",
        temCertezaSeDeseja: "Are you sure you want to delete the item from sale?",
        LinkPagamento: "Payment link",

        //../parent/venda/enderecoEntrega.ts
        criandNovoEndeEntrg: "Creating new shipping address",
        editEndeEntreg: "Editing shipping address",
        jaExistEnderMarcPdr:
            "There is already an address marked as the default address. Do you want to keep this address as the new default address? ",
        naoInfoLoteProd: "A batch of the product was not informed! Do you wish to continue?",
        fracionaEstq: "Inventory Franchising",

        //../parent/venda/itemwrapper.ts
        estoqueNaoInformado: "Stock not informed, select stock to continue!",

        //../parent/venda/linkPagamento.ts
        desejaGerarNovoLink:
            "Do you want to generate a new link? By doing so, the current link will be deactivated and you will no longer be able to make a payment through it, only through the new one!",
        linkCopiado: "Link copied to clipboard!",
        linkPagamentoAtualizado: "Payment link updated!",

        //../parent/venda/list.ts
        repEstaVenda: "Repeat this sale",
        selecioneUmaVenda: "Select at least one sale",

        //../parent/venda/listExpedicao.ts
        detalhExpedic: "Shipment details",
        finalizExpedic: "Finalize expedition",
        gridCodigo: "Code",
        gridCliente: "Customer",

        //../parent/venda/listOrcamento.ts
        orcaSelecAprovSucess: "Selected budgets successfully approved.",
        visuAltOrca: "View / Change Budget",
        adiarValOrca: "Postpone validity of this Budget",
        arqRejOrca: "Archive / Reject Budget",
        arqSucesso: "Successfully filed.",

        //../parent/venda/uploadReceita.ts
        formatInvalid:
            "Your file is in an invalid format. The accepted extensions are: (.jpg), (.png), (.pdf), (.doc), (.txt) ",

        //../parent/venda/vendaEstoque.ts
        prodPossuiContrEstqAtiv:
            "The product has active inventory control and will not be included due to lack of lots.",
        naoQtdDispNoLote: "There is no quantity available in the Batch of",

        //../parent/venda/financeiro/financeiro.ts
        addParcela: "Add Portion",
        parcelamento: "Installment",
        preFaturamento: "Pre-Invoicing",

        //../parent/printModel/list.ts
        gridColumnDescricao: "Description",
        gridColumnPadrao: "Default",

        //../parent/crud/visitador/edit.ts
        prescritorJaAssociado: "This prescriber already has a visitator associated.",
        prescritorJaInserido: "This prescriber has already been entered.",
        prescritorNaoSelecionadoo: "No prescriber has been selected",

        //../parent/dinamizacao/view.ts
        necessarioSelecionarLote: "Need to select a batch of the product",
        necessarioSelecionarKit: "Need to select a packing kit",
        loteSucesso: "Batch generated successfully",

        //../parent/crud/controlereclamacao/list.ts
        responsavel: "Responsible",
        reclamacao: "Complaint",
        acao: "Action",
        setor: "Sector",
        justificativa: "Justification",

        //..parent/estoqueMinimo
        sucessoEstMin: "Minimum and maximum stock updated successfully!",
    },

    /////////////////////////////////////////////////////////////////////////

    limpar: "Clean",
    cancelar: "Cancel",
    salvar: "Save",
    cadastrados: "Registered",
    Components: {
        parent: {
            atendimento: {
                view_vue_html: {
                    selecContatoComecar:
                        "Select one of the contacts to get started, or<br>start a conversation with a new contact",
                    nenhumAtendEncontrado: "No service found...",
                    meus: "Mine",
                    meusTitle: "Services linked to your user",
                    emAberto: "Open",
                    emAbertoTitle: "Services without any assigned agent",
                    encerrados: "Closed",
                    encerradosTitle: "Services closed",
                    emAtendimento: "In Service",
                    emAtendimentoTitle: "In service allocated to other attendants",
                    alocarAtendimento: "Allocate service",
                    trocarAtendente: "Change attendant",
                    encerrarAtendimento: "Close service",
                    anexar: "attach",
                    dadosContato: "Contact Details",
                    historicoAtend: "Service history",
                },
                historicoAtendimento: {
                    semAtendimento: "This contact does not have any closed service!",
                },
                mensagemOptions: {
                    responder: "Reply",
                },
                trocarAtendente: {
                    nomeAtendente: "Attendant Name",
                },
            },

            atualizacaoValores: {
                percentualAtualizacao: "Update Percentage (%)",
                atualizar: "Update",
                formaFarmaceutica: {
                    atualizacaoValoresProdutos: "Pharmaceutical Form Value Update",
                    encontrados: "Pharmaceutical Forms found",
                    percentualValorMinimo: "Percentage Minimum Value (%)",
                    percentualValorAdicional: "Percentage Additional Value (%)",
                },
                produto: {
                    atualizacaoValoresProdutos: "Product Value Update",
                    encontrados: "Products found",
                    nenhumSelecionado: "No records have been selected.",
                },
            },

            duplicacoes: {
                list_vue_html: {
                    clientesDuplicados: "Duplicate Clients",
                    prescritoresDuplicados: "Duplicate Prescribers",
                    registros: "Duplicate Records",
                    resolver: "Solve",
                    unificar: "Unify",
                    prescritorOrigem: "Origin prescriber",
                    prescritorDestino: "Destination prescriber",
                    clienteOrigem: "Originating customer",
                    clienteDestino: "Target customer",
                },

                edit_vue_html: {
                    edicaoCliente: "Customer Edition",
                    edicaoPrescritor: "Prescriber Edition",
                    manter: "Keep",
                },
            },

            crud: {
                incluir: "Incluir",
                alterar: "Change",
                descricao: "Descrição",
                ativo: "Active",
                salvar: "Save",
                limpar: "Clean",
                voltar: "Back",
                fechar: "Close",
                imprimir: "Print",
                replicar: "Replicate",
                copiar: "Copy",
                excluir: "Delete",
                download: "Download",
                gerarArquivo: "Generate File",
                adicionarTodos: "Add All",

                filtrar: "Filter",
                filtroAvancado: "Advanced Filter",

                listInputs: {
                    acao: "Action",
                    adicionar: "Add",
                    remover: "Remove",
                },

                alterarsenha: {
                    alterarsenha_vue_html: {
                        altSenha: "Change Password",
                        novaSenha: "New Password",
                        confSenha: "Confirm Password",
                    },
                },

                balancoCompleto: {
                    view_vue_html: {
                        balancoCompletoGeracao: "Complete Balance Sheet",
                        ano: "Year",
                        tipoPeriodo: "Period Type",
                    },
                },

                mapaSiproquim: {
                    view_vue_html: {
                        mapaSiproquim: "SIPROQUIM Map",
                    },
                },

                banco: {
                    edit_vue_html: {
                        bancoEdit: "Bank - Edition",
                        nome: "Name",
                        codigo: "Code",
                        utilizCodFEBRABAN: "Use the code provided by FEBRABAN",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        bancos: "Banks",
                        cadastrados: "Registered",
                    },
                },

                controleReclamacao: {
                    edit_vue_html: {
                        controleReclamacaoEdit: "Complaint Control - Edit",
                        codVenda: "Sale Code",
                        cliente: "Customer",
                        vendedor: "Seller",
                        responsavel: "Responsible",
                        dataReclamacao: "Complaint Date",
                        reclamacao: "Complaint",
                        acao: "Action",
                        status: "Status",
                        categoriaReclamacao: "Complaint category",
                        justificativaReclamacao: "Justification of the complaint",
                    },

                    list_vue_html: {
                        controleReclamacao: "Complaint Control",
                        reclamacoes: "Complaints",
                        cadastradas: "Registered",
                    },
                },

                categoriaControleReclamacao: {
                    edit_vue_html: {
                        categoriaControleReclamacaoEdit: "Complaint Control Category - Edition",
                        descricao: "Description",
                    },

                    list_vue_html: {
                        categoriaControleReclamacao: "Categories",
                        categoriasList: "Categories",
                        categorias: "Categories",
                        cadastradas: "Registered",
                    },
                },

                justificativaControleReclamacao: {
                    edit_vue_html: {
                        justificativaControleReclamacaoEdit: "Justification of complaints - Edition",
                        descricao: "Descripción",
                    },

                    list_vue_html: {
                        justificativaControleReclamacao: "Justifications",
                        justificativasList: "Justifications",
                        justificativas: "Justifications",
                        cadastradas: "Registered",
                    },
                },

                bandeira: {
                    edit_vue_html: {
                        bandeiraEdit: "Flag - Edition",
                        descricao: "Description",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        bandeiras: "Flags",
                        cadastradas: "Registered",
                    },
                },

                beneficio: {
                    edit_vue_html: {
                        beneficiosEdit: "Benefits - Edition",
                        descricao: "Description",
                        descontos: "Discounts",
                        inicioFaixa: "Range Start",
                        fimFaixa: "Range End",
                        valor: "Value",
                        comissao: "Commission",
                    },

                    list_vue_html: {
                        beneficio: "Benefit",
                        beneficios: "Benefits",
                        cadastrados: "Registered",
                    },
                },

                campanha: {
                    list_vue_html: {
                        campanhas: "Campaigns",
                        cadastradas: "Registered",
                    },

                    edit_vue_html: {
                        campanhaEdit: "Campaign - Edition",
                        descricao: "Description",
                        dataInicio: "Start Date",
                        dataFim: "End Date",
                        faixas: "Tracks",
                        recompensas: "Rewards",
                        grupoProduto: "Product Group",
                        valorInicial: "Initial value",
                        valorPorPonto: "Value per Point",
                        pontos: "Spots",
                        pontuar: "Score",
                        valorInicialFaixaGrid: "Initial value",
                        valorFinalFaixaGrid: "Final value",
                        faixaSobreposta:
                            "The beginning of this range cannot override the ending value of another range.",
                        faixasCampanhaValida:
                            "For the campaign to be valid, it is necessary to register at least one track.",
                        brinde: "Use reward",
                        multiplicador: "Multiplier",
                        valorUtilizadoPntsRecebidos: "Value in Reais per point received",
                        padrao: "Default",
                    },
                },

                capacidadeProducao: {
                    edit_vue_html: {
                        capacidadeProducaoEdit: "Production Capacity - Edition",
                        descricao: "Description",
                        horario: "Time",
                        horarioFinalDeSemana: "Weekend Time",
                        capacidadeSegunda: "Cap. Monday",
                        capacidadeTerca: "Cap. Tuesday",
                        capacidadeQuarta: "Cap. Wednesday",
                        capacidadeQuinta: "Cap. Thursday",
                        capacidadeSexta: "Cap. Friday",
                        capacidadeSabado: "Cap. Saturday",
                        capacidadeDomingo: "Cap. Sunday",
                    },
                },

                capsula: {
                    edit_vue_html: {
                        capsulaEdit: "Capsule - Edition",
                        descricao: "Description",
                        nro: "Number",
                        volumeInternoml: "Internal volume (ml)",
                        volumeTotalml: "Total Volume (ml)",
                        pesog: "Weight (g)",
                        tipoCapsAtivo: "Active capsule type",
                        capsulas: "Capsule",
                        capsulasGastrorresistente: "Capsules Gastroresistant",
                    },

                    list_vue_html: {
                        capsulas: "Capsules",
                        cadastradas: "Registered",
                    },
                },

                cest: {
                    edit_vue_html: {
                        cestEdit: "CEST - Edition",
                        descricao: "Description",
                        codigo: "Code",
                    },

                    list_vue_html: {
                        cests: "CESTs",
                        ncms: "NCMs",
                        cadastrados: "Registered",
                    },
                },

                farmacopeia: {
                    edit_vue_html: {
                        farmacopeiaEdit: "Pharmacopoeia - Edition",
                        codigo: "Code",
                        descricao: "Description",
                    },

                    list_vue_html: {
                        farmacopeia: "Pharmacopoeia",
                        farmacopeias: "Pharmacopoeias",
                        cadastradas: "Registered",
                    },
                },

                cliente: {
                    edit_vue_html: {
                        clienteEdit: "Cliente - Edition",
                        dadosGerais: "General Data",
                        tipo: "Type",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. State",
                        contribuinteEstadual: "State Taxpayer",
                        documentoEstrangeiro: "Foreign document",
                        exterior: "Foreign",
                        clientIsentoEmBranco: "For exempt customers leave it blank",
                        nome: "Name",
                        MASCULINO: "Trade Name",
                        nomeRotulo: "Label Name",
                        nomeQueRotulo: "Name that will be used on the label to identify the customer",
                        razaoSocial: "Company Name",
                        im: "Insc. Municipal",
                        dataNasc: "Date of Birth",
                        genero: "Gender",
                        orgaoExp: "Issuing Agency",
                        ufExp: "UF Expedidor",
                        obs: "Observation",
                        localizacao: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        complemento: "Complement",
                        enderecoEntrega: "Delivery Address",
                        usarMesmoEnde: "Use the same address",
                        contato: "Contact",
                        telefone: "Phone",
                        celular: "Cell",
                        pacientes: "Patients",
                        cpfJACadastradoOutrocliente: "Cpf already registered for another customer!",
                        ativo: "Active",
                        emailsParaNotaFiscal: "Email(s) for invoice",
                        email: "E-mail",
                    },

                    enderecoEntregaEdit_vue_html: {
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        complemento: "Complement",
                        endecoPdr: "Default Address",
                    },

                    list_vue_html: {
                        clientes: "Customers",
                        cadastrados: "Registered",
                        visuCliente: "View Customer",
                    },

                    ver_vue_html: {
                        dadosGerais: "General Data",
                        tipoPessoa: "Person Type:",
                        cpf: "CPF:",
                        rg: "RG:",
                        nome: "Name:",
                        dtNasc: "Date of Birth:",
                        genero: "Gender:",
                        orgaoExp: "Issuing Body:",
                        ufExp: "UF Dispatcher:",
                        obs: "Note:",
                        localiz: "Location",
                        cep: "zip code",
                        estado: "state:",
                        cidade: "City:",
                        endereco: "Address:",
                        nro: "Number:",
                        bairro: "Neighborhood:",
                        complemt: "Complement:",
                        contato: "Contact",
                        tel: "Telephone:",
                        cel: "Phone:",
                        pacientes: "Patients",
                        paciente: "Patient:",
                        nomeEspec: "Name / Species:",
                        documentoEstrangeiro: "Foreign document:",
                    },
                },

                clinicas: {
                    list_vue_html: {
                        titulo: "Clinics",
                        cadastradas: "Registered",
                    },

                    edit_vue_html: {
                        configFranquia: "Registration of Partner Clinics",
                        prescritorOutraClinica: "This prescriber is part of another clinic",
                        prescritorJaInserido: "This prescriber has already been inserted",
                        pontuarDotz: "Score Dotz",
                    },
                },

                coleta: {
                    list_vue_html: {
                        coletas: "Collections",
                        cadastradas: "Registered",
                    },

                    edit_vue_html: {
                        coletaEdit: "Collection - Edit",
                        data: "Collection date",
                        descricao: "Description",
                    },
                },

                condicao: {
                    edit_vue_html: {
                        descricao: "Description",
                    },
                    list_vue_html: {
                        condicoes: "Conditions",
                    },
                },

                configuracaofranquia: {
                    edit_vue_html: {
                        configFranquia: "Franchise Settings",
                        franquia: "Franchise",
                        integradorFranquia: "Integration Setup - Edit",
                        api_Key: "API KEY",
                    },
                },

                configuracaousuario: {
                    edit_vue_html: {
                        configUsuario: "User Settings",
                        usuario: "User",
                    },
                },

                configuracaowhatsapp: {
                    edit_vue_html: {
                        configWhatsApp: "WhatsApp Configuration",
                        iniciarConfiguracao: "Start Configuration",
                        atualizar: "Update",
                        cliqueAqui: "Click here",
                        verComoFazer: "to see how to do it",
                        leiaQrCode: "Use your cell phone to read the QrCode below and connect to WhatsApp.",
                        integracaoPermite:
                            "Integration with WhatsApp allows automatic messages to be sent to customers securely and quickly.",
                        paraFazerAConfiguracao:
                            "To configure and activate the integration, have the store's cell phone at hand and click the button below to start the configuration",
                        cliqueBotaoAbaixo:
                            "After reading the code on your cell phone, click the button below to proceed",
                        integracaoProntaUso: "Integration with WhatsApp is ready to use",
                        desconectarIntegracao: "Disconnect integration",
                        definirWebhook: "Configure Webhook",
                    },
                },

                contacorrente: {
                    edit_vue_html: {
                        contaCorrentEdit: "Current Account - Edition",
                        descricao: "Description",
                        interna: "Internal",
                        ativo: "Active",
                        banco: "Bank",
                        agencia: "Agency",
                        conta: "Account",
                    },

                    list_vue_html: {
                        contasCorr: "Current Accounts",
                        cadastradas: "Registered",
                    },
                },

                contagerencial: {
                    edit_vue_html: {
                        contaGerencialEdit: "Management Account - Edition",
                        descricao: "Description",
                        classif: "Classification",
                        tipoMovimen: "Movement Type",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        contasGerenc: "Management Accounts",
                        cadastradas: "Registered",
                    },
                },

                contagerencialclassificacao: {
                    edit_vue_html: {
                        classifCntGerencialEdit: "Managerial Account Classification - Edition",
                        descricao: "Description",
                    },

                    list_vue_html: {
                        classificCntGerencial: "Managerial Account Classifications",
                        cadastradas: "Registered",
                    },
                },

                contapaga: {
                    list_vue_html: {
                        contasPagas: "Paid Accounts",
                        cadastradas: "Registered",
                        categ: "Category:",
                        fornec: "Supplier:",
                        venciDe: "Expiration From:",
                        ate: "Until:",
                        pagamentDe: "Payment From:",
                    },
                },

                contapagar: {
                    edit_vue_html: {
                        contAPagarEdit: "Account Payable - Edition",
                        dadosConta: "Account Data",
                        descricao: "Description",
                        recorrenc: "Recurrences",
                        utilizParaLancFuturos:
                            "Use this option if this account will be repeated in the coming months, to carry out future launches",
                        frequenc: "Frequency",
                        fornecedor: "Supplier",
                        contGerencial: "Managerial Account",
                        dtVenciment: "Expiration Date",
                        vlrTotal: "Total Amount",
                        saldo: "Balance",
                        cdBarras: "Barcode",
                        obs: "Observation",
                        parcelar: "Parcel",
                        mtdPagament: "Installment Method",
                        parcela: "Portion",
                        vlrParcela: "Installment Value",
                        saldoParcela: "Installment Balance",
                        pgmtRealizados: "Payments Realized",
                        sldPagar: "BALANCE TO PAY",
                    },

                    list_vue_html: {
                        contasAPagrAberto: "Accounts Payable - Open",
                        contasAPagr: "Accounts Payable",
                        cadastradas: "Registered",
                        tudo: "All",
                        vencidos: "Expired",
                        fornecedor: "Supplier:",
                        vencDe: "Expiration From:",
                        ate: "Until:",
                    },

                    pagamentoconta_vue_html: {
                        pgto: "Payment",
                        dtPgto: "Payment Date",
                        valor: "Value",
                        contaCorrente: "Current Account",
                        formaPagamento: "Payment Method",
                        juros: "Interest",
                        desconto: "Discount",
                        totalPago: "Total Paid",
                        obs: "Observation",
                        incluirPgto: "Include Payment",
                    },
                },

                contapagarmultiplas: {
                    edit_vue_html: {
                        cntPagarEdit: "Accounts Payable - Edition",
                        cntPagar: "Accounts Payable",
                        saldoPagar: "BALANCE TO PAY",
                        cifrao: "R $",
                        efetPgtos: "Effect Payments",
                        dtPgto: "Payment Date",
                        cntCorrent: "Current Account",
                        formaPgto: "Payment Method",
                        juros: "Interest",
                        descontos: "Discounts",
                        vlrPago: "Amount Paid",
                    },
                },

                contareceber: {
                    edit_vue_html: {
                        cntReceberEdit: "Account Receivable - Edition",
                        naoPodeAlterarMovimentac:
                            "Receive account from sale! It is not possible to change movements through this register. ",
                        dadosConta: "Account Data",
                        descricao: "Description",
                        recorrencias: "Recurrences",
                        utilizCasoRepita:
                            "Use this option if this account will be repeated in the coming months, to carry out future launches",
                        freq: "Frequency",
                        cntGerencial: "Managerial Account",
                        cliente: "Customer",
                        operadoraCartoes: "Card Operator",
                        autoriz: "Authorization",
                        dtVenc: "Expiration Date",
                        vlrTotal: "Total Amount",
                        sldTotal: "Total Balance",
                        codBarras: "Barcode",
                        obs: "Observation",
                        parcelar: "Parcel",
                        mtdParcelamento: "Installment Method",
                        parcela: "Portion",
                        vlrParcela: "Parcel Value",
                        sldParcela: "Parcel Balance",
                        liquidar: "Liquidate",
                        recebRealizados: "Received realized",
                        sldReceber: "BALANCE TO BE RECEIVED",
                    },

                    list_vue_html: {
                        cntReceberAberto: "Accounts Receivable - Open",
                        cntReceberParcelado: "Accounts Receivable - Installments",
                        cntReceber: "Accounts Receivable",
                        cadastradas: "Registered",
                        cntGerencial: "Management Account:",
                        vncDe: "Expiration From:",
                        ate: "Until:",
                        formaPgto: "Form of payment: ",
                        cntReceberVenda: "Sales Receivable Account",
                    },

                    listOperadoras_vue_html: {
                        cntReceberOpCartAberto: "Accounts Receivable from Card Operators - Open",
                        cntReceber: "Accounts Receivable",
                        cadastradas: "Registered",
                        cntReceberAberto: "Accounts Receivable - Open",
                        cntGerencial: "Management Account:",
                        vncDe: "Expiration From:",
                        ate: "Until:",
                    },

                    recebimentoconta_vue_html: {
                        recebimento: "Receipt",
                        dtRecebimento: "Receipt Date",
                        cntCorrente: "Current Account",
                        especie: "Species",
                        bandeira: "Flag",
                        frmRecebimento: "Receipt Form",
                        mtdParcelamento: "Installment Method",
                        autorizNSUCartao: "Card Authorization / NSU",
                        vlrAbater: "Amount to be Paid",
                        tarifa: "Tariff",
                        juros: "Interest",
                        desconto: "Discount",
                        totalRecebido: "Total Received",
                        obs: "Observation",
                        inclRecebimento: "Include Receipt",
                        attRecebimento: "Update Receipt",
                    },
                },

                contarecebermultiplas: {
                    edit_vue_html: {
                        cntReceberEdit: "Accounts Receivable - Edition",
                        cntReceber: "Accounts Receivable",
                        sldReceber: "BALANCE TO BE RECEIVED",
                        cifrao: "R $",
                        efetRecebimentos: "Make Receipts",
                        dtRecebimento: "Receipt Date",
                        cntCorrente: "Current Account",
                        especie: "Species",
                        bandeira: "Flag",
                        frmRecebimento: "Receipt Form",
                        mtdParcelamento: "Installment Method",
                        autorNSUCartao: "Autor / NSU Cartão",
                        juros: "Interest",
                        descontos: "Discounts",
                        vlrRecebido: "Amount Received",
                    },
                },

                contarecebida: {
                    list_vue_html: {
                        cntReceberRecebidas: "Accounts Receivable - Received",
                        cntRecebidas: "Accounts Received",
                        cadastradas: "Registered",
                        conta: "Account:",
                        vncDe: "Expiration From:",
                        ate: "Until:",
                    },

                    listOperadoras_vue_html: {
                        cntReceberRecebidas: "Accounts Receivable - Received",
                        cntRecebidas: "Accounts Received",
                        cadastradas: "Registered",
                        conta: "Account:",
                        vncDe: "Expiration From:",
                        ate: "Until:",
                    },
                },

                configuracaoFranquiaGatewayPagamento: {
                    edit_vue_html: {
                        gatewayPagamentoEdit: "Payment gateway - Edit",
                        gatewayPagamento: "Payment gateway",
                        toke: "Token",
                        descFaturaCartao: "Card invoice description",
                        cartaoCreditoMaximoParcelasSemJuros:
                            "Maximum number of installments WITHOUT INTEREST on credit",
                        cartaoCreditoMaximoParcelasComJuros: "Maximum number of installments WITH INTEREST on credit",
                        expLinkpagamentoHoras: "Payment link expiration (hours)",
                        pixAtivo: "Pix active",
                        boletoAtivo: "Boleto active",
                        cartCredAtivo: "Credit card active",
                        cartDebAtivo: "Debit card active",
                        valorMinimoParcela: "Minimum installment value",
                    },
                },

                cor: {
                    edit_vue_html: {
                        corEdit: "Colors - Edition",
                        descricao: "Description",
                    },

                    list_vue_html: {
                        cores: "Colors",
                        cadastrados: "Registered",
                    },
                },

                dadosAdicionaisFichaTecnica: {
                    edit_vue_html: {
                        dadosAdicFTEdit: "Additional Data Sheet - Edition",
                        descricao: "Description",
                        padrao: "Default",
                    },

                    list_vue_html: {
                        dadosAdicFT: "Additional Data Sheet",
                        cadastrados: "Registered",
                    },
                },

                declaracaoConteudo: {
                    view_vue_html: {
                        docDeclaraConteudo: "Content Declaration Document",
                    },
                },

                devolucaoCompra: {
                    edit_vue_html: {
                        devolucaoCompraEdit: "Purchase return - Edition",
                        nota: "Nota",
                        numero: "Nota Fiscal Number",
                        serie: "Serie",
                        dataEmissao: "Issue Date",
                        dataInclusao: "Date of inclusion",
                        fornecedor: "Supplier",
                        transportadora: "Carrier",
                        itens: "Items to be returned",
                        emitir: "Issue return note",
                        valorTotal: "Total amount to be returned",
                        cabecalhoProduto: "Product",
                        cabecalhoLote: "Batch",
                        cabecalhoQuantidade: "Quantity",
                        cabecalhoQuantidadeDevolver: "Quantity to be return",
                        cabecalhoUnidadeDevolver: "Un.Measure to be returned",
                        cabecalhoQuantidadeEstoque: "Qty discount Inventory",
                        cabecalhoUnidadeEstoque: "Unit Measure Stock",
                        cabecalhoValorDevolver: "Value to be return",
                        cabecalhoValorUnitario: "Unit value",
                        cabecalhoValorTotal: "Total value",
                    },

                    consulta_vue_html: {
                        devolucaoCompra: "Purchase return - Consult",
                        numero: "Nota Fiscal Number",
                        serie: "Serie",
                        dataEmissao: "Issue Date",
                        chaveAcesso: "Access Key",
                        consultar: "Consult",
                        fornecedor: "Supplier",
                        transportadora: "Shipping Company",
                        notaEntrada: "Entry Note",
                        notaDevolucao: "Return Note",
                        movimentacaoEstoque: "Inventory movement",
                    },

                    list_vue_html: {
                        devolucaoCompra: "Purchase Returns",
                        cadastrados: "Registered",
                    },
                },

                downloadXmls: {
                    edit_vue_html: {
                        downloadXmls: "Download Invoice XMLs",
                        dataInicial: "Initial date",
                        dataFinal: "Final date",
                        tiposDocumento: "Document type",
                        tiposNotas: "Invoice type",
                        download: "Download",
                    },
                },

                email: {
                    edit_vue_html: {
                        emailEdit: "Email - Edition",
                        host: "Host",
                        porta: "Port",
                        email: "E-mail",
                        senha: "Password",
                    },

                    list_vue_html: {
                        cadastrados: "Registered",
                    },
                },

                ensaio: {
                    edit_vue_html: {
                        ensaioEdit: "Essay - Edition",
                        descricao: "Description",
                        farmacopeia: "Pharmacopeia",
                        soludibilidade: "Solubility",
                    },

                    list_vue_html: {
                        ensaio: "Assay",
                        ensaios: "Essay",
                        cadastrados: "Registered",
                    },
                },

                especialidade: {
                    edit_vue_html: {
                        especialidadeEdit: "Specialty - Edition",
                        descricao: "Description",
                    },

                    list_vue_html: {
                        especialidades: "Specialties",
                        cadastradas: "Registered",
                    },
                },

                especieanimal: {
                    edit_vue_html: {
                        espAnimalEdit: "Animal Species - Edition",
                        descricao: "Description",
                    },

                    list_vue_html: {
                        espAnimal: "Animal Species",
                        cadastradas: "Registered",
                    },
                },

                estoque: {
                    edit_vue_html: {
                        estqEdit: "Inventory - Edition",
                        descricao: "Description",
                        permiteVenda: "Allow Sale",
                    },

                    list_vue_html: {
                        estoques: "Stocks",
                        cadastradas: "Registered",
                    },
                },

                estoqueMinimo: {
                    view_vue_html: {
                        mesesConsiderar: "Months to consider",
                        coberturaEstoque: "Inventory coverage in days",
                        calcularEstoque: "Calculate stock",
                    },
                },

                etapaPCP: {
                    edit_vue_html: {
                        etapaPCPEdit: "Stage PCP - Edition",
                        descricao: "Description",
                        ordem: "Order",
                        ativo: "Active",
                        obrigatorio: "Mandatory",
                        tipo: "Type",
                    },

                    list_vue_html: {
                        etapasPCP: "Stages PCP",
                        cadastrados: "Registered",
                    },
                },

                etiquetas: {
                    list_vue_html: {
                        etiquetas: "Tags",
                        cadastradas: "Registered",
                        exibirSomenteVendas: "Show only sales with notes",
                        imprimirEtiquetas: "Print Labels",
                        gerarEtiquetaLote: "Generate labels in batch",
                    },
                },

                expedicao: {
                    listEntrega_vue_html: {
                        statusEntrega: "Delivery Status",
                    },
                },

                formafarmaceutica: {
                    edit_vue_html: {
                        formFarmaceutEdit: "Pharmaceutical Form - Edition",
                        franquia: "Franchise",
                        ativo: "Active",
                        descricao: "Description",
                        utilzMolde: "Use Mold",
                        calcMoldeAdequado:
                            "If mold is used, and it has molds registered in the model register, when creating a Compounding by the budget, the system will search for the most suitable mold for the quantity of asset informed, if it does not use mold the volume of Compounding must be informed manually in its creation.",
                        desconsiDensid: "Disregard Density",
                        desconsiDensidNoCalc:
                            "Disregard density when calculating the total volume of assets that define the QSP.",
                        descrRotulo: "Description Label",
                        tipCalcAtivo: "Asset Calculation Type",
                        tipCalcPadrao:
                            "Type of default calculation for when informed the asset in the handling of this pharmaceutical form, if not informed, the system will use the type of calculation of the product registration.",
                        conceMax: "Maximum Concentration",
                        conceMaxFito: "Maximum Phytotherapeutic Concentration",
                        conceMaxDose: "Maximum concentration per dose of the main asset.",
                        ncmSH: "NCM / SH",
                        codOitoDig:
                            "Code composed of 8 numbers, used to classify items according to Mercosur regulations",
                        valDias: "Validity (days)",
                        tipo: "Type",
                        calcEmbal: "Packaging Calculation",
                        uso: "Use",
                        tipoUso: "Usage Type",
                        qntMin: "Minimum Quantity",
                        qntMax: "Maximum Quantity",
                        unMedVol: "Volume Measure Unit",
                        pop: "P.O.P (default Operating Procedure)",
                        descriDetalhProc: "Detailed description of the operations required to perform the procedure",
                        exibirPrefixoDoseRotulo: "Show dose prefix on label",
                        ocultarExcipienteRotulo: "Hide vehicle/excipient on label",
                        ocultarFlavorizanteRotuno: "Hide flavoring on the label",
                        vlrMin: "Minimum Value",
                        vlrAdd: "Additional Value",
                        ftrMultQtd: "Multip factor. Amount",
                        ftrAddVlrForm:
                            "Factor that multiplied the quantity of the pharmaceutical form will be added to the additional value of the form",
                        ftrCompensacao: "Compensation Factor",
                        ftrSomaMult:
                            "Factor that adds to the multiplier as long as it is greater than zero and decreases with the decay rate",
                        ftrMultDecaimento: "Mult Factor. Decay ",
                        ftrCriaTaxDecaimento:
                            "Factor that multiplied the quantity creates a decay rate and creates a price curve",
                        itemsAdd: "Items Added",
                        atualizarValores:
                            "Do you want to update the value fields? <BR/><BR/><BR/> Fields that will be updated:<BR/> Minimum Value<BR/> Additional Value<BR/> Compensation Factor<BR/> Additional Multiplier Factor<BR/> Decay<BR/>",
                        exibirDescRotulo: "Show Description Label on label",
                        capsVegana: "Vegan Capsule",
                        capsVeganaRevestida: "Coated Vegan Capsule",
                    },

                    item_vue_html: {
                        item: "Item",
                        subGrup: "SubGroup",
                        prod: "Product",
                        tipoCalc: "Calculation Type",
                        qnt: "Quantity",
                        desmemb: "Dismembered",
                    },

                    list_vue_html: {
                        frmsFarmaceu: "Pharmaceutical Forms",
                        cadastradas: "Registered",
                    },
                },

                formapagamento: {
                    edit_vue_html: {
                        frmPagtoEdit: "Payment Method - Edition",
                        descricao: "Description",
                        especie: "Kind",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        frmsPagto: "Payment Methods",
                        cadastradas: "Registered",
                    },
                },

                formarecebimento: {
                    edit_vue_html: {
                        frmRecebimentoEdit: "Form of Receipt - Edition",
                        descricao: "Description",
                        ativo: "Active",
                        especie: "Species",
                        percenDesc: "Discount Percentage",
                        percenJuros: "Percentage of Interest",
                        operadCartoes: "Card Operator",
                        cntCorrOrigem: "Current Account Origin",
                        cntCorrOrigemVlr: "Current Account of the origin of the transferred amount",
                        frmPgtoOrigem: "Form of Payment Origin",
                        frmPgtoParaSaida:
                            "Payment method used to generate the exit. Note: Your species defines the type of species of the receipt generated. ",
                        bandeirasSelec: "Selected Flags",
                        bandeira: "Flag",
                        mtdPgtoAceitos: "Payment Methods accepted",
                        addAltMetodos: "Add / Change Methods",
                    },

                    list_vue_html: {
                        frmsRecebimento: "Ways of Receiving",
                        cadastradas: "Registered",
                    },

                    metodosXFormaEdit_vue_html: {
                        mtdPgto: "Payment Method",
                        percenDesc: "Discount Percentage",
                        percenJuros: "Percentage of Interest",
                        percenTarifa: "Rate Percentage",
                    },
                },

                formulapadrao: {
                    edit_vue_html: {
                        frmlaPadraoEdit: "Default Formula - Edition",
                        dadosGerais: "General Data",
                        franquia: "Franchise",
                        ativo: "Active",
                        descricao: "Description",
                        prodFinal: "Final Product",
                        frmFarmaceu: "Pharmaceutical Form",
                        valDias: "Validity (days)",
                        tipoFrmlPadrao: "Default Formula Type",
                        capsula: "Capsule",
                        qtdCapsulas: "Capsule Quantity",
                        volume: "Volume",
                        unMedida: "Unit of Measure",
                        obs: "Observation",
                        proceProd: "Product Procedure",
                        posologia: "Posology",
                        descrRotulo: "Description Description",
                        desmemFrml: "Dismember Formula",
                        produzir: "Produce",
                        revisAjustar: "Revise / Adjust",
                        kitEmbalagem: "Packing Kit",
                        qtdPadraoPreVenda: "Standard quantity in pre-sale",
                        valorTabelado: "Listed Value",
                        edicaoPreVenda: "Pre-sale Edition",
                    },

                    item_vue_html: {
                        tipoProd: "Product Type",
                        prod: "Product",
                        faseUso: "Use phase",
                        unMedida: "Measure Unit",
                        ftrCorre: "Correction Factor",
                        tipoCalc: "Calculation Type",
                        volumeDose: "Size Dose",
                        qtd: "Quantity",
                        unMedidaManipu: "Compounding Measure Unit",
                        qtdTotal: "Total Quantity",
                    },

                    list_vue_html: {
                        frmlsPadr: "Default Formulas",
                        frmls: "Formulas",
                        cadastradas: "Registered",
                        ativos: "Assets",
                        desativados: "Disabled",
                        ambos: "Both",
                        buscPorInsumo: "Search for input",
                        tipoFrmlPadr: "Default Formula Type",
                        todos: "All",
                        produzir: "Produce",
                        concluirManipulacoes: "Finish Selecteds",
                        imprimirManipulacoes: "Print Selecteds",
                        replAtualizTds: "Replicate / Update All",
                        calcVlres: "Calculate Values",
                        visuFrmPadr: "View Default Formula",
                    },

                    ver_vue_html: {
                        dadosGera: "General Data",
                        descricao: "Description:",
                        prodFinal: "Final Product:",
                        frmFarmaceu: "Pharmaceutical Form:",
                        valDias: "Validity (days):",
                        tipoFrmlPadr: "Default Formula Type:",
                        vol: "Volume:",
                        unMedida: "Unit of Measure:",
                        procedimProducao: "Production Procedure:",
                        obs: "Note:",
                        itens: "Items",
                        prod: "Product",
                        tipoProd: "Product Type",
                        tipoCalc: "Calculation Type",
                        qtde: "Qty",
                        qtdePesag: "Weighing Qty",
                        umMedidaAbv: "Un. Measure ",
                        unManipul: "Un. Compounding",
                        fase: "Phase",
                    },
                },

                dinamizacao: {
                    edit_vue_html: {
                        fieldsetReferencia: "Product Reference",
                        fieldsetDinamizado: "Dynamized Product",
                        fieldsetVeículo: "Vehicle",
                        quantidade: "Amount",
                        unidadeMedida: "Unit of measurement",
                        kitEmbalagem: "Packing Kit",
                        validade: "Validity (days)",
                        estoque: "Stock",
                        produto: "Product",
                        lote: "Batch",
                        dinamizacao: "Dynamization",
                        metodo: "Method",
                        qtdNecessaria: "Quantity Needed for Dynamization",
                        gerarDinamizacao: "Generate Dynamization",
                        gerarLote: "Generate Batch",
                    },
                },

                pontuacaoManualDotz: {
                    list_vue_html: {
                        pontuacaoManual: "Manual Score",
                        pontuacoesManuais: "Manual Scores",
                        gerarPontuacao: "Generate scores",
                        enviarPontuacao: "Send scores",
                    },
                },

                formulapadraomanipulacao: {
                    edit_vue_html: {
                        frmlPadrGerarManipu: "Default Formula - Generate Compounding",
                        frmlPadr: "Default Formula",
                        gerarManipulacao: "Generate Compounding",
                        gerarOrdemManipu: "Generate Compounding Order",
                        sair: "Leave",
                        manipu: "Compounding",
                    },

                    item_vue_html: {
                        ocorrDiverg:
                            "Compounding successfully generated, but divergences have occurred, check them using the view Compounding button next to its code.",
                        manipuNaoGerada:
                            "Compounding cannot be created, check the registration of the default formula to identify the problem.",
                        codManipu: "Compounding Code:",
                        visuManipu: "View Compounding",
                        volQtd: "Volume / Quantity:",
                        valDias: "Validity (days):",
                        itens: "Items:",
                        estq: "Stock",
                        qtd: "Quantity",
                        totalProduzir: "Total Produce",
                        confEstqEQntd: "Check stock and adjust quantities and lots when necessary",
                        printOrdem: "Print Order",
                        printRotulo: "Print Label",
                        naoPermiteQuantidadeDecimal:
                            "Formula with Capsule or Packaging items do not allow decimal quantity",
                        vlrSeraAtualizCustoProd:
                            "If the option is selected, the cost value of the final product will be changed.",
                        atualizarCustosProduto: "Update Product Cost",
                    },
                },

                formulapadraovalorproduto: {
                    list_vue_html: {
                        calcVlrsProdsFrmlPdr: "Calculation of Default Formula Product Values",
                        frmlsRecalcs: "Recalculated Formulas",
                        recalcDeVlrProd: "Product Value Recalculation",
                        tdsRegisRecalcs: "All Recalculated Records",
                        apenasComDifVlr: "Only with Differences in Calculated Values",
                        apenasCustMaiorVlr: "Only Calculated Cost greater than the current value",
                        atualiz: "Update",
                    },
                },

                fornecedor: {
                    edit_vue_html: {
                        fornecedorEdit: "Supplier - Edition",
                        dadosGerais: "General Data",
                        tipo: "Type",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. State",
                        contribuinteEstadual: "State Taxpayer",
                        documentoEstrangeiro: "Foreign document",
                        exterior: "Foreign",
                        nomeFants: "Fantasy Name",
                        nome: "Name",
                        cntGerencialPdr: "Default Management Account",
                        razaoSocial: "Social Reason",
                        localiz: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        compl: "Complement",
                        contato: "Contact",
                        tel: "Telephone",
                        cel: "Phone",
                        fax: "Fax",
                        homepg: "Homepage",
                        telContato: "Telephone Contact",
                    },

                    list_vue_html: {
                        fornecedores: "Suppliers",
                        cadastrados: "Registered",
                    },
                },

                franquia: {
                    edit_vue_html: {
                        franquiaEdit: "Franchise - Edition",
                        dadosGerais: "General Data",
                        mapa: "MAPA",
                        veterinarioMapa: "MAPA veterinarian",
                        veterinarioMapaCRMV: "MAPA veterinarian CRMV",
                        autorizacaoMapa: "MAPA authorization",
                        autorizacaoAnvisa: "ANVISA authorization",
                        nomeFantasia: "Fantasy Name",
                        razaoSocial: "Social Reason",
                        cnpj: "CNPJ",
                        ie: "Insc. State",
                        im: "Insc. Municipal",
                        nomeFarmaceu: "Pharmaceutical Name",
                        conselhoReg: "Regional Council",
                        nroCR: "CR number",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        areaAtuac: "Practice Area",
                        cpfSNGPC: "CPF SNGPC",
                        autorSanitaria: "Sanitary Authority",
                        licenca: "License",
                        natOperacao: "Nature of Operation",
                        optanteSN: "Optante pelo Simples Nacional",
                        incentivCult: "Cultural Incentivator",
                        rede: "Network",
                        tipoFranquia: "Franchise Type",
                        localiz: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        lograd: "Address",
                        complemento: "Complement",
                        nro: "Number",
                        bairro: "Neighborhood",
                        contato: "Contact",
                        tel: "Telephone",
                        cel: "Phone",
                        modulos: "Modules",
                        atendimentoWpp: "Customer Service (WhatsApp)",
                        chaveAPI: "API Key",
                        diasArmaz: "Storage Days",
                        excluirDados: "Delete Data",
                        configuracoes: "Settings",
                        matriz: "Headquarters",
                        moduloApp: "APP Module",
                        moduloCompras: "Purchasing Module",
                        moduloWhatsApp: "WhatsApp Module",
                        moduloAutomatizacaoContinuos: "Continuous Automation Module",
                        whatsapp: "Whatsapp",
                        complementoWhatsapp: "Whatsapp Budget Add-on",
                        validadeLicensaSanitaria: "Sanitary License Validation",
                    },

                    list_vue_html: {
                        franquias: "Franchises",
                        cadastradas: "Registered",
                    },
                },

                grupo: {
                    edit_vue_html: {
                        grupoEdit: "Group - Edition",
                        descr: "Description",
                        ativo: "Active",
                        acoes: "Actions",
                        users: "Users",
                    },

                    list_vue_html: {
                        grupos: "Groups",
                        cadastrados: "Registered",
                        sem_permissao_excluir_grupo: "You do not have permission to delete this group!",
                    },
                },

                grupoproduto: {
                    edit_vue_html: {
                        grupoProdEdit: "Product Group - Edition",
                        descr: "Description",
                        tipoProd: "Product Type",
                        rotuloPadrao: "Standard Label",
                    },

                    list_vue_html: {
                        grupoProd: "Product Groups",
                        cadastrados: "Registered",
                    },
                },

                historicoenviosdotz: {
                    list_vue_html: {
                        title: "Dotz Submissions Historic",
                        enviados: "Submitted",
                        titulofiltroAvancado: "Advanced Filter",
                        filtroDataInicial: "Start Date",
                        filtroDataFinal: "End Date",
                        filtroPrescritor: "Prescriber",
                        filtroStatusEnvio: "Submission Status",
                        btnFiltrar: "Filter",
                    },
                },

                inutilizacaoNotaFiscal: {
                    edit_vue_html: {
                        inutilizacaoNotaFiscalEdit: "Invoice destruction - Edition",
                        ano: "Year",
                        modelo: "Model",
                        numeroInicial: "Initial Number",
                        numeroFinal: "Final Number",
                        serie: "Series",
                        justificativa: "Justification",
                        rejeicao: "Rejection",
                        inutilizar: "Destruction",
                    },

                    list_vue_html: {
                        inutilizacaoNotaFiscal: "Invoice destruction",
                        cadastrados: "Registered",
                    },
                },

                inventario: {
                    edit_vue_html: {
                        ajusteEstq: "Inventory Adjustment",
                        tipoInvent: "Inventory Type",
                        grupoProd: "Product Group",
                        prodLotes: "Products / Lots",
                        produtos: "Products",
                        status: "Status",
                        movimentacoes: "Movements",
                        iniciar: "Start",
                        finalizar: "Finalize",
                        ignoraLotesZerados: "Ignore empty batches",
                    },

                    list_vue_html: {
                        inventarios: "inventories",
                        gerados: "Generated",
                    },

                    produtoassociacao_vue_html: {
                        addNovo: "Add New",
                        cadProd: "Register Product",
                    },

                    produtolotes_vue_html: {
                        prod: "Product",
                        tipo: "Type",
                        editProd: "Edit Product",
                        novoProd: "New Product",
                        ftrCorrec: "Correction Factor",
                        fc: "C.F.",
                        qtdUnMedOrig: "Qty / Un.Med. Orig. ",
                        qtd: "Qty.",
                        unMed: "Un.Med",
                        qtdConvert: "Qty. Converted ",
                        custUnit: "Unit Cost",
                        custRefAtual: "Current Reference Cost",
                        dadosLote: "Batch Data",
                        descr: "Description",
                        dataFab: "Date of Manufacture",
                        dataVal: "Expiration Date",
                        precoMaxConsu: "Max. Consumer Price",
                        importSemLote: "Import Without Batch",
                        preenchLote: "Fill Batch",
                    },

                    produtos_vue_html: {
                        prodLotes: "Products / Lots",
                        proximo: "Next",
                        conclAlteAvancProxProd: "Complete current changes and proceed to the next divergent product",
                    },
                },

                kitembalagem: {
                    edit_vue_html: {
                        kitEmbalEdit: "Packaging Kit - Edition",
                        dadosGerais: "General Data",
                        franquia: "Franchise",
                        descr: "Description",
                        descrRotu: "Label Description",
                        unMed: "Unit of Measure",
                        volMax: "Maximum Volume",
                        ativo: "Active",
                        obs: "Observation",
                        frmFarmace: "Pharmaceutical Form",
                        frmsFarmaces: "Pharmaceutical Forms",
                        prioriz: "Prioritize",
                        priorizEssaOpc:
                            "When selected to Prioritize and in use Pharmaceutical Form have several kits with the same volume, the system will prioritize the one marked with this option",
                        prod: "Product",
                        tipoProd: "Product Type",
                        subGrupProd: "Product SubGroup",
                        prods: "Products",
                        qtd: "Quantity",
                        padrao: "Default",
                        prodPadrSeraoASelec:
                            "Products marked as default will be automatically added when handling this packaging kit",
                        rotuloPadrao: "Standard Label",
                        frmCapacidadeEmbalagemCapsulas: "Packaging Capacity (Capsules)",
                        capsula: "Capsule",
                        qtdMaximaBiscoitos: "Maximum Amount of Cookies",
                    },

                    list_vue_html: {
                        kitsEmbal: "Packaging Kits",
                        cadastrados: "Registered",
                    },
                },

                listacontrolado: {
                    edit_vue_html: {
                        lstControladosEdit: "List of Controllers - Edition",
                        descricao: "Description",
                        codigo: "Code",
                        clssTerapeu: "Therapeutic Class",
                    },

                    list_vue_html: {
                        lstControlados: "Controlled Lists",
                        cadastrados: "Registered",
                    },
                },

                livroRegistroEspecifico: {
                    view_vue_html: {
                        livroRegistroEspecificoGeracao: "Specific Registration Book",
                        ano: "Year",
                        tipoPeriodo: "Period Type",
                        tipoMedicamentoControlado: "Type Of Controlled Drug",
                    },
                },

                localentrega: {
                    list_vue_html: {
                        titulo: "Delivery Locations",
                        cadastrados: "Registered",
                        taxa: "Tax",
                    },

                    edit_vue_html: {
                        titulo: "Delivery Locations - Edition",
                        codigo: "Code",
                        descricao: "Description",
                        taxa: "Tax",
                        transportadora: "Shipping Company",
                    },
                },

                manipuladores: {
                    view_vue_html: {
                        manipuladores: "Handlers",
                    },
                },

                meta: {
                    edit_vue_html: {
                        metaEdit: "Goal - Edition",
                        mes: "Month",
                        ano: "Year",
                        vlrTotal: "Total Amount",
                        diasUteis: "Working Days",
                        vlrDiario: "Daily Value",
                    },

                    list_vue_html: {
                        metas: "Goals",
                        cadastradas: "Registered",
                    },
                },

                metodopagamento: {
                    edit_vue_html: {
                        mtdsPgtoEdit: "Payment Methods - Edition",
                        descricao: "Description",
                        ativo: "Active",
                        nroParcels: "Number of Plots",
                        intervPrimParce: "Interval (Days) up to 1st installment",
                        intervEntreParce: "Interval (Days) between Plots",
                    },

                    list_vue_html: {
                        mtdsPgto: "Payment Methods",
                        cadastrados: "Registered",
                    },
                },

                molde: {
                    edit_vue_html: {
                        moldeEdit: "Mold - Edition",
                        dadosGerais: "General Data",
                        descricao: "Description",
                        ativo: "Active",
                        unMedida: "Unit of Measure",
                        volInterno: "Internal volume",
                        volTotal: "Total Volume",
                        volMaxPrinciAtivo: "Max Volume Active Principle",
                        volMaxPrinciAtivoFito: "Max Volume Phytotherapeutic Active Principle",
                        obs: "Observation",
                        frmFarmaceu: "Pharmaceutical Form",
                        frmsFarmaceus: "Pharmaceutical Forms",
                        qspPadrao: "Standard QSP product",
                    },

                    list_vue_html: {
                        moldes: "Molds",
                        cadastrados: "Registered",
                    },
                },

                motivocancelamento: {
                    edit_vue_html: {
                        mtvCancelEdit: "Reason for Cancellation - Edition",
                        descricao: "Description",
                        exigirObs: "Require Observation",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        mtvCancelamento: "Reasons for Cancellation",
                        cadastrados: "Registered",
                    },
                },

                motivoEtapaPCP: {
                    edit_vue_html: {
                        motivoEtapaPCPEdit: "Reason Stage PCP - Edition",
                        descricao: "Description",
                    },

                    list_vue_html: {
                        motivoEtapaPCP: "Reason Stage PCP",
                        cadastrados: "Registered",
                    },
                },

                motivorejeicao: {
                    edit_vue_html: {
                        mtvRejeicaoEdit: "Reason for Rejection - Editing",
                    },

                    list_vue_html: {
                        mtvRejeicao: "Reasons for Rejection",
                    },
                },

                motivomovimentacao: {
                    edit_vue_html: {
                        motivoMovimentacaoEdit: "Reason Stock Movement - Edition",
                        descricao: "Description",
                    },

                    list_vue_html: {
                        motivomovimentacao: "Reason Stock Movement",
                        cadastrados: "Registered",
                    },
                },

                movimentacaoconta: {
                    edit_vue_html: {
                        movimentacContaEdit: "Account Transaction - Edition",
                        tipoMovimenta: "Movement Type",
                        contaGerenc: "Managerial Account",
                        dataPgto: "Payment Date",
                        frmRecebimento: "Receipt Form",
                        frmPgto: "Payment Method",
                        contaCorrt: "Current Account",
                        vlrRecebido: "Amount Received",
                        vlrPago: "Amount Paid",
                        descricao: "Description",
                        obs: "Observation",
                    },

                    list_vue_html: {
                        movimentacConta: "Account Transactions",
                        cadastradas: "Registered",
                    },
                },

                movimentacaoestoque: {
                    edit_vue_html: {
                        movimentaEstqEdit: "Inventory Movement - Edition",
                        dadosGera: "General Data",
                        tipoMovimenta: "Movement Type",
                        dataMovimenta: "Moving Date",
                        estqOrigem: "Origin Stock",
                        estqDestino: "Destination Stock",
                        motivoMovimentacaoEstoque: "Reason for movement",
                        produtos: "Products",
                        reverterMovimentacao: "Reverse stock movement",
                    },

                    item_vue_html: {
                        dadosLote: "Batch Data",
                        lote: "Lot",
                        editProdLote: "Edit Product Batch",
                        novoProdLote: "New Product Batch",
                        produto: "Product",
                        qtd: "Quantity",
                        vlrUnitario: "Unit Value",
                        vlrTotal: "Total Value",
                        qtdAtual: "Current quantity",
                        dataFab: "Manufacture Date",
                        dataVal: "Expiration Date",
                        obs: "Observation",
                    },

                    list_vue_html: {
                        movimentaEstq: "Stock movement",
                        cadastradas: "Registered",
                        dataInicial: "Initial date",
                        dataFinal: "Final date",
                        tipoMov: "Type of Movement",
                        estoque: "Inventory",
                    },
                },

                naturezaoperacao: {
                    edit_vue_html: {
                        naturezaOpEdit: "Nature of Operation - Edition",
                        descricao: "Description",
                        codigo: "Code",
                        tipoMovimento: "Movement type",
                        dentroEstado: "Inside State",
                        movimentaEstq: "Move Stock",
                        usaConfiguracaoFiscal: "Use Tax Setup",
                        observacoes: "Observations",
                        transferencia: "Transfer",
                        simplesRemessa: "Simple Shipping",
                        movimentaEstqToolTip:
                            "When checking this field, the system will automatically consider stock movement when issuing a standalone invoice after the invoice is issued",
                    },

                    list_vue_html: {
                        naturezaOp: "Nature of Operation",
                        cadastradas: "Registered",
                    },
                },

                nbm: {
                    edit_vue_html: {
                        nbmEdit: "NBM - Edition",
                        descricao: "Description",
                        codigo: "Code",
                    },

                    list_vue_html: {
                        nbms: "NBMs",
                        cadastrados: "Registered",
                    },
                },

                ncm: {
                    edit_vue_html: {
                        ncmEdit: "NCM - Edition",
                        descricao: "Description",
                        codigo: "Code",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        ncms: "NCMs",
                        cadastrados: "Registered",
                    },
                },

                setor: {
                    edit_vue_html: {
                        setorEdit: "Sector - Edit",
                        descricao: "Description",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        setores: "Sectors",
                        cadastrados: "Registered",
                    },
                },

                operadoraCartoes: {
                    edit_vue_html: {
                        operadoraCardsEdit: "Card Operator - Edition",
                        dadosGerais: "General Data",
                        tipo: "Type",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. State",
                        nomeFantasia: "Fantasy Name",
                        nome: "Name",
                        razaoSocial: "Social Reason",
                        localizacao: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        complemento: "Complement",
                        contato: "Contact",
                        tel: "Telephone",
                        cel: "Phone",
                        telContato: "Telephone Contact",
                    },

                    list_vue_html: {
                        operadoraCards: "Card Operator",
                        cadastradas: "Registered",
                    },
                },

                paciente: {
                    edit_vue_html: {
                        pacienteEdit: "Patient - Edition",
                        nome: "Name",
                        nomeRotulo: "Label Name",
                        nomeUtilizRotulo: "Name that will be used on the label for patient identification",
                        ativo: "Active",
                        dataNasc: "Date of Birth",
                        genero: "Gender",
                        pesoKg: "Weight (Kg)",
                        especie: "Species",
                        raca: "Breed",
                        cliente: "Customer",
                        editCliente: "Edit Client",
                        novoCliente: "New Customer",
                        obs: "Observation",
                        pacienteCliente: "Client Patient",
                        produto: "Product",
                        alergias: "Allergies",
                        condicao: "Condition",
                    },

                    list_vue_html: {
                        paciente: "Patient",
                        pacientes: "Patients",
                        cadastrados: "Registered",
                        visuPaciente: "View Patient",
                    },

                    ver_vue_html: {
                        nome: "Name:",
                        nomeRotulo: "Label Name",
                        dtNasc: "Date of Birth:",
                        genero: "Gender:",
                        especie: "Species:",
                        peso: "Weight:",
                        cliente: "Customer:",
                    },
                },

                pcp: {
                    pesquisar: "Search",
                    manipulacaoOrdem: {
                        pcpEdit: "PCP Compounding Order - Edition",
                        manipulacaoOrdemCodigo: "Compounding Order Code",
                        manipulacaoOrdemPCP: "Compounding Order",
                        manipulacao: "Compounding",
                        paciente: "Patient",
                        codigoVenda: "Sale Code",
                        pesquisaAvancada: "Advanced Search",
                    },
                    venda: {
                        pcpEdit: "PCP Sale - Edition",
                        vendaCodigo: "Sale Code",
                        vendaPCP: "Sale",
                        cliente: "Client",
                        valorTotal: "Total Sales Value",
                        quantidadeItens: "Quantity Items",
                    },
                    etapas: {
                        etapas: "Stages",
                        etapaAtual: "Now Stage",
                        etapaDestino: "Destination Stage",
                        motivoEtapaPCP: "Reason",
                        observacao: "Observation",
                        dataInicio: "Start Date",
                        dataFim: "End Date",
                    },

                    ConfiguracaoEtapaPCP: {
                        configuracaoEtapaPCP: "PCP Step Configuration",
                        etapaGradeVenda: "PCP sales grid stage",
                        etapaGradePendente: "Pending PCP Grid Stage",
                    },
                },

                pdv: {
                    edit_vue_html: {
                        pdvEdit: "Pdv - Edition",
                        descricao: "Description",
                        saldoInicial: "Initial Balance",
                        contaCorrente: "Current Account",
                        gerarSaldoInicial: "Generate (Financial) Initial Balance",
                        formaRecebimento: "Transfer Receipt Form",
                        contaCorrenteInterna: "Current accounts for linking with PDV can only be of the internal type",
                    },

                    list_vue_html: {
                        pdvs: "PDVs",
                        cadastrados: "Registered",
                    },
                },

                posologia: {
                    edit_vue_html: {
                        posologiaEdit: "Posology - Edition",
                        descricao: "Description",
                        dosesDia: "Doses per Day",
                        dosesSemana: "Doses per Week",
                        dosesMes: "Doses per Month",
                        duracaoEstimDias: "Estimated duration (days)",
                        padrao: "Default",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        posologias: "Posologies",
                        cadastradas: "Registered",
                    },
                },

                prescritor: {
                    edit_vue_html: {
                        precritorEdit: "Prescriber - Edition",
                        dadosGerais: "General Data",
                        cod: "Cod.",
                        numRegProfis: "Professional Registry",
                        cpf: "CPF",
                        rg: "RG",
                        ativo: "Active",
                        conselhoRegional: "Regional Council",
                        ufConselhoRegional: "UF Regional Council",
                        nome: "Name",
                        dtNasc: "Date of Birth",
                        genero: "Gender",
                        comissao: "Commission",
                        obs: "Observation",
                        especialidSelec: "Selected Specialties",
                        especialid: "Specialty",
                        localiz: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        complement: "Complement",
                        contato: "Contact",
                        tel: "Telephone",
                        cel: "Phone",
                        comissionamento: {
                            titulo: "Comissionamento",
                            inicioFaixa: "Início Faixa",
                            fimFaixa: "Fim Faixa",
                            porcentagemComissao: "Porcentagem Comissão",
                        },
                        visitadoresNomes: "Visitors",
                        classPrescritor: "Prescriber Rating",
                        sipeAgro: "SipeAgro",
                    },

                    list_vue_html: {
                        prescritores: "Prescribers",
                        cadastrados: "Registered",
                        visuPrescritor: "View Prescriber",
                    },

                    ver_vue_html: {
                        dadosGerais: "General Data",
                        codigo: "Code:",
                        numeroRegistro: "Registry Number:",
                        cpf: "CPF:",
                        rg: "RG:",
                        nome: "Name:",
                        dtNasc: "Date of Birth:",
                        genero: "Gender:",
                        obs: "Note:",
                        localiz: "Location",
                        cep: "zip code",
                        estado: "state:",
                        cidade: "City:",
                        endereco: "Address:",
                        nro: "Number:",
                        bairro: "Neighborhood:",
                        complement: "Complement:",
                        contato: "Contact",
                        tel: "Telephone:",
                        cel: "Phone:",
                        especialids: "Specialties",
                        especialid: "Specialty:",
                        tipoConselhoRegional: "Regional Council Type:",
                        ufConselhoRegional: "State of Regional Council:",
                    },
                },

                curvaABC: {
                    edit_vue_html: {
                        dataInicio: "Initial date",
                        dataFim: "Final date",
                        considerar: "To consider",
                        curvaABCProdutos: "ABC Curve Products",
                        curvaABCPrescritores: "ABC Curve Prescribers",
                        atualizar: "Update",
                        sucesso: "ABC curve updated successfully!",
                    },
                },

                produto: {
                    edit_vue_html: {
                        prodEdit: "Product - Edition",
                        dadosGerais: "General Data",
                        codigoFranquia: "Franchise Code",
                        codigoRede: "Network Code",
                        franquia: "Franchise",
                        descricao: "Description",
                        ativo: "Active",
                        grupAtivo: "Product Group",
                        subgrupoProd: "Product SubGroup",
                        cores: "Colors",
                        codBarras: "Barcode",
                        tipoCalc: "Calculation Type",
                        unMedManipu: "Compounding Measure Unit",
                        unMedEstq: "Inventory Measurement Unit",
                        naoControlEstq: "Disable Inventory Control",
                        estqMin: "Minimum Inventory",
                        estqMax: "Maximum Stock",
                        posologia: "Posology",
                        editPosologia: "Edit Posology",
                        novaPosologia: "New Posology",
                        tipoObs: "Observation Type",
                        obs: "Observation",
                        observacoes: "Observations",
                        financeiro: "Financial",
                        vlrCusto: "Cost Value",
                        custoRefe: "Reference Cost",
                        custoMed: "Average Cost",
                        markupPorcent: "Markup (%)",
                        markupPadrao: "Markup franchise",
                        markupPadraoToolTip:
                            "When selecting Franchise Markup, the system will consider the standard Markup configured in the franchise to calculate the sales value",
                        markup: "Markup",
                        vlrVenda: "Sales Value",
                        nbmSH: "NBM / SH",
                        ncmSH: "NCM / SH",
                        cest: "CEST",
                        visuLogVlrs: "View Log Values",
                        manipu: "Compounding",
                        descrRotulo: "Description Description",
                        excip: "Excipient",
                        densidadeGml: "Density (g / ml)",
                        diluicao: "Dilution",
                        margemSegur: "Safety Margin",
                        ftrCorrecao: "Correction Factor",
                        ftrUI: "UI Factor",
                        ftrUFC: "UFC Factor",
                        ftrUTR: "UTR Factor",
                        ftrEquivalencia: "Equivalence Factor",
                        ftrCorrecMolde: "Mold Correction Factor",
                        doseMax: "Maximum dose",
                        doseMaxDiaria: "Maximum Daily Dose",
                        doseMaxPorcent: "Maximum Dose (%)",
                        dcb: "DCB",
                        dci: "DCI",
                        listControlados: "List of Controlled",
                        cas: "CAS",
                        pesoMolecular: "Molecular weight",
                        formulaMolecular: "Molecular formula",
                        prodDiluido: "Diluted Product",
                        prodPuro: "Produto puro que originou o produto diluído",
                        doseMaxProdDiluido: "Maximum Diluted Product Dose",
                        siproquim: "SIPROQUIM",
                        policiaCivil: "Civil Police",
                        codigoSiproquim: "SIPROQUIM Code",
                        indicaLimitDose:
                            "Maximum diluted product dose indicates the dose limit at which the system will suggest switching from pure to diluted assets",
                        embalagem: "Packing",
                        volMaxMl: "Maximum Volume (ml)",
                        kitEmbalagem: "Packing Kit",
                        validades: "Validity",
                        validadeDias: "Validity in Days",
                        capsula: "Capsule",
                        tipoCapsula: "Capsule Type",
                        gastrorre: "Gastroresistant",
                        frmFarmaceu: "Pharmaceutical Form",
                        frmFarmaceuBloqueada: "Blocked Pharmaceutical Forms",
                        libBloq: "Released",
                        associacoes: "Associations",
                        produto: "Product",
                        desmembrar: "dismember",
                        tipoCalculoAssociacao: "Calculation Type",
                        percentual: "Percentage",
                        sinonimos: "Synonyms",
                        descricaoRotulo: "Label description",
                        fatorEquivalencia: "Equivalence factor",
                        qtdTotal: "Total Quantity:",
                        visuLote: "View Batch",
                        incompatibilidade: "Incompatible Products",
                        embalagemIncompativeis: "Incompatible Packaging",
                        produtoRefrigerado: "Chilled Product",
                        produtoIncompativel: "Incompatible Product",
                        kitEmbalagemIncompativel: "Incompatible Packaging Kit",
                        formaFarmIncompativel: "Incompatible Pharmaceutical Form",
                        lote: "Lot",
                        fiscal: "Supervisor",
                        aliquotasInterestaduais: "Interstate Rates",
                        uf: "UF",
                        aliquotaInterestadual: "Rate",
                        verAliquotasInterestaduais: "Show rates by UF",
                        fichaTecnica: "Ficha técnica",
                        usaMarkupDinamico: "Use dynamic markup",
                        markupDinamico: "Dynamic markup",
                        quantidadeLimite: "Limit quantity",
                        acao: "Action",
                        trocarUnidadeMedidaEstoque: "Change unit measure stock",
                        trocar: "Change",
                        unidadeMedidaEstoqueIdOrigem: "Unidade Medida Estoque Atual",
                        unidadeMedidaEstoqueIdDestino: "Unit Measure Inventory Destination",
                        corPadraoCapsula: "Capsule default color",
                        naoUtilizarExcipiente: "Do not use excipient",
                        corManipulacao: "Identification color in handling",
                        classProduto: "Product Classification",
                        fitoterapico: "Phytotherapeutic",
                        descricaoFiscal: "Tax Description",
                    },

                    list_vue_html: {
                        produtos: "Products",
                        cadastrados: "Registered",
                        grupProduto: "Product Group",
                        subgrupProd: "Product SubGroup",
                        corrigirVlrVenda: "Correct Value of Sale",
                        situacaoProduto: "Product status",
                    },

                    verLote_vue_html: {
                        dadosGerais: "General Data",
                        descricao: "Description",
                        fornecedor: "Supplier:",
                        produto: "Product:",
                        qtdAtual: "Current quantity:",
                        dtFabricacao: "Manufacture Date:",
                        dtValidade: "Expiration Date:",
                        ftrCorrecao: "Correction Factor:",
                        densidade: "Density:",
                        fatorUI: "UI Factor:",
                        fatorUFC: "UFC Factor:",
                        fatorUTR: "UTR Factor:",
                        vlrCusto: "Value Cost:",
                        obs: "Note:",
                    },

                    verProduto_vue_html: {
                        dadosGerais: "General Data",
                        descricao: "Description:",
                        descrRotulo: "Description Description:",
                        unMedManipu: "Unit Measure Compounding:",
                        unMedEstq: "Unit Measure Stock:",
                        controlaEstq: "Controls Inventory:",
                        estqMin: "Minimum Inventory:",
                        obs: "Note:",
                        financeiro: "Financial",
                        vlrCusto: "Cost Value:",
                        custoRefe: "Reference Cost:",
                        custoMedio: "Average cost:",
                        markupPorcent: "Markup (%):",
                        vlrVenda: "Sell Value:",
                        manipulacao: "Compounding",
                        densidadeGml: "Density (g/ml):",
                        ftrCorrec: "Correction Factor:",
                        ftrUI: "UI Factor:",
                        ftrUFC: "UFC Factor:",
                        ftrUTR: "UTR Factor:",
                        doseMax: "Maximum dose:",
                        doseMaxDia: "Maximum Daily Dose:",
                        doseMaxPorcent: "Maximum Dose (%):",
                        embalagem: "Packing",
                        volMaxMl: "Maximum Volume (ml):",
                        associacoes: "Associations",
                        sinonimos: "Synonyms",
                        qtdTotal: "Total Quantity:",
                        visuLote: "View Batch",
                        capsulaCor: "Colors capsules",
                        incompatibilidades: "Incompatibilities",
                    },
                },

                produtodcb: {
                    edit_vue_html: {
                        dcbEdit: "DCB - Edition",
                        descricao: "Description",
                        codigo: "Code",
                        codigoCAS: "CAS Code",
                    },

                    list_vue_html: {
                        denomComunsBra: "Brazilian Common Denominations",
                        dcbs: "DCBs",
                        cadastradas: "Registered",
                    },
                },

                produtodci: {
                    edit_vue_html: {
                        dciEdit: "DCI - Edition",
                        descricao: "Description",
                        codigo: "Code",
                    },

                    list_vue_html: {
                        denomComunsInternac: "International Common Names",
                        dcis: "DCIs",
                        cadastradas: "Registered",
                    },
                },

                produtologfinanceiro: {
                    list_vue_html: {
                        logFinanceprod: "Product Finance Log",
                        logProdutos: "Product Log",
                        alterados: "Changed",
                        valoresAtuais: "Current values",
                        valorCusto: "Cost Value",
                        custoRefe: "Reference Cost",
                        custoMedio: "Average cost",
                        markupPorce: "Markup (%)",
                        markup: "markup",
                        vlrsVenda: "Sales Value",
                        vlrsLogAnterior: "Previous Log Values",
                        logAlteCamposFinanceProd: "Product Financial Field Change Log",
                        dataUltimaAlteracao: "Date of last change",
                        usuarioUltimaAlteracao: "User last changed",
                    },
                },

                produtolote: {
                    edit_vue_html: {
                        loteProdEdit: "Product Batch - Edition",
                        descricao: "Description",
                        fornecedor: "Supplier",
                        produto: "Product",
                        qtdAtual: "Current quantity",
                        quantidadeComprometida: "Committed Quantity",
                        dataFab: "Manufacture Date",
                        dataVal: "Expiration Date",
                        sequencialCompra: "Purchase sequence",
                        ftrCorrecao: "Correction Factor",
                        densidadeGml: "Density (g/ml)",
                        fatorUI: "UI Factor",
                        fatorUFC: "UFC Factor",
                        fatorUTR: "UTR Factor",
                        vlrCusto: "Cost Value",
                        obs: "Observation",
                        preenchLote: "Fill Batch",
                        imprimirRotulo: "Print Label",
                        estoqueComprometido: "Sales Compromising Lot Stock",
                        movimentosEstoque: "Batch Stock Movement",
                        campoNull:
                            "If the value is informed in this field even if 0, it will be considered in the manipulation. If you want to use the value registered in the product, leave the field without any value",
                        conservacao: "Conservation",
                        statusProdutoLote: "Batch Status",
                        origemProdutoLote: "Batch Origin",
                        descricaoFornecedor: "Supplier's batch",
                        fracionar: "Fractionate",
                        imprimirOrdemFracionamento: "Print fractionation order",
                        lotesFracionados: "Fractionated Lots",
                        atualizarDadosOrdemFracionamento: "Atualização de dados da Ordem de Fracionamento",
                        estoques: "Inventory",
                        dadosNotaEntradaLote: "Batch entry note data",
                        retornarLoteParaFracionamento: "Return batch for fractionation",
                        anexarLote: "Attach file to Batch",
                        uploadAnexo: "Upload Attachment",
                        nomeArqAnexoLote: "Attachment file name",
                    },

                    list_vue_html: {
                        lotesProduto: "Product Batches",
                        cadastrados: "Registered",
                        statusProdutoLote: "Batch Status",
                        origemProdutoLote: "Batch Origin",
                        tipoEstoque: "Inventory",
                        ignorarZerados: "Ignore Zeros",
                    },

                    atualizarStatus_vue_html: {
                        atualizar: "Update",
                        newStatusProdutoLote: "Batch Status",
                        atualizadoSucesso: "Batch Status update with sucess",
                    },

                    fracionamento_vue_html: {
                        quantidade: "Quantity",
                        quantidadeEmbalagem: "Quantity kit Packing",
                        volume: "Volume",
                        unidadeMedida: "Unit of Measure",
                        total: "Total",
                        quantidadeFracionada: "Fractional Amount",
                        quantidadePerda: "Quantity of Loss",
                        quantidadeTotal: "Total Quantity",
                        fracionar: "Fractionate",
                        sucessoFracionamento: "Fractionation generated successfully",
                        temperatura: "Temperature",
                        umidade: "Humidity",
                        salaFracionamento: "Fractionation Room",
                        quantidadeEfetivamenteFracionada: "Effectively fractionated amount",
                        quantidadeRetiradaAmostragem: "Quantity Withdrawn for Sampling",
                        horaInicio: "Start Date",
                        horaFim: "End Date",
                        embalagensNaoUtilizadas: "Unused packaging",
                        observacao: "Observation",
                        fracionamento: "Fractionation",
                    },

                    fracionamentoKitEmbalagem_vue_html: {
                        kitEmbalagem: "Packing Kit",
                        embalagem: "Packing",
                        embalagemLote: "Batch",
                        quantidade: "Total Quantity",
                    },

                    dadosNotaEntradaLote_vue_html: {
                        dataInclusaoNota: "Note inclusion date",
                        dataEmissaoNota: "Note issue date",
                        numeroNota: "Number",
                        serieNota: "Series",
                        quantidadeItemRespectivoLotea: "Quantity of the item corresponding to the batch",
                        notaDeCompraNaoEncontradaNoSitema: "Lot purchase note not found in the system",
                    },
                },

                racaAnimal: {
                    list_vue_html: {
                        titulo: "Animal Breeds",
                        cadastradas: "Registered",
                    },

                    edit_vue_html: {
                        titulo: "Registration of Animal Breeds",
                        descricao: "Description",
                        especieAnimal: "Animal Specie",
                    },
                },

                recebimentoParcelado: {
                    edit_vue_html: {
                        recebimentoParceladoEdit: "Installment Receipt Methods - Edition",
                        numeroParcelas: "Number of installments",
                        addParcela: "Add installments",
                        descricao: "Description",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        recebimentoParcelado: "Installment Receipt Methods",
                        cadastrados: "Registered",
                    },
                },

                recompensa: {
                    edit_vue_html: {
                        recompensaEdit: "Reward - Edition",
                        premio: "Prize",
                        pontosNecess: "Points Needed",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        campanhaRecompensas: "Campaign: Rewards",
                        recompensas: "Rewards",
                        cadastradas: "Registered",
                    },
                },

                rede: {
                    edit_vue_html: {
                        redeEdit: "Network - Edition",
                        dadosGerais: "General Data",
                        nomeFantasia: "Fantasy Name",
                        razaoSocial: "Social Reason",
                        cnpj: "CNPJ",
                        ie: "Insc. State",
                        im: "Insc. Municipal",
                        respTecnico: "Technical Responsible",
                        conseRegional: "Regional Council",
                        nroCR: "CR number",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        areaAtuacao: "Area of ​​Operation",
                        naturOperacao: "Nature of Operation",
                        optanteSimplNacio: "Opting for Simples Nacional",
                        incentivCultuta: "Cultural Incentivator",
                        localizacao: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        lograd: "Backyard",
                        complemen: "Complement",
                        nro: "Number",
                        bairro: "Neighborhood",
                        contato: "Contact",
                        tel: "Telephone",
                        cel: "Phone",
                        gerarBaseModel: "Generate Model base",
                        usoDoAppPor: "Use of the App by",
                    },

                    list_vue_html: {
                        redes: "Networks",
                        cadastradas: "Registered",
                    },
                },

                rotulopersonalizado: {
                    edit_vue_html: {
                        rotuloEdit: "Rotulo - Edition",
                        name: "Name",
                        tipoRotulo: "Label Type",
                        rotuloUtilizar: "Label to use",
                        largura: "Width",
                        altura: "Height",
                        espacamentoSuperior: "Superior Spacing",
                        espacHoriz: "Horizontal Spacing",
                        posicQRCode: "QR Code/Bar Code Position",
                        larguraCode: "Width of QR Code/Bar Code",
                        alturaCode: "Height of QR Code/Bar Code",
                        posLogo: "Position Logo",
                        largLogo: "Logo Width",
                        altLogo: "Altura del logo",
                        printDadosFranq: "Print Franchise Data",
                        printDadosFranqNegrito: "Bold Franchise Data",
                        dadosFranquiaRespTec: "Franchise data and technical manager",
                        fonteDadosFranq: "Source data Franchise",
                        alinhamentoDadosDaFranquia: "Franchise Data Alignment",
                        quantidadeColunas: "Maximum number of columns",
                        fonteRotulo: "Rotulo font",
                        paddingLine: "Line spacing",
                        camposImpressao: "Print Fields",
                        formatoImpressao: "Print Format",
                        imprimirResponsavelTecnico: "Print Technical Responsible",
                    },

                    imprimirRotuloComModal_vue_html: {
                        rotuloPersonalizado: "Personalized label",
                    },

                    list_vue_html: {
                        rotulos: "Labels",
                        cadastrados: "Registered",
                    },
                },

                separacao: {
                    edit_vue_html: {
                        separacaoVenda: "Separation of Sale ",
                        concluir: "Finish",
                        botaoCodigo: "Separate",
                        labelCodigo: "Batch code",
                        title: "Enter batch code",
                    },

                    list_vue_html: {
                        fracionamento: "fractionation",
                        vendas: "Sales",
                        cadastrados: "Registered",
                        setor: "Sectors",
                    },
                },

                servico: {
                    edit_vue_html: {
                        servicoEdit: "Service - Edition",
                        descricao: "Description",
                        servico: "Service",
                        detalheServico: "Service Detail",
                        atividade: "Activity",
                        utilizCodTributPrefeitura: "Use the service tax code provided by the city hall",
                        codServicLC: "Cod. LC 116/2003 Service ",
                        utilizCodLCPrefeitura: "Use the LC item code provided by the city hall",
                        aliquota: "Aliquot",
                        issRetido: "ISS Retained",
                    },

                    list_vue_html: {
                        servicos: "Services",
                        cadastrados: "Registered",
                    },
                },

                sngpcManual: {
                    edit_vue_html: {
                        formularioSngpcRelat: "SNGPC FORM - REPORT",
                        identificacao: "Identification",
                        razaoSocial: "Social Reason",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        cidade: "City",
                        tel: "Telephone",
                        cnpj: "CNPJ",
                        nroAutorizAnvisa: "ANVISA Authorization No.",
                        nroAutorizMapa: "MAPA Authorization No.",
                        periodo: "Period",
                        tipoBalanco: "Balance Type",
                        periodicBalanco: "Balance Sheet Periodicity",
                        ano: "Year",
                        regiao: "Region",
                        respTecnico: "Technical Responsible",
                        nroCR: "CR number",
                        crf: "CRF",
                        crmv: "CRMV",
                        crm: "CRM",
                        data: "Date",
                        download: "Download",
                    },
                },

                tela: {
                    edit_vue_html: {
                        telaEdit: "Screen - Edition",
                        chaveTraducao: "Translation Key",
                        rota: "Route",
                        iconeMenu: "Menu Icon",
                        rotaAcaoRapida: "Fast Action Route",
                        iconeAcaoRapida: "Fast Action Icon",
                        controller: "Controller",
                        ordem: "Order",
                        pai: "Father",
                        ocultarMenu: "Hide Menu",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        telas: "Screens",
                        cadastradas: "Registered",
                    },
                },

                telaacao: {
                    edit_vue_html: {
                        acaoTelaEdit: "Screen Action - Edition",
                        descricao: "Description",
                        tela: "Screen",
                        acaoController: "Action Controller",
                        acaoVue: "Vue Action",
                        ativo: "Active",
                    },

                    list_vue_html: {
                        acoesTelas: "Screen Actions",
                        acoes: "Actions",
                        cadastradas: "Registered",
                    },
                },
                templateWhatsapp: {
                    edit_vue_html: {
                        templateWhatsappEdit: "WhatsApp Template - EDITION",
                        templateWhatsapp: "WhatsApp Template",
                        texto: "Text",
                        tipo: "Type",
                        addValorCustomizado: "Add custom value",
                    },
                    tag_vue_html: {
                        origem: "Origin",
                        campo: "Field",
                    },
                },

                transportador: {
                    edit_vue_html: {
                        transportadorEdit: "Transporter - Edition",
                        dadosGerais: "General Data",
                        cpf: "CPF",
                        rg: "RG",
                        ie: "Insc. State",
                        clienteIsentoEmBranco: "For exempt clients leave it blank",
                        nome: "Name",
                        nomeFantasia: "fantasy name",
                        razaoSocial: "Social Reason",
                        im: "Insc. Municipal",
                        dtNasc: "Date of Birth",
                        genero: "Gender",
                        orgaoExpedidor: "Dispatching Body",
                        ufExpedidor: "UF Expedidor",
                        obs: "Observation",
                        transportadora: "Carrier",
                        localizacao: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        complemento: "Complement",
                        contato: "Contact",
                        tel: "Telephone",
                        cel: "Phone",
                    },

                    list_vue_html: {
                        transportadores: "Transporters",
                        cadastrados: "Registered",
                    },
                },

                transportadora: {
                    edit_vue_html: {
                        transportadoraEdit: "Shipping Company - Edition",
                        dadosGerais: "General Data",
                        tipo: "Type",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. State",
                        clienteIsentoEmBranco: "For exempt clients leave it blank",
                        nome: "Name",
                        nomeFantasia: "fantasy name",
                        razaoSocial: "Social Reason",
                        im: "Insc. Municipal",
                        dtNasc: "Date of Birth",
                        genero: "Gender",
                        orgaoExpedidor: "Dispatching Body",
                        ufExpedidor: "UF Expedidor",
                        obs: "Observation",
                        localizacao: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        complemento: "Complement",
                        contato: "Contact",
                        tel: "Telephone",
                        cel: "Phone",
                    },

                    list_vue_html: {
                        transportadoras: "Shipping Companies",
                        cadastrados: "Registered",
                    },
                },

                compras: {
                    edit_vue_html: {
                        consolidar: "Consolidate",
                        compraCod: "Buy Code ",
                        compraEdit: "Purchase - Edition",
                        edit: " - Edition",
                        dataInicial: "Sales Start Date",
                        dataFinal: "Sales End Date",
                        dias: "Coverage days",
                        margemTolerancia: "Tolerance Margin",
                        margemToleranciaTooltip:
                            "The tolerance margin will be used for the system to identify whether the quantity present in current stock will cover the quantity calculated for consumption in the selected period. For example, if the current stock is 13 and the purchase quantity is 10, with a tolerance of 20% the system will consider that the required quantity is 12, meaning the current stock will cover the demand. The greater the tolerance, the looser the stock will be.",
                        curvaABC: "ABC curve",
                    },
                    list_vue_html: {
                        compras: "Shopping",
                    },
                    cotacao_vue_html: {
                        cotacaoEdit: "Quotation - Edition",
                        compraItens: "Purchase Items",
                    },
                    melhorCompra_vue_html: {
                        melhorCompra: "Best buy",
                    },
                    cotacaoFornecedor_vue_html: {
                        cotacaoFornecedor: "Supplier Quote",
                        enviarCotacao: "Send Quote",
                    },
                },

                unidademedida: {
                    edit_vue_html: {
                        unMedidaEdit: "Unit of Measure - Edition",
                        descricao: "Description",
                        sigla: "Acronym",
                        tipo: "Type",
                        conversao: "Conversion",
                        operador: "Operator",
                        valor: "Value",
                    },

                    list_vue_html: {
                        unMedida: "Units of Measure",
                        cadastradas: "Registered",
                    },
                },

                usuario: {
                    edit_vue_html: {
                        usuarioEdit: "User - Edition",
                        dadosGerais: "General Data",
                        nomeCompleto: "Full Name",
                        ativo: "Active",
                        supervisor: "Supervisor",
                        cpf: "CPF",
                        perfil: "Profile",
                        beneficio: "Benefit",
                        permissoes: "Permissions",
                        permissoesRemovidas: "Remove Permissions",
                        acoes: "Actions",
                        grupos: "Groups",
                        franquias: "Franchises",
                        franquia: "Franchise",
                        franquiaPadrao: "Franchise Default",
                        padrao: "Default",
                        colaborador: "Colaborador",
                        resetarSenha: "Reset user password",
                        franquiaDrogavet: "Franchises Drogavet",
                        atendimento: "Customer Service",
                        perfilAtendimento: "Profile Service",
                    },

                    list_vue_html: {
                        usuarios: "Users",
                        cadastrados: "Registered",
                    },
                },

                vendaContinuo: {
                    list_vue_html: {
                        orcar: "Budget",
                        relatContinuos: "Continuos Report",
                        dataInicio: "Validity Start Date",
                        dataFinal: "Expiration Date",
                    },
                },

                veterinario: {
                    view_vue_html: {
                        veterinario: "Veterinarian",
                        ano: "Year",
                        tipoPeriodo: "Period Type",
                        mes: "Month",
                        autorizacao: "Authorization",
                    },
                },

                visitador: {
                    edit_vue_html: {
                        visitadorEdit: "Visitor - Edition",
                        dadosGerais: "General Data",
                        tipo: "Type",
                        cpf: "CPF",
                        rg: "RG",
                        cnpj: "CNPJ",
                        ie: "Insc. State",
                        clientIsentoBranco: "For exempt customers, leave it blank",
                        nome: "Name",
                        nomeFantasia: "fantasy name",
                        razaoSocial: "Social Reason",
                        im: "Insc. Municipal",
                        dtNasc: "Date of Birth",
                        genero: "Gender",
                        orgaoExpedidor: "Dispatching Body",
                        ufExpedidor: "UF Expedidor",
                        comissao: "Commission",
                        obs: "Observation",
                        localizacao: "Location",
                        cep: "zip code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        complemen: "Complement",
                        contato: "Contact",
                        tel: "Telephone",
                        cel: "Phone",
                        prescritores: "Prescribers",
                        prescritor: "Prescriber",
                        editPrescritor: "Edit Prescriber",
                        novoPrescritor: "New Prescriber",
                    },

                    list_vue_html: {
                        visitadores: "visitors",
                        cadastrados: "Registered",
                    },
                },

                colaborador: {
                    edit_vue_html: {
                        colaboradorEdicao: "Contributors - Edition",
                        dadosGerais: "General Data",
                        cpf: "CPF",
                        rg: "RG",
                        nome: "Name",
                        franquia: "Franchise",
                        dataNasc: "Date of Birth",
                        genero: "Gender",
                        orgaoExpeditor: "Issuer Agency",
                        ufExpeditor: "Issuer Federative Unit",
                        obs: "Observation",
                        localizacao: "Location",
                        cep: "Zip Code",
                        estado: "State",
                        cidade: "City",
                        endereco: "Address",
                        nro: "Number",
                        bairro: "Neighborhood",
                        complemento: "Complement",
                        contato: "Contact",
                        telefone: "Telephone",
                        celular: "Phone",
                        beneficio: "Benefit",
                        vendedorDotz: "Dotz Seller",
                    },
                    list_vue_html: {
                        colaboradores: "Contributors",
                        cadastrados: "Registered",
                    },
                },

                configuracaoAtendimento: {
                    edit_vue_html: {
                        tituloPagina: "Service Configuration",
                        horarioInicio: "Start Time",
                        horarioFim: "End Time",
                    },
                },

                configuracaodotz: {
                    edit_vue_html: {
                        tituloPagina: "Dotz Configuration - Edit",
                        qtdMinVendas: "Minimum number of formulas",
                        porcMaxDesconto: "Maximum Discount Percentage",
                        nomeLoja: "Store Name",
                        tlpStoreCode: "Store Code",
                        codigoMecanica: "Mechanical Code",
                        tlpOfferCode: "Offer Code",
                        diaEnvioPontuacao: "Business days after the beginning of the month to send the score",
                        utilizaFaixasRede: "Uses Network Ranges",
                        envioInstantaneo: "Instant Shipping",
                        faixasTitulo: "Ranges",
                        filtros: "Filters",
                        inicioFaixa: "Ranges Start",
                        fimFaixa: "Range End",
                        multiplicador: "Multiplier",
                        tlpMultiplicador: "This value will be multiplied by the total value of sales",
                        faixaInicioGrid: "Range Start",
                        faixaFimGrid: "Range End",
                        naoExistemFaixasPadroes: "There are no standard ranges registered on the network.",
                        nenhumaFaixaCadastrada: "No range registered for this franchise.",
                        valorInicialMaiorFinal: "Initial value of the range is greater than the final value.",
                        faixaSobreposta: "Registered range is overlapped with another range.",
                        documentoFuncionario: "Employee document",
                        tlpDiaEnvioPontuacao:
                            "The number of business days will be considered after the beginning of the month, if informed 1 will be the first useful day of the month to send the score.",
                        tlpUtilizaFaixasRede:
                            "When indicating that you use tracks, you will consider the tracks registered in the Modelo franchise.",
                        tlpDocumentoFuncionario:
                            "Document registered at Dotz to link the score. If the document is not registered with Dotz, an error will occur in the credit score. (Must be the franchisee's document)",
                        franquiaEnvio: "Sending Franchise",
                        franquiaEnvioTooltip:
                            "Franchise that will receive the score. When informing the franchise, the system will no longer send the scores to the franchise, but will consolidate them into the selected franchise!",
                        enviarArquivosVendedores: "Send seller files",
                        pontuacaoDotz: "Dotz score",
                        enviadoArquivosVendedores: "Sellers file sent successfully!",
                        tipoEnvio: "Shipping Type",
                        considerarVendasPor: "Consider sales by:",
                    },
                },
            },

            dashboard: {
                charts: {
                    pedidos_vue_html: {
                        pedidosHoje: "Today's Orders",
                    },

                    previsaoRoyalties_vue_html: {
                        prevRoyaltiesMes: "Royalties Forecast of the Month",
                    },

                    vendas_vue_html: {
                        vendasHoje: "Today's sales",
                    },

                    vendasAnual_vue_html: {
                        vendasNesteAno: "Sales this year",
                    },

                    vendasMensal_vue_html: {
                        vendasNesteMes: "Sales this month",
                    },
                },

                evolucaoVendas_vue_html: {
                    valorVendasMes: "Total Sales Value in the selected period",
                    qtdeVendasMes: "Sales made in the selected period",
                    novosClientesMes: "New Customers in the selected period",
                    qtdVendas: "Sales Quantity",
                    franquias: "Franchises",
                    limpar: "Clear",
                    todas: "All",
                    taxaEntrega: "Considere la tarifa de envío",
                },

                manipulacoes_vue_html: {
                    valorTotalVendas: "Total SalesValue",
                    qtdFormulas: "Total Quantity of Formulas",
                    qtdVendas: "Quantity of sales",
                    ticketMedioFormula: "Average Ticket per formula",
                    ticketMedioVenda: "Average Ticket per Sale",
                    dataInicial: "Start Date",
                    dataFinal: "End Date",
                    franquias: "Franchises",
                },

                totaisPorCategoria_vue_html: {
                    dataInicial: "Start Date",
                    dataFinal: "End Date",
                    franquias: "Franchises",
                },
            },

            fichaTecnica: {
                edit_vue_html: {
                    dadosGerais: "General Data",
                    catTerapeu: "Therapeutic Category",
                    conserv: "Conservation",
                    refe: "Reference",
                    ensaios: "Assay",
                    soludibilidade: "Solubility",
                    dadosAdicionais: "Additional data",
                    novoDadoAdicional: "New Additional Data",
                    obss: "Observations",
                },
            },

            fluxo: {
                list_vue_html: {
                    aReceber: "Receivable",
                    aPagar: "Payable",
                    saldoFinal: "Final Balance",
                    fluxoCaixa: "Cash Flow",
                    metaMensal: "Monthly Goal:",
                    maiorMensal: "Largest Monthly:",
                    totalAnual: "Annual Total:",
                    receita: "Recipe",
                    despesa: "Expense",
                    meta: "Goal",
                },
            },

            fracionaEstoque: {
                kitEmbalagens_vue_html: {
                    embalagem: "Packing",
                    lote: "Lot",
                    qtd: "Quantity",
                    total: "Total",
                    acao: "Action",
                },
            },

            gradePCP: {
                view_vue_html: {
                    gradePCP: "PCP Grid",
                    producao: "Production - Mon to Fri / Weekend",
                    capacidade: "Capacity",
                    vendas: "Sales",
                    pendente: "Pending",
                    dataInicial: "Start Date",
                    dataFinal: "End Date",
                    formasFarmacIgnorar: "Pharmaceutical Forms to Ignore",
                    oSistemaDesconsiFormasSelec:
                        "The system will disregard the selected pharmaceutical forms from the grid",
                    tipoVendaAIgnorar: "Type of sale to ignore",
                    oSistemaDesconsiTiposVendasSelec:
                        "The system will disregard the selected sales types from the grid.",
                },
            },

            historico: {
                vendaHistorico: {
                    list_vue_html: {
                        historicoVendas: "Sales History",
                        vendaHistorico: "Historical Sale",
                        tudo: "All",
                        contiuo: "Continuous",
                        dtVenda: "Dt of sale:",
                        visuVenda: "View Sale",
                    },

                    ver_vue_html: {
                        dadosGerais: "General Data",
                        cliente: "Customer:",
                        cpf: "CPF:",
                        dtNasc: "Birth Date:",
                        cel: "Phone:",
                        tel: "Telephone:",
                        vlrTotal: "Total Amount:",
                        dtSale: "Sale Date:",
                        obs: "Obs:",
                        itensAcabados: "Finished Items",
                        produto: "Product:",
                        qtd: "Qty:",
                        vlr: "Value:",
                        prescritor: "Prescriber:",
                        paciente: "Patient:",
                        itensManipu: "Items Handled",
                        formaFarm: "Forma Farm .:",
                        qtdVol: "Qty / Vol .:",
                        volBase: "Vol. Base: ",
                        unMed: "Units. Med .: ",
                        continuo: "Continuous:",
                        embalagem: "Packing:",
                        capsula: "Capsula:",
                        posologia: "Posology:",
                        dose: "Dose",
                        tipoDose: "Dose type",
                        lote: "Batch",
                        nr: "Registration number:",
                    },
                },
            },

            home: {
                dashboard: {
                    dashboard_vue_html: {
                        vendaAno: "Sale year",
                        vendasMes: "Sales per month",
                        vendasDias: "Sales day",
                        pedidosDia: "Orders day",
                    },
                },

                fastaction: {
                    continuos: {
                        continuos_vue_html: {
                            continuos: "Continuos",
                            venc: "/ Exp.:",
                            arqAvisoContinuo: "Archive notice of this continuum",
                            adiarVencContinuo: "Postpone maturity of this continuum",
                            visuUltimaVendaOrcContinuo: "View the last sale / budget of this continuum",
                            gerarOrcamentWpp: "Generate Budget and set up Whats for shipping",
                            verMais: "See More",
                        },
                    },

                    expedicao: {
                        expedicao_vue_html: {
                            expedicao: "Expedition",
                            cod: "Cod: ",
                            aprovacao: "/ Approval.: ",
                            adicionarInfromacao: "Add shipping information",
                            finalizarExpedicao: "Finalize expedition",
                            finalizar: "Finalize",
                            verMais: "See More",
                            visualizarVenda: "View Sale",
                        },
                    },

                    financeiro: {
                        financeiro_vue_html: {
                            sldInicialCaixa: "Cash Opening Balance:",
                            sldAtualCaixa: "Current Cash Balance:",
                            dinheiro: "Money",
                            boleto: "Billet",
                            transferecia: "Transfer",
                            debito: "Debit",
                            credito: "Credit",
                            cheque: "Check",
                            vlr: "Value:",
                            verMais: "See more",
                            fechamCaixa: "Cash Closing",
                            abertCaixa: "Open Cash Flow",
                            gerarReforco: "Generate Reinforcement",
                            gerarSangria: "Cash Out",
                        },
                    },

                    manipulacao: {
                        manipulacao_vue_html: {
                            emProcesso: "In process",
                            verificados: "Verified",
                            formaFarm: "/ Forma Farm .:",
                            paciente: "/ patient:",
                            codVenda: "/ codename:",
                            formula: "/ Formula:",
                            imprimirRotulo: "Print Label",
                            imprimirManip: "Print Compounding",
                            confLotesEstqManipu: "Check Lots / Stock Handling",
                            visuManipu: "View Compounding",
                            conclManipu: "Complete Compounding",
                            verMais: "See more",
                            manipuFrmlPadrao: "Compound Base Formula",
                            manipulacoesVenda: "Compound Sales",
                            novaManipu: "New Compound",
                            confirmarAlte: "Confirm Changes",
                            manipulacao: "Compounding",
                            receita: "View sales revenue",
                            historicomanipulacao: "Handling history",
                        },
                    },

                    orcamentos: {
                        orcamentos_vue_html: {
                            orcadosHoje: "Budgeted today:",
                            orcamentosCriadosHj: "Budgets created today:",
                            orcamentoVenc: "Budget Due",
                            orcamentoAVenc: "Budget to Win",
                            orcamentoEmDia: "Budget in Day",
                            cod: "Code:",
                            vencOrcamento: "Budget Maturity: ",
                            ativos: "Assets:",
                            arquivarRejeiOrcamento: "Archive / reject Budget",
                            adiarValDesteOrcamento: "Postpone validity of this Budget",
                            compartilharOrcamento: "Share budget",
                            visuOrcamento: "View Budget",
                            aprovOrcamento: "Approve Budget",
                            verMais: "See more",
                            clientes: "Customers",
                            orcamentos: "Budgets",
                            novoOrcamento: "New Budget",
                            confirmarAlte: "Confirm Changes",
                        },
                    },

                    produtos: {
                        list_vue_html: {
                            relatorioSituaEstq: "Inventory Status Report",
                            relatorioEstqMin: "Minimum Stock Report",
                        },

                        produtos_vue_html: {
                            prodSemEstq: "Product Out of Stock",
                            prodVencendo: "Products Winning",
                            estqBaixo: "Low Stock",
                            produto: "Product:",
                            lote: "batch:",
                            val: "Validity:",
                            verMais: "See more",
                            produtos: "Products",
                            lotes: "Lots",
                            novoProd: "New Product",
                        },
                    },

                    vendas: {
                        list_vue_html: {
                            relatorioVendClient: "Sales Report by Customer",
                            setor: "Sector",
                            situacao: "Situation",
                            dtInicEntrega: "Initial Delivery Date",
                            dtFinalEntrega: "Final Delivery Date",
                        },

                        vendas_vue_html: {
                            vendidosHj: "Sold today:",
                            vendasAprovHj: "Sales Approved today:",
                            vendasAprovadas: "Sales Approved",
                            semFaturamento: "Sales Without Billing",
                            envioNFPend: "Sales without Invoice",
                            agRetornoPrefe: "Sales with Pending Invoice at City Hall",
                            cod: "Code:",
                            dtAprov: "Approval Date:",
                            dtHrEnvio: "Date Time of Submission: ",
                            ativos: "Assets:",
                            cancelarVenda: "Cancel Sale",
                            imprimirVenda: "Print Sale",
                            visuVenda: "View Sale",
                            faturarVenda: "Bill Sell",
                            emitirNF: "Issue NF",
                            verMais: "See more",
                            novoCliente: "New customer",
                            vendas: "Sales",
                            novoOrcamento: "New Budget",
                            novoPgto: "New Payment",
                            pagamentos: "Payments",
                        },
                    },

                    fastaction_vue_html: {
                        orcamentos: "Quotation",
                        manipulacao: "Compouding",
                        vendas: "Sales",
                        continuos: "Continuous",
                        financeiro: "Accountancy",
                        produtos: "Products",
                        atendimento: "Customer Service",
                        estoque: "Stock",
                        expedicao: "Expedition",
                        fracionamento: "Fraction.",
                    },

                    entregas: {
                        entregas_vue_html: {
                            dataHoraEntrega: "Delivery Date/Time:",
                            local: "Local:",
                            visualizarVenda: "View sale",
                            entregas: "Deliveries",
                            imprimirRomaneio: "Print packing slips",
                            postagens: "Posts",
                        },
                    },
                },
            },

            importacaonfe: {
                contapagar_vue_html: {
                    contaGerenc: "Managerial Account",
                    dtVenc: "Expiration date",
                    financeiro: "Financial",
                    parcela: "Portion",
                    numeracaoDuplic: "Duplicate Numbering",
                    dtDeVenc: "Expiration Date:",
                    vlrDuplic: "Duplicate Value:",
                    status: "Status",
                    dadosPgto: "Payment data",
                    dtPgto: "Payment Date:",
                    formaPgto: "Payment Method:",
                    contaCorrente: "Current Account:",
                    faturamento: "Billing",
                    vlrLiquido: "Net Value:",
                },

                dadosgerais_vue_html: {
                    dadosGerais: "General Data",
                    chaveAcess: "Access Key",
                    modelo: "Model",
                    serie: "Serie",
                    nro: "Number",
                    versaoXML: "XML version",
                    vlrTotalNF: "Total Invoice Amount",
                    dtEmissao: "Issue Date",
                    dtHrEntradaSaida: "Entry / Exit Date and Time",
                },

                destinatario_vue_html: {
                    destinatario: "recipient",
                    cnpj: "CNPJ",
                    ie: "Insc. State",
                    tel: "Telephone",
                    cpf: "CPF",
                    cep: "zip code",
                    pais: "Country",
                    uf: "UF",
                    cidade: "City",
                    endereco: "Address",
                    nro: "Number",
                    bairro: "Neighborhood",
                    complemen: "Complement",
                },

                edit_vue_html: {
                    nfeImport: "NFe - Import",
                    estqDestino: "Destination stock",
                    atualizDadosFornecedor: "Update supplier data",
                    gerarCntPagar: "Generate account payable",
                    fracionarLotes: "Fractionate lots",
                    devolucao: "Return Note",
                    devolucaoTooltip:
                        "If checked, no lot will be included in the system, only the existing ones will be moved, the descriptions of each lot must be the same as those in the system for the return to be done correctly",
                    atualizVlrSeMarcado:
                        "If checked the option and the unit value of the note item is greater than the reference cost value of the product, it will be updated to the value derived from the note.",
                    atualizCustos: "Update Costs",
                },

                emitente_vue_html: {
                    emitente: "Issuer",
                    nomeFantasia: "Fantasy Name",
                    cnpj: "CNPJ",
                    ie: "Insc. State",
                    tel: "Telephone",
                    cpf: "CPF",
                    cep: "zip code",
                    pais: "Country",
                    uf: "UF",
                    cidade: "City",
                    endereco: "Address",
                    nro: "Number",
                    bairro: "Neighborhood",
                    complemen: "Complement",
                },

                produtoassociacao_vue_html: {
                    addNovo: "Add New",
                },

                produtolotes_vue_html: {
                    tipo: "Type",
                    produto: "Product",
                    ftrCorrecao: "Correction Factor",
                    fc: "C.F.",
                    qtdUnMedOrig: "Qty / Un.Med. Orig. ",
                    qtd: "Qty.",
                    unMed: "Un.Med.",
                    qtdConvertida: "Qty. Converted ",
                    custoUnit: "Unit Cost",
                    custoRefeAtual: "Current Reference Cost",
                    percentMarkupVenda: "Percentage Markup Sale (%)",
                    dadosLote: "Batch Data",
                    descricao: "Description",
                    dtFab: "Date of Manufacture",
                    dtVal: "Expiration Date",
                    precoMaxConsumidor: "Max. Consumer Price",
                    densidade: "Density (g/ml)",
                    importSemLote: "Import Without Batch",
                    preencherLote: "Fill Lot",
                    fracionarLote: "Fractionate Lot",
                },

                produtos_vue_html: {
                    prodsLotes: "Products / Lots",
                    total: "TOTAL",
                    proximo: "Next",
                    conclEAvancProxProd: "Complete current changes and move on to the next divergent product",
                },

                transporte_vue_html: {
                    transportador: "Carrier",
                    cnpj: "CNPJ",
                    ie: "Insc. State",
                    cpf: "CPF",
                    uf: "UF",
                    cidade: "City",
                    endereco: "Address",
                    qtd: "Quantity",
                    especie: "Species",
                    marca: "Brand",
                    numeracao: "Numbering",
                    pesoLiq: "Net Weight",
                    pesoBruto: "Gross weight",
                    valorFrete: "Value Shipping",
                },
            },

            manipulacao: {
                cancelamento_vue_html: {
                    manipuOrdemCancelamento: "Order Handling - Cancellation",
                    manipuCanceladaNaoPossiv: "Compounding is already canceled! You cannot change the data. ",
                    deveCancelarPelaTelaCancelamento:
                        "This Compounding must be canceled by the sale cancellation screen",
                    dadosManipu: "Compounding data",
                    dtCancelamento: "Cancellation Date",
                    userCancelamento: "User Cancellation",
                    obrigatorio: "this field is mandatory",
                    motivoCancelamento: "Reason for Cancellation",
                    obsCancelamento: "Cancellation Notice",
                    itensManipu: "Items of Compounding",
                    item: "Item",
                    qtde: "Quantity",
                    semMovimentoManipulacao: "There is no stock movement of this Compounding!",
                    reverteLoteCriado:
                        "Attention! When canceling manipulation, the generated quantity of the produced batch will be reversed",
                },

                detalhesitem_vue_html: {
                    comissaoPrescritor: "Prescriber Commission: ",
                    comissaoVisitador: "Visiting Commission: ",
                    comissaoAtendente: "Attendant Commission: ",
                },

                edit_vue_html: {
                    manipuEdit: "Compounding - Edition",
                    manipuCod: "Compounding Code ",
                    edit: " - Edition",
                    desfazer: "Undo",
                    manipuJaConcluida: "Compounding is already completed! You cannot change the data. ",
                    manipuCancelada: "Compounding is canceled! You cannot change the data. ",
                    situacao: "Situation",
                    codigo: "Code",
                    numeroFormulas: "Number Formulas",
                    formaFarmaceu: "Pharmaceutical Form",
                    molde: "Mold",
                    tamPlaca: "board size",
                    volDose: "Volume",
                    qtdTotal: "Qty. Total",
                    volTotal: "Total Volume",
                    consolidaInfos:
                        "Consolidates information after inserting assets for automatic searches of Capsules, Packaging and inventory validations",
                    consolidarItens: "Consolidate Items",
                    todosItensDevemTerProdutoInformado: "All items of manipulation must have their product informed!",
                    confEstqAjusta: "Check stock and adjust quantities and lots when necessary",
                    lotesEstq: "Lots / Stock",
                    detalsDeCustos: "Cost details",
                    detalsCusto: "Details Cost",
                    valores: "Values",
                    valorFormFarma: "Valor Forma Farm.",
                    valorProds: "Valor Produtos",
                    acrescimo: "Addition",
                    acrescimoPercentual: "Addition (%)",
                    desconto: "Discount",
                    descontoPercentual: "Discount (%)",
                    valorTotal: "Total Value",
                    utilizMinForma: "Using minimum value of the pharmaceutical form",
                    itensManipu: "Compouding Itens",
                    complementosManipu: "Complements of Compounding",
                    dtVal: "Expiration Date",
                    posologia: "Posology",
                    editPosologia: "Edit Posology",
                    novaPosologia: "New Posology",
                    paciente: "Patient",
                    editPaciente: "Edit Patient",
                    novoPaciente: "New Patient",
                    prescritor: "Prescriber",
                    editPrescritor: "Edit Prescriber",
                    novoPrescritor: "New Prescriber",
                    obs: "Observation",
                    continuo: "Continuous",
                    nroReceita: "Revenue number",
                    dataValidadeReceita: "Prescription expiration date",
                    salvarAlt: "Save Change",
                    add: "Add",
                    concluir: "Finish",
                    movimentacaoEstq: "Stock movement",
                    imprimirModel: "Print from Model",
                    atualizDadosLotes: "Update Lots Data",
                    preVenda: "Pre-sales",
                    mensagemHabilitaAlteracao:
                        "Manipulation with pre-sale origin! Change not enabled in standard formula.",
                    nomeVendedor: "Vendor Name",
                    usaMarkupEquinos: "Use Markup for Equine",
                    multiplicarNumeroDose: "Multiply Number of Dose",
                    mensagemMultiplicarNumeroDose:
                        "When informing the multiplier, this will be used to break the total number of doses, if indicated to force the multiplication, the system will look for the most suitable mold, otherwise the break will not be considered in the mold search",
                    forcarMultQuantidade: "Force Multiplication",
                    fatorMultQuantidade: "Multiplication factor",
                    volumeObrigatorio: "Inform the volume before consolidating the manipulation",
                    quantidadeObrigatoria: "Inform the quantity before consolidating the handling",
                    obsUsuario: "User observation",
                    msgPrescritorObs: "Warning, his prescriber has a note",
                    observacoes: "Comments",
                    numeroFormulasMaiorQueZero: "Inform the number of manipulations",
                    naoFoiInformadoRecipienteQSP: "No QSP container was informed in the handling",
                    numeroReceitaDeveSerInformadoContinuo:
                        "The expiration date of the prescription must be informed, as the manipulation contains continuous products",
                    numeroReceitaDeveSerInformadoControlado:
                        "The expiration date of the prescription must be informed, as the manipulation contains controlled products",
                    ativosDuplicado:
                        "The assets {produto1} and {produto2} are the same product. It will not be possible to continue, adjust the manipulation.",
                    manipulador: "manipulator",
                    oCalculoVolume:
                        "The calculation of the volume of assets is based on the raw material group and the Active and Flavoring subgroups",
                    dataValidadeManipulacao: "Expiration date of manipulation",
                },

                item_vue_html: {
                    visuProduto: "View Product",
                    vlrCadaDoseUnMedIguais:
                        "Volume Dose is the value of each dose in units of measure equal to the product unit",
                },

                itemTable_vue_html: {
                    kitEmbalagem: "Packing Kit",
                    lote: "Lot",
                    addAtivo: "Add Asset",
                    add: "Add",
                    addFormBase: "Add Formula Base",
                    addKitEmb: "Add Packaging Kit",
                    addCapsula: "Add Capsule",
                },

                list_vue_html: {
                    manipuVenda: "Sale Handling",
                    manipu: "Compounding",
                    cadastradas: "Registered",
                    de: "Completion Date of:",
                    data: "Date:",
                },

                listFormulaPadrao_vue_html: {
                    manipuFrmlPadrao: "Default Formula Compounding",
                    manipu: "Compounding",
                    cadastradas: "Registered",
                    statusSituacao: "Situation",
                    estoque: "Stock",
                },

                loteitem_vue_html: {
                    produto: "Product",
                    produtoParametNaoControlEstq: "Product parameterized to not control stock",
                    lote: "Lot",
                    qntdEstoque: "Quantity in Stock",
                    qntdBaixa: "Lease Quantity",
                    val: "Validity",
                    status: "Status",
                    saldo: "Balance:",
                    total: "Total:",
                    extra: "Extra:",
                    loteManipulacaoItemTooltip: "The first batch will be saved in the handling order item",
                },
            },

            navmenu: {
                abertura_de_caixa: "Open Cash Flow",
                atualizacao_valores: "Value Update",
                atualizacao_valores_produto: "Product update",
                atualizacao_valores_forma_farmaceutica: "Pharmaceutical Form update",
                bancos: "Banks",
                bandeira: "Flag",
                beneficio: "Benefits",
                cadastros: "Registrations",
                campanhas: "Campaigns",
                capacidade_producao: "Production Capacity",
                capsulas: "Capsules",
                categoria_controle_reclamacao: "Category",
                cests: "CESTs",
                classificacao_de_conta_gerencial: "Managerial Account Classification",
                cliente: "Customer",
                clientes: "Customers",
                clientes_duplicacoes: "Customers",
                prescritores_duplicacoes: "Prescribers",
                colaboradores: "Contributors",
                configuracao: "Configuration",
                configuracao_dotz: "Dotz Configuration",
                configuracoes_da_franquia: "Franchise Settings",
                configuracoes_de_usuario: "User Settings",
                configuracao_whatsapp: "WhatsApp Configuration",
                configuracao_etapa_pcp: "PCP Step Configuration",
                contas_a_pagar: "Accounts Payable",
                contas_a_receber: "Accounts Receivable",
                contas_correntes: "Current Accounts",
                contas_gerenciais: "Management Accounts",
                contas_pagas: "Paid Accounts",
                contas_recebidas: "Accounts Received",
                controle_reclamacao: "Complaint control",
                convenio: "Agreements",
                cores: "Colors",
                cupom: "Coupons",
                dados_adicionais_ficha_tecnica: "Additional Data Technical record",
                dashboard: "Dashboard",
                dashboards: "Dashboards",
                dashboard_evolucao_de_vendas: "Sales Evolution",
                dashboard_manipulacoes: "Compoundings",
                dashboard_totais_categoria: "Totals by Category",
                devolucoes: "Returns",
                dcbs: "DCBs",
                dcis: "DCIs",
                dotz: "Dotz",
                duplicacoes: "Duplications",
                ensaios: "Tests",
                especialidades: "Specialties",
                especie_de_animal: "Animal Species",
                estoque: "Stock",
                estoques: "Stocks",
                etapa_pcp: "PCP Step",
                farmacopeia: "Pharmacopoeia",
                fechamento_de_caixa: "Cash Closing",
                ficha_tecnica: "Technical record",
                financeiro: "Financial",
                fiscal: "Supervisor",
                fluxo_de_caixa: "Cash Flow",
                formas_de_pagamento: "Payment Methods",
                formas_de_recebimento: "Forms of Receipt",
                formas_farmaceuticas: "Forms Pharmaceuticals",
                formula_padrao: "Default Formula",
                formulas_padrao: "Default Formulas",
                fornecedores: "Suppliers",
                franquias: "Franchises",
                grupos: "Groups",
                grade_pcp: "PCP Grid",
                justificativa_controle_reclamacao: "Justification",
                status_pcp: "PCP status",
                historico: "Historic",
                historico_envios: "Submissions Historic",
                importacao_nfe: "NFe import",
                inutilizacao_nota_fiscal: "Invoice destruction",
                download_xmls_nota_fiscal: "Download XMLs Invoices",
                emissao_nota_saida_lote: "Batch invoice issuance",
                geracao_siproquim: "SIPROQUIM",
                inventario: "Inventory",
                kit_embalagem: "Packaging Kit",
                listas_de_controlado: "Controlled Lists",
                localentrega: "Delivery Locations",
                lotes: "Lots",
                manipulacao: "Compounding",
                manipulacoes: "Compoundings",
                manipulacoes_venda: "Sale",
                metas: "Goals",
                metodos_de_pagamento_recebimento: "Payment/ Receipt Methods",
                modelos_de_impressao: "Printing Templates",
                curva_abc: "ABC curve",
                curva_abc_produto: "Products",
                curva_abc_prescritores: "Prescribers",
                moldes: "Molds",
                motivos_cancelamento: "Reasons for Cancellation",
                motivo_rejeicao: "Reasons for Rejection",
                motivo_movimentacao_estoque: "Reasons for movements",
                motivo_etapa_pcp: "Reasons for PCP Step",
                policia_civil: "Civil Police",
                movimentacoes_de_caixa: "Cash Movements",
                movimentacoes_de_conta: "Account Movements",
                mapa_siproquim: "(SIPROQUIM) Map",
                movimentacoes_de_estoque: "Inventory Movements",
                movimentacoes_pendentes: "Pending Transactions",
                naturezas_de_operacao: "Operation natures",
                nbms: "NBMs",
                ncms: "NCMs",
                nfe_danfe_saida: "Notes Issued (NF-e)",
                nfse_manual: "Notes Issued (NFS-e)",
                nota_fiscal_de_entrada: "Input invoice",
                nota_fiscal_de_saida: "Output invoice",
                operadora_de_cartoes: "Card Operator",
                contas_a_receber_parceladas: "In installments",
                operados_de_cartao: "Card Operators",
                orcamentos: "Budgets",
                pacientes: "Patients",
                pcp: "PCP",
                pdv: "PDV",
                posologias: "Posologies",
                prescritores: "Prescribers",
                produto: "Product",
                produtos: "Products",
                programa_fidelidade: "Loyalty Program",
                racas_animais: "Animal Breeds",
                recebimento_parcelado: "Installment Receipt Methods",
                recompensas: "Rewards",
                relatorios: "Reports",
                rotulo: "Label",
                servicos: "Services",
                servicos_fiscais: "Tax Services",
                sngpc: "SNGPC",
                pontuar: "Score",
                pontuar_manual: "Manual scoring",
                compras: "Purchases",
                sngpc_manual: "SNGPC_Manual",
                telas: "Screens",
                telas_acoes: "Screens Actions",
                transportador: "Carrier",
                transportadora: "Shipping Company",
                unidades_de_medida: "Units of Measure",
                setor: "Sector",
                usuarios: "Users",
                venda: "Sale",
                vendas: "Sales",
                visitador: "Visitor",
                caixa: "Cash",
                livro_registro_especifico: "Specific Registration Book",
                balanco_completo: "Complete Balance Sheet",
                veterinario: "Veterinarian",
                dinamizacao: "Dynamization",
                estoque_minimo: "Minimum stock",
                vendas_entregas: "Deliveries",
                expedicao: "Expedition",
                coleta: "Collections",
                declaracao_conteudo: "Declaration of Content",
                suporte: "Support",
                whatsapp: "WhatsApp",
                tomticket: "TomTicket",
                chatbot: "Chat Bot",
                controle_especial: "Special Control",
                manipuladores: "Handlers",
                etiqueta: "Tags",
                condicao: "Conditions",
                configuracao_atendimento: "Service Configuration",
            },

            nfe: {
                edit_vue_html: {
                    nfeEdit: "NF-e - Edition",
                    dadosNfe: "NF-e data",
                    operacao: "Operation",
                    dtEmissao: "Date of Issue",
                    dtHraEntradaSaida: "Date and Time of Entry / Exit",
                    finalidadeEmissao: "Purpose of the Issue",
                    indicadorPresenca: "Presence Indicator",
                    indicativoIntermedio: "Intermediate Indicator",
                    tipoEmissao: "Emission Type",
                    fornecedor: "Supplier",
                    cliente: "Customer",
                    cfop: "CFOP",
                    itens: "Items",
                    obs: "Observations",
                    transportadora: "Carrier",
                    modalFrete: "Shipping Mode",
                    cnpj: "CNPJ",
                    endereco: "Address",
                    cidade: "City",
                    uf: "UF",
                    ie: "I.E.",
                    placaVeiculo: "Vehicle License Plate",
                    salvarEEmitir: "Save and Issue",
                    consultaEmissao: "Consult Issue",
                    cartaCorrecao: "New Correction Letter",
                    consultarCartaCorrecao: "Consult Last Correction Letter",
                    abrirPDF: "Open PDF",
                    abrirPDFSimplificado: "Open Simplified PDF",
                    abrirXML: "Open XML",
                    cancelarEmissao: "Cancel Issue",
                    movimentaEstoque: "Move stock",
                    estoque: "Stock",
                },

                item_vue_html: {
                    produto: "Product",
                    lote: "Lot",
                    infoAdicional: "Additional Information",
                    EAN: "EAN (Bar code)",
                    unMed: "Unit of Measure",
                    qtde: "Quantity",
                    ncm: "NCM",
                    vlrUnitario: "Unit Value",
                    desconto: "Discount",
                    frete: "Freight",
                    seguro: "Safe",
                    outrasDespesas: "Other Expenses",
                    vlrTotal: "Total Amount",
                },

                list_vue_html: {
                    nfe: "NF-e",
                    nf: "Invoices",
                    emitidas: "Issued",
                    NFE: "NFE",
                },
            },

            notafiscalentrada: {
                edit_vue_html: {
                    nfEntradaEdit: "Invoice for Entry - Edition",
                    dataNfEnrtada: "Incoming invoice data",
                    estqDestino: "Destination stock",
                    dtEmissao: "Date of Issue",
                    dtInclusao: "Date of inclusion",
                    vlrSeraAtualizVlrNota:
                        "If checked the option and the unit value of the note item is greater than the reference cost value of the product, it will be updated to the value derived from the note.",
                    atualizarCustos: "Update Costs",
                    gerarCntPagar: "Generate account payable",
                    nroNF: "Nota Fiscal Number",
                    serie: "Série",
                    chave: "Access key",
                    fornecedor: "Supplier",
                    editFornecedor: "Edit Supplier",
                    novoFornecedor: "New Supplier",
                    transportadora: "Carrier",
                    itens: "Items",
                    financeiro: "Financial",
                    totais: "Totals",
                    vlrFrete: "Shipping Value",
                    vlrIPI: "IPI Value",
                    vlrTotalProd: "Total Value of Products",
                    vlrDescontos: "Value Discounts",
                    vlrTotal: "Total Amount",
                    vlrTotalFaturado: "Total Amount Billed",
                },

                financeiro_vue_html: {
                    descrDuplicata: "duplicate description",
                    dtVenc: "Expiration date",
                    contaGerencial: "Management Account",
                    status: "Status",
                    dadosPgto: "Payment data",
                    dtPgto: "Payment Date",
                    formPgto: "Payment Method",
                    contaCorrente: "Current Account",
                },

                item_vue_html: {
                    produto: "Product",
                    codProd: "Product Code",
                    lote: "Lot",
                    editProdLote: "Edit Product Batch",
                    novoProdLote: "New Product Batch",
                    unidadeMedidaOrigem: "Unit of Measure Origin",
                    quantidadeOrigem: "Origin Quantity",
                    valorUnitarioOrigem: "Unit Value Origin",
                    quantidadeConvertida: "Converted Quantity",
                    valorUnitarioConvertido: "Converted Unit Value",
                    vlrTotal: "Total Amount",
                    valorFrete: "Freight Amount",
                    valorSeguro: "Insurance Amount",
                    valorOutraDespesa: "Amount Other Expenses",
                    valorDesconto: "Discount Amount",
                    tlpQuantidadeConvertida: "Quantity Origin with applied conversion ratio",
                    tlpValorUnitarioOrigem: "Gross value of invoice item",
                    tlpValorTotal: "Calculated Net Value",
                    tlpValorUnitarioConvertido: "Will be considered in the unit value of the stock movement",
                },

                list_vue_html: {
                    nfEntrada: "Incoming invoices",
                    cadastradas: "Registered",
                    nf: "Invoices",
                },
            },

            notafiscalsaida: {
                edit_vue_html: {
                    nfSaidaEdit: "Invoice for Exit - Edition",
                    dadosNfSaida: "Outgoing invoice data",
                    statusEmissao: "Status of Issue",
                    tipoNF: "Nota Fiscal Type",
                    motivoCancel: "Reason for Cancellation",
                    nro: "Number",
                    dtEmissao: "Date of Issue",
                    dtInclusao: "Inclusion Date",
                    serie: "Série",
                    chaveBusca: "Search Key",
                    codVerif: "Verification Code",
                    chave: "Key",
                    servico: "Service",
                    prods: "Products",
                    totais: "Totals",
                    baseCalculo: "Calculation Base",
                    vlrServico: "Service Value",
                    vlrISS: "ISS Value",
                    vlrTotalServicos: "Total Value of Services",
                    baseCalculos: "Calculation Base",
                    vlrProds: "Product Value",
                    valorICMS: "Value of ICMS",
                    vlrTotal: "Total value",
                    consultEmissao: "Consult Issue",
                    cancelarEmissao: "Cancel Issue",
                    consultEmissaoCancelamento: "Consult Cancellation Issue",
                    altDadosNF: "Change invoice data",
                    cartaCorrecao: "New Correction Letter",
                    consultarCartaCorrecao: "Consult Last Correction Letter",
                    abrirPDF: "Open PDF",
                    abrirPDFSimplificado: "Open Simplified PDF",
                    abrirXML: "Open XML",
                },

                list_vue_html: {
                    nfSaida: "Outgoing invoices",
                    nf: "Invoices",
                    cadastradas: "Registered",
                    status: "Status Note:",
                    tipoNota: "Note Type:",
                    de: "From:",
                    ate: "Until:",
                    todos: "All",
                },
            },

            printModel: {
                campo_vue_html: {
                    nomeCampo: "Field Name",
                    mostrarNomeCampo: "Show Field Name",
                    seMarcNaoMostra: "If unchecked, it does not display the field name in print",
                    origemCampoComplemen: "Origin of the Complement Field",
                    separOndeOriginaInfo: "Separation from where the information originates",
                    campoComplemen: "Complement Field",
                    utilizOutroCampoComplemen: "Use another Field to complement the value of this field",
                    complemenCampo: "Field Complement",
                    posComplemen: "Complement Position",
                    posComplemenRelacaoVlr: "Position of the complement in relation to the printed value data",
                    txtNegrito: "Bold Text",
                    corTxt: "Text Color",
                    corFundoTxt: "Text Background Color",
                    origCampoSubstituto: "Origin of the Substitute Field",
                    primeiroNomeCampoSubstituto: "First Name Substitute Field",
                    campoSubstituto: "Substitute Field",
                    campoSubstituiInfos: "Field that replaces field information if it does not have values",
                    utilizLimiteChar: "Use Character Limit",
                    utilizNroLimiteCharVlrCampo: "Use limited number of characters for the field value",
                    maxChar: "Maximum Characters",
                    alinTxt: "Text Alignment",
                    primeiroNome: "First Name",
                    apresTxtAtePrimEspaco: "Displays text up to the first spacing",
                    quebLinha: "Break Line",
                    qndoLimitMaxCharLinhaAbaixo:
                        "When limited to the maximum number of characters, you can choose to continue the text in the line below",
                    personalizado: "Custom",
                    identificador: "Identifier",
                    exibirCampoBranco: "Blank field",
                    exibirCampoBrancoTooltip:
                        "By checking this option, when the field has a zero value, it will be displayed blank.",
                },

                condicao_vue_html: {
                    condImpressao: "Impression condition",
                    origemCampo: "Origin of the Field",
                    separOndeOriginInfo: "Separation from where the information originates",
                    campoComparar: "Field to compare",
                    vlrComparar: "Value to compare",
                },

                edit_vue_html: {
                    modelImpressEdit: "Print Model - Edition",
                    dadosImpress: "Print Data",
                    descrSessao: "Session Description",
                    imprimirDesc: "Print Desc.",
                    ordem: "Order",
                    auxOrdenacaoSessoes: "Assists in ordering sessions",
                    tipoDado: "Data Type",
                    tipoDadoCampo: "Field data type, sessions must be separated by data type",
                    linhas: "Lines",
                    tiposLinhasTab: "Table Row Types",
                    ativo: "Active",
                    maxCharLinha: "Maximum Characters per Line",
                    limitaCharLinha: "Limits the number of characters per line",
                    maxLinhas: "Maximum Lines",
                    limitInfosCampos: "Limits field information to x number of lines",
                    corpoImpress: "Impression Body",
                    separOriginaInfo: "Separation from where the information originates",
                },

                list_vue_html: {
                    modeloImpress: "Print Model",
                    cadastrados: "Registered",
                },

                sessao_vue_html: {
                    modelImpressEdit: "Print Model - Edition",
                    descricao: "Description",
                    padrao: "Pattern",
                    tamanhoFonte: "Font Size",
                    fonte: "Font",
                    modeloPreDefinido: "Predefined Template",
                    modeloPreDefinidoTooltip:
                        "Custom template will respect the settings of this print template, otherwise it will use the pre-defined PDF without the possibility of change",
                    sessoesModelImpress: "Print Model Sessions",
                    condicoes: "Conditions",
                    usuarios: "Users",
                    tipoModelo: "Type Model",
                },
            },

            relatorio: {
                list_vue_html: {
                    obsoleto: "obsolete",
                    relatorios: "Reports",
                    relatVendas: "SALES REPORTS",
                    relaNotas: "NOTES REPORT",
                    relaFaturamentos: "BILLING REPORTS",
                    relaOrcamentos: "BUDGET REPORTS",
                    relaComissao: "COMMISSION REPORTS",
                    relatCustoVenda: "COST OF SALE REPORT",
                    relatCustoVendaFracionamento: "FRACTIONATION COST OF SALE REPORT",
                    relatProdSemVenda: "PRODUCT SELL OUT REPORT",
                    relatVendaXPrescr: "QUANTITY OF SALES PER PRESCRIPTOR",
                    relatVendaComDesc: "DISCOUNT SALE REPORT",
                    relatPrescrDiasSemPrescricoes: "PRESCRIPTORS REPORT: DAYS WITHOUT PRESCRIPTIONS",
                    relatVendaPorCliente: "SALES REPORT BY CUSTOMER",
                    relatVendasPorEspecie: "SALES BY SPECIES REPORT",
                    relatVendasPorPrescr: "SALES REPORT BY PRESCRIBER",
                    relatVendasPorProds: "SALES REPORT BY PRODUCTS",
                    relatVendasPorProdsClientes: "SALES REPORT BY PRODUCTS AND CUSTOMERS",
                    relatVendasPorTipoPgto: "SALES REPORT BY PAY TYPE",
                    relatVendasPorVendedor: "SALES BY SELLER REPORT",
                    relatVendasPorVisitador: "SALES PER VISITOR REPORT",
                    relatFatuamentoFranquia: "FRANCHISE BILLING REPORT",
                    relatFatuamentoFracionamento: "FRACTIONATION BILLING REPORT",
                    relatComissaoVisitadorSobreVendas: "VISITOR COMMITTEE REPORT ON SALES",
                    relatComissaoVendedorSobreVendas: "CLERK COMMITTEE REPORT ON SALES",
                    relatOrcamentosRejeitados: "REPORT REJECTED BUDGETS",
                    relatVendasPorTransportador: "SALES REPORT BY CARRIER",
                    relatVendasSemNota: "SALES REPORT WITHOUT NOTE",
                    relatVendasSemFaturamento: "SALES REPORT WITHOUT BILLING",
                    relatRegistroReceituarioGeral: "GENERAL RECEIPT RECORD",
                    relatFaturamento: "BILLING REPORT",
                    relatsProdutos: "PRODUCT REPORTS",
                    relatContinuos: "CONTINUOUS REPORT",
                    relatRecompensaUtilizadaCampanha: "SCORE/REWARDS REPORT",
                    relatDadosGeraisProduto: "GENERAL PRODUCT DATA REPORT",
                    relatCustoMedioProdutoVendido: "AVERAGE COST OF GOODS SOLD REPORT",
                    relatsGerenciais: "MANAGEMENT REPORTS",
                    relatCustoMarkupProds: "COST AND MARKUP OF PRODUCTS REPORT",
                    relatsFinanceiro: "FINANCIAL REPORTS",
                    relatContasAPagar: "REPORTACCOUNTS PAYABLE",
                    relatContasAReceber: "REPORT ACCOUNTS RECEIVABLE",
                    relatContasPagas: "PaysAccounts REPORT",
                    relatContasRecebidas: "REPORT ACCOUNTS RECEIVED",
                    relatFluxoCaixa: "CASH FLOW REPORT",
                    relatProdutosFinanceiro: "FINANCIAL PRODUCTS REPORT",
                    relatEstoque: "STOCK REPORTS",
                    relatMovimentacaoLote: "LOT MOVEMENT REPORT",
                    relatLotes: "BATCH REPORT ",
                    relatComprasProduto: "PRODUCT PURCHASE REPORT",
                    relatVendasCompromentendoLote: "SALES REPORT COMPROMISING LOT",
                    relatSaldoProdutos: "PRODUCT BALANCE REPORTS",
                    relatPrescritores: "PRESCRIBERS REPORT",
                    relatCobranca: "CHARGE REPORT",
                    relatProdutoVendidosDrogavet: "REPORT OF PRODUCTS SOLD DROGAVET",
                    outros: "OTHERS",
                    relatAniversario: "ANNIVERSARY REPORT",
                    relatorioPontuacoesPorPrescritor: "DOTZ SCORE REPORT BY PRESCRIBER",
                    relatorioAberturasCaixa: "CASH OPENINGS REPORT",
                    relatorioPCPEtapas: "PCP STATUS REPORT STEPS",
                    relatorioProdutosControlados: "REPORT CONTROLLED PRODUCTS (SIPROQUIM)",
                    relatorioConsumoProdutoPorFranquia: "PRODUCT CONSUMPTION REPORT BY FRANCHISE",
                    relatorioVendasPorFormaFarmaceutica: "SALES REPORT FOR PHARMACEUTICAL FORMS",
                    relatorioNotasSaida: "OUTLET NOTES REPORT",
                    relatorioEvolucaoVendas: "SALES EVOLUTION REPORT",
                    relatorioVendasPorPeriodo: "SALES REPORT BY PERIOD",
                    relatorioProdutosMaisVendidos: "TOP SELLING PRODUCTS",
                    relatorioVendaPorCidade: "SALES REPORT BY CITY",
                    relatorioVendaComTroco: "SALES REPORT WITH CHANGE",
                    relatorioVendaComCupom: "SALES REPORT WITH COUPON",
                    relatorioTaxaEntrega: "DELIVERY FEE REPORT",
                    relatorioOrcamento: "BUDGET REPORT",
                    relatorioControleReclamacao: "COMPLAINT CONTROL REPORT",
                    relatorioVendasPorColaborador: "SALES REPORT BY EMPLOYEE",
                    relatorioVendasPorEstoque: "REPORT OF BANDAGES BY RAPID",
                    relatorioAcompanhamentoFranqueadora: "FRANCHISOR MONITORING REPORT",
                },

                configuracaoImpressao: {
                    tipo: "File format",
                    showSubItens: "Export sub items",
                    gerarPDFHorizontal: "Generate PDF Horizontally",
                },
            },

            sessaoCaixa: {
                aberturaCaixa_vue_html: {
                    pontoVenda: "Point of Sale",
                    saldoInicial: "Initial Balance",
                    gerarDifSaldoInicial: "Generate initial balance difference",
                    formaRecebiTransferencia: "Transfer Receipt Form",
                    formaRecebiOrigemDifCaixa:
                        "Form of Receipt whose origin is different from the cashier of the open Session",
                    contaCorrenteDestino: "Destination Current Account",
                    confirmarAbertura: "Confirm Open",
                },

                fechamentoCaixa_vue_html: {
                    fechamentoCaixa: "Cash Closing",
                    movimentacoesCaixa: "Cash Transactions",
                    realizadas: "Accomplished",
                    saldoInicial: "Initial Balance",
                    saldoAtual: "Current Balance",
                    somaEntradas: "Sum of Entries",
                    somaSaidas: "Sum of Exits",
                    somaDiferenc: "Sum of Differences",
                    totalSaldoGeral: "Total General Balance",
                    somaEntradasSubtraindoSaidas: "Sum of all Entries subtracting all Exits",
                    resumoCaixa: "Cashier Summary",
                    dtHraAbertura: "Opening Date / Time",
                    dtHoraFechamento: "Date / Time Closing",
                    saldoFechamento: "Closing Balance",
                    sangria: "Bloodletting",
                    reforcos: "Reinforcements",
                    movimentacoesDinheiro: "Cash Transactions",
                    movimentacoesCredito: "Movements in Credit",
                    movimentacoesDebito: "Debit Transactions",
                    movimentacoesBoleto: "Billet Transactions",
                    movimentacoesTransferencia: "Transfer Movements",
                    movimentacoesCheque: "Check Transactions",
                    anterior: "Previous",
                    proximo: "Next",
                    concluir: "Finish",
                },

                listMovimentosCaixa_vue_html: {
                    movimentacoesCaixa: "Cash Transactions",
                    realizadas: "Accomplished",
                    saldoInicial: "Initial Balance",
                    saldoAtual: "Current Balance",
                    gerarReforco: "Generate Reinforcement",
                    gerarSangria: "Generate Cash Out",
                },

                listMovimentacoesPendente_vue_html: {
                    movimentacoesPendente: "Pending Transactions",
                    cadastradas: "Registered",
                    reprocessar: "Reprocess",
                },

                listCaixa_vue_html: {
                    sessaoCaixa: "Cashier Session",
                    fechamentoCaixa: "Box closing",
                },

                reforco_vue_html: {
                    descricao: "Description",
                    saldoAtual: "Current Balance",
                    valorReforco: "Reinforcement Value",
                    totalCalculado: "Calculated Total",
                    contaCorrente: "Current Account",
                    frmRecebimentoTransf: "Transfer Receipt Form",
                    tooltipFormaRecebimento: "Form of Receipt whose origin is DIFFERENT from the Open Session cashier",
                },

                sangria_vue_html: {
                    descricao: "Description",
                    saldoAtual: "Current Balance",
                    sangria: "Cash Withdrawal",
                    saldoCalculado: "Calculated Balance",
                    saldoPermanPDV: "Balance that will remain at the POS",
                    contaCorrenteDestino: "Destination Current Account",
                    frmRecebimentoTransf: "Transfer Receipt Form",
                    tooltipContaCorrente: "Current Account that is not the same as the Open Session cashier",
                    tooltipFormaRecebimento: "Form of Receipt whose origin is EQUAL from the Open Session cashier",
                },
            },

            shortcut: {
                shortcut_vue_html: {
                    addNovo: "Add New",
                },
            },

            siproquim: {
                edit_vue_html: {
                    siproquimGeracao: "Siproquim - Generation",
                    dataInicial: "Initial Date",
                    dataFinal: "Final Date",
                    geraCadastroProdutos: "Generate product registration record (PR)",
                    gerarArquivo: "Generate File",
                },
            },

            sngpc: {
                list_vue_html: {
                    sngpc: "SNGPC",
                    gerados: "Generated",
                    gerarNovoRelatorio: "Generate New Report",
                    gerarInventario: "Gerar Novo Inventário",
                    visuProdutos: "View Products",
                    confirmaGeracaoInventario:
                        "ATENÇÃO. Confira no site da ANVISA as situações para criação de um novo inventário. Confirma a geração de um novo inventário?",
                    dataInicio: "Initial date",
                    dataFinal: "Final date",
                },

                sngpc_vue_html: {
                    funcionalidadeEmDesenvol: "This functionality is under development",
                    aguardeProxAtualiz: "Wait for the next update before you can access it",
                    duvidasEntrarContato: "If you have any questions, please contact us",
                },

                ver_vue_html: {
                    sngpcProds: "SNGPC Products",
                },
            },

            statusPCP: {
                view_vue_html: {
                    statusPCP: "PCP status",
                    etapa: "Stage",
                    venda: "Sale",
                    dataInicial: "Start date",
                    dataFinal: "End date",
                    codigo: "Code",
                    pcpRapido: "Fast PCP",
                },
            },

            topmenu: {
                topmenu_vue_html: {
                    suporte: "Support",
                    cidade: "city",
                    caixaAberto: "Opened Cashier",
                    caixaFechado: "Closed Cashier",
                    notificacoes: "Notifications",
                    semNotificacoesRecentes: "No Recent Notifications",
                    novas: "New",
                    marcarTodasComoLidas: "Mark all as read",
                    marcarComoLido: "Mark as Read",
                    antigos: "Old",
                    marcarComoNaoLido: "Mark as Unread",
                    verMais: "See More",
                    alterarSenha: "Change Password",
                    sair: "Logout",
                    alterarIdioma: "Change Language",
                    notasDaVersao: "Release Notes",
                    emailNotasDaVersao: "Version Email",
                    logLevel: "Log Level",
                    ambienteDesenvolvimento: "Development environment",
                    ambienteHomologacao: "Homologation environment",
                    ambienteTrainamento: "Training environment",
                    statusSistema: "System Status",
                },
            },

            venda: {
                cancelamento_vue_html: {
                    vendaCancelCodigo: "Sale Cancellation - Code: ",
                    vendaJaCancelada: "Sale is already canceled! You cannot change the data. ",
                    selecionaItensCancelar:
                        "Attention! Select all the items you want to cancel, you will not be able to cancel again after saving",
                    dadosVenda: "Sales data",
                    dtVenda: "Sale Date",
                    nomeCliente: "Customer Name",
                    vlrTotal: "Total Amount",
                    dtCancelamento: "Cancellation Date",
                    userCancelamento: "User Cancellation",
                    motivoCancelamento: "Reason for Cancellation",
                    obsCancelamento: "Cancellation Notice",
                    itensVenda: "Items for Sale",
                    item: "Item",
                    qtd: "Quantity",
                    itensManipulCod: "Items for handling cod: ",
                    naoHaMovimentEstq: "There is no stock movement in this sale!",
                    naoFoiRealizEstornoVenda: "There was no reversal of this sale!",
                    naoHaMovimentoFinanceiro: "There is no financial movement for this sale!",
                },

                edit_vue_html: {
                    vendaEdit: "Sale - Edition",
                },

                editSingle_vue_html: {
                    dtVencimentoContinuoVazio: "Enter an due date for continuous",
                    vendaJaConcluida: "Sale is already concluded! You cannot change the data. ",
                    vendaJaCancelada: "Sale is canceled! You cannot change the data. ",
                    origemAtend: "Origin Service",
                    tipoVenda: "Tipo da Venda",
                    situacao: "Situation",
                    codigo: "Code",
                    cliente: "Customer",
                    editCliente: "Edit Client",
                    novoCliente: "New Customer",
                    dtAprov: "Date/Time Approval",
                    dtConclusao: "Completion Date",
                    dtHraEntrega: "Delivery Date / Time",
                    continuo: "Continuous",
                    dtVencimentoContinuo: "Continuous due date",
                    origem: "Origin",
                    obs: "Observation",
                    vlrProds: "Value Products",
                    taxaEntrega: "Delivery fee",
                    acrescimo: "Increase",
                    desconto: "Discount",
                    descBeneficio: "Benefit Discount",
                    vlrFinal: "Final Value",
                    dadosFiscais: "Tax data",
                    emitiNFEAposSalvarConcluir: "Issue NFS-e Automatically after Save / Complete Sale",
                    detalhes: "Details",
                    permitirFinalSemEmissao: "Allow finalizing sale without issuing a note",
                    detalhesNFe: "NF-e Details",
                    campanha: "Campaign",
                    cpf: "CPF",
                    recompensas: "Rewards",
                    ptsAcumulados: "Accumulated Points",
                    ptsCompra: "Purchase Points",
                    totalPts: "Total Points",
                    dadosComplementOrcamento: "Complementary Budget Data",
                    dtInclusao: "Inclusion Date",
                    dtValOrcamento: "Dt Val. Budget",
                    transportadora: "Shipping Company",
                    transportador: "Carrier",
                    salvarImprimir: "Save and Print",
                    aprovOrcamento: "Approve Budget",
                    emitirNota: "Issue Note",
                    faturar: "Bill",
                    concluir: "Finish",
                    financeiro: "Financial",
                    enviarOrcamento: "Share Budget",
                    imprimirOrcamento: "Print Budget",
                    receita: "Attach file",
                    expedicao: "Expedition",
                    confirAlteracoes: "Confirm Changes",
                    novoPgto: "New Payment",
                    selecione: "Select",
                    pagamentos: "Payments",
                    movimentacaoEstoque: "Stock movement",
                    nomeSeparador: "Separator Name",
                    identificarClienteNF: "Identify Customer on Invoice",
                    tlpIdentificarClienteNF: "Customer must have the CPF informed in the registration",
                    localEntrega: "Delivery Locations",
                    cashback: "CashBack",
                    cashbackAplicar: "Apply Discount",
                    desconcluirVenda: "Undo Completion",
                    estoqueOrigem: "Franchise stock",
                    setor: "Sector",
                    historicoVendas: "Sales history",
                    notaTransporte: "Transport Note",
                    entrega: "Delivery",
                    gerarCodigoRastreio: "Generate tracking code",
                    obsDesconto: "Discount observation",
                    acrescimoDesc: "Increase/Discount",
                },

                enderecoEntrega_vue_html: {
                    enderecoEntrega: "Shipping address",
                    dadosTransporte: "Transport Data",
                    cep: "zip code",
                    estado: "State",
                    cidade: "City",
                    endereco: "Address",
                    nro: "Number",
                    bairro: "Neighborhood",
                    complemento: "Complement",
                    enderecoPadrao: "Default Address",
                    pesoBrutoKg: "Gross Weight (kg)",
                    pesoLiqKg: "Net Weight (kg)",
                    volumesUn: "Volumes (Un)",
                    transportadora: "Shipping Company",
                    transportador: "Conveyor",
                    taxaEntrega: "Delivery fee",
                    codigoRastreio: "Tracking code",
                },

                estorno_vue_html: {
                    contaGerencial: "Management Account",
                    frmPgto: "Payment Method",
                    contaCorrente: "Current Account",
                    vlrPago: "Amount Paid",
                    obs: "Observation",
                },

                item_vue_html: {
                    produto: "Product",
                    produtoLote: "Product Lot",
                    qtde: "Quantity",
                    vlrUnit: "Value Unit.",
                    acrescimo: "Addition",
                    desconto: "Discount",
                    markupProduto: "Product branding",
                    vlrTotal: "Total Amount",
                    posologia: "Posology",
                    editPosologia: "Edit Posology",
                    novaPosologia: "New Posology",
                    paciente: "Patient",
                    editPaciente: "Edit Patient",
                    novoPaciente: "New Patient",
                    prescritor: "Prescriber",
                    editPrescritor: "Edit Prescriber",
                    novoPrescritor: "New Prescriber",
                },

                itemwrapper_vue_html: {
                    manipulacao: "Compounding",
                    acabado: "Finished",
                    fracionamento: "fractionation",
                    homeopatia: "Homeopathy",
                    alterar: "Change",
                    add: "Add",
                    naoImplementado: "Not implemented...",
                    total: "Total : ",
                    infoAdic: "Additional Information",
                    continuos: "Continuous",
                    continuo: "Continuous",
                    tipoNF: "Invoice Type",
                    movimentaEstq: "Moves Stock",
                },

                linkPagamento_vue_html: {
                    linkPagamento: "Payment link",
                    gerarLink: "Generate new Link",
                    copiarAreaTransf: "Copy to clipboard",
                },

                list_vue_html: {
                    cadastrados: "Registered",
                    cadastradas: "Registered",
                    vendas: "Sales",
                    tudo: "All",
                    aFaturar: "A Invoice",
                    faturado: "Billed",
                    concluido: "Done",
                    cancelado: "Canceled",
                    de: "From:",
                    ate: "Until:",
                    manipulacao: "Compounding",
                    repetirVendas: "Repeat selected",
                },

                listExpedicao_vue_html: {
                    title: "Sales in Expedition",
                    finalizar: "Finalize",
                    setor: "Sector",
                },

                listOrcamento_vue_html: {
                    cadastrados: "Registered",
                    orcamento: "Budget",
                    orcamentos: "Budgets",
                    rejeitados: "Rejected",
                    ambos: "Both",
                    de: "From:",
                    ate: "Until:",
                    confirmAlteracoes: "Confirm Changes",
                },

                pagamento_vue_html: {
                    recebimento: "Receipt",
                    dtRecebimento: "Receipt Date",
                    contaCorrente: "Current Account",
                    especie: "Species",
                    bandeira: "Flag",
                    frmaRecebimento: "Receipt Form",
                    mtdParcelamento: "Installment Method",
                    autorizNSUCartao: "Card Authorization / NSU",
                    obs: "Observation",
                    vlrAbater: "Amount to subtract",
                    juros: "Interest",
                    desconto: "Discount",
                    totalRecebido: "Total Received",
                    inclRecebimento: "Include Receipt",
                },

                revisaoCampos_vue_html: {
                    manipulacao: "Compounding",
                    ativoPrincipal: "Main Active",
                    posologia: "Posology",
                    editPosologia: "Edit Posology",
                    novaPosologia: "New Posology",
                    paciente: "Patient",
                    editPaciente: "Edit Patient",
                    novoPaciente: "New Patient",
                    prescritor: "Prescriber",
                    editPrescritor: "Edit Prescriber",
                    novoPrescritor: "New Prescriber",
                    nroReceita: "Revenue Number",
                },

                vendaEstoque_vue_html: {
                    produto: "Product",
                    quantidadeComprometida: "Committed Quantity Product",
                    quantidadeComprometidaTitle: "Committed Quantity of the product in fractioning",
                    quantidadeComprometidaLote: "Committed Lot Quantity",
                    quantidadeComprometidaLoteTitle: "Committed quantity of the lot in fractioning",
                    quantidadeDisponivel: "Qty. Available",
                    prodLote: "Batch Product",
                    qtd: "Quantity",
                    vlrUnit: "Value Unit.",
                    acrescimo: "Addition",
                    desconto: "Discount",
                    vlrTotal: "Total Amount",
                    embalagemProd: "Product Packaging",
                    kitEmbalagem: "Packing Kit",
                    unidadeMed: "Unit of Measurement",
                    infoLote: "Lot Information",
                    dataVal: "Expiration Date",
                    qtdEstoque: "Quantity in Stock",
                    valorCusto: "Cost Value",
                    observacaoArmazenamento: "Note Storage",
                },

                financeiro: {
                    financeiro_vue_html: {
                        addParcela: "Add Portion",
                        addRecebimento: "Add Receipt",
                    },

                    parcelamento_vue_html: {
                        descricao: "Description",
                        dataVencimento: "Expiration",
                        valor: "Value",
                    },

                    preFaturamento_vue_html: {
                        valorSerPago: "Amount to be paid",
                        troco: "Change",
                        parcelas: "Installments",
                    },
                },
            },

            statusSistema: {
                statussistema_vue_html: {
                    title: "System Status",
                },
            },
        },

        child: {
            form: {
                combo_vue_html: {
                    nenhumaOpcEncont: "No options found",
                },

                filepicker_vue_html: {
                    arqSelecionado: "Selected file:",
                    cliqueParaSelecArq: "Click here to select the file",
                },

                sei_vue_html: {
                    tipoProcesso: "Process Type",
                    especificacoes: "Specifications",
                    classificacao: "Classification",
                    interessado: "Interested",
                    destino: "Destiny",
                    obsUnidade: "Unit Observations",
                    endereco: "E-mail",
                    numProcesso: "Previous Process No.",
                    outrasInfo: "Other Information",
                },
            },

            grid: {
                grid_vue_html: {
                    procurarPor: "Search by...",
                    imprimirRegistrosAbaixo: "Print records shown below",
                    gerarXLSdosArq: "Generate file of type Xls from the records shown below.",
                    gerarPDFdosArq: "Generate Pdf file of the records shown below.",
                    cash: "Account",
                    de: "From:",
                    ate: "Until:",
                    pgtoDe: "Payment From:",
                    grupo: "Group:",
                    subgrupo: "Subgroup:",
                    fornecedor: "Supplier:",
                    categoria: "Category:",
                    acoes: "Actions",
                    exibindo: "Showing",
                    dee: "de",
                    registros: "records",
                    addNovo: "Add new",
                },
            },

            rascunho: {
                rascunho_vue_html: {
                    rascunho: "Draft",
                    salvarRascunho: "Save Draft",
                    selecionar: "Select",
                    remover: "Remove",
                },
            },

            searchCombo: {
                search_vue_html: {
                    digiteParaProcurar: "Type to search...",
                },
            },
        },
    },

    Crud: null,
};

__.Crud = __.Components.parent.crud;

export default { __ };
