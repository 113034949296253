import BaseModel from "./base/baseModel";
import ConfiguracaoAtendimentoHorario from "./configuracaoAtendimentoHorarioModel";
import TipoTemplateWhatsApp from "./enum/TiposTemplateWhatsApp";

export default class ConfiguracaoAtendimentoModel extends BaseModel {
    id?: number = null;
    texto = "";
    tipo: TipoTemplateWhatsApp = null;
    tipoDescricao = "";
    configuracaoAtendimentoHorario: ConfiguracaoAtendimentoHorario[] = [];
}
