import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImprimirRotuloPersonalizadoComponent from "@/components/parent/crud/rotulopersonalizado/imprimirRotulo";
import impressaoRotuloComponent from "@/components/parent/crud/rotulopersonalizado/imprimirRotulo.vue";
import ImprimirRotuloPersonalizadoComModaComponent from "@/components/parent/crud/rotulopersonalizado/imprimirRotuloComModal";
import imprimirRotuloPersonalizadoComModalComponent from "@/components/parent/crud/rotulopersonalizado/imprimirRotuloComModal.vue";
import PrintModel from "@/components/parent/printModel/printModel";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import ConfiguracaoUsuarioModel from "@/models/configuracaoUsuarioModel";
import ConfiguracoesUsuario from "@/models/enum/configuracao/configuracoesUsuario";
import ModeloEspecifico from "@/models/enum/modeloEspecifico";
import ModeloPreDefinido from "@/models/enum/modeloPreDefinido";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import ModeloImpressaoModel from "@/models/modeloImpressao/modeloImpressaoModel";
import UsuarioModel from "@/models/usuarioModel";
import VendaModel from "@/models/vendaModel";
import ColetaService from "@/services/expedicao/coletaService";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";
import PDFService from "@/services/pdf/PDFService";
import { HtmlPrintPageInfo } from "@/services/print/printerQueue";
import PrinterService from "@/services/print/printerService";
import VendaService from "@/services/vendaService";
import { AppState, Getters } from "@/store/store";

import { ModalButtonAction } from "../modal/modalButtonAction";

type TipoImpressao = "Venda" | "Manipulacao" | "Lote" | "Coleta";

type ShowType = {
    modelId: number;
    tipoImpressao: TipoImpressao;
    impressaoTodosModelo?: boolean;
    impressaoSelecionarModelo?: boolean;
    impressaoRotulo?: boolean;
    origemRotulo?: OrigemRotulo;
    modeloEspecifico?: ModeloEspecifico;
};

class ModeloImpressaoType {
    descricao: string;
    idModeloImpressao: number;
    idRegistro: number;
    impressora: string;
    modeloDescricao: string;
    copias: number;
    modeloPreDefinido: ModeloPreDefinido;
    tipo: "VENDA" | "MANIPULACAO" | "COLETA";

    constructor(modelo: ModeloImpressaoModel, tipo: "VENDA" | "MANIPULACAO" | "COLETA", usuarioId: number) {
        this.idRegistro = modelo.idRegistro;
        this.idModeloImpressao = modelo.id;
        if (modelo.usuarios.some(p => p.usuarioId == usuarioId)) {
            this.impressora = modelo.usuarios.find(p => p.usuarioId == usuarioId)?.nomeImpressora ?? "browser";
        } else {
            this.impressora = modelo.nomeImpressora ?? "browser";
        }
        this.modeloDescricao = modelo.descricao;
        this.copias = 1;
        this.modeloPreDefinido = modelo.modeloPreDefinido;
        this.tipo = tipo;
    }
}

type ManipulacaoImpressaoType = { id: number; nome: string };

@Component({
    components: {
        shortcutComponent,
        impressaoRotuloComponent,
        imprimirRotuloPersonalizadoComModalComponent,
        gridComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO", "GET_IS_FRACIONAMENTO", "GET_CONFIG_USUARIO"] as Getters),
    },
})
export default class ImpressaoComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    GET_CONFIG_USUARIO: (configuracao: ConfiguracoesUsuario) => ConfiguracaoUsuarioModel;

    GET_IS_FRACIONAMENTO: () => Promise<boolean>;

    private printerService = new PrinterService();

    private showData: ShowType = {
        modelId: null,
        tipoImpressao: "Venda",
        impressaoRotulo: false,
        impressaoSelecionarModelo: false,
        impressaoTodosModelo: false,
        origemRotulo: null,
        modeloEspecifico: null,
    };

    private vendaService = new VendaService();
    private manipulacaoOrdemService = new ManipulacaoOrdemService();
    private coletaService = new ColetaService();
    private fracionamentoPDFService = new PDFService("FracionamentoPDF");
    private shortcutImpressao: ShortcutComponent = null;
    private impressaoRotuloComponent: ImprimirRotuloPersonalizadoComponent = null;
    private imprimirRotuloPersonalizadoComModalComponent: ImprimirRotuloPersonalizadoComModaComponent = null;

    private gridData: ModeloImpressaoType[] = [];
    private initialCheckedModelos: ModeloImpressaoType[] = [];
    private get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descricao") as string, GridColumnType.String),
            new GridColumn("modeloDescricao", this.$t("__.ts.model") as string, GridColumnType.String),
            new GridColumn("copias", this.$t("copias") as string, GridColumnType.Integer, true),
            new GridColumn("impressora", "Impressora", GridColumnType.String),
        ];
    }
    private modelosSelecionados: ModeloImpressaoType[] = [];
    private venda: VendaModel = null;
    private isMultiplePageRotulos = false;
    private checkImprimirRotulos = true;

    extraActions: Array<object> = [];
    gridExtraActionsVisualizar: Array<GridAction> = [];
    gridExtraActionsImprimir: Array<GridAction> = [];

    private mounted() {
        this.shortcutImpressao = this.$refs.shortcutImpressao as ShortcutComponent;
        this.impressaoRotuloComponent = this.$refs.impressaoRotuloComponent as ImprimirRotuloPersonalizadoComponent;
        this.imprimirRotuloPersonalizadoComModalComponent = this.$refs
            .imprimirRotuloPersonalizadoComModalComponent as ImprimirRotuloPersonalizadoComModaComponent;
    }

    public async show({
        modelId,
        tipoImpressao,
        impressaoTodosModelo = false,
        impressaoSelecionarModelo = false,
        impressaoRotulo = true,
        origemRotulo = null,
        modeloEspecifico = null,
    }: ShowType) {
        this.showData.modelId = modelId;
        this.showData.tipoImpressao = tipoImpressao;
        this.showData.impressaoTodosModelo = impressaoTodosModelo;
        this.showData.impressaoSelecionarModelo = impressaoSelecionarModelo;
        this.showData.impressaoRotulo = impressaoRotulo;
        this.showData.origemRotulo = origemRotulo;
        this.showData.modeloEspecifico = modeloEspecifico;
        this.isMultiplePageRotulos = false;
        this.checkImprimirRotulos =
            this.GET_CONFIG_USUARIO(ConfiguracoesUsuario.MarcarModeloRotulo)?.verdadeiro ?? true;

        if (
            (this.showData.tipoImpressao == "Venda" &&
                !this.VALIDAR_PERMISSAO_USUARIO("vendas", "ImprimirRotuloVenda")) ||
            (this.showData.tipoImpressao == "Manipulacao" &&
                !this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ImprimirRotuloManipulacao"))
        ) {
            this.showData.impressaoRotulo = false;
        }

        if (
            (this.showData.impressaoTodosModelo || this.showData.impressaoSelecionarModelo) &&
            this.showData.impressaoRotulo
        ) {
            this.shortcutImpressao.title = "Impressão de Modelo e Rótulo";
        } else if (this.showData.impressaoTodosModelo || this.showData.impressaoSelecionarModelo) {
            this.shortcutImpressao.title = "Impressão de Modelo";
        } else if (this.showData.impressaoRotulo) {
            this.shortcutImpressao.title = "Impressão de Rótulo";
        }

        let showModal = true;
        this.gridData = [];
        this.initialCheckedModelos = [];
        this.modelosSelecionados = [];

        if (this.showData.impressaoRotulo) {
            showModal = showModal && (await this.carregarRotulos());
        }

        if (this.showData.impressaoTodosModelo || this.showData.impressaoSelecionarModelo) {
            showModal = showModal && (await this.carregarModelosImpressao());
        }

        if (showModal) {
            this.shortcutImpressao.show();
        }
    }

    get modalExtraButtons(): Array<ModalButtonAction> {
        return [
            new ModalButtonAction(
                this.$t("__.ts.salvarRotulo").toString(),
                this.$t("__.ts.salvarRotulo").toString(),
                true,
                "s",
                " btn-info",
            ),
        ];
    }

    private async onExtraButtonImprimirRotulo(name: string) {
        if (name == this.$t("__.ts.salvarRotulo").toString()) {
            await this.impressaoRotuloComponent.salvarRotulo().withLoading();
            await this.$showSuccess(this.$t("__.ts.sucesso"), this.$t("__.ts.salvarRotuloSucesso"));
            this.shortcutImpressao.hide();
        }
    }

    private async carregarModelosImpressao() {
        let modelosVenda: ModeloImpressaoModel[] = [];
        let modelosColeta: ModeloImpressaoModel[] = [];
        let manipulacoes: ManipulacaoImpressaoType[] = [];

        if (this.showData.tipoImpressao == "Venda") {
            modelosVenda = await this.vendaService.getImpressoesModels(this.showData.modelId, "Venda");
        } else if (this.showData.tipoImpressao == "Coleta") {
            modelosColeta = await this.coletaService.getImpressoesModels(this.showData.modelId, "Coleta");
        } else if (this.showData.tipoImpressao == "Manipulacao" && this.showData.impressaoSelecionarModelo) {
            const manipulacao = await this.manipulacaoOrdemService
                .get(this.showData.modelId)
                .resolveWithJSON<ManipulacaoOrdemModel>();

            manipulacoes.push({ id: manipulacao.id, nome: manipulacao.descricao });
        }

        if (this.showData.modeloEspecifico) {
            modelosVenda = modelosVenda.filter(p => p.modeloEspecifico == this.showData.modeloEspecifico);
        }

        const modelosManipulacao: ModeloImpressaoModel[] = [];

        if (this.showData.tipoImpressao == "Venda" && !this.showData.modeloEspecifico) {
            await this.loadVenda();

            manipulacoes = this.venda.itens
                .filter(p => p.manipulacaoOrdem != null)
                .map(p => ({ id: p.manipulacaoOrdemId, nome: p.manipulacaoOrdem.descricao }));
        }

        for (let i = 0; i < manipulacoes.length; i++) {
            try {
                const modelos = await this.manipulacaoOrdemService.getImpressoesModels(
                    manipulacoes[i].id,
                    "Manipulacao",
                );

                modelos.forEach(modelo => {
                    modelo.idRegistro = manipulacoes[i].id;
                    modelosManipulacao.push(modelo);
                });
            } catch {}
        }

        if (modelosVenda.length == 0 && modelosManipulacao.length == 0 && modelosColeta.length == 0) {
            await this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.modelImpressNaoEncont"));
            return false;
        }

        if (this.showData.impressaoSelecionarModelo) {
            if (modelosManipulacao.length == 1) {
                if (this.showData.impressaoRotulo && this.impressaoRotuloComponent.models.length > 0) {
                    // Deve exibir grid caso tenha somente um modelo e tenha rótulo
                    this.showData.impressaoSelecionarModelo = false;
                    this.showData.impressaoTodosModelo = true;
                } else {
                    this.modelosSelecionados.push(
                        new ModeloImpressaoType(modelosManipulacao[0], "MANIPULACAO", this.usuarioLogado.id),
                    );
                    await this.onImprimir();
                    return false;
                }
            }
            if (modelosVenda.length == 1) {
                modelosVenda[0].idRegistro = this.showData.modelId;

                this.modelosSelecionados.push(new ModeloImpressaoType(modelosVenda[0], "VENDA", this.usuarioLogado.id));
                await this.onImprimir();
                return false;
            }
            if (modelosColeta.length == 1) {
                modelosColeta[0].idRegistro = this.showData.modelId;

                this.modelosSelecionados.push(
                    new ModeloImpressaoType(modelosColeta[0], "COLETA", this.usuarioLogado.id),
                );
                await this.onImprimir();
                return false;
            }
        }

        this.loadGridData(modelosVenda, modelosManipulacao, modelosColeta, manipulacoes);
        return true;
    }

    private loadGridData(
        modelosVenda: ModeloImpressaoModel[],
        modelosManipulacao: ModeloImpressaoModel[],
        modelosColeta: ModeloImpressaoModel[],
        manipulacoes: ManipulacaoImpressaoType[],
    ) {
        const checkModeloVenda = this.GET_CONFIG_USUARIO(ConfiguracoesUsuario.MarcarModeloVenda)?.verdadeiro ?? true;
        const checkModeloManipulacao =
            this.GET_CONFIG_USUARIO(ConfiguracoesUsuario.MarcarModeloManipulacao)?.verdadeiro ?? true;

        modelosVenda.forEach(modelo => {
            modelo.idRegistro = this.showData.modelId;
            const data = new ModeloImpressaoType(modelo, "VENDA", this.usuarioLogado.id);
            data.descricao = this.$t("__.ts.venda") as string;
            this.gridData.push(data);
            if (checkModeloVenda) {
                this.initialCheckedModelos.push(data);
            }
        });

        modelosColeta.forEach(modelo => {
            modelo.idRegistro = this.showData.modelId;
            const data = new ModeloImpressaoType(modelo, "COLETA", this.usuarioLogado.id);
            data.descricao = this.$t("__.ts.coleta") as string;
            this.gridData.push(data);
            this.initialCheckedModelos.push(data);
        });

        const sortFunction = (a: ModeloImpressaoModel, b: ModeloImpressaoModel) =>
            a.idRegistro == b.idRegistro ? Number(b.padrao) - Number(a.padrao) : a.idRegistro > b.idRegistro ? 1 : 0;

        const modelosChecked = modelosManipulacao
            .sort(sortFunction)
            .filter((modelo, index, self) => index === self.findIndex(m => m.idRegistro === modelo.idRegistro));

        modelosManipulacao.sort(sortFunction).forEach(modelo => {
            const data = new ModeloImpressaoType(modelo, "MANIPULACAO", this.usuarioLogado.id);
            data.descricao = `${this.$t("__.ts.manipulacao")} ${
                manipulacoes.find(p => p.id == modelo.idRegistro)?.nome
            }`;

            this.gridData.push(data);

            if (checkModeloManipulacao) {
                if (modelosChecked.some(p => p.id == modelo.id && p.idRegistro == modelo.idRegistro)) {
                    this.initialCheckedModelos.push(data);
                }
            }
        });

        this.gridExtraActionsVisualizar.splice(0);
        this.gridExtraActionsImprimir.splice(0);
        this.extraActions.splice(0);

        this.gridData.forEach(() => {
            this.gridExtraActionsVisualizar.push(
                new GridAction("visualizar-modelo", "Visualizar impressão", "fa fa-eye", GridColors.BLUE),
            );
            this.gridExtraActionsImprimir.push(
                new GridAction(
                    "imprimir-modelo",
                    "Impressão individual via navegador",
                    "fa fa-print",
                    GridColors.DARKGRAY,
                ),
            );
        });

        this.extraActions.push(this.gridExtraActionsVisualizar);
        this.extraActions.push(this.gridExtraActionsImprimir);
    }

    private async carregarRotulos(isPrintMultipleRotulos = false) {
        if (this.showData.tipoImpressao == "Venda") {
            await this.loadVenda();

            if (await this.GET_IS_FRACIONAMENTO()) {
                const fracionamentosIds = this.venda.itens
                    .filter(p => p.vendaEstoque)
                    .map(p => p.fracionamento.itens[0].produtoLoteId);

                if (this.isMultiplePageRotulos && isPrintMultipleRotulos) {
                    this.imprimirRotuloPersonalizadoComModalComponent.addRotuloLoteByIds(fracionamentosIds);
                } else {
                    this.impressaoRotuloComponent.addRotuloLoteByIds(fracionamentosIds);
                }
            } else {
                const manipulacoesIds = this.venda.itens
                    .filter(p => p.manipulacaoOrdem != null)
                    .map(p => p.manipulacaoOrdemId);

                if (this.isMultiplePageRotulos && isPrintMultipleRotulos) {
                    this.imprimirRotuloPersonalizadoComModalComponent.addRotuloManipulacaoByIds(manipulacoesIds);
                } else {
                    this.impressaoRotuloComponent.addRotuloManipulacaoByIds(manipulacoesIds);
                }
            }

            const quantidadesLotes = this.venda.itens
                .filter(p => p.vendaEstoque)
                .map(p => ({
                    id: p.fracionamento.itens[0].produtoLoteId,
                    quantidade: p.fracionamento.itens[0].quantidade,
                }));

            this.isMultiplePageRotulos = this.venda.itens.length > 100;

            if (this.isMultiplePageRotulos) {
                if (isPrintMultipleRotulos) {
                    await this.imprimirRotuloPersonalizadoComModalComponent.show({
                        quantidadesLotes,
                        vendaId: this.venda.id,
                        origemRotulo: this.showData.origemRotulo,
                        checkImprimir: this.checkImprimirRotulos,
                    });
                }

                return true;
            }
            return await this.impressaoRotuloComponent.show({
                quantidadesLotes,
                vendaId: this.venda.id,
                origemRotulo: this.showData.origemRotulo,
                checkImprimir: this.checkImprimirRotulos,
            });
        } else if (this.showData.tipoImpressao == "Manipulacao") {
            return await this.impressaoRotuloComponent.showRotuloManipulacao(this.showData.modelId, {
                checkImprimir: this.checkImprimirRotulos,
            });
        } else if (this.showData.tipoImpressao == "Lote") {
            return await this.impressaoRotuloComponent.showRotuloLote(this.showData.modelId, {
                origemRotulo: this.showData.origemRotulo,
                checkImprimir: this.checkImprimirRotulos,
            });
        }
    }

    private onHideModal() {
        this.$emit("hide-modal");
    }

    private async onImprimir() {
        const htmlPages: HtmlPrintPageInfo[] = [];

        if (this.modelosSelecionados.length > 0) {
            const modelos = this.modelosSelecionados
                .filter(p => p.modeloPreDefinido == 0)
                .sort((a, b) => (a.tipo > b.tipo ? 1 : -1));

            for await (const modelo of modelos) {
                const service = this.getService(modelo.tipo);

                htmlPages.push(
                    await service.getHtmlPage(
                        modelo.idRegistro,
                        modelo.idModeloImpressao,
                        modelo.copias,
                        this.usuarioLogado.id,
                    ),
                );
            }

            // Modelos do fracionamento (TODO converter para HTML)
            for await (const modelo of this.modelosSelecionados.filter(p => p.modeloPreDefinido > 0)) {
                await this.imprimeModeloPreDefinido(modelo);
            }
        }

        let mantemModelAberto = await this.printHtmls(htmlPages);

        if (this.isMultiplePageRotulos) {
            await this.carregarRotulos(true);
        } else {
            // Modelos do rótulo
            if (this.showData.impressaoRotulo) {
                const pages = await this.impressaoRotuloComponent.getHtmlPages();

                mantemModelAberto = (await this.printHtmls(pages)) || mantemModelAberto;
            }

            if (!mantemModelAberto) {
                this.shortcutImpressao.hide();
            }
        }
    }

    private async printHtmls(htmlPages: HtmlPrintPageInfo[]) {
        try {
            await this.printerService.enqueuePages(htmlPages);
        } catch (e) {
            await this.$showError(this.$t("__.ts.erroNenhumaImpressoraDisponivel"), e.message);
            return true;
        }

        if (await this.printerService.verifyErrors(htmlPages)) {
            await this.printerService.dequeuePages(htmlPages);
            return true;
        }

        await this.printerService.flushAllQueues();
        return false;
    }

    private async onExtraAction(name: string, modelo: ModeloImpressaoType) {
        if (name.trim() == "visualizar-modelo" || name.trim() == "imprimir-modelo") {
            if (modelo.modeloPreDefinido) {
                await this.imprimeModeloPreDefinido(modelo);
            } else {
                const service = this.getService(modelo.tipo);
                const page = await service
                    .getHtmlPage(modelo.idRegistro, modelo.idModeloImpressao, modelo.copias, this.usuarioLogado.id)
                    .withLoading();

                if (name.trim() == "imprimir-modelo") {
                    await this.printHtmls([page]);
                } else {
                    const printModel = new PrintModel();
                    printModel.showHTMLOnBrowser(page.pageHtml, false);
                }
            }
        }
    }

    private async imprimeModeloPreDefinido(modelo: ModeloImpressaoType) {
        switch (modelo.modeloPreDefinido) {
            case ModeloPreDefinido.PDFFracionamento:
                await this.fracionamentoPDFService.geraPDF(modelo.idRegistro);
                break;
        }
    }

    private onSelectedModelosChanged(value: ModeloImpressaoType[]) {
        this.modelosSelecionados = value;
    }

    private async loadVenda() {
        if (this.venda == null || this.venda.id != this.showData.modelId) {
            this.venda = await this.vendaService.get(this.showData.modelId).resolveWithJSON<VendaModel>();
        }
    }

    private getService(tipo: "VENDA" | "MANIPULACAO" | "COLETA") {
        switch (tipo) {
            case "COLETA":
                return this.coletaService;
            case "MANIPULACAO":
                return this.manipulacaoOrdemService;
            case "VENDA":
                return this.vendaService;
        }
    }
}
