import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import PaginationModel from "@/models/paginationModel";
import ConfiguracaoEtapaPCPModel from "@/models/pcp/configuracaoEtapaPCPModel";
import EtapaPCPModel from "@/models/pcp/etapaPCPModel";
import ConfiguracaoEtapaPCPService from "@/services/configuracaoEtapaPCPService";
import EtapaPCPService from "@/services/etapaPCPService";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        comboComponent,
    },
})
export default class ConfiguracaoEtapaPCP extends EditComponentBase<ConfiguracaoEtapaPCPModel> {
    etapaGradeVendaOptions: Array<Object> = [];
    etapaGradePendenteOptions: Array<Object> = [];

    private configuracaoEtapaPCPService = new ConfiguracaoEtapaPCPService();

    private async loadOptions() {
        try {
            const data = await new EtapaPCPService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<EtapaPCPModel>>();

            this.etapaGradeVendaOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
            this.etapaGradePendenteOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    private async beforeMount() {
        await this.loadOptions();

        await this.setProps(
            this.configuracaoEtapaPCPService,
            "configuracao_etapa_pcp",
            new ConfiguracaoEtapaPCPModel(),
        );

        await this.load();
    }

    public async load(): Promise<void> {
        try {
            const data = await this.configuracaoEtapaPCPService
                .getByFranquia()
                .withLoading()
                .resolveWithJSON<ConfiguracaoEtapaPCPModel>();

            this.model.updateFrom(data);
        } catch {}
    }
}
