import PreLoadPackService from "@/services/preLoadPackService";
import { LoadListProps } from "@/store/loadListStore";
import {
    getFormaFarmaceuticaCombo,
    getKitEmbalagemComboText,
    getPosologiaCombo,
    getProdutoCombo,
    getUnidadeMedidaCombo,
} from "@/utils/common/combo/combotext";

import CapsulaModel from "../capsulaModel";
import ConfiguracaoRotuloModel from "../configuracaoRotuloModel";
import EstoqueModel from "../estoqueModel";
import ProdutoModel from "../produto/produtoModel";
import CampanhaModel from "../programafidelidade/campanhaModel";
import SetorModel from "../setorModel";
import LocalEntregaModel from "../transporte/localEntregaModel";
import UsuarioModel from "../usuarioModel";

import BaseModel from "./../base/baseModel";
import FormaFarmaceuticaModel from "./../formaFarmaceuticaModel";
import FormulaPadraoModel from "./../formulaPadraoModel";
import KitEmbalagemModel from "./../kitEmbalagemModel";
import PosologiaModel from "./../posologiaModel";
import unidadeMedidaModel from "./../unidadeMedidaModel";
import UnidadeMedidaModel from "./../unidadeMedidaModel";

//pacote de arrays usados normalmente em combos e listas simples
export default class PreLoadPackModel extends BaseModel {
    currentFranquiaId: number = null;

    unidadesMedida: Array<UnidadeMedidaModel> = [];
    unidadeMedidaCombo(onlySigla = false) {
        return this.unidadesMedida.map(item => getUnidadeMedidaCombo(item, onlySigla));
    }

    formasFarmaceuticas: Array<FormaFarmaceuticaModel> = [];
    formaFarmaceuticaCombo() {
        return this.formasFarmaceuticas.map(getFormaFarmaceuticaCombo);
    }

    posologias: Array<PosologiaModel> = [];
    async posologiaCombo(ativo?: boolean) {
        if (ativo) {
            const listaPosologias = this.posologias.map(getPosologiaCombo);
            return listaPosologias.filter(p => p.ativo == ativo);
        }
        return this.posologias.map(getPosologiaCombo);
    }

    kitsEmbalagem: Array<KitEmbalagemModel> = [];
    kitEmbalagemCombo = () =>
        this.kitsEmbalagem ? this.kitsEmbalagem.map(p => ({ value: p.id, text: getKitEmbalagemComboText(p) })) : [];

    formulasPadrao: Array<FormulaPadraoModel> = [];

    produtos: Array<ProdutoModel> = [];
    produtosCombo = () => this.produtos.map(getProdutoCombo);
    produtosComboOnlyAtivos = () => this.produtos.filter(p => p.ativo).map(getProdutoCombo);

    estoques: Array<EstoqueModel> = [];
    estoquesCombo = () => this.ConvertToComboListDescricao<EstoqueModel>(this.estoques);

    locaisEntrega: Array<LocalEntregaModel> = [];
    locaisEntregaCombo = () =>
        this.locaisEntrega ? this.locaisEntrega.map(p => ({ value: p.id, text: `${p.codigo} - ${p.descricao}` })) : [];

    campanhas: Array<CampanhaModel> = [];
    campanhasCombo = () => this.ConvertToComboListDescricao<CampanhaModel>(this.campanhas);

    capsulas: Array<CapsulaModel> = [];
    capsulasCombo = () => this.ConvertToComboListDescricao<CapsulaModel>(this.capsulas);

    vendedores: Array<UsuarioModel> = [];
    vendedoresCombo = () => (this.vendedores ? this.vendedores.map(p => ({ value: p.id, text: p.nome })) : []);

    rotulos: Array<ConfiguracaoRotuloModel> = [];
    rotulosCombo = () => (this.rotulos ? this.rotulos.map(p => ({ value: p.id, text: p.nome })) : []);

    setores: Array<SetorModel> = [];
    setoresCombo = () => (this.setores ? this.setores.map(p => ({ value: p.id, text: p.descricao })) : []);

    dadosFranquiaRotulo: Array<string> = [];

    public ConvertToComboListNome<T>(model: Array<T>) {
        if (model != null)
            return model.map(item => ({
                value: item["id"],
                text: item["nome"],
            }));
        return [];
    }

    public ConvertToComboListDescricao<T>(model: Array<T>) {
        if (model != null)
            return model
                .filter(p => p["franquiaId"] == null || p["franquiaId"] == this.currentFranquiaId)
                .map(item => ({
                    value: item["id"],
                    text: item["descricao"],
                }));
        return [];
    }

    async LoadAllPackLists() {
        await this.LoadPackLists({
            loadUnidadeMedida: true,
            loadFormaFarmaceutica: true,
            loadPosologia: true,
            loadKitEmbalagem: true,
            loadFormulaPadraoDesmembrados: true,
            loadProdutos: true,
            loadEstoque: true,
            loadLocaisEntrega: true,
            loadCampanhas: true,
            loadCapsulas: true,
            loadVendedores: true,
        });
    }

    async LoadPackLists(params: LoadListProps) {
        try {
            const data = await new PreLoadPackService()
                .loadPackLists(
                    params.loadUnidadeMedida,
                    params.loadFormaFarmaceutica,
                    params.loadPosologia,
                    params.loadKitEmbalagem,
                    params.loadFormulaPadraoDesmembrados,
                    params.loadProdutos,
                    params.loadEstoque,
                    params.loadLocaisEntrega,
                    params.loadCampanhas,
                    params.loadCapsulas,
                    params.loadVendedores,
                )
                .resolveWithJSON<PreLoadPackModel>();

            if (params.loadUnidadeMedida) {
                this.unidadesMedida = new Array<unidadeMedidaModel>();
                this.unidadesMedida = data.unidadesMedida;
            }

            if (params.loadFormaFarmaceutica) {
                this.formasFarmaceuticas = new Array<FormaFarmaceuticaModel>();
                this.formasFarmaceuticas = data.formasFarmaceuticas;
            }

            if (params.loadPosologia) {
                this.posologias = new Array<PosologiaModel>();
                this.posologias = data.posologias;
            }

            if (params.loadKitEmbalagem) {
                this.kitsEmbalagem = new Array<KitEmbalagemModel>();
                this.kitsEmbalagem = data.kitsEmbalagem;
            }

            if (params.loadFormulaPadraoDesmembrados) {
                this.formulasPadrao = new Array<FormulaPadraoModel>();
                this.formulasPadrao = data.formulasPadrao;
            }

            if (params.loadProdutos) {
                this.produtos = new Array<ProdutoModel>();
                this.produtos = data.produtos;
            }

            if (params.loadEstoque) {
                this.estoques = new Array<EstoqueModel>();
                this.estoques = data.estoques;
            }

            if (params.loadLocaisEntrega) {
                this.locaisEntrega = new Array<LocalEntregaModel>();
                this.locaisEntrega = data.locaisEntrega;
            }

            if (params.loadCampanhas) {
                this.campanhas = new Array<CampanhaModel>();
                this.campanhas = data.campanhas;
            }

            if (params.loadCapsulas) {
                this.capsulas = new Array<CapsulaModel>();
                this.capsulas = data.capsulas;
            }

            if (params.loadVendedores) {
                this.vendedores = new Array<UsuarioModel>();
                this.vendedores = data.vendedores;
            }
        } catch {}
    }
}
