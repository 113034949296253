import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoOperacao from "@/models/enum/tipoOperacao";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import FranquiaModel from "@/models/franquiaModel";
import NotaFiscalEletronicaEmissaoItemModel, { TipoCST } from "@/models/notaFiscalEletronicaEmissaoItemModel";
import NotaFiscalEletronicaEmissaoModel from "@/models/notaFiscalEletronicaEmissaoModel";
import NcmModel from "@/models/produto/ncmModel";
import ProdutoModel from "@/models/produto/produtoModel";
import VendaModel from "@/models/vendaModel";
import NotaFiscalEletronicaEmissaoItemService from "@/services/emissaoNota/notaFiscalEletronicaEmissaItemService";
import NcmService from "@/services/produto/ncmService";
import ProdutoService from "@/services/produto/produtoService";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

const produtoService = new ProdutoService();
const ncmService = new NcmService();
const notaFiscalEletronicaEmissaoItemService = new NotaFiscalEletronicaEmissaoItemService();

export function validateItemNota(
    item: NotaFiscalEletronicaEmissaoItemModel,
    nota: NotaFiscalEletronicaEmissaoModel,
    simplesNacional = false,
) {
    if (!item.produtoDescricao) return "Descrição do produto é obrigatório";
    if (!item.unidadeMedidaSigla) return "Unidade de medida é obrigatório";
    if (nota.movimentarEstoque && !item.produtoLoteId) return "O lote é obrigatório quando nota movimenta estoque";

    if (nota.finalidadeEmissao != 2) {
        if (!item.quantidade) return "Quantidade é obrigatório";
        if (!item.valorUnitario) return "Valor Unitário é obrigatório";
    }

    // Dados ICMS
    if (!item.origemMercadoria && item.origemMercadoria != 0) return "Origem da mercadoria é obrigatório";
    if (!item.cstICMS && item.cstICMS != 0) return `${simplesNacional ? "CSOSN" : "CST"} de ICMS é obrigatório`;

    if (simplesNacional && (item.cstICMS == 101 || item.cstICMS == 201)) {
        if (!item.percentualCreditoSimplesNacional)
            return `Percentual de crédito de ICMS do Simples Nacional é obrigatório para CSOSN ${item.cstICMS}`;
        if (!item.valorCreditoSimplesNacional)
            return `Valor de crédito de ICMS do Simples Nacional é obrigatório para CSOSN ${item.cstICMS}`;
    }

    // Dados IPI
    if (item.sujeitoIPI) {
        if (!item.cstIPI && item.cstIPI != 0) return "CST de IPI é obrigatório";
        if (!item.codigoEnquadramento) return "Código de Enquadramento do IPI é obrigatório";
    }
    // Dados PIS
    if (!item.cstPIS) return "CST de PIS é obrigatório";

    // Dados COFINS
    if (!item.cstCOFINS) return "CST de COFINS é obrigatório";

    if (nota.notaImportacao) {
        if (!item.numeroDI) return "Número da DI é obrigatório";
        if (!item.dataDI) return "Data da DI é obrigatório";
        if (!item.codigoExportador) return "Código do exportador é obrigatório";
        if (!item.localDesembaraco) return "Local de desembaraço é obrigatório";
        if (!item.ufDesembaracoId) return "UF de desembaraço é obrigatório";
        if (!item.dataDesembaraco) return "Data de desembaraço é obrigatório";
        if (!item.viaTransporte) return "Via de trasnporte é obrigatório";
        if (item.viaTransporte == 1 && !item.valorAFRMM)
            return "Valor AFRMM é obrigatório para via de transporte Maritima";
        if (!item.tipoIntermedio) return "Tipo do intermédio é obrigatório";
        if (item.adicoes.length == 0) return "Ao menos uma adição é obrigatório";
    }

    if (item.codigoAnvisa && item.precoMaximoConsumidor == 0) {
        return "Ao informar código ANVISA é obrigatório informar o Preço Máximo ao Consumidor";
    }

    return "";
}

export function totalizaValorNota(
    nota: NotaFiscalEletronicaEmissaoModel,
    origemVenda = false,
    venda: VendaModel = null,
) {
    nota.valorTotal = calculaValorTotalNota(nota, origemVenda);

    if (venda) {
        if (venda.valorFinal != nota.valorTotal) {
            let diferenca = arithmeticHelper.round(venda.valorFinal - nota.valorTotal);
            if (diferenca > 0) {
                nota.itens[0].valorOutraDespesa += diferenca;
                if (nota.itens[0].tipoCSTICMS == TipoCST.TRIBUTADO) {
                    nota.itens[0].baseICMS += diferenca;
                    calculaImposto(nota.itens[0], "ICMS");
                }
                nota.itens[0].valorTotal += diferenca;
            } else {
                diferenca = diferenca * -1;
                nota.itens[0].valorDesconto += diferenca;
                if (nota.itens[0].tipoCSTICMS == TipoCST.TRIBUTADO) {
                    nota.itens[0].baseICMS -= diferenca;
                    calculaImposto(nota.itens[0], "ICMS");
                }
                nota.itens[0].valorTotal -= diferenca;
            }
            nota.valorTotal = venda.valorFinal;
        }
    }

    if (nota.finalidadeEmissao != 4 && (nota.pagamentos.length == 0 || nota.pagamentos[0].automatico)) {
        const valorPagamento = nota.valorTotal;

        if (nota.pagamentos.length == 0) {
            nota.pagamentos.push({
                indicadorPagamento: 0,
                meioPagamento: 1,
                valorPagamento,
                automatico: true,
            });
        } else {
            nota.pagamentos[0].valorPagamento = valorPagamento;
        }
    }
    return nota;
}

export function calculaValorTotalNota(nota: NotaFiscalEletronicaEmissaoModel, origemVenda = false) {
    let valor = 0;
    if (nota.notaImportacao) {
        valor = nota.itens.reduce(
            (acc, c) =>
                acc +
                (c.valorUnitario * c.quantidade +
                    (c.cstICMS == 51 ? c.valorICMSPagar : c.valorICMS) +
                    (c.sujeitoIPI ? c.valorIPI : 0) +
                    c.valorPIS +
                    c.valorCOFINS +
                    c.valorII +
                    c.valorOutraDespesa +
                    c.valorSeguro +
                    c.valorDespesasAduaneiras),
            0,
        );
    } else if (origemVenda) {
        valor = nota.itens.reduce((acc, c) => acc + c.valorTotal + c.valorII + (c.sujeitoIPI ? c.valorIPI : 0), 0);
    } else {
        valor = nota.itens.reduce(
            (acc, c) =>
                acc +
                c.valorUnitario * c.quantidade -
                c.valorDesconto +
                c.valorFrete +
                c.valorSeguro +
                c.valorOutraDespesa +
                c.valorII +
                (c.sujeitoIPI ? c.valorIPI : 0),
            0,
        );
    }
    return arithmeticHelper.round(valor);
}

export async function loadImpostosByNaturezaOperacao(
    nota: NotaFiscalEletronicaEmissaoModel,
    item: NotaFiscalEletronicaEmissaoItemModel,
) {
    if (nota.naturezaOperacao && nota.naturezaOperacao.usaConfiguracaoFiscal) {
        if (nota.naturezaOperacao.cstICMS || nota.naturezaOperacao.cstICMS == 0) {
            item.cstICMS = nota.naturezaOperacao.cstICMS;
        }

        if (nota.naturezaOperacao.cstPIS || nota.naturezaOperacao.cstPIS == 0) {
            item.cstPIS = nota.naturezaOperacao.cstPIS;
        }

        if (nota.naturezaOperacao.cstCOFINS || nota.naturezaOperacao.cstCOFINS == 0) {
            item.cstCOFINS = nota.naturezaOperacao.cstCOFINS;
        }

        if (nota.naturezaOperacao.cstIPI || nota.naturezaOperacao.cstIPI == 0) {
            item.cstIPI = nota.naturezaOperacao.cstIPI;
        }

        if (nota.naturezaOperacao.aliquotaICMS != 0) {
            item.aliquotaICMS = nota.naturezaOperacao.aliquotaICMS;
        }

        if (nota.naturezaOperacao.aliquotaPIS != 0) {
            item.aliquotaPIS = nota.naturezaOperacao.aliquotaPIS;
        }

        if (nota.naturezaOperacao.aliquotaCOFINS != 0) {
            item.aliquotaCOFINS = nota.naturezaOperacao.aliquotaCOFINS;
        }

        if (nota.naturezaOperacao.aliquotaIPI != 0) {
            item.aliquotaIPI = nota.naturezaOperacao.aliquotaIPI;
        }

        if (nota.naturezaOperacao.codigoBeneficio) {
            item.codigoBeneficio = nota.naturezaOperacao.codigoBeneficio;
        }
    }
    return item;
}

export async function getNewItemByProduto(
    id: number,
    nota: NotaFiscalEletronicaEmissaoModel,
    franquia: FranquiaModel,
    buscaICMSCompra = false,
    produtoAlteradosCompra = [],
) {
    const produto = await produtoService.get(id).then(r => r.json() as Promise<ProdutoModel>);
    let item = new NotaFiscalEletronicaEmissaoItemModel();

    item.produtoId = id;
    if (!item.produtoLoteId) {
        item.produtoLoteId = produto.lotePadraoId;
    }
    if (item.produtoId > 0) {
        item.produtoDescricao = produto.descricaoFiscal ?? produto.descricao;
    }
    item.valorUnitario = produto.valorVenda;
    item.unidadeMedidaId = produto.unidadeMedidaEstoqueId;
    item.ncmId = produto.ncmId;
    if (produto.ncmId) {
        item.ncmCodigo = (await ncmService.get(produto.ncmId).then(r => r.json() as Promise<NcmModel>)).codigo;
    }
    item.EAN = Number(produto.codigoBarra);

    item.origemMercadoria = produto.origemMercadoria;
    item.aliquotaII = produto.aliquotaII;
    item.cstICMS = produto.cstICMS == null ? 0 : produto.cstICMS;
    item.codigoAnvisa = produto.codigoAnvisa;
    item.precoMaximoConsumidor = produto.precoMaximoConsumidor;

    item.sujeitoIPI = produto.sujeitoIPI;
    item.aliquotaIPI = produto.aliquotaIPI;

    if (nota.tipoOperacao == TipoOperacao.Entrada) {
        item.aliquotaICMS = produto.aliquotaICMSEntrada;
        item.cstIPI = produto.cstIPIEntrada;
        item.cstPIS = produto.cstPISEntrada;
        item.aliquotaPIS = produto.aliquotaPISEntrada;
        item.cstCOFINS = produto.cstCOFINSEntrada;
        item.aliquotaCOFINS = produto.aliquotaCOFINSEntrada;
    } else {
        item.aliquotaICMS = produto.aliquotaICMSSaida;
        item.cstIPI = produto.cstIPISaida;
        item.aliquotaPIS = produto.cstPISSaida == null ? 0 : produto.aliquotaPISSaida;
        item.cstPIS = produto.cstPISSaida == null ? 49 : produto.cstPISSaida;
        item.aliquotaCOFINS = produto.cstCOFINSSaida == null ? 0 : produto.aliquotaCOFINSSaida;
        item.cstCOFINS = produto.cstCOFINSSaida == null ? 49 : produto.cstCOFINSSaida;
    }

    if (item.cstICMS == 40 || item.cstICMS == 41 || item.cstICMS == 50) {
        item.baseICMS = null;
    } else if (nota.notaImportacao) {
        item.baseICMS = arithmeticHelper.round(
            (item.valorUnitario * item.quantidade +
                item.valorII +
                (item.sujeitoIPI ? item.valorIPI : 0) +
                item.valorPIS +
                item.valorCOFINS +
                item.valorDespesasAduaneiras) /
                (1 - item.aliquotaICMS / 100),
        );
    } else {
        item.baseICMS = item.valorUnitario * item.quantidade;
    }

    await carregaICMSInterestadual(item, nota, franquia);

    if (!nota.vendaInterestadual && !nota.notaExportacao && !nota.notaImportacao) {
        item.codigoBeneficio = produto.codigoBeneficio;
    }

    item.percentualReducaoBase = produto.percentualReducaoBase;
    item.percentualDiferimento = produto.percentualDiferimento;

    if (nota.notaImportacao && franquia.opcaoSimplesNacional) {
        item.cstICMS = 900;
    }

    if (nota.destinatario && !franquia.opcaoSimplesNacional) {
        if (
            nota.tipoOperacao == TipoOperacao.Saida &&
            nota.destinatario["estadoId"] == Ufs.PR &&
            nota.destinatario["contribuinteEstadual"]
        ) {
            item.cstICMS = 51;
            item.percentualDiferimento = 36.84;
            item.codigoBeneficio = "PR830001";
        }
    }
    item = await loadImpostosByNaturezaOperacao(nota, item);

    if (buscaICMSCompra) {
        const aliquotaCompra = await notaFiscalEletronicaEmissaoItemService
            .getICMSCompra(item.produtoId, item.produtoLoteId)
            .resolveWithJSON<number>();

        if (aliquotaCompra) {
            item.aliquotaICMS = aliquotaCompra;
            if (produtoAlteradosCompra.filter(p => p == item.produtoId).length == 0) {
                produtoAlteradosCompra.push(item.produtoId);
            }
        }
    }

    if (nota.notaImportacao) {
        item.sujeitoIPI = true;
        item.cstIPI = produto.cstIPIEntrada;
        item.aliquotaIPI = produto.aliquotaIPI;
        item.codigoEnquadramento = 999;
    }

    if (nota.notaExportacao) {
        item.cstICMS = franquia.opcaoSimplesNacional ? 300 : 41;
    }

    if (franquia.opcaoSimplesNacional && (item.cstICMS == 101 || item.cstICMS == 201 || item.cstICMS == 900)) {
        item.percentualCreditoSimplesNacional = produto.percentualCreditoSimplesNacional;
        item.valorCreditoSimplesNacional = arithmeticHelper.round(
            (item.baseICMS * produto.percentualCreditoSimplesNacional) / 100,
        );
    }

    if (nota.naturezaOperacao?.transferencia) {
        item.valorUnitario = produto.valorCusto;
    }

    return item;
}

export async function carregaICMSInterestadual(
    item: NotaFiscalEletronicaEmissaoItemModel,
    nota: NotaFiscalEletronicaEmissaoModel,
    franquia: FranquiaModel,
) {
    try {
        if (nota.vendaInterestadual) {
            // No futuro deve buscar a origem da compra
            if (isOrigemMercadoriaEstrangeira(item.origemMercadoria)) {
                // Se mercadoria de origem estrangeira adquirida no mercado interno ou origem estrangeira - 1, 2, 6, 7
                if (nota.destinatario["estadoId"]) {
                    item.aliquotaInterestadual = await produtoService
                        .getAliquotaInterestadualByNCM(
                            item.ncmCodigo,
                            item.produtoId,
                            EnumExtensions.getNameByValue(Ufs, nota.destinatario["estadoId"]),
                        )
                        .then(r => r.json() as Promise<number>);
                }
            } else {
                // Se mercadoria de origem nacional - 0, 3, 4, 5 e 8
                if (nota.destinatario["estadoId"]) {
                    item.aliquotaInterestadual = await produtoService
                        .getAliquotaICMSInterestadual(
                            item.produtoId,
                            EnumExtensions.getNameByValue(Ufs, nota.destinatario["estadoId"]),
                        )
                        .then(r => r.json() as Promise<number>);
                }
            }

            item.aliquotaICMS = item.aliquotaInterestadual;

            // Se não for CST Isenta
            if (item.cstICMS != 40 && item.cstICMS != 41 && item.cstICMS != 50) {
                item.baseUFDestino = item.baseICMS;
            }

            // Destinatário sem IE ou for pessoa física e não for simples remessa
            if (
                !nota.naturezaOperacao.simplesRemessa &&
                (nota.destinatario["inscricaoEstadual"] == null ||
                    nota.destinatario["tipoPessoa"] == TiposPessoa.Fisica)
            ) {
                item.comICMSPartilha = true;
                item.aliquotaInterestadualPartilha = 100;
                item.baseUFDestino = item.baseICMS;

                if (franquia.estadoId == Ufs.PR) {
                    item.aliquotaUFDestino = 17;
                } else {
                    item.aliquotaUFDestino = item.aliquotaICMS;
                }
            }
        } else {
            item.comICMSPartilha = false;
            item.aliquotaUFDestino = 0;
            item.aliquotaInterestadual = 0;
            item.aliquotaInterestadualPartilha = 0;
            item.baseUFDestino = 0;
            item.ICMSFCPUFDestino = 0;
            item.ICMSUFRemetente = 0;
        }
    } catch {}
}

export function isOrigemMercadoriaEstrangeira(origemMercadoria: number) {
    switch (origemMercadoria) {
        case 1:
        case 2:
        case 6:
        case 7:
            return true;
        default:
            return false;
    }
}

export function consolidaValoresItens(venda: VendaModel, itens: NotaFiscalEletronicaEmissaoItemModel[]) {
    let valorTotalReteadoDesconto = 0;
    let valorTotalReteadoAcrescimo = 0;

    itens.forEach(item => {
        const percentual = item.valorTotal / venda.valorProdutos;
        const descontoReatedo = arithmeticHelper.round(venda.desconto * percentual);
        const acrescimoReatedo = arithmeticHelper.round(venda.acrescimo * percentual);
        item.valorDesconto += descontoReatedo;
        item.valorOutraDespesa += acrescimoReatedo;
        item.valorTotal = getValorTotalItemNota(item);
        valorTotalReteadoDesconto += descontoReatedo;
        valorTotalReteadoAcrescimo += acrescimoReatedo;
    });

    valorTotalReteadoDesconto = arithmeticHelper.round(valorTotalReteadoDesconto);
    if (venda.desconto > 0 && valorTotalReteadoDesconto != venda.desconto) {
        const diferenca = arithmeticHelper.round(venda.desconto - valorTotalReteadoDesconto);
        const itensAptosDesconto = itens.filter(p => p.valorDesconto + diferenca < p.valorTotal);
        if (itensAptosDesconto.length > 0) {
            const item = itens[itensAptosDesconto.length - 1];
            item.valorDesconto += diferenca;
            item.valorTotal = getValorTotalItemNota(item);
        }
    }

    valorTotalReteadoAcrescimo = arithmeticHelper.round(valorTotalReteadoAcrescimo);
    if (venda.acrescimo > 0 && valorTotalReteadoAcrescimo != venda.acrescimo) {
        const diferenca = arithmeticHelper.round(venda.acrescimo - valorTotalReteadoAcrescimo);
        const itensAptosDesconto = itens.filter(p => p.valorOutraDespesa + diferenca < p.valorTotal);
        if (itensAptosDesconto.length > 0) {
            const item = itens[itensAptosDesconto.length - 1];
            item.valorOutraDespesa += diferenca;
            item.valorTotal = getValorTotalItemNota(item);
        }
    }

    return itens;
}

export function rateioTaxaEntrega(venda: VendaModel, itens: NotaFiscalEletronicaEmissaoItemModel[]) {
    let valorTotalReteado = 0;
    const valorTotal = itens.reduce((accumulator, currentValue) => Number(accumulator + currentValue.valorTotal), 0);

    itens.forEach(item => {
        const percentual = valorTotal > 0 ? item.valorTotal / valorTotal : 0;
        const valorReatedo = arithmeticHelper.round(venda.taxaEntrega * percentual);
        item.valorFrete += valorReatedo;
        item.valorTotal = getValorTotalItemNota(item);
        valorTotalReteado += valorReatedo;
    });

    valorTotalReteado = arithmeticHelper.round(valorTotalReteado);
    if (venda.taxaEntrega > 0 && valorTotalReteado != venda.taxaEntrega) {
        const diferenca = arithmeticHelper.round(venda.taxaEntrega - valorTotalReteado);
        const itensAptosDesconto = itens.filter(p => p.valorUnitario + diferenca < p.valorTotal);
        if (itensAptosDesconto.length > 0) {
            const item = itens[itensAptosDesconto.length - 1];
            item.valorFrete += diferenca;
            item.valorTotal = getValorTotalItemNota(item);
        }
    }

    return itens;
}

export function getValorTotalItemNota(item: NotaFiscalEletronicaEmissaoItemModel) {
    return arithmeticHelper.round(
        item.valorUnitario * item.quantidade +
            item.valorFrete +
            item.valorSeguro +
            item.valorOutraDespesa -
            item.valorDesconto,
    );
}

export function defineTipoCSTICMS(
    item: NotaFiscalEletronicaEmissaoItemModel,
    nota: NotaFiscalEletronicaEmissaoModel,
    simplesNacional: boolean,
) {
    if (simplesNacional) {
        if (!nota.notaImportacao && item.cstICMS != 900) {
            item.baseICMS = null;
            item.aliquotaICMS = null;
            item.valorICMS = null;
        }
        if (item.cstICMS != 101 && item.cstICMS != 201) {
            item.tipoCSTICMS = TipoCST.NAOTRIBUTADO;
            item.percentualCreditoSimplesNacional = null;
            item.valorCreditoSimplesNacional = null;

            if (item.cstICMS != 900) {
                item.baseICMS = null;
                item.aliquotaICMS = null;
                item.valorICMS = null;
            } else {
                item.tipoCSTICMS = TipoCST.TRIBUTADO;
                calculaImposto(item, "ICMS");
            }
        } else {
            item.tipoCSTICMS = TipoCST.TRIBUTADO;
        }
    } else {
        if (item.cstICMS == 40 || item.cstICMS == 41 || item.cstICMS == 50) {
            item.tipoCSTICMS = TipoCST.NAOTRIBUTADO;
        } else {
            item.tipoCSTICMS = TipoCST.TRIBUTADO;
        }

        if (item.cstICMS != 20) item.percentualReducaoBase = null;
        if (item.cstICMS != 51) item.percentualDiferimento = null;
        calculaImposto(item, "ICMS");
    }
    return item;
}

export function defineTipoCSTIPI(item: NotaFiscalEletronicaEmissaoItemModel) {
    if ((item.cstIPI >= 2 && item.cstIPI <= 5) || (item.cstIPI >= 52 && item.cstIPI <= 55)) {
        item.tipoCSTIPI = TipoCST.NAOTRIBUTADO;
    } else if (item.cstIPI == 1 || item.cstIPI == 51) {
        item.tipoCSTIPI = TipoCST.ALIQUOTAZERO;
    } else {
        item.tipoCSTIPI = TipoCST.TRIBUTADO;
    }
    calculaImposto(item, "IPI");
    return item;
}

export function defineTipoCSTPIS(item: NotaFiscalEletronicaEmissaoItemModel) {
    if (
        (item.cstPIS >= 7 && item.cstPIS <= 9) ||
        (item.cstPIS >= 70 && item.cstPIS <= 72) ||
        (item.cstPIS >= 74 && item.cstPIS <= 75)
    ) {
        item.tipoCSTPIS = TipoCST.NAOTRIBUTADO;
    } else if (item.cstPIS == 4 || item.cstPIS == 6 || item.cstPIS == 73) {
        item.tipoCSTPIS = TipoCST.ALIQUOTAZERO;
    } else {
        item.tipoCSTPIS = TipoCST.TRIBUTADO;
    }
    calculaImposto(item, "PIS");
    return item;
}

export function defineTipoCSTCOFINS(item: NotaFiscalEletronicaEmissaoItemModel) {
    if (
        (item.cstCOFINS >= 7 && item.cstCOFINS <= 9) ||
        (item.cstCOFINS >= 70 && item.cstCOFINS <= 72) ||
        (item.cstCOFINS >= 74 && item.cstCOFINS <= 75)
    ) {
        item.tipoCSTCOFINS = TipoCST.NAOTRIBUTADO;
    } else if (item.cstCOFINS == 4 || item.cstCOFINS == 6 || item.cstCOFINS == 73) {
        item.tipoCSTCOFINS = TipoCST.ALIQUOTAZERO;
    } else {
        item.tipoCSTCOFINS = TipoCST.TRIBUTADO;
    }
    calculaImposto(item, "COFINS");
    return item;
}

export function calculaImposto(item: NotaFiscalEletronicaEmissaoItemModel, imposto: "ICMS" | "IPI" | "PIS" | "COFINS") {
    switch (item[`tipoCST${imposto}`]) {
        case TipoCST.TRIBUTADO:
            item[`valor${imposto}`] = arithmeticHelper.round(
                (item[`base${imposto}`] * item[`aliquota${imposto}`]) / 100,
            );
            break;
        case TipoCST.NAOTRIBUTADO:
            item[`base${imposto}`] = null;
            item[`aliquota${imposto}`] = null;
            item[`valor${imposto}`] = null;
            break;
        case TipoCST.ALIQUOTAZERO:
            item[`aliquota${imposto}`] = null;
            item[`valor${imposto}`] = arithmeticHelper.round(
                (item[`base${imposto}`] * item[`aliquota${imposto}`]) / 100,
            );
            break;
    }
}

export function calculaValoresICMS(item: NotaFiscalEletronicaEmissaoItemModel) {
    if (item.cstICMS == 51) {
        item.valorICMSDiferido = arithmeticHelper.round((item.valorICMS * item.percentualDiferimento) / 100);
        item.valorICMSPagar = arithmeticHelper.round(item.valorICMS - item.valorICMSDiferido);
    }

    if (item.cstICMS == 20) {
        item.baseReduzida = arithmeticHelper.round((item.baseICMS * item.percentualReducaoBase) / 100);
    }

    if (item.comICMSPartilha) {
        item.baseUFDestino = item.baseICMS;
        const aliquota =
            item.aliquotaUFDestino > item.aliquotaInterestadual
                ? item.aliquotaUFDestino - item.aliquotaInterestadual
                : item.aliquotaInterestadual;

        item.ICMSUFDestino = arithmeticHelper.round(
            item.baseUFDestino * (aliquota / 100) * (item.aliquotaInterestadualPartilha / 100),
        );
        item.ICMSUFRemetente = arithmeticHelper.round(
            item.baseUFDestino * (aliquota / 100) * ((100 - item.aliquotaInterestadualPartilha) / 100),
        );
        item.ICMSFCPUFDestino = arithmeticHelper.round((item.baseFCPUFDestino * item.aliquotaFCPUFDestino) / 100);
    }

    return item;
}

export function getImpostos(item: NotaFiscalEletronicaEmissaoItemModel): object[] {
    return [
        {
            imposto: "ICMS",
            base: item.baseICMS || 0,
            cst: item.cstICMS > 9 ? item.cstICMS : `0${item.cstICMS ? item.cstICMS : ""}`,
            aliquota: item.aliquotaICMS || 0,
            valor: item.cstICMS == 51 ? item.valorICMSPagar : item.valorICMS,
        },
        {
            imposto: "IPI",
            base: item.baseIPI || 0,
            cst: item.sujeitoIPI ? (item.cstIPI > 9 ? item.cstIPI : `0${item.cstIPI ? item.cstIPI : ""}`) : "",
            aliquota: item.aliquotaIPI || 0,
            valor: item.sujeitoIPI ? item.valorIPI : 0,
        },
        {
            imposto: "PIS",
            base: item.basePIS || 0,
            cst: item.cstPIS > 9 ? item.cstPIS : `0${item.cstPIS ? item.cstPIS : ""}`,
            aliquota: item.aliquotaPIS || 0,
            valor: item.valorPIS || 0,
        },
        {
            imposto: "COFINS",
            base: item.baseCOFINS || 0,
            cst: item.cstCOFINS > 9 ? item.cstCOFINS : `0${item.cstCOFINS ? item.cstCOFINS : ""}`,
            aliquota: item.aliquotaCOFINS || 0,
            valor: item.valorCOFINS || 0,
        },
    ];
}
