import Vue from "vue";
import { mapState } from "vuex";

import { Component, Prop } from "@/decorators";
import StatusAtendimento from "@/models/enum/statusAtendimento";
import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";
import { AtendimentoContextActions } from "@/store/atendimentoContext/types";
import { AppState } from "@/store/store";
import { formatPhone } from "@/utils/common/number";

import mensagemOptionsComponent from "./mensagemOptions/mensagemOptions.vue";
import mensagemTempoComponent from "./mensagemTempo/mensagemTempo.vue";

import "./mensagem.scss";

@Component({
    components: {
        mensagemOptionsComponent,
        mensagemTempoComponent,
    },
    computed: {
        ...mapState({
            currentAtendimento: (state: AppState) => state.atendimentoContext.currentAtendimento,
        }),
    },
})
export default class MensagemComponent extends Vue {
    // State computed props
    currentAtendimento: AtendimentoWhatsAppModel;

    @Prop(Object) message: MensagemAtendimentoWhatsAppModel;
    @Prop({ type: Boolean, default: false }) reply: boolean;
    @Prop({ type: Boolean, default: false }) isHistorico: boolean;

    private onResponder() {
        this.$emit("onResponder", this.message);
    }

    private formatTelefone(input: string) {
        return formatPhone(input);
    }

    private async onAddAtendimento(telefone: string) {
        await this.$store.dispatch(AtendimentoContextActions.CREATE_ATENDIMENTO_BY_NUMERO, telefone);
    }

    private showOptions() {
        return (
            !this.isHistorico &&
            !this.reply &&
            this.message &&
            !this.message.observacao &&
            !this.message.mensagemExcluida &&
            this.currentAtendimento &&
            this.currentAtendimento.statusAtendimento !== StatusAtendimento.Fechado
        );
    }

    private openImage(base64Data: string) {
        const image = new Image();
        image.src = `data:image/png;base64,${base64Data}`;

        const w = window.open("");
        w.document.write(image.outerHTML);
    }

    private prepareText(text: string) {
        return text
            .trim()
            .replace(/\*(.*?)\*/g, "<b>$1</b>")
            .replace(/\n/g, "<br>");
    }

    private getImageSize(height: number, width: number) {
        const maxHeight = 450;
        const maxWidth = 450;

        height = height > 0 ? height : maxHeight;
        width = width > 0 ? width : maxWidth;

        if (height > width) {
            const ratio = maxHeight / height;
            return {
                width: Math.round(width * ratio) + "px",
                height: maxHeight + "px",
            };
        }

        const ratio = maxWidth / width;
        return {
            height: Math.round(height * ratio) + "px",
            width: maxWidth + "px",
        };
    }
}
