import ConfiguracaoAtendimentoModel from "@/models/configuracaoAtendimentoModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class ConfiguracaoAtendimentoService extends IService<ConfiguracaoAtendimentoModel> {
    constructor() {
        super("ConfiguracaoAtendimento");
    }

    public async getByFranquia(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByFranquia`, {
            credentials: "same-origin",
        });
    }
}
