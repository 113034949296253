import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop } from "@/decorators";
import ConfiguracaoImpressaoModel from "@/models/configuracaoImpressaoModel";

@Component({
    components: {
        checkboxComponent,
        shortcutComponent,
    },
})
export default class ConfiguracoesImpresaoPDFComponent extends Vue {
    @Prop({ type: Boolean, default: false }) hasSubItens: boolean;

    private shortCutComponent: ShortcutComponent = null;

    model = new ConfiguracaoImpressaoModel();

    public async open() {
        this.shortCutComponent = this.$refs.shortCutComponent as ShortcutComponent;
        this.shortCutComponent.title = "Exportar para PDF";
        this.shortCutComponent.show();
    }

    private async onConfirm() {
        this.$emit("onConfirm", this.model);

        this.shortCutComponent.hide();
    }
}
