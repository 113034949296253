var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortCutComponent",
      attrs: { btOkName: "Avançar", modalClass: "ss-modal", showAddNew: false },
      on: { "selection-item": _vm.onConfirm },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
          _c("form", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: {
                        for: "gerarPDFHorizontal",
                        title: _vm.$t("__.obrigatorio"),
                      },
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.relatorio.configuracaoImpressao.gerarPDFHorizontal"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("checkbox-component", {
                    attrs: {
                      id: "gerarPDFHorizontal",
                      name: "gerarPDFHorizontal",
                    },
                    model: {
                      value: _vm.model.gerarPDFHorizontal,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "gerarPDFHorizontal", $$v)
                      },
                      expression: "model.gerarPDFHorizontal",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasSubItens
                ? _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "showSubItens",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.relatorio.configuracaoImpressao.showSubItens"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("checkbox-component", {
                        attrs: { id: "showSubItens", name: "showSubItens" },
                        model: {
                          value: _vm.model.showSubItens,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "showSubItens", $$v)
                          },
                          expression: "model.showSubItens",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }