import Boot from "@/main";

import { iServiceAtendimento } from "../base/iServiceAtendimento";

export default class WhatsAppService extends iServiceAtendimento {
    constructor() {
        super("Sender");
    }

    public async enviarMensagemTexto(
        numeroDestino: string,
        mensagem: string,
        mensagemRespondidaId: string = null,
    ): Promise<string> {
        try {
            return await this.post("EnviarMensagem", { numeroDestino, mensagem, mensagemRespondidaId })
                .withLoading()
                .resolveWithText();
        } catch {
            Boot.$notifyError(Boot.$t("__.ts.erroConectarWhatsapp"));
        }
        return null;
    }

    public async enviarImagem(
        numeroDestino: string,
        legenda: string,
        file: File,
        mensagemRespondidaId: string = null,
    ): Promise<string> {
        try {
            const data = new FormData();
            data.append(file.name, file, file.name);
            data.append("numeroDestino", numeroDestino);
            data.append("legenda", legenda);
            data.append("mensagemRespondidaId", mensagemRespondidaId);

            return await this.postWithFormData("EnviarImagem", data).withLoading().resolveWithText();
        } catch {
            Boot.$notifyError(Boot.$t("__.ts.erroConectarWhatsapp"));
        }
        return null;
    }

    public async enviarVideo(
        numeroDestino: string,
        legenda: string,
        file: File,
        mensagemRespondidaId: string = null,
    ): Promise<string> {
        try {
            const data = new FormData();
            data.append(file.name, file, file.name);
            data.append("numeroDestino", numeroDestino);
            data.append("legenda", legenda);
            data.append("mensagemRespondidaId", mensagemRespondidaId);

            return await this.postWithFormData("EnviarVideo", data).withLoading().resolveWithText();
        } catch {
            Boot.$notifyError(Boot.$t("__.ts.erroConectarWhatsapp"));
        }
        return null;
    }

    public async enviarDocumento(
        numeroDestino: string,
        legenda: string,
        file: File,
        mensagemRespondidaId: string = null,
    ): Promise<string> {
        try {
            const data = new FormData();
            data.append(file.name, file, file.name);
            data.append("numeroDestino", numeroDestino);
            data.append("legenda", legenda);
            data.append("mensagemRespondidaId", mensagemRespondidaId);

            return await this.postWithFormData("EnviarDocumento", data).withLoading().resolveWithText();
        } catch {
            Boot.$notifyError(Boot.$t("__.ts.erroConectarWhatsapp"));
        }
        return null;
    }
}
