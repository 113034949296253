import BaseModel from "./base/baseModel";
import CompraFornecedorModel from "./compraFornecedorModel";
import CompraItemFornecedorModel from "./compraItemFornecedorModel";

export default class CompraItemModel extends BaseModel {
    id?: number = null;
    compraId?: number = null;
    produtoId?: number = null;
    quantidadeCompra?: number = null;
    valorCusto?: number = null;
    produtoDescricao?: string = null;
    grupoProdutoDescricao?: string = null;
    consumoDiario?: number = null;
    quantidadeVendida?: number = null;
    valorVendido?: number = null;
    quantidadeAtual?: number = null;
    estoqueMinimo?: number = null;
    estoqueMaximo?: number = null;
    unidadeMedidaDescricao?: string = null;
    fornecedores?: Array<CompraItemFornecedorModel> = null;
    curvaAbcDescricao?: string = null;
    unidadeMedidaFornecedor: string = null;
    unidadeMedidaId?: number = null;
    valorTotal?: number = null;
    valorUnitario?: number = null;
    dataValidade?: Date = null;
    quantidade?: number = null;
    produtoFornecedores?: Array<CompraFornecedorModel> = null;
}
