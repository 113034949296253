import SetorModel from "@/models/setorModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class SetorService extends IService<SetorModel> {
    constructor() {
        super("Setor");
    }

    public getAll(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetAll`, {
            credentials: "same-origin",
        });
    }
}
