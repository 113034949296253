import TiposPessoa from "@/models/enum/tiposPessoa";
import TipoVenda from "@/models/enum/tipoVenda";
import EstoqueModel from "@/models/estoqueModel";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import FornecedorModel from "@/models/fornecedorModel";
import KitEmbalagemModel from "@/models/kitEmbalagemModel";
import PosologiaModel from "@/models/posologiaModel";
import PrescritorModel from "@/models/prescritorModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";

import { editaCnpj } from "../formatText";

export function getProdutoComboText(item: ProdutoModel, mostrarSinonimos = true) {
    return (
        `${item.id} - ` +
        item.descricao +
        (mostrarSinonimos && item.sinonimos != null && item.sinonimos != ""
            ? "((" + item.sinonimoIds + " - " + item.sinonimos + "))"
            : "") +
        (item.ativo ? "" : " - [INATIVO]")
    );
}

export function getProdutoCombo(item: ProdutoModel) {
    return {
        value: item.id,
        text: getProdutoComboText(item),
    };
}

export function getKitEmbalagemComboText(item: KitEmbalagemModel) {
    return item.descricao + (item.ativo ? "" : " - [INATIVO]");
}

export function getFormaFarmaceuticaCombo(item: FormaFarmaceuticaModel) {
    return {
        value: item.id,
        text: getFormaFarmaceuticaComboText(item),
    };
}

export function getFormaFarmaceuticaComboText(item: FormaFarmaceuticaModel) {
    return item.descricao + (item.ativo ? "" : " - [INATIVO]");
}

export function getUnidadeMedidaCombo(item: UnidadeMedidaModel, onlySigla = false) {
    return {
        value: item.id,
        text: onlySigla ? item.sigla : `${item.descricao} - ${item.sigla}`,
        sigla: item.sigla,
        type: item.unidadeMedida,
    };
}

export function getProdutoLoteCombo(item: ProdutoLoteModel) {
    return {
        value: item.id,
        text: item.descricao,
    };
}

export function getEstoqueCombo(item: EstoqueModel) {
    return {
        value: item.id,
        text: item.descricao,
    };
}

export function getPosologiaCombo(item: PosologiaModel) {
    return {
        value: item.id,
        text: `${item.id}-${item.descricao}`,
        ativo: item.ativo,
    };
}

export function getKitEmbalagemCombo(item: KitEmbalagemModel) {
    return {
        value: item.id,
        text: getKitEmbalagemComboText(item),
    };
}

export const getPrescritorCombo = (item: PrescritorModel) => ({
    value: item.id,
    text: `${item.ativo ? "" : "[INATIVO]"} ${item.nome} - (${item.ufConselhoRegionalDescricao} ${
        item.numeroRegistro
    })`,
});

export function getFornecedorCombo(item: FornecedorModel) {
    return {
        value: item.id,
        text: `${item.ativo ? "" : "[INATIVO] - "}${item.nome} ${
            item.tipoPessoa == TiposPessoa.Juridica ? " - " + editaCnpj(item.cnpj) : ""
        }`,
        contaGerencialId: item.contaGerencialId,
    };
}

export function getTipoVendaCombo() {
    return [
        { text: "Normal", value: TipoVenda.Normal },
        { text: "Transferência", value: TipoVenda.Transferencia },
        { text: "Amostra ou Bonificação", value: TipoVenda.AmostraOuBonificacao },
        { text: "Reposição ou SAC", value: TipoVenda.ReposicaoOuSAC },
        { text: "Venda Cancelada", value: TipoVenda.VendaCancelada },
    ];
}
