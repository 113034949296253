var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      _c("atendimentosComponent", {
        on: { onSelectContact: _vm.onSelectContact },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "chat-window" }, [
        _c("div", { staticClass: "messages-container" }, [
          _vm.currentAtendimento != null
            ? _c(
                "header",
                [
                  _c("div", [
                    _c("img", {
                      staticClass: "avatar",
                      attrs: { src: _vm.currentAtendimento.fotoContato },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.currentAtendimento.nomeContato) +
                          " " +
                          _vm._s(_vm.atendente)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "dropdownComponent",
                    [
                      _c("i", {
                        staticClass: "fa fa-sort-down fa-2x",
                        staticStyle: { "margin-right": "8px" },
                        attrs: { slot: "button", "aria-hidden": "true" },
                        slot: "button",
                      }),
                      _vm._v(" "),
                      _c("template", { slot: "itens" }, [
                        _c("li", [
                          _vm.validarVisibilidadeBotao("atendente")
                            ? _c(
                                "div",
                                {
                                  on: {
                                    click: _vm.vincularUsuarioByAtendimento,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.atendimento.view_vue_html.alocarAtendimento"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm.validarVisibilidadeBotao("admin")
                            ? _c(
                                "div",
                                { on: { click: _vm.onTrocarAtendente } },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.atendimento.view_vue_html.trocarAtendente"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm.currentAtendimento.statusAtendimento != 2
                            ? _c(
                                "div",
                                {
                                  on: {
                                    click: _vm.fecharAtendimentoByAtendimento,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Components.parent.atendimento.view_vue_html.encerrarAtendimento"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "div",
                            { on: { click: _vm.dadosContatoAtendimento } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.atendimento.view_vue_html.dadosContato"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "div",
                            { on: { click: _vm.historicoAtendimento } },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.atendimento.view_vue_html.historicoAtend"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.selectedFile != null
                  ? "messages-content selected"
                  : "messages-content",
              ],
            },
            [
              _vm.currentAtendimento == null
                ? _c("div", { staticClass: "info" }, [
                    _c("img", {
                      attrs: { src: require("../../../image/logo-login.svg") },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "__.Components.parent.atendimento.view_vue_html.selecContatoComecar"
                          )
                        ),
                      },
                    }),
                  ])
                : _vm._l(_vm.messages, function (message) {
                    return _c(
                      "div",
                      {
                        key: message.id,
                        class: [
                          message.mensagemPropria ? "me" : "them",
                          message.observacao ? "observation" : "",
                        ],
                      },
                      [
                        message.usuarioEnvio != null &&
                        message.usuarioEnvio.id != _vm.usuarioLogado.id
                          ? _c(
                              "div",
                              {
                                class: [
                                  "send-user",
                                  message.observacao ? "observation" : "",
                                ],
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(message.usuarioEnvio.nome)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        message.mensagemRespondidaId
                          ? _c("div", { staticClass: "reply-container" }, [
                              _c(
                                "div",
                                {
                                  class: [
                                    "reply-content",
                                    _vm.isMensagemContato(
                                      message.mensagemRespondidaId
                                    )
                                      ? "them-reply"
                                      : "me-reply",
                                  ],
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.sentBy(message.mensagemRespondidaId)
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("mensagemComponent", {
                                    attrs: {
                                      message: _vm.getMensagemRespondida(
                                        message.mensagemRespondidaId
                                      ),
                                      reply: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("mensagemComponent", {
                                    attrs: { message: message },
                                    on: { onResponder: _vm.onResponder },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _c("mensagemComponent", {
                              attrs: { message: message },
                              on: { onResponder: _vm.onResponder },
                            }),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _vm.currentAtendimento != null &&
        _vm.currentAtendimento.statusAtendimento != 2
          ? _c("div", { staticClass: "message-input-container" }, [
              _vm.selectedFile != null
                ? _c("div", { staticClass: "message-input-overlay" }, [
                    _c("div", [
                      _c("i", { staticClass: "fa fa-file" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.selectedFile.name))]),
                    ]),
                    _vm._v(" "),
                    _c("button", { on: { click: _vm.deleteAttachment } }, [
                      _c("i", {
                        staticClass: "fa fa-trash-alt",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.mensagemAResponser != null
                ? _c(
                    "div",
                    {
                      class: [
                        "message-input-overlay",
                        { reply: _vm.mensagemAResponser.mensagemPropria },
                      ],
                    },
                    [
                      _c("div", { staticClass: "reply-container" }, [
                        _c("span", { staticClass: "title-reply" }, [
                          _vm._v(_vm._s(_vm.sentBy(_vm.mensagemAResponser.id))),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.getTextMensagemAResponser(
                                _vm.mensagemAResponser
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        { on: { click: _vm.deleteMensagemAResponder } },
                        [
                          _c("i", {
                            staticClass: "fa fa-trash-alt",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "message-input" },
                [
                  _c(
                    "dropdownComponent",
                    { attrs: { menuSide: "RIGHT" } },
                    [
                      _c("i", {
                        staticClass: "fa fa-ellipsis-v blue-button",
                        attrs: { slot: "button", "aria-hidden": "true" },
                        slot: "button",
                      }),
                      _vm._v(" "),
                      _c("template", { slot: "itens" }, [
                        _c("li", [
                          _c("div", [
                            _c("label", [
                              _c("i", { staticClass: "fa fa-paperclip" }),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.atendimento.view_vue_html.anexar"
                                    )
                                  ) +
                                  "\n                                    "
                              ),
                              _c("input", {
                                attrs: {
                                  type: "file",
                                  id: "file",
                                  name: "file",
                                },
                                on: { change: _vm.onFileChanged },
                              }),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c("div", { on: { click: _vm.onObservacao } }, [
                            _c("i", { staticClass: "far fa-comment-dots" }),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.cliente.edit_vue_html.obs")
                                ) +
                                "\n                            "
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newMessage,
                        expression: "newMessage",
                      },
                    ],
                    style: { resize: "none" },
                    attrs: {
                      id: "text-input",
                      placeholder: "Escreva uma mensagem...",
                      rows: "1",
                    },
                    domProps: { value: _vm.newMessage },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.newMessage = $event.target.value
                        },
                        _vm.adjustTextareaStyle,
                      ],
                      keyup: function ($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleKeyUp($event)
                      },
                      blur: _vm.adjustTextareaStyle,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "blue-button",
                      on: {
                        click: function ($event) {
                          _vm.sendMessage(null)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-chevron-right" })]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("dadosContatoComponent", { ref: "dadosContatoComponent" }),
      _vm._v(" "),
      _c("trocarAtendenteComponent", {
        ref: "trocarAtendenteComponent",
        on: { onTrocarAtendente: _vm.vincularUsuarioByAtendimento },
      }),
      _vm._v(" "),
      _c("historicoAtendimentoComponent", {
        ref: "historicoAtendimentoComponent",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }