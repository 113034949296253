var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "top-menu" },
    [
      _c("div", { staticClass: "navbar navbar-fixed-top navbar-inverse" }, [
        _vm.usuarioLogado.ambiente === "Development"
          ? _c(
              "div",
              {
                staticClass:
                  "environment-indicator environment-indicator-green",
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.topmenu.topmenu_vue_html.ambienteDesenvolvimento"
                      )
                    )
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.usuarioLogado.ambiente === "Staging"
          ? _c(
              "div",
              {
                staticClass:
                  "environment-indicator environment-indicator-yellow",
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.topmenu.topmenu_vue_html.ambienteHomologacao"
                      )
                    )
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.usuarioLogado.ambiente === "Training"
          ? _c(
              "div",
              {
                staticClass:
                  "environment-indicator environment-indicator-orange",
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "__.Components.parent.topmenu.topmenu_vue_html.ambienteTrainamento"
                      )
                    )
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("header", [
          _c(
            "section",
            { staticClass: "menu-section" },
            [
              _c(
                "div",
                {
                  class: { "icon-container": true, change: this.menuExibicao },
                  on: {
                    click: function ($event) {
                      _vm.toggleNavMenu()
                    },
                  },
                },
                [
                  _c(
                    "article",
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/", exact: true } }, [
                        _c("div", { staticClass: "pharmup-logo" }),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { title: "Home", to: "/", exact: true } },
                [
                  _c("i", {
                    staticClass: "fa fa-home fa-2x",
                    staticStyle: { "padding-left": "10px" },
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("article", { staticClass: "zoom" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#", to: "/", exact: true },
                    on: {
                      click: function ($event) {
                        _vm.aumentar()
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-plus",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "#", to: "/", exact: true },
                    on: {
                      click: function ($event) {
                        _vm.diminuir()
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-minus",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.temTempo
                ? _c(
                    "article",
                    {
                      staticClass: "weather",
                      attrs: { title: _vm.franquiaCidade },
                    },
                    [
                      _c("i", {
                        class: "wiIco " + _vm.icon,
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("h5", [_vm._v(_vm._s(_vm.temp) + "ºC")]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("article", { staticClass: "date" }, [
                _c("i", {
                  staticClass: "fa fa-calendar-alt",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("div", [
                  _c("strong", [_vm._v(_vm._s(_vm.dia))]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.semana))]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "franquia-section" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    title: "Franquias",
                    to: "/franquia-lista",
                    exact: true,
                  },
                },
                [_c("i", { staticClass: "fa fa-building" })]
              ),
              _vm._v(" "),
              _c("div", [
                _c("strong", [_vm._v(_vm._s(_vm.nomeFranquia))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.franquiaCidade))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("section", { staticClass: "user-section" }, [
            _c("article", [
              _c("div", [
                _vm.sessaoCaixaId > 0
                  ? _c(
                      "span",
                      {
                        attrs: {
                          title: _vm.$t(
                            "__.Components.parent.topmenu.topmenu_vue_html.caixaAberto"
                          ),
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              title: "Movimentações de caixa",
                              to: "/sessaoCaixa-lista",
                              exact: true,
                            },
                          },
                          [_c("i", { staticClass: "fa fa-cash-register" })]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "caixa sinalizadorVerde" }),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      {
                        attrs: {
                          title: _vm.$t(
                            "__.Components.parent.topmenu.topmenu_vue_html.caixaFechado"
                          ),
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              title: "Abrir caixa",
                              to: "/sessaoCaixa-abertura",
                              exact: true,
                            },
                          },
                          [_c("i", { staticClass: "fa fa-cash-register" })]
                        ),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "caixa sinalizadorVermelho",
                        }),
                      ],
                      1
                    ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Components.parent.topmenu.topmenu_vue_html.notificacoes"
                    ),
                  },
                  on: { click: _vm.onShowNotification },
                },
                [
                  _vm.countUnreadedNotification > 0
                    ? _c(
                        "span",
                        {
                          staticClass: "popup",
                          attrs: { "aria-hidden": "true" },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.countUnreadedNotification) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fa fa-bell fa-2x",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.hasModuloAtendimento
                ? _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            title: "Atendimentos",
                            to: "/atendimento",
                            exact: true,
                          },
                        },
                        [
                          _vm.totalUnreadCount > 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "popup",
                                  attrs: { "aria-hidden": "true" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.totalUnreadCount) +
                                      "\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fab fa-whatsapp fa-2x",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "article",
              [
                _c("span", [_vm._v(_vm._s(_vm.nomeUsuario))]),
                _vm._v(" "),
                _c("v-network", { on: { changed: _vm.netChanged } }, [
                  _c("span", { attrs: { slot: "online" }, slot: "online" }, [
                    _c(
                      "i",
                      {
                        staticClass: "fa fa-user fa-2x",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "effectjump networkStatus on" },
                          [_c("strong", [_vm._v("ON")])]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { attrs: { slot: "offline" }, slot: "offline" }, [
                    _c(
                      "i",
                      {
                        staticClass: "fa fa-user fa-2x",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "effectjumpOff networkStatus off" },
                          [_c("strong", [_vm._v("OFF")])]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "dropdownComponent",
                  [
                    _c("i", {
                      staticClass: "fa fa-sort-down fa-2x",
                      staticStyle: { "margin-right": "8px" },
                      attrs: { slot: "button", "aria-hidden": "true" },
                      slot: "button",
                    }),
                    _vm._v(" "),
                    _c("template", { slot: "itens" }, [
                      _c("li", [
                        _c("div", { on: { click: _vm.onSair } }, [
                          _c("i", { staticClass: "fa fa-arrow-circle-left" }),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.topmenu.topmenu_vue_html.sair"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", { on: { click: _vm.onAlterarSenha } }, [
                          _c("i", { staticClass: "fa fa-lock" }),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.topmenu.topmenu_vue_html.alterarSenha"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", [
                          _c("i", { staticClass: "fa fa-chevron-left" }),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.topmenu.topmenu_vue_html.alterarIdioma"
                                )
                              ) +
                              "\n                                    "
                          ),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "custom-dropdown-menu custom-dropdown-submenu-left",
                            },
                            [
                              _c("li", [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.onChangeIdioma("pt-br")
                                      },
                                    },
                                  },
                                  [_vm._v("Português")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.onChangeIdioma("es")
                                      },
                                    },
                                  },
                                  [_vm._v("Español")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.onChangeIdioma("en-us")
                                      },
                                    },
                                  },
                                  [_vm._v("English")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", { on: { click: _vm.onVerNotasDaVersao } }, [
                          _c("i", { staticClass: "fa fa-file-alt" }),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.topmenu.topmenu_vue_html.notasDaVersao"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.isAdmin
                        ? _c("li", [
                            _c(
                              "div",
                              { on: { click: _vm.onEmailNotasDaVersao } },
                              [
                                _c("i", { staticClass: "fa fa-envelope" }),
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Components.parent.topmenu.topmenu_vue_html.emailNotasDaVersao"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAdmin
                        ? _c("li", [
                            _c("div", { on: { click: _vm.onUpdateLogLevel } }, [
                              _c("i", { staticClass: "fa fa-history" }),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Components.parent.topmenu.topmenu_vue_html.logLevel"
                                    )
                                  ) +
                                  "\n                                "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", { on: { click: _vm.onVerStatusDoSistema } }, [
                          _c("i", { staticClass: "fa fa-plug" }),
                          _vm._v(
                            "\n                                    " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.topmenu.topmenu_vue_html.statusSistema"
                                )
                              ) +
                              "\n                                "
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("notificationComponent", { ref: "notificationComponent" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "bar1" }),
      _vm._v(" "),
      _c("div", { staticClass: "bar2" }),
      _vm._v(" "),
      _c("div", { staticClass: "bar3" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }