import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnExt, GridColumnType } from "@/components/child/grid/gridColumn";
import modalComponent from "@/components/child/modal/modal.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoRotuloCamposModel from "@/models/configuracaoRotuloCamposModel";
import ConfiguracaoRotuloModel from "@/models/configuracaoRotuloModel";
import Alinhamento from "@/models/enum/alinhamento";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import { Fonte } from "@/models/enum/fonte";
import FormatoImpressaoRotulo from "@/models/enum/rotulo/formatoImpressaoRotulo";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import PosicaoDadosFranquia from "@/models/enum/rotulo/posicaoDadosFranquia";
import PosicaoRotulo from "@/models/enum/rotulo/posicaoRotulo";
import RespTecDadosFranquia from "@/models/enum/rotulo/respTecDadosFranquia";
import RotuloPersonalizadoModel from "@/models/rotuloPersonalizadoModel";
import ConfiguracaoRotuloService from "@/services/configuracaoRotuloService";
import PrinterService from "@/services/print/printerService";
import { AppState, Getters, LoadListProps, Mutations } from "@/store/store";
import { changePosition } from "@/utils/common/array";

import ReplicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit";
import replicarSelecaoFranquiaComponent from "../replicarSelecaoFranquia/edit.vue";

import rotuloComponent from "./rotulo.vue";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        gridComponent,
        fieldsetComponent,
        comboComponent,
        modalComponent,
        checkboxComponent,
        decimalComSinalCustomComponent,
        rotuloComponent,
        replicarSelecaoFranquiaComponent,
        dataTooltipComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
    computed: {
        ...mapGetters(["HAS_PERMISSAO_PERFIL"] as Getters),
        ...mapState<AppState>({
            preLoadList: state => state.preLoad.preLoadList,
        }),
        isRotuloManipulacao() {
            return (
                this.model.origemDados == OrigemRotulo.Manipulacao ||
                this.model.origemDados == OrigemRotulo.Acabado ||
                this.model.origemDados == OrigemRotulo.Strips
            );
        },
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class RotuloPersonalizadoEditComponent extends Vue {
    // State computed props
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    LOAD_LIST: (props: LoadListProps) => void;
    preLoadList: PreLoadPackModel;
    isRotuloManipulacao: boolean;

    private service = new ConfiguracaoRotuloService();
    private modelDadosRotulo: any = null;
    private modelId = 0;
    private timer: number = null;
    private loadingRotuloEdicao = false;
    public modelUtilizado: ConfiguracaoRotuloModel = new ConfiguracaoRotuloModel();
    public rotuloNovoSelecionado = true;
    private temRotuloAntigo = false;
    private carregouValoresCampos = false;
    private campoSelected: number = null;
    private optionsCampos: Array<{ text: string; value: number }> = [];
    private nomeProduto = "";
    private nomeConfiguracaoProduto = "";
    private manipulacaoKey = "";
    private replicarSelecaoFranquiaComponent: ReplicarSelecaoFranquiaComponent = null;

    private rotuloSelecionado: number = null;
    private rotulosOptions: Array<{ text: string; value: number }> = [];

    private impressoraSelecionada: number = null;
    private impressoraOptions: Array<{ text: string; value: number; printerName: string }> = [];

    private isAdminFranqueador = false;

    private get previewScaleFactor() {
        return 1.4; // Se houver necessidade, é possível implementar o fator de escala de acordo com o tamanho do rótulo
    }

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;
    @Prop({ type: Boolean, default: false }) collapsedCamposImpressoes: boolean;

    public model: ConfiguracaoRotuloModel = new ConfiguracaoRotuloModel();

    posicaoLogoOptions = [
        { text: "Sem logo", value: PosicaoRotulo.Nenhuma },
        { text: "Esquerda", value: PosicaoRotulo.Esquerda },
        { text: "Direita", value: PosicaoRotulo.Direita },
    ];

    formatoImpressaoRotuloOptions = [
        { text: "Paisagem", value: FormatoImpressaoRotulo.Paisagem },
        { text: "Retrato", value: FormatoImpressaoRotulo.Retrato },
        { text: "Paisagem Invertido", value: FormatoImpressaoRotulo.PaisagemInvertido },
        { text: "Retrato Invertido", value: FormatoImpressaoRotulo.RetratoInvertido },
    ];

    posicaoQrCodeOptions = [
        { text: "Nenhuma", value: PosicaoRotulo.Nenhuma },
        { text: "Esquerda (QR code)", value: PosicaoRotulo.Esquerda },
        { text: "Direita (QR code)", value: PosicaoRotulo.Direita },
        { text: "Em baixo (Código de barras)", value: PosicaoRotulo.Baixo },
        { text: "Em cima (Código de barras)", value: PosicaoRotulo.Cima },
    ];

    origemDadosOptions = [
        { text: "Lote estoque", value: OrigemRotulo.Lote },
        { text: "Manipulação", value: OrigemRotulo.Manipulacao },
        { text: "Lote Fracionamento", value: OrigemRotulo.Fracionamento },
        { text: "Lote Secundário", value: OrigemRotulo.Secundario },
        { text: "Acabados", value: OrigemRotulo.Acabado },
        { text: "Strips", value: OrigemRotulo.Strips },
        { text: "Lote Secundário Pequeno", value: OrigemRotulo.SecundarioPequeno },
    ];

    fonteRotuloOptions = [
        { text: "Padrão", value: Fonte.Default },
        { text: "Calibri", value: Fonte.Calibri },
        { text: "Monospace", value: Fonte.Monospace },
        { text: "Courier", value: Fonte.Courier },
        { text: "Helvetica", value: Fonte.Helvetica },
        { text: "Times Roman", value: Fonte.TimesRoman },
    ];

    posicaoDadosFranquiaOptions = [
        { text: "Centro", value: PosicaoDadosFranquia.Centro },
        { text: "Esquerda", value: PosicaoDadosFranquia.Esquerda },
        { text: "Direita", value: PosicaoDadosFranquia.Direita },
    ];

    respTecDadosFranquiaOptions = [
        { text: "Nenhum", value: RespTecDadosFranquia.Nenhum },
        { text: "Somente Dados Franquia", value: RespTecDadosFranquia.SomenteFranquia },
        { text: "Somente Dados Responsável Técnico", value: RespTecDadosFranquia.SomenteRespTec },
        { text: "Ambos", value: RespTecDadosFranquia.Ambos },
        { text: "Ambos mesma linha", value: RespTecDadosFranquia.AmbosMesmaLinha },
    ];

    gridColumnsCampos: Array<GridColumn> = [
        new GridColumn("campo", "Campo", GridColumnType.String),
        new GridColumn("label", "Label", GridColumnType.String, true),
        new GridColumn("fonte", "Tamanho da fonte", GridColumnType.String, true),
        new GridColumn("negrito", "Negrito", GridColumnType.Boolean, true),
        new GridColumnExt({
            value: "alinhamento",
            text: "Alinhamento",
            type: GridColumnType.Combo,
            editable: true,
            comboOptions: () => EnumExtensions.getNamesAndValuesOrderedByValues(Alinhamento),
            defaultValueProp: "alinhamento",
        }),
        new GridColumn("quantidadeColunas", "Colunas", GridColumnType.Integer, true),
    ];

    gridColumnsCamposEditable: Array<GridColumn> = [
        new GridColumn("campo", "Campo", GridColumnType.String),
        new GridColumn("label", "Label", GridColumnType.String, true),
        new GridColumn("value", "Descrição", GridColumnType.String, true),
        new GridColumn("fonte", "Tamanho da fonte", GridColumnType.String, true),
        new GridColumn("negrito", "Negrito", GridColumnType.Boolean, true),
        new GridColumnExt({
            value: "alinhamento",
            text: "Alinhamento",
            type: GridColumnType.Combo,
            editable: true,

            comboOptions: () => EnumExtensions.getNamesAndValuesOrderedByValues(Alinhamento),
            defaultValueProp: "alinhamento",
        }),
        new GridColumn("quantidadeColunas", "Colunas", GridColumnType.Integer, true),
    ];

    gridColumnsCamposReadonly: Array<GridColumn> = [
        new GridColumn("campo", "Campo", GridColumnType.String),
        new GridColumn("label", "Label", GridColumnType.Raw),
        new GridColumn("value", "Descrição", GridColumnType.Raw),
        new GridColumn("fonte", "Tamanho da fonte", GridColumnType.String),
        new GridColumn("negrito", "Negrito", GridColumnType.Boolean),
        new GridColumnExt({
            value: "alinhamento",
            text: "Alinhamento",
            type: GridColumnType.Combo,
            editable: true,
            disabled: true,
            comboOptions: () => EnumExtensions.getNamesAndValuesOrderedByValues(Alinhamento),
            defaultValueProp: "alinhamento",
        }),
        new GridColumn("quantidadeColunas", "Colunas", GridColumnType.Integer),
    ];

    extraActions: Array<object> = [];
    gridExtraActionsUp: Array<GridAction> = [];
    gridExtraActionsDown: Array<GridAction> = [];
    gridExtraActionsCopy: Array<GridAction> = [];
    gridExtraActionsGetOld: Array<GridAction> = [];

    private collapsedCamposImpressoesFieldset = false;

    private validarVisibilidadeBotoes(botao: string): boolean {
        if (botao == "replicar") {
            return this.model.id > 0 && this.model.configuracaoRotuloPaiId == null && this.isAdminFranqueador;
        }
    }

    private onReplicar() {
        this.replicarSelecaoFranquiaComponent.openFranquias();
    }

    private async onConfirmReplicar(franquiasIds: Array<number>) {
        const idsFranquias = franquiasIds.toString();

        try {
            const sucesso = await this.service.replicar(this.model.id, idsFranquias).withLoading().resolveWithoutJSON();
            if (sucesso) {
                this.$showSuccess("Replicou", this.$t("__.ts.regisSalvosSucess"));
            }
        } catch {}
    }

    public async getDadosManipulacao(manipulacaoId: number, rotuloId = 0): Promise<boolean> {
        try {
            const data = await this.service
                .getRotuloManipulacao(manipulacaoId, rotuloId)
                .resolveWithJSON<RotuloPersonalizadoModel>();
            return this.setRotuloManipulacao(data);
        } catch {}
        return false;
    }

    public async setRotuloManipulacao(rotulo: RotuloPersonalizadoModel) {
        rotulo.rotulo.copias = rotulo.rotulo.copias ?? 1;
        if (rotulo.lote) {
            this.modelDadosRotulo = rotulo.lote;
            this.nomeProduto = rotulo.lote.produtoDescricao;
            await this.getComboRotulos(OrigemRotulo.Lote);
            await this.afterDadosLote(rotulo, rotulo.lote.id);
        } else {
            this.manipulacaoKey = rotulo.manipulacao.key;
            this.modelDadosRotulo = rotulo.manipulacao;
            if (rotulo.rotulo.origemDados == OrigemRotulo.Strips) {
                this.nomeProduto = rotulo.manipulacao["descricaoManipulacao"];
            } else {
                this.nomeProduto = rotulo.manipulacao.descricao;
            }
            await this.getComboRotulos(OrigemRotulo.Manipulacao);
            await this.afterDadosLote(rotulo, rotulo.manipulacao.id);
        }
        return true;
    }

    public async getDadosLote(loteId: number, rotuloId = 0): Promise<boolean> {
        try {
            const data = await this.service.getRotuloLote(loteId, rotuloId).resolveWithJSON<RotuloPersonalizadoModel>();
            return this.setRotuloLote(data);
        } catch {}
        return false;
    }

    public async setRotuloLote(rotulo: RotuloPersonalizadoModel, vendaId = 0) {
        await this.getComboRotulos(OrigemRotulo.Lote);

        if (!rotulo.rotulo.copias || vendaId) {
            rotulo.rotulo.copias = 1;
        }

        this.modelDadosRotulo = rotulo.lote;
        if (rotulo.lote) {
            this.nomeProduto = this.modelDadosRotulo.produtoDescricao
                ? this.modelDadosRotulo.produtoDescricao
                : this.modelDadosRotulo.descricao;
        } else {
            this.nomeProduto = this.modelDadosRotulo.descricao;
            this.modelDadosRotulo.quantidadeAtual = this.modelDadosRotulo.volumeFracionado;
        }

        await this.afterDadosLote(rotulo, this.modelDadosRotulo.id);
        return true;
    }

    private async afterDadosLote(data: RotuloPersonalizadoModel, id: number) {
        this.nomeConfiguracaoProduto = this.$t("__.ts.confImpress") + ` - ${this.nomeProduto}`;

        if (!data.rotulo) {
            data.rotulo = this.geraRotuloDefault();
        }

        data.rotulo.dadosId = id;
        if (data.rotulo.id) {
            this.rotuloSelecionado = data.rotulo.id;
        }

        await this.carregaRotulo(data.rotulo, data.rotuloAntigo);
    }

    private async getComboRotulos(origem: OrigemRotulo) {
        let rotulosOptions = this.preLoadList.rotulos;
        switch (origem) {
            case OrigemRotulo.Lote:
                rotulosOptions = rotulosOptions.filter(
                    p =>
                        p.origemDados != OrigemRotulo.Manipulacao &&
                        p.origemDados != OrigemRotulo.Acabado &&
                        p.origemDados != OrigemRotulo.Strips,
                );
                break;
            case OrigemRotulo.Manipulacao:
            case OrigemRotulo.Acabado:
            case OrigemRotulo.Strips:
                rotulosOptions = rotulosOptions.filter(
                    p =>
                        p.origemDados == OrigemRotulo.Manipulacao ||
                        p.origemDados == OrigemRotulo.Acabado ||
                        p.origemDados == OrigemRotulo.Strips,
                );
                break;
        }
        this.rotulosOptions = rotulosOptions.map(p => ({ text: p.nome, value: p.id }));
    }

    private geraRotuloDefault(): ConfiguracaoRotuloModel {
        const rotulo = new ConfiguracaoRotuloModel();
        let campo = new ConfiguracaoRotuloCamposModel();
        campo.campo = "Produto";
        campo.fonte = 12;
        campo.negrito = false;
        rotulo.campos.push(campo);

        campo = new ConfiguracaoRotuloCamposModel();
        campo.fonte = 12;
        campo.negrito = false;
        campo.campo = "Descricao";
        rotulo.campos.push(campo);
        return rotulo;
    }

    private getRotuloContainerStyle(selected: boolean) {
        const isSelectable = this.temRotuloAntigo && this.CalledByShortCut;

        return {
            cursor: `${isSelectable ? "pointer" : ""}`,
            outline: isSelectable && selected ? "6px solid #8bc53d" : "2px solid #000",
            maxHeight: "max-content",
        };
    }

    private async carregaRotulo(rotulo: ConfiguracaoRotuloModel, rotuloAntigo: ConfiguracaoRotuloModel = null) {
        this.loadingRotuloEdicao = true;
        this.model.updateFrom(rotulo);

        this.carregouValoresCampos = false;
        this.rotuloNovoSelecionado = true;
        this.temRotuloAntigo = false;
        if (rotuloAntigo != null) {
            if (!rotuloAntigo.nomeImpressora) {
                // se o rotulo ja utilizado nao tem impressora, usar o mesmo do novo rotulo, pois é a impressora padrão
                rotuloAntigo.nomeImpressora = rotulo.nomeImpressora;
            }
            this.modelUtilizado.updateFrom(rotuloAntigo);
            this.temRotuloAntigo = true;
        }

        this.model.campos = this.model.campos
            .map(campo => {
                let campoModel = new ConfiguracaoRotuloCamposModel();
                campoModel = campo;
                campoModel.campoOriginal = this.normalizarNomeCampo(campoModel.campo);
                return campoModel;
            })
            .sort((a, b) => (a.sequencia > b.sequencia ? 1 : -1));

        this.relacionarCamposValores();

        this.addAcoesGrid();
        await this.loadDadosFranquia();
        this.loadingRotuloEdicao = false;
    }

    private async load() {
        this.loadingRotuloEdicao = true;

        this.model = new ConfiguracaoRotuloModel();

        this.nomeProduto = this.$t("__.ts.visuImpress").toString();
        this.nomeConfiguracaoProduto = this.$t("__.ts.confImpress").toString();

        if (this.modelId > 0) {
            try {
                const data = await this.service
                    .get(this.modelId)
                    .withLoading()
                    .then(response => response.json() as Promise<ConfiguracaoRotuloModel>);

                await this.carregaRotulo(data);
            } catch {
                this.$router.back();
            }
        }

        if (!this.CalledByShortCut) {
            this.loadImpressoraOptions();
        }

        await this.loadOptionsCampos();
        this.loadingRotuloEdicao = false;
    }

    //@ts-ignore
    @Watch("rotuloSelecionado")
    private onChangeRotuloSelecionado() {
        if (
            this.rotuloSelecionado &&
            this.model.dadosId &&
            this.model.origemDados != null &&
            !this.loadingRotuloEdicao
        ) {
            if (this.isRotuloManipulacao) {
                this.getDadosManipulacao(this.model.dadosId, this.rotuloSelecionado).withLoading();
            } else {
                if (this.model.dadosId == 0) {
                    this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.msgLoteNaoEncontrado"));
                    return;
                }
                this.getDadosLote(this.model.dadosId, this.rotuloSelecionado).withLoading();
            }
        }
    }

    //@ts-ignore
    @Watch("model.origemDados")
    private onChangeOrigemDados() {
        if (!this.loadingRotuloEdicao) {
            this.model.campos = [];
        }
        this.loadOptionsCampos();
    }

    private async loadOptionsCampos() {
        if (this.CalledByShortCut) return;

        let data: string[] = [];

        switch (this.model.origemDados) {
            case OrigemRotulo.Fracionamento:
            case OrigemRotulo.Lote:
            case OrigemRotulo.Secundario:
            case OrigemRotulo.SecundarioPequeno:
                data = await this.service.getCamposTabelaLote().resolveWithJSON<string[]>();
                break;
            case OrigemRotulo.Acabado:
            case OrigemRotulo.Manipulacao:
            case OrigemRotulo.Strips:
                data = await this.service.getCamposTabelaManipulacao().resolveWithJSON<string[]>();
                break;
        }

        this.optionsCampos = data.map((campo, index) => ({ text: campo, value: index }));

        this.optionsCampos = this.optionsCampos
            .filter(o => this.model.campos.filter(u => o.text == u.campo).length == 0)
            .sort((a, b) => (a.text > b.text ? 1 : -1));
    }

    private async loadImpressoraOptions() {
        const printers = await new PrinterService().refreshPrinterQueues();

        const printerOptions = printers.map(printer => ({
            text: printer.printerName + (printer.printerDesc ? ` (${printer.printerDesc})` : ""),
            printerName: printer.printerName,
        }));

        // Se já houver uma impressora definida no modelo, colocar ela como primeira opção.
        if (this.model.nomeImpressora) {
            const res = printerOptions.findIndex(i => i.printerName == this.model.nomeImpressora);
            const printerName = this.model.nomeImpressora;
            const text = res == -1 ? `(offline) ${this.model.nomeImpressora}` : printerOptions.splice(res, 1)[0].text;
            printerOptions.unshift({ text, printerName });
        }

        this.impressoraOptions = printerOptions.map((option, index) => ({ ...option, value: index }));
        if (this.impressoraOptions.length) {
            this.impressoraSelecionada = 0;
        }
    }

    //@ts-ignore
    @Watch("impressoraSelecionada")
    private onChangeImpressoraSelecionada() {
        if (this.impressoraSelecionada != null) {
            this.model.nomeImpressora = this.impressoraOptions[this.impressoraSelecionada].printerName;
        } else {
            this.model.nomeImpressora = null;
        }
    }

    //@ts-ignore
    @Watch("model.campos", { deep: true })
    private onChangeCampos() {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => this.relacionarCamposValores(), 500);

        this.validaQuantidadeColunas();
    }
    //@ts-ignore
    @Watch("model.paddingHorizontal")
    private onChangePaddingHorizontal() {
        this.relacionarCamposValores();
    }
    //@ts-ignore
    @Watch("model.paddingVertical")
    private onChangePaddingVerticaol() {
        this.relacionarCamposValores();
    }
    //@ts-ignore
    @Watch("model.largura")
    private onChangeLargura() {
        this.relacionarCamposValores();
    }
    //@ts-ignore
    @Watch("model.altura")
    private onChangeAltura() {
        this.relacionarCamposValores();
    }

    private relacionarCamposValores() {
        if (this.carregouValoresCampos) {
            return;
        }

        this.model.campos = this.model.campos.map(campo => {
            const campoOriginal = campo.campoOriginal;

            if (!campoOriginal || !this.CalledByShortCut) {
                campo.value = campo.label;
            } else {
                if (this.isRotuloManipulacao) {
                    switch (campoOriginal) {
                        case "produto":
                            campo.value = this.modelDadosRotulo.descricao;
                            break;
                        case "cRMV":
                            campo.value = this.modelDadosRotulo.crmv;
                            break;
                        case "descricaoDaManipulacao":
                            campo.value = this.modelDadosRotulo.descricaoManipulacao;
                            break;
                        case "volumeComUnMed":
                            campo.value = this.modelDadosRotulo.volumeUnidMedida;
                            break;
                        default:
                            campo.value = this.modelDadosRotulo[campoOriginal];
                            break;
                    }
                } else {
                    campo.value = this.modelDadosRotulo[campoOriginal];
                }
            }

            return campo;
        });

        this.carregouValoresCampos = this.CalledByShortCut;
    }

    private async validaQuantidadeColunas() {
        this.model.campos.forEach(campo => {
            if (campo.quantidadeColunas < 1 || campo.quantidadeColunas > this.model.quantidadeColunas) {
                this.$showError(
                    this.$t("__.ts.atencao"),
                    this.$t("__.ts.quantidadeColunasInvalida") + campo.campo,
                ).then(() => {
                    campo.quantidadeColunas = this.model.quantidadeColunas;
                });
                return false;
            }
        });
        return true;
    }

    private normalizarNomeCampo(string: string): string {
        return string
            .replace(/[àáâãäå]/g, "a")
            .replace(/[ÀÁÂÃÄÅ]/g, "A")
            .replace(/[ÈÉÊË]/g, "E")
            .replace(/[èéê]/g, "e")
            .replace(/[ÌÍÎ]/g, "I")
            .replace(/[ìíî]/g, "i")
            .replace(/[ÒÓÔÕ]/g, "O")
            .replace(/[òóôõ]/g, "o")
            .replace(/[ÙÚÛ]/g, "U")
            .replace(/[ùúû]/g, "u")
            .replace(/[ç]/g, "c")
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
                if (+match === 0) {
                    return "";
                }

                return index === 0 ? match.toLowerCase() : match.toUpperCase();
            });
    }

    //@ts-ignore
    @Watch("model.respTecDadosFranquia")
    private async onChangeRespTecDadosFranquia() {
        await this.loadDadosFranquia();
    }

    //@ts-ignore
    @Watch("model.imprimirDadosFranquiaNegrito")
    private async onChangeImprimirDadosFranquiaNegrito() {
        await this.loadDadosFranquia();
    }

    private async loadDadosFranquia() {
        if (this.model.respTecDadosFranquia !== RespTecDadosFranquia.Nenhum) {
            if (!this.preLoadList.dadosFranquiaRotulo) {
                this.preLoadList.dadosFranquiaRotulo = await this.service
                    .getDadosFranquia()
                    .resolveWithJSON<string[]>();
            }

            if (this.model.origemDados == OrigemRotulo.Acabado || this.model.origemDados == OrigemRotulo.Strips) {
                this.model.dadosResponsavelTecnicoFranquia = this.preLoadList.dadosFranquiaRotulo[3] as string;
                this.modelUtilizado.dadosResponsavelTecnicoFranquia = this.preLoadList.dadosFranquiaRotulo[3] as string;
            } else {
                this.model.dadosResponsavelTecnicoFranquia = this.preLoadList.dadosFranquiaRotulo[2] as string;
                this.modelUtilizado.dadosResponsavelTecnicoFranquia = this.preLoadList.dadosFranquiaRotulo[2] as string;
            }

            if (!this.preLoadList.dadosFranquiaRotulo) {
                this.preLoadList.dadosFranquiaRotulo = await this.service
                    .getDadosFranquia()
                    .resolveWithJSON<string[]>();
            }

            if (this.model.origemDados == OrigemRotulo.Acabado || this.model.origemDados == OrigemRotulo.Strips) {
                this.model.dadosFranquia = this.preLoadList.dadosFranquiaRotulo[1] as string;
            } else {
                this.model.dadosFranquia = this.preLoadList.dadosFranquiaRotulo[0] as string;
            }

            this.relacionarCamposValores();
        }
    }

    private onInclusaoCampo() {
        const dadosAdicionaisComponent = this.$el.querySelector("#campoId") as HTMLSelectElement;

        if (this.campoSelected != null) {
            const alreadyExists = this.model.campos.filter(da => da.id == this.campoSelected).length > 0;

            if (!alreadyExists) {
                const camposModel = new ConfiguracaoRotuloCamposModel();

                camposModel.id = this.campoSelected;
                camposModel.campo = dadosAdicionaisComponent.innerText;
                camposModel.campoOriginal = this.normalizarNomeCampo(camposModel.campo);
                camposModel.fonte = 12;
                camposModel.label = camposModel.campo;
                camposModel.quantidadeColunas = this.model.quantidadeColunas;

                this.model.campos.push(camposModel);

                this.optionsCampos = this.optionsCampos.filter(
                    o => this.model.campos.filter(u => o.text == u.campo).length == 0,
                );

                this.campoSelected = -1;
            }
        }

        this.addAcoesGrid();
    }

    private addAcoesGrid() {
        this.gridExtraActionsUp.splice(0);
        this.gridExtraActionsDown.splice(0);
        this.gridExtraActionsGetOld.splice(0);
        this.extraActions.splice(0);

        for (const campo of this.model.campos) {
            if (this.CalledByShortCut) {
                const oldCampo = this.modelUtilizado.campos.find(p => p.campo == campo.campo);

                this.gridExtraActionsGetOld.push(
                    new GridAction(
                        "get-old",
                        oldCampo ? oldCampo.value : "Campo não utilizado no rótulo antigo",
                        "fa fa-undo",
                        GridColors.BLUE,
                        true,
                    ),
                );
            }
            this.gridExtraActionsUp.push(
                new GridAction("up", "Colocar para cima", "fa fa-arrow-up", GridColors.DARKGRAY, true),
            );
            this.gridExtraActionsDown.push(
                new GridAction("down", "Colocar para baixo", "fa fa-arrow-down", GridColors.DARKGRAY, true),
            );
        }

        this.extraActions.push(this.gridExtraActionsGetOld);
        this.extraActions.push(this.gridExtraActionsUp);
        this.extraActions.push(this.gridExtraActionsDown);
    }

    private addAcoesCopyGrid() {
        this.gridExtraActionsCopy.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.model.campos.length; i++) {
            this.gridExtraActionsCopy.push(
                new GridAction("copy", "Copiar Texto", "fa fa-clone", GridColors.DARKBLUE, true),
            );
        }
        this.extraActions.push(this.gridExtraActionsCopy);
    }

    private onRemoveCampo(model: ConfiguracaoRotuloCamposModel) {
        this.model.campos = this.model.campos.filter(da => da != model);

        this.loadOptionsCampos();
    }

    private onExtraAction(name: string, model: ConfiguracaoRotuloCamposModel) {
        if (name.trim() == "up") {
            this.model.campos = changePosition(this.model.campos, model, "UP");
        }

        if (name.trim() == "down") {
            this.model.campos = changePosition(this.model.campos, model, "DOWN");
        }

        if (name.trim() == "copy") {
            const dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = model.value;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            this.$showSuccess(this.$t("__.ts.copiaa"), this.$t("__.ts.regisCopiadoSucess"));
        }

        if (name.trim() == "get-old") {
            const campo = this.model.campos.find(p => p.campo == model.campo);
            const oldCampo = this.modelUtilizado.campos.find(p => p.campo == model.campo);
            if (oldCampo) {
                campo.value = oldCampo.value;
            } else {
                this.$showWarning(this.$t("__.ts.atencao"), "Campo não utilizado no rótulo antigo");
            }
        }
    }

    private onSelectRotulo(newRotulo: boolean) {
        if (this.CalledByShortCut && this.temRotuloAntigo) {
            this.rotuloNovoSelecionado = newRotulo;
            this.extraActions = [];

            if (this.rotuloNovoSelecionado) {
                this.addAcoesGrid();
            } else {
                this.addAcoesCopyGrid();
            }
        }
    }

    private onImprimirChange(data) {
        this.model.imprimir = data;
    }

    private onCollapsedStatusChange(newValue: boolean) {
        this.collapsedCamposImpressoesFieldset = newValue;
    }

    private async save() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            if (!(await this.validaQuantidadeColunas())) return;

            this.model.campos = this.model.campos.map((campo, index) => {
                let campoModel = new ConfiguracaoRotuloCamposModel();
                campoModel = campo;
                campoModel.sequencia = index + 1;
                return campoModel;
            });

            try {
                const sucesso = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                    this.LOAD_LIST({ loadRotulos: true });
                    this.$router.back();
                }
            } catch {}
        } else {
            this.$focusErrorField();
        }
    }

    private cancel() {
        this.$router.back();
    }

    private async clear() {
        this.model = new ConfiguracaoRotuloModel();
        this.modelUtilizado = new ConfiguracaoRotuloModel();
        this.temRotuloAntigo = false;
        this.rotuloNovoSelecionado = true;
        this.carregouValoresCampos = false;
        this.modelId = null;
    }

    private async mounted() {
        this.clear();

        this.collapsedCamposImpressoesFieldset = this.collapsedCamposImpressoes;

        this.isAdminFranqueador = await this.HAS_PERMISSAO_PERFIL(false, true, false);

        this.replicarSelecaoFranquiaComponent = this.$refs
            .replicarSelecaoFranquiaComponent as ReplicarSelecaoFranquiaComponent;

        if (this.$route.params.id) {
            this.modelId = +this.$route.params.id;
        }

        if (!this.CalledByShortCut) {
            this.load();
        }
    }
}
