var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset-component",
    { attrs: { title: "Itens", collapsed: false } },
    [
      _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
        _c(
          "div",
          { staticClass: "crud crud-list" },
          [
            _vm.showItem
              ? [
                  _c(
                    "vue-tabs",
                    { staticStyle: { margin: "15px 0 0" } },
                    [
                      _c(
                        "v-tab",
                        { attrs: { title: "Item" } },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "produtoId" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.produto"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("data-tooltip-component", {
                                    attrs: {
                                      text: "Produto que será utilizado como base para carregar as informações do item",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    attrs: {
                                      id: "produtoId",
                                      name: "produtoId",
                                      data: _vm.produtosOptions,
                                      searchable: !_vm.consulta,
                                      refresh: _vm.consulta
                                        ? null
                                        : _vm.loadProdutos,
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.produtoId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "produtoId",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.produtoId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-5" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("ncmId"),
                                      expression: "errors.first('ncmId')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "ncmId",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.ncm"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("search-combo-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      id: "ncmId",
                                      name: "ncmId",
                                      disabled: _vm.consulta,
                                      customView: false,
                                      advancedSearch: true,
                                      service: "Ncm",
                                      propDescription: "descricao",
                                      customComboText: _vm.customComboTextNCM,
                                      advancedSearchCampos: [
                                        {
                                          campo: "codigo",
                                          descricao: "codigo",
                                        },
                                        {
                                          campo: "text",
                                          descricao: "descricao",
                                        },
                                      ],
                                    },
                                    model: {
                                      value: _vm.modelItem.ncmId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.modelItem, "ncmId", $$v)
                                      },
                                      expression: "modelItem.ncmId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value:
                                        _vm.errors.first("unidadeMedidaId"),
                                      expression:
                                        "errors.first('unidadeMedidaId')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "unidadeMedidaId",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.unMed"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("combo-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    attrs: {
                                      id: "unidadeMedidaId",
                                      name: "unidadeMedidaId",
                                      data: _vm.unidadeMedidaOptions.filter(
                                        function (p) {
                                          return p.type < 3
                                        }
                                      ),
                                      searchable: !_vm.consulta,
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.unidadeMedidaId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "unidadeMedidaId",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.unidadeMedidaId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value:
                                        _vm.errors.first("produtoDescricao"),
                                      expression:
                                        "errors.first('produtoDescricao')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "produtoDescricao",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        Descrição do produto\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("data-tooltip-component", {
                                    attrs: {
                                      text: "Descrição do produto que será emitido na nota",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.modelItem.produtoDescricao,
                                        expression:
                                          "modelItem.produtoDescricao",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "produtoDescricao",
                                      name: "produtoDescricao",
                                      disabled: _vm.consulta,
                                    },
                                    domProps: {
                                      value: _vm.modelItem.produtoDescricao,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.modelItem,
                                          "produtoDescricao",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("quantidade"),
                                      expression: "errors.first('quantidade')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "quantidade",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.qtde"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("decimal-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "maior_que_zero",
                                        expression: "'maior_que_zero'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "quantidade",
                                      name: "quantidade",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.quantidade,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "quantidade",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.quantidade",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("valorUnitario"),
                                      expression:
                                        "errors.first('valorUnitario')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "valorUnitario",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.vlrUnitario"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "mask-moeda-decimal",
                                        rawName: "v-mask-moeda-decimal",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "valorUnitario",
                                      name: "valorUnitario",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.valorUnitario,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "valorUnitario",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.valorUnitario",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("valorDesconto"),
                                      expression:
                                        "errors.first('valorDesconto')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "valorDesconto",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.desconto"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "valorDesconto",
                                      name: "valorDesconto",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.valorDesconto,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "valorDesconto",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.valorDesconto",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("valorFrete"),
                                      expression: "errors.first('valorFrete')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "valorFrete",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.frete"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "valorFrete",
                                      name: "valorFrete",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.valorFrete,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "valorFrete",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.valorFrete",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("valorSeguro"),
                                      expression: "errors.first('valorSeguro')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "valorSeguro",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.seguro"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "valorSeguro",
                                      name: "valorSeguro",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.valorSeguro,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "valorSeguro",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.valorSeguro",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value:
                                        _vm.errors.first("valorOutraDespesa"),
                                      expression:
                                        "errors.first('valorOutraDespesa')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "valorOutraDespesa",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.outrasDespesas"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "valorOutraDespesa",
                                      name: "valorOutraDespesa",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.valorOutraDespesa,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "valorOutraDespesa",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.valorOutraDespesa",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "codigoBeneficio" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Código Benefício Fiscal\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.modelItem.codigoBeneficio,
                                      expression: "modelItem.codigoBeneficio",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "codigoBeneficio",
                                    name: "codigoBeneficio",
                                    disabled: _vm.consulta,
                                  },
                                  domProps: {
                                    value: _vm.modelItem.codigoBeneficio,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.modelItem,
                                        "codigoBeneficio",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "informacaoAdicional" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Components.parent.nfe.item_vue_html.infoAdicional"
                                          )
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.modelItem.informacaoAdicional,
                                      expression:
                                        "modelItem.informacaoAdicional",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "informacaoAdicional",
                                    name: "informacaoAdicional",
                                    disabled: _vm.consulta,
                                  },
                                  domProps: {
                                    value: _vm.modelItem.informacaoAdicional,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.modelItem,
                                        "informacaoAdicional",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("valorTotal"),
                                      expression: "errors.first('valorTotal')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "valorTotal" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm.$t(
                                              "__.Components.parent.nfe.item_vue_html.vlrTotal"
                                            )
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      readonly: "",
                                      id: "valorTotal",
                                      name: "valorTotal",
                                    },
                                    model: {
                                      value: _vm.modelItem.valorTotal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "valorTotal",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.valorTotal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            !_vm.consulta
                              ? _c("div", { staticClass: "col-sm-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "produtoLoteId" },
                                        },
                                        [
                                          _vm.nota.movimentarEstoque
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [_vm._v("*")]
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                                        Lote do produto\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "combo-component",
                                        {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: {
                                                rules: {
                                                  required:
                                                    _vm.nota.movimentarEstoque,
                                                },
                                              },
                                              expression:
                                                "{\n                                            rules: {\n                                                required: nota.movimentarEstoque,\n                                            },\n                                        }",
                                            },
                                          ],
                                          attrs: {
                                            id: "produtoLoteId",
                                            name: "produtoLoteId",
                                            actionName: "lotes",
                                            addEditId:
                                              _vm.modelItem.produtoLoteId > 0 &&
                                              !_vm.consulta
                                                ? _vm.modelItem.produtoLoteId
                                                : 0,
                                            addNew:
                                              _vm.modelItem.produtoLoteId > 0 ||
                                              !_vm.consulta,
                                            data: _vm.produtoLoteOptions,
                                            formAddNewName:
                                              _vm.modelItem.produtoLoteId > 0
                                                ? "Editar lote"
                                                : "Novo lote",
                                            searchable: true,
                                            useCloseModalCallback: true,
                                          },
                                          on: {
                                            addNewItemCombo:
                                              _vm.onAddProdutoLote,
                                            openComboEditItem:
                                              _vm.openComboEditProdutoLote,
                                            openComboNewItem:
                                              _vm.openNewProdutoLote,
                                          },
                                          model: {
                                            value: _vm.modelItem.produtoLoteId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.modelItem,
                                                "produtoLoteId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "modelItem.produtoLoteId",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "component" },
                                              slot: "component",
                                            },
                                            [
                                              _c("produto-lote-component", {
                                                ref: "produtoLoteComponent",
                                                attrs: {
                                                  CalledByShortCut: true,
                                                  IdProduto:
                                                    _vm.modelItem.produtoId,
                                                },
                                                on: {
                                                  "on-load-lote": function () {
                                                    return _vm.loadProdutoLotes(
                                                      _vm.item.produtoId
                                                    )
                                                  },
                                                  "save-ok":
                                                    _vm.onProdutoLoteSaveOk,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _c("div", { staticClass: "col-sm-6" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "control-label",
                                        attrs: { for: "numeroLote" },
                                      },
                                      [_vm._v("Lote do produto")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.modelItem.numeroLote,
                                          expression: "modelItem.numeroLote",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "numeroLote",
                                        name: "numeroLote",
                                        disabled: true,
                                      },
                                      domProps: {
                                        value: _vm.modelItem.numeroLote,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.modelItem,
                                            "numeroLote",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-3" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "dataFabricacao" },
                                    },
                                    [_vm._v("Data de fabricacao")]
                                  ),
                                  _vm._v(" "),
                                  _c("date-time-picker-component", {
                                    attrs: {
                                      id: "dataFabricacao",
                                      name: "dataFabricacao",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.dataFabricacao,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "dataFabricacao",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.dataFabricacao",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-3" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "dataValidade" },
                                    },
                                    [_vm._v("Data de validade")]
                                  ),
                                  _vm._v(" "),
                                  _c("date-time-picker-component", {
                                    attrs: {
                                      id: "dataValidade",
                                      name: "dataValidade",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value: _vm.modelItem.dataValidade,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "dataValidade",
                                          $$v
                                        )
                                      },
                                      expression: "modelItem.dataValidade",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.errors.first("codigoAnvisa"),
                                      expression:
                                        "errors.first('codigoAnvisa')",
                                    },
                                  ],
                                  staticClass: "form-group",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "codigoBeneficio" },
                                    },
                                    [_vm._v("Código Anvisa")]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.modelItem.codigoAnvisa,
                                        expression: "modelItem.codigoAnvisa",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "min:13",
                                        expression: "'min:13'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "codigoAnvisa",
                                      name: "codigoAnvisa",
                                      autocomplete: "off",
                                      disabled: _vm.consulta,
                                    },
                                    domProps: {
                                      value: _vm.modelItem.codigoAnvisa,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.modelItem,
                                          "codigoAnvisa",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-2" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: { for: "precoMaximoConsumidor" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Preço Máximo Consumidor\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("moeda-component", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "precoMaximoConsumidor",
                                      name: "precoMaximoConsumidor",
                                      disabled: _vm.consulta,
                                    },
                                    model: {
                                      value:
                                        _vm.modelItem.precoMaximoConsumidor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.modelItem,
                                          "precoMaximoConsumidor",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "modelItem.precoMaximoConsumidor",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          !_vm.consulta
                            ? _c("buttonIncluirComponent", {
                                attrs: {
                                  alterar: _vm.editingModel,
                                  cancelar: _vm.editingModel,
                                },
                                on: {
                                  click: _vm.onAdicionarItem,
                                  cancelar: _vm.onCancelar,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.modelItem.produtoDescricao != null
                        ? _c("v-tab", { attrs: { title: "Impostos" } }, [
                            _c(
                              "div",
                              { staticStyle: { margin: "15px" } },
                              [
                                _c(
                                  "fieldset-component",
                                  {
                                    attrs: { title: "ICMS", collapsed: false },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { slot: "rows" }, slot: "rows" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-6" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "origemMercadoria"
                                                        ),
                                                      expression:
                                                        "errors.first('origemMercadoria')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "origemMercadoria",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Origem Mercadoria\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("combo-component", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "origemMercadoria",
                                                      name: "origemMercadoria",
                                                      data: _vm.origemMercadoriaOptions,
                                                      searchable: false,
                                                      disabled: _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem
                                                          .origemMercadoria,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "origemMercadoria",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.origemMercadoria",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-6" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "cstICMS"
                                                        ),
                                                      expression:
                                                        "errors.first('cstICMS')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "cstICMS",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Situação tributaria\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("combo-component", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "cstICMS",
                                                      name: "cstICMS",
                                                      data: _vm.cstICMSOptions,
                                                      searchable: false,
                                                      disabled:
                                                        _vm.consulta ||
                                                        (_vm.nota
                                                          .notaExportacao &&
                                                          _vm.modelItem
                                                            .produtoId &&
                                                          _vm.nota
                                                            .finalidadeEmissao !=
                                                            4),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem.cstICMS,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "cstICMS",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.cstICMS",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        !_vm.usuarioLogado.franquia
                                          .opcaoSimplesNacional ||
                                        _vm.modelItem.cstICMS == 900
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "baseICMS",
                                                          },
                                                        },
                                                        [_vm._v("Base")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "baseICMS",
                                                          name: "baseICMS",
                                                          disabled:
                                                            _vm.modelItem
                                                              .tipoCSTICMS ==
                                                              2 || _vm.consulta,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .baseICMS,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "baseICMS",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.baseICMS",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "aliquotaICMS",
                                                          },
                                                        },
                                                        [_vm._v("Alíquota")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "aliquotaICMS",
                                                            name: "aliquotaICMS",
                                                            disabled:
                                                              _vm.modelItem
                                                                .tipoCSTICMS !=
                                                                1 ||
                                                              _vm.consulta,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .aliquotaICMS,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "aliquotaICMS",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.aliquotaICMS",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "valorICMS",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm.modelItem
                                                                  .cstICMS == 51
                                                                  ? "Valor ICMS Operação"
                                                                  : "Valor"
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "valorICMS",
                                                          name: "valorICMS",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .valorICMS,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "valorICMS",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.valorICMS",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.usuarioLogado.franquia
                                          .opcaoSimplesNacional &&
                                        (_vm.modelItem.cstICMS == 101 ||
                                          _vm.modelItem.cstICMS == 201)
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "percentualCreditoSimplesNacional",
                                                          },
                                                        },
                                                        [
                                                          _vm.modelItem
                                                            .cstICMS == 101
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "red",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        *\n                                                    "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                                                    Percentual de Crédito SN\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "percentualCreditoSimplesNacional",
                                                            name: "percentualCreditoSimplesNacional",
                                                            disabled:
                                                              _vm.consulta,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .percentualCreditoSimplesNacional,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "percentualCreditoSimplesNacional",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.percentualCreditoSimplesNacional",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "valorCreditoSimplesNacional",
                                                          },
                                                        },
                                                        [
                                                          _vm.modelItem
                                                            .cstICMS == 101
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "red",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                        *\n                                                    "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                                                    Valor de Crédito ICMS SN\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "valorCreditoSimplesNacional",
                                                          name: "valorCreditoSimplesNacional",
                                                          disabled:
                                                            _vm.consulta,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .valorCreditoSimplesNacional,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "valorCreditoSimplesNacional",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.valorCreditoSimplesNacional",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.modelItem.cstICMS == 51
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "percentualDiferimento",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Percentual Diferimento\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "percentualDiferimento",
                                                            name: "percentualDiferimento",
                                                            disabled:
                                                              _vm.consulta,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .percentualDiferimento,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "percentualDiferimento",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.percentualDiferimento",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "valorICMSDiferido",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Valor ICMS Diferido\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "valorICMSDiferido",
                                                          name: "valorICMSDiferido",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .valorICMSDiferido,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "valorICMSDiferido",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.valorICMSDiferido",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "valorICMSPagar",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Valor ICMS a Pagar\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "valorICMSPagar",
                                                          name: "valorICMSPagar",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .valorICMSPagar,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "valorICMSPagar",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.valorICMSPagar",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.modelItem.cstICMS == 20
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value:
                                                            _vm.errors.first(
                                                              "percentualReducaoBase"
                                                            ),
                                                          expression:
                                                            "errors.first('percentualReducaoBase')",
                                                        },
                                                      ],
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "percentualReducaoBase",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Percentual Redução de Base\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "percentualReducaoBase",
                                                            name: "percentualReducaoBase",
                                                            disabled:
                                                              _vm.consulta,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .percentualReducaoBase,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "percentualReducaoBase",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.percentualReducaoBase",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "baseReduzida",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Base reduzida\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "baseReduzida",
                                                          name: "baseReduzida",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .baseReduzida,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "baseReduzida",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.baseReduzida",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.nota.vendaInterestadual &&
                                !(
                                  _vm.consulta && !_vm.modelItem.comICMSPartilha
                                ) &&
                                !_vm.nota.naturezaOperacao.simplesRemessa
                                  ? _c(
                                      "fieldset-component",
                                      {
                                        attrs: {
                                          title: "ICMS INTERESTADUAL",
                                          collapsed: true,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "rows" },
                                            slot: "rows",
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              !_vm.consulta
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col-sm-1" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "control-label",
                                                              attrs: {
                                                                for: "comICMSPartilha",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    ICMS Partilha\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "checkbox-component",
                                                            {
                                                              attrs: {
                                                                id: "comICMSPartilha",
                                                                name: "comICMSPartilha",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.modelItem
                                                                    .comICMSPartilha,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.modelItem,
                                                                      "comICMSPartilha",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "modelItem.comICMSPartilha",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "baseUFDestino",
                                                            title:
                                                              _vm.$t(
                                                                "__.obrigatorio"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v(
                                                            "\n                                                    Base UF de Destino\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "baseUFDestino",
                                                          name: "baseUFDestino",
                                                          disabled:
                                                            _vm.consulta ||
                                                            !_vm.modelItem
                                                              .comICMSPartilha,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .baseUFDestino,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "baseUFDestino",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.baseUFDestino",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "aliquotaUFDestino",
                                                            title:
                                                              _vm.$t(
                                                                "__.obrigatorio"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v(
                                                            "\n                                                    Alíquota UF de Destino\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "aliquotaUFDestino",
                                                            name: "aliquotaUFDestino",
                                                            disabled:
                                                              _vm.consulta ||
                                                              !_vm.modelItem
                                                                .comICMSPartilha,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .aliquotaUFDestino,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "aliquotaUFDestino",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.aliquotaUFDestino",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "aliquotaInterestadual",
                                                            title:
                                                              _vm.$t(
                                                                "__.obrigatorio"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v(
                                                            "\n                                                    Alíquota interestadual\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "aliquotaInterestadual",
                                                            name: "aliquotaInterestadual",
                                                            disabled:
                                                              _vm.consulta ||
                                                              !_vm.modelItem
                                                                .comICMSPartilha,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .aliquotaInterestadual,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "aliquotaInterestadual",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.aliquotaInterestadual",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  class: _vm.consulta
                                                    ? "col-sm-2"
                                                    : "col-sm-1",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "aliquotaInterestadualPartilha",
                                                            title:
                                                              _vm.$t(
                                                                "__.obrigatorio"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v(
                                                            "\n                                                    % da partilha\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "aliquotaInterestadualPartilha",
                                                            name: "aliquotaInterestadualPartilha",
                                                            disabled:
                                                              _vm.consulta ||
                                                              !_vm.modelItem
                                                                .comICMSPartilha,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .aliquotaInterestadualPartilha,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "aliquotaInterestadualPartilha",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.aliquotaInterestadualPartilha",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "ICMSUFDestino",
                                                            title:
                                                              _vm.$t(
                                                                "__.obrigatorio"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v(
                                                            "\n                                                    Valor ICMS UF de Destino\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "ICMSUFDestino",
                                                          name: "ICMSUFDestino",
                                                          disabled:
                                                            _vm.consulta ||
                                                            !_vm.modelItem
                                                              .comICMSPartilha,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .ICMSUFDestino,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "ICMSUFDestino",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.ICMSUFDestino",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-2" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "ICMSUFRemetente",
                                                            title:
                                                              _vm.$t(
                                                                "__.obrigatorio"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v(
                                                            "\n                                                    Valor ICMS UF Rementente\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "ICMSUFRemetente",
                                                          name: "ICMSUFRemetente",
                                                          disabled:
                                                            _vm.consulta ||
                                                            !_vm.modelItem
                                                              .comICMSPartilha,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .ICMSUFRemetente,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "ICMSUFRemetente",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.ICMSUFRemetente",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "baseFCPUFDestino",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Base FCP UF de Destino\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "baseFCPUFDestino",
                                                          name: "baseFCPUFDestino",
                                                          disabled:
                                                            _vm.consulta ||
                                                            !_vm.modelItem
                                                              .comICMSPartilha,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .baseFCPUFDestino,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "baseFCPUFDestino",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.baseFCPUFDestino",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "aliquotaFCPUFDestino",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Alíquota FCP UF de Destino\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "aliquotaFCPUFDestino",
                                                            name: "aliquotaFCPUFDestino",
                                                            disabled:
                                                              _vm.consulta ||
                                                              !_vm.modelItem
                                                                .comICMSPartilha,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .aliquotaFCPUFDestino,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "aliquotaFCPUFDestino",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.aliquotaFCPUFDestino",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "ICMSFCPUFDestino",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Valor FCP UF de Destino\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "ICMSFCPUFDestino",
                                                          name: "ICMSFCPUFDestino",
                                                          disabled:
                                                            _vm.consulta ||
                                                            !_vm.modelItem
                                                              .comICMSPartilha,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .ICMSFCPUFDestino,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "ICMSFCPUFDestino",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.ICMSFCPUFDestino",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.nota.notaImportacao
                                  ? _c(
                                      "fieldset-component",
                                      {
                                        attrs: {
                                          title: "Imposto de Importação (II)",
                                          collapsed: false,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "rows" },
                                            slot: "rows",
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "baseII",
                                                          },
                                                        },
                                                        [_vm._v("Base")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "baseII",
                                                          name: "baseII",
                                                          disabled:
                                                            _vm.consulta,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .baseII,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "baseII",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.baseII",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "aliquotaII",
                                                          },
                                                        },
                                                        [_vm._v("Alíquota")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "aliquotaII",
                                                            name: "aliquotaII",
                                                            disabled:
                                                              _vm.consulta,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .aliquotaII,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "aliquotaII",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.aliquotaII",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "valorII",
                                                          },
                                                        },
                                                        [_vm._v("Valor")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "valorII",
                                                          name: "valorII",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .valorII,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "valorII",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.valorII",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "valorDespesasAduaneiras",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Despesas aduaneiras (Taxa SISCOMEX)\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "valorDespesasAduaneiras",
                                                          name: "valorDespesasAduaneiras",
                                                          disabled:
                                                            _vm.consulta,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .valorDespesasAduaneiras,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "valorDespesasAduaneiras",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.valorDespesasAduaneiras",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "valorIOF",
                                                          },
                                                        },
                                                        [_vm._v("Valor IOF")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "valorIOF",
                                                          name: "valorIOF",
                                                          disabled:
                                                            _vm.consulta,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .valorIOF,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "valorIOF",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.valorIOF",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.modelItem.cstICMS == 60
                                  ? _c(
                                      "fieldset-component",
                                      {
                                        attrs: {
                                          title: "ICMS ST",
                                          collapsed: false,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "rows" },
                                            slot: "rows",
                                          },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c("div", { class: "col-sm-4" }, [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for: "baseSTRetido",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Base ST Retido:\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("moeda-component", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "baseSTRetido",
                                                        name: "baseSTRetido",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .baseSTRetido,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "baseSTRetido",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.baseSTRetido",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", { class: "col-sm-4" }, [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for: "aliquotaSTComFCP",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Alíquota ST com FCP:\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "decimal-com-sinal-custom-component",
                                                      {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          signal: "%",
                                                          id: "aliquotaSTComFCP",
                                                          name: "aliquotaSTComFCP",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .aliquotaSTComFCP,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "aliquotaSTComFCP",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.aliquotaSTComFCP",
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", { class: "col-sm-4" }, [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for: "valorSTRetido",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Valor ST Retido:\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("moeda-component", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "valorSTRetido",
                                                        name: "valorSTRetido",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .valorSTRetido,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "valorSTRetido",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.valorSTRetido",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", { class: "col-sm-4" }, [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for: "baseFCPSTRetido",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Base FCP ST Retido:\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("moeda-component", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "baseFCPSTRetido",
                                                        name: "baseFCPSTRetido",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .baseFCPSTRetido,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "baseFCPSTRetido",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.baseFCPSTRetido",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "aliquotaFCPSTRetido",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Alíquota FCP ST Retido:\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "aliquotaFCPSTRetido",
                                                            name: "aliquotaFCPSTRetido",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .aliquotaFCPSTRetido,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "aliquotaFCPSTRetido",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.aliquotaFCPSTRetido",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", { class: "col-sm-4" }, [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for: "valorFCPSTRetido",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Valor FCP ST Retido:\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("moeda-component", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "valorFCPSTRetido",
                                                        name: "valorFCPSTRetido",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .valorFCPSTRetido,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "valorFCPSTRetido",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.valorFCPSTRetido",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", { class: "col-sm-3" }, [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for: "baseICMSEfetivo",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Base ICMS Efetivo:\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("moeda-component", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "baseICMSEfetivo",
                                                        name: "baseICMSEfetivo",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .baseICMSEfetivo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "baseICMSEfetivo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.baseICMSEfetivo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "aliquotaICMSEfetivo",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Alíquota ICMS Efetivo:\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "aliquotaICMSEfetivo",
                                                            name: "aliquotaICMSEfetivo",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .aliquotaICMSEfetivo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "aliquotaICMSEfetivo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.aliquotaICMSEfetivo",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", { class: "col-sm-3" }, [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "control-label",
                                                        attrs: {
                                                          for: "valorICMSEfetivo",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Valor ICMS Efetivo:\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("moeda-component", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "valorICMSEfetivo",
                                                        name: "valorICMSEfetivo",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .valorICMSEfetivo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "valorICMSEfetivo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.valorICMSEfetivo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "percentualReducaoICMSEfetivo",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Percentual Redução ICMS Efetivo:\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "percentualReducaoICMSEfetivo",
                                                            name: "percentualReducaoICMSEfetivo",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .percentualReducaoICMSEfetivo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "percentualReducaoICMSEfetivo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.percentualReducaoICMSEfetivo",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "fieldset-component",
                                  { attrs: { title: "IPI", collapsed: false } },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { slot: "rows" }, slot: "rows" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          !_vm.consulta
                                            ? _c(
                                                "div",
                                                { staticClass: "col-sm-1" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "sujeitoIPI",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Sujeito ao IPI"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("checkbox-component", {
                                                        attrs: {
                                                          id: "sujeitoIPI",
                                                          name: "sujeitoIPI",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .sujeitoIPI,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "sujeitoIPI",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.sujeitoIPI",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              class: _vm.consulta
                                                ? "col-sm-4"
                                                : "col-sm-3",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: { for: "baseIPI" },
                                                    },
                                                    [_vm._v("Base")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("moeda-component", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "baseIPI",
                                                      name: "baseIPI",
                                                      disabled:
                                                        _vm.modelItem
                                                          .tipoCSTIPI == 2 ||
                                                        !_vm.modelItem
                                                          .sujeitoIPI ||
                                                        _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem.baseIPI,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "baseIPI",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.baseIPI",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "aliquotaIPI",
                                                      },
                                                    },
                                                    [_vm._v("Alíquota")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "decimal-com-sinal-custom-component",
                                                    {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        signal: "%",
                                                        id: "aliquotaIPI",
                                                        name: "aliquotaIPI",
                                                        disabled:
                                                          _vm.modelItem
                                                            .tipoCSTIPI != 1 ||
                                                          !_vm.modelItem
                                                            .sujeitoIPI ||
                                                          _vm.consulta,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .aliquotaIPI,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "aliquotaIPI",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.aliquotaIPI",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "valorIPI",
                                                      },
                                                    },
                                                    [_vm._v("Valor")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("moeda-component", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "valorIPI",
                                                      name: "valorIPI",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem.valorIPI,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "valorIPI",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.valorIPI",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.errors.first(
                                                        "codigoEnquadramento"
                                                      ),
                                                      expression:
                                                        "errors.first('codigoEnquadramento')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "codigoEnquadramento",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    C. Enquadramento\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.modelItem
                                                            .codigoEnquadramento,
                                                        expression:
                                                          "modelItem.codigoEnquadramento",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "number",
                                                      id: "codigoEnquadramento",
                                                      name: "codigoEnquadramento",
                                                      disabled:
                                                        !_vm.modelItem
                                                          .sujeitoIPI ||
                                                        _vm.consulta,
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.modelItem
                                                          .codigoEnquadramento,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "codigoEnquadramento",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "cstIPI"
                                                        ),
                                                      expression:
                                                        "errors.first('cstIPI')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "cstIPI",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Situação tributaria\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("combo-component", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "cstIPI",
                                                      name: "cstIPI",
                                                      data: _vm.cstIPIOptions,
                                                      searchable: false,
                                                      disabled:
                                                        !_vm.modelItem
                                                          .sujeitoIPI ||
                                                        _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem.cstIPI,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "cstIPI",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.cstIPI",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.nota.finalidadeEmissao == 4
                                          ? _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "percentualDevolvido",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Percentual de devolução\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "decimal-com-sinal-custom-component",
                                                        {
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            signal: "%",
                                                            id: "percentualDevolvido",
                                                            name: "percentualDevolvido",
                                                            disabled:
                                                              _vm.consulta,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modelItem
                                                                .percentualDevolvido,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modelItem,
                                                                "percentualDevolvido",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modelItem.percentualDevolvido",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "col-sm-6" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "control-label",
                                                          attrs: {
                                                            for: "valorIPIDevolvido",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Valor IPI Devolvido\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("moeda-component", {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: "valorIPIDevolvido",
                                                          name: "valorIPIDevolvido",
                                                          disabled:
                                                            _vm.consulta,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.modelItem
                                                              .valorIPIDevolvido,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.modelItem,
                                                              "valorIPIDevolvido",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "modelItem.valorIPIDevolvido",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "fieldset-component",
                                  { attrs: { title: "PIS", collapsed: false } },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { slot: "rows" }, slot: "rows" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: { for: "basePIS" },
                                                    },
                                                    [_vm._v("Base")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("moeda-component", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "basePIS",
                                                      name: "basePIS",
                                                      disabled:
                                                        _vm.modelItem
                                                          .tipoCSTPIS == 2 ||
                                                        _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem.basePIS,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "basePIS",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.basePIS",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "aliquotaPIS",
                                                      },
                                                    },
                                                    [_vm._v("Alíquota")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "decimal-com-sinal-custom-component",
                                                    {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        signal: "%",
                                                        id: "aliquotaPIS",
                                                        name: "aliquotaPIS",
                                                        disabled:
                                                          _vm.modelItem
                                                            .tipoCSTPIS != 1 ||
                                                          _vm.consulta,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .aliquotaPIS,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "aliquotaPIS",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.aliquotaPIS",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "valorPIS",
                                                      },
                                                    },
                                                    [_vm._v("Valor")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("moeda-component", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "valorPIS",
                                                      name: "valorPIS",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem.valorPIS,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "valorPIS",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.valorPIS",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "cstPIS"
                                                        ),
                                                      expression:
                                                        "errors.first('cstPIS')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "cstPIS",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Situação tributaria\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("combo-component", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "cstPIS",
                                                      name: "cstPIS",
                                                      data: _vm.cstPISCOFINSOptions,
                                                      searchable: !_vm.consulta,
                                                      disabled: _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem.cstPIS,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "cstPIS",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.cstPIS",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "fieldset-component",
                                  {
                                    attrs: {
                                      title: "COFINS",
                                      collapsed: false,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { slot: "rows" }, slot: "rows" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "baseCOFINS",
                                                      },
                                                    },
                                                    [_vm._v("Base")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("moeda-component", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "baseCOFINS",
                                                      name: "baseCOFINS",
                                                      disabled:
                                                        _vm.modelItem
                                                          .tipoCSTCOFINS == 2 ||
                                                        _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem
                                                          .baseCOFINS,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "baseCOFINS",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.baseCOFINS",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "aliquotaCOFINS",
                                                      },
                                                    },
                                                    [_vm._v("Alíquota")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "decimal-com-sinal-custom-component",
                                                    {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        signal: "%",
                                                        id: "aliquotaCOFINS",
                                                        name: "aliquotaCOFINS",
                                                        disabled:
                                                          _vm.modelItem
                                                            .tipoCSTCOFINS !=
                                                            1 || _vm.consulta,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .aliquotaCOFINS,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "aliquotaCOFINS",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.aliquotaCOFINS",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "valorCOFINS",
                                                      },
                                                    },
                                                    [_vm._v("Valor")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("moeda-component", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "valorCOFINS",
                                                      name: "valorCOFINS",
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem
                                                          .valorCOFINS,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "valorCOFINS",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.valorCOFINS",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "cstCOFINS"
                                                        ),
                                                      expression:
                                                        "errors.first('cstCOFINS')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "cstCOFINS",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Situação tributaria\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("combo-component", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "cstCOFINS",
                                                      name: "cstCOFINS",
                                                      data: _vm.cstPISCOFINSOptions,
                                                      searchable: !_vm.consulta,
                                                      disabled: _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem.cstCOFINS,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "cstCOFINS",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.cstCOFINS",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.modelItem.produtoDescricao != null &&
                      _vm.nota.notaImportacao
                        ? _c("v-tab", { attrs: { title: "DI" } }, [
                            _c(
                              "div",
                              { staticStyle: { margin: "15px" } },
                              [
                                _c(
                                  "fieldset-component",
                                  {
                                    attrs: {
                                      title: "Declaração de Importação (DI)",
                                      collapsed: false,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { attrs: { slot: "rows" }, slot: "rows" },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "numeroDI"
                                                        ),
                                                      expression:
                                                        "errors.first('numeroDI')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "numeroDI",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Número DI\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("data-tooltip-component", {
                                                    attrs: {
                                                      text: "Número do Documento de Importação DI/DSI/DA",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.modelItem
                                                            .numeroDI,
                                                        expression:
                                                          "modelItem.numeroDI",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      id: "numeroDI",
                                                      autocomplete: "off",
                                                      name: "numeroDI",
                                                      disabled: _vm.consulta,
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.modelItem.numeroDI,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "numeroDI",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "dataDI"
                                                        ),
                                                      expression:
                                                        "errors.first('dataDI')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "dataDI",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Data do registro DI\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "date-time-picker-component",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: "required",
                                                          expression:
                                                            "'required'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        id: "dataDI",
                                                        name: "dataDI",
                                                        disabled: _vm.consulta,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem.dataDI,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "dataDI",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.dataDI",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "codigoExportador"
                                                        ),
                                                      expression:
                                                        "errors.first('codigoExportador')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "codigoExportador",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Código do exportador\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("data-tooltip-component", {
                                                    attrs: {
                                                      text: "Código do exportador, usado nos sistemas internos de informação do emitente da NF-e",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.modelItem
                                                            .codigoExportador,
                                                        expression:
                                                          "modelItem.codigoExportador",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      id: "codigoExportador",
                                                      autocomplete: "off",
                                                      name: "codigoExportador",
                                                      disabled: _vm.consulta,
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.modelItem
                                                          .codigoExportador,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "codigoExportador",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "viaTransporte"
                                                        ),
                                                      expression:
                                                        "errors.first('viaTransporte')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "viaTransporte",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Via de Transporte\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("combo-component", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "viaTransporte",
                                                      name: "viaTransporte",
                                                      data: _vm.viaTransporteOptions,
                                                      searchable: false,
                                                      disabled: _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem
                                                          .viaTransporte,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "viaTransporte",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.viaTransporte",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "valorAFRMM"
                                                        ),
                                                      expression:
                                                        "errors.first('valorAFRMM')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "valorAFRMM",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Valor AFRMM\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("moeda-component", {
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      id: "valorAFRMM",
                                                      name: "valorAFRMM",
                                                      disabled:
                                                        _vm.modelItem
                                                          .viaTransporte != 1 ||
                                                        _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem
                                                          .valorAFRMM,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "valorAFRMM",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.valorAFRMM",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-3" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "tipoIntermedio"
                                                        ),
                                                      expression:
                                                        "errors.first('tipoIntermedio')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "tipoIntermedio",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Tipo de Intermédio\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("combo-component", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "tipoIntermedio",
                                                      name: "tipoIntermedio",
                                                      data: _vm.tipoIntermedioOptions,
                                                      searchable: false,
                                                      disabled: _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem
                                                          .tipoIntermedio,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "tipoIntermedio",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.tipoIntermedio",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-5" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "localDesembaraco"
                                                        ),
                                                      expression:
                                                        "errors.first('localDesembaraco')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "localDesembaraco",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Local de desembaraço\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.modelItem
                                                            .localDesembaraco,
                                                        expression:
                                                          "modelItem.localDesembaraco",
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      id: "localDesembaraco",
                                                      autocomplete: "off",
                                                      name: "localDesembaraco",
                                                      disabled: _vm.consulta,
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.modelItem
                                                          .localDesembaraco,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "localDesembaraco",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "ufDesembaraco"
                                                        ),
                                                      expression:
                                                        "errors.first('ufDesembaraco')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "ufDesembaraco",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    UF Desembaraço\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("data-tooltip-component", {
                                                    attrs: {
                                                      text: "Sigla da UF onde ocorreu o Desembaraço Aduaneiro",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("combo-component", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "ufDesembaraco",
                                                      name: "ufDesembaraco",
                                                      data: _vm.ufDesembaracoOptions,
                                                      searchable: !_vm.consulta,
                                                      disabled: _vm.consulta,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.modelItem
                                                          .ufDesembaracoId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.modelItem,
                                                          "ufDesembaracoId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "modelItem.ufDesembaracoId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value:
                                                        _vm.errors.first(
                                                          "dataDesembaraco"
                                                        ),
                                                      expression:
                                                        "errors.first('dataDesembaraco')",
                                                    },
                                                  ],
                                                  staticClass: "form-group",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "control-label",
                                                      attrs: {
                                                        for: "dataDesembaraco",
                                                        title:
                                                          _vm.$t(
                                                            "__.obrigatorio"
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                    Data Desembaraço\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("data-tooltip-component", {
                                                    attrs: {
                                                      text: "Data do Desembaraço Aduaneiro",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "date-time-picker-component",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: "required",
                                                          expression:
                                                            "'required'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        id: "dataDesembaraco",
                                                        name: "dataDesembaraco",
                                                        disabled: _vm.consulta,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.modelItem
                                                            .dataDesembaraco,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.modelItem,
                                                            "dataDesembaraco",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "modelItem.dataDesembaraco",
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("adicao-component", {
                                          attrs: {
                                            data: _vm.modelItem.adicoes,
                                            consulta: _vm.consulta,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("grid-component", {
              attrs: {
                data: _vm.nota.itens,
                columns: _vm.gridColumns,
                "show-action-column": true,
                "show-edit-item": !_vm.consulta,
                "show-remove-item": !_vm.consulta,
                "show-sort-icons": false,
                showAddNewButton: false,
                "show-table-head": false,
                "show-table-foot": false,
                refreshData: _vm.refreshGrid,
                subColumns: _vm.gridColumnsVinculos,
                subDataName: "impostos",
                showExpand: true,
                conditionShowSubData: _vm.conditionShowSubData,
                extraActions: _vm.extraActions,
              },
              on: {
                "edit-item": function (item) {
                  return _vm.onEditItem(item).withLoading()
                },
                "remove-item": _vm.onRemoveItem,
                "extra-action": _vm.onExtraAction,
              },
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }