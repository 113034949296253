import NotaFiscalServicoEletronicaItemModel from "./notaFiscalServicoEletronicaItemModel";

export default class NotaFiscalServicoEletronicaModel {
    id = 0;
    clienteId = 0;
    valorDeducoes = 0;
    valorPIS = 0;
    valorCOFINS = 0;
    valorISSRetido = 0;
    valorRetencoes = 0;
    observacao = "";
    pdfLink = "";
    pdfSimplificadoLink = "";
    xmlLink = "";
    itens = new Array<NotaFiscalServicoEletronicaItemModel>();
}
