import { mapGetters, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import SolicitarSenhaComponent from "@/components/parent/solicitarSenha/view";
import solicitarSenhaComponent from "@/components/parent/solicitarSenha/view.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import StatusFracionamento from "@/models/enum/statusFracionamento";
import PaginationModel from "@/models/paginationModel";
import VendaModel from "@/models/vendaModel";
import VendaService from "@/services/vendaService";
import { AppState, Getters } from "@/store/store";

import ListComponentBase from "../listComponentBase";

import "../crud.scss";

@Component({
    components: {
        comboComponent,
        impressaoComponent,
        solicitarSenhaComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO"] as Getters),
    },
})
export default class SeparacaoListComponent extends ListComponentBase<VendaModel> {
    // State computed props
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    preLoadList: PreLoadPackModel;

    public service = new VendaService();
    private impressaoComponent: ImpressaoComponent = null;
    private solicitarSenhaComponent: SolicitarSenhaComponent = null;

    private model: VendaModel = null;
    modelId = 0;
    gridSortKey = "codigo";
    gridSortOrder = "desc";
    gridData: Array<VendaModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", "C\u00f3digo", GridColumnType.String),
            new GridColumn("situacaoDescricao", "Situação", GridColumnType.String),
            new GridColumn("setorDescricao", "Setor", GridColumnType.String),
            new GridColumn("clienteNome", "Cliente", GridColumnType.String),
            new GridColumn("dataInclusao", "Data Inclusão", GridColumnType.Date),
            new GridColumn("dataAprovacao", "Data Aprovação", GridColumnType.Date),
        ];
    }

    isFracionamento = false;

    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];
    gridExtraActionsSeparar: Array<GridAction> = [];
    gridExtraActionsConcluir: Array<GridAction> = [];
    gridExtraActionsImprimir: Array<GridAction> = [];

    isHidden = false;
    setor = null;
    setorOptions = [];

    public override async loadMethod() {
        return await this.service
            .listFracionamento(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                { setor: this.setor },
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<VendaModel>>();
    }

    public async afterLoad() {
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;
        this.solicitarSenhaComponent = this.$refs.solicitarSenhaComponent as SolicitarSenhaComponent;

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();

        this.setorOptions = this.preLoadList.setoresCombo();
        this.gridExtraActionsVer.splice(0);
        this.gridExtraActionsSeparar.splice(0);
        this.gridExtraActionsImprimir.splice(0);
        this.gridExtraActionsConcluir.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.gridExtraActionsImprimir.push(
                new GridAction(
                    "imprimir-fracionamento",
                    "Imprimir Fracionamento",
                    "fa fa-print",
                    GridColors.DARKGRAY,
                    true,
                ),
            );
            this.gridExtraActionsSeparar.push(
                new GridAction("separar-itens", "Separar itens", "fa fa-inbox", GridColors.ORANGE, true),
            );
            this.gridExtraActionsVer.push(
                new GridAction("ver", "Visualizar Venda", "fa fa-eye", GridColors.BLUE, true),
            );
            this.gridExtraActionsConcluir.push(
                new GridAction("concluir", "Concluir Separação", "fa fa-check", GridColors.GREEN, true),
            );
        }

        this.extraActions.push(this.gridExtraActionsSeparar);
        this.extraActions.push(this.gridExtraActionsImprimir);
        this.extraActions.push(this.gridExtraActionsVer);
        this.extraActions.push(this.gridExtraActionsConcluir);
    }

    private async onResult(sucesso: boolean) {
        if (sucesso) {
            const response = await this.$showQuestion(
                this.$t("__.ts.atencao"),
                this.$t("__.ts.conclSeparacItensApagados"),
            );
            if (response) {
                await this.concluirSeparacao(this.modelId).withLoading();
            }
        } else {
            await this.$showError(this.$t("__.ts.erro"), "Senha invalida, separação não será salva!");
        }

        this.modelId = 0;
    }

    private async onExtraAction(name: string, model: VendaModel) {
        if (name.trim() == "ver") {
            this.$router.push("/venda-edicao/" + model.id);
        } else if (name.trim() == "separar-itens") {
            this.$router.push("/separacao-edicao/" + model.id);
        } else if (name.trim() == "imprimir-fracionamento") {
            this.impressaoComponent
                .show({
                    modelId: model.id,
                    tipoImpressao: "Venda",
                    impressaoTodosModelo: true,
                })
                .withLoading();
        } else if (name.trim() == "concluir") {
            this.modelId = model.id;
            this.solicitarSenhaComponent.show();
        }
    }

    private async concluirSeparacao(id: number) {
        try {
            const venda = await this.service.get(id).then(r => r.json() as Promise<VendaModel>);

            venda.itens
                .filter(p => p.fracionamentoId)
                .forEach(i => {
                    i.separado = true;
                    i.fracionamento.itens.forEach(f => {
                        f.statusFracionamento = StatusFracionamento.Separado;
                    });
                });

            venda.situacao = SituacoesVenda.Expedicao;

            const sucesso = await this.service.update(venda).resolveWithoutJSON();
            if (sucesso) {
                this.$showSuccess("Separação!", "Venda separada com sucesso.");
                this.load();
            }
        } catch {}
    }
}
