import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnSearchColor, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import { Component, Watch } from "@/decorators";
import PaginationPackModel from "@/models/auxiliar/paginationPackModel";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import TipoBuscaVenda from "@/models/enum/tipoBuscaVenda";
import ContaReceberModel from "@/models/financeiro/contaReceberModel";
import PaginationModel from "@/models/paginationModel";
import VendaItemModel from "@/models/vendaItemModel";
import VendaModel, { VendaListParameters } from "@/models/vendaModel";
import ContaReceberService from "@/services/financeiro/contaReceberService";
import ReceitaArquivoService from "@/services/receitaArquivoService";
import VendaService from "@/services/vendaService";
import { AppState, Getters, Mutations, SessionActions } from "@/store/store";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import RecebimentoComponent from "../recebimentos/list";
import recebimentoComponent from "../recebimentos/list.vue";
import ShortcutComponent from "../shortcut/shortcut";

import shortcutComponent from "./../shortcut/shortcut.vue";
import FinanceiroComponent from "./financeiro/financeiro";
import financeiroComponent from "./financeiro/financeiro.vue";

import "../crud/crud.scss";
import "./list.scss";

@Component({
    components: {
        gridComponent,
        dateTimePickerComponent,
        shortcutComponent,
        impressaoComponent,
        recebimentoComponent,
        comboComponent,
        financeiroComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
            loadingPack: (state: AppState) => state.paginationStatus.loading,
            paginationParams: (state: AppState) => state.paginationStatus.paginationParams,
        }),
        ...mapGetters([
            "GET_IS_FRACIONAMENTO",
            "GET_IS_ESTEREIS",
            "VALIDAR_PERMISSAO_USUARIO",
            "VALIDAR_PERMISSAO_SOMENTE_CONSULTA",
            "CONFIG_FRANQUIA_LOADED",
            "GET_CONFIG_FRANQUIA",
        ] as Getters),
    },
    methods: mapMutations(["SET_PARAMS", "GET_PARAMS"] as Mutations),
})
export default class VendaListComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;
    loadingPack: boolean;
    paginationParams: PaginationPackModel;
    SET_PARAMS: (obj: object) => void;
    GET_PARAMS: (path: string) => Promise<void>;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;
    CONFIG_FRANQUIA_LOADED: () => boolean;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;

    private service = new VendaService();
    private receitaArquivoService = new ReceitaArquivoService();
    private contaReceberService = new ContaReceberService();
    private gridcomponent: Grid = null;

    private situacao = -1;
    private title = "__.ts.vendas";

    somenteConsultaVenda = false;
    private loadPagamentoComponent = false;
    private permiteParcelamentoVenda = false;
    private contaReceber = new ContaReceberModel();

    private radioFiltro = ["Todos", "A Faturar", "Concluídos"];

    private impressaoComponent: ImpressaoComponent = null;
    private financeiroComponent: FinanceiroComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private recebimentoComponent: RecebimentoComponent = null;

    isFracionamento = false;
    isEstereis = false;

    itensSelecionados: VendaItemModel[] = [];

    get gridColumnsImpressoes(): Array<GridColumn> {
        return [
            new GridColumn("descricao", this.$t("__.ts.descr") as string, GridColumnType.String),
            new GridColumn("modelo", this.$t("__.ts.model") as string, GridColumnType.String),
        ];
    }

    startFilterKey = "";
    gridFilterKey = "";
    gridSortKey = "codigo";
    gridSortOrder = "desc";
    gridData: Array<VendaModel> = [];
    tipoBuscaVenda = 0;
    filterKey = "";

    tipoBuscaVendaOptions: Array<Object> = [
        { text: "Codigo da Venda", value: TipoBuscaVenda.Venda },
        { text: "Cliente", value: TipoBuscaVenda.Cliente },
        { text: "Manipulação", value: TipoBuscaVenda.Manipulacao },
        { text: "Paciente", value: TipoBuscaVenda.Paciente },
    ];

    get gridColumns(): Array<GridColumnSearchColor> {
        const columns = [];
        columns.push(new GridColumnSearchColor("codigo", "Código", GridColumnType.String));
        columns.push(new GridColumnSearchColor("situacaoDescricao", "Situação", GridColumnType.String, false));
        columns.push(new GridColumnSearchColor("clienteNome", "Cliente", GridColumnType.String));
        if (!this.isFracionamento) {
            columns.push(new GridColumnSearchColor("vendedor", "Vendedor", GridColumnType.String));
        }
        columns.push(new GridColumnSearchColor("dataAprovacao", "Data", GridColumnType.Date));
        if (this.isFracionamento || this.isEstereis) {
            columns.push(new GridColumnSearchColor("setorDescricao", "Setor", GridColumnType.String));
            columns.push(new GridColumnSearchColor("numeroNota", "Nota", GridColumnType.Integer, false));
        }
        columns.push(new GridColumnSearchColor("valorFinal", "Valor", GridColumnType.Money));
        return columns;
    }
    subDataName = "itens";

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("codigo", "Código", GridColumnType.Integer),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("continuo", "Continuo", GridColumnType.Boolean),
            new GridColumn("quantidade", "Quantidade", GridColumnType.DecimalPonto),
            new GridColumn("valorUnitario", "Valor Unitário", GridColumnType.Money),
            new GridColumn("valorTotal", "Valor Total", GridColumnType.Money),
            new GridColumn("dataValidade", "Data Validade", GridColumnType.Date, false, this.isFracionamento),
        ];
    }

    conditionShowSubData(it) {
        return it["itens"]["length"] > 0;
    }

    pageIndex = 1;
    pageSize = 20;
    total = 0;
    lastFilter = 0;
    filtro: Array<Object> = [];
    checar = 0;
    isHidden = false;
    radioFilter: number = null;
    dataFilter1: Date = null;
    dataFilter2: Date = null;
    setorId: number = null;
    private model: VendaModel = null;
    vendedor = null;
    produto = null;

    extraActions: Array<object> = [];
    extraSubActions: Array<object> = [];
    extraSubActionPcp: Array<object> = [];
    gridExtraActionsImprimir: Array<GridAction> = [];
    gridExtraActionsRepetir: Array<GridAction> = [];
    gridExtraActionsExibirReceitas: Array<GridAction> = [];
    gridExtraActionsExibirDadosFinanceiros: Array<GridAction> = [];
    setorOptions: Array<Object> = [];
    usuariosOptions: Array<Object> = [];
    produtosOptions: Array<Object> = [];

    pags = false;
    tipoDocGerar: number = null;

    private async load() {
        try {
            const customParameters: VendaListParameters = {
                dataInicial: convertToDateInputString(this.filtro[1]),
                dataFinal: convertToDateInputString(this.filtro[2], true),
                radio: this.filtro[0] ? this.filtro[0].toString() : "",
                tipoBuscaVenda: this.tipoBuscaVenda,
                vendedor: this.vendedor,
                setorId: this.setorId,
                produto: this.produto,
            };

            this.pageIndex = this.gridFilterKey !== this.filterKey ? 1 : this.pageIndex;
            this.filterKey = this.gridFilterKey;

            const data = await this.service
                .listVenda(
                    this.gridFilterKey,
                    this.gridSortKey,
                    this.gridSortOrder,
                    this.pags ? 1 : this.pageIndex,
                    this.pags ? 999999 : this.pageSize,
                    customParameters,
                )
                .withLoading()
                .resolveWithJSON<PaginationModel<VendaModel>>();

            //atualiza params no store
            if (data.total > 0) {
                this.SET_PARAMS({
                    routePath: this.$route.path,
                    filterKey: this.gridFilterKey,
                    sortKey: this.gridSortKey,
                    sortOrder: this.gridSortOrder,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    filtros: this.filtro,
                });
            }

            this.gridData = data.list;
            this.gridData["dataDe"] = this.filtro[1] ? this.filtro[1].toString() : " ";
            this.gridData["dataAte"] = this.filtro[2] ? this.filtro[2].toString() : " ";
            this.gridData["status"] = this.filtro[0] ? this.filtro[0].toString() : " ";
            this.total = data.total;
            this.pageIndex = data.pageIndex;
            this.pageSize = data.pageSize;
            this.isHidden = false;
            this.gridExtraActionsImprimir.splice(0);
            this.gridExtraActionsRepetir.splice(0);
            this.gridExtraActionsExibirReceitas.splice(0);
            this.gridExtraActionsExibirDadosFinanceiros.splice(0);
            this.extraSubActionPcp.splice(0);
            this.extraActions.splice(0);
            this.extraSubActions.splice(0);

            for (let i = 0; i < this.gridData.length; i++) {
                if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "ImprimirVenda")) {
                    this.gridExtraActionsImprimir.push(
                        new GridAction("imprimir-venda", "Imprimir Venda", "fa fa-print", GridColors.DARKGRAY),
                    );
                }

                if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "CopiarVenda")) {
                    this.gridExtraActionsRepetir.push(
                        new GridAction(
                            "repetir-venda",
                            "Repetir esta Venda",
                            "fa fa-copy",
                            GridColors.DARKBLUE,
                            this.gridData[i].situacao != SituacoesVenda.Cancelado,
                        ),
                    );
                }
                if (!this.isFracionamento) {
                    this.gridExtraActionsExibirReceitas.push(
                        new GridAction(
                            "exibir-receitas",
                            this.$t(
                                "__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.receita",
                            ) as string,
                            "fa fa-paperclip",
                            GridColors.RED,
                            this.gridData[i].receitas.length > 0,
                        ),
                    );

                    if (this.GET_CONFIG_FRANQUIA(Configuracoes.UsaPCP).verdadeiro) {
                        this.gridData[i].itens.forEach(p =>
                            this.extraSubActionPcp.push(
                                new GridAction(
                                    "Pcp",
                                    "PCP",
                                    "fa fa-clipboard-check",
                                    GridColors.ORANGE,
                                    p.manipulacaoOrdemId > 0,
                                ),
                            ),
                        );
                    }
                }
                this.gridExtraActionsExibirDadosFinanceiros.push(
                    new GridAction(
                        "exibir-dados-financeiros",
                        "Dados financeiros",
                        "fa fa-dollar-sign",
                        GridColors.LIGHTGREEN,
                        this.gridData[i].contaReceberId > 0,
                    ),
                );
            }

            this.extraActions.push(this.gridExtraActionsExibirDadosFinanceiros);
            this.extraActions.push(this.gridExtraActionsImprimir);
            this.extraActions.push(this.gridExtraActionsRepetir);
            this.extraActions.push(this.gridExtraActionsExibirReceitas);
            this.extraSubActions.push(this.extraSubActionPcp);

            if (this.pags) {
                if (this.tipoDocGerar == 0) {
                    setTimeout(() => {
                        this.gridcomponent.gerarXls();
                    }, 2000);
                }

                if (this.tipoDocGerar == 1) {
                    setTimeout(() => {
                        this.gridcomponent.gerarPdf();
                    }, 2000);
                }

                if (this.tipoDocGerar == 2) {
                    setTimeout(() => {
                        this.gridcomponent.gerarImpressao();
                    }, 2000);
                }

                setTimeout(() => {
                    this.pags = false;
                }, 2000);
                this.loadPagination();
            }
        } catch {}
    }

    private onSelectedLine(itensSelecionados: VendaItemModel[]) {
        this.itensSelecionados = itensSelecionados;
    }

    async showTelaDadosFinanceiros(model: VendaModel) {
        this.loadPagamentoComponent = true;

        this.contaReceber = await this.contaReceberService
            .get(model.contaReceberId)
            .withLoading()
            .resolveWithJSON<ContaReceberModel>();

        while (!this.$refs.shortcutComponent) await Delay(5);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        if (this.permiteParcelamentoVenda) {
            while (!this.$refs.financeiroComponent) await Delay(5);
            this.financeiroComponent = this.$refs.financeiroComponent as FinanceiroComponent;

            this.financeiroComponent.setModel(this.contaReceber);
        } else {
            while (!this.$refs.recebimentoComponent) await Delay(5);
            this.recebimentoComponent = this.$refs.recebimentoComponent as RecebimentoComponent;

            this.recebimentoComponent.show(this.contaReceber.movimentacoes);
        }

        this.shortcutComponent.title = "Financeiro";
        this.shortcutComponent.show();
    }

    private onExtraAction(name: string, model: VendaModel) {
        if (name.trim() == "imprimir-venda") {
            this.impressaoComponent
                .show({
                    modelId: model.id,
                    tipoImpressao: "Venda",
                    impressaoTodosModelo: true,
                })
                .withLoading();
        } else if (name.trim() == "repetir-venda") {
            this.replicar(model.id);
        } else if (name.trim() == "exibir-receitas") {
            this.onExibirReceita(model);
        } else if (name.trim() == "exibir-dados-financeiros") {
            this.showTelaDadosFinanceiros(model);
        }
    }

    private onExtraSubAction(name: string, model: VendaItemModel) {
        if (name.trim() == "Pcp") {
            window.open(`/pcp-edicao/${model.codigo}`, "_blank");
        }
    }

    public async onExibirReceita(venda: VendaModel) {
        await this.receitaArquivoService.showFiles(venda.receitas);
    }

    private async replicar(vendaId: number) {
        try {
            const data = await this.service.replicarOrcamento(vendaId).withLoading().resolveWithJSON<VendaModel>();
            if (data.id) {
                this.$router.push("/venda-edicao/" + data.id + "/true");
            }
        } catch {}
    }

    private async replicarVendasSelecionadas() {
        const itemsIds = this.itensSelecionados.map(p => p.id);

        if (itemsIds.length < 1) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.selecioneUmaVenda"));
            return;
        }

        try {
            const data = await this.service
                .replicarMultiplosVendaItem(itemsIds)
                .withLoading()
                .resolveWithJSON<VendaModel>();
            if (data.id) {
                this.$router.push("/venda-edicao/" + data.id + "/true");
            }
        } catch {}
    }

    private onEditItem(model: VendaModel) {
        this.$router.push("/venda-edicao/" + model.id);
    }

    private onRemoveItem(model: VendaModel) {
        this.$router.push("/venda-cancelamento/" + model.id);
    }

    private onCreateItem() {
        this.$router.push("/venda-edicao");
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.load();
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.load();
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.load();
    }

    private getFormValues(listaFiltro) {
        this.filtro = [];

        this.filtro.push(listaFiltro.target.elements.optradio.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter1.value);

        this.filtro.push(listaFiltro.target.elements.dataFilter2.value);

        this.load();
    }

    @Watch("pags")
    private onPags() {
        this.load();
    }

    @Watch("tipoBuscaVenda")
    private onTipoBuscaVenda() {
        this.load();
    }

    private async loadPagination() {
        await this.GET_PARAMS(this.$route.path);
        while (this.loadingPack) await Delay(50);

        if (this.paginationParams != null) {
            this.gridFilterKey = this.paginationParams.filterKey;
            this.startFilterKey = this.gridFilterKey;
            this.gridSortKey = this.paginationParams.sortKey;
            this.gridSortOrder = this.paginationParams.sortOrder;
            this.pageIndex = this.paginationParams.pageIndex;
            this.pageSize = this.paginationParams.pageSize;

            if (this.paginationParams.filtros != null) {
                this.filtro = this.paginationParams.filtros;

                if (this.filtro.length >= 0) {
                    this.radioFilter = <number>this.filtro[0];

                    if (this.filtro.length >= 1) {
                        if (this.filtro[1]) {
                            this.dataFilter1 = convertToDateInput(this.filtro[1].toString());
                        }
                    }
                    if (this.filtro.length >= 2) {
                        if (this.filtro[2]) {
                            this.dataFilter2 = convertToDateInput(this.filtro[2].toString());
                        }
                    }
                }
            }
        }
    }

    private onChangePageSize(newPageSize) {
        this.pageIndex = 1;
        this.pageSize = newPageSize;
        this.load();
    }

    private async mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.usuariosOptions = this.preLoadList.vendedoresCombo();
        this.produtosOptions = this.preLoadList.produtosCombo();
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();

        this.setorOptions = this.preLoadList.setoresCombo();
        this.title = this.situacao == 0 ? "__.ts.orcamentos" : "__.ts.vendas";

        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        this.somenteConsultaVenda = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("vendas");

        while (!this.CONFIG_FRANQUIA_LOADED()) await Delay(100);

        const permiteParcelamentoVenda = this.GET_CONFIG_FRANQUIA(Configuracoes.PermiteParcalamentoVenda);
        if (permiteParcelamentoVenda) {
            this.permiteParcelamentoVenda = permiteParcelamentoVenda.verdadeiro;
        }

        Promise.all([this.loadPagination()])
            .withLoading()
            .then(() => {
                this.load();
            })
            .catch(() => {});
    }
}
