var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.CalledByShortCut
        ? _c("actionBarComponent", {
            attrs: {
              title: _vm.$t(
                "__.Crud.naturezaoperacao.edit_vue_html.naturezaOpEdit"
              ),
            },
            on: { onSave: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("descricao"),
                        expression: "errors.first('descricao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "descricao",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.descricao"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        { name: "focus", rawName: "v-focus" },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.descricao,
                          expression: "model.descricao",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:256",
                          expression: "'required|max:256'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "descricao",
                        name: "descricao",
                      },
                      domProps: { value: _vm.model.descricao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "descricao", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("codigo"),
                        expression: "errors.first('codigo')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "codigo",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.codigo"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.codigo,
                          expression: "model.codigo",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|maior_que_zero",
                          expression: "'required|maior_que_zero'",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "codigo",
                        name: "codigo",
                      },
                      domProps: { value: _vm.model.codigo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "codigo", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("tipoMovimentacao"),
                        expression: "errors.first('tipoMovimentacao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "tipoMovimentacao",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.tipoMovimento"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "tipoMovimentacao",
                        name: "tipoMovimentacao",
                        data: _vm.tiposMovimentacaoOptions,
                        searchable: false,
                      },
                      model: {
                        value: _vm.model.tipoMovimentacao,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "tipoMovimentacao", $$v)
                        },
                        expression: "model.tipoMovimentacao",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("dentroEstado"),
                        expression: "errors.first('dentroEstado')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "control-label", attrs: { for: "ativo" } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.dentroEstado"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "dentroEstado", name: "dentroEstado" },
                      model: {
                        value: _vm.model.dentroEstado,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "dentroEstado", $$v)
                        },
                        expression: "model.dentroEstado",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("movimentaEstoque"),
                        expression: "errors.first('movimentaEstoque')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "control-label", attrs: { for: "ativo" } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.movimentaEstq"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("data-tooltip-component", {
                      attrs: {
                        text: _vm.$t(
                          "__.Crud.naturezaoperacao.edit_vue_html.movimentaEstqToolTip"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: {
                        id: "movimentaEstoque",
                        name: "movimentaEstoque",
                      },
                      model: {
                        value: _vm.model.movimentaEstoque,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "movimentaEstoque", $$v)
                        },
                        expression: "model.movimentaEstoque",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("usaConfiguracaoFiscal"),
                        expression: "errors.first('usaConfiguracaoFiscal')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "control-label", attrs: { for: "ativo" } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.usaConfiguracaoFiscal"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: {
                        id: "usaConfiguracaoFiscal",
                        name: "usaConfiguracaoFiscal",
                      },
                      model: {
                        value: _vm.model.usaConfiguracaoFiscal,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "usaConfiguracaoFiscal", $$v)
                        },
                        expression: "model.usaConfiguracaoFiscal",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "label",
                      { staticClass: "control-label", attrs: { for: "ativo" } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.transferencia"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "transferencia", name: "transferencia" },
                      model: {
                        value: _vm.model.transferencia,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "transferencia", $$v)
                        },
                        expression: "model.transferencia",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("simplesRemessa"),
                        expression: "errors.first('simplesRemessa')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "control-label", attrs: { for: "ativo" } },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.simplesRemessa"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("checkbox-component", {
                      attrs: { id: "simplesRemessa", name: "simplesRemessa" },
                      model: {
                        value: _vm.model.simplesRemessa,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "simplesRemessa", $$v)
                        },
                        expression: "model.simplesRemessa",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cstICMS" },
                          },
                          [_vm._v("Situação tributaria ICMS")]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "cstICMS",
                            name: "cstICMS",
                            data: _vm.cstICMSOptions,
                          },
                          model: {
                            value: _vm.model.cstICMS,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cstICMS", $$v)
                            },
                            expression: "model.cstICMS",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "aliquotaICMS" },
                          },
                          [_vm._v("Alíquota ICMS")]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          staticClass: "form-control",
                          attrs: {
                            signal: "%",
                            id: "aliquotaICMS",
                            name: "aliquotaICMS",
                          },
                          model: {
                            value: _vm.model.aliquotaICMS,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "aliquotaICMS", $$v)
                            },
                            expression: "model.aliquotaICMS",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "codigoBeneficio" },
                        },
                        [_vm._v("Código Benefício Fiscal")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.codigoBeneficio,
                            expression: "model.codigoBeneficio",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "codigoBeneficio",
                          name: "codigoBeneficio",
                        },
                        domProps: { value: _vm.model.codigoBeneficio },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "codigoBeneficio",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cstPIS" },
                          },
                          [_vm._v("Situação tributaria PIS")]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "cstPIS",
                            name: "cstPIS",
                            data: _vm.cstPISCOFINSOptions,
                          },
                          model: {
                            value: _vm.model.cstPIS,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cstPIS", $$v)
                            },
                            expression: "model.cstPIS",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "aliquotaPIS" },
                          },
                          [_vm._v("Alíquota PIS")]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          staticClass: "form-control",
                          attrs: {
                            signal: "%",
                            id: "aliquotaPIS",
                            name: "aliquotaPIS",
                          },
                          model: {
                            value: _vm.model.aliquotaPIS,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "aliquotaPIS", $$v)
                            },
                            expression: "model.aliquotaPIS",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cstCOFINS" },
                          },
                          [_vm._v("Situação tributaria COFINS")]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "cstCOFINS",
                            name: "cstCOFINS",
                            data: _vm.cstPISCOFINSOptions,
                          },
                          model: {
                            value: _vm.model.cstCOFINS,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cstCOFINS", $$v)
                            },
                            expression: "model.cstCOFINS",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "aliquotaCOFINS" },
                          },
                          [_vm._v("Alíquota COFINS")]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "aliquotaCOFINS",
                            name: "aliquotaCOFINS",
                            signal: "%",
                          },
                          model: {
                            value: _vm.model.aliquotaCOFINS,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "aliquotaCOFINS", $$v)
                            },
                            expression: "model.aliquotaCOFINS",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "cstIPI" },
                          },
                          [_vm._v("Situação tributaria IPI")]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          attrs: {
                            id: "cstIPI",
                            name: "cstIPI",
                            data: _vm.cstIPIOptions,
                          },
                          model: {
                            value: _vm.model.cstIPI,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "cstIPI", $$v)
                            },
                            expression: "model.cstIPI",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.model.usaConfiguracaoFiscal
                ? _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "aliquotaIPI" },
                          },
                          [_vm._v("Alíquota IPI")]
                        ),
                        _vm._v(" "),
                        _c("decimal-com-sinal-custom-component", {
                          staticClass: "form-control",
                          attrs: {
                            signal: "%",
                            id: "aliquotaIPI",
                            name: "aliquotaIPI",
                          },
                          model: {
                            value: _vm.model.aliquotaIPI,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "aliquotaIPI", $$v)
                            },
                            expression: "model.aliquotaIPI",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("observacao"),
                        expression: "errors.first('observacao')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "observacao" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.naturezaoperacao.edit_vue_html.observacoes"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.observacao,
                          expression: "model.observacao",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        id: "observacao",
                        name: "observacao",
                      },
                      domProps: { value: _vm.model.observacao },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.model, "observacao", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          !_vm.CalledByShortCut
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }