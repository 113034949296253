import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonScComponent from "@/components/child/form/buttonSc.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import OrigemProdutoLote from "@/models/enum/origemProdutoLote";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import StatusFracionamento from "@/models/enum/statusFracionamento";
import FracionamentoItemModel from "@/models/fracionamentoItemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import UsuarioModel from "@/models/usuarioModel";
import VendaItemModel from "@/models/vendaItemModel";
import VendaModel from "@/models/vendaModel";
import ProdutoLoteService from "@/services/produtoLoteService";
import VendaService from "@/services/vendaService";
import { AppState, Getters } from "@/store/store";

import "../crud.scss";
import "./edit.scss";

@Component({
    components: {
        gridComponent,
        actionBarComponent,
        buttonScComponent,
        shortcutComponent,
    },
    computed: {
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO"] as Getters),
    },
})
export default class SeparacaoEditComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    preLoadList: PreLoadPackModel;

    private service = new VendaService();
    private produtoLoteService = new ProdutoLoteService();
    private shortcutComponentCodigo: ShortcutComponent = null;

    model: VendaModel = new VendaModel();
    modelGrid: Array<FracionamentoItemModel> = [];
    modelId = 0;
    isFracionamento = false;
    codigo = 0;

    get gridColumns(): Array<GridColumn> {
        if (this.preLoadList.setores.find(p => p.id == this.model.setorId)?.descricao === "FRACIONAMENTO") {
            return [
                new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
                new GridColumn("produtoLoteDescricao", "Lote", GridColumnType.String),
                new GridColumn("quantidade", "Quantidade", GridColumnType.Decimal),
                new GridColumn("quantidadeSeparada", "quantidade Separada", GridColumnType.Decimal),
                new GridColumn("unidadeMedidaDescricao", "Unidade Medida", GridColumnType.String),
            ];
        }
        return [
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumn("produtoLoteDescricao", "Lote", GridColumnType.String),
            new GridColumn("quantidade", "Quantidade", GridColumnType.Decimal),
            new GridColumn("unidadeMedidaDescricao", "Unidade Medida", GridColumnType.String),
        ];
    }

    get gridSubColumn(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", "Itens Embalagem", GridColumnType.String),
            new GridColumn("produtoLoteDescricao", "Lote", GridColumnType.String),
            new GridColumn("quantidade", "quantidade", GridColumnType.Decimal),
            new GridColumn("unidadeMedidaDescricao", "Unidade Medida", GridColumnType.String),
        ];
    }

    subDataName = "itensEmbalagemFracionamento";

    extraActions: Array<object> = [];
    gridExtraActionsSeparar: Array<GridAction> = [];

    todosSeparados = false;
    salvarEConcluir = false;

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    conditionShowSubData(it) {
        return it["itensEmbalagemFracionamento"]["length"] > 0;
    }

    public loadVendaById(id: number) {
        this.modelId = id;
        this.load();
    }

    private async load() {
        this.model = new VendaModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<VendaModel>();
                this.model.updateFrom(data);

                if (data.usuarioSeparacaoId && data.usuarioSeparacaoId != this.usuarioLogado.id) {
                    await this.$showWarning(
                        "Separação já iniciada",
                        "Separação já foi iniciada por outro usuário, não será possível continuar",
                    );
                    this.$router.push("/");
                }

                this.modelGrid = [];

                if (this.model?.itens) {
                    this.model.itens
                        .filter(p => p.fracionamento)
                        .forEach(item => {
                            item.fracionamento.itens.forEach(itemFracionamento => {
                                itemFracionamento.produtoDescricao = itemFracionamento?.produto?.descricao;
                                itemFracionamento.produtoLoteDescricao = itemFracionamento?.produtoLote?.descricao;
                                itemFracionamento.unidadeMedidaDescricao =
                                    itemFracionamento?.produtoLote?.origemProdutoLote == OrigemProdutoLote.Fracionamento
                                        ? "Unidade"
                                        : itemFracionamento?.unidadeMedida?.descricao;
                                itemFracionamento.quantidadeSeparada =
                                    itemFracionamento.quantidadeSeparada > 0 ? itemFracionamento.quantidadeSeparada : 0;

                                if (item.fracionamento != null) {
                                    itemFracionamento.itensEmbalagemFracionamento = item.fracionamento.itensEmbalagem;

                                    itemFracionamento.itensEmbalagemFracionamento.forEach(p => {
                                        p.produtoDescricao = p?.produto?.descricao;
                                        p.unidadeMedidaDescricao = "Unidade";
                                        p.produtoLoteDescricao = p?.produtoLote?.descricao;
                                    });
                                }
                                this.modelGrid.push(itemFracionamento);
                            });
                        });

                    this.model.itens
                        .filter(p => p.produtoId && !p.fracionamento)
                        .forEach(item => {
                            const itemAcabado = new FracionamentoItemModel();
                            itemAcabado.produtoDescricao = item?.produtoDescricao;
                            itemAcabado.produtoLoteDescricao = item?.produtoLoteDescricao;
                            itemAcabado.quantidade = item?.quantidade;
                            itemAcabado.unidadeMedidaDescricao = item?.unidadeMedidaDescricao;
                            itemAcabado.itensEmbalagemFracionamento = [];
                            itemAcabado.statusFracionamento = item.statusFracionamento;
                            itemAcabado.id = item.id;
                            this.modelGrid.push(itemAcabado);
                        });
                }

                this.todosSeparados =
                    this.modelGrid.filter(x => x.statusFracionamento == StatusFracionamento.Separado).length ==
                    this.modelGrid.length;

                this.editGridExtraActions();
            } catch {
                this.$router.push("/");
            }
        }
    }

    private onExtraAction(name: string, model: FracionamentoItemModel) {
        if (name.trim() == "separar") {
            this.onSeparar(model.id);
        }
        if (name.trim() == "separado") {
            this.onSeparar(model.id);
        }
    }

    private async onSeparar(id: number) {
        try {
            const itemAcabado = this.model.itens.find(p => p.produtoLoteId == id && !p.fracionamentoId);

            if (itemAcabado) {
                this.onSepararAcabado(itemAcabado);
            } else {
                const vendaItemFracionamento = this.model.itens.find(p =>
                    p.fracionamento.itens.find(i => i.produtoLoteId == id),
                );

                if (vendaItemFracionamento) {
                    const fracionamento = vendaItemFracionamento.fracionamento.itens[0];
                    if (this.preLoadList.setores.find(p => p.id == this.model.setorId)?.descricao === "FRACIONAMENTO") {
                        if (fracionamento.quantidadeSeparada + 1 > fracionamento.quantidade) {
                            return this.$showError(
                                "Separação",
                                `Produto ${fracionamento.produtoDescricao} já foi separdo!`,
                            );
                        }

                        fracionamento.quantidadeSeparada += 1;

                        if (fracionamento.quantidadeSeparada == fracionamento.quantidade) {
                            this.onSepararFracionamento(vendaItemFracionamento.fracionamento.itens[0]);
                        }
                    } else {
                        this.onSepararFracionamento(vendaItemFracionamento.fracionamento.itens[0]);
                    }
                } else {
                    await this.showMessageNaoEncontrado();
                }
            }

            this.editGridExtraActions();

            if (this.modelGrid.filter(x => x.statusFracionamento != StatusFracionamento.Separado).length == 0) {
                this.hideAddCodigo();
                this.salvarEConcluir = true;
            } else {
                this.salvarEConcluir = false;
            }

            this.codigo = null;
        } catch {
            this.showMessageError();
        }
    }

    private onSepararFracionamento(item: FracionamentoItemModel) {
        let status = item.statusFracionamento;
        const itemGrid = this.modelGrid.find(p => p.id == item.id);
        const statusGrid = itemGrid.statusFracionamento;

        if (status == null || statusGrid == null) {
            item.statusFracionamento = status = StatusFracionamento.Separacao;
            itemGrid.statusFracionamento = status = StatusFracionamento.Separacao;
        }

        if (status == StatusFracionamento.Separacao) {
            item.statusFracionamento = StatusFracionamento.Separado;
            itemGrid.statusFracionamento = StatusFracionamento.Separado;
        } else {
            item.statusFracionamento = StatusFracionamento.Separacao;
            itemGrid.statusFracionamento = StatusFracionamento.Separacao;
        }
    }

    private onSepararAcabado(item: VendaItemModel) {
        let status = item.statusFracionamento;

        const itemGrid = this.modelGrid.find(p => p.id == item.id);
        const statusGrid = itemGrid.statusFracionamento;
        if (status == null || statusGrid == null) {
            item.statusFracionamento = status = StatusFracionamento.Separacao;
            itemGrid.statusFracionamento = status = StatusFracionamento.Separacao;
        }

        if (status == StatusFracionamento.Separacao) {
            item.statusFracionamento = StatusFracionamento.Separado;
            itemGrid.statusFracionamento = StatusFracionamento.Separado;
        } else {
            item.statusFracionamento = StatusFracionamento.Separacao;
            itemGrid.statusFracionamento = StatusFracionamento.Separacao;
        }
    }

    private editGridExtraActions() {
        this.gridExtraActionsSeparar.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.modelGrid.length; i++) {
            if (this.modelGrid[i].statusFracionamento == StatusFracionamento.Separado) {
                this.gridExtraActionsSeparar.push(
                    new GridAction(
                        "separado",
                        "Separado",
                        "fa fa-check-circle",
                        GridColors.GREEN,
                        !this.todosSeparados,
                    ),
                );
            } else {
                this.gridExtraActionsSeparar.push(
                    new GridAction(
                        "separar",
                        "Separar",
                        "fa fa-exclamation-circle",
                        GridColors.ORANGE,
                        !this.todosSeparados,
                    ),
                );
            }
        }
        this.extraActions.push(this.gridExtraActionsSeparar);
    }

    //@ts-ignore
    @Watch("salvarEConcluir")
    private onChangesalvarEConcluir() {
        //depois colocar as difs aqui
        this.$emit("btnName", this.salvarEConcluir);
    }

    public async save() {
        if (this.modelGrid.some(x => x.statusFracionamento != StatusFracionamento.Separado)) {
            await this.$showError(this.$t("__.ts.atencao"), this.$t("__.ts.necSepararTodosItens"));
            return false;
        }
        if (this.modelGrid.filter(x => x.statusFracionamento != StatusFracionamento.Separado).length == 0) {
            this.model.situacao = SituacoesVenda.Expedicao;
        } else {
            this.model.situacao = SituacoesVenda.Fracionando;

            for (const item of this.model.itens.filter(p => p.fracionamento)) {
                for (const itemI of item.fracionamento.itens) {
                    const itemGrid = this.modelGrid.find(p => p.id == itemI.id);
                    if (itemGrid) {
                        itemI.statusFracionamento = itemGrid.statusFracionamento;
                    }
                }
            }

            for (const item of this.model.itens.filter(p => p.produtoId && !p.fracionamento)) {
                const itemGrid = this.modelGrid.find(p => p.id == item.id);
                item.statusFracionamento = itemGrid.statusFracionamento;
            }
        }

        if (await this.validarEmbalagensSemEstoque()) {
            try {
                const sucesso = await this.service.update(this.model).resolveWithoutJSON();

                if (sucesso) {
                    await this.$showInclusaoUpdate(this.model.id);
                }
            } catch {}

            if (!this.CalledByShortCut) {
                this.$router.back();
            }
        }
        return true;
    }

    private async validarEmbalagensSemEstoque() {
        const embalagensSemEstoque = [];

        for (const itemVenda of this.model.itens.filter(p => p.fracionamento)) {
            const itensSemEstoque = itemVenda.fracionamento.itensEmbalagem.filter(
                i => i.produtoLote.quantidadeAtual == 0,
            );
            for (const item of itensSemEstoque) {
                const lotes = await this.produtoLoteService
                    .getByProdutoId(item.produtoId)
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

                const lotesValidos = lotes.list.filter(l => l.quantidadeAtual > 0);
                if (lotesValidos.length) {
                    item.produtoLote = lotesValidos.reduce((menor, prox) =>
                        menor.dataValidade > prox.dataValidade ? prox : menor,
                    );
                    item.produtoLoteId = item.produtoLote.id;
                } else embalagensSemEstoque.push(item.produto.descricao);
            }
        }

        if (embalagensSemEstoque.length) {
            await this.$showError(
                "Atenção",
                `${
                    embalagensSemEstoque.length > 1 ? "Os itens" : "O item"
                } ${embalagensSemEstoque.toString()} não contém estoque.
                Para prosseguir com o processo é necessário revisar ${
                    embalagensSemEstoque.length > 1 ? "esses itens" : "esse item"
                }.`,
            );
        }

        return embalagensSemEstoque.length == 0;
    }

    private cancel() {
        this.$router.back();
    }

    private async onSearchCodigo() {
        if (this.codigo) {
            this.onSeparar(this.codigo);
        } else {
            await this.$showWarning(`Atenção!`, `Informe código do lote.`);
        }
    }

    private showAddCodigo() {
        this.shortcutComponentCodigo.show();
        this.shortcutComponentCodigo.title = this.$t("__.Crud.separacao.edit_vue_html.title") as string;
        this.codigo = null;
    }

    private async showMessageNaoEncontrado() {
        await this.$showAlert({
            titleText: `Atenção!`,
            text: `Código informado não encontrado na listagem!`,
            icon: "warning",
            allowEnterKey: false,
        });
        this.codigo = null;
    }

    private async showMessageError() {
        await this.$showAlert({
            titleText: `Atenção!`,
            text: `Erro ao buscar item na lista!`,
            icon: "error",
            allowEnterKey: false,
        });
        this.codigo = null;
    }
    private hideAddCodigo() {
        this.shortcutComponentCodigo.hide();
    }

    private async mounted() {
        this.modelGrid = [];
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.shortcutComponentCodigo = this.$refs.shortcutComponentCodigo as ShortcutComponent;

        if (this.$route.params.id && !this.CalledByShortCut) {
            this.modelId = +this.$route.params.id;
        }

        this.load();
    }
}
