var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          showSave: !_vm.somenteConsulta && _vm.gridData.length > 0,
          titleHtml: _vm.compraTitle,
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              ref: "fieldsetComponent",
              attrs: { title: "Filtros", collapsed: false },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("tipoCompra"),
                            expression: "errors.first('tipoCompra')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "tipoCompra" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.cliente.edit_vue_html.tipo")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "tipoCompra",
                            name: "tipoCompra",
                            data: _vm.tipoOptions,
                            disabled: this.model.id,
                          },
                          model: {
                            value: _vm.tipoCompra,
                            callback: function ($$v) {
                              _vm.tipoCompra = $$v
                            },
                            expression: "tipoCompra",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("curvaAbc"),
                            expression: "errors.first('curvaAbc')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "curvaAbc" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.compras.edit_vue_html.curvaABC"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "curvaAbc",
                            name: "curvaAbc",
                            data: _vm.curvaAbcOptions,
                            disabled: this.model.id,
                          },
                          model: {
                            value: _vm.curvaAbc,
                            callback: function ($$v) {
                              _vm.curvaAbc = $$v
                            },
                            expression: "curvaAbc",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataInicial"),
                            expression: "errors.first('dataInicial')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataInicial" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.compras.edit_vue_html.dataInicial"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("dateTimePickerComponent", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "dataInicial",
                            name: "dataInicial",
                            disabled: this.model.id,
                          },
                          model: {
                            value: _vm.dataInicial,
                            callback: function ($$v) {
                              _vm.dataInicial = $$v
                            },
                            expression: "dataInicial",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dataFinal"),
                            expression: "errors.first('dataFinal')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dataFinal" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.compras.edit_vue_html.dataFinal"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("dateTimePickerComponent", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "dataFinal",
                            name: "dataFinal",
                            disabled: this.model.id,
                          },
                          model: {
                            value: _vm.dataFinal,
                            callback: function ($$v) {
                              _vm.dataFinal = $$v
                            },
                            expression: "dataFinal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("dias"),
                            expression: "errors.first('dias')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "dias" },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.compras.edit_vue_html.dias")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.dias,
                              expression: "model.dias",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "number",
                            id: "dias",
                            name: "dias",
                            maxlength: "4",
                            disabled: this.model.id,
                          },
                          domProps: { value: _vm.model.dias },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "dias", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("margemTolerancia"),
                            expression: "errors.first('margemTolerancia')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "margemTolerancia" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.compras.edit_vue_html.margemTolerancia"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: _vm.$t(
                              "__.Crud.compras.edit_vue_html.margemToleranciaTooltip"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("decimalComSinalCustomComponent", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "margemTolerancia",
                            name: "margemTolerancia",
                            signal: "%",
                            title: "margemTolerancia",
                            disabled: this.model.id,
                          },
                          model: {
                            value: _vm.model.margemTolerancia,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "margemTolerancia", $$v)
                            },
                            expression: "model.margemTolerancia",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  !this.model.id
                    ? _c("div", { staticClass: "col-sm-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "grupos" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.usuario.edit_vue_html.grupos"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("multiSelect-component", {
                              attrs: {
                                id: "grupoProdutoSelected",
                                name: "grupoProdutoSelected",
                                options: _vm.grupoProdutoOptions,
                              },
                              model: {
                                value: _vm.grupoProdutoSelected,
                                callback: function ($$v) {
                                  _vm.grupoProdutoSelected = $$v
                                },
                                expression: "grupoProdutoSelected",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.notafiscalentrada.edit_vue_html.itens"
                ),
                collapsed: false,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "produtoId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.movimentacaoestoque.item_vue_html.produto"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "produtoId",
                              name: "produtoId",
                              data: _vm.produtosOptions,
                              refresh: _vm.loadProdutos,
                            },
                            model: {
                              value: _vm.produtoId,
                              callback: function ($$v) {
                                _vm.produtoId = $$v
                              },
                              expression: "produtoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoCompraItem },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("grid-component", {
                          ref: "gridcomponent",
                          attrs: {
                            data: _vm.gridData,
                            columns: _vm.gridColumns,
                            showActionColumn: true,
                            showTableFoot: false,
                            showRemoveItem: true,
                            showEditItem: false,
                            showSearchField: false,
                            showAddNewButton: false,
                          },
                          on: {
                            "change-sort": _vm.onChangeSort,
                            "remove-item": _vm.onRemove,
                            "gerar-xls": function ($event) {
                              _vm.pags = true
                              _vm.tipoDocGerar = 0
                            },
                            "gerar-pdf": function ($event) {
                              _vm.pags = true
                              _vm.tipoDocGerar = 1
                            },
                            "gerar-impressao": function ($event) {
                              _vm.pags = true
                              _vm.tipoDocGerar = 2
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    this.gridData.length > 0 ||
                    this.grupoProdutoSelected.length > 0
                      ? _c("div", { staticClass: "col-sm-2" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-primary buttonIncluir-button",
                                staticStyle: { "margin-top": "30px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.onConsolidar()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.compras.edit_vue_html.consolidar"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showFornecedores
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Crud.fornecedor.list_vue_html.fornecedores"
                    ),
                    collapsed: false,
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { class: "col-sm-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "descricao" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.produtolote.edit_vue_html.fornecedor"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("combo-component", {
                                attrs: {
                                  id: "fornecedorId",
                                  name: "fornecedorId",
                                  data: _vm.fornecedoresOptions,
                                },
                                model: {
                                  value: _vm.fornecedorModel.fornecedorId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.fornecedorModel,
                                      "fornecedorId",
                                      $$v
                                    )
                                  },
                                  expression: "fornecedorModel.fornecedorId",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("buttonIncluirComponent", {
                        on: { click: _vm.onInclusaoItem },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12" },
                          [
                            _c("grid-component", {
                              ref: "gridcomponentFornecedores",
                              attrs: {
                                data: _vm.gridDataFornecedores,
                                columns: _vm.gridColumnsFornecedores,
                                showTableHead: false,
                                showTableFoot: false,
                                showRemoveItem: true,
                                showEditItem: false,
                              },
                              on: {
                                "remove-item": _vm.onRemoveFornecedores,
                                "change-sort": _vm.onChangeSortFornecedores,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  !_vm.somenteConsulta && _vm.gridData.length > 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }