import Vue from "vue";
import { mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import { AppState } from "@/store/store";
import Delay from "@/utils/common/delay";

@Component({
    components: {
        comboComponent,
        shortcutComponent,
    },
    computed: {
        ...mapState<AppState>({
            preLoadList: state => state.preLoad.preLoadList,
        }),
    },
})
export default class TrocarAtendenteComponent extends Vue {
    // State computed props
    preLoadList: PreLoadPackModel;

    private shortCutComponent: ShortcutComponent = null;

    usuariosOptions: Array<Object> = [];

    usuarioId = null;

    public open() {
        this.usuariosOptions = this.preLoadList.vendedoresCombo();
        this.shortCutComponent = this.$refs.shortCutComponent as ShortcutComponent;
        this.shortCutComponent.title = "Trocar Atendente";
        this.shortCutComponent.show();
    }

    private async onTrocarAtendente() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            this.shortCutComponent.hide();
            await Delay(100);
            this.$emit("onTrocarAtendente", this.usuarioId);
        }
    }
}
