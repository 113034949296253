import { mapState } from "vuex";

import moment from "moment";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import FieldSetComponent from "@/components/child/form/fieldset";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import multiSelectComponent from "@/components/child/multiSelect/multiSelect.vue";
import { Component } from "@/decorators";
import { Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import CompraFornecedorModel from "@/models/compraFornecedorModel";
import CompraItemModel from "@/models/compraItemModel";
import CompraModel from "@/models/compraModel";
import CurvaABC from "@/models/enum/produto/curvaABC";
import TiposCompra from "@/models/enum/tiposCompra";
import FornecedorModel from "@/models/fornecedorModel";
import PaginationModel from "@/models/paginationModel";
import GrupoProdutoModel from "@/models/produto/grupoProdutoModel";
import CompraService from "@/services/compraService";
import FornecedorService from "@/services/fornecedorService";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";
import { AppState, SessionActions } from "@/store/store";
import { sortArray } from "@/utils/common/array";
import { getFornecedorCombo, getProdutoComboText } from "@/utils/common/combo/combotext";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        dateTimePickerComponent,
        fieldsetComponent,
        multiSelectComponent,
        buttonIncluirComponent,
        comboComponent,
        dataTooltipComponent,
        decimalComSinalCustomComponent,
        gridComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        compraTitle() {
            const path = "__.Crud.compras.edit_vue_html.";
            if (this.modelId == 0) {
                return this.$t(path + "compraEdit");
            }
            return `${this.$t(path + "compraCod")}<span style="color: mediumblue">${this.model.codigo}</span>${this.$t(
                path + "edit",
            )}`;
        },
    },
})
export default class ComprasEditComponent extends EditComponentBase<CompraModel> {
    // State computed props
    preLoadList: PreLoadPackModel;

    private gridcomponent: Grid = null;
    private fieldsetComponent: FieldSetComponent = null;

    grupoProdutoOptions: Array<Object> = [];
    grupoProdutoSelected: Array<number> = [];
    produtosOptions: Array<Object> = [];
    fornecedoresOptions: Array<Object> = [];

    tipoCompra = 0;
    curvaAbc = 0;
    gridData: Array<CompraItemModel> = [];
    gridDataFornecedores: Array<CompraFornecedorModel> = [];
    produtoId = 0;
    pags = false;
    tipoDocGerar: number = null;
    fornecedorModel = new CompraFornecedorModel();
    showFornecedores = false;
    private compraService = new CompraService();

    private dataInicial = moment(new Date()).subtract(1, "month").startOf("month").toDate();
    private dataFinal = moment(new Date()).subtract(1, "month").endOf("month").toDate();

    tipoOptions: Array<Object> = [{ text: "Consumo", value: TiposCompra.Consumo }];

    curvaAbcOptions: Array<Object> = [
        { text: "Geral", value: 0 },
        { text: "A", value: CurvaABC.A },
        { text: "B", value: CurvaABC.B },
        { text: "C", value: CurvaABC.C },
    ];

    get gridColumnsFornecedores(): Array<GridColumn> {
        return [new GridColumn("fornecedorDescricao", "Fornecedor", GridColumnType.String, false, false, "", "", true)];
    }

    private get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", this.$t("__.ts.descricao") as string, GridColumnType.String),
            new GridColumn("curvaAbcDescricao", "Curva ABC", GridColumnType.String),
            new GridColumn("grupoProdutoDescricao", this.$t("__.ts.grupo") as string, GridColumnType.String),
            new GridColumn("estoqueMinimo", this.$t("__.ts.estoqueMin") as string, GridColumnType.String),
            new GridColumn("estoqueMaximo", this.$t("__.ts.estoqueMax") as string, GridColumnType.String),
            new GridColumn("quantidadeVendida", this.$t("__.ts.qtdVendida") as string, GridColumnType.Decimal),
            new GridColumn("valorVendido", this.$t("__.ts.valorVendido") as string, GridColumnType.Money),
            new GridColumn("consumoDiario", this.$t("__.ts.consumoDiario") as string, GridColumnType.Decimal),
            new GridColumn("quantidadeAtual", this.$t("__.ts.qtdAtual") as string, GridColumnType.Decimal),
            new GridColumn("valorCusto", this.$t("__.ts.valorCusto") as string, GridColumnType.Money),
            new GridColumn("quantidadeCompra", this.$t("__.ts.qtdComprar") as string, GridColumnType.Decimal),
        ];
    }

    private async onConsolidar() {
        if (await this.$validator.validateAll()) {
            const filters = {
                grupoProdutoIds: this.grupoProdutoSelected.map(p => p["value"]),
                produtosFixosIds: this.gridData.map(p => p.produtoId),
                tipoCompra: this.tipoCompra,
                dataInicial: this.dataInicial,
                dataFinal: this.dataFinal,
                dias: this.model.dias,
                margemTolerancia: this.model.margemTolerancia,
                curvaAbc: this.curvaAbc,
                fornecedores: this.gridDataFornecedores,
            };

            this.gridData = await this.compraService
                .consolidar(filters)
                .withLoading()
                .resolveWithJSON<CompraItemModel[]>();

            this.gridDataFornecedores = this.gridData[0].produtoFornecedores;

            await this.loadFornecedores();

            if (this.gridData.length > 0) {
                this.fieldsetComponent = this.$refs.fieldsetComponent as FieldSetComponent;
                this.fieldsetComponent.isCollapsed = true;
                this.showFornecedores = true;
            } else {
                await this.$showWarning(
                    this.$t("__.ts.atencao"),
                    "Nenhum item foi encontrado para os filtros selecionados",
                );
            }
        }
    }

    public async onLoadModel(model: CompraModel) {
        this.gridData = model.itens;
        this.dataInicial = model.dataInicial;
        this.dataFinal = model.dataFinal;
        this.tipoCompra = model.tipoCompra;

        this.showFornecedores = !!this.model.id;

        if (model.fornecedores.length > 0) {
            this.gridDataFornecedores = model.fornecedores;
            this.loadFornecedores();
        }
    }

    private onRemove(item: CompraItemModel) {
        this.gridData = this.gridData.filter(i => i !== item);
    }

    private onRemoveFornecedores(item: CompraFornecedorModel) {
        this.gridDataFornecedores = this.gridDataFornecedores.filter(
            fornecedor => fornecedor.fornecedorId !== item.fornecedorId,
        );

        this.loadFornecedores();
    }

    public async onPreSave() {
        this.model.dataInicial = this.dataInicial;
        this.model.dataFinal = this.dataFinal;
        this.model.tipoCompra = this.tipoCompra;
        this.model.itens = this.gridData;
        this.model.fornecedores = this.gridDataFornecedores;

        return true;
    }

    @Watch("pags")
    private onPags() {
        if (this.pags) {
            this.gridcomponent = this.$refs.gridcomponent as Grid;

            if (this.tipoDocGerar == 0) {
                setTimeout(() => {
                    this.gridcomponent.gerarXls();
                }, 2000);
            }

            if (this.tipoDocGerar == 1) {
                setTimeout(() => {
                    this.gridcomponent.gerarPdf();
                }, 2000);
            }

            if (this.tipoDocGerar == 2) {
                setTimeout(() => {
                    this.gridcomponent.gerarImpressao();
                }, 2000);
            }

            setTimeout(() => {
                this.pags = false;
            }, 2000);
        }
    }

    private onInclusaoCompraItem() {
        const produto = this.$el.querySelector("#produtoId") as HTMLSelectElement;

        if (this.produtoId > 0) {
            const alreadyExists = this.gridData.filter(f => f.produtoId == this.produtoId).length > 0;

            if (!alreadyExists) {
                const compraItem = new CompraItemModel();

                compraItem.produtoDescricao = produto.innerText;
                compraItem.produtoId = this.produtoId;
                compraItem.valorCusto = this.preLoadList.produtos.find(x => x.id == this.produtoId)?.valorCusto ?? 0;
                compraItem.compraId = 0;
                compraItem.quantidadeCompra = 0;

                this.gridData.push(compraItem);

                this.loadProdutos();
                this.produtoId = 0;
            }
        }
    }

    private async loadFornecedores() {
        try {
            const data = await new FornecedorService()
                .combo()
                .withLoading()
                .resolveWithJSON<PaginationModel<FornecedorModel>>();

            this.fornecedoresOptions = data.list
                .filter(
                    p =>
                        p.id != this.fornecedorModel.id &&
                        !this.gridDataFornecedores.some(item => item.fornecedorId === p.id),
                )
                .map(getFornecedorCombo);
        } catch {}
    }

    private onInclusaoItem() {
        const fornecedor = this.$el.querySelector("#fornecedorId") as HTMLSelectElement;
        const compraFornecedor = new CompraFornecedorModel();
        compraFornecedor.fornecedorId = this.fornecedorModel.fornecedorId;
        compraFornecedor.fornecedorDescricao = fornecedor.innerText;
        this.gridDataFornecedores.push(compraFornecedor);
        this.loadFornecedores();
        this.fornecedorModel.fornecedorId = null;
    }

    private onChangeSortFornecedores(sortKey: keyof CompraFornecedorModel, sortOrder: "asc" | "desc") {
        sortArray(this.gridDataFornecedores, sortKey, sortOrder);
    }

    private onChangeSort(sortKey: keyof CompraItemModel, sortOrder: "asc" | "desc") {
        sortArray(this.gridData, sortKey, sortOrder);
    }

    private async loadProdutos() {
        try {
            this.produtosOptions = this.preLoadList.produtos
                .filter(
                    p =>
                        p.subGrupo != null &&
                        p.subGrupo >= 0 &&
                        p.id != this.produtoId &&
                        this.gridData.filter(i => i.produtoId == p.id).length == 0,
                )
                .map(item => ({
                    value: item.id,
                    text: getProdutoComboText(item),
                    subGrupo: item.subGrupo,
                }));
        } catch {}
    }

    private async beforeMount() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);

        if (!this.usuarioLogado.franquia.moduloCompras) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaNaoModuloContratado"));

            return this.$router.back();
        }

        await this.setProps(new CompraService(), "compras", new CompraModel());

        const grupoResponse = await new GrupoProdutoService()
            .combo()
            .resolveWithJSON<PaginationModel<GrupoProdutoModel>>()
            .withLoading();

        this.grupoProdutoOptions = grupoResponse.list.map(grupo => ({
            value: grupo.id,
            text: grupo.descricao,
        }));

        this.loadProdutos();
        await this.loadFornecedores();
    }
}
