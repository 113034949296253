import AtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/atendimentoWhatsAppModel";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";

export function setAtendimentoNome(atendimento: AtendimentoWhatsAppModel) {
    if (!atendimento.nomeContato) {
        const numero = atendimento.numero.split("@")[0];
        atendimento.nomeContato = `+55 ${numero.slice(2, 4)} ${numero.slice(4, 9)}-${numero.slice(9)}`;
    }
    return atendimento;
}

export function sortDataUltimaMensagem(atendimentos: AtendimentoWhatsAppModel[]) {
    return [
        ...atendimentos.sort((a, b) => {
            if (a.dataUltimaMensagem && b.dataUltimaMensagem) {
                return a.dataUltimaMensagem > b.dataUltimaMensagem ? -1 : 1;
            }
            if (a.dataUltimaMensagem) {
                return -1;
            }
            if (b.dataUltimaMensagem) {
                return 1;
            }
            return a.dataInicio > b.dataInicio ? -1 : 1;
        }),
    ];
}

export function getUltimaMensagem(message: MensagemAtendimentoWhatsAppModel) {
    if (message.mensagemExcluida) return "Mensagem apagada";
    if (message.audio) return "Áudio";
    if (message.documento) return "Documento";
    if (message.figura) return "Figurinha";
    if (message.imagem) return "Imagem";
    if (message.video) return "Vídeo";
    if (message.reacao) return "Reação";
    if (message.localizacao) return "Localização";
    if (message.contato) return "Contato";
    if (message.tipoMensagemNaoSuportado) return "Mensagem não suportada";
    return message.texto?.trim().replace(/\*(.*?)\*/g, "$1");
}
