import { Module } from "vuex";

import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import CapsulaModel from "@/models/capsulaModel";
import ConfiguracaoRotuloModel from "@/models/configuracaoRotuloModel";
import EstoqueModel from "@/models/estoqueModel";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import FormulaPadraoModel from "@/models/formulaPadraoModel";
import KitEmbalagemModel from "@/models/kitEmbalagemModel";
import PosologiaModel from "@/models/posologiaModel";
import ProdutoModel from "@/models/produto/produtoModel";
import CampanhaModel from "@/models/programafidelidade/campanhaModel";
import SetorModel from "@/models/setorModel";
import LocalEntregaModel from "@/models/transporte/localEntregaModel";
import UnidadeMedidaModel from "@/models/unidadeMedidaModel";
import UsuarioModel from "@/models/usuarioModel";
import PreLoadPackService from "@/services/preLoadPackService";

import { AppState } from "./store";

export type LoadListState = {
    loadedList: boolean;
    loadingList: boolean;
    preLoadList: PreLoadPackModel;
};

export type LoadListProps = {
    loadAll?: boolean;
    loadUnidadeMedida?: boolean;
    loadFormaFarmaceutica?: boolean;
    loadPosologia?: boolean;
    loadKitEmbalagem?: boolean;
    loadFormulaPadraoDesmembrados?: boolean;
    loadProdutos?: boolean;
    loadEstoque?: boolean;
    loadLocaisEntrega?: boolean;
    loadCampanhas?: boolean;
    loadCapsulas?: boolean;
    loadVendedores?: boolean;
    loadRotulos?: boolean;
    loadSetores?: boolean;
};

export type LoadListMutations = "LOAD_LIST";

const preLoad: Module<LoadListState, AppState> = {
    state: {
        loadedList: false,
        loadingList: false,
        preLoadList: new PreLoadPackModel(),
    },
    mutations: {
        async LOAD_LIST(store, props: LoadListProps) {
            store.loadingList = true;

            const { loadAll } = props;

            let {
                loadUnidadeMedida,
                loadFormaFarmaceutica,
                loadPosologia,
                loadKitEmbalagem,
                loadFormulaPadraoDesmembrados,
                loadProdutos,
                loadEstoque,
                loadLocaisEntrega,
                loadCampanhas,
                loadCapsulas,
                loadVendedores,
                loadRotulos,
                loadSetores,
            } = props;

            if (loadAll) {
                loadUnidadeMedida =
                    loadFormaFarmaceutica =
                    loadPosologia =
                    loadKitEmbalagem =
                    loadFormulaPadraoDesmembrados =
                    loadProdutos =
                    loadEstoque =
                    loadLocaisEntrega =
                    loadCampanhas =
                    loadCapsulas =
                    loadVendedores =
                    loadRotulos =
                    loadSetores =
                        true;
            }

            const data = await new PreLoadPackService()
                .loadPackLists(
                    loadUnidadeMedida,
                    loadFormaFarmaceutica,
                    loadPosologia,
                    loadKitEmbalagem,
                    loadFormulaPadraoDesmembrados,
                    loadProdutos,
                    loadEstoque,
                    loadLocaisEntrega,
                    loadCampanhas,
                    loadCapsulas,
                    loadVendedores,
                    loadRotulos,
                    loadSetores,
                )
                .withLoading()
                .resolveWithJSON<PreLoadPackModel>();

            store.preLoadList.currentFranquiaId = data.currentFranquiaId;

            if (loadUnidadeMedida) {
                store.preLoadList.unidadesMedida = new Array<UnidadeMedidaModel>();
                store.preLoadList.unidadesMedida = data.unidadesMedida;
            }

            if (loadFormaFarmaceutica) {
                store.preLoadList.formasFarmaceuticas = new Array<FormaFarmaceuticaModel>();
                store.preLoadList.formasFarmaceuticas = data.formasFarmaceuticas;
            }

            if (loadPosologia) {
                store.preLoadList.posologias = new Array<PosologiaModel>();
                store.preLoadList.posologias = data.posologias;
            }

            if (loadKitEmbalagem) {
                store.preLoadList.kitsEmbalagem = new Array<KitEmbalagemModel>();
                store.preLoadList.kitsEmbalagem = data.kitsEmbalagem;
            }

            if (loadFormulaPadraoDesmembrados) {
                store.preLoadList.formulasPadrao = new Array<FormulaPadraoModel>();
                store.preLoadList.formulasPadrao = data.formulasPadrao;
            }

            if (loadProdutos) {
                store.preLoadList.produtos = new Array<ProdutoModel>();
                store.preLoadList.produtos = data.produtos;
            }

            if (loadEstoque) {
                store.preLoadList.estoques = new Array<EstoqueModel>();
                store.preLoadList.estoques = data.estoques;
            }

            if (loadLocaisEntrega) {
                store.preLoadList.locaisEntrega = new Array<LocalEntregaModel>();
                store.preLoadList.locaisEntrega = data.locaisEntrega;
            }

            if (loadCampanhas) {
                store.preLoadList.campanhas = new Array<CampanhaModel>();
                store.preLoadList.campanhas = data.campanhas;
            }

            if (loadCapsulas) {
                store.preLoadList.capsulas = new Array<CapsulaModel>();
                store.preLoadList.capsulas = data.capsulas;
            }

            if (loadVendedores) {
                store.preLoadList.vendedores = new Array<UsuarioModel>();
                store.preLoadList.vendedores = data.vendedores;
            }

            if (loadRotulos) {
                store.preLoadList.rotulos = new Array<ConfiguracaoRotuloModel>();
                store.preLoadList.rotulos = data.rotulos;
                store.preLoadList.dadosFranquiaRotulo = data.dadosFranquiaRotulo;
            }

            if (loadSetores) {
                store.preLoadList.setores = new Array<SetorModel>();
                store.preLoadList.setores = data.setores;
            }

            store.loadedList = true;
            store.loadingList = false;
        },
    },
};

export default preLoad;
