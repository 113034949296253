var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c(
        "actionBarComponent",
        {
          attrs: {
            slots: 2,
            title: _vm.$t("__.Crud.usuario.edit_vue_html.usuarioEdit"),
          },
          on: { onSave: _vm.save },
          scopedSlots: _vm._u([
            {
              key: "buttonSlot1",
              fn: function (ref) {
                var open = ref.open
                return _vm.modelId
                  ? _c(
                      "div",
                      {
                        attrs: { title: _vm.$t("__.ts.historicoAlteracoes") },
                        on: {
                          click: function ($event) {
                            _vm.onShowLogsUsuario(open)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-history",
                          attrs: { slot: "button", "aria-hidden": "true" },
                          slot: "button",
                        }),
                      ]
                    )
                  : _vm._e()
              },
            },
          ]),
        },
        [
          _vm.model.id && _vm.isAdmin
            ? _c(
                "div",
                {
                  attrs: {
                    slot: "buttonSlot0",
                    title: _vm.$t("__.Crud.usuario.edit_vue_html.resetarSenha"),
                  },
                  on: { click: _vm.resetarSenha },
                  slot: "buttonSlot0",
                },
                [
                  _c("i", {
                    staticClass: "fa fa-lock",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "modalSlot1" }, slot: "modalSlot1" },
            [
              _c("logEntidadeComponent", {
                attrs: {
                  withoutFranquiaId: true,
                  registroId: _vm.modelId,
                  entidade: 7,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.usuario.edit_vue_html.dadosGerais"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("colaborador"),
                            expression: "errors.first('colaborador')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "colaborador",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.usuario.edit_vue_html.colaborador"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                            { name: "focus", rawName: "v-focus" },
                          ],
                          attrs: {
                            id: "colaborador",
                            name: "colaborador",
                            data: _vm.colaboradoresOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.colaboradorId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "colaboradorId", $$v)
                            },
                            expression: "model.colaboradorId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("nome"),
                            expression: "errors.first('nome')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "nome",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.usuario.edit_vue_html.nomeCompleto"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.nome,
                              expression: "model.nome",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:256",
                              expression: "'required|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "text",
                            id: "nome",
                            name: "nome",
                          },
                          domProps: { value: _vm.model.nome },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "nome", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-1" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("ativo"),
                            expression: "errors.first('ativo')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "ativo" },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.usuario.edit_vue_html.ativo")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("checkbox-component", {
                          attrs: { id: "ativo", name: "ativo" },
                          model: {
                            value: _vm.model.ativo,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "ativo", $$v)
                            },
                            expression: "model.ativo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("email"),
                            expression: "errors.first('email')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "email",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                E-mail\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.email,
                              expression: "model.email",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email|max:256",
                              expression: "'required|email|max:256'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            autocomplete: "off",
                            type: "email",
                            id: "email",
                            name: "email",
                            placeholder: "exemplo@exemplo.com.br",
                          },
                          domProps: { value: _vm.model.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.model, "email", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("perfil"),
                            expression: "errors.first('perfil')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "perfil",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t("__.Crud.usuario.edit_vue_html.perfil")
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "perfil",
                            name: "perfil",
                            data: _vm.usuarioPerfilOptions,
                            searchable: false,
                          },
                          model: {
                            value: _vm.model.perfil,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "perfil", $$v)
                            },
                            expression: "model.perfil",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("franquia"),
                            expression: "errors.first('franquia')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "franquia",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.usuario.edit_vue_html.franquiaPadrao"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("combo-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "franquia",
                            name: "franquia",
                            data: _vm.franquiasOptions,
                            searchable: true,
                          },
                          model: {
                            value: _vm.model.franquiaId,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "franquiaId", $$v)
                            },
                            expression: "model.franquiaId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.usuario.edit_vue_html.permissoes"),
                collapsed: true,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "acaoId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.usuario.edit_vue_html.acoes"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "acaoId",
                              name: "acaoId",
                              data: _vm.acoesOptions,
                              searchable: true,
                              refresh: _vm.loadAcoes,
                            },
                            model: {
                              value: _vm.acaoId,
                              callback: function ($$v) {
                                _vm.acaoId = $$v
                              },
                              expression: "acaoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onInclusaoTelaAcao },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.telasAcaoUsuario,
                      columns: _vm.gridColumnsTelaAcao,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.onRemoveTelaAcao },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "grupoId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.usuario.edit_vue_html.grupos"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "grupoId",
                              name: "grupoId",
                              data: _vm.gruposOptions,
                              searchable: true,
                              refresh: _vm.loadGrupos,
                            },
                            model: {
                              value: _vm.grupoId,
                              callback: function ($$v) {
                                _vm.grupoId = $$v
                              },
                              expression: "grupoId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onInclusaoGrupo },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.gruposUsuario,
                      columns: _vm.gridColumnsGrupo,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.onRemoveGrupo },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.usuario.edit_vue_html.permissoesRemovidas"
                ),
                collapsed: true,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "franquiaIdRemovida" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.usuario.edit_vue_html.franquia"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "franquiaIdRemovida",
                              name: "franquiaIdRemovida",
                              data: _vm.franquiasOptions,
                              searchable: true,
                            },
                            model: {
                              value: _vm.franquiaIdRemovida,
                              callback: function ($$v) {
                                _vm.franquiaIdRemovida = $$v
                              },
                              expression: "franquiaIdRemovida",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "acaoIdRemovida" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.usuario.edit_vue_html.acoes"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "acaoIdRemovida",
                              name: "acaoIdRemovida",
                              data: _vm.acoesOptions,
                              searchable: true,
                              refresh: _vm.loadAcoes,
                            },
                            model: {
                              value: _vm.acaoIdRemovida,
                              callback: function ($$v) {
                                _vm.acaoIdRemovida = $$v
                              },
                              expression: "acaoIdRemovida",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onInclusaoTelaAcaoRemovida },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.telasAcaoRemover,
                      columns: _vm.gridColumnsTelaAcaoRemovida,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.onRemoveTelaAcaoRemovida },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.usuario.edit_vue_html.franquias"),
                collapsed: true,
              },
            },
            [
              _c(
                "div",
                { attrs: { slot: "rows" }, slot: "rows" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-8" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "franquiaId" },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.$t(
                                      "__.Crud.usuario.edit_vue_html.franquia"
                                    )
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("combo-component", {
                            attrs: {
                              id: "franquiaId",
                              name: "franquiaId",
                              data: _vm.franquiasOptions,
                              searchable: true,
                              refresh: _vm.loadFranquias,
                            },
                            model: {
                              value: _vm.franquiaId,
                              callback: function ($$v) {
                                _vm.franquiaId = $$v
                              },
                              expression: "franquiaId",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    !(_vm.model.Id > 0) && !(_vm.model.franquiaId > 0)
                      ? _c("div", { staticClass: "col-sm-1" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.errors.first("isFranquiaPadrao"),
                                  expression:
                                    "errors.first('isFranquiaPadrao')",
                                },
                              ],
                              staticClass: "form-group",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "control-label",
                                  attrs: { for: "isFranquiaPadrao" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.$t(
                                          "__.Crud.usuario.edit_vue_html.padrao"
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("checkbox-component", {
                                attrs: {
                                  id: "isFranquiaPadrao",
                                  name: "isFranquiaPadrao",
                                },
                                model: {
                                  value: _vm.isFranquiaPadrao,
                                  callback: function ($$v) {
                                    _vm.isFranquiaPadrao = $$v
                                  },
                                  expression: "isFranquiaPadrao",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAdmin
                      ? _c(
                          "div",
                          { staticClass: "col-sm-2 div-franquias-drogavet" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary buttonIncluir-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    _vm.onClickFranquiaDrogavet()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.usuario.edit_vue_html.franquiaDrogavet"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("buttonIncluirComponent", {
                    on: { click: _vm.onInclusaoFranquia },
                  }),
                  _vm._v(" "),
                  _c("grid-component", {
                    attrs: {
                      data: _vm.model.usuarioFranquias,
                      columns: _vm.gridColumnsFranquia,
                      showSortIcons: false,
                      "show-table-head": false,
                      "show-table-foot": false,
                      "show-edit-item": false,
                    },
                    on: { "remove-item": _vm.onRemoveFranquia },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.usuarioLogado.franquia.moduloWhatsApp && _vm.isAdmin
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t("__.Crud.usuario.edit_vue_html.atendimento"),
                    collapsed: true,
                  },
                },
                [
                  _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "perfilAtendente" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.usuario.edit_vue_html.perfilAtendimento"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "perfilAtendente",
                                name: "perfilAtendente",
                                data: _vm.perfisAtendenteOptions,
                              },
                              model: {
                                value: _vm.model.perfilAtendente,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "perfilAtendente", $$v)
                                },
                                expression: "model.perfilAtendente",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.id
            ? _c("infoBagdeComponent", { attrs: { text: _vm.infoBagdeText } })
            : _vm._e(),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.load()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.limpar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }