import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import StatusEnvioSNGPC from "@/models/enum/statusEnvioSNGPC";
import SNGPCModel from "@/models/sngpc/sngpcModel";
import SNGPCProdutoModel from "@/models/sngpc/sNGPCProdutoModel";
import SNGPCService from "@/services/sngpc/sNGPCService";
import Delay from "@/utils/common/delay";
import FileHelper from "@/utils/common/fileHelper";

import ListComponentBase from "../crud/listComponentBase";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import SNGPCProdutoListComponent from "./ver";
import sNGPCProdutoListComponent from "./ver.vue";

@Component({
    components: {
        dateTimePickerComponent,
        gridComponent,
        shortcutComponent,
        sNGPCProdutoListComponent,
    },
})
export default class SNGPCListComponent extends ListComponentBase<SNGPCModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataInicial", "Inicio", GridColumnType.Date),
            new GridColumn("dataFinal", "Fim", GridColumnType.Date),
            new GridColumn("statusEnvioDescricao", "Status", GridColumnType.String),
            new GridColumn("inventario", "Inventário", GridColumnType.Boolean),
            new GridColumn("dataEnvio", "Envio", GridColumnType.Date),
            new GridColumn("dataValidacao", "Validação", GridColumnType.Date),
        ];
    }

    private beforeMount() {
        this.setProps(new SNGPCService(), "sngpc", "datainicial", false, "desc");
    }

    private sngpcService = new SNGPCService();
    private sNGPCProdutoListComponent: SNGPCProdutoListComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private shortcutComponentSelecao: ShortcutComponent = null;

    extraActions: Array<object> = [];
    gridExtraActionsVer: Array<GridAction> = [];
    gridExtraActionsEnviarXml: Array<GridAction> = [];
    gridExtraActionsRemover: Array<GridAction> = [];
    gridExtraActionsBaixar: Array<GridAction> = [];
    gridExtraActionsVerReprovacao: Array<GridAction> = [];

    modelVer: Array<SNGPCProdutoModel> = [];

    dataInicio = new Date();
    dataFinal = new Date();
    gerarInventario = false;

    public async afterLoad() {
        this.gridExtraActionsVer.splice(0);
        this.gridExtraActionsEnviarXml.splice(0);
        this.gridExtraActionsRemover.splice(0);
        this.extraActions.splice(0);
        this.gridExtraActionsBaixar.splice(0);
        this.gridExtraActionsVerReprovacao.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.gridExtraActionsVer.push(
                new GridAction("ver", "Visualizar Produtos", "fa fa-eye", GridColors.BLUE, true),
            );

            this.gridExtraActionsEnviarXml.push(
                new GridAction(
                    "enviar",
                    "Enviar Xml",
                    "fa fa-file-code",
                    GridColors.GREEN,
                    this.gridData[i].statusEnvio == StatusEnvioSNGPC.Pendente,
                ),
            );

            this.gridExtraActionsRemover.push(
                new GridAction(
                    "remover",
                    "Remover",
                    "fa fa-times",
                    GridColors.RED,
                    this.gridData[i].statusEnvio == StatusEnvioSNGPC.Pendente,
                ),
            );

            this.gridExtraActionsBaixar.push(
                new GridAction(
                    "baixar",
                    "Baixar Xml",
                    "fa fa-cloud-download-alt",
                    GridColors.GREEN,
                    this.gridData[i].statusEnvio != StatusEnvioSNGPC.Pendente,
                ),
            );

            this.gridExtraActionsVerReprovacao.push(
                new GridAction(
                    "verMotivoReprovacao",
                    "Visualizar o Motivo da Reprovação",
                    "fa fa-exclamation-triangle",
                    GridColors.YELLOW,
                    this.gridData[i].statusEnvio == StatusEnvioSNGPC.Reprovado,
                ),
            );
        }

        this.extraActions.push(this.gridExtraActionsVer);
        this.extraActions.push(this.gridExtraActionsVerReprovacao);
        this.extraActions.push(this.gridExtraActionsEnviarXml);
        this.extraActions.push(this.gridExtraActionsBaixar);
        this.extraActions.push(this.gridExtraActionsRemover);
    }

    private async onGerarInventario() {
        const response = await this.$showQuestion(
            this.$t("__.Components.parent.sngpc.list_vue_html.confirmaGeracaoInventario"),
            this.$t("__.ts.acaoNaoRevert"),
        );

        if (response) {
            try {
                await this.showSelecaoData();
                this.gerarInventario = true;
            } catch {}
        }
    }

    private async onGerarNovo() {
        try {
            await this.showSelecaoData();
            this.gerarInventario = false;
        } catch {}
    }

    private async onConfirmSelecaoData() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            this.shortcutComponentSelecao.hide();

            await this.onGerarRelatorio();
        }
    }

    private async onGerarRelatorio() {
        try {
            const sucesso = await this.sngpcService
                .getSNGPCAtual(this.dataInicio, this.dataFinal, this.gerarInventario)
                .withLoading()
                .resolveWithoutJSON();

            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.inclusao"), this.$t("__.ts.registroSalvSucess"));
                this.load();
            }
        } catch {}
    }

    private onExtraAction(name: string, model: SNGPCModel) {
        if (name.trim() == "ver") {
            this.onVer(model);
        } else if (name.trim() == "enviar") {
            this.onEnviarXml(model);
        } else if (name.trim() == "baixar") {
            this.onBaixarXml(model);
        } else if (name.trim() == "remover") {
            this.onRemoveItem(model);
        } else if (name.trim() == "verMotivoReprovacao") {
            this.onVerMotivoReprovacao(model);
        }
    }

    private onVerMotivoReprovacao(model: SNGPCModel) {
        const hasMensagemValidacao = model.retornoXml.includes("MENSAGEMVALIDACAO");
        if (hasMensagemValidacao) {
            const regex = /<MENSAGEMVALIDACAO>([\s\S]*?)<\/MENSAGEMVALIDACAO>/;
            const match = model.retornoXml.match(regex);
            if (match) {
                return this.$showWarningHtml("Motivo da Rejeição", match[1].replaceAll("[NOVA_LINHA]", "<br/>"));
            }
        }

        this.$showInfo("Motivo da Rejeição", model.retornoXml);
    }

    private async onBaixarXml(model: SNGPCModel) {
        try {
            const response = await this.sngpcService.getDataXml(model.id).withLoading().resolveWithResponse();
            if (response.ok) {
                const datablob = await response.blob();
                if (datablob) {
                    const blob = new Blob([datablob], { type: "application/xml" });

                    const fileName = response.headers.get("Content-Disposition").split("filename=")[1].split(";")[0];
                    FileHelper.download(blob, fileName, "application/xml");
                }
            }
        } catch {}
    }

    private async onEnviarXml(model: SNGPCModel) {
        try {
            const sucesso = await this.sngpcService.enviarRelatorio(model.id).withLoading().resolveWithoutJSON();

            if (sucesso) {
                await this.$showSuccess("Enviado!", "Registro foi Enviado com sucesso.");
                this.load();
            }
        } catch {}
    }

    private async onVer(model: SNGPCModel) {
        while (!this.$refs.shortcutComponent) await Delay(100);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.sNGPCProdutoListComponent = this.$refs.sNGPCProdutoListComponent as SNGPCProdutoListComponent;

        this.shortcutComponent.title = "Produtos";
        this.sNGPCProdutoListComponent.loadModel(model["sngpcProdutos"]);

        this.shortcutComponent.show();
    }

    private async showSelecaoData() {
        const periodo = await this.sngpcService
            .getProximoPeriodo(this.gerarInventario)
            .withLoading()
            .resolveWithJSON<{ dataInicio: string; dataFinal: string }>();

        this.dataInicio = new Date(periodo.dataInicio);
        this.dataFinal = new Date(periodo.dataFinal);

        while (!this.$refs.shortcutComponentSelecao) await Delay(100);
        this.shortcutComponentSelecao = this.$refs.shortcutComponentSelecao as ShortcutComponent;
        this.shortcutComponentSelecao.title = "Período";
        this.shortcutComponentSelecao.show();
    }
}
