var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.CalledByShortCut
        ? _c(
            "actionBarComponent",
            {
              attrs: {
                slots: 1,
                title: _vm.$t(
                  "__.Crud.rotulopersonalizado.edit_vue_html.rotuloEdit"
                ),
              },
              on: { onSave: _vm.save },
            },
            [
              _vm.validarVisibilidadeBotoes("replicar")
                ? _c(
                    "div",
                    {
                      attrs: {
                        slot: "buttonSlot0",
                        title: _vm.$t("__.Crud.replicar"),
                      },
                      on: { click: _vm.onReplicar },
                      slot: "buttonSlot0",
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-sync",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.nomeProduto,
                collapsed: false,
                withCheckBox: _vm.CalledByShortCut,
                checkBoxLiteral: "Imprimir",
                isChecked: _vm.model.imprimir,
              },
              on: { onChangeChecked: _vm.onImprimirChange },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _vm.CalledByShortCut && _vm.temRotuloAntigo
                  ? _c("div", { staticClass: "row oldRotulo-row" }, [
                      _c("div", { staticClass: "col-sm-6 rotuloTopTextLeft" }, [
                        _c("i", { staticClass: "fa fa-check" }),
                        _vm._v(
                          "\n                        RÓTULO A SER IMPRESSO\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-6 rotuloTopTextRight" },
                        [
                          _c("i", {
                            staticClass: "fa fa-exclamation-triangle",
                          }),
                          _vm._v(
                            "\n                        RÓTULO JÁ UTILIZADO\n                    "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "display-rotulo-contaier" }, [
                  _c(
                    "div",
                    {
                      style: _vm.getRotuloContainerStyle(
                        _vm.CalledByShortCut ? _vm.rotuloNovoSelecionado : false
                      ),
                      attrs: { id: "print" },
                      on: {
                        click: function ($event) {
                          _vm.onSelectRotulo(true)
                        },
                      },
                    },
                    [
                      _c("rotulo-component", {
                        attrs: {
                          model: _vm.model,
                          manipulacaoKey: _vm.manipulacaoKey,
                          CalledByShortCut: _vm.CalledByShortCut,
                          scaleFactor: _vm.previewScaleFactor,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.CalledByShortCut && _vm.temRotuloAntigo
                    ? _c(
                        "div",
                        {
                          staticStyle: { "margin-left": "32px" },
                          style: _vm.getRotuloContainerStyle(
                            !_vm.rotuloNovoSelecionado
                          ),
                          attrs: { id: "print" },
                          on: {
                            click: function ($event) {
                              _vm.onSelectRotulo(false)
                            },
                          },
                        },
                        [
                          _c("rotulo-component", {
                            attrs: {
                              model: _vm.modelUtilizado,
                              manipulacaoKey: _vm.manipulacaoKey,
                              CalledByShortCut: _vm.CalledByShortCut,
                              scaleFactor: _vm.previewScaleFactor,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          !_vm.CalledByShortCut
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.nomeConfiguracaoProduto,
                    collapsed: true,
                  },
                },
                [
                  _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("nome"),
                                expression: "errors.first('nome')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "nome",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.nome"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.model.nome,
                                  expression: "model.nome",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|max:256",
                                  expression: "'required|max:256'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "text",
                                id: "nome",
                                name: "nome",
                              },
                              domProps: { value: _vm.model.nome },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "nome",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("origemDados"),
                                expression: "errors.first('origemDados')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "origemDados",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.tipoRotulo"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "origemDados",
                                name: "origemDados",
                                data: _vm.origemDadosOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.origemDados,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "origemDados", $$v)
                                },
                                expression: "model.origemDados",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("fonteRotulo"),
                                expression: "errors.first('fonteRotulo')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "fonteRotulo",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.fonteRotulo"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                name: "fonteRotulo",
                                data: _vm.fonteRotuloOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.fonteRotulo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "fonteRotulo", $$v)
                                },
                                expression: "model.fonteRotulo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "nomeImpressora" },
                              },
                              [_vm._v(_vm._s(_vm.$t("__.ts.impressora")))]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                id: "nomeImpressora",
                                name: "nomeImpressora",
                                data: _vm.impressoraOptions,
                                refresh: _vm.loadImpressoraOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.impressoraSelecionada,
                                callback: function ($$v) {
                                  _vm.impressoraSelecionada = $$v
                                },
                                expression: "impressoraSelecionada",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("largura"),
                                expression: "errors.first('largura')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "largura",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.largura"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                signal: "mm",
                                id: "largura",
                                name: "largura",
                              },
                              model: {
                                value: _vm.model.largura,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "largura", $$v)
                                },
                                expression: "model.largura",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("altura"),
                                expression: "errors.first('altura')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "altura",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.altura"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                signal: "mm",
                                id: "altura",
                                name: "altura",
                              },
                              model: {
                                value: _vm.model.altura,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "altura", $$v)
                                },
                                expression: "model.altura",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("paddingVertical"),
                                expression: "errors.first('paddingVertical')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "paddingVertical",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.espacamentoSuperior"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                signal: "mm",
                                id: "paddingVertical",
                                name: "paddingVertical",
                              },
                              model: {
                                value: _vm.model.paddingVertical,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "paddingVertical", $$v)
                                },
                                expression: "model.paddingVertical",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("paddingHorizontal"),
                                expression: "errors.first('paddingHorizontal')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "paddingHorizontal",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.espacHoriz"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                signal: "mm",
                                id: "paddingHorizontal",
                                name: "paddingHorizontal",
                              },
                              model: {
                                value: _vm.model.paddingHorizontal,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "paddingHorizontal", $$v)
                                },
                                expression: "model.paddingHorizontal",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("paddingLine"),
                                expression: "errors.first('paddingLine')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "paddingLine",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.paddingLine"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.model.paddingLine,
                                  expression: "model.paddingLine",
                                  modifiers: { number: true },
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                autocomplete: "off",
                                type: "number",
                                name: "paddingLine",
                              },
                              domProps: { value: _vm.model.paddingLine },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.model,
                                    "paddingLine",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("quantidadeColunas"),
                                expression: "errors.first('quantidadeColunas')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "quantidadeColunas",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.quantidadeColunas"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("decimal-com-sinal-custom-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|maior_que_zero",
                                  expression: "'required|maior_que_zero'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                signal: "",
                                id: "quantidadeColunas",
                                name: "quantidadeColunas",
                              },
                              model: {
                                value: _vm.model.quantidadeColunas,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "quantidadeColunas", $$v)
                                },
                                expression: "model.quantidadeColunas",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("posicaoQrCode"),
                                expression: "errors.first('posicaoQrCode')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "posicaoQrCode",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.posicQRCode"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                name: "posicaoQrCode",
                                data: _vm.posicaoQrCodeOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.posicaoQrCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "posicaoQrCode", $$v)
                                },
                                expression: "model.posicaoQrCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _vm.model.posicaoQrCode != 0
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("larguraCode"),
                                    expression: "errors.first('larguraCode')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "larguraCode",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.rotulopersonalizado.edit_vue_html.larguraCode"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("decimal-com-sinal-custom-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    signal: "mm",
                                    id: "larguraCode",
                                    name: "larguraCode",
                                  },
                                  model: {
                                    value: _vm.model.larguraCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "larguraCode", $$v)
                                    },
                                    expression: "model.larguraCode",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _vm.model.posicaoQrCode != 0
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("alturaCode"),
                                    expression: "errors.first('alturaCode')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "alturaCode",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.rotulopersonalizado.edit_vue_html.alturaCode"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("decimal-com-sinal-custom-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    signal: "mm",
                                    id: "alturaCode",
                                    name: "alturaCode",
                                  },
                                  model: {
                                    value: _vm.model.alturaCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "alturaCode", $$v)
                                    },
                                    expression: "model.alturaCode",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("formatoImpressao"),
                                expression: "errors.first('formatoImpressao')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "formatoImpressao",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.formatoImpressao"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                name: "formatoImpressao",
                                data: _vm.formatoImpressaoRotuloOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.formatoImpressao,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "formatoImpressao", $$v)
                                },
                                expression: "model.formatoImpressao",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.errors.first("posicaoLogo"),
                                expression: "errors.first('posicaoLogo')",
                              },
                            ],
                            staticClass: "form-group",
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: {
                                  for: "posicaoLogo",
                                  title: _vm.$t("__.obrigatorio"),
                                },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.posLogo"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                name: "posicaoLogo",
                                data: _vm.posicaoLogoOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.posicaoLogo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.model, "posicaoLogo", $$v)
                                },
                                expression: "model.posicaoLogo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _vm.model.posicaoLogo != 0
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("larguraLogo"),
                                    expression: "errors.first('larguraLogo')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "larguraLogo",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.rotulopersonalizado.edit_vue_html.largLogo"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("decimal-com-sinal-custom-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    signal: "mm",
                                    id: "larguraLogo",
                                    name: "larguraLogo",
                                  },
                                  model: {
                                    value: _vm.model.larguraLogo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "larguraLogo", $$v)
                                    },
                                    expression: "model.larguraLogo",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-1" }, [
                        _vm.model.posicaoLogo != 0
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: _vm.errors.first("alturaLogo"),
                                    expression: "errors.first('alturaLogo')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "alturaLogo",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.rotulopersonalizado.edit_vue_html.altLogo"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("decimal-com-sinal-custom-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    signal: "mm",
                                    id: "alturaLogo",
                                    name: "alturaLogo",
                                  },
                                  model: {
                                    value: _vm.model.alturaLogo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "alturaLogo", $$v)
                                    },
                                    expression: "model.alturaLogo",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "repTecDadosFranquia" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.dadosFranquiaRespTec"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              attrs: {
                                name: "repTecDadosFranquia",
                                data: _vm.respTecDadosFranquiaOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.respTecDadosFranquia,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "respTecDadosFranquia",
                                    $$v
                                  )
                                },
                                expression: "model.respTecDadosFranquia",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "imprimirDadosFranquiaNegrito" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.printDadosFranqNegrito"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("checkbox-component", {
                              attrs: { name: "imprimirDadosFranquiaNegrito" },
                              model: {
                                value: _vm.model.imprimirDadosFranquiaNegrito,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "imprimirDadosFranquiaNegrito",
                                    $$v
                                  )
                                },
                                expression:
                                  "model.imprimirDadosFranquiaNegrito",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "control-label",
                                attrs: { for: "posicaoDadosFranquia" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.$t(
                                        "__.Crud.rotulopersonalizado.edit_vue_html.alinhamentoDadosDaFranquia"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("combo-component", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                name: "posicaoDadosFranquia",
                                data: _vm.posicaoDadosFranquiaOptions,
                                searchable: false,
                              },
                              model: {
                                value: _vm.model.posicaoDadosFranquia,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.model,
                                    "posicaoDadosFranquia",
                                    $$v
                                  )
                                },
                                expression: "model.posicaoDadosFranquia",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.model.respTecDadosFranquia > 0
                        ? _c("div", { staticClass: "col-sm-3" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.errors.first("fonteDadosFranquia"),
                                    expression:
                                      "errors.first('fonteDadosFranquia')",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: {
                                      for: "fonteDadosFranquia",
                                      title: _vm.$t("__.obrigatorio"),
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.$t(
                                            "__.Crud.rotulopersonalizado.edit_vue_html.fonteDadosFranq"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("decimal-com-sinal-custom-component", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    signal: "",
                                    id: "fonteDadosFranquia",
                                    name: "fonteDadosFranquia",
                                  },
                                  model: {
                                    value: _vm.model.fonteDadosFranquia,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.model,
                                        "fonteDadosFranquia",
                                        $$v
                                      )
                                    },
                                    expression: "model.fonteDadosFranquia",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.temRotuloAntigo || _vm.rotuloNovoSelecionado
            ? _c(
                "fieldset-component",
                {
                  attrs: {
                    title: _vm.$t(
                      "__.Crud.rotulopersonalizado.edit_vue_html.camposImpressao"
                    ),
                    collapsed: _vm.collapsedCamposImpressoesFieldset,
                  },
                  on: { collapsedStatus: _vm.onCollapsedStatusChange },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      !_vm.CalledByShortCut
                        ? _c(
                            "div",
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("combo-component", {
                                        attrs: {
                                          id: "campoId",
                                          name: "campoId",
                                          data: _vm.optionsCampos,
                                          refresh: _vm.loadOptionsCampos,
                                        },
                                        model: {
                                          value: _vm.campoSelected,
                                          callback: function ($$v) {
                                            _vm.campoSelected = $$v
                                          },
                                          expression: "campoSelected",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("buttonIncluirComponent", {
                                on: { click: _vm.onInclusaoCampo },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.CalledByShortCut
                        ? _c("div", { staticClass: "row" }, [
                            _vm.rotulosOptions.length > 1
                              ? _c("div", { staticClass: "col-sm-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "control-label",
                                          attrs: { for: "origemDados" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__.Crud.rotulopersonalizado.edit_vue_html.rotuloUtilizar"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("combo-component", {
                                        attrs: {
                                          id: "rotuloSelecionado",
                                          name: "rotuloSelecionado",
                                          data: _vm.rotulosOptions,
                                          searchable: false,
                                        },
                                        model: {
                                          value: _vm.rotuloSelecionado,
                                          callback: function ($$v) {
                                            _vm.rotuloSelecionado = $$v
                                          },
                                          expression: "rotuloSelecionado",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-1" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "control-label",
                                      attrs: {
                                        for: "copias",
                                        title: _vm.$t("__.obrigatorio"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.$t("__.ts.copias")) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("data-tooltip-component", {
                                    attrs: {
                                      text: _vm.$t(
                                        "__.ts.selecioneZeroNaoImprimir"
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.model.copias,
                                        expression: "model.copias",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      autocomplete: "off",
                                      type: "number",
                                      id: "copias",
                                      name: "copias",
                                    },
                                    domProps: { value: _vm.model.copias },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.model,
                                          "copias",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("grid-component", {
                        ref: "gridcomponent",
                        attrs: {
                          data: _vm.model.campos,
                          columns: !_vm.CalledByShortCut
                            ? _vm.gridColumnsCampos
                            : _vm.gridColumnsCamposEditable,
                          showRemoveItem: true,
                          showEditItem: false,
                          showActionColumn: true,
                          showSortIcons: true,
                          showTableHead: false,
                          showAddNewButton: false,
                          showTableFoot: false,
                          showTools: false,
                          extraActions: _vm.extraActions,
                        },
                        on: {
                          "remove-item": _vm.onRemoveCampo,
                          "extra-action": _vm.onExtraAction,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "fieldset-component",
                { attrs: { title: "Campos Impressos", collapsed: false } },
                [
                  _c(
                    "div",
                    { attrs: { slot: "rows" }, slot: "rows" },
                    [
                      _c("grid-component", {
                        ref: "gridcomponent",
                        attrs: {
                          data: _vm.modelUtilizado.campos,
                          columns: _vm.gridColumnsCamposReadonly,
                          showRemoveItem: false,
                          showEditItem: false,
                          showActionColumn: true,
                          showSortIcons: true,
                          showTableHead: false,
                          showAddNewButton: false,
                          showTableFoot: false,
                          showTools: false,
                          extraActions: _vm.extraActions,
                        },
                        on: { "extra-action": _vm.onExtraAction },
                      }),
                    ],
                    1
                  ),
                ]
              ),
          _vm._v(" "),
          !_vm.CalledByShortCut
            ? _c("fieldset", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 newbtn" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.voltar")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      [
        _c("replicar-selecao-franquia-component", {
          ref: "replicarSelecaoFranquiaComponent",
          on: { replicar: _vm.onConfirmReplicar },
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }