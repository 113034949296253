import Vue from "vue";

import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnDecimal, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import SNGPCProdutoModel from "@/models/sngpc/sNGPCProdutoModel";
import { sortArray } from "@/utils/common/array";

import "@/components/parent/crud/crud.scss";

@Component({
    components: {
        gridComponent,
    },
})
export default class SNGPCProdutoListComponent extends Vue {
    gridData: Array<SNGPCProdutoModel> = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataInicial", "Inicio", GridColumnType.Date),
            new GridColumn("dataFinal", "Fim", GridColumnType.Date),
            new GridColumn("produtoDescricao", "Produto", GridColumnType.String),
            new GridColumnDecimal("estoqueSaldoInicial", "Estoque Inicial", 4),
            new GridColumnDecimal("estoqueSaldoFinal", "Estoque Final", 4),
        ];
    }

    subDataName = "sngpcDetalhes";

    get gridColumnsVinculos(): Array<GridColumn> {
        return [
            new GridColumn("dataMovimento", "Data", GridColumnType.Date),
            new GridColumn("tipoMovimentoDescricao", "Movimento", GridColumnType.String),
            new GridColumnDecimal("quantidadeMovimentada", "Quantidade", 4),
            new GridColumn("unidadeFarmacotecnicaDescricao", "Unidade", GridColumnType.String),
            new GridColumnDecimal("quantidadeDeUnidadesFarmacotecnicas", "Volume", 4),
            new GridColumnDecimal("quantidadeDeInsumoPorUnidadeFarmacotecnica", "Quantidade Insumo", 4),
            new GridColumn("unidadeDeMedidaDoInsumoDescricao", "Unidade Insumo", GridColumnType.String),
            new GridColumn("loteDescricao", "Lote", GridColumnType.String),
        ];
    }

    conditionShowSubData(it) {
        return it["sngpcDetalhes"]["length"] > 0;
    }

    private onChangeSort(sortKey: keyof SNGPCProdutoModel, sortOrder: "asc" | "desc") {
        sortArray(this.gridData, sortKey, sortOrder);
    }

    public loadModel(model: Array<SNGPCProdutoModel>) {
        this.gridData = null;
        this.gridData = new Array<SNGPCProdutoModel>();
        this.gridData = model;
    }
}
