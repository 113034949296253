import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnDecimal, GridColumnType } from "@/components/child/grid/gridColumn";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import { Component, Watch, Prop } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import CorManipulacao from "@/models/enum/corManipulacao";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import SubGrupoProduto from "@/models/enum/subGrupoProduto";
import TipoCalculoAssociacao from "@/models/enum/tipoCalculoAssociacao";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import tiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import tiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import TiposProduto from "@/models/enum/tiposProduto";
import UnidadesMedida from "@/models/enum/unidadesMedida";
import FormulaPadraoItemModel from "@/models/formulaPadraoItemModel";
import FormulaPadraoModel from "@/models/formulaPadraoModel";
import ManipulacaoOrdemItemModel from "@/models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import PaginationModel from "@/models/paginationModel";
import ProdutoModel from "@/models/produto/produtoModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import CapsulaService from "@/services/capsulaService";
import FormulaPadraoService from "@/services/formulaPadraoService";
import ManipulacaoOrdemItemService from "@/services/manipulacaoOrdemItemService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import { ItemTableType, ManipulacaoContextMutations, ManipulacaoContextState } from "@/store/manipulacaoContext/types";
import { ManipulacaoContextActions } from "@/store/manipulacaoContext/types";
import { AppState, Getters } from "@/store/store";
import { getProdutoComboText } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";

import ProdutoVerComponent from "../crud/produto/verProduto";
import produtoVerComponent from "../crud/produto/verProduto.vue";

import { calculos, getItemManipulacaoByProduto } from "./manipulacaoUtils";

import "./item.scss";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        moedaComponent,
        decimalComponent,
        decimalComSinalCustomComponent,
        gridComponent,
        produtoVerComponent,
        searchComboComponent,
    },
    computed: {
        ...mapState({
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
        }),
        ...mapGetters(["GET_MANIPULACAO_CONTEXT"] as Getters),
        isAssociado() {
            return this.model.produtoAssociadoPaiId != null;
        },
        isFormaFarmaceutica() {
            return this.model.formaFarmaceuticaId != null;
        },
        produtoStyle() {
            if (this.produtoId > 0) {
                const produto = this.preLoadPack.produtos.find(x => x.id == this.produtoId);
                switch (produto?.corManipulacao) {
                    case CorManipulacao.Vermelho:
                        return "background-red";
                    case CorManipulacao.Roxo:
                        return "background-purple";
                    case CorManipulacao.Verde:
                        return "background-green";
                    case CorManipulacao.Azul:
                        return "background-blue";
                    case CorManipulacao.Laranja:
                        return "background-orange";
                    case CorManipulacao.Amarelo:
                        return "background-yellow";
                }
            }
            return "";
        },
        isDisabledEdicaoPreVenda() {
            return this.manipulacao.isPreVenda && !this.manipulacao.isPreVendaEditavel;
        },
    },
})
export default class ManipulacaoItemComponent extends Vue {
    // State computed props
    GET_MANIPULACAO_CONTEXT: (uuid: string) => ManipulacaoContextState;
    isAssociado: boolean;
    isFormaFarmaceutica: boolean;
    preLoadPack: PreLoadPackModel;

    private produtoService = new ProdutoService();
    private produtoLoteService = new ProdutoLoteService();
    private manipulacaoOrdemItemService = new ManipulacaoOrdemItemService();
    private formulaPadraoService = new FormulaPadraoService();
    private capsulaService = new CapsulaService();

    private produtoVerComponent: ProdutoVerComponent = null;

    model = new ManipulacaoOrdemItemModel();
    public produto = new ProdutoModel();
    private produtoPaiPuro = null;

    private timer: number = null;

    public isFormulaPadrao = false;
    private isFlavorizante = false;
    private formulaPadraoModel = new FormulaPadraoModel();
    private itensDesemembradosFormulaBase: Array<ManipulacaoOrdemItemModel> = [];

    private unidadeMedidaIdProduto = 0;
    private produtoId = null;
    private changeProdutoId = false;

    private produtosOptions: Array<Object> = [];
    private lotesOptions: Array<Object> = [];
    private formulaPadraoOptions: Array<Object> = [];
    private tipoItemOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TiposItemManipulacao);
    private tiposCalculoOptions = [];
    private unidadeMedidaOptions: Array<Object> = [];
    private capsulasOptions: Array<Object> = [];
    private coresCapsulasOptions: Array<Object> = [];

    private loading = false;
    private loadingFormulaPadrao = false;
    private nivelItemDesmembrado = 0;

    private unsubscribe = null;

    private manipulacao = new ManipulacaoOrdemModel();
    private manipulacaoContext = new ManipulacaoContextState();

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("produtoDescricao", this.$t("__.ts.descr"), GridColumnType.Raw),
            new GridColumnDecimal("formulaPadraoItemQuantidadeDose", this.$t("__.ts.qtdeFormPadrao"), 4),
            new GridColumnDecimal("quantidadePesagem", this.$t("__.ts.qtdTotalPesado"), 4),
        ];
    }

    @Prop({ type: Number, required: true }) index: number;
    @Prop({ type: Object, default: () => null }) itemModel;

    @Prop({ type: Array, required: true }) produtosList;
    @Prop({ type: Array, required: true }) capsulasOptionsList;
    @Prop({ type: Array, required: true }) formulaPadraoList;
    @Prop({ type: Array, required: true }) unidadeMedidaList;
    //0 = ativos, 1 = embalagens,  2 = capsulas
    @Prop(Number) itemType: number;

    @Prop({ type: String, required: true }) uuid: string;

    @Watch("model", { deep: true })
    private onChangeModel() {
        if (
            !this.manipulacaoContext.isConsolidando &&
            (this.model.produtoId || this.model.tipoCalculo) &&
            !this.loadingFormulaPadrao
        ) {
            this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
                item: this.model,
                type: this.itemType,
                index: this.index,
            });
        }
    }

    public clear() {
        this.model = null;
        this.model = new ManipulacaoOrdemItemModel();
        this.produto = new ProdutoModel();
        this.produtoId = null;
        this.model.produtoId = null;
        this.model.tipoCalculo = null;
        this.model.quantidadeDose = 0;
        this.model.unidadeMedidaManipulacaoId = 0;
        this.model.quantidade = 0;
        this.model.quantidadePesagem = 0;
        this.model.valor = 0;
        this.model.valorTotal = 0;
        this.model.fatorUTR = null;
    }

    private async loadFormulaPadrao() {
        try {
            const data = await this.formulaPadraoService.listDesmembrado().resolveWithJSON<FormulaPadraoModel[]>();

            this.formulaPadraoOptions = data.map(item => ({
                value: item.id,
                text: `${item.produtoId} - ${item.descricao}`,
            }));
        } catch {}
    }

    private loadProdutoOptions() {
        if (this.isFlavorizante) {
            this.produtosOptions = this.produtosList.filter(p => p["subGrupo"] == SubGrupoProduto.Flavorizante);
        } else if (this.model.subGrupoItem != null && this.itemType != ItemTableType.ATIVO) {
            this.produtosOptions = this.produtosList.filter(p => p["subGrupo"] == this.model.subGrupoItem);

            if (!this.produtosOptions.some(p => p["value"] == this.produtoId)) {
                this.produtosOptions = this.produtosList;
            }
        } else {
            this.produtosOptions = this.produtosList;
        }
    }

    @Watch("produtosList", { deep: true })
    private onChangeProdutoList() {
        this.loadProdutoOptions();
    }

    private async loadProdutos() {
        let tipoProduto = TiposProduto.MateriaPrima;
        switch (this.model.tipoItem) {
            case TiposItemManipulacao.Embalagem:
                tipoProduto = TiposProduto.Embalagem;
                break;
            case TiposItemManipulacao.Capsula:
                tipoProduto = TiposProduto.Capsula;
                break;
            default:
        }

        const data = await this.produtoService
            .listFilterProdutoTipo(tipoProduto, 1, 5000)
            .resolveWithJSON<PaginationModel<ProdutoModel>>();

        this.produtosOptions = data.list
            .filter(p => (!this.manipulacao.id ? p.ativo : true))
            .map(item => ({
                value: item.id,
                text: getProdutoComboText(item, false),
                subGrupo: item.subGrupo,
            }));
    }

    public async validateAll() {
        try {
            const isValid = await this.$validator.validateAll();
            if (!isValid) {
                if (this.manipulacao.homeopatia) {
                    await this.$showError(
                        this.$t("__.ts.erro"),
                        `${this.$t("__.ts.obrigatorioinformarLoteParaProduto")} ${this.produto.descricao}`,
                    );
                }
                this.$focusErrorField();
            }
            return isValid;
        } catch {}

        return false;
    }

    public forceUpdate() {
        this.$forceUpdate();

        this.loadProdutoId();
    }

    public async consolidaItem(manipulacao: ManipulacaoOrdemModel) {
        await this.loadValoresProdutoByLote(this.manipulacao.usaMarkupEquinos, manipulacao);

        if (this.model.tipoCalculo == TiposCalculoManipulacao.Percentual) {
            this.model = this.setUnidadeMedidaCalculoPercentual(this.model);
        }

        await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
            item: this.model,
            type: this.itemType,
            index: this.index,
            noAddIfExists: true,
        });

        await this.dispatch(ManipulacaoContextActions.CALCULAR_QUANTIDADES_ITEM, {
            type: this.itemType,
            index: this.index,
            item: this.model,
        });

        if (!this.produto.id) {
            await this.loadProduto().withLoading();

            this.model.fatorCorrecao = this.produto.fatorCorrecao;
            this.model.produtoListaControladoId = this.produto.listaControladoId;
        }

        if (this.model.produtoId) {
            await this.loadProduto().withLoading();
        }

        this.model.produtoDescricao = this.produto.descricao;

        if (this.produto.produtoAssociacoes.length > 0) {
            await this.adicionarAssociacoes();
        }
    }

    private async adicionarAssociacoes() {
        if (!this.isAssociado) {
            this.loadManipulacaoContext();

            const produtoAssociacoes = this.produto.produtoAssociacoes.filter(
                p => p.formaFarmaceuticaId == this.manipulacao.formaFarmaceuticaId,
            );

            for (const associacao of produtoAssociacoes) {
                let itemAssociacao = new ManipulacaoOrdemItemModel();
                itemAssociacao.updateFrom(this.model, ["id"]);

                // Se possui associação com QSP não deve buscar o item padrão da forma farmacêutica e sim da associação
                if (associacao.excipiente) {
                    itemAssociacao.tipoCalculo = TiposCalculoManipulacao.Qsp;
                }

                itemAssociacao.produto = null;
                itemAssociacao.produtoLoteId = null;
                itemAssociacao.sinonimo = null;
                itemAssociacao.sinonimoId = null;
                itemAssociacao.produtoAssociadoPaiId = this.model.produtoId;
                itemAssociacao.produtoDescricao = associacao.produtoAssociadoDescricao;
                itemAssociacao.produtoId = associacao.produtoAssociadoId;
                itemAssociacao.formulaPadraoId = null;

                if (itemAssociacao.tipoItem == tiposItemManipulacao.FormulaPadrao) {
                    itemAssociacao.tipoItem = tiposItemManipulacao.MateriaPrima;
                }

                if (!associacao.produtoAssociado) {
                    associacao.produtoAssociado = await this.produtoService
                        .get(associacao.produtoAssociadoId)
                        .resolveWithJSON<ProdutoModel>();
                }

                let isUnidadeMedidaEspecial = false;
                const unidade = this.unidadeMedidaOptions.filter(
                    p => p["value"] == this.model.unidadeMedidaManipulacaoId,
                );

                if (unidade.length > 0) {
                    if (
                        unidade[0]["type"] == UnidadesMedida.UI ||
                        unidade[0]["type"] == UnidadesMedida.UFC ||
                        unidade[0]["type"] == UnidadesMedida.UTR
                    ) {
                        isUnidadeMedidaEspecial = true;
                    }
                }

                itemAssociacao.densidade = this.manipulacaoContext.formaFarmaceutica.desconsiderarDensidade
                    ? 1
                    : associacao.produtoAssociado.densidade;
                itemAssociacao.fatorCorrecao = associacao.produtoAssociado.fatorCorrecao;
                itemAssociacao.fatorUI = associacao.produtoAssociado.fatorUI;
                itemAssociacao.fatorUTR = associacao.produtoAssociado.fatorUTR;
                itemAssociacao.fatorUFC = associacao.produtoAssociado.fatorUFC;
                itemAssociacao.unidadeMedidaId = associacao.produtoAssociado.unidadeMedidaEstoqueId;
                itemAssociacao.unidadeMedidaManipulacaoId = associacao.produtoAssociado.unidadeMedidaManipulacaoId;
                itemAssociacao.valor = this.manipulacaoContext.isTransferencia
                    ? associacao.produtoAssociado.valorCusto
                    : associacao.produtoAssociado.valorVenda;
                itemAssociacao.tipoCalculoAssociacao = associacao.tipoCalculoAssociacao;

                if (associacao.tipoCalculoAssociacao == TipoCalculoAssociacao.VolumeTotal) {
                    itemAssociacao.tipoCalculo = TiposCalculoManipulacao.Percentual;
                    itemAssociacao.quantidadeDose = associacao.percentual;

                    await this.calculaQuantidadeAssociacaoVolumeTotal(itemAssociacao);

                    await Delay(1000);

                    itemAssociacao = this.setUnidadeMedidaCalculoPercentual(itemAssociacao);
                } else {
                    let quantidade = this.model.quantidade;
                    if (
                        !isUnidadeMedidaEspecial &&
                        ((this.model.tipoCalculo == tiposCalculoManipulacao.Percentual &&
                            associacao.produtoAssociado.unidadeMedidaManipulacaoId !=
                                this.produto.unidadeMedidaManipulacaoId) ||
                            (this.model.tipoCalculo != tiposCalculoManipulacao.Percentual &&
                                associacao.produtoAssociado.unidadeMedidaManipulacaoId !=
                                    this.model.unidadeMedidaManipulacaoId))
                    ) {
                        const indice =
                            await this.manipulacaoContext.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                                this.model.unidadeMedidaManipulacaoId,
                                itemAssociacao.unidadeMedidaManipulacaoId,
                                itemAssociacao.densidade,
                                itemAssociacao.produtoId,
                                itemAssociacao.produtoLoteId,
                            );
                        quantidade = this.model.quantidade * indice;
                    }

                    itemAssociacao.quantidadeDose =
                        Math.round((associacao.percentual / 100) * this.model.quantidadeDose * 10000) / 10000;
                    itemAssociacao.quantidade = Math.round((associacao.percentual / 100) * quantidade * 10000) / 10000;
                    itemAssociacao.quantidadePesagem =
                        Math.round((associacao.percentual / 100) * this.model.quantidadePesagem * 10000) / 10000;
                }

                if (itemAssociacao.tipoCalculo == TiposCalculoManipulacao.Percentual) {
                    itemAssociacao = this.setUnidadeMedidaCalculoPercentual(itemAssociacao);
                }

                await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
                    item: itemAssociacao,
                    type: ItemTableType.ATIVO,
                    noAddIfExists: true,
                });
            }
        }
    }

    private async calculaQuantidadeAssociacaoVolumeTotal(item: ManipulacaoOrdemItemModel) {
        let quantidade =
            (item.quantidadeDose / 100) * (this.manipulacao.volumeTotal / (this.manipulacao.numeroFormulas ?? 1));

        if (item.fatorCorrecao) {
            quantidade *= item.fatorCorrecao;
        }

        if (item.fatorEquivalencia) {
            quantidade *= item.fatorEquivalencia;
        }

        const indiceConversao = await this.manipulacaoContext.indicesConversoesUnidadeMedidasService.getIndiceConversao(
            this.manipulacao.unidadeMedidaId,
            item.unidadeMedidaId,
            item.densidade,
            item.produtoId,
            item.produtoLoteId,
        );

        item.quantidade = quantidade;
        item.quantidadePesagem = quantidade * indiceConversao;
    }

    //@ts-ignore
    @Watch("manipulacao.usaMarkupEquinos")
    private async onChangeUsaMarkupEquinos() {
        this.loadManipulacaoContext();

        await this.loadProduto().withLoading();

        if (this.produto.id) {
            if (this.manipulacao.usaMarkupEquinos) {
                this.loadValoresProdutoByLote(true, this.manipulacao);
            } else {
                this.model.valor = this.produto.valorVenda;
            }
        }

        this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.manipulacao });
    }

    private async carregarProduto() {
        this.loadManipulacaoContext();

        try {
            if (!this.produto.id || this.produto.id != this.model.produtoId) {
                this.produto = await this.produtoService
                    .get(this.model.produtoId)
                    .withLoading()
                    .resolveWithJSON<ProdutoModel>();
            }

            const { item, produto } = getItemManipulacaoByProduto(
                this.produto,
                this.model,
                this.manipulacaoContext,
                this.itemType,
            );

            if (!this.manipulacao.usaMarkupEquinos) {
                item.valor = 0;
                if (produto.valorVenda) {
                    item.valor = produto.valorVenda;
                }
            }

            const newItem = { ...item } as ManipulacaoOrdemItemModel;
            this.model.updateFrom(newItem);
            this.produto = produto;

            if (this.produto.subGrupo != null) {
                this.isFlavorizante = this.produto.subGrupo == SubGrupoProduto.Flavorizante;
                this.loadProdutoOptions();
            }

            this.loadProdutoPaiPuro();

            this.$emit("onChangeMovimentaEstoque", !this.produto.ignoraControleEstoque);

            if (this.manipulacaoContext.isCapsula && this.produto.volumeCapsula > 0) {
                this.$emit("alterarVolumeCapsula", this.produto.volumeCapsula, this.produto.descricao);
            }

            if (this.isFormulaPadrao && !this.formulaPadraoModel.unidadeMedidaId) {
                this.formulaPadraoModel.unidadeMedidaId = this.model.unidadeMedidaId;
            }
        } catch {}
    }

    @Watch("itemModel", { deep: true })
    private onChangeItens() {
        this.model.updateFrom(this.itemModel);

        if (this.manipulacaoContext.formaFarmaceutica.desconsiderarDensidade) {
            this.model.densidade = 1;
        }

        this.loadProdutoId();

        this.isFormulaPadrao = false;
        if (this.model.tipoItem == TiposItemManipulacao.FormulaPadrao) {
            this.isFormulaPadrao = true;
        }

        if (!this.isFormulaPadrao) {
            this.itensDesemembradosFormulaBase = [];
        }
    }

    //@ts-ignore
    @Watch("model.capsulaId")
    private async onChangeCapsulaId(newValue: number, oldValue: number) {
        if (newValue && oldValue && newValue != oldValue) {
            this.manipulacao.consolidado = false;
            this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.manipulacao });
        }

        this.loadProdutoCoresCapsulas();
    }

    //@ts-ignore
    @Watch("manipulacaoContext.formaFarmaceutica.tipoCapsula")
    private async onChangeTipoCapsula() {
        this.loadProdutoCoresCapsulas();
    }

    //@ts-ignore
    @Watch("produtoId")
    private onChangeProdutoId(newValue: number, oldValue: number) {
        if (!newValue || newValue == oldValue) {
            return;
        }

        const oldProdutoId = this.model.produtoId;
        const oldSinonimoId = this.model.sinonimoId;

        const produtos = this.produtosList.filter(p => p["value"] == this.produtoId);

        this.changeProdutoId = true;

        if (produtos.length > 0 && produtos[0]["sinonimoId"]) {
            this.model.produtoId = produtos[0]["produtoId"];
            this.model.sinonimoId = produtos[0]["sinonimoId"];
            this.model.produtoDescricao = produtos[0]["text"];
        } else {
            this.model.produtoId = this.produtoId;
            this.model.sinonimoId = null;
        }

        // Se trocou de sinonimo para outro sinonimo do mesmo produto ou para o proprio produto deve recarregar os dados
        if (
            (this.model.sinonimoId || oldSinonimoId) &&
            this.model.produtoId == oldProdutoId &&
            this.model.sinonimoId != oldSinonimoId
        ) {
            this.carregarProduto();
        }
    }

    //@ts-ignore
    @Watch("model.produtoId")
    private async onProdutoIdChanged(newValue: number, oldValue: number) {
        if (!newValue || newValue == oldValue) {
            return;
        }

        this.loadProdutoId();

        this.loadManipulacaoContext();

        if (!this.manipulacaoContext.uploadingEditingModel && !this.isAssociado) {
            await this.carregarProduto().withLoading();
        }

        if (this.itemType == ItemTableType.CAPSULA) {
            this.$emit("onCorCapsulaChange", this.model);
        }
    }

    private loadProdutoId() {
        if (
            !this.changeProdutoId ||
            this.itemType == ItemTableType.EMBALAGEM ||
            this.itemType == ItemTableType.CAPSULA
        ) {
            if (this.model.sinonimoId && this.model.produtoId) {
                this.produtoId = this.model.sinonimoId * this.model.produtoId;
            } else {
                this.produtoId = this.model.produtoId;
            }
        }
        this.changeProdutoId = false;
    }

    //@ts-ignore
    @Watch("model.formulaPadraoId")
    private async onFormulaPadraoIdChanged(newValue: number) {
        if (!newValue || newValue == 0) {
            return;
        }

        await this.carregarFormulaPadrao().withLoading();

        await this.dispatch(ManipulacaoContextActions.CALCULAR_QSP, { loadDadosLote: false, ignoreDebounce: false });

        await this.loadModelByFormulaPadrao();
    }

    private async loadModelByFormulaPadrao() {
        if (!this.model.formulaPadraoId || this.loadingFormulaPadrao) {
            return;
        }

        this.loadingFormulaPadrao = true;

        const alterouQuantidade =
            this.manipulacaoContext.desmembrados.reduce((acc, p) => acc + p.quantidadePesagem, 0) !=
            this.model.quantidadePesagem;
        // Se item já está salvo e os ativos desmembrados forem os mesmos não deve fazer nada
        if (
            !alterouQuantidade &&
            this.model.id &&
            this.manipulacaoContext.desmembrados.every(p => p.formulaPadraoId == this.model.formulaPadraoId)
        ) {
            this.loadingFormulaPadrao = false;
            return;
        }

        if (this.formulaPadraoModel && this.formulaPadraoModel.formulaPadraoItens.length == 0) {
            await this.carregarFormulaPadrao().withLoading();
        }

        this.loadManipulacaoContext();

        this.model.produtoId = this.formulaPadraoModel.produtoId;
        this.model.produtoDescricao = this.formulaPadraoModel.produtoDescricao;
        this.model.unidadeMedidaManipulacaoDecricao = this.unidadeMedidaOptions.filter(
            option => option["value"] == this.model.unidadeMedidaManipulacaoId,
        )[0]["text"];
        this.model.movimentaEstoque = false;
        this.model.formulaPadraoId = this.formulaPadraoModel.id;

        await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
            item: this.model,
            type: this.itemType,
            index: this.index,
        });

        //deleta itens filhos para reincluir abaixo
        let hasItens = this.itensDesemembradosFormulaBase.length == 0;
        if (this.itensDesemembradosFormulaBase.length > 0) {
            if (
                this.formulaPadraoModel.id != this.itensDesemembradosFormulaBase[0].formulaPadraoId ||
                this.itensDesemembradosFormulaBase[0].id == null ||
                // Ajuste especifico para corrigir quantidades incorretas da venda de origem
                (this.manipulacaoContext.isVendaReplicada &&
                    this.itensDesemembradosFormulaBase[0].quantidadePesagem === this.model.quantidadePesagem)
            ) {
                hasItens = true;

                this.itensDesemembradosFormulaBase.splice(0);
            }
        }

        for await (const formulaPadraoItem of this.orderQSPToLast(this.formulaPadraoModel.formulaPadraoItens)) {
            formulaPadraoItem.formulaPadrao = this.formulaPadraoModel;

            await this.addItemFormulaPadrao(formulaPadraoItem, hasItens, false);
        }

        await this.dispatch(ManipulacaoContextActions.UPDATE_FORMULA_BASE, {
            desmembrados: this.itensDesemembradosFormulaBase,
            manipulacaoOrdemItemProdutoPaiId: this.model.produtoId,
        });

        this.loadingFormulaPadrao = false;
    }

    private orderQSPToLast(formulaPadraoItens: FormulaPadraoItemModel[]) {
        return formulaPadraoItens.sort((_, p) => (p.tipoCalculo != TiposCalculoManipulacao.Qsp ? 1 : -1));
    }

    private async addItemFormulaPadrao(formulaItem: FormulaPadraoItemModel, hasItens: boolean, isDesmembrado = false) {
        let manipulacaoItem = this.getNewItemToFormula(formulaItem, isDesmembrado);

        const item = await this.manipulacaoOrdemItemService
            .loadDadosProdutoLote(
                manipulacaoItem,
                this.manipulacao,
                this.manipulacao.usaMarkupEquinos,
                this.manipulacaoContext.isTransferencia,
            )
            .resolveWithJSON<ManipulacaoOrdemItemModel>();

        manipulacaoItem.densidade = item.densidade;
        manipulacaoItem.fatorCorrecao = item.fatorCorrecao;
        manipulacaoItem.fatorUI = item.fatorUI;
        manipulacaoItem.fatorUFC = item.fatorUFC;
        manipulacaoItem.fatorUTR = item.fatorUTR;
        manipulacaoItem.produtoLoteCalculosId = item.produtoLoteCalculosId;
        manipulacaoItem.valor = item.valor;

        manipulacaoItem.formulaPadraoItemId = formulaItem.id;
        manipulacaoItem.formulaPadraoItemQuantidadeDose = formulaItem.quantidadeDose;

        const itemPai = this.itensDesemembradosFormulaBase.find(p => p.produtoId == manipulacaoItem.produtoPai);
        manipulacaoItem = await this.calculaQuantidadeFormulaPadraoItem(
            manipulacaoItem,
            isDesmembrado ? itemPai.quantidadePesagem : this.model.quantidadePesagem,
        );

        if (formulaItem.desmembrado) {
            const formulas = await this.manipulacaoContext.manipulacaoOrdemService
                .getFormulaPadraoByProdutos([formulaItem.produtoId])
                .resolveWithJSON<ManipulacaoOrdemItemModel[]>();

            if (formulas.some(p => p.formulaPadrao)) {
                manipulacaoItem.movimentaEstoque = false;
                manipulacaoItem.tipoItem = TiposItemManipulacao.FormulaPadrao;
                this.addItemDesmembradoFormulaBase(manipulacaoItem, hasItens);

                for await (const item of this.orderQSPToLast(formulas[0].formulaPadrao.formulaPadraoItens)) {
                    item.formulaPadrao = formulas[0].formulaPadrao;

                    await this.addItemFormulaPadrao(item, hasItens, true);
                }
            } else {
                this.addItemDesmembradoFormulaBase(manipulacaoItem, hasItens);
            }
        } else {
            this.addItemDesmembradoFormulaBase(manipulacaoItem, hasItens);
        }
    }

    private async calculaQuantidadeFormulaPadraoItem(manipulacaoItem: ManipulacaoOrdemItemModel, volumePai: number) {
        let quantidade = 0;
        let indiceConversao = 1;
        const converter = manipulacaoItem.tipoCalculo != TiposCalculoManipulacao.UTRPercentual;

        if (converter) {
            indiceConversao = await this.manipulacaoContext.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                manipulacaoItem.unidadeMedidaManipulacaoId,
                manipulacaoItem.unidadeMedidaId,
                manipulacaoItem.densidade,
                manipulacaoItem.produtoId,
                manipulacaoItem.produtoLoteId,
            );
        }

        switch (manipulacaoItem.tipoCalculo) {
            case TiposCalculoManipulacao.Unitario:
                quantidade = calculos.tipoCalculo.unitario(manipulacaoItem, this.manipulacaoContext);
                break;
            case TiposCalculoManipulacao.Percentual:
                quantidade = calculos.tipoCalculo.percentual(manipulacaoItem, volumePai, this.manipulacaoContext);
                break;
            case TiposCalculoManipulacao.UTRPercentual:
                quantidade = calculos.tipoCalculo.percentualUTR(manipulacaoItem, volumePai);
                break;
            case TiposCalculoManipulacao.Qsp:
                const volumeAtivos = this.itensDesemembradosFormulaBase
                    .filter(
                        p =>
                            p.produtoPai === manipulacaoItem.produtoPai && p.tipoCalculo != TiposCalculoManipulacao.Qsp,
                    )
                    .reduce((acc, p) => acc + p.quantidadePesagem, 0);
                const volumeQSP = volumePai >= volumeAtivos ? volumePai - volumeAtivos : 0;
                const indiceConversaoQSP =
                    await this.manipulacaoContext.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                        manipulacaoItem.unidadeMedidaManipulacaoId,
                        manipulacaoItem.unidadeMedidaId,
                        manipulacaoItem.densidade,
                        manipulacaoItem.produtoId,
                        manipulacaoItem.produtoLoteId,
                    );

                manipulacaoItem.quantidadeDose = 1;
                manipulacaoItem.quantidadePesagem = volumeQSP * indiceConversaoQSP;
                manipulacaoItem.quantidade = Math.round(volumeQSP * 10000) / 10000;
                return manipulacaoItem;
            case TiposCalculoManipulacao.Dose:
                quantidade = calculos.tipoCalculo.dose(manipulacaoItem, this.manipulacaoContext);
                break;
            default:
                quantidade = calculos.tipoCalculo.nenhum(manipulacaoItem);
        }

        quantidade = quantidade * (Number(this.manipulacaoContext.manipulacao.numeroFormulas) ?? 1);
        manipulacaoItem.quantidade = Math.round(quantidade * 10000) / 10000;

        const quantidadeUnidadeEstoque = quantidade * indiceConversao;
        manipulacaoItem.quantidadePesagem =
            quantidadeUnidadeEstoque < 0.0001 && quantidadeUnidadeEstoque > 0
                ? 0.0001
                : Math.round(quantidadeUnidadeEstoque * 10000) / 10000;

        return manipulacaoItem;
    }

    private getNewItemToFormula(formulaPadraoItem: FormulaPadraoItemModel, isDesmembrado = false) {
        const manipulacaoItem = new ManipulacaoOrdemItemModel();

        manipulacaoItem.updateFrom(this.model, ["id"]);
        manipulacaoItem.manipulacaoOrdemId = this.model.id;

        manipulacaoItem.unidadeMedidaManipulacaoId = formulaPadraoItem.unidadeMedidaManipulacaoId;
        manipulacaoItem.unidadeMedidaManipulacaoDecricao = this.unidadeMedidaOptions.filter(
            option => option["value"] == this.model.unidadeMedidaManipulacaoId,
        )[0]["text"];
        manipulacaoItem.produtoId = formulaPadraoItem.produtoId;
        manipulacaoItem.valor = 1;
        manipulacaoItem.formulaPadraoId = formulaPadraoItem.formulaPadraoId;

        manipulacaoItem.valorTotal = 1;
        if (isDesmembrado) {
            manipulacaoItem.produtoPai = formulaPadraoItem.formulaPadrao.produtoId;
            manipulacaoItem.produtoDescricao = `${this.getNivelDesmembrado(manipulacaoItem.produtoPai)}${
                formulaPadraoItem.produto.descricao
            }`;
        } else {
            manipulacaoItem.produtoDescricao = formulaPadraoItem.produtoDescricao;
            manipulacaoItem.produtoPai = this.formulaPadraoModel.produtoId;
        }
        manipulacaoItem.tipoItem = tiposItemManipulacao.MateriaPrima;
        manipulacaoItem.movimentaEstoque = true;
        manipulacaoItem.tipoCalculo = formulaPadraoItem.tipoCalculo;
        manipulacaoItem.volumeDose = formulaPadraoItem.volumeDose;
        manipulacaoItem.quantidadeDose = formulaPadraoItem.quantidadeDose;
        manipulacaoItem.quantidade = formulaPadraoItem.quantidadeDose;
        manipulacaoItem.quantidadePesagem = formulaPadraoItem.quantidadeDose;

        return manipulacaoItem;
    }

    private getNivelDesmembrado(paiId: number) {
        this.nivelItemDesmembrado = 0;
        this.hasItemFormulaPai(paiId);

        let text = "";
        for (let index = 0; index < this.nivelItemDesmembrado; index++) {
            text += "           ";
        }
        return text;
    }

    private hasItemFormulaPai(paiId: number) {
        const pai = this.itensDesemembradosFormulaBase.find(p => p.produtoId == paiId);
        if (pai) {
            this.nivelItemDesmembrado++;
            this.hasItemFormulaPai(pai.produtoPai);
        }
    }

    private addItemDesmembradoFormulaBase(manipulacaoItem: ManipulacaoOrdemItemModel, addItem = true) {
        if (addItem) {
            manipulacaoItem.manipulacaoOrdemItemProdutoPaiId = this.model.produtoId;
            this.itensDesemembradosFormulaBase.push(manipulacaoItem);
        } else {
            this.itensDesemembradosFormulaBase
                .filter(item => item.produtoId == manipulacaoItem.produtoId)
                .forEach(item => {
                    item.unidadeMedidaManipulacaoId = manipulacaoItem.unidadeMedidaManipulacaoId;
                    item.unidadeMedidaManipulacaoDecricao = manipulacaoItem.unidadeMedidaManipulacaoDecricao;
                    item.quantidadeDose = manipulacaoItem.quantidadeDose;
                    item.quantidade = manipulacaoItem.quantidade;
                    item.quantidadePesagem = manipulacaoItem.quantidadePesagem;
                    item.formulaPadraoItemId = manipulacaoItem.formulaPadraoItemId;
                    item.formulaPadraoItemQuantidadeDose = manipulacaoItem.formulaPadraoItemQuantidadeDose;
                    item.manipulacaoOrdemItemProdutoPaiId = this.model.produtoId;

                    if (item.id > 0) {
                        manipulacaoItem.id = item.id;
                    }
                });
        }
    }

    private async carregarFormulaPadrao() {
        this.loadManipulacaoContext();

        try {
            const data = await this.formulaPadraoService
                .get(this.model.formulaPadraoId)
                .resolveWithJSON<FormulaPadraoModel>();

            this.formulaPadraoModel.updateFrom(data);

            this.model.produtoId = data.produtoId;
            this.model.produtoDescricao = data.produtoDescricao;

            this.model.valor = 0;
            if (data.produto.valorVenda) {
                this.model.valor = data.produto.valorVenda;
            }

            this.model.fatorCorrecao = null;
            if (data.produto.fatorCorrecao) {
                this.model.fatorCorrecao = data.produto.fatorCorrecao;
            }

            this.model.densidade = 1;
            if (data.produto.densidade) {
                this.model.densidade = data.produto.densidade;
            }

            if (data.produto.fatorUTR) {
                this.model.fatorUTR = data.produto.fatorUTR;
            }
            if (data.produto.fatorUFC) {
                this.model.fatorUFC = data.produto.fatorUFC;
            }

            if (data.produto.tipoCalculo) {
                // Se item não é QSP, mas está configurado como QSP e já tem QSP na manipulação deve manter o tipo de cálculo
                const manterTipoCalculo =
                    this.model.tipoCalculo &&
                    this.model.tipoCalculo != TiposCalculoManipulacao.Qsp &&
                    data.produto.tipoCalculo == TiposCalculoManipulacao.Qsp &&
                    this.manipulacao.itens.some(p => p.tipoCalculo == TiposCalculoManipulacao.Qsp);

                if (!manterTipoCalculo) {
                    this.model.tipoCalculo = data.produto.tipoCalculo;
                }
            }

            this.model.unidadeMedidaId = data.produto.unidadeMedidaEstoqueId;
            this.model.unidadeMedidaManipulacaoId = data.produto.unidadeMedidaManipulacaoId;
        } catch {}
    }

    //@ts-ignore
    @Watch("manipulacao.volumeTotal")
    private async onVolumeChanged() {
        this.calcularQuantidadeTotal();

        if (this.model.tipoCalculoAssociacao == TipoCalculoAssociacao.VolumeTotal) {
            await this.calculaQuantidadeAssociacaoVolumeTotal(this.model);
        }
    }

    //@ts-ignore
    @Watch("manipulacao.quantidade")
    private async onQuantidadeDosesChanged() {
        this.loadManipulacaoContext();
        if (this.manipulacao.volumeTotal > 0) return;

        if (this.model.tipoCalculo == TiposCalculoManipulacao.Dose) {
            await this.calculaDoseReplicaParaAtivos();
        } else {
            this.calcularQuantidadeTotal();
        }
    }

    //@ts-ignore
    @Watch("model.fatorEquivalencia")
    private onFatorEquivalenciaChanged() {
        this.calcularQuantidadeTotal();
    }

    //@ts-ignore
    @Watch("model.fatorCorrecao")
    private onFatorCorrecaoChanged() {
        this.calcularQuantidadeTotal();
    }

    //@ts-ignore
    @Watch("model.quantidadeDose")
    private async onQuantidadeDoseChanged() {
        this.loadManipulacaoContext();

        if (!this.manipulacaoContext.uploadingEditingModel) {
            await this.verificaDiluido();
        }

        if (this.model.tipoCalculo == TiposCalculoManipulacao.Dose) {
            await this.calculaDoseReplicaParaAtivos();
        } else {
            this.calcularQuantidadeTotal();
        }

        if (this.itemType == ItemTableType.CAPSULA && this.model.quantidadeDose > 0) {
            if (this.model.quantidadeDose % this.manipulacao.quantidade != 0) {
                this.$showWarning(
                    this.$t("__.ts.atencao"),
                    `Quantidade precisa ser multiplo de ${this.manipulacao.quantidade} para que não ocorra problema nos volumes dos ativos!`,
                );
            }

            this.manipulacao.fatorMultQuantidade = Math.round(this.model.quantidadeDose / this.manipulacao.quantidade);
            this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.manipulacao });
        }
    }

    private async verificaDiluido() {
        //busca do puro para o diluido
        if (this.model.quantidadeDose > 0 && this.model.doseMaximaDiluido > 0 && this.model.produtoDiluidoId > 0) {
            if (this.produtosOptions.filter(p => p["value"] == this.model.produtoDiluidoId).length == 0) return;

            if (this.unidadeMedidaIdProduto == this.model.unidadeMedidaManipulacaoId) {
                if (this.model.quantidadeDose <= this.model.doseMaximaDiluido) {
                    const trocarProd = await this.perguntaProdutoDiluido();
                    if (trocarProd) {
                        this.produtoId = this.model.produtoDiluidoId;
                    }
                }
            } else {
                //busca conversao antes
                const idxConversao =
                    await this.manipulacaoContext.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                        this.model.unidadeMedidaManipulacaoId,
                        this.unidadeMedidaIdProduto,
                    );

                const quantidadeComparar = this.model.quantidadeDose * idxConversao;
                if (quantidadeComparar <= this.model.doseMaximaDiluido) {
                    const trocarProd = await this.perguntaProdutoDiluido();
                    if (trocarProd) {
                        this.produtoId = this.model.produtoDiluidoId;
                    }
                }
            }
        }
        //busca do diluido para o puro
        else if (
            this.model.quantidadeDose > 0 &&
            this.produtoPaiPuro != null &&
            this.produtoPaiPuro.doseMaximaDiluido
        ) {
            if (this.produtoPaiPuro.unidadeMedidaManipulacaoId == this.model.unidadeMedidaManipulacaoId) {
                if (this.model.quantidadeDose > this.produtoPaiPuro.doseMaximaDiluido) {
                    const trocarProd = await this.perguntaProdutoDiluido(true);
                    if (trocarProd) {
                        this.produtoId = this.produtoPaiPuro.id;
                    }
                }
            } else {
                //busca conversao antes
                const idxConversao =
                    await this.manipulacaoContext.indicesConversoesUnidadeMedidasService.getIndiceConversao(
                        this.model.unidadeMedidaManipulacaoId,
                        this.produtoPaiPuro.unidadeMedidaManipulacaoId,
                    );

                const quantidadeComparar = this.model.quantidadeDose * idxConversao;
                if (quantidadeComparar > this.produtoPaiPuro.doseMaximaDiluido) {
                    const trocarProd = await this.perguntaProdutoDiluido(true);
                    if (trocarProd) {
                        this.produtoId = this.produtoPaiPuro.id;
                    }
                }
            }
        }
    }

    private async perguntaProdutoDiluido(diluido = false) {
        const prodPuroDiluido = diluido ? this.$t("__.ts.puro") : this.$t("__.ts.diluido");

        const question = `${this.$t("__.ts.sisteIdentifProd")}${prodPuroDiluido}${this.$t(
            "__.ts.paraEssaQtdDose",
        )}${prodPuroDiluido}?`;

        return await this.$showQuestion(`${this.$t("__.ts.prod")} ${prodPuroDiluido}!`, question);
    }

    //@ts-ignore
    @Watch("model.valor")
    private onValorChanged() {
        this.calcularQuantidadeTotal();
    }

    //@ts-ignore
    @Watch("model.quantidade")
    private async onQuantidadeChanged(newValue: number, oldValue: number) {
        if (
            newValue &&
            oldValue &&
            newValue != oldValue &&
            this.model.tipoItem != TiposItemManipulacao.Capsula &&
            this.model.tipoItem != TiposItemManipulacao.KitEmbalagem
        ) {
            this.manipulacao.consolidado = false;
            this.dispatch(ManipulacaoContextActions.UPDATE_MANIPULACAO, { ...this.manipulacao });
        }

        if (this.isFormulaPadrao && this.model.tipoCalculo != TiposCalculoManipulacao.Qsp) {
            await this.loadModelByFormulaPadrao();
        } else if (this.model.tipoCalculo != TiposCalculoManipulacao.Qsp) {
            this.calcularQuantidadeTotal();
        }
    }

    //@ts-ignore
    @Watch("model.volumeDose")
    private async onVolumeDoseChanged() {
        this.calcularQuantidadeTotal();
    }

    private async onChangeVolumeDose(newValue: number) {
        await this.$notifyWarn(this.$t("__.ts.ocorreuTrocaManual"), this.$t("__.ts.trocaDose"));
        await this.dispatch(ManipulacaoContextActions.REPLICA_DOSE_ATIVOS, newValue);

        this.calcularQuantidadeTotal();
    }

    //@ts-ignore
    @Watch("model.unidadeMedidaManipulacaoId")
    private async onUnidadeMedidaManipulacaoChanged(newValue: number, oldValue: number) {
        const unMedManipulacao = this.unidadeMedidaOptions.filter(
            p => p["value"] == this.model.unidadeMedidaManipulacaoId,
        );

        if (unMedManipulacao.length > 0) {
            const unidadeUI = unMedManipulacao[0]["type"] == UnidadesMedida.UI;
            if (unidadeUI && (this.model.fatorUI == null || this.model.fatorUI == 0)) {
                await this.$showError(this.$t("__.ts.fatorUI"), this.$t("__.ts.naoHaFtrUI"));

                this.model.unidadeMedidaManipulacaoId = oldValue;
            }
        }

        this.calcularQuantidadeTotal();

        if (this.model.tipoCalculo == TiposCalculoManipulacao.Dose) {
            await this.calculaDoseReplicaParaAtivos();
        }
    }

    //@ts-ignore
    @Watch("model.tipoCalculo")
    private async onTipoCalculoChanged(newValue: number, oldValue: number) {
        switch (newValue) {
            case TiposCalculoManipulacao.Qsp:
                this.model.quantidadeDose = 1;
                this.model.unidadeMedidaManipulacaoId = this.model.unidadeMedidaId;
                break;
            case TiposCalculoManipulacao.Dose:
                await this.onChangeTiposCalculoManipulacaoDose();
                break;
            case TiposCalculoManipulacao.UTRPercentual:
                this.setUnidadeMedidaUTR(UnidadesMedida.UTR);
                break;
            case TiposCalculoManipulacao.DH:
            case TiposCalculoManipulacao.CH:
                await this.onChangeTiposCalculoDinamizado();
                break;
            default:
        }

        this.calcularQuantidadeTotal();

        if (newValue == tiposCalculoManipulacao.Dose || oldValue == tiposCalculoManipulacao.Dose) {
            this.$emit("onRefazerHeader");
        }
    }

    //@ts-ignore
    @Watch("manipulacao.unidadeMedidaId")
    private onUnidadeMedidaTotalChanged() {
        this.model.unidadeMedidaDoseId = this.manipulacao.unidadeMedidaId;
    }

    //@ts-ignore
    @Watch("manipulacaoContext.formaFarmaceutica.desconsiderarDensidade")
    private onChangeDesconsiderarDensidade() {
        if (this.manipulacaoContext.formaFarmaceutica.desconsiderarDensidade) {
            this.model.densidade = 1;
        } else {
            this.model.densidade = this.produto.densidade ?? 0;
        }
    }

    //@ts-ignore
    @Watch("model.fatorUI")
    private onFatorUIChanged() {
        this.calcularQuantidadeTotal();
    }

    private async setUnidadeMedidaUTR(tipoUnidadeMedida: UnidadesMedida) {
        if (!this.model.fatorUTR) {
            return this.$showError(this.$t("__.ts.atencao"), "Este item não tem o 'Fator UTR' informado!");
        }

        const unidade = this.unidadeMedidaOptions.filter(p => p["type"] == tipoUnidadeMedida);

        if (unidade.length > 0) {
            this.model.unidadeMedidaManipulacaoId = unidade[0]["value"];
        } else {
            return this.$showError(this.$t("__.ts.atencao"), "Unidade de medida 'UTR' não encontrada!");
        }
    }

    private calcularQuantidadeTotal() {
        this.loadManipulacaoContext();

        // Se está na replicação de venda deve forçar os calculos
        if (
            !this.$route.params.vendaReplicada &&
            (this.manipulacaoContext.uploadingEditingModel || this.manipulacaoContext.isDesabilitado)
        ) {
            return;
        }

        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.calcularQuantidadeTotalInterno(), this.loading ? 400 : 100);
    }

    private async calcularQuantidadeTotalInterno() {
        await this.dispatch(ManipulacaoContextActions.CALCULAR_QUANTIDADES_ITEM, {
            type: this.itemType,
            index: this.index,
            item: this.model,
        });

        if (this.manipulacaoContext.ativos.length == 1 || this.model.tipoCalculo != TiposCalculoManipulacao.Qsp) {
            await this.dispatch(ManipulacaoContextActions.CALCULAR_QSP, {
                loadDadosLote: false,
                ignoreDebounce: false,
            });
        }

        if (this.isFormulaPadrao && this.model.tipoCalculo != TiposCalculoManipulacao.Qsp) {
            await this.loadModelByFormulaPadrao();
        }
    }

    public async calculaDoseReplicaParaAtivos() {
        await this.dispatch(ManipulacaoContextActions.ADD_ITEM_MANIPULACAO, {
            item: this.model,
            type: this.itemType,
            index: this.index,
        });

        if (!this.model.quantidadeDose || this.model.produtoAssociadoPaiId) {
            return;
        }

        await this.dispatch(ManipulacaoContextActions.CALCULA_DOSE, null);

        this.calcularQuantidadeTotal();
    }

    private async onChangeTiposCalculoManipulacaoDose() {
        this.loadManipulacaoContext();

        const dose = this.manipulacaoContext.ativos.find(p => p.tipoCalculo == TiposCalculoManipulacao.Dose);
        if (dose && dose.volumeDose > 0) {
            await this.dispatch(ManipulacaoContextActions.REPLICA_DOSE_ATIVOS, dose.volumeDose);
        }

        await this.calculaDoseReplicaParaAtivos();
    }

    private async onChangeTiposCalculoDinamizado() {
        const data = await this.produtoLoteService
            .getListDinamizado(this.produtoId)
            .withLoading()
            .resolveWithJSON<ProdutoLoteModel[]>();

        this.lotesOptions = data.map(item => ({ value: item.id, text: item.descricao }));

        this.model.unidadeMedidaManipulacaoId = this.unidadeMedidaOptions.find(p => p["text"] === "gt")["value"];
        this.model.quantidadeDose = 4;
    }

    get showDose() {
        return this.model.tipoCalculo == TiposCalculoManipulacao.Dose;
    }

    private getTooltipValorFinal() {
        const valorStr = this.model.valor.toFixed(4).toString().replace(".", ",");
        return this.$t("__.ts.vlrUnit") + " R$" + valorStr;
    }

    private getTipoCalculoName(tipo: tiposCalculoManipulacao) {
        if (tipo != null && tipo != 0) {
            const tipoCalculo = this.tiposCalculoOptions.filter(p => p["value"] == tipo)[0]["text"];
            return `${this.$t("__.ts.quantidade")} ${tipoCalculo}`;
        }
        return "";
    }

    private getTootipQtdTotal(tipo: tiposCalculoManipulacao) {
        let qtdStr = "";
        switch (tipo) {
            case TiposCalculoManipulacao.Unitario:
                qtdStr = this.$t("__.ts.qtdCapsMultQtdForma") as string;
                break;
            case TiposCalculoManipulacao.Percentual:
                qtdStr = this.$t("__.ts.qtdPercentVolForma") as string;
                break;
            case TiposCalculoManipulacao.Qsp:
                qtdStr = this.$t("__.ts.qtdSuficComplForma") as string;
                break;
            case TiposCalculoManipulacao.Dose:
                qtdStr = this.$t("__.ts.qtdTotalRepresMultQtd") as string;
                break;
            default:
                qtdStr = this.$t("__.ts.semCalcEspecifQtdIgualQtd") as string;
        }

        let obs =
            this.model.fatorCorrecao > 0
                ? this.$t("__.ts.obsPossuiFtrCorrec") + this.model.fatorCorrecao.toString()
                : "";

        if (this.model.densidade > 0) {
            obs += `. Possui densidade igual a ${this.model.densidade.toString()}.`;
        }

        if (this.model.fatorUI > 0) {
            const texto = obs != "" ? `${obs}${this.$t("__.ts.eP")}` : (this.$t("__.ts.obsP") as string);
            obs = texto + this.$t("__.ts.ossuiFtrIgual") + this.model.fatorUI.toString();
        }

        if (this.model.fatorEquivalencia > 0) {
            obs += `. Possui fator de equivalência igual a ${this.model.fatorEquivalencia.toString()}.`;
        }

        return qtdStr + obs + (obs != "" ? "." : "");
    }

    private getTooltipUnidadeMedidaDose(unDose: number) {
        this.loadManipulacaoContext();

        if (unDose != null && unDose > 0) {
            const unMed = this.unidadeMedidaOptions.filter(p => p["value"] == this.manipulacao.unidadeMedidaId);
            if (unMed.length > 0) {
                return this.unidadeMedidaOptions.filter(p => p["value"] == this.manipulacao.unidadeMedidaId)[0]["text"];
            }
        }

        return "";
    }

    private getSignalQuantidade(tipo: tiposCalculoManipulacao, total: boolean, campoQtdDose = false) {
        this.loadManipulacaoContext();

        if (!total && this.itemType == ItemTableType.ATIVO) {
            switch (tipo) {
                case TiposCalculoManipulacao.Unitario:
                    if (
                        !campoQtdDose &&
                        this.model.unidadeMedidaId > 0 &&
                        this.unidadeMedidaOptions.some(p => p["value"] == this.model.unidadeMedidaManipulacaoId)
                    )
                        return this.getSiglaUnidadeMedida(this.model.unidadeMedidaManipulacaoId);
                    return "";
                case TiposCalculoManipulacao.Percentual:
                    if (total && this.unidadeMedidaOptions.some(p => p["value"] == this.manipulacao.unidadeMedidaId))
                        return this.getSiglaUnidadeMedida(this.manipulacao.unidadeMedidaId);
                    return "%";
                case TiposCalculoManipulacao.Nenhum:
                    if (
                        !campoQtdDose &&
                        this.unidadeMedidaOptions.some(p => p["value"] == this.model.unidadeMedidaManipulacaoId)
                    )
                        return this.getSiglaUnidadeMedida(this.model.unidadeMedidaManipulacaoId);
                    return "";
                case TiposCalculoManipulacao.Dose:
                    if (
                        this.model.unidadeMedidaId > 0 &&
                        total &&
                        this.unidadeMedidaOptions.some(p => p["value"] == this.model.unidadeMedidaManipulacaoId)
                    )
                        return this.getSiglaUnidadeMedida(this.model.unidadeMedidaManipulacaoId);
                    return "";
                case TiposCalculoManipulacao.Qsp:
                    if (
                        this.model.unidadeMedidaId > 0 &&
                        total &&
                        this.unidadeMedidaOptions.some(p => p["value"] == this.model.unidadeMedidaManipulacaoId)
                    )
                        return this.getSiglaUnidadeMedida(this.model.unidadeMedidaManipulacaoId);
                    return "";
                default:
                    return "";
            }
        } else {
            if (this.unidadeMedidaOptions.some(p => p["value"] == this.model.unidadeMedidaId)) {
                return this.getSiglaUnidadeMedida(this.model.unidadeMedidaId);
            }
        }
        return "";
    }

    private getSiglaUnidadeMedida(value: number) {
        return this.unidadeMedidaOptions.filter(p => p["value"] == value)[0]["text"];
    }

    private tabIndex(valor) {
        if (valor != null && valor > 0) return -1;
        return null;
    }

    private onCancelar() {
        this.clear();
    }

    private async openComboVerProduto() {
        while (!this.$refs.produtoVerComponent) await Delay(5);
        this.produtoVerComponent = this.$refs.produtoVerComponent as ProdutoVerComponent;

        this.produtoVerComponent.load(this.model.produtoId, this.model.quantidadePesagem);
    }

    private async loadProduto() {
        if (
            !this.produto?.id &&
            this.model.produtoId &&
            (this.produto == null || this.produto.id !== this.model.produtoId)
        ) {
            try {
                this.produto = await this.produtoService.get(this.model.produtoId).resolveWithJSON<ProdutoModel>();
            } catch {}
        }

        this.loadProdutoPaiPuro();
    }

    private setUnidadeMedidaCalculoPercentual(item: ManipulacaoOrdemItemModel) {
        this.loadManipulacaoContext();

        item.unidadeMedidaManipulacaoId = this.manipulacao.unidadeMedidaId;

        if (item.unidadeMedidaManipulacaoId > 0) {
            const unidadesMedida = this.unidadeMedidaOptions.filter(p => p["value"] == item.unidadeMedidaManipulacaoId);
            if (unidadesMedida.length > 0) {
                item.unidadeMedidaManipulacaoDecricao = unidadesMedida[0]["text"];
            }
        }
        return item;
    }

    @Watch("capsulasOptionsList", { deep: true })
    private onChangeCapsulasOptionsList() {
        this.capsulasOptions = this.capsulasOptionsList;
    }

    @Watch("formulaPadraoList", { deep: true })
    private onChangeFormulaPadraoList() {
        this.formulaPadraoOptions = this.formulaPadraoList;
    }

    @Watch("unidadeMedidaList", { deep: true })
    private onChangeUnidadeMedidaList() {
        this.unidadeMedidaOptions = this.unidadeMedidaList;
    }

    private async dispatch(action: ManipulacaoContextActions, data: unknown) {
        await this.$store.dispatch(action, { uuid: this.uuid, data });

        this.loadManipulacaoContext();
    }

    public loadManipulacaoContext() {
        this.manipulacaoContext = this.GET_MANIPULACAO_CONTEXT(this.uuid);
        this.manipulacao = this.manipulacaoContext.manipulacao;
    }

    public unsubscribeAll() {
        this.unsubscribe();
    }

    private async loadProdutoCoresCapsulas() {
        if (this.model.capsulaId) {
            const data = await this.capsulaService
                .produtosCores(this.model.capsulaId)
                .resolveWithJSON<ProdutoModel[]>();

            this.coresCapsulasOptions = data
                .filter(p => (!this.manipulacao.id ? p.ativo : true))
                .filter(p => p.tipoCapsula == this.manipulacaoContext.formaFarmaceutica.tipoCapsula)
                .map(p => ({ text: p.descricao, value: p.id }));
        }
    }

    private loadProdutoPaiPuro() {
        this.produtoPaiPuro = null;
        this.unidadeMedidaIdProduto = null;

        if (this.produto.id) {
            this.unidadeMedidaIdProduto = this.produto.unidadeMedidaManipulacaoId;

            if (this.produto.produtoPuros != null && this.produto.produtoPuros.length > 0) {
                //verifica se esta na lista
                for (let i = 0; i < this.produto.produtoPuros.length; i++) {
                    const prodPuro = this.produtosOptions.filter(p => p["value"] == this.produto.produtoPuros[i].id);

                    if (prodPuro.length > 0) {
                        this.produtoPaiPuro = new ProdutoModel();
                        this.produtoPaiPuro.updateFrom(this.produto.produtoPuros[i]);
                        break;
                    }
                }
            }
        }
    }

    private async loadValoresProdutoByLote(usaMarkupEquinos?: boolean, manipulacao: ManipulacaoOrdemModel = null) {
        let item = null;
        try {
            if (manipulacao != null && manipulacao.itens.some(p => p.produtoId == this.model.produtoId)) {
                item = manipulacao.itens.find(p => p.produtoId == this.model.produtoId);
            } else {
                this.model.produto = null;
                item = await this.manipulacaoOrdemItemService
                    .loadDadosProdutoLote(
                        this.model,
                        this.manipulacao,
                        usaMarkupEquinos,
                        this.manipulacaoContext.isTransferencia,
                        this.manipulacaoContext.atualizaDadosLote,
                    )
                    .resolveWithJSON<ManipulacaoOrdemItemModel>();
            }

            if (this.manipulacaoContext.formaFarmaceutica.desconsiderarDensidade) {
                item.densidade = 1;
            }

            this.model.densidade = item.densidade;
            this.model.fatorCorrecao = item.fatorCorrecao;
            this.model.fatorUI = item.fatorUI;
            this.model.fatorUFC = item.fatorUFC;
            this.model.fatorUTR = item.fatorUTR;
            this.model.produtoLoteCalculosId = item.produtoLoteCalculosId;
            this.model.valor = item.valor;
        } catch {}
    }

    public remontarFormulaPadraoDesmembrada() {
        if (
            this.isFormulaPadrao &&
            this.model.tipoCalculo == TiposCalculoManipulacao.Qsp &&
            this.manipulacaoContext.isConsolidando
        ) {
            this.loadManipulacaoContext();

            this.loadModelByFormulaPadrao();
        }
    }

    private mounted() {
        this.capsulasOptions = this.capsulasOptionsList;
        this.formulaPadraoOptions = this.formulaPadraoList;
        this.unidadeMedidaOptions = this.unidadeMedidaList;

        this.unsubscribe = this.$store.subscribe(mutation => {
            switch (mutation.type) {
                case ManipulacaoContextMutations.UPDATE_MANIPULACAO:
                case ManipulacaoContextMutations.REMOVE_ATIVOS_ASSOCIADOS:
                    this.loadManipulacaoContext();
                    break;
            }
        });

        Promise.all([this.loadProdutoOptions()])
            .withLoading()
            .then(async () => {
                this.loadManipulacaoContext();

                this.tiposCalculoOptions = [
                    { text: "Nenhum", value: 0 },
                    { text: "Unitário", value: 1 },
                    { text: "Percentual", value: 2 },
                    { text: "Qsp", value: 3 },
                    { text: "Dose", value: 4 },
                    { text: "UTR Percentual", value: 5 },
                    { text: "DH", value: 6 },
                    { text: "CH", value: 7 },
                ];

                if (this.manipulacao.homeopatia) {
                    this.tiposCalculoOptions = this.tiposCalculoOptions.filter(
                        p => p["value"] > 5 || p["value"] == 2 || p["value"] == 3,
                    );
                } else {
                    this.tiposCalculoOptions = this.tiposCalculoOptions.filter(p => p["value"] <= 5);
                }

                this.loading = true;
                if (this.itemModel != null) {
                    this.model.updateFrom(this.itemModel);

                    this.isFormulaPadrao = false;
                    if (this.model.tipoItem == TiposItemManipulacao.FormulaPadrao) {
                        this.isFormulaPadrao = true;
                    }

                    if (this.model.produtoId > 0) {
                        if (this.isFormulaPadrao) {
                            this.itensDesemembradosFormulaBase = [];

                            let itensByFormula = this.manipulacaoContext.desmembrados.filter(
                                p => p.formulaPadraoId == this.model.formulaPadraoId,
                            );

                            itensByFormula = [
                                ...itensByFormula,
                                ...this.manipulacaoContext.desmembrados.filter(
                                    p =>
                                        itensByFormula.map(i => i.produtoId).includes(p.produtoPai) &&
                                        !itensByFormula.map(i => i.produtoId).includes(p.produtoId),
                                ),
                            ];

                            itensByFormula.forEach(p => {
                                p.produtoDescricao = `${this.getNivelDesmembrado(p.produtoPai)}${p.produtoDescricao}`;
                                p.manipulacaoOrdemItemProdutoPaiId = this.model.produtoId;
                                this.itensDesemembradosFormulaBase.push(p);
                            });
                        }

                        if (this.manipulacao.isPreVendaEditavel && this.manipulacao.formulaPadraoId > 0) {
                            this.produto = this.model.produto;
                        }

                        await this.loadProduto().withLoading();
                    } else {
                        this.model.unidadeMedidaManipulacaoId = this.manipulacao.unidadeMedidaId;
                        this.model.unidadeMedidaId = this.manipulacao.unidadeMedidaId;
                    }
                } else {
                    this.model.tipoItem = (this.itemType + 1) as TiposItemManipulacao;
                }

                setTimeout(() => {
                    this.isFlavorizante = this.model.subGrupoItem == SubGrupoProduto.Flavorizante;

                    if (this.isFlavorizante || this.itemType != ItemTableType.ATIVO) {
                        this.loadProdutoOptions();
                    }
                }, 250);

                this.loading = false;
            })
            .catch(() => {});
    }
}
