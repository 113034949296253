import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ConfiguracaoAtendimentoHorario from "@/models/configuracaoAtendimentoHorarioModel";
import ConfiguracaoAtendimentoModel from "@/models/configuracaoAtendimentoModel";
import TipoHorarioAtendimento from "@/models/enum/TipoHorarioAtendimento";
import ConfiguracaoAtendimentoService from "@/services/configuracaoAtendimentoService";

import EditComponentBase from "../editComponentBase";

import "../crud.scss";

@Component({
    components: {
        gridComponent,
        comboComponent,
        fieldsetComponent,
        buttonIncluirComponent,
        actionBarComponent,
    },
})
export default class ConfiguracaoAtendimentoEditComponent extends EditComponentBase<ConfiguracaoAtendimentoModel> {
    horarioInicioString: string = null;
    horarioFimString: string = null;
    tipoHorario = 0;

    tiposOptions: Array<Object> = [
        { text: "Semanal", value: TipoHorarioAtendimento.Semanal },
        { text: "Sábado", value: TipoHorarioAtendimento.Sabado },
        { text: "Domingo", value: TipoHorarioAtendimento.Domingo },
    ];

    gridColums: Array<GridColumn> = [
        new GridColumn("tipoDescricao", "Tipo", GridColumnType.String),
        new GridColumn("horarioInicioString", "Horário Inicio", GridColumnType.String),
        new GridColumn("horarioFimString", "Horário Fim", GridColumnType.String),
    ];

    private dateParaString(horario: Date): string {
        const horas = horario.getHours().toString().padStart(2, "0");
        const minutos = horario.getMinutes().toString().padStart(2, "0");
        return `${horas}:${minutos}`;
    }

    private async onAdicionaHorario() {
        const isValid = await this.$validator.validateAll();
        if (isValid) {
            const hasAtendimento = this.model.configuracaoAtendimentoHorario.some(
                p =>
                    this.tipoHorario == p.tipo &&
                    ((this.horarioInicioString >= this.dateParaString(p.horarioInicio) &&
                        this.horarioInicioString < this.dateParaString(p.horarioFim)) ||
                        (this.tipoHorario == p.tipo &&
                            this.horarioFimString > this.dateParaString(p.horarioInicio) &&
                            this.horarioFimString <= this.dateParaString(p.horarioFim)) ||
                        (this.tipoHorario == p.tipo &&
                            this.horarioInicioString <= this.dateParaString(p.horarioInicio) &&
                            this.horarioFimString > this.dateParaString(p.horarioInicio) &&
                            this.horarioFimString <= this.dateParaString(p.horarioFim))),
            );

            if (!hasAtendimento) {
                const horario = new ConfiguracaoAtendimentoHorario();
                horario.tipo = this.tipoHorario;
                horario.tipoDescricao = this.tiposOptions.find(item => item["value"] == this.tipoHorario)["text"];

                horario.horarioInicio = new Date();
                horario.horarioInicioString = this.horarioInicioString;
                horario.horarioInicio.setHours(Number(this.horarioInicioString.split(":")[0]));
                horario.horarioInicio.setMinutes(Number(this.horarioInicioString.split(":")[1]));
                horario.horarioInicio.setSeconds(0, 0);

                horario.horarioFim = new Date();
                horario.horarioFimString = this.horarioFimString;
                horario.horarioFim.setHours(Number(this.horarioFimString.split(":")[0]));
                horario.horarioFim.setMinutes(Number(this.horarioFimString.split(":")[1]));
                horario.horarioFim.setSeconds(0, 0);

                this.model.configuracaoAtendimentoHorario.push(horario);

                this.model.configuracaoAtendimentoHorario.sort((a, b) => {
                    if (a.tipo < b.tipo) return -1;
                    if (a.tipo > b.tipo) return 1;

                    const horarioInicioA = new Date(a.horarioInicio).getTime();
                    const horarioInicioB = new Date(b.horarioInicio).getTime();

                    return horarioInicioA - horarioInicioB;
                });

                this.horarioInicioString = "";
                this.horarioFimString = "";
            } else {
                return this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.voceJaPossuiHorario"));
            }
        } else {
            this.$focusErrorField();
        }
    }

    private onRemover(model: ConfiguracaoAtendimentoHorario) {
        this.model.configuracaoAtendimentoHorario = this.model.configuracaoAtendimentoHorario.filter(p => p != model);
    }

    private padLeft(value: number) {
        const valueString = value.toString();
        return valueString.length == 1 ? "0" + valueString : valueString;
    }

    public async save() {
        try {
            const response = await this.service[!this.model["id"] ? "insert" : "update"](this.model)
                .withLoading()
                .resolveWithResponse();

            if (response.ok) {
                await this.$showInclusaoUpdate(this.model["id"]);

                this.$router.back();
            }

            return false;
        } catch {}
    }

    public async afterMounted() {
        if (!this.usuarioLogado.franquia.moduloWhatsApp) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.franquiaNaoModuloContratado"));

            this.$router.back();
        }

        try {
            const data = await new ConfiguracaoAtendimentoService()
                .getByFranquia()
                .withLoading()
                .resolveWithJSON<ConfiguracaoAtendimentoModel>();

            if (data) {
                data.configuracaoAtendimentoHorario = data.configuracaoAtendimentoHorario
                    .sort((a, b) => {
                        if (a.tipo < b.tipo) return -1;
                        if (a.tipo > b.tipo) return 1;

                        const horarioInicioA = new Date(a.horarioInicio).getTime();
                        const horarioInicioB = new Date(b.horarioInicio).getTime();

                        return horarioInicioA - horarioInicioB;
                    })
                    .map(p => {
                        const model = new ConfiguracaoAtendimentoHorario();
                        model.updateFrom(p);

                        const dateInicio = new Date(p.horarioInicio);
                        model.horarioInicioString = `${this.padLeft(dateInicio.getHours())}:${this.padLeft(
                            dateInicio.getMinutes(),
                        )}`;

                        const dateFim = new Date(p.horarioFim);
                        model.horarioFimString = `${this.padLeft(dateFim.getHours())}:${this.padLeft(
                            dateFim.getMinutes(),
                        )}`;

                        return model;
                    });
            }

            this.model.updateFrom(data);
        } catch {}
    }

    private async beforeMount() {
        await this.setProps(
            new ConfiguracaoAtendimentoService(),
            "configuracao_atendimento",
            new ConfiguracaoAtendimentoModel(),
        );
    }
}
