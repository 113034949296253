import ConfiguracaoImpressaoModel from "@/models/configuracaoImpressaoModel";

import BaseModel from "../../base/baseModel";
import FiltersRelatorioModel from "../filtersRelatorioModel";

export default class RelatorioBaseModel extends BaseModel {
    id?: number = null;
    campos: string = null;
    nomeRelatorio: string = null;
    nomeExibicao: string = null;
    descricao: string = null;
    filters: Array<FiltersRelatorioModel> = [];
    exportacao: ConfiguracaoImpressaoModel;
}
