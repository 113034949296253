var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortCutComponent",
      attrs: { btOkName: "Avançar", modalClass: "ss-modal", showAddNew: false },
      on: { "selection-item": _vm.onTrocarAtendente },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
          _c("form", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.errors.first("usuarioId"),
                        expression: "errors.first('usuarioId')",
                      },
                    ],
                    staticClass: "form-group",
                  },
                  [
                    _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: {
                          for: "usuarioId",
                          title: _vm.$t("__.obrigatorio"),
                        },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.$t(
                                "__.Components.parent.atendimento.trocarAtendente.nomeAtendente"
                              )
                            ) +
                            "\n                            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("combo-component", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        id: "usuarioId",
                        name: "usuarioId",
                        data: _vm.usuariosOptions,
                        searchable: true,
                      },
                      model: {
                        value: _vm.usuarioId,
                        callback: function ($$v) {
                          _vm.usuarioId = $$v
                        },
                        expression: "usuarioId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }