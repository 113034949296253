var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          showSave: !_vm.somenteConsulta,
          title: _vm.$t(
            "__.Crud.templateWhatsapp.edit_vue_html.templateWhatsappEdit"
          ),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c(
            "fieldset",
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("tipo"),
                          expression: "errors.first('tipo')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "tipo",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.templateWhatsapp.edit_vue_html.tipo"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "tipo",
                          name: "tipo",
                          data: _vm.tiposOptions,
                        },
                        model: {
                          value: _vm.model.tipo,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "tipo", $$v)
                          },
                          expression: "model.tipo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row", staticStyle: { "margin-top": "20px" } },
                [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "button",
                          disabled: !_vm.model.tipo || _vm.model.tipo > 5,
                        },
                        on: {
                          click: function ($event) {
                            _vm.onTags()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.$t(
                                "__.Crud.templateWhatsapp.edit_vue_html.addValorCustomizado"
                              )
                            ) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("texto"),
                            expression: "errors.first('texto')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "texto",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.templateWhatsapp.edit_vue_html.texto"
                                  )
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "texto",
                            name: "texto",
                            disabled: _vm.somenteConsulta,
                            rows: "3",
                          },
                          model: {
                            value: _vm.model.texto,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "texto", $$v)
                            },
                            expression: "model.texto",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("tagsComponent", {
                ref: "tagsComponent",
                attrs: { tipo: _vm.model.tipo },
                on: { campo: _vm.onAdicionaCampo },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "submit" },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-save",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.salvar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.somenteConsulta
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.load()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("__.Crud.limpar")) +
                              "\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }