import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import SetorModel from "@/models/setorModel";
import SetorService from "@/services/setorService";

import ListComponentBase from "../listComponentBase";

@Component
export default class SetorListComponent extends ListComponentBase<SetorModel> {
    get gridColumns(): Array<GridColumn> {
        return [new GridColumn("descricao", this.$t("__.ts.descricao"), GridColumnType.String)];
    }

    private beforeMount() {
        this.setProps(new SetorService(), "setor", "descricao");
    }
}
