import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import ListComponentBase from "@/components/parent/crud/listComponentBase";
import { Component } from "@/decorators";
import NotaFiscalServicoEletronicaModel from "@/models/nfse/notaFiscalServicoEletronicaModel";
import NotaFiscalSaidaModel from "@/models/notaFiscalSaidaModel";
import PaginationModel from "@/models/paginationModel";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import NotaFiscalServicoService from "@/services/emissaoNota/notaFiscalServicoEmissaoService";
import NotaFiscalSaidaService from "@/services/notaFiscalSaidaService";

import "@/components/parent/crud/crud.scss";

@Component
export default class NfseListComponent extends ListComponentBase<NotaFiscalSaidaModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("cliente", "Cliente", GridColumnType.String),
            new GridColumn("status", "Status", GridColumnType.String),
            new GridColumn("dataEmissao", "Data da Emissão", GridColumnType.DateTime),
            new GridColumn("numero", "Número", GridColumnType.String),
            new GridColumn("serie", "Série", GridColumnType.String),
            new GridColumn("valor", "Valor", GridColumnType.Money),
        ];
    }

    private beforeMount() {
        this.setProps(new NotaFiscalSaidaService(), "nfse", "dataEmissao", false, "desc");
    }

    private notaFiscalServicoService = new NotaFiscalServicoService();
    private notaFiscalEletronicaEmissaoService = new NotaFiscalEletronicaEmissaoService();

    extraActions: Array<GridAction[]> = [];
    extraActionsPdf: Array<GridAction> = [];
    extraActionsPdfSimplificado: Array<GridAction> = [];
    extraActionsXml: Array<GridAction> = [];
    extraActionsConsultar: Array<GridAction> = [];
    extraActionsGerarCartaCorrecao: Array<GridAction> = [];
    extraActionsCartaCorrecao: Array<GridAction> = [];

    public async loadMethod() {
        return await this.notaFiscalServicoService
            .listNotasServico(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<NotaFiscalSaidaModel>>();
    }

    public async afterLoad() {
        this.extraActions.splice(0);
        this.extraActionsConsultar.splice(0);
        this.extraActionsPdf.splice(0);
        this.extraActionsPdfSimplificado.splice(0);
        this.extraActionsXml.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.extraActionsConsultar.push(
                new GridAction("consultar", "Consultar Nota", "fa fa-search", GridColors.BLUE),
            );

            this.extraActionsPdf.push(new GridAction("abrir-pdf", "Abrir PDF", "fa fa-file-pdf", GridColors.RED));

            this.extraActionsPdfSimplificado.push(
                new GridAction(
                    "abrir-pdf-simplificado",
                    "Abrir PDF Simplificado",
                    "fa fa-file-pdf",
                    GridColors.LIGHTRED,
                ),
            );

            this.extraActionsXml.push(
                new GridAction("abrir-xml", "Download do XML", "fa fa-file-code", GridColors.LIGHTGREEN),
            );
        }

        this.extraActions.push(this.extraActionsConsultar);
        this.extraActions.push(this.extraActionsPdf);
        this.extraActions.push(this.extraActionsPdfSimplificado);
        this.extraActions.push(this.extraActionsXml);
    }

    private onExtraAction(name: string, nota: NotaFiscalServicoEletronicaModel) {
        if (name.trim() == "abrir-pdf") {
            this.notaFiscalEletronicaEmissaoService.openPdf(nota.pdfLink);
        } else if (name.trim() == "abrir-pdf-simplificado") {
            this.notaFiscalEletronicaEmissaoService.openPdf(nota.pdfSimplificadoLink);
        } else if (name.trim() == "abrir-xml") {
            this.notaFiscalEletronicaEmissaoService.downloadXML(nota.xmlLink);
        } else if (name.trim() == "consultar") {
            this.$router.push("/notafiscalsaida-edicao/" + nota.id);
        }
    }
}
