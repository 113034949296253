var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message
    ? _c("div", { staticClass: "message" }, [
        _vm.showOptions()
          ? _c(
              "div",
              {
                class: [
                  "mensagem-options",
                  _vm.message.mensagemPropria ? "mensagem-options-propria" : "",
                ],
              },
              [
                _c("mensagemOptionsComponent", {
                  attrs: { isMensagemPropria: _vm.message.mensagemPropria },
                  on: { onResponder: _vm.onResponder },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.message.mensagemExcluida
          ? _c(
              "span",
              { staticClass: "message-deleted" },
              [
                _c("i", { staticClass: "fa fa-ban" }),
                _vm._v("\n        Mensagem apagada\n\n        "),
                _c("mensagemTempoComponent", {
                  attrs: { message: _vm.message, showStatus: false },
                }),
              ],
              1
            )
          : _vm.message.mensagemEditada
          ? _c("span", { staticClass: "message-edited" }, [
              _c("div", [
                _c("pre", {
                  domProps: { textContent: _vm._s(_vm.message.texto.trim()) },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("Editada")]),
                    _vm._v(" "),
                    _c("mensagemTempoComponent", {
                      attrs: { message: _vm.message, showStatus: !_vm.reply },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm.message.tipoMensagemNaoSuportado
          ? _c(
              "span",
              { staticClass: "message-deleted" },
              [
                _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                _vm._v("\n        Mensagem não suportada\n\n        "),
                _c("mensagemTempoComponent", {
                  attrs: { message: _vm.message, showStatus: false },
                }),
              ],
              1
            )
          : _vm.message.audio != null
          ? _c("div", { staticClass: "audio-container" }, [
              _c(
                "audio",
                {
                  class: [_vm.message.mensagemPropria ? "me" : "them"],
                  attrs: { controls: "", muted: "" },
                },
                [
                  _c("source", {
                    attrs: {
                      src: "data:audio/mpeg;base64," + _vm.message.audio.base64,
                      type: "audio/ogg",
                    },
                  }),
                  _vm._v(" "),
                  _c("source", {
                    attrs: {
                      src: "data:audio/mpeg;base64," + _vm.message.audio.base64,
                      type: "audio/mpeg",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "message-container",
                  staticStyle: { "justify-content": "end" },
                },
                [
                  _c("mensagemTempoComponent", {
                    attrs: { message: _vm.message, showStatus: !_vm.reply },
                  }),
                ],
                1
              ),
            ])
          : _vm.message.imagem != null
          ? _c("div", { staticClass: "imagem-container" }, [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      _vm.openImage(_vm.message.imagem.base64)
                    },
                  },
                },
                [
                  _c("img", {
                    style: _vm.getImageSize(
                      _vm.message.imagem.altura,
                      _vm.message.imagem.largura
                    ),
                    attrs: {
                      src: "data:image/png;base64," + _vm.message.imagem.base64,
                      alt: _vm.message.imagem.legenda,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "message-container" },
                [
                  _c("span", {
                    style: {
                      width: _vm.getImageSize(
                        _vm.message.imagem.altura,
                        _vm.message.imagem.largura
                      ).width,
                    },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.prepareText(_vm.message.imagem.legenda)
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("mensagemTempoComponent", {
                    attrs: { message: _vm.message, showStatus: !_vm.reply },
                  }),
                ],
                1
              ),
            ])
          : _vm.message.documento != null
          ? _c("div", { staticClass: "pdf-container" }, [
              _c("div", { staticClass: "pdf-content" }, [
                _c("embed", {
                  attrs: {
                    src:
                      "data:" +
                      _vm.message.documento.mimeType +
                      ";base64," +
                      _vm.message.documento.base64,
                    type: _vm.message.documento.mimeType,
                    width: "100%",
                    height: "140px",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "pdf-overlay" }, [
                  _c("div", { staticClass: "pdf-info" }, [
                    _c("i", { staticClass: "fas fa-file-pdf" }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.message.documento.nome))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "data:application/pdf;base64," +
                          _vm.message.documento.base64,
                        download: _vm.message.documento.nome,
                      },
                    },
                    [_c("i", { staticClass: "fa fa-arrow-circle-down" })]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "message-container" },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.prepareText(_vm.message.imagem.legenda)
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("mensagemTempoComponent", {
                    attrs: { message: _vm.message, showStatus: !_vm.reply },
                  }),
                ],
                1
              ),
            ])
          : _vm.message.video != null
          ? _c("div", { staticClass: "video-container" }, [
              _c("video", {
                attrs: {
                  controls: "",
                  src:
                    "data:" +
                    _vm.message.video.mimeType +
                    ";base64," +
                    _vm.message.video.base64,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "message-container",
                  staticStyle: { "justify-content": "end" },
                },
                [
                  _c("mensagemTempoComponent", {
                    attrs: { message: _vm.message, showStatus: !_vm.reply },
                  }),
                ],
                1
              ),
            ])
          : _vm.message.figura != null
          ? _c("div", { staticClass: "figura-container" }, [
              _c("img", {
                staticStyle: { width: "110px", height: "110px" },
                attrs: {
                  src:
                    "data:" +
                    _vm.message.figura.mimeType +
                    ";base64," +
                    _vm.message.figura.base64,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "message-container",
                  staticStyle: { "justify-content": "end" },
                },
                [
                  _c("mensagemTempoComponent", {
                    attrs: { message: _vm.message, showStatus: !_vm.reply },
                  }),
                ],
                1
              ),
            ])
          : _vm.message.contato
          ? _c(
              "span",
              { staticClass: "message-contato" },
              [
                _c(
                  "div",
                  {
                    class: [
                      "contact-box",
                      _vm.message.mensagemPropria ? "me" : "them",
                    ],
                  },
                  [
                    _c("div", { staticClass: "contact-info" }, [
                      _c("span", { staticClass: "contact-name" }, [
                        _vm._v(_vm._s(_vm.message.contato.nomeContato)),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "contact-number" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatTelefone(_vm.message.contato.telefone)
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "chat-button",
                        on: {
                          click: function ($event) {
                            _vm.onAddAtendimento(_vm.message.contato.telefone)
                          },
                        },
                      },
                      [_vm._v("Conversar")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("mensagemTempoComponent", {
                  attrs: { message: _vm.message, showStatus: false },
                }),
              ],
              1
            )
          : _vm.message.texto
          ? _c(
              "div",
              { staticClass: "message-container" },
              [
                _c("pre", {
                  domProps: {
                    innerHTML: _vm._s(_vm.prepareText(_vm.message.texto)),
                  },
                }),
                _vm._v(" "),
                _c("mensagemTempoComponent", {
                  attrs: { message: _vm.message, showStatus: !_vm.reply },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }