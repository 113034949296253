import { mapState } from "vuex";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import TipoEtapa from "@/models/enum/tipoEtapa";
import EtapaPCPModel from "@/models/pcp/etapaPCPModel";
import EtapaPCPService from "@/services/etapaPCPService";
import { AppState } from "@/store/store";

import EditComponentBase from "../editComponentBase";

@Component({
    components: {
        checkboxComponent,
        comboComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
    },
})
export default class EtapaEditComponent extends EditComponentBase<EtapaPCPModel> {
    // State computed props
    preLoadList: PreLoadPackModel;

    tipoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(TipoEtapa);
    setorOptions = [];

    private async beforeMount() {
        this.setorOptions = this.preLoadList.setoresCombo();

        await this.setProps(new EtapaPCPService(), "especie_de_animal", new EtapaPCPModel());
    }
}
