export abstract class iServiceAtendimento {
    protected controllerName: string;

    constructor(controllerName: string) {
        this.controllerName = `${process.env.VUE_APP_ROOT_ATENDIMENTO_API}/${controllerName}`;
    }

    public async get(path: string) {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");

        return await fetch(`${this.controllerName}/${path}`, {
            credentials: "same-origin",
            headers: {
                Authorization: `Bearer ${tokenAtendimento}`,
            },
        });
    }

    public async post(path: string, data: object) {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");

        return await fetch(`${this.controllerName}/${path}`, {
            credentials: "same-origin",
            method: "POST",
            headers: {
                Authorization: `Bearer ${tokenAtendimento}`,
                "Content-Type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify(data),
        });
    }

    public async postWithFormData(path: string, body: FormData) {
        const tokenAtendimento = localStorage.getItem("@PharmUP:AtendimentoToken");

        return await fetch(`${this.controllerName}/${path}`, {
            credentials: "same-origin",
            method: "POST",
            headers: {
                Authorization: `Bearer ${tokenAtendimento}`,
            },
            body,
        });
    }
}
