import Vue from "vue";
import { VueTabs, VTab } from "vue-nav-tabs";
import { mapState, mapGetters } from "vuex";

import moment from "moment";

Vue.component("vue-tabs", VueTabs);
Vue.component("v-tab", VTab);

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import dropdownComponent from "@/components/child/dropdown/dropdown.vue";
import buttonScComponent from "@/components/child/form/buttonSc.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import decimalComSinalCustomComponent from "@/components/child/form/decimalComSinalCustom.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnExt, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import { ModalButtonAction } from "@/components/child/modal/modalButtonAction";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import VendasClienteListComponent from "@/components/parent/crud/cliente/listVendas";
import vendasClienteListComponent from "@/components/parent/crud/cliente/listVendas.vue";
import ClientesDuplicadosViewComponent from "@/components/parent/duplicacoes/clientesDuplicados/view";
import clientesDuplicadosViewComponent from "@/components/parent/duplicacoes/clientesDuplicados/view.vue";
import VendaHisticoComponent from "@/components/parent/historico/vendaHistorico/list";
import vendaHisticoComponent from "@/components/parent/historico/vendaHistorico/list.vue";
import { Component, Prop, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import BeneficioModel from "@/models/beneficio/beneficioModel";
import ClienteModel from "@/models/clienteModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import ConfiguracaoUsuarioModel from "@/models/configuracaoUsuarioModel";
import ConvenioModel from "@/models/convenioModel";
import CupomModel from "@/models/cupomModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import ConfiguracoesUsuario from "@/models/enum/configuracao/configuracoesUsuario";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import OrigemAtendimento from "@/models/enum/origemAtendimento";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import StatusEnvioNF from "@/models/enum/statusEnvioNF";
import TipoBeneficio from "@/models/enum/tipoBeneficio";
import TipoConvenioFaixa from "@/models/enum/tipoConvenioFaixa";
import TipoDesconto from "@/models/enum/TipoDesconto";
import TipoNotaFiscal from "@/models/enum/tipoNotaFiscal";
import TipoNota from "@/models/enum/tipoNotaFiscal";
import TipoOperacao from "@/models/enum/tipoOperacao";
import ContaReceberModel from "@/models/financeiro/contaReceberModel";
import ContaReceberRecebimentoModel from "@/models/financeiro/contaReceberRecebimentoModel";
import FranquiaModel from "@/models/franquiaModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import MovimentacaoEstoqueModel from "@/models/movimentacaoEstoqueModel";
import NotaFiscalSaidaModel from "@/models/notaFiscalSaidaModel";
import PaginationModel from "@/models/paginationModel";
import { StatusEstoque } from "@/models/produtoEstoqueConferenciaLoteModel";
import ProdutoEstoqueConferenciaModel from "@/models/produtoEstoqueConferenciaModel";
import CampanhaModel from "@/models/programafidelidade/campanhaModel";
import RecompensaModel from "@/models/programafidelidade/recompensaModel";
import LocalEntregaModel from "@/models/transporte/localEntregaModel";
import UsuarioModel from "@/models/usuarioModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import VendaContinuoModel from "@/models/vendaContinuoModel";
import VendaExtraModel from "@/models/vendaExtraModel";
import VendaItemModel from "@/models/vendaItemModel";
import VendaModel from "@/models/vendaModel";
import VendaPreFaturamentoModel from "@/models/vendaPreFaturamentoModel";
import BeneficioService from "@/services/beneficioService";
import CampanhaService from "@/services/campanhaService";
import ClienteService from "@/services/clienteService";
import ConvenioService from "@/services/convenioService";
import NotaFiscalEletronicaEmissaoService from "@/services/emissaoNota/notaFiscalEletronicaEmissaoService";
import NotaFiscalServicoService, {
    VerificaCancelamentoRetorno,
} from "@/services/emissaoNota/notaFiscalServicoEmissaoService";
import FranquiaService from "@/services/franquiaService";
import MovimentacaoEstoqueService from "@/services/movimentacaoEstoqueService";
import NotaFiscalSaidaService from "@/services/notaFiscalSaidaService";
import { NotaFiscalInfo } from "@/services/notaFiscalSaidaService";
import PDFService from "@/services/pdf/PDFService";
import ProdutoEstoqueConferenciaService from "@/services/produtoEstoqueConferenciaService";
import LocalEntregaService from "@/services/transporte/localEntregaService";
import VendaContinuoUtilsService from "@/services/utils/vendaContinuoUtilsService";
import VendaContinuoService from "@/services/vendaContinuoService";
import VendaService from "@/services/vendaService";
import WebSocketService from "@/services/webSocketService";
import { AppState, Getters, SessionActions } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { getTipoVendaCombo } from "@/utils/common/combo/combotext";
import { copyObject } from "@/utils/common/copyObject";
import { addHours } from "@/utils/common/date";
import Delay from "@/utils/common/delay";
import Bus from "@/utils/events/bus";
import { showErrorsAsync } from "@/wrappers/validationError";

import ChatBotReceitaComponent from "../chatbotReceita/view";
import chatBotReceitaComponent from "../chatbotReceita/view.vue";
import ClienteComponent from "../crud/cliente/edit";
import clienteComponent from "../crud/cliente/edit.vue";
import NfeEditComponent from "../nfe/edit";
import nfeComponent from "../nfe/edit.vue";
import RecebimentoComponent from "../recebimentos/list";
import recebimentoComponent from "../recebimentos/list.vue";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";
import SolicitarSenhaComponent from "../solicitarSenha/view";
import solicitarSenhaComponent from "../solicitarSenha/view.vue";

import AdicionarCupomComponent from "./adicionarCupom";
import adicionarCupomComponent from "./adicionarCupom.vue";
import EnderecoEntregaComponent from "./enderecoEntrega";
import enderecoEntregaComponent from "./enderecoEntrega.vue";
import FinanceiroComponent from "./financeiro/financeiro";
import financeiroComponent from "./financeiro/financeiro.vue";
import PreFaturamentoComponent from "./financeiro/preFaturamento";
import preFaturamentoComponent from "./financeiro/preFaturamento.vue";
import VendaItemWrapperComponent from "./itemwrapper";
import itemwrapperComponent from "./itemwrapper.vue";
import linkPagamentoComponent from "./linkPagamento.vue";
import PagamentoComponent from "./pagamento";
import pagamentoComponent from "./pagamento.vue";
import RevisaoCamposComponent from "./revisaoCampos";
import revisaoCamposComponent from "./revisaoCampos.vue";
import UploadReceitaComponent from "./uploadReceita";
import uploadReceitaComponent from "./uploadReceita.vue";

import "../crud/crud.scss";
import "./progress.scss";
import "./../../../../node_modules/vue-nav-tabs/themes/vue-tabs.css";

class NFContext {
    foiEmitida?: boolean = false;
    emitirAutomaticamente?: boolean = null;
    totalItens?: number = 0;
    onDetails?: () => void;
}

type SaveVendaParams = {
    concluir?: boolean;
    concluirPosEmissaoNota?: boolean;
    forcarEmitirNF?: boolean;
};

@Component({
    components: {
        textareaComponent,
        cpfComponent,
        comboComponent,
        searchComboComponent,
        fieldsetComponent,
        dateTimePickerComponent,
        decimalComponent,
        moedaComponent,
        buttonScComponent,
        recebimentoComponent,
        itemwrapperComponent,
        pagamentoComponent,
        gridComponent,
        checkboxComponent,
        shortcutComponent,
        clienteComponent,
        revisaoCamposComponent,
        linkPagamentoComponent,
        uploadReceitaComponent,
        enderecoEntregaComponent,
        adicionarCupomComponent,
        nfeComponent,
        preFaturamentoComponent,
        decimalComSinalCustomComponent,
        logEntidadeComponent,
        impressaoComponent,
        financeiroComponent,
        actionBarComponent,
        dropdownComponent,
        dataTooltipComponent,
        clientesDuplicadosViewComponent,
        solicitarSenhaComponent,
        vendaHisticoComponent,
        vendasClienteListComponent,
        chatBotReceitaComponent,
    },
    computed: {
        ...mapState<AppState>({
            loadedList: state => state.preLoad.loadedList,
            preLoadList: state => state.preLoad.preLoadList,
            usuarioLogado: state => state.session.usuarioLogado,
        }),
        ...mapGetters([
            "GET_CONFIG_FRANQUIA",
            "CONFIG_FRANQUIA_LOADED",
            "GET_CONFIG_USUARIO",
            "GET_IS_ESTEREIS",
            "GET_IS_FRACIONAMENTO",
            "HAS_PERMISSAO_PERFIL",
            "VALIDAR_PERMISSAO_SOMENTE_CONSULTA",
            "VALIDAR_PERMISSAO_USUARIO",
        ] as Getters),
    },
})
export default class VendaEditSingleComponent extends Vue {
    // State computed props
    loadedList: boolean;
    preLoadList: PreLoadPackModel;
    usuarioLogado: UsuarioModel;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    GET_IS_ESTEREIS: () => Promise<boolean>;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;
    CONFIG_FRANQUIA_LOADED: () => boolean;
    GET_CONFIG_USUARIO: (configuracao: ConfiguracoesUsuario) => ConfiguracaoUsuarioModel;
    HAS_PERMISSAO_PERFIL: (isRede: boolean, isFranqueador: boolean, isSuporte: boolean) => Promise<boolean>;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    VALIDAR_PERMISSAO_SOMENTE_CONSULTA: (telaDescricao: string) => boolean;

    private service = new VendaService();
    private vendaContinuoService = new VendaContinuoService();
    private clienteService = new ClienteService();
    private campanhaService = new CampanhaService();
    private orcamentoPDFService = new PDFService("OrcamentoPDF");
    private notaFiscalEletronicaEmissaoService = new NotaFiscalEletronicaEmissaoService();
    private notaFiscalServicoService = new NotaFiscalServicoService();
    private vendaContinuoUtilsService = new VendaContinuoUtilsService();
    private localEntregaService = new LocalEntregaService();
    private webSocketService = new WebSocketService("ws://localhost:5008/ws/sat");

    private pagamentoComponent: PagamentoComponent = null;
    private vendaItemWrapperComponent: VendaItemWrapperComponent = null;
    private nfeComponent: NfeEditComponent = null;
    private preFaturamentoComponent: PreFaturamentoComponent = null;
    private shortcutComponent: ShortcutComponent = null;
    private clienteComponent1: ClienteComponent = null;
    private clienteComponent2: ClienteComponent = null;
    private enderecoEntregaComponent: EnderecoEntregaComponent = null;
    private adicionarCupomComponent: AdicionarCupomComponent = null;
    private financeiroComponent: FinanceiroComponent = null;
    private vendasClienteListComponent: VendasClienteListComponent = null;
    private chatBotReceitaComponent: ChatBotReceitaComponent = null;

    private shortCutRevisaoCampos: ShortcutComponent = null;
    private shortCutUploadReceita: ShortcutComponent = null;
    private shortcutComponent2: ShortcutComponent = null;
    private shortcutComponentDataContinuo: ShortcutComponent = null;
    private shortcutComponentEntrega: ShortcutComponent = null;
    private shortcutComponentCupom: ShortcutComponent = null;
    private shortcutComponentEmissao: ShortcutComponent = null;
    private shortcutComponentFinanceiro: ShortcutComponent = null;
    private shortcutHistoricoVenda: ShortcutComponent = null;

    private solicitarSenhaComponent: SolicitarSenhaComponent = null;
    private revisaoCamposComponent: RevisaoCamposComponent = null;
    private impressaoComponent: ImpressaoComponent = null;
    private uploadReceitaComponent: UploadReceitaComponent = null;
    private clientesDuplicadosViewComponent: ClientesDuplicadosViewComponent = null;
    private vendaHisticoComponent: VendaHisticoComponent = null;
    private recebimentoComponent: RecebimentoComponent = null;

    private waitingPrint = false;
    private finalizarSemNota = false;

    private loadRevisaoComponent = false;
    private loadUploadReceitaComponent = false;
    private loadEnderecoEntrega = false;
    private loadPagamentoComponent = false;
    private loadCupomComponent = false;
    private loadNfeComponent = false;
    private loadPreFaturamentoComponent = false;
    private loadClienteComponent1 = false;
    private loadClienteComponent2 = false;
    private loadClientesDuplicados = false;

    private obrigatorioFaturamentoParaEmitirNota = false;
    private franquiaAbrangencia = false;
    private imprimirVendaAoSalvar = false;
    private observacaoDesconto = false;

    private salvandoVenda = false;

    private aprovouVenda = false;

    somenteConsultaVenda = false;
    tabFinanceiro = "";
    file: File = null;

    etapasProgressbar: Array<string> = ["Orçamento", "Venda", "Faturado", "Concluido"];
    etapaSelecionada = 0;

    modelImpressaoId = 0;

    model: VendaModel = new VendaModel();
    modelExtra: VendaExtraModel = new VendaExtraModel();
    itemModel: VendaItemModel = new VendaItemModel();
    situacaoVenda: SituacoesVenda = SituacoesVenda.Orcamento;
    situacaoVendaAnterior: SituacoesVenda = null;
    validarSaida = true;
    modelItem: VendaItemModel;

    saldoPagar = 0;

    isCollapsedAdd = true;

    campanhasOptions: Array<Object> = [];
    recompensasOptions: Array<Object> = [];
    pontosAcumulados = 0;
    gridSelectedImpValues: Array<Object> = [];

    isFracionamento = false;
    isEstereis = false;
    isFranqueador = false;
    isFranqueadorSuporte = false;
    isImprimirVenda = false;
    permiteAlterarVendaFaturada = false;

    active = true;
    startFilterKey = "Descrição";

    flagReload = 0;

    private requisicaoApp = false;
    private pacienteIdApp: number = null;

    private TipoNota = TipoNota; // Para acessar no template

    private NFinfo: Array<NotaFiscalInfo> = NotaFiscalSaidaService.NFinfo;

    private NFops: Array<NFContext> = [
        {}, // TipoNota.Servico = 0
        {}, // TipoNota.CupomFiscal = 1
        {}, // TipoNota.DANFe = 2
    ];

    private exibirNF = true;
    private vendaFaturada = false;
    private temMovimentoEstoqueComItens = false;
    private temLocalEntregaInformado = false;

    private permiteParcelamentoVenda = false;
    private permiteNotaSemTomador = false;
    private alertarEstoqueMinimo = false;
    private percentualDescontoMaximoVenda = 0;
    private emiteNotaServico = false;
    private itemModelIndex = 0;
    private entrega = false;
    private title = "";
    private convenioModel = new ConvenioModel();
    private beneficioModel = new BeneficioModel();

    extraButtonActionNfe: Array<ModalButtonAction> = [
        new ModalButtonAction("resumo", "Resumo Pré-Emissão", true, "l", " btn-info"),
    ];

    @Prop({ type: Number, required: true }) modelId: number;
    @Prop({ type: Number, default: 0 }) indexVenda: number;
    @Prop({ type: Array, required: true }) motivoCancelamentoOp;
    @Prop({ type: Object, default: null }) replicaModel;
    @Prop({ type: Boolean, default: false }) isActiveTab: boolean;
    @Prop(Number) clienteId: number;
    @Prop(Number) pacienteId: number;
    @Prop(Number) enderecoEntregaId: number;
    @Prop(Number) origem: number;

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.id} - ` + (p.tipoPessoa != 1 ? p.nome : p.razaoSocial) + (p.ativo ? "" : " - [INATIVO]"),
    });

    private getNFOptions(item: VendaItemModel) {
        const options = [];
        this.NFops.forEach(
            (ctx, tipo) =>
                this.NFinfo[tipo].isValidItem(item, this.emiteNotaServico) &&
                options.push({
                    text: this.NFinfo[tipo].desc,
                    value: tipo,
                }),
        );
        return options;
    }

    private atualizarEtapaVenda(indexEtapa: number) {
        if (this.somenteConsultaVenda) {
            return;
        }

        if (indexEtapa < this.etapaSelecionada) {
            this.voltarEtapa();
        } else if (indexEtapa > this.etapaSelecionada) {
            this.avancarEtapa();
        }
    }

    private voltarEtapa() {
        if (this.validarVolta()) {
            this.etapaSelecionada--;

            if (this.etapaSelecionada < 1) {
                this.etapaSelecionada = 0;
            }

            this.situacaoVenda = this.model.situacao = 0;
        }
    }

    private validarVolta() {
        if (this.etapaSelecionada == 1 && !this.VALIDAR_PERMISSAO_USUARIO("vendas", "VoltarVendaOrcamento")) {
            this.$showWarning(this.$t("__.ts.atencao"), "Usuário sem permissão de voltar para orçamento. ");
            return false;
        } else if (
            this.situacaoVenda == SituacoesVenda.EmFaturamento ||
            this.situacaoVenda == SituacoesVenda.Faturado ||
            this.situacaoVenda == SituacoesVenda.ExpedicaoNota ||
            this.situacaoVenda == SituacoesVenda.Entrega ||
            this.situacaoVenda == SituacoesVenda.Concluido ||
            this.situacaoVenda == SituacoesVenda.Entregue ||
            this.situacaoVenda == SituacoesVenda.Cancelado
        ) {
            this.$showWarning(this.$t("__.ts.atencao"), "Venda não pode mais voltar para orçamento.");
            return false;
        } else if (this.etapaSelecionada == 2 && this.saldoPagar == 0) {
            this.etapaSelecionada = 3;
            this.showTelaPagamentos();
            return false;
        } else if (this.etapaSelecionada == 1) {
            this.situacaoVenda = SituacoesVenda.Orcamento;
            this.model.situacao = this.situacaoVenda;
            this.model.dataAprovacao = null;
            this.$emit("update-venda", this.model, this.indexVenda);

            return true;
        } else if (this.saldoPagar > 0) {
            return true;
        } else if (this.etapaSelecionada >= 3 && this.saldoPagar == 0) {
            this.showTelaPagamentos();
            return false;
        } else {
            return true;
        }
    }

    private async avancarEtapa() {
        if (await this.validarAvanco()) {
            this.$emit("update-venda", this.model, this.indexVenda);

            if (!this.isFracionamento) {
                this.etapaSelecionada++;
            }

            if (this.isFracionamento) {
                if (this.situacaoVenda == SituacoesVenda.Aprovado) {
                    this.model.situacao = SituacoesVenda.Fracionando;
                    this.situacaoVenda = SituacoesVenda.Fracionando;
                    await this.atualizarSituacaoVenda();
                }
                if (this.situacaoVenda == SituacoesVenda.Expedicao && this.saldoPagar == 0) {
                    this.model.situacao = SituacoesVenda.Faturado;
                    this.situacaoVenda = SituacoesVenda.Faturado;
                    await this.atualizarSituacaoVenda();
                }
            }
            //avanca para faturado direto
            if (this.etapaSelecionada == 1 && this.saldoPagar == 0 && !this.isFracionamento) {
                this.etapaSelecionada = 3;
                if (this.model.situacao <= 1) {
                    this.model.situacao = SituacoesVenda.Faturado;
                    this.situacaoVenda = SituacoesVenda.Faturado;
                }

                if (this.etapaSelecionada > 3 && !this.isFracionamento) {
                    this.etapaSelecionada = 3;
                    if (this.model.situacao <= 1 && this.saldoPagar == 0) {
                        this.model.situacao = SituacoesVenda.Faturado;
                        this.situacaoVenda = SituacoesVenda.Faturado;
                    }

                    if (this.etapaSelecionada == 1 && !this.isFracionamento)
                        this.situacaoVenda = this.model.situacao = SituacoesVenda.Aprovado;

                    await this.atualizarSituacaoVenda();
                }
            }
        }
    }

    private async validarAvanco() {
        if (await this.$validator.validateAll()) {
            if (this.situacaoVenda == SituacoesVenda.Orcamento || this.situacaoVenda == SituacoesVenda.Rejeitado) {
                if (await this.validarCampos(false, true)) {
                    this.situacaoVenda = this.model.situacao = SituacoesVenda.Aprovado;
                    this.aprovouVenda = true;
                    this.model.dataAprovacao = new Date();
                    return true;
                }
            } else if (this.isFracionamento) {
                if (this.situacaoVenda == SituacoesVenda.Fracionando) {
                    this.$showWarning(
                        this.$t("__.ts.atencao"),
                        "A venda já encontra-se em separação pelo fracionamento",
                    );
                    return false;
                }

                if (this.situacaoVenda == SituacoesVenda.Aprovado) {
                    this.situacaoVenda = this.model.situacao = SituacoesVenda.Aprovado;
                    return true;
                }
                if (this.situacaoVenda == SituacoesVenda.Expedicao && this.saldoPagar == 0) {
                    this.situacaoVenda = this.model.situacao = SituacoesVenda.Faturado;
                    return true;
                }
                if (this.situacaoVenda == SituacoesVenda.Expedicao && this.saldoPagar > 0) {
                    this.situacaoVenda = this.model.situacao = SituacoesVenda.EmFaturamento;
                    this.showTelaPagamentos();
                    return false;
                }
                if (this.situacaoVenda == SituacoesVenda.EmFaturamento && this.saldoPagar > 0) {
                    this.situacaoVenda = this.model.situacao = SituacoesVenda.EmFaturamento;
                    this.showTelaPagamentos();
                    return false;
                }
                if (this.situacaoVenda == SituacoesVenda.ExpedicaoNota && this.finalizarSemNota) {
                    this.situacaoVenda = SituacoesVenda.Entrega;
                    this.model.situacao = SituacoesVenda.Entrega;
                    return true;
                }
                if (this.situacaoVenda == SituacoesVenda.ExpedicaoNota && !this.finalizarSemNota) {
                    this.situacaoVenda = SituacoesVenda.ExpedicaoNota;
                    this.model.situacao = SituacoesVenda.ExpedicaoNota;
                    return true;
                }
                if (this.situacaoVenda == SituacoesVenda.Entrega) {
                    this.situacaoVenda = SituacoesVenda.Concluido;
                    this.model.situacao = SituacoesVenda.Concluido;
                    return true;
                }
                if (this.situacaoVenda == SituacoesVenda.EmFaturamento && this.saldoPagar == 0) {
                    this.situacaoVenda = SituacoesVenda.Faturado;
                    this.model.situacao = SituacoesVenda.Faturado;
                    return true;
                }
                if (this.situacaoVenda == SituacoesVenda.Faturado && this.saldoPagar == 0) {
                    this.situacaoVenda = SituacoesVenda.ExpedicaoNota;
                    this.model.situacao = SituacoesVenda.ExpedicaoNota;
                    return true;
                }
            } else {
                if (this.situacaoVenda == SituacoesVenda.Aprovado && this.saldoPagar == 0) {
                    this.situacaoVenda = this.model.situacao = SituacoesVenda.Faturado;
                    return true;
                } else if (this.situacaoVenda == SituacoesVenda.Aprovado) {
                    this.showTelaPagamentos();
                    return false;
                } else if (this.situacaoVenda == SituacoesVenda.Faturado && this.saldoPagar > 0) {
                    this.situacaoVenda = this.model.situacao = SituacoesVenda.Aprovado;
                    this.showTelaPagamentos();
                    return false;
                } else return false;
            }
        } else {
            return false;
        }
    }

    public getModelId() {
        return this.model.id;
    }

    public getSituacao() {
        return this.model.situacaoDescricao;
    }

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("codigo", "Código", GridColumnType.Integer),
            new GridColumn("produtoDescricao", "Descri\u00e7\u00e3o", GridColumnType.String),
            new GridColumn("quantidade", "Qtde", GridColumnType.DecimalPonto),
            new GridColumn("acrescimo", "Acréscimo", GridColumnType.Money),
            new GridColumn("desconto", "Desconto", GridColumnType.Money),
            new GridColumn("valorTotal", "Valor", GridColumnType.Money),
            new GridColumnExt({
                value: "continuo",
                text: "Contínuo",
                type: GridColumnType.Boolean,
                editable: true,
                hidden: false,
                validation: "",
                parent: "",
                searchbleColorControl: true,
                showSortIcon: false,
                casasDecimais: 2,
                disabled: this.somenteConsultaVenda || this.situacaoVenda > SituacoesVenda.Aprovado,
                onChange: (item, itemModelIndex) => this.handleModalDataContinuo(item, itemModelIndex),
            }),
            new GridColumn("dataValidade", "Data Validade", GridColumnType.Date, false, this.isFracionamento),
            new GridColumnExt({
                value: "tipoNF",
                text: "Fiscal",
                type: GridColumnType.Combo,
                editable: true,
                disabled: this.somenteConsultaVenda,
                hidden: !(this.situacaoVenda > 0 && this.situacaoVenda < 5),
                showSortIcon: false,
                comboOptions: (item: VendaItemModel) => this.getNFOptions(item),
                onChange: () => this.atualizarContadorItensPorNF(),
            }),
        ];
    }

    gridColumnsPagamento: Array<GridColumn> = [
        new GridColumn("dataMovimento", "Dt Recebimento", GridColumnType.Date),
        new GridColumn("formaPagamentoDescricao", "Forma Pgto", GridColumnType.String),
        new GridColumn("valor", "Valor", GridColumnType.Money),
        new GridColumn("observacao", "Observação", GridColumnType.String),
    ];

    gridColumnsNotas: Array<GridColumn> = [
        new GridColumn("statusEnvioDescricao", "Status da Nota", GridColumnType.String),
        new GridColumn("tipoDescricao", "Tipo da Nota", GridColumnType.String),
        new GridColumn("numero", "Número", GridColumnType.Integer),
        new GridColumn("serie", "Série", GridColumnType.Integer),
        new GridColumn("valorTotal", "Valor", GridColumnType.Money),
    ];

    gridImpressoes: Array<Object> = [];
    gridColumnsImpressoes: Array<GridColumn> = [
        new GridColumn("descricao", "Descrição", GridColumnType.String),
        new GridColumn("modelo", "Modelo", GridColumnType.String),
    ];

    situacoesVendaOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(SituacoesVenda);
    origemAtendimentoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(OrigemAtendimento).filter(
        i => i.value !== OrigemAtendimento.Aplicativo,
    );
    tipoVendaOptions = getTipoVendaCombo();
    motivoCancelamentoOptions: Array<Object> = [];
    franquiaAbrangenciaOptions: Array<Object> = [];
    locaisEntregaOptions: Array<Object> = [];
    usuariosOptions: Array<Object> = [];
    estoqueOptions: Array<Object> = [];
    setorOptions: Array<Object> = [];

    //por enquanto tratado apenas para botao de nota de pdf ....mais tarde tratar envio email e etc
    extraActionsPdf: Array<GridAction> = [];
    extraActionsPdfSimplificado: Array<GridAction> = [];
    extraActionsXml: Array<GridAction> = [];
    extraActionsConsultar: Array<GridAction> = [];
    extraActionsCancelar: Array<GridAction> = [];
    extraActionsGerarCartaCorrecao: Array<GridAction> = [];
    extraActionsCartaCorrecao: Array<GridAction> = [];
    extraActions: Array<object> = [];
    extraButtonAction: Array<ModalButtonAction> = [new ModalButtonAction("Visualizar PDF", "Visualizar PDF", true)];

    constructor() {
        super();

        this.model.situacao = SituacoesVenda.Orcamento;
        this.model.dataInclusao = new Date();
        this.iniciarContaReceber(this.model);
        this.pontosAcumulados = 0;
    }

    private iniciarContaReceber(model: VendaModel) {
        if (!model.contaReceber) {
            model.contaReceber = new ContaReceberModel();
            model.contaReceber.recorrencias = 0;
            model.contaReceber.frequencia = null;
            model.contaReceber.descricao = "Venda";

            //model.contaReceber.contaGerencialId = 6;

            model.contaReceber.saldo = 0;
            model.contaReceber.valorTotal = 0;
            model.contaReceber.dataVencimento = new Date();
            model.contaReceber.dataLancamento = new Date();
            model.contaReceber.contabilizar = true;
            model.contaReceber.clienteId = model.clienteId;
        }
    }

    onAdicionarItem(model: VendaItemModel) {
        this.model.itens.push(model);
        this.atualizarTotalProdutos();
        this.atualizarContadorItensPorNF();

        this.isCollapsedAdd = true;
    }

    private atualizarContadorItensPorNF() {
        this.NFops.forEach((ctx, tipo) => {
            this.$set(
                ctx,
                "totalItens",
                this.model.itens.filter(
                    i => i.tipoNF == tipo && this.NFinfo[tipo].isValidItem(i, this.emiteNotaServico),
                ).length,
            );
        });
    }

    private onEditItem(model: VendaItemModel) {
        this.modelItem = model;
        const item = new VendaItemModel();
        item.updateFrom(model);
        this.isCollapsedAdd = false;
        this.vendaItemWrapperComponent.load(item, false);

        this.atualizarContadorItensPorNF();
    }

    private onChangeCollapsedStatusAdd(collapsed: boolean) {
        this.isCollapsedAdd = collapsed;
    }

    private onEditSubmit(item) {
        copyObject(item, this.modelItem);
        this.isCollapsedAdd = true;

        this.atualizarTotalProdutos();
        this.atualizarContadorItensPorNF();
        this.vendaItemWrapperComponent.clear();
    }

    private onUpdateItensFracionamento(itens: VendaItemModel[]) {
        this.model.itens = this.model.itens.map(p => {
            const itemFind = itens.find(
                i =>
                    i.fracionamento.itens[0].produtoId == p.fracionamento.itens[0].produtoId &&
                    i.fracionamento.itens[0].produtoLoteId == p.fracionamento.itens[0].produtoLoteId,
            );
            return itemFind ? itemFind : p;
        });

        this.atualizarTotalProdutos();
        this.atualizarContadorItensPorNF();
    }

    async onRemoveItem(model: VendaItemModel) {
        const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.temCertezaSeDeseja"));

        if (response) {
            this.model.itens = this.model.itens.filter(p => p != model);
            this.atualizarTotalProdutos();

            this.atualizarContadorItensPorNF();
        }
    }

    loadMotivoCancelamento() {
        this.motivoCancelamentoOptions = this.motivoCancelamentoOp;
    }

    private resetEmitirNFAutomaticamente(state = true) {
        const emissaoNotaAutomatica = this.GET_CONFIG_FRANQUIA(Configuracoes.EmissaoNotaAutomatica);
        this.NFops.forEach(i =>
            this.$set(i, "emitirAutomaticamente", emissaoNotaAutomatica ? emissaoNotaAutomatica.verdadeiro : state),
        );
    }

    private iniciarContextoNotaFiscal() {
        const emissaoNotaAutomatica = this.GET_CONFIG_FRANQUIA(Configuracoes.EmissaoNotaAutomatica);
        this.NFops.forEach((ctx, tipo) => {
            this.$set(ctx, "foiEmitida", this.temNotaFiscalEmitida(tipo));
            this.$set(
                ctx,
                "emitirAutomaticamente",
                emissaoNotaAutomatica ? emissaoNotaAutomatica.verdadeiro : !ctx.foiEmitida,
            );
        });

        this.atualizarContadorItensPorNF();
    }

    private temNotaFiscalEmitida(tipo: TipoNotaFiscal = TipoNotaFiscal.DANFe) {
        if (tipo != TipoNotaFiscal.Servico) {
            return this.model.notasFiscaisSaida.some(
                p => (p.tipo == tipo || p.tipo == TipoNotaFiscal.CFe) && p.statusEnvio == StatusEnvioNF.Confirmado,
            );
        }
        return this.model.notasFiscaisSaida.some(p => p.tipo == tipo && p.statusEnvio == StatusEnvioNF.Confirmado);
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        if (sortOrder == "desc") {
            this.model.itens = this.model.itens.sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : -1));
        } else {
            this.model.itens = this.model.itens.sort((a, b) => (a[sortKey] > b[sortKey] ? -1 : 1));
        }
    }

    private async load(_id = 0) {
        const modelId = this.modelId || _id;

        this.model = new VendaModel();
        this.isCollapsedAdd = false;

        if (modelId > 0) {
            try {
                const data = await this.service.get(modelId).resolveWithJSON<VendaModel>();

                if (data.localEntregaId) {
                    this.entrega = true;
                }

                if (data.vendaExtra) {
                    this.modelExtra = data.vendaExtra;
                }

                data.itens.forEach(item => {
                    item.codigo = item.manipulacaoOrdemId !== null ? item.manipulacaoOrdem.codigo : item.produtoId;
                });

                this.temLocalEntregaInformado = !!data.localEntregaId;
                this.model.updateFrom(data);
                if (!this.model.estoqueId) {
                    this.model.estoqueId = this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId;
                }

                if (this.model.origem == OrigemAtendimento.Aplicativo) {
                    this.requisicaoApp = true;

                    if (this.model.itens.filter(p => p.manipulacaoOrdem != null).length > 0) {
                        this.pacienteId = this.model.itens.filter(p => p.manipulacaoOrdem != null)[0].pacienteId;
                    } else if (this.model.itens.filter(p => p.produtoId > 0).length > 0) {
                        this.pacienteId = this.model.itens.filter(p => p.produtoId > 0)[0].pacienteId;
                    }

                    this.pacienteIdApp = this.pacienteId;
                }

                this.isCollapsedAdd = true;
                this.situacaoVenda = this.model.situacao;
                this.situacaoVendaAnterior = this.model.situacao;
                this.iniciarContaReceber(this.model);

                this.iniciarContextoNotaFiscal();

                await this.loadActionsNF();

                //se concluido ou cancelado eh 2 se aprovado eh 1 e orcamento eh 0
                this.etapaSelecionada =
                    this.model.situacao == SituacoesVenda.Concluido ||
                    this.model.situacao == SituacoesVenda.Cancelado ||
                    this.model.situacao == SituacoesVenda.ExpedicaoNota
                        ? 2
                        : this.model.situacao == SituacoesVenda.Aprovado
                        ? 1
                        : 0;

                this.calcularSaldoPagar();

                if (!this.isFracionamento) {
                    if (this.etapaSelecionada == 1 && this.saldoPagar == 0) {
                        this.situacaoVenda = 4;
                        this.model.situacao = 4;
                        this.etapaSelecionada = 3;
                    }

                    if (
                        this.model.situacao == SituacoesVenda.Faturado ||
                        this.model.situacao == SituacoesVenda.ExpedicaoNota
                    ) {
                        this.etapaSelecionada = 3;
                    }

                    if (this.model.situacao == SituacoesVenda.Concluido) {
                        this.etapaSelecionada = 4;
                    }
                }

                if (this.model.situacao == SituacoesVenda.Entregue) {
                    this.etapaSelecionada = this.saldoPagar > 0 ? 2 : 3;
                }

                if (
                    this.model.movimentacaoEstoqueId &&
                    this.VALIDAR_PERMISSAO_USUARIO("movimentacoes_de_estoque", "get")
                ) {
                    const movimentoEstoque = await new MovimentacaoEstoqueService()
                        .get(this.model.movimentacaoEstoqueId)
                        .resolveWithJSON<MovimentacaoEstoqueModel>();

                    this.temMovimentoEstoqueComItens = movimentoEstoque.movimentacaoEstoqueItens.length > 0;
                }

                this.setVendaFaturada();
                this.$emit("edit-venda", this.model, this.indexVenda);

                this.loadCampanhas();
            } catch {}
        }
    }

    private async loadCampanhas() {
        if (this.model.situacao == SituacoesVenda.Orcamento) {
            this.campanhasOptions = this.preLoadList.campanhasCombo();
        }
    }

    private async loadDescontoCliente() {
        if (!this.model?.clienteId) {
            return;
        }

        await this.loadDescontoBeneficio();
        await this.loadDescontoConvenio();
    }

    private async loadDescontoConvenio() {
        this.model.descontoPrescritor = null;

        try {
            if (this.convenioModel.tipoDescontoCliente == TipoDesconto.Faixa) {
                const faixa = this.convenioModel.faixas.find(
                    p => this.model.valorProdutos >= p.faixaInicio && this.model.valorProdutos <= p.faixaFim,
                );
                if (faixa) {
                    this.model.descontoPrescritor = arithmeticHelper.round(
                        this.model.valorProdutos * (faixa.valorDesconto / 100),
                    );
                }
            } else if (this.convenioModel.tipoDescontoCliente == TipoDesconto.Dinheiro) {
                this.model.descontoPrescritor = arithmeticHelper.round(
                    this.convenioModel.faixas.find(p => p.tipoFaixa == TipoConvenioFaixa.Cliente).valorDesconto,
                );
            } else {
                this.model.descontoPrescritor = arithmeticHelper.round(
                    (this.convenioModel.faixas.find(p => p.tipoFaixa == TipoConvenioFaixa.Cliente).valorDesconto /
                        100) *
                        this.model.valorProdutos,
                );
            }
        } catch {}
    }

    private async loadDescontoBeneficio() {
        if (this.model.situacao == SituacoesVenda.Orcamento) {
            this.model.descontoFuncionario = null;
        }

        try {
            if (this.beneficioModel.beneficioFaixas != null && this.beneficioModel.beneficioFaixas.length > 0) {
                const faixa = this.beneficioModel.beneficioFaixas
                    .filter(p => p.tipoBeneficio == TipoBeneficio.Desconto)
                    .find(p => this.model.valorProdutos >= p.inicioFaixa && this.model.valorProdutos <= p.fimFaixa);

                if (faixa && this.model.situacao == SituacoesVenda.Orcamento) {
                    this.model.descontoFuncionario = arithmeticHelper.round(
                        this.model.valorProdutos * (faixa.valor / 100),
                    );
                }
            }
        } catch {}
    }

    //@ts-ignore
    @Watch("model.clienteId")
    private async onChangeClienteId(newValue: number, oldValue: number) {
        this.beneficioModel = null;
        if (this.model.clienteId) {
            try {
                this.convenioModel = await new ConvenioService()
                    .GetFromCliente(this.model.clienteId)
                    .resolveWithJSON<ConvenioModel>();
            } catch {}

            try {
                this.beneficioModel = await new BeneficioService()
                    .GetFromCliente(this.model.clienteId)
                    .resolveWithJSON<BeneficioModel>();
            } catch {}
        }

        await this.loadDescontoCliente();

        if (this.model) {
            this.model.clienteCpfCnpj = null;
        }

        if (this.model?.clienteId) {
            const cliente = await this.clienteService.get(this.model.clienteId).resolveWithJSON<ClienteModel>();

            try {
                if (cliente.beneficioId) {
                    this.beneficioModel = await new BeneficioService()
                        .get(cliente.beneficioId)
                        .resolveWithJSON<BeneficioModel>();
                }
            } catch {}

            this.model.clienteNome = cliente.nome ?? cliente.razaoSocial;

            const config = this.GET_CONFIG_FRANQUIA(Configuracoes.BloquearClienteDuplicado);
            if (config && config.verdadeiro) {
                const clientesDuplicados = await this.clienteService
                    .getClientesDuplicadosByCliente(cliente)
                    .resolveWithJSON<ClienteModel[]>();

                if (clientesDuplicados.length > 0) {
                    this.loadClientesDuplicados = true;

                    while (!this.$refs.clientesDuplicadosViewComponent) await Delay(5);

                    this.clientesDuplicadosViewComponent = this.$refs
                        .clientesDuplicadosViewComponent as ClientesDuplicadosViewComponent;

                    this.clientesDuplicadosViewComponent.show(cliente);
                }
            }

            const temEndereco = cliente.enderecosEntrega.some(p => p.id == this.model.enderecoEntregaId);
            if (!temEndereco) {
                if (cliente.enderecosEntrega != null) {
                    const enderecoPadrao = cliente.enderecosEntrega.find(p => p.enderecoPadrao);
                    if (enderecoPadrao) {
                        this.model.enderecoEntregaId = enderecoPadrao.id;
                    } else if (cliente.enderecosEntrega.length > 0) {
                        this.model.enderecoEntregaId = cliente.enderecosEntrega[0].id;
                    }
                }
            }

            if (!this.modelId || (this.modelId && oldValue != 0 && newValue != oldValue)) {
                try {
                    const notificacoesCliente = await this.clienteService
                        .getNotificacoesByClienteId(this.model.clienteId)
                        .resolveWithJSON<Array<string>>()
                        .withLoading();

                    notificacoesCliente.forEach(item => {
                        this.$notifyWarn(item);
                    });
                } catch {}
            }

            this.model.clienteCpfCnpj = cliente.cpf ?? cliente.cnpj;
        } else {
            if (this.model) {
                this.model.enderecoEntregaId = null;
                this.model.clienteCpfCnpj = null;
            }
        }

        if (this.model) {
            this.model.identificarClienteNF = !!this.model.clienteCpfCnpj;

            this.calcularValorTotal();
        }
    }

    private async onChangeFlagIdentificarClienteNF() {
        if (this.model.identificarClienteNF && !this.model.clienteCpfCnpj) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.msgCpfNaoInformado"));
            this.model.identificarClienteNF = false;
            this.flagReload++;
        }
    }

    private onChangeUsaCashback() {
        if (this.model.cashback > this.model.valorFinal) {
            return this.$showWarning(
                this.$t("__.ts.atencao"),
                "Valor do cashback é maior que venda, e não pode ser utilizado",
            );
        }

        if (!this.model.usaCashback) {
            this.model.desconto = arithmeticHelper.round(this.model.cashback - this.model.desconto);
        } else {
            this.model.desconto = arithmeticHelper.round(this.model.cashback + this.model.desconto);
        }
    }

    private async onChangeTaxaEntrega(newValue) {
        if (newValue == 0) {
            this.model.transportadoraId = null;
        }
    }

    private onChangeEntrega() {
        if (!this.entrega) {
            this.model.localEntregaId = null;
        }
    }

    //@ts-ignore
    @Watch("model.campanhaId")
    private async onChangeCampanha() {
        this.pontosAcumulados = 0;
        this.model.cashback = 0;

        if (this.model.campanhaId > 0) {
            const data = await this.campanhaService.get(this.model.campanhaId).resolveWithJSON<CampanhaModel>();

            await this.loadPontosAcumulados();
            this.model.cashback = this.pontosAcumulados * data.multiplicador;
        }
    }

    //@ts-ignore
    @Watch("model.clienteCpfCnpj")
    private async watchCpfCnpj() {
        await this.loadPontosAcumulados();
    }

    private async loadPontosAcumulados() {
        this.pontosAcumulados = 0;

        if (this.model.clienteCpfCnpj) {
            if (this.model.campanhaId > 0) {
                this.pontosAcumulados = await this.campanhaService
                    .getPontosByCampanha(this.model.clienteCpfCnpj, this.model.campanhaId)
                    .resolveWithJSON<number>();
                await this.loadRecompensas();
            }
        }
    }

    private async loadRecompensas() {
        try {
            const recompensas = await this.campanhaService
                .buscaRecompensas(this.model.campanhaId, this.model.clienteCpfCnpj, this.pontosAcumulados)
                .resolveWithJSON<PaginationModel<RecompensaModel>>();

            if (recompensas.total > 0 && this.model.situacao == SituacoesVenda.Orcamento) {
                this.$showWarning(
                    this.$t("__.ts.atencao"),
                    "O Cpf informado atingiu a pontuação necesssária para ganhar um prêmio",
                );
            }

            this.recompensasOptions = [];
            this.recompensasOptions = this.recompensasOptions.concat(
                {
                    text: "Não Receber",
                    value: null,
                },
                recompensas.list.map(item => ({
                    value: item.id,
                    text: item.premio,
                })),
            );
        } catch {}
    }

    //@ts-ignore
    @Watch("model.usuarioId")
    private onChangeVendedor(newValue: number, oldValue: number) {
        // Case selecione um setor diferente do que está configurado no usuário não deve trocar
        if ((this.isFracionamento || this.isEstereis) && newValue && !(newValue && !oldValue && this.model.setorId)) {
            const vendedor = this.preLoadList.vendedores.find(x => x.id === this.model.usuarioId);
            if (vendedor) {
                this.model.setorId = vendedor.setorId;
            }
        }
    }

    private async loadActionsNF() {
        this.extraActions.splice(0);
        this.extraActionsConsultar.splice(0);
        this.extraActionsCancelar.splice(0);
        this.extraActionsPdf.splice(0);
        this.extraActionsPdfSimplificado.splice(0);
        this.extraActionsXml.splice(0);
        this.extraActionsGerarCartaCorrecao.splice(0);
        this.extraActionsCartaCorrecao.splice(0);

        for (let i = 0; i < this.model.notasFiscaisSaida.length; i++) {
            this.extraActionsConsultar.push(
                new GridAction(
                    "consultar",
                    "Consultar Nota",
                    "fa fa-search",
                    GridColors.BLUE,
                    this.model.notasFiscaisSaida[i].statusEnvioDescricao != "Cancelado",
                ),
            );

            this.extraActionsCancelar.push(
                new GridAction(
                    "cancelar",
                    "Cancelar Nota",
                    "fa fa-exclamation-circle",
                    GridColors.LIGHTRED,
                    this.model.notasFiscaisSaida[i].statusEnvioDescricao == "Confirmado",
                ),
            );

            this.extraActionsPdf.push(
                new GridAction(
                    "abrir-pdf",
                    "Abrir PDF",
                    "fa fa-file-pdf",
                    GridColors.RED,
                    this.model.notasFiscaisSaida[i].pdfLink != null &&
                        this.model.notasFiscaisSaida[i].statusEnvioDescricao == "Confirmado",
                ),
            );

            this.extraActionsPdfSimplificado.push(
                new GridAction(
                    "abrir-pdf-simplificado",
                    "Abrir PDF Simplificado",
                    "fa fa-file-pdf",
                    GridColors.LIGHTRED,
                    this.model.notasFiscaisSaida[i].pdfSimplificadoLink &&
                        this.model.notasFiscaisSaida[i].statusEnvioDescricao == "Confirmado",
                ),
            );

            this.extraActionsXml.push(
                new GridAction(
                    "abrir-xml",
                    "Download do XML",
                    "fa fa-file-code",
                    GridColors.LIGHTGREEN,
                    this.model.notasFiscaisSaida[i].xmlLink != null &&
                        this.model.notasFiscaisSaida[i].statusEnvioDescricao == "Confirmado",
                ),
            );

            this.extraActionsGerarCartaCorrecao.push(
                new GridAction(
                    "carta-correcao",
                    "Gerar Carta de correção",
                    "fa fa-envelope",
                    GridColors.LIGHTGREEN,
                    this.model.notasFiscaisSaida[i].statusEnvioDescricao == "Confirmado" &&
                        this.model.notasFiscaisSaida[i].tipo == TipoNotaFiscal.DANFe,
                ),
            );

            this.extraActionsCartaCorrecao.push(
                new GridAction(
                    "consulta-carta-correcao",
                    "Consultar última Carta de correção",
                    "fa fa-envelope-open",
                    GridColors.ORANGE,
                    this.model.notasFiscaisSaida[i].temCartaCorrecao,
                ),
            );
        }

        if (this.extraActions.length == 0) {
            if (this.extraActionsConsultar.length > 0) this.extraActions.push(this.extraActionsConsultar);
            if (this.extraActionsPdf.length > 0) this.extraActions.push(this.extraActionsPdf);
            if (this.extraActionsPdfSimplificado.length > 0) this.extraActions.push(this.extraActionsPdfSimplificado);
            if (this.extraActionsXml.length > 0) this.extraActions.push(this.extraActionsXml);
            if (this.extraActionsCancelar.length > 0) this.extraActions.push(this.extraActionsCancelar);
            if (this.extraActionsGerarCartaCorrecao.length > 0)
                this.extraActions.push(this.extraActionsGerarCartaCorrecao);
            if (this.extraActionsCartaCorrecao.length > 0) this.extraActions.push(this.extraActionsCartaCorrecao);
        }
    }

    private async onExtraAction(name: string, model: NotaFiscalSaidaModel) {
        if (name.trim() == "abrir-pdf") {
            this.notaFiscalEletronicaEmissaoService.openPdf(model.pdfLink);
        } else if (name.trim() == "abrir-pdf-simplificado") {
            this.notaFiscalEletronicaEmissaoService.openPdf(model.pdfSimplificadoLink);
        } else if (name.trim() == "consultar") {
            if (model.tipo == TipoNotaFiscal.Servico) {
                this.$router.push("/notafiscalsaida-edicao/" + model.id);
            } else {
                this.$router.push("/nfe-edicao/" + model.id);
            }
        } else if (name.trim() == "abrir-xml") {
            this.notaFiscalEletronicaEmissaoService.downloadXML(model.xmlLink);
        } else if (name.trim() == "cancelar") {
            if (model.tipo == TipoNotaFiscal.Servico) {
                this.cancelarNotaNFSE(model.numero, model.id).withLoading();
            } else {
                this.cancelarNotaNFe(model.chave).withLoading();
            }
        } else if (name.trim() == "carta-correcao") {
            this.cartaCorrecao(model).withLoading();
        } else if (name.trim() == "consulta-carta-correcao") {
            this.notaFiscalEletronicaEmissaoService.showCartaCorrecao(model.id, TipoOperacao.Saida);
        }
    }

    private async concluir() {
        try {
            const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.desejSistemEfetConcl"));
            if (response) {
                this.save(true).withLoading();
            }
        } catch {}
    }

    private async onEmitirNota() {
        if (await this.nfeComponent.emitir()) {
            this.shortcutComponentEmissao.hide();
            this.loadNfeComponent = false;
            this.closeVenda();
        }
    }

    private async cancelarNotaNFSE(numero: number, notaId: number) {
        const cancelamento = await this.notaFiscalServicoService.verificaCancelamento(
            notaId,
            this.usuarioLogado.franquia.cidadeId,
        );
        if (cancelamento != VerificaCancelamentoRetorno.CANCELANOTA) {
            if (cancelamento == VerificaCancelamentoRetorno.CANCELANOTASISTEMA) {
                this.load().withLoading();
            }
            return;
        }

        const response = await this.notaFiscalServicoService.cancelamentoSelecao();
        if (response.isConfirmed) {
            try {
                const sucesso = await this.notaFiscalServicoService
                    .cancelamento(numero, response.value)
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load().withLoading();
                }
            } catch {}
        }
    }

    private async cancelarNotaNFe(chave: string) {
        const usaSAT = await this.utilizarComunicaoSAT();
        if (usaSAT == 1) {
            const sat = await this.notaFiscalEletronicaEmissaoService
                .cancelarSAT(chave)
                .withLoading()
                .resolveWithJSON<{ xml: string }>();

            return this.webSocketService.send(sat.xml);
        }

        if (usaSAT == 3) {
            return;
        }

        const response = await this.notaFiscalEletronicaEmissaoService.cancelamentoSelecao();

        if (response.isConfirmed) {
            try {
                const sucesso = await this.notaFiscalEletronicaEmissaoService
                    .cancelamento(chave, response.value)
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load().withLoading();
                }
            } catch {}
        }
    }

    private async cartaCorrecao(model: NotaFiscalSaidaModel) {
        const response = await this.notaFiscalEletronicaEmissaoService.cartaCorrecaoSelecao();

        if (response.isConfirmed) {
            try {
                const sucesso = await this.notaFiscalEletronicaEmissaoService
                    .cartaCorrecao(model.chave, model.id, response.value, TipoOperacao.Saida)
                    .resolveWithoutJSON();

                if (sucesso) {
                    this.load().withLoading();
                }
            } catch {}
        }
    }

    private async emitirNotaServico() {
        try {
            if (!this.permiteNotaSemTomador && !this.model.clienteCpfCnpj) {
                return await this.$showWarning(
                    this.$t("__.ts.atencao"),
                    "Municipio obriga a informação de CPF na Nota Fiscal de Serviço e este cliente não possue essa informação no cadastro. Para emitir a nota atualize o cadastro do cliente.",
                );
            }

            const response = await this.notaFiscalServicoService.emitirByVenda(this.model);

            if (response.ok) {
                const statusRetorno = (await response.json()) as StatusEnvioNF;

                await this.notaFiscalServicoService.showMessageByStatus(statusRetorno);

                this.NFops[0].foiEmitida = true;
            } else {
                const erros = (await response.json()) as ValidationErrorModel[];
                await showErrorsAsync(erros, this.$validator);
            }
        } catch {}
    }

    private async emitirNotaFiscal(tipoNota: TipoNotaFiscal) {
        try {
            const response = await this.notaFiscalEletronicaEmissaoService.emitirAutomaticoByVenda(
                tipoNota,
                this.model,
            );
            if (response.ok) {
                this.NFops[tipoNota].foiEmitida = true;

                const notaString = tipoNota == TipoNotaFiscal.CupomFiscal ? "Cupom" : "Nota";

                await this.$showSuccess("Enviado!", `${notaString} fiscal foi enviado com sucesso`);
            } else {
                const erros = (await response.json()) as ValidationErrorModel[];
                await showErrorsAsync(erros, this.$validator);
            }
        } catch {}
    }

    private async emitirNotaTransporte() {
        this.loadNfeComponent = true;

        while (!this.$refs.nfeComponent) await Delay(5);
        this.nfeComponent = this.$refs.nfeComponent as NfeEditComponent;

        this.shortcutComponentEmissao.title = "Emissão de Nota Fiscal de Transporte";
        this.shortcutComponentEmissao.show();

        await this.nfeComponent.loadModelByTransporte(this.model).withLoading();
    }

    private async onPreFaturamento() {
        while (!this.$refs.preFaturamentoComponent) await Delay(10);
        this.preFaturamentoComponent.show();

        this.preFaturamentoComponent.load(this.model.vendaPreFaturamento, this.model.valorFinal);
    }

    private async onResult(sucesso: boolean) {
        if (sucesso) {
            this.model.descontoAprovadoSupervisor = this.getPercentualDescontoVenda();

            return await this.preSaveVenda({});
        }

        await this.$showError(this.$t("__.ts.erro"), "Senha invalida, venda não será salva!");
    }

    private async onResultPreFaturamento(vendaPreFaturamento: VendaPreFaturamentoModel[]) {
        if (vendaPreFaturamento.length > 0) {
            this.model.vendaPreFaturamento = vendaPreFaturamento;
        }
    }

    private async save(concluir = false, forcarEmitirNF = false) {
        if (this.salvandoVenda) {
            return;
        }

        this.aprovouVenda = false;
        this.salvandoVenda = true;
        try {
            await this.calcularValorTotal();

            const valid = await this.$validator.validateAll();

            if (!(await this.validarCampos())) {
                this.salvandoVenda = false;
                return;
            }

            if (valid) {
                if (
                    !this.isFranqueadorSuporte &&
                    !this.permiteAlterarVendaFaturada &&
                    this.situacaoVendaAnterior == SituacoesVenda.Faturado
                ) {
                    this.title = this.$t("__.ts.altVendaFaturada") as string;
                    this.solicitarSenhaComponent.show();
                    this.salvandoVenda = false;
                    return;
                } else if (!this.isFranqueadorSuporte && this.percentualDescontoMaximoVenda > 0) {
                    const percentualDescontoVenda = this.getPercentualDescontoVenda();

                    if (
                        arithmeticHelper.round(this.model.descontoAprovadoSupervisor) <
                            arithmeticHelper.round(percentualDescontoVenda) &&
                        percentualDescontoVenda > this.percentualDescontoMaximoVenda
                    ) {
                        this.title =
                            "Desconto total da venda ultrapassou o percentual máximo de " +
                            this.percentualDescontoMaximoVenda +
                            "% permitido, para prosseguir é necessário aprovação de um supervisor";
                        this.solicitarSenhaComponent.show();
                        this.salvandoVenda = false;
                        return;
                    }
                }
                if (!concluir) {
                    concluir = await this.checkConcluirVenda();
                }
                await this.preSaveVenda({ concluir, forcarEmitirNF });
            } else {
                this.$focusErrorField();
            }
        } catch {}
        this.salvandoVenda = false;
    }

    private async checkConcluirVenda() {
        if (
            this.model.notasFiscaisSaida.length > 0 &&
            this.vendaFaturada &&
            this.situacaoVenda != SituacoesVenda.Concluido
        ) {
            return await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.desejSistemEfetConcl"));
        }
        return false;
    }

    private async preSaveVenda({
        concluir = false,
        concluirPosEmissaoNota = false,
        forcarEmitirNF = false,
    }: SaveVendaParams) {
        if (this.isFracionamento) {
            for (let i = 0; i < this.model.itens.length; i++) {
                if (this.model.itens[i]?.fracionamento) {
                    this.model.itens[i].unidadeMedidaId = this.model.itens[i].fracionamento.unidadeMedidaId;
                }
            }
        }

        // finalizarSemNota
        const progIt = this.etapaSelecionada;
        this.model.vendaExtra = this.modelExtra;
        const situacaoAtual = this.model.situacao;

        if (this.situacaoVenda == 2 && this.saldoPagar == 0) {
            this.situacaoVenda = this.model.situacao = 4;
        } else if (this.saldoPagar > 0 && this.situacaoVenda == 4) {
            this.situacaoVenda = this.model.situacao = 2;
        }

        // Se força conclusão da venda
        if (this.etapaSelecionada >= 2 && this.saldoPagar == 0 && (concluir || concluirPosEmissaoNota)) {
            this.situacaoVenda = SituacoesVenda.Concluido;
            this.model.situacao = this.situacaoVenda;

            if (this.isFracionamento) {
                this.etapaSelecionada = 6;
            } else {
                this.etapaSelecionada = 4;
            }
        }

        if (
            (this.situacaoVenda == SituacoesVenda.ExpedicaoNota ||
                this.situacaoVenda == SituacoesVenda.Entrega ||
                this.situacaoVenda == SituacoesVenda.Expedicao) &&
            (this.finalizarSemNota || this.temNotaFiscalEmitida()) &&
            this.isFracionamento
        ) {
            this.model.situacao = this.situacaoVenda = SituacoesVenda.Concluido;
        }

        for (const itemModel of this.model.itens) {
            if (itemModel.fracionamento != null && itemModel.fracionamento.itens[0].produtoLoteId == 0) {
                itemModel.fracionamento.itens[0].produtoLote = null;
            }
        }

        if (this.model.id && !this.model.usuarioId) {
            const vendaInserida = await this.service.get(this.model.id).resolveWithJSON<VendaModel>();
            this.model.usuarioId = vendaInserida.usuarioId;
        }

        if (
            this.model.contaReceber.lancamentosVinculados != null &&
            this.model.contaReceber.lancamentosVinculados.length > 0
        ) {
            const valorTotalDasParcelas = arithmeticHelper.round(
                this.model.contaReceber.lancamentosVinculados.reduce((acc, p) => acc + p.valorTotal, 0),
            );

            if (valorTotalDasParcelas != this.model.valorFinal) {
                return await this.$showError(
                    "Venda não pode ser salva",
                    "O valor total das parcelas esta diferente do valor da total da venda.",
                );
            }
        }

        try {
            if (forcarEmitirNF && !this.VALIDAR_PERMISSAO_USUARIO("vendas", "update")) {
                await this.saveFinish(null, { forcarEmitirNF: true });
            } else {
                await this.saveVenda(situacaoAtual, { forcarEmitirNF, concluirPosEmissaoNota });
            }
        } catch {
            this.etapaSelecionada = progIt;
        }
    }

    private async saveVenda(
        situacaoAtual: SituacoesVenda,
        { forcarEmitirNF = false, concluirPosEmissaoNota = false }: SaveVendaParams,
    ) {
        const response = await this.service[!this.model.id ? "insert" : "update"](this.model)
            .withLoading()
            .resolveWithResponse();

        const nId = Number(response.headers.get("Id"));
        let nCod = Number(response.headers.get("Codigo"));
        const nContaReceberId = Number(response.headers.get("ContaReceberId"));

        if (this.model.codigo > 0 && (nCod == null || nCod == 0)) {
            nCod = this.model.codigo;
        }

        if (response.ok) {
            if (!this.model.id) {
                this.model.id = nId;
            }
            if (!this.model.contaReceberId && nContaReceberId) {
                this.model.contaReceberId = nContaReceberId;
            }

            if (!this.modelId && this.loadUploadReceitaComponent) {
                this.uploadReceitaComponent = this.$refs.uploadReceitaComponent as UploadReceitaComponent;
                this.uploadReceitaComponent.upload(this.model.id);
            }

            const strName = this.model.situacao == SituacoesVenda.Orcamento ? "Orçamento" : "Venda";
            await this.$showSuccess(strName + " Código: " + nCod, "Salvo com sucesso.");

            this.atualizarSituacaoVenda();
        } else {
            this.situacaoVenda = this.model.situacao = situacaoAtual;
        }

        await this.saveFinish(nId, { forcarEmitirNF, concluirPosEmissaoNota });
    }

    private async saveFinish(id: number, { forcarEmitirNF = false, concluirPosEmissaoNota = false }: SaveVendaParams) {
        await this.emitirNF(forcarEmitirNF);

        //recarrega e atualiza o nome da guia
        if (this.modelId == 0 && id > 0) {
            await this.load(id).withLoading();
        }

        if (!forcarEmitirNF && this.imprimirVendaAoSalvar) {
            await this.imprimirVenda();
        } else if (!forcarEmitirNF && !concluirPosEmissaoNota) {
            this.closeVenda();
        }
    }

    private getPercentualDescontoVenda() {
        const valorTotal =
            this.model.itens.reduce((acc, p) => acc + p.valorTotal + p.desconto, 0) +
            this.model.taxaEntrega +
            this.model.acrescimo;
        const descontoItens = this.model.itens.reduce((acc, p) => acc + p.desconto, 0);
        return ((this.model.desconto + descontoItens) / valorTotal) * 100;
    }

    private async imprimirVenda(isImprimirVenda = false) {
        this.isImprimirVenda = isImprimirVenda;
        if (
            this.aprovouVenda ||
            this.situacaoVenda == SituacoesVenda.Orcamento ||
            this.situacaoVenda == SituacoesVenda.Rejeitado
        ) {
            await this.onImprimirOrcamento();
        } else {
            await this.impressaoComponent
                .show({
                    modelId: this.model.id,
                    tipoImpressao: "Venda",
                    impressaoTodosModelo: true,
                })
                .withLoading();
        }
    }

    private onHideImpressao() {
        if (!this.isImprimirVenda) {
            this.closeVenda();
        }
    }

    @Watch("situacaoVenda")
    @Watch("etapaSelecionada")
    private onChangeSituacao() {
        this.imprimirVendaAoSalvar = false;

        if (
            this.situacaoVenda == SituacoesVenda.Aprovado &&
            this.etapaSelecionada == 1 &&
            this.VALIDAR_PERMISSAO_USUARIO("vendas", "ImprimirVenda")
        ) {
            this.imprimirVendaAoSalvar = true;
        }
    }

    private async emitirNF(forcarEmitirNF = true) {
        // Se forcarEmitirNF é falso, as notas não são emitidas se a situação for diferente de faturado
        if (
            !this.model.id ||
            this.model.situacao == SituacoesVenda.Orcamento ||
            this.model.situacao == SituacoesVenda.Rejeitado ||
            this.model.situacao == SituacoesVenda.Cancelado ||
            (this.model.situacao != SituacoesVenda.Faturado && !forcarEmitirNF) ||
            !this.VALIDAR_PERMISSAO_USUARIO("vendas", "EmitirNotaVenda")
        ) {
            return;
        }

        if (!forcarEmitirNF && this.temNotaFiscalEmitida()) {
            return;
        }

        if (this.model.identificarClienteNF && !this.model.clienteCpfCnpj) {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.msgCpfNaoInformado"));
            return;
        }

        if (this.temNotaFiscalEmitida()) {
            const response = await this.$showQuestion(
                this.$t("__.ts.atencao"),
                "Já foi emitida nota para essa venda, realmente deseja emitir novamente?",
            );

            if (!response) return;
        }

        if (this.isFracionamento || !this.emiteNotaServico) {
            this.loadNfeComponent = true;

            while (!this.$refs.nfeComponent) await Delay(5);
            this.nfeComponent = this.$refs.nfeComponent as NfeEditComponent;

            this.shortcutComponentEmissao.title = "Emissão de Nota Fiscal";
            this.shortcutComponentEmissao.show();

            await this.nfeComponent.loadModelByVenda(this.model).withLoading();
        } else {
            let enviouPeloMenosUmaNota = false;

            if (
                !this.NFops[TipoNotaFiscal.Servico].foiEmitida &&
                this.NFops[TipoNotaFiscal.Servico].totalItens &&
                (forcarEmitirNF || this.NFops[TipoNotaFiscal.Servico].emitirAutomaticamente)
            ) {
                enviouPeloMenosUmaNota = true;
                await this.emitirNotaServico().withLoading();
            }

            if (
                !this.NFops[TipoNotaFiscal.CupomFiscal].foiEmitida &&
                this.NFops[TipoNotaFiscal.CupomFiscal].totalItens &&
                (forcarEmitirNF || this.NFops[TipoNotaFiscal.CupomFiscal].emitirAutomaticamente)
            ) {
                const usaSAT = await this.utilizarComunicaoSAT();
                if (usaSAT == 1) {
                    const sat = await this.notaFiscalEletronicaEmissaoService
                        .emitirSAT(this.model)
                        .withLoading()
                        .resolveWithJSON<{ xml: string }>();

                    await this.webSocketService.send(sat.xml);

                    // Callback websocket
                    await this.salvarNotaSAT();
                }

                if (usaSAT == 0 || usaSAT == 2) {
                    enviouPeloMenosUmaNota = true;
                    await this.emitirNotaFiscal(TipoNotaFiscal.CupomFiscal).withLoading();
                }
            }

            if (
                !this.NFops[TipoNotaFiscal.DANFe].foiEmitida &&
                this.NFops[TipoNotaFiscal.DANFe].totalItens &&
                (forcarEmitirNF || this.NFops[TipoNotaFiscal.DANFe].emitirAutomaticamente)
            ) {
                enviouPeloMenosUmaNota = true;
                await this.emitirNotaFiscal(TipoNotaFiscal.DANFe).withLoading();
            }

            if (enviouPeloMenosUmaNota) {
                await this.load(this.model.id).withLoading();

                const resposta = await this.checkConcluirVenda();
                if (resposta) {
                    await this.preSaveVenda({ concluirPosEmissaoNota: true });
                }
            }
        }
    }

    /*
        0 - Não usa SAT
        1 - Usa SAT
        2 - Não usa SAT e emite NFC-e
        3 - Não usa SAT e não emite NFC-e
    */
    private async utilizarComunicaoSAT() {
        const usaSAT = this.GET_CONFIG_FRANQUIA(Configuracoes.UsaSAT)?.verdadeiro ?? false;
        if (usaSAT) {
            if (!(await this.webSocketService.isConnected())) {
                const response = await this.$showQuestion(
                    "Franquia utiliza SAT",
                    "Franquia está configurada para utilizar SAT mas o sistema não conseguiu se conectar com o aparelho, deseja emitir a nota como (NFC-e) na SEFAZ?",
                );
                return response ? 2 : 3;
            }
            return 1;
        }
        return 0;
    }

    private async salvarNotaSAT() {
        await this.notaFiscalEletronicaEmissaoService.salvarSAT(this.model);

        this.load(this.model.id).withLoading();
    }

    private atualizarTotalProdutos() {
        const total = this.model.itens
            .map(function (data) {
                return +data.valorTotal;
            })
            .reduce(function (a, b) {
                return +a + +b;
            }, 0);

        this.model.valorProdutos = arithmeticHelper.round(total);
    }

    private atualizarContaReceber() {
        if (this.model.contaReceber) {
            this.model.contaReceber.valorTotal = arithmeticHelper.round(this.model.valorFinal);
            const totalRecebido = this.model.contaReceber.movimentacoes
                .map(function (data) {
                    return +data.valor + +data.desconto - +data.juros;
                })
                .reduce(function (a, b) {
                    return +a + +b;
                }, 0);

            this.model.contaReceber.saldo = arithmeticHelper.round(this.model.contaReceber.valorTotal - totalRecebido);

            if (this.model.contaReceber.saldo < 0) {
                this.model.contaReceber.saldo = 0;
            }

            this.loadTabFinanceiro();
        }
    }

    // @ts-ignore
    @Watch("model.valorProdutos")
    private onValorProdutosChanged() {
        this.calcularValorTotal();
        this.calculaAcrescimoByPercentual();
        this.calculaDescontoByPercentual();
    }
    // @ts-ignore
    @Watch("model.localEntregaId")
    private async onChangeLocalEntregaId() {
        if (this.model.localEntregaId && !this.temLocalEntregaInformado) {
            try {
                const local = await this.localEntregaService
                    .get(this.model.localEntregaId)
                    .resolveWithJSON<LocalEntregaModel>();
                this.model.taxaEntrega = local.taxa;
                this.model.transportadoraId = local.transportadoraId;
            } catch {}
        }
    }
    // @ts-ignore
    @Watch("model.taxaEntrega")
    private onTaxaEntregaChanged() {
        this.calcularValorTotal();
    }
    // @ts-ignore
    @Watch("model.acrescimo")
    private onAcrescimoChanged() {
        this.calcularValorTotal();
    }
    // @ts-ignore
    @Watch("model.desconto")
    private onDescontoChanged() {
        if (this.model.desconto == 0) {
            this.modelExtra.observacaoDesconto = null;
        }

        this.calcularValorTotal();
    }
    // @ts-ignore
    @Watch("model.acrescimoPercentual")
    private onAcrescimoPercentualChanged() {
        this.calculaAcrescimoByPercentual();
    }

    // @ts-ignore
    @Watch("model.descontoPercentual")
    private onDescontoPercentualChanged() {
        this.calculaDescontoByPercentual();
    }

    private calculaAcrescimoByPercentual() {
        if (this.model.acrescimoPercentual)
            this.model.acrescimo = arithmeticHelper.round(
                (this.model.valorProdutos * this.model.acrescimoPercentual) / 100,
            );
    }

    private calculaDescontoByPercentual() {
        if (this.model.descontoPercentual)
            this.model.desconto = arithmeticHelper.round(
                (this.model.valorProdutos * this.model.descontoPercentual) / 100,
            );
    }

    private onChangeDesconto() {
        this.model.descontoPercentual = 0;
    }

    private onChangeAcrescimo() {
        this.model.acrescimoPercentual = 0;
    }

    private async calcularValorTotal() {
        await this.loadDescontoCliente();
        const descontoFuncionario = this.model.descontoFuncionario ? this.model.descontoFuncionario : 0;
        const descontoPrescritor = this.model.descontoPrescritor ? this.model.descontoPrescritor : 0;
        this.model.valorFinal = arithmeticHelper.round(
            this.model.valorProdutos +
                this.model.taxaEntrega +
                this.model.acrescimo -
                this.model.desconto -
                descontoFuncionario -
                descontoPrescritor,
        );
        this.calcularSaldoPagar();
        this.atualizarContaReceber();
    }

    private cancel() {
        this.closeVenda();
    }

    private closeVenda() {
        this.$emit("close-venda", this.indexVenda);
    }

    private onRemovePagamento(model: ContaReceberRecebimentoModel) {
        if (this.model.contaReceber) {
            this.model.contaReceber.movimentacoes = this.model.contaReceber.movimentacoes.filter(p => p != model);
            this.calcularSaldoPagar();
        }
    }

    async showTelaPagamentos() {
        this.tabFinanceiro = "";
        this.loadPagamentoComponent = true;

        if (this.permiteParcelamentoVenda) {
            while (!this.$refs.shortcutComponentFinanceiro) await Delay(5);
            this.shortcutComponentFinanceiro = this.$refs.shortcutComponentFinanceiro as ShortcutComponent;

            while (!this.$refs.financeiroComponent) await Delay(5);
            this.financeiroComponent = this.$refs.financeiroComponent as FinanceiroComponent;

            this.financeiroComponent.setModel(this.model.contaReceber);
            this.financeiroComponent.load(this.model.valorFinal, 0, 0);

            this.shortcutComponentFinanceiro.title = "Financeiro";
            this.shortcutComponentFinanceiro.show();
            return;
        }

        while (!this.$refs.pagamentoComponent) await Delay(5);
        this.pagamentoComponent = this.$refs.pagamentoComponent as PagamentoComponent;

        while (!this.$refs.shortcutComponent) await Delay(5);
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;

        while (!this.$refs.recebimentoComponent) await Delay(5);
        this.recebimentoComponent = this.$refs.recebimentoComponent as RecebimentoComponent;

        // sempre vamos user o valor final da venda e não será possível aplicar descontos ou acrescimos pela tela de pagamento
        this.pagamentoComponent.load(this.saldoPagar);

        this.loadTabFinanceiro();

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponent.title = this.$t("__.ts.financCntsReceber") as string;
        this.shortcutComponent.show();
        this.recebimentoComponent.show(this.model.contaReceber.movimentacoes);
    }

    @Watch("tabFinanceiro")
    private async onChangeTabFinanceiro() {
        if (this.tabFinanceiro == this.$t("__.Components.parent.venda.editSingle_vue_html.pagamentos")) {
            while (!this.$refs.recebimentoComponent) await Delay(5);
            this.recebimentoComponent = this.$refs.recebimentoComponent as RecebimentoComponent;

            this.recebimentoComponent.show(this.model.contaReceber.movimentacoes);
        }
    }

    private onAdicionarPagamento(model: ContaReceberRecebimentoModel) {
        if (this.model.contaReceber) {
            this.model.contaReceber.movimentacoes.push(model);
            this.calcularSaldoPagar();
        }

        if (this.isFracionamento) {
            this.avancarEtapa();
        }

        this.setVendaFaturada();
    }

    private getSaldoConta(conta: ContaReceberRecebimentoModel) {
        return Number(conta.valor) + Number(conta.desconto) - Number(conta.juros);
    }

    private calcularSaldoPagar() {
        if (this.model.contaReceber) {
            let total = this.model.contaReceber.movimentacoes.reduce((acc, p) => acc + this.getSaldoConta(p), 0);

            if (total == 0) {
                this.model.contaReceber.lancamentosVinculados.forEach(p => {
                    total += p.movimentacoes.reduce((acc, p) => acc + this.getSaldoConta(p), 0);
                });
            }

            this.saldoPagar = arithmeticHelper.round(this.model.valorFinal) - arithmeticHelper.round(total);

            if (total == 0) {
                this.saldoPagar = arithmeticHelper.round(this.model.valorFinal);
            }

            if (this.saldoPagar < 0) {
                this.saldoPagar = 0;
            }

            if (this.pagamentoComponent) {
                this.pagamentoComponent.load(this.saldoPagar);
            }

            this.loadTabFinanceiro();

            if (this.isFracionamento) {
                this.atualizarSituacaoVenda();
            } else {
                if (this.saldoPagar == 0 && (this.situacaoVenda == 2 || this.situacaoVenda == 4)) {
                    this.situacaoVenda = this.model.situacao = 4;
                    this.etapaSelecionada = 3;
                }
                if (this.saldoPagar > 0 && this.situacaoVenda == 4) {
                    this.situacaoVenda = this.model.situacao = 2;
                    this.etapaSelecionada = 2;
                } else if (this.etapaSelecionada > 1 && this.saldoPagar > 0) this.etapaSelecionada = 1;
            }
        }
    }

    private async atualizarSituacaoVenda(nota = false) {
        if (this.situacaoVenda == SituacoesVenda.Entrega && nota) {
            if (this.onAlertFinaliza()) {
                this.situacaoVenda = SituacoesVenda.Concluido;
                this.model.situacao = SituacoesVenda.Concluido;
            }
        }

        if (
            this.saldoPagar == 0 &&
            (this.situacaoVenda == SituacoesVenda.Aprovado ||
                this.situacaoVenda == SituacoesVenda.Faturado ||
                this.situacaoVenda == SituacoesVenda.EmFaturamento)
        ) {
            this.situacaoVenda = this.model.situacao = SituacoesVenda.Faturado;
            this.etapaSelecionada = 1;
        }
        if (this.saldoPagar > 0 && this.situacaoVenda == SituacoesVenda.EmFaturamento) {
            this.situacaoVenda = this.model.situacao = SituacoesVenda.EmFaturamento;
            this.etapaSelecionada = 1;
        }

        if (this.situacaoVenda == SituacoesVenda.Orcamento) {
            this.etapaSelecionada = 0;
            this.etapasProgressbar = ["Orçamento", "Venda", "Fracionamento", "Concluido"];
        } else if (this.situacaoVenda == SituacoesVenda.Aprovado) {
            this.etapasProgressbar = [
                "Orçamento",
                "Venda",
                this.isFracionamento ? "Fracionamento" : "Faturado",
                "Concluido",
            ];
        } else if (this.situacaoVenda == SituacoesVenda.Fracionando) {
            this.etapaSelecionada = 1;
            this.etapasProgressbar = ["Orçamento", "Fracionamento", "Expedição", "Concluido"];
        } else if (this.situacaoVenda == SituacoesVenda.Expedicao) {
            this.etapaSelecionada = 1;
            this.etapasProgressbar = ["Orçamento", "Expedição", "Faturado", "Concluido"];
        } else if (
            this.situacaoVenda == SituacoesVenda.Faturado ||
            (!this.isFracionamento && this.situacaoVenda == SituacoesVenda.ExpedicaoNota)
        ) {
            this.etapasProgressbar = ["Orçamento", "Faturado", "Nota", "Concluido"];
        } else if (this.situacaoVenda == SituacoesVenda.EmFaturamento) {
            this.etapasProgressbar = ["Orçamento", "Faturado", "Nota", "Concluido"];
        } else if (this.model.situacao == SituacoesVenda.ExpedicaoNota) {
            this.etapaSelecionada = 1;
            this.etapasProgressbar = ["Orçamento", "Nota", "Entrega", "Concluido"];
        } else if (this.model.situacao == SituacoesVenda.Entrega) {
            this.etapaSelecionada = 2;
            this.etapasProgressbar = ["Orçamento", "Nota", "Entrega", "Concluido"];
        } else if (this.situacaoVenda == SituacoesVenda.Concluido) {
            this.etapaSelecionada = 3;
            this.etapasProgressbar = ["Orçamento", "Nota", "Entrega", "Concluido"];
        } else if (this.situacaoVenda == SituacoesVenda.Entregue) {
            this.etapaSelecionada = 3;
            this.etapasProgressbar = ["Orçamento", "Venda", "Entregue", "Concluido"];
        }

        if (this.etapaSelecionada > 1 && this.saldoPagar > 0) {
            this.etapaSelecionada = 1;
        }
    }

    private async onShowHistoricoStatus(open: (slot: number, title: string, size: string) => {}) {
        open(3, this.$t("__.ts.historicoAlteracoes") as string, "lg-modal");
    }

    private async showLinkPagamento(open: (slot: number, title: string, size: string) => {}) {
        open(4, this.$t("__.ts.LinkPagamento") as string, "md-h-flex-modal");
    }

    private async onConfirmFinanceiro() {
        this.shortcutComponent.hide();
        this.shortcutComponentFinanceiro.hide();
        this.loadPagamentoComponent = false;

        // Se não tem permissão para salvar, somente fatura a venda
        if (!this.validarVisibilidadeBotao("salvarImprimir")) {
            const sucesso = await this.service.updateFaturamento(this.model).withLoading().resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.alt"), this.$t("__.ts.salvoSucess"));
            }
        } else {
            if (this.permiteParcelamentoVenda) {
                this.model.contaReceber = this.financeiroComponent.model;
                this.calcularSaldoPagar();

                if (this.isFracionamento && this.saldoPagar == 0) {
                    this.avancarEtapa();
                }
            } else {
                this.calcularSaldoPagar();
            }

            this.setVendaFaturada();
        }
    }

    private async onAddCliente1(closeModal) {
        if (await this.clienteComponent1.save()) {
            this.loadClienteComponent1 = false;
            closeModal();
        }
    }

    private async onAddCliente2(closeModal) {
        if (await this.clienteComponent2.save()) {
            this.loadClienteComponent2 = false;
            closeModal();
        }
    }

    private async openNewCliente1() {
        this.loadClienteComponent1 = true;

        while (!this.$refs.clienteComponent1) await Delay(5);
        this.clienteComponent1 = this.$refs.clienteComponent1 as ClienteComponent;

        this.onClienteOpenModal(this.clienteComponent1, null);
    }

    private async openComboEditCliente1(id: number) {
        this.loadClienteComponent1 = true;

        while (!this.$refs.clienteComponent1) await Delay(5);
        this.clienteComponent1 = this.$refs.clienteComponent1 as ClienteComponent;

        this.onClienteOpenModal(this.clienteComponent1, id);
    }

    private async openNewCliente2() {
        this.loadClienteComponent2 = true;

        while (!this.$refs.clienteComponent2) await Delay(5);
        this.clienteComponent2 = this.$refs.clienteComponent2 as ClienteComponent;

        this.onClienteOpenModal(this.clienteComponent2, null);
    }

    private async openComboEditCliente2(id: number) {
        this.loadClienteComponent2 = true;

        while (!this.$refs.clienteComponent2) await Delay(5);
        this.clienteComponent2 = this.$refs.clienteComponent2 as ClienteComponent;

        this.onClienteOpenModal(this.clienteComponent2, id);
    }

    private async onClienteOpenModal(clienteComponent: ClienteComponent, id: number) {
        clienteComponent.modelId = id;
        clienteComponent.load();
    }

    private async onClienteSaveOk(modelNovoCliente: ClienteModel) {
        this.model.clienteId = modelNovoCliente.id;
    }

    private async onCopiar() {
        const replica = await this.service
            .getReplicaOrcamento(this.model.id)
            .withLoading()
            .resolveWithJSON<VendaModel>();

        this.iniciarContaReceber(replica);
        replica.nomeVendedor = this.usuarioLogado.nome;

        this.$emit("replicar-venda", replica);
    }

    private async onWhats() {
        const prodsArray: string[] = [];

        for (let i = 0; i < this.model.itens.length; i++) {
            const prodStr = this.model.itens[i].produtoDescricao.replace("Manipulação ", "");
            if (prodStr != null) {
                prodsArray.push(prodStr);
            }
        }

        const venda = new VendaContinuoModel();
        venda.vendaId = this.model.id;

        const message = venda.getMensagemWhats(prodsArray, [], this.model.valorFinal, this.model.dataValidadeOrcamento);

        await this.vendaContinuoUtilsService.showWhatsMessage(message, venda);
    }

    private toggleUploadReceita(show: boolean) {
        if (show) {
            this.loadUploadReceitaComponent = true;
            this.shortCutUploadReceita.title = "Upload Receita";
            this.shortCutUploadReceita.show();
        } else {
            this.shortCutUploadReceita.hide();
        }
    }

    private async toggleHistoricoVenda() {
        this.shortcutHistoricoVenda = this.$refs.shortcutHistoricoVenda as ShortcutComponent;
        this.shortcutHistoricoVenda.title = this.$t(
            "__.Components.parent.venda.editSingle_vue_html.historicoVendas",
        ).toString();
        this.shortcutHistoricoVenda.show();

        this.vendaHisticoComponent = this.$refs.vendaHisticoComponent as VendaHisticoComponent;
        await this.vendaHisticoComponent.load();
    }

    private async onOpenVendasModal() {
        while (!this.$refs.vendasClienteListComponent) await Delay(5);
        this.vendasClienteListComponent = this.$refs.vendasClienteListComponent as VendasClienteListComponent;
        this.vendasClienteListComponent.show(this.model.clienteId, this.model.clienteNome);
    }

    private async onConfirmRevisao() {
        try {
            const validou = await this.revisaoCamposComponent.validaRevisaoCampos();
            if (validou) {
                this.shortCutRevisaoCampos.hide();
                if (this.revisaoCamposComponent.modelsOrigin.length > 0) {
                    for (let index = 0; index < this.model.itens.length; index++) {
                        const item = this.model.itens[index];
                        if (item.manipulacaoOrdem) {
                            await this.vendaItemWrapperComponent.load(item);
                            this.vendaItemWrapperComponent.manipulacaoEditComponent.model.prescritorId =
                                this.revisaoCamposComponent.modelsOrigin[index].prescritorId;
                            this.vendaItemWrapperComponent.manipulacaoEditComponent.aprovouDesconto =
                                this.revisaoCamposComponent.modelsOrigin[index].aprovouDesconto;
                            await Delay(1000);
                            await this.vendaItemWrapperComponent.manipulacaoEditComponent.carregarConvenio(true);
                            this.model.itens[index] = this.vendaItemWrapperComponent.getModel();

                            this.atualizarTotalProdutos();
                        }
                    }
                }

                this.loadRevisaoComponent = false;
                this.avancarEtapa();
            }
        } catch {}
    }

    private inFocus() {
        //se nao estao mostrando nenhum deles, pode continuar o teste nos filhos
        if (
            this.shortCutRevisaoCampos != null &&
            !this.shortCutRevisaoCampos.isShowing() &&
            (!this.shortcutComponent || !this.shortcutComponent.isShowing())
        ) {
            //verificar no item wrapper//se true eh pq nao tem shortcut aberto //todo: rever isso para combos depois, talvez uma variavel global
            return this.vendaItemWrapperComponent.inFocus();
        } else return false;
    }

    private onSelecionar() {
        this.shortcutComponent2.title = "Enviar Orçamento";
        this.shortcutComponent2.show();
    }

    private async toggleEntregaComponent() {
        this.loadEnderecoEntrega = true;

        while (!this.$refs.enderecoEntregaComponent) await Delay(5);
        this.enderecoEntregaComponent = this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent;

        this.shortcutComponentEntrega.title = this.$t("__.ts.endeEntrega").toString();

        this.enderecoEntregaComponent.setVenda(this.model);
        this.shortcutComponentEntrega.show();
    }

    private async toggleCupomComponent() {
        this.loadCupomComponent = true;

        while (!this.$refs.adicionarCupomComponent) await Delay(5);
        this.adicionarCupomComponent = this.$refs.adicionarCupomComponent as AdicionarCupomComponent;

        if (!this.shortcutComponentCupom)
            this.shortcutComponentCupom = this.$refs.shortcutComponentCupom as ShortcutComponent;

        this.shortcutComponentCupom.title = "Informar cupom";
        this.shortcutComponentCupom.show();
    }

    private async onConfirmCupom() {
        this.adicionarCupomComponent.validarCupom();
    }

    private onAdicionarCupom(cupom: CupomModel) {
        this.loadCupomComponent = false;
        this.shortcutComponentCupom.hide();
        if (cupom.tipoDesconto == TipoDesconto.Dinheiro) {
            this.model.desconto = cupom.valorDesconto;
        } else {
            this.model.desconto = arithmeticHelper.round(this.model.valorFinal * (cupom.valorDesconto / 100));
        }
        this.model.cupomId = cupom.id;
    }

    private hideEnderecoEntrega() {
        this.enderecoEntregaComponent.resetVenda();
        this.model = this.enderecoEntregaComponent.getVenda();

        this.loadEnderecoEntrega = false;
    }

    private onConfirmEndereco() {
        this.model = this.enderecoEntregaComponent.getVenda();

        if (this.model.taxaEntrega > 0 && this.model.enderecoEntregaId <= 0) {
            this.$showWarning(this.$t("__.ts.endeEntrega"), "Obrigatório selecionar o endereço de entrega");
        } else {
            this.validarSaida = false;
            this.shortcutComponentEntrega.hidewithoudOutputEvent();
            this.loadEnderecoEntrega = false;
        }
    }

    private beforeDestroy() {
        this.active = false;
        if (this.$children.length > 0) {
            for (let i = 0; i < this.$children.length; i++) {
                this.destroyChildrens(this.$children[i], 0);
                this.$children[i].$destroy();
                this.$children[i] = null;
            }
        }
        this.clearAll();
    }

    destroyChildrens(object: any, nivel: number) {
        if (object != null && object.$children != null) {
            if (object.$children.length > 0) {
                for (let i = 0; i < object.$children.length; i++) {
                    this.destroyChildrens(object.$children[i], nivel + 1);
                    object.$children[i].$destroy();
                    object.$children[i] = null;
                }
            }
        }
    }

    private clearAll() {
        this.service = null;
        this.clienteService = null;
        this.campanhaService = null;
        this.pagamentoComponent = null;
        this.vendaItemWrapperComponent = null;
        this.shortcutComponent = null;
        this.clienteComponent1 = null;
        this.clienteComponent2 = null;
        this.enderecoEntregaComponent = null;
        this.shortCutRevisaoCampos = null;
        this.shortCutUploadReceita = null;
        this.shortcutComponent2 = null;
        this.shortcutComponentDataContinuo = null;
        this.shortcutComponentEntrega = null;
        this.revisaoCamposComponent = null;
        this.model = null;
        this.itemModel = null;
        this.modelItem = null;
        this.campanhasOptions = null;
        this.recompensasOptions = null;
        this.pontosAcumulados = null;
        this.gridSelectedImpValues = null;
    }

    private loadPermissao() {
        if (this.isFracionamento) {
            this.etapasProgressbar = ["Orçamento", "Fracionamento", "Expedição", "Concluido"];
            this.resetEmitirNFAutomaticamente(false);
            this.exibirNF = false;
        }
    }

    public async onAlertFracionamento() {
        await this.$showSuccess(this.$t("__.ts.aprovado"), "A venda encontra-se em separação pelo fracionamento");

        return true;
    }

    public async onAlertFinaliza() {
        const question = "Esta ação irá finalizar a venda, deseja continuar?";

        const response = await this.$showQuestion(this.$t("__.ts.atencao"), question);

        if (response) {
            this.$showSuccess(this.$t("__.ts.concluido"), "A venda foi finalizada");
        }

        return response;
    }

    public async onImprimirOrcamento() {
        await this.orcamentoPDFService.geraPDF(this.model.id);
    }

    private setVendaFaturada() {
        if (
            this.model.contaReceber != null &&
            (this.model.contaReceber.movimentacoes.length > 0 ||
                this.model.contaReceber.lancamentosVinculados.some(p => p.movimentacoes.length > 0))
        ) {
            this.vendaFaturada = this.saldoPagar == 0;
        } else {
            if (this.vendaFaturada) {
                this.etapaSelecionada = 1;
                this.situacaoVenda = this.model.situacao = SituacoesVenda.Aprovado;
            }
            this.vendaFaturada = false;
        }
    }

    private async getFranquiaAbrangencia() {
        const config = this.GET_CONFIG_FRANQUIA(Configuracoes.FranquiaAbrangencia);
        this.franquiaAbrangencia = config && config.verdadeiro;
        if (this.franquiaAbrangencia) {
            await this.loadFranquias().withLoading();
        }
    }

    private async loadFranquias() {
        try {
            const data = await new FranquiaService()
                .listFranquiasRede(false, this.franquiaAbrangencia)
                .resolveWithJSON<PaginationModel<FranquiaModel>>();
            this.franquiaAbrangenciaOptions = data.list.map(item => ({ value: item.id, text: item.nomeFantasia }));
        } catch {}
    }

    private validarVisibilidadeBotao(botao: string): boolean {
        if (botao === "imprimirVenda") {
            return this.modelId > 0 && this.VALIDAR_PERMISSAO_USUARIO("vendas", "ImprimirVenda");
        }

        if (botao === "cancelarVenda") {
            return (
                this.modelId > 0 &&
                !this.aprovouVenda &&
                this.situacaoVenda > 1 &&
                this.VALIDAR_PERMISSAO_USUARIO("vendas", "CancelarVenda")
            );
        }

        if (botao === "salvarImprimir" || botao === "fechar") {
            return !this.somenteConsultaVenda && this.situacaoVenda != SituacoesVenda.Cancelado;
        }

        if (botao === "aprovarOrcamento") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("vendas", "AprovarOrcamento") &&
                this.etapaSelecionada < 1 &&
                this.situacaoVenda < SituacoesVenda.Cancelado
            );
        }

        if (botao === "emitirNota") {
            if (this.obrigatorioFaturamentoParaEmitirNota && !this.vendaFaturada) {
                return false;
            }

            if (!this.VALIDAR_PERMISSAO_USUARIO("vendas", "EmitirNotaVenda")) {
                return false;
            }

            if (
                !this.isFracionamento &&
                this.etapaSelecionada > 0 &&
                this.etapaSelecionada < 4 &&
                (this.situacaoVenda < SituacoesVenda.Cancelado || this.situacaoVenda == SituacoesVenda.ExpedicaoNota) &&
                this.NFops.some(i => i.totalItens && !i.foiEmitida)
            ) {
                return true;
            }

            if (
                this.isFracionamento &&
                (this.situacaoVenda == SituacoesVenda.ExpedicaoNota ||
                    this.situacaoVenda == SituacoesVenda.EmFaturamento ||
                    this.situacaoVenda == SituacoesVenda.Concluido)
            ) {
                return true;
            }

            return false;
        }

        if (botao === "emitirNotaTransporte") {
            if (this.obrigatorioFaturamentoParaEmitirNota && !this.vendaFaturada) {
                return false;
            }

            if (
                !this.isFracionamento &&
                this.etapaSelecionada > 0 &&
                this.etapaSelecionada < 4 &&
                (this.situacaoVenda < SituacoesVenda.Cancelado || this.situacaoVenda == SituacoesVenda.ExpedicaoNota) &&
                this.VALIDAR_PERMISSAO_USUARIO("vendas", "EmitirNotaTransporte")
            ) {
                return true;
            }
        }

        if (botao === "faturar") {
            if (
                this.VALIDAR_PERMISSAO_USUARIO("vendas", "FaturarVenda") &&
                !this.vendaFaturada &&
                (this.situacaoVenda == SituacoesVenda.Aprovado || this.situacaoVenda == SituacoesVenda.EmFaturamento)
            ) {
                return true;
            }

            if (
                this.VALIDAR_PERMISSAO_USUARIO("vendas", "FaturarVenda") &&
                this.etapaSelecionada == 2 &&
                this.situacaoVenda < SituacoesVenda.Cancelado &&
                this.NFops.some(i => i.totalItens && i.emitirAutomaticamente)
            ) {
                return true;
            }

            return false;
        }

        if (botao === "financeiro") {
            if (this.situacaoVenda == SituacoesVenda.Rejeitado) {
                return !!this.model.contaReceber;
            }

            return (
                this.VALIDAR_PERMISSAO_USUARIO("vendas", "FaturarVenda") &&
                this.situacaoVenda != SituacoesVenda.Orcamento &&
                this.situacaoVenda != SituacoesVenda.Aprovado &&
                this.situacaoVenda != SituacoesVenda.Fracionando &&
                this.situacaoVenda != SituacoesVenda.Expedicao &&
                this.situacaoVenda != SituacoesVenda.EmFaturamento
            );
        }

        if (botao === "concluir") {
            if (!this.VALIDAR_PERMISSAO_USUARIO("vendas", "update") || this.model.id == null) {
                return false;
            }

            if (
                this.situacaoVenda == SituacoesVenda.Faturado &&
                this.NFops.some(i => i.totalItens && (!i.emitirAutomaticamente || i.foiEmitida)) &&
                (this.etapaSelecionada == 2 || (!this.isFracionamento && this.etapaSelecionada == 3))
            ) {
                return true;
            }

            if (
                ((this.finalizarSemNota || this.temNotaFiscalEmitida()) &&
                    (this.etapaSelecionada == 5 ||
                        this.etapaSelecionada == 4 ||
                        this.situacaoVenda == SituacoesVenda.ExpedicaoNota)) ||
                this.situacaoVenda == SituacoesVenda.Entrega
            ) {
                return true;
            }

            if (
                (this.etapaSelecionada == 5 && this.situacaoVenda > SituacoesVenda.Concluido) ||
                ((this.etapaSelecionada == 3 || this.etapaSelecionada == 4) &&
                    this.situacaoVenda < SituacoesVenda.Cancelado &&
                    this.NFops.some(i => i.totalItens && (!i.emitirAutomaticamente || i.foiEmitida)))
            ) {
                return true;
            }

            return false;
        }

        if (botao === "copiar") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("vendas", "CopiarVenda") &&
                (this.situacaoVenda == SituacoesVenda.Orcamento ||
                    this.situacaoVenda == SituacoesVenda.EmFaturamento ||
                    this.situacaoVenda == SituacoesVenda.Faturado ||
                    this.modelId > 0)
            );
        }

        if (botao === "enviarOrcamento") {
            return this.situacaoVenda == SituacoesVenda.Orcamento && this.modelId > 0;
        }

        if (botao === "expedicao") {
            return this.situacaoVenda == SituacoesVenda.Expedicao;
        }

        if (botao === "movimentacaoEstoque") {
            return this.temMovimentoEstoqueComItens && this.situacaoVenda != SituacoesVenda.Cancelado;
        }

        if (botao === "desconcluirVenda") {
            return this.situacaoVenda == SituacoesVenda.Concluido;
        }

        if (botao === "historicoVendas") {
            return this.VALIDAR_PERMISSAO_USUARIO("historico", "List");
        }

        if (botao === "preFaturamento") {
            return this.situacaoVenda >= SituacoesVenda.Aprovado;
        }

        if (botao === "linkPagamento") {
            return (
                this.VALIDAR_PERMISSAO_USUARIO("vendas", "LinkPagamentoVenda") &&
                this.modelId > 0 &&
                (this.situacaoVenda == SituacoesVenda.Orcamento || this.situacaoVenda >= SituacoesVenda.Aprovado) &&
                !this.vendaFaturada
            );
        }
    }

    private async onExtraButtonActionClickNfe(name: string) {
        if (name == "resumo") {
            this.nfeComponent.resumo();
        }
    }

    private onMovimentoEstoque() {
        this.$router.push("/movimentacaoestoque-edicao/" + this.model.movimentacaoEstoqueId);
    }

    private async onDesconcluirVenda() {
        try {
            const sucesso = await this.service.desconcluirVenda(this.model).withLoading().resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.sucesso"), "Conclusão da venda desfeita com sucesso!");
                this.load();
            }
        } catch {}
    }

    private onCancelarVenda() {
        this.$router.push("/venda-cancelamento/" + this.model.id);
    }

    private async validarCampos(validarDataEntrega = true, avanco = false) {
        if (this.model.itens.length == 0) {
            await this.$showError(this.$t("__.ts.erro"), "Informe ao menos um item na venda");
            return false;
        }

        if (!this.model.clienteId) {
            await this.$showError(this.$t("__.ts.erro"), "Informe um cliente para a venda");
            return false;
        }

        if (validarDataEntrega && this.model.situacao > 1 && !this.model.dataEntrega) {
            const config = this.GET_CONFIG_FRANQUIA(Configuracoes.DataEntregaObrigatoria);
            if (config && config.verdadeiro) {
                await this.$showError(this.$t("__.ts.erro"), "Informe uma data de entrega para a venda");
                return false;
            }
        }

        if (!this.isFracionamento && (avanco || this.model.situacao > 1)) {
            let manipulacoesRevisar: ManipulacaoOrdemModel[] = [];

            if (this.isEstereis) {
                manipulacoesRevisar = this.model.itens
                    .filter(p => p.manipulacaoOrdem != null)
                    .map(p => p.manipulacaoOrdem)
                    .filter(p => p.prescritorId == null || (p.possuiAtivoControlado && p.numeroReceita == null));
            } else {
                manipulacoesRevisar = this.model.itens
                    .filter(p => p.manipulacaoOrdem != null)
                    .map(p => p.manipulacaoOrdem)
                    .filter(
                        p =>
                            (p.posologiaId == null && !p.posologiaTexto) ||
                            p.pacienteId == null ||
                            p.prescritorId == null ||
                            (p.possuiAtivoControlado && p.numeroReceita == null),
                    );
            }
            this.loadRevisaoComponent = true;

            while (!this.$refs.revisaoCamposComponent) await Delay(5);
            this.revisaoCamposComponent = this.$refs.revisaoCamposComponent as RevisaoCamposComponent;

            const openModal =
                manipulacoesRevisar.length > 0 ||
                (!this.model.dataEntrega && this.revisaoCamposComponent.getDataEntregaObrigatoria());

            if (openModal) {
                this.shortCutRevisaoCampos = this.$refs.shortCutRevisaoCampos as ShortcutComponent;
                this.shortCutRevisaoCampos.title = this.$t("__.ts.revPreenchCampos") as string;

                if (openModal) {
                    await this.revisaoCamposComponent.setPreLoadPack(this.preLoadList).withLoading();
                    await this.revisaoCamposComponent.loadModels(manipulacoesRevisar, this.model).withLoading();

                    this.shortCutRevisaoCampos.show();
                }
                return false;
            }
        }

        if (
            this.alertarEstoqueMinimo &&
            this.isFracionamento &&
            (this.model.situacao == SituacoesVenda.Orcamento || this.model.situacao == SituacoesVenda.Fracionando)
        ) {
            const produtosVerificacao = new Array<ProdutoEstoqueConferenciaModel>();

            this.model.itens.forEach(value => {
                const produtoConferencia = new ProdutoEstoqueConferenciaModel();

                if (value.fracionamento != null) {
                    produtoConferencia.id = value.fracionamento.itens[0].produtoId;
                    produtoConferencia.quantidade = value.fracionamento.itens[0].quantidade;
                    produtoConferencia.unidadeMedidaManipulacaoId = value.fracionamento.itens[0].unidadeMedidaId;
                    produtosVerificacao.push(produtoConferencia);

                    if (value.fracionamento.itensEmbalagem.length > 0) {
                        value.fracionamento.itensEmbalagem.forEach(itens => {
                            const produtoConferenciaEmbalagem = new ProdutoEstoqueConferenciaModel();
                            produtoConferenciaEmbalagem.id = itens.produtoId;
                            produtoConferenciaEmbalagem.quantidade = itens.quantidade;
                            produtoConferenciaEmbalagem.unidadeMedidaManipulacaoId =
                                itens.produto.unidadeMedidaEstoqueId;
                            produtosVerificacao.push(produtoConferenciaEmbalagem);
                        });
                    }
                } else {
                    produtoConferencia.id = value.produtoId;
                    produtoConferencia.quantidade = value.quantidade;
                    produtoConferencia.unidadeMedidaManipulacaoId = value.unidadeMedidaId;
                    produtosVerificacao.push(produtoConferencia);
                }
            });
            if (produtosVerificacao.length > 0) {
                const dataVerificados = await new ProdutoEstoqueConferenciaService()
                    .listProdutosVerificados(produtosVerificacao)
                    .resolveWithJSON<ProdutoEstoqueConferenciaModel[]>();

                const produtosEstoqueBaixo = dataVerificados.filter(
                    p => p.statusEstoque == StatusEstoque.Baixo && p.quantidade > 0,
                );

                if (produtosEstoqueBaixo.length > 0) {
                    let html = "";
                    if (produtosEstoqueBaixo.length == 1) {
                        html = `${this.$t("__.ts.prod")} ${produtosEstoqueBaixo[0].descricao} ${this.$t(
                            "__.ts.estaComQtdAbaixoMinCad",
                        )} ${this.$t("__.ts.estoqueAtual")}: ${Math.round(produtosEstoqueBaixo[0].quantidadeTotal)}, ${
                            this.$t("__.ts.estoqueMinimo") as string
                        }: ${Math.round(produtosEstoqueBaixo[0].estoqueMinimo)}`;
                    } else {
                        const descricoes = this.getProdutosSemEstoqueDescricao(produtosEstoqueBaixo);
                        html = `${this.$t("__.ts.segProdsQtdsEstqAbaixMin")} ${descricoes}</b>.`;
                    }
                    this.$notifyWarn(html, this.$t("__.ts.avisEstqBaixo") as string);
                }
            }
        }

        if (
            this.$route.params.vendaReplicada &&
            this.model.itens.some(p => p.manipulacaoOrdem && !p.manipulacaoOrdem.consolidado)
        ) {
            await this.$showWarning(
                this.$t("__.ts.vendaReplicadaComManipulacao"),
                this.$t("__.ts.vendaReplicadaComManipulacaoSemConsolidar"),
            );
            return false;
        }
        return true;
    }

    private getProdutosSemEstoqueDescricao(produtos: ProdutoEstoqueConferenciaModel[]) {
        return produtos
            .map(
                p =>
                    `</br><b>${p.descricao}</b></br>${this.$t("__.ts.estoqueAtual")}: <b>${Math.round(
                        p.quantidadeTotal,
                    )}</b>, ${this.$t("__.ts.estoqueMinimo") as string}: <b>${Math.round(p.estoqueMinimo)}</b>`,
            )
            .join(", ");
    }

    private async handleModalDataContinuo(item: VendaItemModel, index: number) {
        this.shortcutComponentDataContinuo = this.$refs.shortcutComponentDataContinuo as ShortcutComponent;
        if (item.continuo) {
            if (this.model.itens[index].id && !this.model.itens[index].dataVencimentoContinuo) {
                const response = await this.vendaContinuoService
                    .getDataContinuo(this.model.itens[index], this.model.dataConclusao)
                    .withLoading()
                    .resolveWithJSON<Date>();

                this.model.itens[index].dataVencimentoContinuo = moment(response).toDate();
            }
            this.itemModelIndex = index;

            this.shortcutComponentDataContinuo.title = this.$t(
                "__.Components.parent.venda.editSingle_vue_html.dtVencimentoContinuo",
            ).toString();
            this.shortcutComponentDataContinuo.show();
        }
    }

    private onSaveModalDataVencimento() {
        if (this.model.itens.length > 0) {
            if (!this.model.itens[this.itemModelIndex].dataVencimentoContinuo) {
                return this.$showError(
                    this.$t("__.ts.erro"),
                    this.$t("__.Components.parent.venda.editSingle_vue_html.dtVencimentoContinuoVazio"),
                );
            }
        }
        //Usando hidewithoudOutputEvent para evitar enviar evento de closeModal
        this.shortcutComponentDataContinuo.hidewithoudOutputEvent();
    }

    private onCloseModalDataVencimento() {
        this.model.itens[this.itemModelIndex].continuo = false;
    }

    private onRefreshClientesDuplicados(closeShortcut: boolean) {
        if (closeShortcut) {
            this.clientesDuplicadosViewComponent.hide();
        }
    }

    private onHideShortcutClientesDuplicados() {
        this.model.clienteId = null;
    }

    private async getPreLoadPack() {
        while (!this.loadedList) await Delay(100);
    }

    private loadTabFinanceiro() {
        if (this.saldoPagar > 0) {
            if (this.tabFinanceiro != this.$t("__.Components.parent.venda.editSingle_vue_html.novoPgto")) {
                this.tabFinanceiro = this.$t("__.Components.parent.venda.editSingle_vue_html.novoPgto") as string;
            }
        } else if (this.tabFinanceiro != this.$t("__.Components.parent.venda.editSingle_vue_html.pagamentos")) {
            this.tabFinanceiro = this.$t("__.Components.parent.venda.editSingle_vue_html.pagamentos") as string;
        }
    }

    private async toggleOverlay() {
        while (!this.$refs.chatBotReceitaComponent) await Delay(5);
        this.chatBotReceitaComponent = this.$refs.chatBotReceitaComponent as ChatBotReceitaComponent;
        this.chatBotReceitaComponent.toggleOverlay();
    }

    private beforeMount() {
        this.active = true;
    }

    private async mounted() {
        await this.runMonted().withLoading();

        Bus.$on("venda-update", (vendaId: number) => {
            if (this.modelId === vendaId) {
                this.load(this.modelId);
            }
        });
    }

    private async runMonted() {
        await this.$store.dispatch(SessionActions.LOAD_USUARIO_LOGADO);
        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.isEstereis = await this.GET_IS_ESTEREIS();
        this.loadPermissao();

        this.setorOptions = this.preLoadList.setoresCombo();

        Promise.all([this.getPreLoadPack(), this.getFranquiaAbrangencia()]).then(() => {
            this.locaisEntregaOptions = this.preLoadList.locaisEntregaCombo();
            this.estoqueOptions = this.preLoadList.estoques
                .filter(p => p.permiteVenda)
                .map(p => ({ value: p.id, text: p.descricao }));
            this.usuariosOptions = this.preLoadList.vendedoresCombo();
        });

        while (!this.CONFIG_FRANQUIA_LOADED()) await Delay(100);

        this.clienteComponent1 = this.$refs.clienteComponent1 as ClienteComponent;
        this.clienteComponent2 = this.$refs.clienteComponent2 as ClienteComponent;

        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.shortcutComponentFinanceiro = this.$refs.shortcutComponentFinanceiro as ShortcutComponent;

        this.solicitarSenhaComponent = this.$refs.solicitarSenhaComponent as SolicitarSenhaComponent;
        this.vendaItemWrapperComponent = this.$refs.vendaItemWrapperComponent as VendaItemWrapperComponent;
        this.nfeComponent = this.$refs.nfeComponent as NfeEditComponent;
        this.shortCutRevisaoCampos = this.$refs.shortCutRevisaoCampos as ShortcutComponent;
        this.shortCutUploadReceita = this.$refs.shortCutUploadReceita as ShortcutComponent;
        this.shortcutComponent2 = this.$refs.shortcutComponent2 as ShortcutComponent;
        this.adicionarCupomComponent = this.$refs.adicionarCupomComponent as AdicionarCupomComponent;
        this.shortcutComponentCupom = this.$refs.shortcutComponentCupom as ShortcutComponent;
        this.shortcutComponentEmissao = this.$refs.shortcutComponentEmissao as ShortcutComponent;
        this.preFaturamentoComponent = this.$refs.preFaturamentoComponent as PreFaturamentoComponent;
        this.enderecoEntregaComponent = this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent;
        this.shortcutComponentEntrega = this.$refs.shortcutComponentEntrega as ShortcutComponent;
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;

        const emiteNotaServicoConfig = this.GET_CONFIG_FRANQUIA(Configuracoes.EmiteNotaDeServico);

        const obsDesconto = this.GET_CONFIG_FRANQUIA(Configuracoes.ObsDesconto);
        if (obsDesconto) {
            this.observacaoDesconto = obsDesconto.verdadeiro;
        }

        if (emiteNotaServicoConfig) {
            this.emiteNotaServico = emiteNotaServicoConfig.verdadeiro;
        }

        const obrigatorioFaturamentoParaEmitirNota = this.GET_CONFIG_FRANQUIA(
            Configuracoes.ObrigatorioFaturamentoParaEmitirNota,
        );
        if (obrigatorioFaturamentoParaEmitirNota) {
            this.obrigatorioFaturamentoParaEmitirNota = obrigatorioFaturamentoParaEmitirNota.verdadeiro;
        }

        const permiteParcelamentoVenda = this.GET_CONFIG_FRANQUIA(Configuracoes.PermiteParcalamentoVenda);
        if (permiteParcelamentoVenda) {
            this.permiteParcelamentoVenda = permiteParcelamentoVenda.verdadeiro;
        }

        const permiteNotaSemTomador = this.GET_CONFIG_FRANQUIA(Configuracoes.PermiteEnviarNotaSemTomador);
        if (permiteNotaSemTomador) {
            this.permiteNotaSemTomador = permiteNotaSemTomador.verdadeiro;
        }

        const alertarEstoqueMinimo = this.GET_CONFIG_FRANQUIA(Configuracoes.AlertarEstoqueMinimo);
        if (alertarEstoqueMinimo) {
            this.alertarEstoqueMinimo = alertarEstoqueMinimo.verdadeiro;
        }

        this.percentualDescontoMaximoVenda = 0;
        const percentualDescontoMaximo = this.GET_CONFIG_FRANQUIA(Configuracoes.PercentualDescontoMaximo);
        if (percentualDescontoMaximo) {
            this.percentualDescontoMaximoVenda = percentualDescontoMaximo.valor;
        }

        const percentualDescontoMaximoUsuario = this.GET_CONFIG_USUARIO(ConfiguracoesUsuario.PercentualMaximoDesconto);
        if (percentualDescontoMaximoUsuario?.valorDecimal > 0) {
            this.percentualDescontoMaximoVenda = percentualDescontoMaximoUsuario.valorDecimal;
        }

        const emissaoNotaAutomatica = this.GET_CONFIG_FRANQUIA(Configuracoes.EmissaoNotaAutomatica);
        if (emissaoNotaAutomatica) {
            this.NFops.forEach(i => this.$set(i, "emitirAutomaticamente", emissaoNotaAutomatica.verdadeiro));
        }

        this.isFranqueador = await this.HAS_PERMISSAO_PERFIL(false, true, false);
        this.isFranqueadorSuporte = await this.HAS_PERMISSAO_PERFIL(false, true, true);
        this.model.estoqueId = this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId;
        this.model.nomeVendedor = this.usuarioLogado.nome;

        this.permiteAlterarVendaFaturada =
            this.GET_CONFIG_USUARIO(ConfiguracoesUsuario.PermitirAlterarVendaFaturada)?.verdadeiro ?? false;

        if ((this.isFracionamento || this.isEstereis) && !this.modelId) {
            this.model.usuarioId = this.usuarioLogado.id;
            const vendedor = this.preLoadList.vendedores.find(x => x.id === this.usuarioLogado.id);
            if (vendedor) {
                this.model.setorId = vendedor.setorId;
            }
        }

        if (this.replicaModel != null) {
            this.model.updateFrom(this.replicaModel);
            if (this.model.situacao == SituacoesVenda.Aprovado) this.etapaSelecionada = 1;
            this.situacaoVenda = this.model.situacao;
            this.iniciarContaReceber(this.model);

            this.$emit("edit-venda", this.model, this.indexVenda);
        } else {
            if (this.modelId > 0 && (this.model.id == null || this.model.id == 0)) {
                await this.load().withLoading();
            } else if (this.modelId == 0) {
                const horasValidade = this.GET_CONFIG_FRANQUIA(Configuracoes.HorasValidadeOrcamento);

                this.model.dataValidadeOrcamento = addHours(horasValidade ? horasValidade.valor : 0, new Date());

                this.iniciarContaReceber(this.model);

                this.resetEmitirNFAutomaticamente();

                if (this.clienteId != null && this.clienteId > 0) {
                    this.model.clienteId = <number>this.clienteId;
                }

                if (this.pacienteId != null && this.pacienteId > 0) this.pacienteIdApp = this.pacienteId;

                if (this.origem) {
                    this.model.origem = this.origem as OrigemAtendimento;
                }

                if (this.enderecoEntregaId != null && this.enderecoEntregaId > 0)
                    this.model.enderecoEntregaId = this.enderecoEntregaId;

                this.requisicaoApp =
                    this.clienteId > 0 && <OrigemAtendimento>this.origem == OrigemAtendimento.Aplicativo;

                this.$emit("loaded-new-venda", this.indexVenda);
                this.loadCampanhas();
            }
        }
        const campanhaPadrao = this.preLoadList.campanhas.find(p => p.padrao);
        if (campanhaPadrao) {
            this.model.campanhaId = campanhaPadrao.id;
        }

        this.somenteConsultaVenda = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("vendas");

        if (this.$route.params.vendaReplicada && this.model.itens.some(p => p.manipulacaoOrdem)) {
            this.$showWarning(
                this.$t("__.ts.vendaReplicadaComManipulacao"),
                this.$t("__.ts.vendaReplicadaComManipulacaoConsolidar"),
            );
            this.model.itens
                .filter(p => p.manipulacaoOrdem)
                .forEach(p => {
                    p.manipulacaoOrdem.consolidado = false;
                });
        }
    }
}
