import BaseModel from "./base/baseModel";
import ConfiguracaoRotuloCamposModel from "./configuracaoRotuloCamposModel";
import { Fonte } from "./enum/fonte";
import FormatoImpressaoRotulo from "./enum/rotulo/formatoImpressaoRotulo";
import OrigemRotulo from "./enum/rotulo/origemRotulo";
import PosicaoDadosFranquia from "./enum/rotulo/posicaoDadosFranquia";
import PosicaoRotulo from "./enum/rotulo/posicaoRotulo";
import RespTecDadosFranquia from "./enum/rotulo/respTecDadosFranquia";

export default class ConfiguracaoRotuloModel extends BaseModel {
    vendaId?: number = null;
    produtoId?: number = null;
    manipulacaoId?: number = null;
    id?: number = null;
    nome: string = null;
    largura = 90;
    altura = 21;
    paddingVertical = 1;
    paddingHorizontal = 3;
    larguraLogo?: number = 30;
    alturaLogo?: number = 30;
    posicaoLogo: PosicaoRotulo = PosicaoRotulo.Nenhuma;
    dadosFranquia: string = null;
    imprimirDadosFranquiaNegrito?: boolean = null;
    fonteDadosFranquia?: number = 7;
    dimensoes?: string = null;
    origemDados: OrigemRotulo = OrigemRotulo.Lote;
    posicaoQrCode: PosicaoRotulo = PosicaoRotulo.Nenhuma;
    larguraCode?: number = 25;
    alturaCode?: number = 25;
    quantidadeColunas?: number = 8;
    observacao: string = null;
    observacaoFonte = 7;
    observacaoNegrito = false;
    dadosId?: number = null;
    campos: Array<ConfiguracaoRotuloCamposModel> = [];
    configuracaoRotuloPaiId?: number = null;
    fonteRotulo = Fonte.Default;
    paddingLine = 1;
    nomeImpressora: string = null;
    formatoImpressao: FormatoImpressaoRotulo = FormatoImpressaoRotulo.Paisagem;
    dadosResponsavelTecnicoFranquia: string = null;
    posicaoDadosFranquia: PosicaoDadosFranquia = PosicaoDadosFranquia.Esquerda;
    respTecDadosFranquia: RespTecDadosFranquia = RespTecDadosFranquia.AmbosMesmaLinha;

    /** Campo não usado no backend */
    copias?: number = 1;
    imprimir = true;
}
