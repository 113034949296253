enum TipoTemplateWhatsApp {
    EnvioOrcamentoContinuos = 1,
    EnvioManipulacaoEmProducao = 2,
    EnvioManipulacaoEmExecucao = 3,
    EnvioManipulacaoSaiuParaEntrega = 4,
    EnvioManipulacaoEntregue = 5,
    MensagemSaudacao = 6,
    MensagemAusencia = 7,
}

export default TipoTemplateWhatsApp;
