import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import ControleReclamacaoModel from "@/models/controleReclamacaoModel";
import ControleReclamacaoService from "@/services/controleReclamacaoService";

import ListComponentBase from "../listComponentBase";

@Component
export default class ControleReclamacaoListComponent extends ListComponentBase<ControleReclamacaoModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("dataReclamacao", "Data Reclamação", GridColumnType.Date),
            new GridColumn("clienteNome", this.$t("__.ts.cliente"), GridColumnType.String),
            new GridColumn("tipoDescricao", this.$t("__.ts.tipo"), GridColumnType.String),
            new GridColumn("vendaCodigo", this.$t("__.ts.codVenda"), GridColumnType.String),
            new GridColumn("reclamacao", this.$t("__.ts.reclamacao"), GridColumnType.String),
            new GridColumn("acao", this.$t("__.ts.acao"), GridColumnType.String),
            new GridColumn("categoriaControleReclamacaoDescricao", this.$t("__.ts.categoria"), GridColumnType.String),
            new GridColumn(
                "justificativaControleReclamacaoDescricao",
                this.$t("__.ts.justificativa"),
                GridColumnType.String,
            ),
            new GridColumn("statusDescricao", this.$t("__.ts.status"), GridColumnType.String),
            new GridColumn("setorDescricao", this.$t("__.ts.setor"), GridColumnType.String),
            new GridColumn("produtoLoteDescricao", this.$t("__.ts.lote"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new ControleReclamacaoService(), "controlereclamacao", "datareclamacao", false, "desc");
    }
}
