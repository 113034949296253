import { MutationTree } from "vuex";

import { addItemManipulacaoContext, updateManipulacao } from "@/components/parent/manipulacao/manipulacaoUtils";
import SituacoesManipulacaoOrdem from "@/models/enum/situacoesManipulacaoOrdem";
import TiposCalculoManipulacao from "@/models/enum/tiposCalculoManipulacao";
import TiposItemManipulacao from "@/models/enum/tiposItemManipulacao";
import FormaFarmaceuticaModel from "@/models/formaFarmaceuticaModel";
import ManipulacaoOrdemItemModel from "@/models/manipulacaoOrdemItemModel";
import ManipulacaoOrdemModel from "@/models/manipulacaoOrdemModel";
import IndicesConversoesUnidadeMedidasService from "@/services/indicesConversoesUnidadeMedidaService";
import { isEqual } from "@/utils/common/copyObject";

import {
    ActionItemType,
    ItemTableType,
    ManipulacaoContextState,
    ManipulacaoContextUUID,
    UpdateItemDesmembradoType,
    UpdateItemType,
} from "./types";

const indicesConversoesUnidadeMedidasService = new IndicesConversoesUnidadeMedidasService();

const mutations: MutationTree<Map<string, ManipulacaoContextState>> = {
    ADD_NEW_CONTEXT(store, { id, franquiaId }: { id: string; franquiaId: number }) {
        const newState = new ManipulacaoContextState();
        newState.indicesConversoesUnidadeMedidasService = indicesConversoesUnidadeMedidasService;
        // newState.isFranquiaConsolidarBackend = franquiaId == 93;
        newState.isFranquiaConsolidarBackend = false;
        store.set(id, newState);
    },
    RESET_MANIPULACAO(store, data: ManipulacaoContextUUID<number>) {
        const newState = new ManipulacaoContextState();
        newState.indicesConversoesUnidadeMedidasService = indicesConversoesUnidadeMedidasService;
        // newState.isFranquiaConsolidarBackend = data.data == 93;
        newState.isFranquiaConsolidarBackend = false;
        store.set(data.uuid, newState);
    },
    UPDATE_MANIPULACAO(store, { uuid, data }: ManipulacaoContextUUID<ManipulacaoOrdemModel>) {
        let state = store.get(uuid);

        if (isEqual(state.manipulacao, data)) {
            return;
        }

        state.manipulacao = data;

        if (state.ativos.length == 0) {
            state.ativos = state.manipulacao.itens.filter(
                p =>
                    (p.tipoItem == TiposItemManipulacao.MateriaPrima ||
                        p.tipoItem == TiposItemManipulacao.FormulaPadrao) &&
                    p.produtoPai == null,
            );
        }

        if (state.manipulacao.itens.some(p => p.tipoItem == TiposItemManipulacao.FormulaPadrao)) {
            if (state.desmembrados.length == 0 && state.manipulacao.itens.some(p => p.produtoPai != null)) {
                state.desmembrados = state.manipulacao.itens.filter(p => p.produtoPai != null);
            }
        } else {
            state.desmembrados = [];
        }

        if (state.embalagens.length == 0) {
            state.embalagens = state.manipulacao.itens.filter(
                p => p.tipoItem == TiposItemManipulacao.Embalagem || p.tipoItem == TiposItemManipulacao.KitEmbalagem,
            );
        }

        if (state.capsulas.length == 0) {
            state.capsulas = state.manipulacao.itens.filter(p => p.tipoItem == TiposItemManipulacao.Capsula);
        }

        if (
            state.manipulacao.situacao == SituacoesManipulacaoOrdem.Cancelado ||
            state.manipulacao.situacao == SituacoesManipulacaoOrdem.Concluido
        ) {
            state.isDesabilitado = true;
        }

        state = updateManipulacao(state);
        store.set(uuid, state);
    },
    UPDATE_MANIPULACAO_KIT_EMBALAGEM_ID(store, { uuid, data }: ManipulacaoContextUUID<number>) {
        const state = store.get(uuid);
        state.manipulacao.kitEmbalagemId = data;
        store.set(uuid, state);
    },
    SET_FORMA_FARMACEUTICA(store, { uuid, data }: ManipulacaoContextUUID<FormaFarmaceuticaModel>) {
        const state = store.get(uuid);
        state.formaFarmaceutica = data;
        store.set(uuid, state);
    },
    SET_UPLOADING_EDITING_MODEL(store, { uuid, data }: ManipulacaoContextUUID<boolean>) {
        const state = store.get(uuid);
        state.uploadingEditingModel = data;
        store.set(uuid, state);
    },
    SET_UPLOADING_PREVENDA(store, { uuid, data }: ManipulacaoContextUUID<boolean>) {
        const state = store.get(uuid);
        state.uplodingPrevenda = data;
        store.set(uuid, state);
    },
    SET_IS_CAPSULA(store, { uuid, data }: ManipulacaoContextUUID<boolean>) {
        const state = store.get(uuid);
        state.isCapsula = data;
        store.set(uuid, state);
    },
    SET_IS_CONSOLIDANDO(store, { uuid, data }: ManipulacaoContextUUID<boolean>) {
        const state = store.get(uuid);
        state.isConsolidando = data;
        store.set(uuid, state);
    },
    SET_IS_DESABILITADO(store, { uuid, data }: ManipulacaoContextUUID<boolean>) {
        const state = store.get(uuid);
        state.isDesabilitado = data;
        store.set(uuid, state);
    },
    SET_IS_ITEM_FROM_VENDA(store, { uuid, data }: ManipulacaoContextUUID<boolean>) {
        const state = store.get(uuid);
        state.isItemFromVenda = data;
        store.set(uuid, state);
    },
    SET_IS_TRANSFERENCIA(store, { uuid, data }: ManipulacaoContextUUID<boolean>) {
        const state = store.get(uuid);
        state.isTransferencia = data;
        store.set(uuid, state);
    },
    SET_IS_VENDA_REPLICADA(store, { uuid, data }: ManipulacaoContextUUID<boolean>) {
        const state = store.get(uuid);
        state.isVendaReplicada = data;
        store.set(uuid, state);
    },
    SET_CONCENTRACAO_MAXIMA(store, { uuid, data }: ManipulacaoContextUUID<number>) {
        const state = store.get(uuid);
        state.concentracaoMaxima = data;
        store.set(uuid, state);
    },
    ADD_ITEM_MANIPULACAO(store, { uuid, data }: ManipulacaoContextUUID<ActionItemType>) {
        let state = store.get(uuid);
        const { item, type } = data;

        if (item) {
            if (!item.valor) {
                item.valor = 0.01;
            }

            if (item.produtoListaControladoId) {
                state.temProdutoListacontrolado = true;
            }

            switch (type) {
                case ItemTableType.ATIVO:
                    state.ativos = addItemManipulacaoContext(
                        state.ativos,
                        item,
                        TiposItemManipulacao.MateriaPrima,
                        data,
                    );
                    break;
                case ItemTableType.EMBALAGEM:
                    state.embalagens = addItemManipulacaoContext(
                        state.embalagens,
                        item,
                        TiposItemManipulacao.Embalagem,
                        data,
                    );
                    break;
                case ItemTableType.CAPSULA:
                    state.capsulas = addItemManipulacaoContext(
                        state.capsulas,
                        item,
                        TiposItemManipulacao.Capsula,
                        data,
                    );
                    break;
            }

            state = updateManipulacao(state);
        } else {
            switch (type) {
                case ItemTableType.ATIVO:
                    state.ativos.push(item);
                    break;
                case ItemTableType.EMBALAGEM:
                    state.embalagens.push(item);
                    break;
                case ItemTableType.CAPSULA:
                    state.capsulas.push(item);
                    break;
            }
        }
        store.set(uuid, state);
    },
    ADD_ITENS_DESMEMBRADOS(store, { uuid, data }: ManipulacaoContextUUID<UpdateItemDesmembradoType>) {
        const state = store.get(uuid);
        if (data.desmembrados.length > 0) {
            state.desmembrados = state.desmembrados.filter(
                p => p.manipulacaoOrdemItemProdutoPaiId !== data.manipulacaoOrdemItemProdutoPaiId,
            );

            state.desmembrados = [...state.desmembrados, ...data.desmembrados];
        } else {
            state.desmembrados = [];
        }
        store.set(uuid, state);
    },
    REMOVE_EMBALAGENS_BY_KIT_ID(store, { uuid, data }: ManipulacaoContextUUID<number>) {
        const state = store.get(uuid);
        state.embalagens = state.embalagens.filter(p => p.kitEmbalagemId != data);
        store.set(uuid, state);
    },
    UPDATE_ITENS_KIT_EMBALAGEM(store, { uuid, data }: ManipulacaoContextUUID<ManipulacaoOrdemItemModel[]>) {
        const state = store.get(uuid);
        state.embalagens = data;
        store.set(uuid, state);
    },
    REMOVE_ALL_ITENS_BY_TYPE(store, { uuid, data }: ManipulacaoContextUUID<ItemTableType>) {
        const state = store.get(uuid);
        switch (data) {
            case ItemTableType.ATIVO:
                state.ativos = [];
                state.desmembrados = [];
                break;
            case ItemTableType.EMBALAGEM:
                state.embalagens = [];
                break;
            case ItemTableType.CAPSULA:
                state.capsulas = [];
                break;
        }
        store.set(uuid, state);
    },
    REMOVE_ITEM_MANIPULACAO(store, { uuid, data }: ManipulacaoContextUUID<ActionItemType>) {
        let state = store.get(uuid);
        switch (data.type) {
            case ItemTableType.ATIVO:
                const ativo = state.ativos[data.index];
                if (ativo.produtoPai == null && ativo.formulaPadraoId != null) {
                    state.desmembrados = state.desmembrados.filter(
                        p => p.manipulacaoOrdemItemProdutoPaiId !== ativo.produtoId,
                    );
                }
                state.ativos.splice(data.index, 1);
                break;
            case ItemTableType.EMBALAGEM:
                state.embalagens.splice(data.index, 1);
                break;
            case ItemTableType.CAPSULA:
                state.capsulas.splice(data.index, 1);
                break;
        }
        state = updateManipulacao(state);
        store.set(uuid, state);
    },
    CALCULAR_QSP(store, { uuid, data }: ManipulacaoContextUUID<ManipulacaoOrdemItemModel[]>) {
        let state = store.get(uuid);
        state.ativos = data;
        state = updateManipulacao(state);
        store.set(uuid, state);
    },
    REMOVE_ATIVOS_ASSOCIADOS(store, { uuid }: ManipulacaoContextUUID<null>) {
        const state = store.get(uuid);
        state.ativos = state.ativos.filter(p => p.produtoAssociadoPaiId == null || p.formulaPadraoId > 0);
        state.manipulacao.itens = state.manipulacao.itens.filter(
            p => p.produtoAssociadoPaiId == null || p.formulaPadraoId > 0,
        );
        store.set(uuid, state);
    },
    REMOVE_ATIVOS_ASSOCIADOS_FORMA_FARMACEUTICA(store, { uuid }: ManipulacaoContextUUID<null>) {
        const state = store.get(uuid);
        state.ativos = state.ativos.filter(p => p.formaFarmaceuticaId == null);
        state.manipulacao.itens = state.manipulacao.itens.filter(p => p.formaFarmaceuticaId == null);
        store.set(uuid, state);
    },
    REPLICA_DOSE_ATIVOS(store, { uuid, data }: ManipulacaoContextUUID<number>) {
        const state = store.get(uuid);

        state.ativos
            .filter(p => p.tipoCalculo == TiposCalculoManipulacao.Dose)
            .forEach(p => {
                p.volumeDose = data;
            });

        store.set(uuid, state);
    },
    SET_ITEM_DATA(store, { uuid, data }: ManipulacaoContextUUID<UpdateItemType>) {
        const state = store.get(uuid);
        switch (data.type) {
            case ItemTableType.ATIVO:
                state.ativos[data.index] = data.item;
                break;
            case ItemTableType.EMBALAGEM:
                state.embalagens[data.index] = data.item;
                break;
            case ItemTableType.CAPSULA:
                state.capsulas[data.index] = data.item;
                break;
        }
    },
};

export default mutations;
