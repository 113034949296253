enum TipoLogEntidade {
    FormaFarmaceutica = 1,
    Manipulacao = 2,
    ProdutoLote = 3,
    Produto = 4,
    SessaoCaixa = 5,
    Prescritor = 6,
    Usuario = 7,
    NotaFiscal = 8,
    Fornecedor = 9,
    Venda = 10,
    Cliente = 11,
}

export default TipoLogEntidade;
