import Vue from "vue";
import { mapState } from "vuex";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import decimalComponent from "@/components/child/form/decimal.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import moedaComponent from "@/components/child/form/moeda.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import ClienteEditComponent from "@/components/parent/crud/cliente/edit";
import clienteComponent from "@/components/parent/crud/cliente/edit.vue";
import { Component, Watch } from "@/decorators";
import ClienteModel from "@/models/clienteModel";
import NotaFiscalServicoEletronicaItemModel from "@/models/nfse/notaFiscalServicoEletronicaItemModel";
import NotaFiscalServicoEletronicaModel from "@/models/nfse/notaFiscalServicoEletronicaModel";
import UsuarioModel from "@/models/usuarioModel";
import NotaFiscalServicoService from "@/services/emissaoNota/notaFiscalServicoEmissaoService";
import { AppState } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";

import "@/components/parent/crud/crud.scss";

@Component({
    components: {
        gridComponent,
        decimalComponent,
        buttonIncluirComponent,
        dataTooltipComponent,
        moedaComponent,
        fieldsetComponent,
        comboComponent,
        clienteComponent,
        actionBarComponent,
        searchComboComponent,
    },
    computed: {
        valorTotal() {
            return arithmeticHelper.round(this.item.valorUnitario * this.item.quantidade);
        },
        ...mapState({
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
    },
})
export default class NfseEditComponent extends Vue {
    // State computed props
    usuarioLogado: UsuarioModel;

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("descricao", "Descrição", GridColumnType.String),
            new GridColumn("quantidade", "Qtd.", GridColumnType.Decimal),
            new GridColumn("valorUnitario", "Valor Unit.", GridColumnType.Money),
            new GridColumn("valorTotal", "Valor Total", GridColumnType.Money),
        ];
    }

    private service = new NotaFiscalServicoService();
    private model = new NotaFiscalServicoEletronicaModel();
    private item = new NotaFiscalServicoEletronicaItemModel();
    private clienteComponent: ClienteEditComponent = null;

    private indexEdditingModel = -1;

    private customComboTextCliente = (p: ClienteModel) => ({
        value: p.id,
        text: `${p.id} - ` + (p.tipoPessoa != 1 ? p.nome : p.razaoSocial),
    });

    private onRemoveItem(item: NotaFiscalServicoEletronicaItemModel) {
        const index = this.model.itens.indexOf(item);
        this.model.itens.splice(index, 1);
    }

    private clear() {
        this.indexEdditingModel = -1;
        this.model = new NotaFiscalServicoEletronicaModel();
        this.item = new NotaFiscalServicoEletronicaItemModel();
    }

    public async emitirNfse() {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                const sucesso = await this.service.emitirNFSE(this.model).withLoading().resolveWithoutJSON();
                if (sucesso) {
                    await this.$showSuccess("NFS-e", "NFS-e enviada com sucesso.");

                    this.$router.back();
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private async onEditItem(item: NotaFiscalServicoEletronicaItemModel) {
        this.item = { ...item };

        this.indexEdditingModel = this.model.itens.indexOf(item);

        if (this.indexEdditingModel < 0) {
            const itensFinded = this.model.itens.filter(
                p => p.descricao == item.descricao && p.quantidade == item.quantidade,
            );
            if (itensFinded.length > 0) {
                this.indexEdditingModel = this.model.itens.indexOf(itensFinded[0]);
            }
        }
    }

    private onAdicionarItem() {
        if (this.indexEdditingModel >= 0) {
            this.model.itens.splice(this.indexEdditingModel, 1);
        }
        this.model.itens.push(this.item);
        this.item = new NotaFiscalServicoEletronicaItemModel();
    }

    private onCancelar() {
        this.clear();
    }

    private cancel() {
        this.$router.back();
    }

    private async openNewCliente() {
        this.clienteComponent = this.$refs.clienteComponent as ClienteEditComponent;
        this.clienteComponent.modelId = null;
        this.clienteComponent.load();
    }

    private async openComboEditCliente(id: number) {
        this.clienteComponent = this.$refs.clienteComponent as ClienteEditComponent;
        this.clienteComponent.modelId = id;
        this.clienteComponent.load();
    }

    private async onAddCliente(closeModal) {
        if (await this.clienteComponent.save()) {
            closeModal();
        }
    }

    @Watch("valorTotal")
    private onCalculateValorTotal() {
        this.item.valorTotal = this["valorTotal"];
    }
}
