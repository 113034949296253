import Vue from "vue";
import { mapGetters, mapState } from "vuex";

import moment from "moment";

import actionBarComponent from "@/components/child/actionBar/actionBar.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import Grid from "@/components/child/grid/grid";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import multiselectComponent from "@/components/child/multiSelect/multiSelect.vue";
import searchComboComponent from "@/components/child/searchCombo/search.vue";
import ConfiguracoesImpresaoPDFComponent from "@/components/parent/relatorio/configuracoesImpresaoPDF";
import configuracoesImpresaoPDFComponent from "@/components/parent/relatorio/configuracoesImpresaoPDF.vue";
import ConfiguracoesImpresaoXLSComponent from "@/components/parent/relatorio/configuracoesImpresaoXLS";
import configuracoesImpresaoXLSComponent from "@/components/parent/relatorio/configuracoesImpresaoXLS.vue";
import { Component, Watch } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoImpressaoModel from "@/models/configuracaoImpressaoModel";
import ControleReclamacaoStatus from "@/models/enum/controleReclamacaoStatus";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import MesesDoAno from "@/models/enum/mesesDoAno";
import CurvaABC from "@/models/enum/produto/curvaABC";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import StatusEnvioNF from "@/models/enum/statusEnvioNF";
import SubGrupoProduto from "@/models/enum/subGrupoProduto";
import TipoDado from "@/models/enum/tipoDado";
import TiposMovimentacaoEstoque from "@/models/enum/tiposMovimentacaoEstoque";
import TipoVenda from "@/models/enum/tipoVenda";
import Ufs from "@/models/enum/ufs";
import PaginationModel from "@/models/paginationModel";
import ProdutoLoteModel from "@/models/produtoLoteModel";
import RelatorioBaseModel from "@/models/relatorios/base/relatorioBaseModel";
import FiltersRelatorioModel from "@/models/relatorios/filtersRelatorioModel";
import RelatorioModel from "@/models/relatorios/relatorioModel";
import BeneficioService from "@/services/beneficioService";
import CategoriaControleReclamacaoService from "@/services/categoriaControleReclamacaoService";
import ClienteService from "@/services/clienteService";
import ColaboradorService from "@/services/colaboradorService";
import ConvenioService from "@/services/convenioService";
import CupomService from "@/services/CupomService";
import EspecialidadeService from "@/services/especialidadeService";
import EstoqueService from "@/services/estoqueService";
import EtapaPCPService from "@/services/etapaPCPService";
import CidadeService from "@/services/external/cidadeService";
import ContaCorrenteService from "@/services/financeiro/contaCorrenteService";
import ContaGerencialService from "@/services/financeiro/contaGerencialService";
import FormaPagamentoService from "@/services/financeiro/formaPagamentoService";
import FormaRecebimentoService from "@/services/financeiro/formaRecebimentoService";
import OperadoraCartoesService from "@/services/financeiro/operadoraCartoesService";
import FormaFarmaceuticaService from "@/services/formaFarmaceuticaService";
import FornecedorService from "@/services/fornecedorService";
import FranquiaAbrangenciaService from "@/services/franquiaAbrangenciaService";
import FranquiaService from "@/services/franquiaService";
import JustificativaControleReclamacaoService from "@/services/justificativaControleReclamacaoService";
import MotivoMovimentacaoEstoqueService from "@/services/motivoMovimentacaoEstoque";
import MotivoRejeicaoService from "@/services/motivoRejeicaoService";
import NaturezaOperacaoService from "@/services/naturezaOperacaoService";
import EspecieAnimalService from "@/services/paciente/especieAnimalService";
import RelatorioPdfService from "@/services/pdf/relatorioPdfService";
import PrescritorService from "@/services/prescritorService";
import GrupoProdutoService from "@/services/produto/grupoProdutoService";
import ProdutoService from "@/services/produto/produtoService";
import ProdutoLoteService from "@/services/produtoLoteService";
import RelatorioService from "@/services/relatorioService";
import SetorService from "@/services/setorService";
import LocalEntregaService from "@/services/transporte/localEntregaService";
import TransportadoraService from "@/services/transporte/transportadoraService";
import UsuarioService from "@/services/usuarioService";
import VisitadorService from "@/services/visitadorService";
import { AppState, Getters } from "@/store/store";
import arithmeticHelper from "@/utils/common/arithmeticHelper";
import { changePosition } from "@/utils/common/array";
import { getProdutoLoteCombo, getTipoVendaCombo } from "@/utils/common/combo/combotext";
import Delay from "@/utils/common/delay";
import FileHelper from "@/utils/common/fileHelper";
import { formatMoney } from "@/utils/common/number";

import "../crud/crud.scss";

type CampoType = {
    descricao: string;
};

const templates: CampoType[][] = [
    [
        { descricao: "Descricao" },
        { descricao: "Ativo" },
        { descricao: "GrupoProduto" },
        { descricao: "SubGrupo" },
        { descricao: "UnidadeMedidaEstoque" },
        { descricao: "CurvaABC" },
    ],
    [
        { descricao: "Descricao" },
        { descricao: "UnidadeMedidaManipulacao" },
        { descricao: "TipoCalculo" },
        { descricao: "ListaControlado" },
        { descricao: "FatorCorrecao" },
        { descricao: "Densidade" },
        { descricao: "CurvaABC" },
    ],
    [
        { descricao: "Descricao" },
        { descricao: "ValorCusto" },
        { descricao: "CustoReferencia" },
        { descricao: "ValorVenda" },
        { descricao: "Markup" },
        { descricao: "CurvaABC" },
    ],
    [
        { descricao: "Descricao" },
        { descricao: "OrigemMercadoria" },
        { descricao: "CstICMS" },
        { descricao: "CstIPIEntrada" },
        { descricao: "CstIPISaida" },
        { descricao: "CstPISEntrada" },
        { descricao: "CstPISSaida" },
        { descricao: "CstCOFINSEntrada" },
        { descricao: "CstCOFINSSaida" },
    ],
];

const serviceMap = new Map<string, any>([
    ["ClienteService", ClienteService],
    ["ConvenioService", ConvenioService],
    ["CupomService", CupomService],
    ["BeneficioService", BeneficioService],
    ["GrupoProdutoService", GrupoProdutoService],
    ["UsuarioService", UsuarioService],
    ["VisitadorService", VisitadorService],
    ["MotivoMovimentacaoEstoqueService", MotivoMovimentacaoEstoqueService],
    ["FranquiaAbrangenciaService", FranquiaAbrangenciaService],
    ["PrescritorService", PrescritorService],
    ["EtapaPCPService", EtapaPCPService],
    ["LocalEntregaService", LocalEntregaService],
    ["FranquiaService", FranquiaService],
    ["NaturezaOperacaoService", NaturezaOperacaoService],
    ["MotivoRejeicaoService", MotivoRejeicaoService],
    ["ContaGerencialService", ContaGerencialService],
    ["OperadoraCartoesService", OperadoraCartoesService],
    ["FornecedorService", FornecedorService],
    ["ContaCorrenteService", ContaCorrenteService],
    ["FormaPagamentoService", FormaPagamentoService],
    ["FormaRecebimentoService", FormaRecebimentoService],
    ["FormaFarmaceuticaService", FormaFarmaceuticaService],
    ["EspecieAnimalService", EspecieAnimalService],
    ["EspecialidadeService", EspecialidadeService],
    ["TransportadoraService", TransportadoraService],
    ["CategoriaControleReclamacaoService", CategoriaControleReclamacaoService],
    ["JustificativaControleReclamacaoService", JustificativaControleReclamacaoService],
    ["ColaboradorService", ColaboradorService],
    ["ProdutoService", ProdutoService],
    ["EstoqueService", EstoqueService],
    ["SetorService", SetorService],
]);

const enumsMap = new Map<string, EnumExtensions>([
    ["StatusEnvioNF", StatusEnvioNF],
    ["TiposMovimentacaoEstoque", TiposMovimentacaoEstoque],
    ["MesesDoAno", MesesDoAno],
    ["Ufs", Ufs],
    ["SituacoesVenda", SituacoesVenda],
    ["CurvaABC", CurvaABC],
    ["ControleReclamacaoStatus", ControleReclamacaoStatus],
    ["TipoVenda", TipoVenda],
    ["SubGrupoProduto", SubGrupoProduto],
]);

function toSnakeCase(text: string) {
    return text.charAt(0).toLowerCase() + text.slice(1);
}

@Component({
    components: {
        checkboxComponent,
        dataTooltipComponent,
        comboComponent,
        gridComponent,
        dateTimePickerComponent,
        actionBarComponent,
        multiselectComponent,
        searchComboComponent,
        configuracoesImpresaoXLSComponent,
        configuracoesImpresaoPDFComponent,
    },
    computed: {
        ...mapState({
            preLoadPack: (state: AppState) => state.preLoad.preLoadList,
            loadedList: (state: AppState) => state.preLoad.loadedList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO"] as Getters),
    },
})
export default class RelatorioViewComponent extends Vue {
    // State computed props
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    private preLoadPack: PreLoadPackModel;
    private loadedList: boolean;

    private gridcomponent: Grid = null;
    private service = new RelatorioService();
    private relatorioPdfService = new RelatorioPdfService("RelatorioPDF");
    private cidadeService = new CidadeService();
    private produtoLoteService = new ProdutoLoteService();
    private lista: Array<Object> = [];
    model = new RelatorioBaseModel();
    modelId = 0;
    comboOptions: Array<Object> = [];
    uf: number = null;
    produtoId: number = null;
    showExpand = false;

    private configuracoesImpresaoPDFComponent: ConfiguracoesImpresaoPDFComponent = null;
    private configuracoesImpresaoXLSComponent: ConfiguracoesImpresaoXLSComponent = null;

    gridFilterKey = "";
    gridSortKey = "";
    gridSortOrder = "";
    gridData: Array<Object> = [];
    gridColumns: Array<GridColumn> = [new GridColumn("", "", GridColumnType.String)];
    gridColumnsVinculos: Array<GridColumn> = [new GridColumn("", "", GridColumnType.String)];

    totais = [];

    pageIndex = 1;
    pageSize = 30;
    total = 0;

    pags = true;
    tipoDocGerar: number = null;
    footer: Object = null;
    isFracionamento = false;
    hasSubItens = false;

    private isCustomFields = false;
    private campoId = null;
    private templateId = null;
    private camposOptions = [];
    private templateOptions = [
        {
            text: "Dados Gerais",
            value: 0,
        },
        {
            text: "Dados Manipulação",
            value: 1,
        },
        {
            text: "Dados Financeiros",
            value: 2,
        },
        {
            text: "Dados Fiscais",
            value: 3,
        },
    ];
    private camposSelecionados: CampoType[] = [];
    private camposGridColumns = [new GridColumn("descricao", "Descrição", GridColumnType.String)];

    private extraActions: Array<object> = [];
    private gridExtraActionsUp: Array<GridAction> = [];
    private gridExtraActionsDown: Array<GridAction> = [];

    constructor() {
        super();
    }

    public temModelo() {
        return this.model.nomeRelatorio != null;
    }

    private async load() {
        try {
            const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<RelatorioBaseModel>();
            this.model.updateFrom(data);
        } catch {}

        if (!this.temModelo()) {
            return;
        }
        if (this.model.filters != null && this.model.filters.length > 0) {
            for (let filter of this.model.filters) {
                if (filter.valor == null) {
                    filter = this.getDefaultFieldValue(filter);
                }
                switch (filter.tipoDado) {
                    case TipoDado.MultiSelect:
                        filter = await this.getComboOptionsMultiSelect(filter);
                        break;
                    case TipoDado.Enum:
                        filter = this.getComboOptionsEnum(filter);
                        break;
                    case TipoDado.Combo:
                        filter = await this.getComboOptions(filter);
                        break;
                    case TipoDado.Boolean:
                        filter = await this.getComboOptionsBool(filter);
                        break;
                    default:
                        break;
                }
            }
        }

        if (this.model && (this.model.filters == null || this.model.filters.length == 0)) {
            await this.search();
        }
    }

    private getDefaultFieldValue(filter: FiltersRelatorioModel) {
        switch (filter.nomeCampo) {
            case "dataInicial":
                filter.valor = moment(new Date()).subtract(1, "month").toDate();
                break;
            case "dataFinal":
                filter.valor = moment(new Date()).add(1, "month").toDate();
                break;
            default:
                break;
        }

        return filter;
    }

    private async getComboOptionsMultiSelect(filter: FiltersRelatorioModel) {
        filter.comboOptions = filter.valorMultiSelect;

        let dataCombo = null;

        if (filter.comboLoad && filter.comboLoad.includes("SituacoesVenda")) {
            filter.comboOptions = EnumExtensions.getNamesAndValuesOrderedByNames(enumsMap.get("SituacoesVenda"));

            if (filter.comboLoad == "SituacoesVendaAprovada") {
                filter.comboOptions = filter.comboOptions.filter(
                    p => p["value"] != 0 && p["value"] != 1 && p["value"] != 5,
                );
            }

            if (filter.comboLoad == "SituacoesVendaFaturado") {
                filter.comboOptions = filter.comboOptions.filter(
                    p => p["value"] != 0 && p["value"] != 1 && p["value"] != 2 && p["value"] != 5,
                );
            }

            if (!this.isFracionamento) {
                filter.comboOptions = filter.comboOptions.filter(p => p["value"] <= 6);
            }
        } else if (
            filter.comboLoad &&
            (filter.comboLoad.includes("TipoVenda") || filter.comboLoad.includes("TiposMovimentacaoEstoque"))
        ) {
            filter = this.getComboOptionsEnum(filter);
        } else {
            try {
                const responseCombo = await new (serviceMap.get(filter.comboLoad))()["combo"]();
                dataCombo = (await responseCombo.json()).list;
            } catch {
                dataCombo = [];
            }
        }

        switch (filter.comboLoad) {
            case "NaturezaOperacaoService":
                filter.comboOptions = dataCombo.map(p => ({
                    value: p.id,
                    text: `${p.codigo} - ${p.descricao}`,
                }));
                break;
        }

        if (filter.comboOptions === null) {
            filter.comboOptions = dataCombo.map(p => ({
                value: p.id,
                text: p.descricao || p.razaoSocial || p.nome || p.nomeFantasia,
            }));
        }

        return filter;
    }

    private getComboOptionsEnum(filter: FiltersRelatorioModel) {
        switch (filter.comboLoad) {
            case "MesesDoAno":
                filter.comboOptions = EnumExtensions.getNamesAndValuesOrderedByValues(enumsMap.get(filter.comboLoad));
                break;
            case "TipoVenda":
                filter.comboOptions = getTipoVendaCombo();
                break;
            default:
                filter.comboOptions = EnumExtensions.getNamesAndValuesOrderedByNames(enumsMap.get(filter.comboLoad));
        }

        return filter;
    }

    private getComboOptionsBool(filter: FiltersRelatorioModel) {
        if (filter.valor == "false") {
            filter.valor = false;
        } else {
            filter.valor = true;
        }

        return filter;
    }

    private async getComboOptions(filter: FiltersRelatorioModel) {
        let dataCombo = null;

        switch (filter.comboLoad) {
            case "ProdutoService":
            case "ProdutoDrogavetService":
                dataCombo = this.preLoadPack.produtos;
                break;
            case "UsuarioService":
                dataCombo = this.preLoadPack.vendedores;
                break;
            case "EstoqueService":
                dataCombo = this.preLoadPack.estoques;
                break;
            case "ClienteService":
            case "PrescritorService":
            case "CidadeService":
            case "ProdutoLoteService":
                break;
            default:
                try {
                    const responseCombo = await new (serviceMap.get(filter.comboLoad))()["combo"]();
                    dataCombo = (await responseCombo.json()).list;
                } catch {
                    dataCombo = [];
                }
                break;
        }

        if (filter.comboLoad == "FranquiaService") {
            dataCombo = dataCombo.filter(p => p["redeId"] == 6);
        }

        switch (filter.comboLoad) {
            case "NaturezaOperacaoService":
                filter.comboOptions = dataCombo.map(p => ({
                    value: p.id,
                    text: `${p.codigo} - ${p.descricao}`,
                }));
                break;
            case "LocalEntregaService":
                filter.comboOptions = dataCombo.map(p => ({
                    value: p.id,
                    text: `${p.codigo} - ${p.descricao}`,
                }));
                break;
            case "ProdutoDrogavetService":
                filter.comboOptions = dataCombo
                    .filter(p => p.produtoPaiId)
                    .map(p => ({
                        value: p.produtoPaiId,
                        text: p.descricao,
                    }));
                break;
            case "ClienteService":
            case "PrescritorService":
            case "ProdutoLoteService":
            case "CidadeService":
                break;
            default:
                filter.comboOptions = dataCombo.map(p => ({
                    value: p.id,
                    text: p.descricao || p.razaoSocial || p.nome || p.nomeFantasia,
                }));
                break;
        }

        return filter;
    }

    public async downloadXLS() {
        this.configuracoesImpresaoXLSComponent = this.$refs
            .configuracoesImpresaoXLSComponent as ConfiguracoesImpresaoXLSComponent;
        this.configuracoesImpresaoXLSComponent.open();
    }

    public async downloadPDF() {
        this.configuracoesImpresaoPDFComponent = this.$refs
            .configuracoesImpresaoPDFComponent as ConfiguracoesImpresaoPDFComponent;
        this.configuracoesImpresaoPDFComponent.open();
    }

    private async onConfirmXLS(data: ConfiguracaoImpressaoModel) {
        this.model.exportacao = data;

        try {
            const response = await this.service.getRelatorioXLS(this.model).withLoading().resolveWithResponse();
            const data = await response.blob();

            const blob = new Blob([data], { type: response.headers.get("Content-Type") });
            const fileName = response.headers.get("Content-Disposition").split("filename=")[1].split(";")[0];
            FileHelper.download(blob, fileName);
        } catch {}
    }

    public async onConfirmPDF(data: ConfiguracaoImpressaoModel) {
        this.model.exportacao = data;

        await this.relatorioPdfService.getRelatorioPdf(this.model, this.gridSortKey, this.gridSortOrder);
    }

    private async search(edit = false) {
        try {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.gridData = [];

                if (!edit) {
                    if (this.isCustomFields && this.camposSelecionados.length == 0) {
                        return this.$showError("Erro", "Selecione ao menos um campo!");
                    }

                    this.model.campos = this.camposSelecionados.reduce(
                        (acc, p) => (acc ? acc + `,${p.descricao}` : acc + p.descricao),
                        "",
                    );
                    const data = await this.service
                        .getRelatorio(this.model)
                        .withLoading()
                        .resolveWithJSON<RelatorioModel>();

                    this.lista = [];
                    for (let i = 0; i < data.dados.length; i++) {
                        this.lista.push(
                            Object.keys(data.dados[i]).reduce(
                                (acc, p) => (acc = { ...acc, [toSnakeCase(p)]: data.dados[i][p] }),
                                {},
                            ),
                        );
                    }
                    this.total = data.dados.length;
                    for (let i = 0; i < this.pageSize && i < this.total; i++) {
                        this.gridData.push(this.lista[i]);
                    }

                    this.gridColumns = [
                        ...data.cabecalho.map(cab => {
                            const column = new GridColumn(toSnakeCase(cab.valor), cab.descricao, cab.tipoDado);
                            column.minimumFractionDigits = cab.casasDecimais;
                            return column;
                        }),
                    ];

                    this.hasSubItens = data.cabecalhoSubItens.length > 0;

                    if (this.hasSubItens) {
                        this.showExpand = true;

                        this.gridColumnsVinculos = [
                            ...data.cabecalhoSubItens.map(cab => {
                                const column = new GridColumn(toSnakeCase(cab.valor), cab.descricao, cab.tipoDado);
                                column.minimumFractionDigits = cab.casasDecimais;
                                return column;
                            }),
                        ];
                    } else {
                        this.showExpand = false;
                    }

                    this.totais = data.totais.map(total => ({
                        ...total,
                        valor: this.editTotalValue(total.tipoDado, total.valor, total.casasDecimais),
                    }));
                } else {
                    let i = this.pageSize * this.pageIndex - this.pageSize;
                    if (i <= 0) {
                        i = 0;
                    }
                    let j = i > 0 ? 0 : i;
                    for (i; j < this.pageSize && i < this.total; i++) {
                        this.gridData.push(this.lista[i]);
                        j++;
                    }
                    if (this.pags) {
                        this.gridData = [];
                        for (i = 0; i < this.total; i++) {
                            this.gridData.push(this.lista[i]);
                        }

                        if (this.tipoDocGerar == 2) {
                            setTimeout(() => {
                                this.gridcomponent.gerarImpressao();
                            }, 2000);
                        }

                        setTimeout(() => {
                            this.pags = false;
                        }, 2000);
                    }
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}
    }

    private clear() {
        this.model = new RelatorioBaseModel();
        this.modelId = null;
        this.total = 0;
        this.gridData = [];
        this.gridColumns = [];
        this.gridColumnsVinculos = [];
        this.showExpand = false;
        this.pageIndex = 1;
        this.pageSize = 30;
        this.total = 0;
        this.pags = false;
    }

    private reset() {
        this.total = 0;
        this.gridData = [];
        this.gridColumns = [];
        this.gridColumnsVinculos = [];
        this.showExpand = false;
        this.pageIndex = 1;
        this.pageSize = 30;
        this.total = 0;
        this.pags = false;
        this.load();
    }

    private onChangeFilterKey(filterKey: string) {
        this.gridFilterKey = filterKey;
        this.search(true);
    }

    @Watch("pags")
    private onPags() {
        this.search(true);
    }

    private onChangeSort(sortKey: string, sortOrder: string) {
        this.gridSortKey = sortKey;
        this.gridSortOrder = sortOrder;
        this.lista.sort((atual, proximo) => {
            const x = atual[this.gridSortKey];
            const y = proximo[this.gridSortKey];
            if (this.gridSortOrder == "asc") {
                if (x < y) return -1;
                if (x > y) return 1;
                return 0;
            } else {
                if (x > y) return -1;
                if (x < y) return 1;
                return 0;
            }
        });
        this.search(true);
    }

    private onChangePage(pageIndex: number) {
        this.pageIndex = pageIndex;
        this.search(true);
    }

    private editTotalValue(tipoDado: TipoDado, value: string, casasDecimais: number) {
        switch (tipoDado) {
            case TipoDado.Money:
                return formatMoney(Number(value.replace(",", ".")), casasDecimais);
            case TipoDado.Decimal:
                if (isNaN(Number(value))) {
                    return value;
                }
                return arithmeticHelper.round(Number(value), casasDecimais);
            case TipoDado.Integer:
                return Number(value);
            case TipoDado.Percent:
                return value.replace(".", ",") + " %";
            default:
                return value;
        }
    }

    //@ts-ignore
    @Watch("isCustomFields")
    private async onChangeIsCustomFields() {
        if (this.isCustomFields) {
            const data = await new ProdutoService().getCamposDescricao().resolveWithJSON<string[]>();

            this.camposOptions = data.map((p, index) => ({ value: index, text: p }));
        }
    }

    //@ts-ignore
    @Watch("templateId")
    private async onChangeTemplateId() {
        if (this.templateId >= 0) {
            this.camposSelecionados = templates[this.templateId];
            this.addAcoesGrid();
        }
    }

    @Watch("produtoId")
    private async onChangeProdutoId() {
        if (this.produtoId) {
            const index = this.model.filters.findIndex(p => p.nomeCampo == "produtoWithLote");

            if (index > -1) {
                this.model.filters[index].valor = this.produtoId;
                const indexLote = this.model.filters.findIndex(p => p.nomeCampo == "lote");
                const data = await this.produtoLoteService
                    .getByProdutoId(this.produtoId, 0)
                    .withLoading()
                    .resolveWithJSON<PaginationModel<ProdutoLoteModel>>();

                this.model.filters[indexLote].comboOptions = data.list.map(getProdutoLoteCombo);
            }
        }
    }

    //@ts-ignore
    @Watch("uf")
    private async onChangeUf() {
        const index = this.model.filters.findIndex(p => p.nomeCampo == "uf");
        if (index > -1) {
            this.model.filters[index].valor = this.uf;
            const indexCidade = this.model.filters.findIndex(p => p.nomeCampo == "cidade");
            const cidadesOptions = await this.cidadeService.get(this.uf).withLoading();
            this.model.filters[indexCidade].comboOptions = cidadesOptions
                .sort((a, b) => (a.text > b.text ? 1 : -1))
                .map(x => ({
                    text: x.text,
                    value: x.value,
                }));
        }
    }

    private onInclusaoCampo() {
        const campoComponent = this.$el.querySelector("#campoId") as HTMLSelectElement;

        if (
            campoComponent.innerText &&
            this.camposSelecionados.filter(p => p.descricao == campoComponent.innerText).length == 0
        ) {
            this.campoId = null;
            this.camposSelecionados.push({ descricao: campoComponent.innerText });

            this.addAcoesGrid();
        }
    }

    private onRemoveCampo(campo: CampoType) {
        this.camposSelecionados = this.camposSelecionados.filter(p => p.descricao != campo.descricao);
        this.addAcoesGrid();
    }

    private addAcoesGrid() {
        this.gridExtraActionsUp.splice(0);
        this.gridExtraActionsDown.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.camposSelecionados.length; i++) {
            this.gridExtraActionsUp.push(
                new GridAction("up", "Colocar para cima", "fa fa-arrow-up", GridColors.DARKGRAY, true),
            );
            this.gridExtraActionsDown.push(
                new GridAction("down", "Colocar para baixo", "fa fa-arrow-down", GridColors.DARKGRAY, true),
            );
        }

        this.extraActions.push(this.gridExtraActionsUp);
        this.extraActions.push(this.gridExtraActionsDown);
    }

    private onExtraAction(name: string, campo: CampoType) {
        if (name.trim() == "up") {
            this.camposSelecionados = changePosition(this.camposSelecionados, campo, "UP");
        }

        if (name.trim() == "down") {
            this.camposSelecionados = changePosition(this.camposSelecionados, campo, "DOWN");
        }
    }

    private getColumnClass(filter: FiltersRelatorioModel) {
        if (filter.colunas > 0) {
            return `col-sm-${filter.colunas}`;
        }

        if (
            filter.tipoDado == TipoDado.Combo ||
            filter.tipoDado == TipoDado.String ||
            filter.tipoDado == TipoDado.Enum
        ) {
            if (this.model.filters.length > 2 && this.model.filters.length <= 4) {
                return "col-sm-4";
            }
            return "col-sm-3";
        } else {
            if (filter.tipoDado == TipoDado.MultiSelect) {
                return "col-sm-3";
            }
            if (filter.tipoDado == TipoDado.Boolean) {
                return "col-sm-1";
            }
            if (filter.tipoDado == TipoDado.Custom) {
                return "col-sm-3";
            }
            return "col-sm-2";
        }
    }

    private checkShowFilter(filter: FiltersRelatorioModel, filters: FiltersRelatorioModel[]) {
        if (filter.showOn) {
            return filters.some(eval(filter.showOn));
        }
        return true;
    }

    private checkIsCombo(filter: FiltersRelatorioModel) {
        return (
            filter.nomeCampo != "uf" &&
            filter.nomeCampo != "produtoWithLote" &&
            (filter.tipoDado == 10 || filter.tipoDado == 12 || filter.tipoDado == 14) &&
            filter.comboOptions != null
        );
    }

    private async mounted() {
        this.gridcomponent = this.$refs.gridcomponent as Grid;
        this.clear();

        this.modelId = +this.$route.params.id;

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();

        while (!this.loadedList) await Delay(100).withLoading();

        if (this.modelId) {
            // Relatório de dados gerais do produto
            this.isCustomFields = this.modelId == 19;

            await this.load();
        }
    }
}
