import BaseModel from "./base/baseModel";
import TipoHorarioAtendimento from "./enum/TipoHorarioAtendimento";

export default class ConfiguracaoAtendimentoHorario extends BaseModel {
    id?: number = null;
    configuracaoAtendimentoId?: number = null;
    tipo: TipoHorarioAtendimento = null;
    tipoDescricao?: string = null;
    horarioInicio: Date = null;
    horarioInicioString?: string = null;
    horarioFim: Date = null;
    horarioFimString?: string = null;
}
