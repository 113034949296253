import Vue from "vue";
import { mapState } from "vuex";

import { Component } from "@/decorators";
import ConfiguracaoRotuloModel from "@/models/configuracaoRotuloModel";
import OrigemRotulo from "@/models/enum/rotulo/origemRotulo";
import RotuloPersonalizadoModel from "@/models/rotuloPersonalizadoModel";
import ValidationErrorModel from "@/models/validationErrorModel";
import ConfiguracaoRotuloService from "@/services/configuracaoRotuloService";
import { HtmlPrintPageInfo } from "@/services/print/printerQueue";
import { AppState } from "@/store/store";
import Delay from "@/utils/common/delay";
import ValidationErrorWrapper from "@/wrappers/validationErrorWrapper";

import RotuloPersonalizadoComponent from "../rotulopersonalizado/edit";

import rotuloPersonalizadoComponent from "./edit.vue";
import rotuloComponent from "./rotulo.vue";

export type QuantidadeLote = {
    id: number;
    quantidade: number;
};

export type PrintOptionsType = {
    quantidadesLotes?: QuantidadeLote[];
    vendaId?: number;
    origemRotulo?: OrigemRotulo;
    checkImprimir?: boolean;
};

@Component({
    components: {
        rotuloPersonalizadoComponent,
        rotuloComponent,
    },
    computed: {
        ...mapState<AppState>({
            franquiaId: state => state.session.usuarioLogado.franquiaId,
        }),
    },
})
export default class ImprimirRotuloPersonalizadoComponent extends Vue {
    // State computed props
    franquiaId: number;

    private rotuloService = new ConfiguracaoRotuloService();

    private validationErrorWrapper = new ValidationErrorWrapper(this.$validator);

    private rotulos: ConfiguracaoRotuloModel[] = [];

    public models: RotuloPersonalizadoComponent[] = [];
    indexs = [0];
    reseted = false;

    private loteIds = [];
    private manipulacoesIds = [];

    public async showRotuloLote(
        id: number,
        { quantidadesLotes = [], vendaId = 0, origemRotulo = null, checkImprimir = true }: PrintOptionsType,
    ) {
        this.inicializaDados();
        this.loteIds.push(id);
        return await this.show({ quantidadesLotes, vendaId, origemRotulo, checkImprimir });
    }

    public async showRotuloManipulacao(
        id: number,
        { quantidadesLotes = [], vendaId = 0, origemRotulo = null, checkImprimir = true }: PrintOptionsType,
    ) {
        this.inicializaDados();
        this.manipulacoesIds.push(id);
        return await this.show({ quantidadesLotes, vendaId, origemRotulo, checkImprimir });
    }

    public async getRotulos({ quantidadesLotes = [], vendaId = 0, origemRotulo = null }: PrintOptionsType) {
        const response = await this.rotuloService.getRotulos(
            this.loteIds.filter(p => p),
            this.manipulacoesIds.filter(p => p),
            vendaId,
            origemRotulo,
        );

        if (!response.ok) {
            this.validationErrorWrapper.showErrors((await response.json()) as ValidationErrorModel[]);
            this.indexs = [];
            this.reseted = true;
            return false;
        }

        const rotulos = (await response.json()) as RotuloPersonalizadoModel[];

        if (quantidadesLotes.length > 0) {
            rotulos
                .filter(
                    p =>
                        p.rotulo.origemDados != OrigemRotulo.Manipulacao &&
                        p.rotulo.origemDados != OrigemRotulo.Acabado,
                )
                .forEach(p => {
                    if (quantidadesLotes.some(q => q.id == p.lote.id)) {
                        p.lote.quantidadeAtual = quantidadesLotes.find(q => q.id == p.lote.id).quantidade;
                    }
                });
        }

        return rotulos;
    }

    public async loadRotulosComponents(
        rotulos: RotuloPersonalizadoModel[],
        { vendaId = 0, checkImprimir = true }: PrintOptionsType,
    ) {
        for (let index = 0; index < rotulos.length; index++) {
            this.models[index] = this.$refs.index[index] as RotuloPersonalizadoComponent;
            while (!this.$refs.index[index]) await Delay(5);
            this.models[index] = this.$refs.index[index] as RotuloPersonalizadoComponent;

            this.$refs.index[index] = this.models[index];
            this.indexs.push(index);
        }

        for (let index = 0; index < rotulos.length; index++) {
            const rotulo = rotulos[index];

            switch (rotulo.rotulo.origemDados) {
                case OrigemRotulo.Fracionamento:
                case OrigemRotulo.Lote:
                case OrigemRotulo.Secundario:
                case OrigemRotulo.SecundarioPequeno:
                    await this.models[index].setRotuloLote(rotulo, vendaId);
                    break;
                case OrigemRotulo.Acabado:
                case OrigemRotulo.Manipulacao:
                case OrigemRotulo.Strips:
                    await this.models[index].setRotuloManipulacao(rotulo);
                    break;
            }

            this.models[index].model.imprimir = checkImprimir;
        }

        this.indexs = this.indexs.slice(0, this.models.length);
        this.reseted = true;
    }

    public async show({
        quantidadesLotes = [],
        vendaId = 0,
        origemRotulo = null,
        checkImprimir = true,
    }: PrintOptionsType) {
        const rotulos = await this.getRotulos({ quantidadesLotes, vendaId, origemRotulo, checkImprimir });

        if (!rotulos) {
            return false;
        }

        await this.loadRotulosComponents(rotulos, { quantidadesLotes, vendaId, origemRotulo, checkImprimir });

        return true;
    }

    public addRotuloLoteByIds(ids: number[]) {
        this.inicializaDados();

        this.loteIds = ids;
    }

    public addRotuloLote(id: number) {
        this.inicializaDados();

        this.loteIds.push(id);
    }

    public addRotuloManipulacaoByIds(ids: number[]) {
        this.inicializaDados();

        this.manipulacoesIds = ids;
    }

    public addRotuloManipulacao(id: number) {
        this.inicializaDados();

        this.manipulacoesIds.push(id);
    }

    public async salvarRotulo() {
        if (this.models.length == 0) {
            return [];
        }

        this.rotulos = this.models.map(p =>
            p.rotuloNovoSelecionado ? p.model : ({ ...p.modelUtilizado, id: null } as ConfiguracaoRotuloModel),
        );

        for (const p of this.rotulos) {
            for (const x of p.campos) {
                if (x.fonte == null || x.fonte < 1) {
                    x.fonte = 0;
                }
            }
        }
        await this.rotuloService.salvarRotulo(this.rotulos);
    }

    public async getHtmlPages() {
        await this.salvarRotulo();
        //@ts-ignore
        const rotuloCollection = this.$refs.renderRotulosRef.children;

        const pages: string[] = [];
        for (const rotulo of rotuloCollection) {
            if (rotulo && rotulo?.__vue__?.model?.imprimir) {
                pages.push(rotulo.outerHTML);
            }
        }

        const htmlPages: HtmlPrintPageInfo[] = this.rotulos
            .filter(item => item.imprimir)
            .map((rotulo, index) => ({
                pageHtml: pages[index],
                copies: rotulo.copias,
                pageTitle: `${rotulo.nome || ""} ${rotulo.vendaId || ""}`,
                printerName: rotulo.nomeImpressora,
                pageSize: { name: null, width: rotulo.largura, height: rotulo.altura },
                orientation: rotulo.formatoImpressao,
            }));

        this.rotulos = [];

        return htmlPages;
    }

    public inicializaDados(forceInitialize = false) {
        if (this.reseted || forceInitialize) {
            this.models = [] as Array<RotuloPersonalizadoComponent>;
            this.loteIds = [];
            this.manipulacoesIds = [];
            this.indexs = [0];
            this.reseted = false;
        }
    }
}
