import Boot from "@/main";
import MensagemAtendimentoWhatsAppModel from "@/models/whatsApp/atendimento/mensagemAtendimentoWhatsAppModel";

import { iServiceAtendimento } from "../base/iServiceAtendimento";

export default class MensagemService extends iServiceAtendimento {
    constructor() {
        super("Mensagem");
    }

    public async getMensagensByAtendimento(atendimentoId: string): Promise<MensagemAtendimentoWhatsAppModel[]> {
        try {
            return await this.get(`GetByAtendimento?atendimento=${atendimentoId}`)
                .withLoading()
                .resolveWithJSON<MensagemAtendimentoWhatsAppModel[]>();
        } catch {
            Boot.$notifyError(Boot.$t("__.ts.erroConectarWhatsapp"));
        }
        return undefined;
    }

    public async inserirObservacaoByAtendimentoId(observacao: string, atendimentoId: string): Promise<Response> {
        try {
            return await this.post("InserirObservacao", { observacao, atendimentoId })
                .withLoading()
                .resolveWithResponse();
        } catch {
            Boot.$notifyError(Boot.$t("__.ts.erroConectarWhatsapp"));
        }
        return null;
    }
}
