import BaseModel from "./base/baseModel";
import PerfisAtendente from "./enum/perfisAtendente";
import PerfisUsuario from "./enum/perfisUsuario";
import FranquiaModel from "./franquiaModel";
import GrupoModel from "./grupoModel";
import GrupoUsuarioModel from "./grupoUsuarioModel";
import TelaAcaoModel from "./telaAcaoModel";
import TelaAcaoRemoverModel from "./telaAcaoRemoverModel";
import TelaAcaoUsuarioModel from "./telaAcaoUsuarioModel";
import UsuarioFranquiaModel from "./usuarioFranquiaModel";

export default class UsuarioModel extends BaseModel {
    id?: number = null;
    dataCriacao: Date = null;
    nome: string = null;
    franquiaId?: number = null;
    franquia: FranquiaModel = null;
    franquiaNome: string = null;
    franquiaCidade: string = null;
    franquiaUtilizaDotz?: boolean = null;
    email: string = null;
    ativo = true;
    perfil?: PerfisUsuario = null;
    telasAcaoUsuario: Array<TelaAcaoUsuarioModel> = [];
    telasAcaoRemover: Array<TelaAcaoRemoverModel> = [];
    gruposUsuario: Array<GrupoUsuarioModel> = [];
    senha: string = null;
    colaboradorId?: number = null;
    usuarioFranquias: Array<UsuarioFranquiaModel> = [];
    ambiente: string = null;
    setorId?: number = null;
    perfilAtendente?: PerfisAtendente = null;

    // Campos usados para validação de acesso do usuário no frontend
    telasAcao: Array<TelaAcaoModel> = [];
    grupos: Array<GrupoModel> = [];
}
