import SNGPCModel from "@/models/sngpc/sngpcModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./../base/iService";

export default class SNGPCService extends IService<SNGPCModel> {
    constructor() {
        super("Sngpc");
    }

    public getSNGPCAtual(dataInicio: Date, dataFinal: Date, gerarInventario: boolean): Promise<Response> {
        const dataInicioString = new Date(dataInicio).toISOString();
        const dataFinalString = new Date(dataFinal).toISOString();

        return nfetch(
            `/${this.controllerName}/GetSNGPCAtual?dataInicio=${dataInicioString}&dataFinal=${dataFinalString}&gerarInventario=${gerarInventario}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public getDataXml(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Download?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public enviarRelatorio(id: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/EnviarRelatorio?id=${id}`, {
            credentials: "same-origin",
        });
    }

    public getProximoPeriodo(gerarInventario: boolean): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetProximoPeriodo?&gerarInventario=${gerarInventario}`, {
            credentials: "same-origin",
        });
    }
}
