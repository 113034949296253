import Vue from "vue";

import comboComponent from "@/components/child/form/combo.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop, Watch } from "@/decorators";
import ModeloImpressaoCampos from "@/models/modeloImpressao/modeloImpressaoCamposModel";
import ModeloImpressao from "@/models/modeloImpressao/modeloImpressaoModel";
import ModeloImpressaoSessaoModel from "@/models/modeloImpressao/modeloImpressaoSessaoModel";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";
import VendaService from "@/services/vendaService";

@Component({
    components: {
        comboComponent,
        shortcutComponent,
    },
})
export default class TagsComponent extends Vue {
    @Prop({ type: Number, required: true }) tipo: number;

    private shortcutComponent: ShortcutComponent = null;
    private dados = 0;
    private campos = 0;
    private classFullName: string = null;
    telaName: string = null;

    dadosOptions: Array<Object> = [];
    camposOptions: Array<Object> = [];
    private model = new ModeloImpressao();
    sessao = new ModeloImpressaoSessaoModel();

    public async show() {
        this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
        this.loadClassName();
        this.shortcutComponent.title = this.$t("__.ts.addTags") as string;
        this.shortcutComponent.show();
    }

    private async getCamposComDisplayName() {
        let campos = new Array<ModeloImpressaoCampos>();
        if (this.classFullName) {
            campos = await this.model.GetCamposComDisplayName(this.classFullName);
        }

        const sessao = new ModeloImpressaoSessaoModel();
        sessao.campos = campos;
        this.loadSessao(sessao);
        return campos;
    }

    private async loadClassName() {
        if (this.tipo == 1) {
            this.classFullName = await new VendaService().getFullClassName().resolveWithText();
        } else if (this.tipo >= 2 && this.tipo <= 5) {
            this.classFullName = await new ManipulacaoOrdemService().getFullClassName().resolveWithText();
        }
        this.getCamposComDisplayName();
    }

    public async loadSessao(sessao: ModeloImpressaoSessaoModel) {
        this.sessao = new ModeloImpressaoSessaoModel();
        this.sessao.campos.splice(0);

        this.sessao.updateFrom(sessao);
        this.dadosOptions.splice(0);

        const dadosDesordenados = this.sessao.campos
            .filter(p => !(p.labelName?.includes("Dados da Franquia") && p.codGroup?.includes(".")))
            .filter(p => p.header)
            .filter(p => this.sessao.campos.some(c => !c.header && c.parentIndex == p.index))
            .map(item => ({
                value: item.index,
                text: item.codGroup + " - " + item.labelName,
            }));

        this.dadosOptions = dadosDesordenados.sort((n1, n2) => n1["value"] - n2["value"]);

        this.dados = 0;
        const mainDado = { value: 0, text: this.tipo == 1 ? "Dados Venda" : "Dados Manipulação" };
        this.dadosOptions.push(mainDado);

        this.camposOptions = this.sessao.campos
            .filter(p => !p.header && p.parentIndex == this.dados)
            .map(item => ({
                value: item.index,
                text: item.codGroup + " - " + item.labelName,
                label: item.labelId,
            }));
    }

    @Watch("dados")
    private onDadosChanged() {
        this.camposOptions = this.sessao.campos
            .filter(
                p =>
                    !p.header &&
                    p.parentIndex == this.dados &&
                    document.getElementById((p.index * -1).toString()) == null,
            )
            .map(item => ({
                value: item.index,
                text: item.codGroup + " - " + item.labelName,
                label: item.labelId,
            }));
    }

    private onConfirm() {
        const valor = this.camposOptions.find(item => item["value"] == this.campos);

        this.$emit("campo", valor["label"]);
        this.shortcutComponent.hide();
    }
}
