import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import TemplateWhatsAppModel from "@/models/templateWhatsAppModel";
import TemplateWhatsAppService from "@/services/templateWhatsAppService";

import ListComponentBase from "../listComponentBase";

@Component
export default class TemplateWhatsAppListComponent extends ListComponentBase<TemplateWhatsAppModel> {
    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("tipoDescricao", this.$t("__.ts.tipo"), GridColumnType.String),
            new GridColumn("texto", this.$t("__.ts.texto"), GridColumnType.String),
        ];
    }

    private beforeMount() {
        this.setProps(new TemplateWhatsAppService(), "templateWhatsApp", "texto");
    }
}
