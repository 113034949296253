import { mapGetters, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumnSearchColor, GridColumnType } from "@/components/child/grid/gridColumn";
import ListComponentBase from "@/components/parent/crud/listComponentBase";
import { Component, Prop } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import PaginationModel from "@/models/paginationModel";
import VendaModel, { VendaListParameters } from "@/models/vendaModel";
import ReceitaArquivoService from "@/services/receitaArquivoService";
import VendaService from "@/services/vendaService";
import { AppState, Getters } from "@/store/store";
import { convertToDateInputString } from "@/utils/common/date";

import "@/components/parent/crud/crud.scss";

@Component({
    components: {
        dateTimePickerComponent,
        comboComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO", "VALIDAR_PERMISSAO_USUARIO"] as Getters),
    },
})
export default class VendaListComponent extends ListComponentBase<VendaModel> {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    preLoadList: PreLoadPackModel;

    @Prop({ type: Number, default: null }) categoria: number;

    private vendaService = new VendaService();
    private receitaArquivoService = new ReceitaArquivoService();
    private isFracionamento = false;
    dataInicial: Date = null;
    dataFinal: Date = null;

    isHidden = false;
    setorId = null;
    setorOptions = [];
    situacao = -1;
    situacoesVendaOptions = EnumExtensions.getNamesAndValuesOrderedByValues(SituacoesVenda);

    extraActions: Array<object> = [];
    gridExtraActionsCopiar: Array<GridAction> = [];
    gridExtraActionsEmitir: Array<GridAction> = [];
    gridExtraActionsVisualizar: Array<GridAction> = [];
    gridExtraActionsCancelar: Array<GridAction> = [];
    gridExtraActionsImprimir: Array<GridAction> = [];
    gridExtraActionsFaturar: Array<GridAction> = [];
    gridExtraActionsExibirReceitas: Array<GridAction> = [];

    pageSize = 10;

    get gridColumns(): Array<GridColumnSearchColor> {
        return [
            new GridColumnSearchColor("codigo", this.$t("__.ts.codigo"), GridColumnType.String),
            new GridColumnSearchColor("situacaoDescricao", this.$t("__.ts.situacao"), GridColumnType.String),
            new GridColumnSearchColor("clienteNome", this.$t("__.ts.cliente"), GridColumnType.String),
            new GridColumnSearchColor("dataAprovacao", this.$t("__.ts.data"), GridColumnType.Date),
            new GridColumnSearchColor("dataEntrega", this.$t("__.ts.dataEntrega"), GridColumnType.Date),
            new GridColumnSearchColor("valorFinal", this.$t("__.ts.vlr"), GridColumnType.Money),
            new GridColumnSearchColor("observacao", this.$t("__.ts.obss"), GridColumnType.String),
        ];
    }

    private async beforeMount() {
        this.setProps(this.vendaService, "venda", "dataaprovacao", false, "desc");

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();
        this.situacao = this.isFracionamento ? 11 : -1;
    }

    public async loadMethod() {
        this.isHidden = false;

        const customParameters: VendaListParameters = {
            situacao: this.situacao,
            setorId: this.setorId,
            categoria: this.categoria,
            dataEntregaInicial: convertToDateInputString(this.dataInicial),
            dataEntregaFinal: convertToDateInputString(this.dataFinal),
        };

        return await this.vendaService
            .listVendaHomePagination(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 99999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<VendaModel>>();
    }

    public async afterLoad() {
        this.gridExtraActionsCopiar.splice(0);
        this.gridExtraActionsEmitir.splice(0);
        this.gridExtraActionsVisualizar.splice(0);
        this.gridExtraActionsCancelar.splice(0);
        this.gridExtraActionsImprimir.splice(0);
        this.gridExtraActionsFaturar.splice(0);
        this.gridExtraActionsExibirReceitas.splice(0);
        this.extraActions.splice(0);

        for (const venda of this.gridData) {
            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "CopiarVenda")) {
                this.gridExtraActionsCopiar.push(
                    new GridAction(
                        "copiar",
                        "Copiar Venda",
                        "fa fa-copy",
                        GridColors.DARKBLUE,
                        venda.situacao != SituacoesVenda.Cancelado,
                    ),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "CancelarVenda")) {
                this.gridExtraActionsCancelar.push(
                    new GridAction("cancelar-venda", "Cancelar Venda", "fa fa-exclamation-circle", GridColors.RED),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "ImprimirVenda")) {
                this.gridExtraActionsImprimir.push(
                    new GridAction("imprimir-venda", "Imprimir Venda", "fa fa-print", GridColors.DARKGRAY, true),
                );
            }

            this.gridExtraActionsVisualizar.push(
                new GridAction("visualizar-venda", "Visualizar Venda", "fa fa-eye", GridColors.BLUE, true),
            );

            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "FaturarVenda")) {
                this.gridExtraActionsFaturar.push(
                    new GridAction("faturar-venda", "Faturar Venda.", "fa fa-dollar-sign", GridColors.GREEN, true),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("vendas", "EmitirNotaVenda")) {
                const isFaturado =
                    venda.situacao == SituacoesVenda.Faturado ||
                    venda.situacao == SituacoesVenda.Entregue ||
                    venda.situacao == SituacoesVenda.ExpedicaoNota ||
                    venda.situacao == SituacoesVenda.EmFaturamento;

                this.gridExtraActionsEmitir.push(
                    new GridAction("emitir-nota", "Emitir Nota.", "fa fa-file-invoice", GridColors.ORANGE, isFaturado),
                );
            }

            this.gridExtraActionsExibirReceitas.push(
                new GridAction(
                    "exibir-receitas",
                    this.$t("__.Components.parent.home.fastaction.manipulacao.manipulacao_vue_html.receita") as string,
                    "fa fa-paperclip",
                    GridColors.RED,
                    venda.receitas.length > 0,
                ),
            );
        }

        this.extraActions.push(this.gridExtraActionsCopiar);
        this.extraActions.push(this.gridExtraActionsCancelar);
        this.extraActions.push(this.gridExtraActionsImprimir);
        this.extraActions.push(this.gridExtraActionsVisualizar);
        this.extraActions.push(this.gridExtraActionsExibirReceitas);
        this.extraActions.push(this.gridExtraActionsFaturar);
        this.extraActions.push(this.gridExtraActionsEmitir);
    }

    private onExtraAction(name: string, model: VendaModel) {
        if (name.trim() == "copiar") {
            this.$emit("copiar-venda", model);
        } else if (name.trim() == "cancelar-venda") {
            this.$emit("cancelar-venda", model);
        } else if (name.trim() == "imprimir-venda") {
            this.$emit("imprimir-venda", model);
        } else if (name.trim() == "visualizar-venda") {
            this.$router.push("/venda-edicao/" + model.id);
        } else if (name.trim() == "faturar-venda") {
            this.$emit("faturar-venda", model);
        } else if (name.trim() == "emitir-nota") {
            this.$emit("emitir-nota", model);
        } else if (name.trim() == "exibir-receitas") {
            this.onExibirReceita(model);
        }
    }

    public async onExibirReceita(venda: VendaModel) {
        await this.receitaArquivoService.showFiles(venda.receitas);
    }

    public async afterMounted() {
        this.situacoesVendaOptions = EnumExtensions.getNamesAndValuesOrderedByValues(SituacoesVenda);
        if (!this.isFracionamento) {
            this.situacoesVendaOptions = this.situacoesVendaOptions.filter(
                p =>
                    p.value == SituacoesVenda.Aprovado ||
                    p.value == SituacoesVenda.Faturado ||
                    p.value == SituacoesVenda.Concluido,
            );
        } else {
            this.situacoesVendaOptions = this.situacoesVendaOptions.filter(
                p =>
                    p.value != SituacoesVenda.Orcamento &&
                    p.value != SituacoesVenda.Rejeitado &&
                    p.value != SituacoesVenda.Cancelado,
            );
        }

        this.setorOptions = this.preLoadList.setoresCombo();
    }
}
