import Vue from "vue";

import checkboxComponent from "@/components/child/form/checkbox.vue";
import comboComponent from "@/components/child/form/combo.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop } from "@/decorators";
import ConfiguracaoImpressaoModel from "@/models/configuracaoImpressaoModel";

@Component({
    components: {
        comboComponent,
        checkboxComponent,
        shortcutComponent,
    },
})
export default class ConfiguracoesImpresaoXLSComponent extends Vue {
    @Prop({ type: Boolean, default: false }) hasSubItens: boolean;

    private shortCutComponent: ShortcutComponent = null;

    model = new ConfiguracaoImpressaoModel();

    tipoExportacaoXLSOptions: Array<Object> = [
        { value: 1, text: "XLS" },
        { value: 2, text: "CSV" },
    ];

    public open() {
        this.shortCutComponent = this.$refs.shortCutComponent as ShortcutComponent;
        this.shortCutComponent.title = "Exportar para XLS";
        this.shortCutComponent.show();
    }

    private async onConfirm() {
        this.$emit("onConfirm", this.model);

        this.shortCutComponent.hide();
    }
}
