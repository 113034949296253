var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      !_vm.emissaoVenda
        ? _c("actionBarComponent", {
            attrs: {
              slots: 8,
              title: _vm.$t("__.Components.parent.nfe.edit_vue_html.nfeEdit"),
              showSave: false,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.emitirNfse($event)
            },
          },
        },
        [
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.nfe.edit_vue_html.dadosNfe"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("clienteId"),
                            expression: "errors.first('clienteId')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "clienteId",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Components.parent.nfe.edit_vue_html.cliente"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "search-combo-component",
                          {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              id: "clienteId",
                              name: "clienteId",
                              actionName: "clientes",
                              addEditId:
                                _vm.model.clienteId > 0
                                  ? _vm.model.clienteId
                                  : 0,
                              addNew: _vm.model.clienteId > 0,
                              formAddNewName:
                                _vm.model.clienteId > 0
                                  ? "Editar cliente"
                                  : "Novo cliente",
                              useCloseModalCallback: true,
                              customComboText: _vm.customComboTextCliente,
                              advancedSearch: true,
                              advancedSearchCampos: [
                                { campo: "text", descricao: "Nome" },
                                { campo: "cpf", descricao: "CNPJ/CPF" },
                                { campo: "celular", descricao: "Celular" },
                              ],
                              customView: true,
                              propDescription: "nome",
                              service: "Cliente",
                            },
                            on: {
                              addNewItemCombo: _vm.onAddCliente,
                              openComboEditItem: _vm.openComboEditCliente,
                              openComboNewItem: _vm.openNewCliente,
                            },
                            model: {
                              value: _vm.model.clienteId,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "clienteId", $$v)
                              },
                              expression: "model.clienteId",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "component" },
                                slot: "component",
                              },
                              [
                                _c("clienteComponent", {
                                  ref: "clienteComponent",
                                  attrs: { CalledByShortCut: true },
                                  on: { "save-ok": _vm.onClienteSaveOk },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t("__.Crud.formulapadrao.ver_vue_html.itens"),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("produtoDescricao"),
                            expression: "errors.first('produtoDescricao')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "produtoDescricao",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.$t("__.ts.descricao")) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("data-tooltip-component", {
                          attrs: {
                            text: "Descrição do produto que será emitido na nota",
                          },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.descricao,
                              expression: "item.descricao",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "produtoDescricao",
                            name: "produtoDescricao",
                          },
                          domProps: { value: _vm.item.descricao },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.item,
                                "descricao",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("quantidade"),
                            expression: "errors.first('quantidade')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "quantidade",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.produto.verLote_vue_html.quantidade"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("decimal-component", {
                          staticClass: "form-control",
                          attrs: { id: "quantidade", name: "quantidade" },
                          model: {
                            value: _vm.item.quantidade,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "quantidade", $$v)
                            },
                            expression: "item.quantidade",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("valorUnitario"),
                            expression: "errors.first('valorUnitario')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "valorUnitario",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.movimentacaoestoque.item_vue_html.vlrUnitario"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          staticClass: "form-control",
                          attrs: { id: "valorUnitario", name: "valorUnitario" },
                          model: {
                            value: _vm.item.valorUnitario,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "valorUnitario", $$v)
                            },
                            expression: "item.valorUnitario",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-2" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.errors.first("valorTotal"),
                            expression: "errors.first('valorTotal')",
                          },
                        ],
                        staticClass: "form-group",
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: {
                              for: "valorTotal",
                              title: _vm.$t("__.obrigatorio"),
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.$t(
                                    "__.Crud.contapagar.edit_vue_html.vlrTotal"
                                  )
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "valorTotal",
                            name: "valorTotal",
                            disabled: true,
                          },
                          model: {
                            value: _vm.item.valorTotal,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "valorTotal", $$v)
                            },
                            expression: "item.valorTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("buttonIncluirComponent", {
                      attrs: {
                        alterar: _vm.editingModel,
                        cancelar: _vm.editingModel,
                      },
                      on: {
                        click: _vm.onAdicionarItem,
                        cancelar: _vm.onCancelar,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12" },
                    [
                      _c("grid-component", {
                        attrs: {
                          data: _vm.model.itens,
                          columns: _vm.gridColumns,
                          "show-action-column": true,
                          "show-sort-icons": false,
                          showAddNewButton: false,
                          "show-table-head": false,
                          "show-table-foot": false,
                        },
                        on: {
                          "edit-item": _vm.onEditItem,
                          "remove-item": _vm.onRemoveItem,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Components.parent.manipulacao.edit_vue_html.valores"
                ),
                collapsed: true,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "valorDeducoes" },
                          },
                          [_vm._v("Valor Deduções")]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          staticClass: "form-control",
                          attrs: { id: "valorDeducoes", name: "valorDeducoes" },
                          model: {
                            value: _vm.model.valorDeducoes,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "valorDeducoes", $$v)
                            },
                            expression: "model.valorDeducoes",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "valorPIS" },
                          },
                          [_vm._v("Valor PIS")]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          staticClass: "form-control",
                          attrs: { id: "valorPIS", name: "valorPIS" },
                          model: {
                            value: _vm.model.valorPIS,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "valorPIS", $$v)
                            },
                            expression: "model.valorPIS",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "valorCOFINS" },
                          },
                          [_vm._v("Valor COFINS")]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          staticClass: "form-control",
                          attrs: { id: "valorCOFINS", name: "valorCOFINS" },
                          model: {
                            value: _vm.model.valorCOFINS,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "valorCOFINS", $$v)
                            },
                            expression: "model.valorCOFINS",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "valorISSRetido" },
                          },
                          [_vm._v("Valor ISS Redito")]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          staticClass: "form-control",
                          attrs: {
                            id: "valorISSRetido",
                            name: "valorISSRetido",
                          },
                          model: {
                            value: _vm.model.valorISSRetido,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "valorISSRetido", $$v)
                            },
                            expression: "model.valorISSRetido",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "control-label",
                            attrs: { for: "valorRetencoes" },
                          },
                          [_vm._v("Outras Retenções")]
                        ),
                        _vm._v(" "),
                        _c("moeda-component", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "valorRetencoes",
                            name: "valorRetencoes",
                          },
                          model: {
                            value: _vm.model.valorRetencoes,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "valorRetencoes", $$v)
                            },
                            expression: "model.valorRetencoes",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "fieldset-component",
            {
              attrs: {
                title: _vm.$t(
                  "__.Crud.naturezaoperacao.edit_vue_html.observacoes"
                ),
                collapsed: false,
              },
            },
            [
              _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "observacao" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.nfe.edit_vue_html.obs"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.observacao,
                            expression: "model.observacao",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "observacao",
                          name: "observacao",
                        },
                        domProps: { value: _vm.model.observacao },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model,
                              "observacao",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.nfe.edit_vue_html.salvarEEmitir"
                            )
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }