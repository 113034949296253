import Boot from "@/main";
import ConfiguracaoWhatsAppSituacao from "@/models/enum/configuracaoWhatsAppSituacao";
import VendaModel from "@/models/vendaModel";
import { formatMoney } from "@/utils/common/number";

import ConfiguracaoWhatsAppService from "../atendimentos/configuracaoWhatsAppService";
import WhatsAppService from "../atendimentos/whatsAppService";

export default class WhatsAppUtilsService {
    whatsAppService: WhatsAppService;

    constructor() {
        this.whatsAppService = new WhatsAppService();
    }

    getMensagemWhats(venda: VendaModel, complementoMsg: string) {
        let produtosStr = "";

        venda.itens.forEach((item, index) => {
            if (item.manipulacaoOrdem && item.manipulacaoOrdem.itens.length > 0) {
                const descricaoProduto = item.manipulacaoOrdem.itens[0].produtoDescricao;
                const quantidade = item.manipulacaoOrdem.quantidade;
                const formaFarmaceuticaDescricao = item.manipulacaoOrdem.formaFarmaceuticaDescricao;

                produtosStr += `${index + 1} - ${descricaoProduto} ${quantidade} ${formaFarmaceuticaDescricao}\n`;
            }
        });

        const str = `Orçamento Código: ${venda.codigo}\n\n-Descrição-\n\n${produtosStr}\n${formatMoney(
            venda.valorFinal,
        )}\n\n`;

        return str + (complementoMsg ?? "");
    }

    async showMensagemWhats(telefoneDestino: string, msg: string) {
        const configService = new ConfiguracaoWhatsAppService();

        const statusIntegracao = await configService.getStatusIntegracao();
        const integracaoWhatsAppProntoParaUso = statusIntegracao == ConfiguracaoWhatsAppSituacao.ProntoParaUso;

        const textoBotaoEnviar = integracaoWhatsAppProntoParaUso ? "Enviar Automaticamente" : "Enviar";
        const acaoEnviar = (numero, mensagem) =>
            integracaoWhatsAppProntoParaUso
                ? this.enviarMensagemIntegracao(numero, mensagem)
                : this.abrirWhatsWeb(numero, mensagem);

        try {
            const response = await Boot.$showAlert({
                title: Boot.$t("__.ts.enviarOrca") as string,
                text: Boot.$t("__.ts.enviarMsgOrcaCliente") as string,
                html: `
                <label>Telefone:</label>
                <input id="swal-input-tel" class="swal2-input" value="${telefoneDestino ?? ""}">
                <textarea style="width: 95%; height: 300px; margin: 10px;" id="swal-textarea" class="swal2-textarea">${msg
                    .replace('"', "")
                    .replace('"', "")}</textarea>
                <button id="copy-button" class="swal2-confirm swal2-styled" style="width: 42%; margin-top: 0px; margin-bottom: 0px; background-color: #3ba0b1">Copiar</button>
                `,
                showCancelButton: true,
                confirmButtonText: textoBotaoEnviar,
                preConfirm: () => {
                    return {
                        telefone: (document.getElementById("swal-input-tel") as HTMLInputElement).value,
                        mensagem: (document.getElementsByClassName("swal2-textarea")[0] as HTMLTextAreaElement).value,
                    };
                },
                didOpen: () => {
                    const copyButton = document.getElementById("copy-button");
                    copyButton.addEventListener("click", event => {
                        event.preventDefault();
                        const textarea = document.getElementById("swal-textarea") as HTMLTextAreaElement;
                        textarea.select();
                        document.execCommand("copy");
                    });
                },
            });

            if (response.isConfirmed) {
                const celular = response.value.telefone.replace(/\D/g, "");
                acaoEnviar(celular, response.value.mensagem);
            }
        } catch {}
    }

    private abrirWhatsWeb(celular, mensagem) {
        const url = "https://api.whatsapp.com/send?phone=55" + encodeURI(celular) + "&text=" + encodeURI(mensagem);
        const win = window.open(url);
        win.focus();
    }

    public async enviarMensagemIntegracao(numeroDestino: string, mensagem: string): Promise<void> {
        const sucesso = await this.whatsAppService.enviarMensagemTexto(numeroDestino, mensagem);

        if (sucesso) {
            Boot.$notifySuccess("Mensagem enviada com sucesso!");
        }
    }
}
