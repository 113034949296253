var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "shortcutComponent",
        {
          ref: "shortcutImpressao",
          attrs: {
            extraButtons: _vm.showData.impressaoRotulo
              ? _vm.modalExtraButtons
              : [],
            btOkName: _vm.$t("__.ts.imprimir"),
          },
          on: {
            "extra-button-click": _vm.onExtraButtonImprimirRotulo,
            "hide-click": _vm.onHideModal,
            "selection-item": function ($event) {
              _vm.onImprimir().withLoading()
            },
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _vm.showData.impressaoSelecionarModelo ||
              _vm.showData.impressaoTodosModelo
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-12" },
                      [
                        _c("gridComponent", {
                          attrs: {
                            columns: _vm.gridColumns,
                            data: _vm.gridData,
                            extraActions: _vm.extraActions,
                            initialRowsChecked: _vm.initialCheckedModelos,
                            multipleSelect:
                              !_vm.showData.impressaoSelecionarModelo,
                            selectLikeRadio:
                              _vm.showData.impressaoSelecionarModelo,
                            "show-edit-item": false,
                            "show-remove-item": false,
                            "show-select-checkbox": true,
                            "show-sort-icons": false,
                            "show-table-foot": false,
                            "show-table-head": false,
                          },
                          on: {
                            "extra-action": _vm.onExtraAction,
                            "selected-values-changed":
                              _vm.onSelectedModelosChanged,
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showData.impressaoRotulo,
                      expression: "showData.impressaoRotulo",
                    },
                  ],
                  staticClass: "row",
                },
                [
                  _vm.isMultiplePageRotulos
                    ? _c("div", { staticClass: "col-sm-12" }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$t("__.ts.muitosRotulos")) +
                              "\n                    "
                          ),
                        ]),
                      ])
                    : _c(
                        "div",
                        { staticClass: "col-sm-12" },
                        [
                          _c("impressaoRotuloComponent", {
                            ref: "impressaoRotuloComponent",
                          }),
                        ],
                        1
                      ),
                ]
              ),
              _vm._v(" "),
              _c("imprimirRotuloPersonalizadoComModalComponent", {
                ref: "imprimirRotuloPersonalizadoComModalComponent",
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }