import AtendimentoComponent from "@/components/parent/atendimento/view.vue";
import AtualizacaoValoresFormaFarmaceutica from "@/components/parent/atualizacaoValores/atualizacaoValoresFormaFarmaceutica.vue";
import AtualizacaoValoresProduto from "@/components/parent/atualizacaoValores/atualizacaoValoresProduto.vue";
import ChatBotComponent from "@/components/parent/chatbot/view.vue";
import Alterarsenha from "@/components/parent/crud/alterarsenha/alterarsenha.vue";
import BalancoCompleto from "@/components/parent/crud/balancoCompleto/view.vue";
import BancoEdit from "@/components/parent/crud/banco/edit.vue";
import BancoList from "@/components/parent/crud/banco/list.vue";
import BandeiraEdit from "@/components/parent/crud/bandeira/edit.vue";
import BandeiraList from "@/components/parent/crud/bandeira/list.vue";
import BeneficioEdit from "@/components/parent/crud/beneficio/edit.vue";
import BeneficioList from "@/components/parent/crud/beneficio/list.vue";
import CampanhaEdit from "@/components/parent/crud/campanha/edit.vue";
import CampanhaList from "@/components/parent/crud/campanha/list.vue";
import PontuarComponent from "@/components/parent/crud/campanha/pontuar.vue";
import CapacidadeProducaoEdit from "@/components/parent/crud/capacidadeProducao/edit.vue";
import CapsulaEdit from "@/components/parent/crud/capsula/edit.vue";
import CapsulaList from "@/components/parent/crud/capsula/list.vue";
import CategoriaControleReclamacaoEdit from "@/components/parent/crud/categoriaControleReclamacao/edit.vue";
import CategoriaControleReclamacaoList from "@/components/parent/crud/categoriaControleReclamacao/list.vue";
import CestEdit from "@/components/parent/crud/cest/edit.vue";
import CestList from "@/components/parent/crud/cest/list.vue";
import ClienteEdit from "@/components/parent/crud/cliente/edit.vue";
import ClienteList from "@/components/parent/crud/cliente/list.vue";
import ClinicaEdit from "@/components/parent/crud/clinica/edit.vue";
import ClinicaList from "@/components/parent/crud/clinica/list.vue";
import ColaboradorEdit from "@/components/parent/crud/colaborador/edit.vue";
import ColaboradorList from "@/components/parent/crud/colaborador/list.vue";
import ColetaEditComponent from "@/components/parent/crud/coleta/edit.vue";
import ColetaListComponent from "@/components/parent/crud/coleta/list.vue";
import CompraCotacaoEditComponent from "@/components/parent/crud/compras/cotacao.vue";
import ComprasEditComponent from "@/components/parent/crud/compras/edit.vue";
import CotacaoFornecedorComponent from "@/components/parent/crud/compras/fornecedor/cotacaoFornecedor.vue";
import ComprasListComponent from "@/components/parent/crud/compras/list.vue";
import MelhorCompraEditComponent from "@/components/parent/crud/compras/melhorCompra.vue";
import CondicaoEdit from "@/components/parent/crud/condicao/edit.vue";
import CondicaoList from "@/components/parent/crud/condicao/list.vue";
import ConfiguracaoAtendimentoEdit from "@/components/parent/crud/configuracaoAtendimento/edit.vue";
import ConfiguracaodotzEdit from "@/components/parent/crud/configuracaodotz/edit.vue";
import ConfiguracaoEtapaPCP from "@/components/parent/crud/configuracaoEtapaPCP/edit.vue";
import ConfiguracaofranquiaEdit from "@/components/parent/crud/configuracaofranquia/edit.vue";
import ConfiguracaousuarioEdit from "@/components/parent/crud/configuracaousuario/edit.vue";
import ConfiguracaoWhatsAppEdit from "@/components/parent/crud/configuracaoWhatsApp/edit.vue";
import ContacorrenteEdit from "@/components/parent/crud/contacorrente/edit.vue";
import ContacorrenteList from "@/components/parent/crud/contacorrente/list.vue";
import ContagerencialEdit from "@/components/parent/crud/contagerencial/edit.vue";
import ContagerencialList from "@/components/parent/crud/contagerencial/list.vue";
import ContagerencialclassificacaoEdit from "@/components/parent/crud/contagerencialclassificacao/edit.vue";
import ContagerencialclassificacaoList from "@/components/parent/crud/contagerencialclassificacao/list.vue";
import ContapagaList from "@/components/parent/crud/contapaga/list.vue";
import ContapagarEdit from "@/components/parent/crud/contapagar/edit.vue";
import ContapagarList from "@/components/parent/crud/contapagar/list.vue";
import ContapagarmultiplasEdit from "@/components/parent/crud/contapagarmultiplas/edit.vue";
import ContareceberEdit from "@/components/parent/crud/contareceber/edit.vue";
import ContareceberList from "@/components/parent/crud/contareceber/list.vue";
import ContareceberListOperadoras from "@/components/parent/crud/contareceber/listOperadoras.vue";
import ContareceberParceladasList from "@/components/parent/crud/contareceber/listParceladas.vue";
import ContaReceberVendasListComponent from "@/components/parent/crud/contareceber/listVendas.vue";
import ContarecebermultiplasEdit from "@/components/parent/crud/contarecebermultiplas/edit.vue";
import ContarecebidaList from "@/components/parent/crud/contarecebida/list.vue";
import ContarecebidaListOperadoras from "@/components/parent/crud/contarecebida/listOperadoras.vue";
import ControleReclamacaoEdit from "@/components/parent/crud/controleReclamacao/edit.vue";
import ControleReclamacaoList from "@/components/parent/crud/controleReclamacao/list.vue";
import ConvenioEdit from "@/components/parent/crud/convenio/edit.vue";
import ConvenioList from "@/components/parent/crud/convenio/list.vue";
import CorEdit from "@/components/parent/crud/cor/edit.vue";
import CorList from "@/components/parent/crud/cor/list.vue";
import CupomEdit from "@/components/parent/crud/cupom/edit.vue";
import CupomList from "@/components/parent/crud/cupom/list.vue";
import DadosAdicionaisFichaTecnicaEdit from "@/components/parent/crud/dadosAdicionaisFichaTecnica/edit.vue";
import DadosAdicionaisFichaTecnicaList from "@/components/parent/crud/dadosAdicionaisFichaTecnica/list.vue";
import DeclaracaoConteudoComponent from "@/components/parent/crud/declaracaoConteudo/view.vue";
import DevolucaocompraConsulta from "@/components/parent/crud/devolucaocompra/consulta.vue";
import DevolucaocompraEdit from "@/components/parent/crud/devolucaocompra/edit.vue";
import DevolucaocompraList from "@/components/parent/crud/devolucaocompra/list.vue";
import DownloadXmlsEdit from "@/components/parent/crud/downloadXmls/edit.vue";
import EmailEdit from "@/components/parent/crud/email/edit.vue";
import EmailList from "@/components/parent/crud/email/list.vue";
import EnsaioEdit from "@/components/parent/crud/ensaio/edit.vue";
import EnsaioList from "@/components/parent/crud/ensaio/list.vue";
import EspecialidadeEdit from "@/components/parent/crud/especialidade/edit.vue";
import EspecialidadeList from "@/components/parent/crud/especialidade/list.vue";
import EspecieanimalEdit from "@/components/parent/crud/especieanimal/edit.vue";
import EspecieanimalList from "@/components/parent/crud/especieanimal/list.vue";
import EstoqueEdit from "@/components/parent/crud/estoque/edit.vue";
import EstoqueList from "@/components/parent/crud/estoque/list.vue";
import EtapaPCPEdit from "@/components/parent/crud/etapaPCP/edit.vue";
import EtapaPCPList from "@/components/parent/crud/etapaPCP/list.vue";
import EtiquetasListComponent from "@/components/parent/crud/etiquetas/list.vue";
import FarmacopeiaEdit from "@/components/parent/crud/farmacopeia/edit.vue";
import FarmacopeiaList from "@/components/parent/crud/farmacopeia/list.vue";
import FormafarmaceuticaEdit from "@/components/parent/crud/formafarmaceutica/edit.vue";
import FormafarmaceuticaList from "@/components/parent/crud/formafarmaceutica/list.vue";
import FormapagamentoEdit from "@/components/parent/crud/formapagamento/edit.vue";
import FormapagamentoList from "@/components/parent/crud/formapagamento/list.vue";
import FormarecebimentoEdit from "@/components/parent/crud/formarecebimento/edit.vue";
import FormarecebimentoList from "@/components/parent/crud/formarecebimento/list.vue";
import FormulapadraoEdit from "@/components/parent/crud/formulapadrao/edit.vue";
import FormulapadraoList from "@/components/parent/crud/formulapadrao/list.vue";
import FormulapadraomanipulacaoEdit from "@/components/parent/crud/formulapadraomanipulacao/edit.vue";
import FormulapadraovalorprodutoList from "@/components/parent/crud/formulapadraovalorproduto/list.vue";
import FornecedorEdit from "@/components/parent/crud/fornecedor/edit.vue";
import FornecedorList from "@/components/parent/crud/fornecedor/list.vue";
import ConfiguracaoGatewayPagamentoEdit from "@/components/parent/crud/franquia/configuracaoGatewayPagamento/edit.vue";
import FranquiaEdit from "@/components/parent/crud/franquia/edit.vue";
import FranquiaIntegracao from "@/components/parent/crud/franquia/integracao/edit.vue";
import FranquiaList from "@/components/parent/crud/franquia/list.vue";
import GrupoEdit from "@/components/parent/crud/grupo/edit.vue";
import GrupoList from "@/components/parent/crud/grupo/list.vue";
import GrupoprodutoEdit from "@/components/parent/crud/grupoproduto/edit.vue";
import GrupoprodutoList from "@/components/parent/crud/grupoproduto/list.vue";
import HistoricoEnviosDotzList from "@/components/parent/crud/historicoEnviosDotz/list.vue";
import InutilizacaoNotaFiscalEdit from "@/components/parent/crud/inutilizacaoNotaFiscal/edit.vue";
import InutilizacaoNotaFiscalList from "@/components/parent/crud/inutilizacaoNotaFiscal/list.vue";
import InventarioEdit from "@/components/parent/crud/inventario/edit.vue";
import InventarioList from "@/components/parent/crud/inventario/list.vue";
import JustificativaControleReclamacaoEdit from "@/components/parent/crud/justificativaControleReclamacao/edit.vue";
import JustificativaControleReclamacaoList from "@/components/parent/crud/justificativaControleReclamacao/list.vue";
import KitembalagemEdit from "@/components/parent/crud/kitembalagem/edit.vue";
import KitembalagemList from "@/components/parent/crud/kitembalagem/list.vue";
import ListacontroladoEdit from "@/components/parent/crud/listacontrolado/edit.vue";
import ListacontroladoList from "@/components/parent/crud/listacontrolado/list.vue";
import Livroregistroespecifico from "@/components/parent/crud/livroregistroespecifico/view.vue";
import LocalentregaEdit from "@/components/parent/crud/localentrega/edit.vue";
import LocalentregaList from "@/components/parent/crud/localentrega/list.vue";
import Manipuladores from "@/components/parent/crud/manipuladores/view.vue";
import MapaSiproquim from "@/components/parent/crud/mapaSiproquim/view.vue";
import MetaEdit from "@/components/parent/crud/meta/edit.vue";
import MetaList from "@/components/parent/crud/meta/list.vue";
import MetodoPagamentoEdit from "@/components/parent/crud/metodoPagamento/edit.vue";
import MetodoPagamentoList from "@/components/parent/crud/metodoPagamento/list.vue";
import MoldeEdit from "@/components/parent/crud/molde/edit.vue";
import MoldeList from "@/components/parent/crud/molde/list.vue";
import MotivocancelamentoEdit from "@/components/parent/crud/motivocancelamento/edit.vue";
import MotivocancelamentoList from "@/components/parent/crud/motivocancelamento/list.vue";
import MotivoEtapaPCPEdit from "@/components/parent/crud/motivoEtapaPCP/edit.vue";
import MotivoEtapaPCPList from "@/components/parent/crud/motivoEtapaPCP/list.vue";
import MotivomovimentacaoestoqueEdit from "@/components/parent/crud/motivomovimentacaoestoque/edit.vue";
import MotivomovimentacaoestoqueList from "@/components/parent/crud/motivomovimentacaoestoque/list.vue";
import MotivoRejeicaoEditComponent from "@/components/parent/crud/motivorejeicao/edit.vue";
import MotivoRejeicaoListComponent from "@/components/parent/crud/motivorejeicao/list.vue";
import MovimentacaocontaEdit from "@/components/parent/crud/movimentacaoconta/edit.vue";
import MovimentacaocontaList from "@/components/parent/crud/movimentacaoconta/list.vue";
import MovimentacaoestoqueEdit from "@/components/parent/crud/movimentacaoestoque/edit.vue";
import MovimentacaoestoqueList from "@/components/parent/crud/movimentacaoestoque/list.vue";
import NaturezaoperacaoEdit from "@/components/parent/crud/naturezaoperacao/edit.vue";
import NaturezaoperacaoList from "@/components/parent/crud/naturezaoperacao/list.vue";
import NbmEdit from "@/components/parent/crud/nbm/edit.vue";
import NbmList from "@/components/parent/crud/nbm/list.vue";
import NcmEdit from "@/components/parent/crud/ncm/edit.vue";
import NcmList from "@/components/parent/crud/ncm/list.vue";
import OperadoraCartoesEdit from "@/components/parent/crud/operadoraCartoes/edit.vue";
import OperadoraCartoesList from "@/components/parent/crud/operadoraCartoes/list.vue";
import PacienteEdit from "@/components/parent/crud/paciente/edit.vue";
import PacienteList from "@/components/parent/crud/paciente/list.vue";
import PcpEdit from "@/components/parent/crud/pcp/edit.vue";
import PdvEdit from "@/components/parent/crud/pdv/edit.vue";
import PdvList from "@/components/parent/crud/pdv/list.vue";
import PontuacaoManualDotzComponent from "@/components/parent/crud/pontuacaoManualDotz/list.vue";
import PosologiaEdit from "@/components/parent/crud/posologia/edit.vue";
import PosologiaList from "@/components/parent/crud/posologia/list.vue";
import PrescritorEdit from "@/components/parent/crud/prescritor/edit.vue";
import PrescritorList from "@/components/parent/crud/prescritor/list.vue";
import ProdutoEdit from "@/components/parent/crud/produto/edit.vue";
import ProdutoList from "@/components/parent/crud/produto/list.vue";
import ProdutodcbEdit from "@/components/parent/crud/produtodcb/edit.vue";
import ProdutodcbList from "@/components/parent/crud/produtodcb/list.vue";
import ProdutodciEdit from "@/components/parent/crud/produtodci/edit.vue";
import ProdutodciList from "@/components/parent/crud/produtodci/list.vue";
import ProdutoloteEdit from "@/components/parent/crud/produtolote/edit.vue";
import ProdutoloteList from "@/components/parent/crud/produtolote/list.vue";
import RacaAnimalEdit from "@/components/parent/crud/racaAnimal/edit.vue";
import RacaAnimalList from "@/components/parent/crud/racaAnimal/list.vue";
import RecebimentoParceladoEdit from "@/components/parent/crud/recebimentoParcelado/edit.vue";
import RecebimentoParceladoList from "@/components/parent/crud/recebimentoParcelado/list.vue";
import RecompensaEdit from "@/components/parent/crud/recompensa/edit.vue";
import RecompensaList from "@/components/parent/crud/recompensa/list.vue";
import RedeEdit from "@/components/parent/crud/rede/edit.vue";
import RedeList from "@/components/parent/crud/rede/list.vue";
import RotulopersonalizadoEdit from "@/components/parent/crud/rotulopersonalizado/edit.vue";
import RotulopersonalizadoList from "@/components/parent/crud/rotulopersonalizado/list.vue";
import SeparacaoEdit from "@/components/parent/crud/separacao/edit.vue";
import SeparacaoList from "@/components/parent/crud/separacao/list.vue";
import ServicoEdit from "@/components/parent/crud/servico/edit.vue";
import ServicoList from "@/components/parent/crud/servico/list.vue";
import SetorEdit from "@/components/parent/crud/setor/edit.vue";
import SetorList from "@/components/parent/crud/setor/list.vue";
import SngpcManualEdit from "@/components/parent/crud/sngpcManual/edit.vue";
import StatusSistema from "@/components/parent/crud/statusSistema/view.vue";
import TelaEdit from "@/components/parent/crud/tela/edit.vue";
import TelaList from "@/components/parent/crud/tela/list.vue";
import TelaacaoEdit from "@/components/parent/crud/telaacao/edit.vue";
import TelaacaoList from "@/components/parent/crud/telaacao/list.vue";
import TemplateWhatsAppEdit from "@/components/parent/crud/templateWhatsApp/edit.vue";
import TemplateWhatsAppList from "@/components/parent/crud/templateWhatsApp/list.vue";
import TransportadorEdit from "@/components/parent/crud/transportador/edit.vue";
import TransportadorList from "@/components/parent/crud/transportador/list.vue";
import TransportadoraEdit from "@/components/parent/crud/transportadora/edit.vue";
import TransportadoraList from "@/components/parent/crud/transportadora/list.vue";
import UnidademedidaEdit from "@/components/parent/crud/unidademedida/edit.vue";
import UnidademedidaList from "@/components/parent/crud/unidademedida/list.vue";
import UsuarioEdit from "@/components/parent/crud/usuario/edit.vue";
import UsuarioList from "@/components/parent/crud/usuario/list.vue";
import VendaContinuoList from "@/components/parent/crud/vendaContinuo/list.vue";
import Veterinario from "@/components/parent/crud/veterinario/view.vue";
import VisitadorEdit from "@/components/parent/crud/visitador/edit.vue";
import VisitadorList from "@/components/parent/crud/visitador/list.vue";
import CurvaABCPrescritores from "@/components/parent/curvaABC/curvaPrescritores.vue";
import CurvaABCProdutos from "@/components/parent/curvaABC/curvaProdutos.vue";
import DashboardEvolucaoVendas from "@/components/parent/dashboard/evolucaoVendas.vue";
import DashboardManipulacoes from "@/components/parent/dashboard/manipulacoes.vue";
import DashboardTotaisPorCategoria from "@/components/parent/dashboard/totaisPorCategoria.vue";
import DinamizacaoView from "@/components/parent/dinamizacao/view.vue";
import ClientesDuplicadosList from "@/components/parent/duplicacoes/clientesDuplicados/list.vue";
import PrescritoresDuplicadosList from "@/components/parent/duplicacoes/prescritoresDuplicados/list.vue";
import EstoqueMinimoComponent from "@/components/parent/estoqueMinimo/view.vue";
import ListEntrega from "@/components/parent/expedicao/listEntrega.vue";
import FichaTecnicaEdit from "@/components/parent/fichaTecnica/edit.vue";
import FluxoList from "@/components/parent/fluxo/list.vue";
import GradePCP from "@/components/parent/gradePCP/view.vue";
import HistoricoVendaHistorico from "@/components/parent/historico/vendaHistorico/list.vue";
import HomeHome from "@/components/parent/home/home.vue";
import ImportacaonfeEdit from "@/components/parent/importacaonfe/edit.vue";
import EsqueceuSenha from "@/components/parent/login/esqueceuSenha/view.vue";
import Login from "@/components/parent/login/login.vue";
import LogLevelComponent from "@/components/parent/logLevel/view.vue";
import ManipulacaoCancelamento from "@/components/parent/manipulacao/cancelamento.vue";
import ManipulacaoEdit from "@/components/parent/manipulacao/edit.vue";
import ManipulacaoList from "@/components/parent/manipulacao/list.vue";
import ManipulacaoListFormulaPadrao from "@/components/parent/manipulacao/listFormulaPadrao.vue";
import NfeEdit from "@/components/parent/nfe/edit.vue";
import NfeList from "@/components/parent/nfe/list.vue";
import NfseEdit from "@/components/parent/nfse/edit.vue";
import NfseList from "@/components/parent/nfse/list.vue";
import NotafiscalsaidaEmissaoLote from "@/components/parent/notafiscalemissaolote/emissaoLote.vue";
import NotafiscalentradaEdit from "@/components/parent/notafiscalentrada/edit.vue";
import NotafiscalentradaList from "@/components/parent/notafiscalentrada/list.vue";
import NotafiscalsaidaEdit from "@/components/parent/notafiscalsaida/edit.vue";
import NotafiscalsaidaList from "@/components/parent/notafiscalsaida/list.vue";
import PrintModelList from "@/components/parent/printModel/list.vue";
import PrintModelSessao from "@/components/parent/printModel/sessao.vue";
import RelatorioList from "@/components/parent/relatorio/list.vue";
import Relatorio from "@/components/parent/relatorio/view.vue";
import RelatorioStimulsoftComponent from "@/components/parent/relatorioStimulsoft/view.vue";
import SessaoCaixaAberturaCaixa from "@/components/parent/sessaoCaixa/aberturaCaixa.vue";
import SessaoCaixaFechamentoCaixa from "@/components/parent/sessaoCaixa/fechamentoCaixa.vue";
import SessaoCaixaList from "@/components/parent/sessaoCaixa/list.vue";
import MovimentacoesPendentesList from "@/components/parent/sessaoCaixa/listMovimentacoesPendente.vue";
import SessaoCaixaListMovimentosCaixa from "@/components/parent/sessaoCaixa/listMovimentosCaixa.vue";
import SiproquimEdit from "@/components/parent/siproquim/edit.vue";
import SngpcList from "@/components/parent/sngpc/list.vue";
import StatusPCP from "@/components/parent/statusPCP/view.vue";
import VendaCancelamento from "@/components/parent/venda/cancelamento.vue";
import VendaEdit from "@/components/parent/venda/edit.vue";
import VendaList from "@/components/parent/venda/list.vue";
import VendaListOrcamento from "@/components/parent/venda/listOrcamento.vue";

const routes = [
    { path: "/", component: HomeHome, meta: { title: "Home" } },
    { path: "/banco-edicao/:id?", component: BancoEdit },
    { path: "/banco-lista", component: BancoList },
    { path: "/bandeira-edicao/:id?", component: BandeiraEdit },
    { path: "/bandeira-lista", component: BandeiraList },
    { path: "/campanha-edicao/:id?", component: CampanhaEdit },
    { path: "/campanha-lista", component: CampanhaList },
    { path: "/capsula-edicao/:id?", component: CapsulaEdit },
    { path: "/capsula-lista", component: CapsulaList },
    {
        path: "/categoriacontrolereclamacao-edicao/:id?",
        component: CategoriaControleReclamacaoEdit,
        meta: { title: "Reclamações" },
    },
    {
        path: "/categoriacontrolereclamacao-lista",
        component: CategoriaControleReclamacaoList,
        meta: { title: "Reclamações" },
    },
    { path: "/cliente-edicao/:id?", component: ClienteEdit, meta: { title: "Cliente" } },
    { path: "/cliente-lista", component: ClienteList, meta: { title: "Clientes" } },
    { path: "/condicao-lista", component: CondicaoList },
    { path: "/condicao-edicao/:id?", component: CondicaoEdit },
    { path: "/configuracaofranquia-edicao", component: ConfiguracaofranquiaEdit },
    { path: "/configuracaogatewaypagamento-edicao/:id?", component: ConfiguracaoGatewayPagamentoEdit },
    { path: "/configuracaousuario-edicao", component: ConfiguracaousuarioEdit },
    { path: "/configuracaowhatsapp-edicao", component: ConfiguracaoWhatsAppEdit },
    { path: "/contacorrente-edicao/:id?", component: ContacorrenteEdit },
    { path: "/contacorrente-lista", component: ContacorrenteList },
    { path: "/contagerencialclassificacao-edicao/:id?", component: ContagerencialclassificacaoEdit },
    { path: "/contagerencialclassificacao-lista", component: ContagerencialclassificacaoList },
    { path: "/contagerencial-edicao/:id?", component: ContagerencialEdit },
    { path: "/contagerencial-lista", component: ContagerencialList },
    { path: "/contapaga-lista", component: ContapagaList, meta: { title: "Fluxo de Caixa" } },
    { path: "/contapagar-edicao/:id?", component: ContapagarEdit, meta: { title: "Fluxo de Caixa" } },
    { path: "/contapagar-lista", component: ContapagarList, meta: { title: "Fluxo de Caixa" } },
    {
        path: "/contapagar-multiplas-edicao/:ids?",
        component: ContapagarmultiplasEdit,
        meta: { title: "Fluxo de Caixa" },
    },
    { path: "/contareceber-edicao/:id?", component: ContareceberEdit, meta: { title: "Fluxo de Caixa" } },
    { path: "/contareceber-lista", component: ContareceberList, meta: { title: "Fluxo de Caixa" } },
    { path: "/controlereclamacao-lista", component: ControleReclamacaoList, meta: { title: "Reclamações" } },
    { path: "/controlereclamacao-edicao/:id?", component: ControleReclamacaoEdit, meta: { title: "Reclamações" } },
    { path: "/contareceberOperadoras-lista", component: ContareceberListOperadoras, meta: { title: "Fluxo de Caixa" } },
    { path: "/contareceberParceladas-lista", component: ContareceberParceladasList, meta: { title: "Fluxo de Caixa" } },
    {
        path: "/contareceberVendas-lista",
        component: ContaReceberVendasListComponent,
        meta: { title: "Fluxo de Caixa" },
    },
    {
        path: "/contareceber-multiplas-edicao/:ids?",
        component: ContarecebermultiplasEdit,
        meta: { title: "Fluxo de Caixa" },
    },
    { path: "/contarecebida-lista", component: ContarecebidaList, meta: { title: "Fluxo de Caixa" } },
    {
        path: "/contarecebidaOperadoras-lista",
        component: ContarecebidaListOperadoras,
        meta: { title: "Fluxo de Caixa" },
    },
    { path: "/dashboard/evolucao-vendas", component: DashboardEvolucaoVendas, meta: { title: "Dashboard" } },
    { path: "/dashboard/manipulacoes", component: DashboardManipulacoes, meta: { title: "Dashboard" } },
    { path: "/dashboard/totais-categoria", component: DashboardTotaisPorCategoria, meta: { title: "Dashboard" } },
    { path: "/dinamizacao-view", component: DinamizacaoView },
    { path: "/fluxo-de-caixa", component: FluxoList, meta: { title: "Fluxo de Caixa" } },
    { path: "/especialidade-edicao/:id?", component: EspecialidadeEdit },
    { path: "/especialidade-lista", component: EspecialidadeList },
    { path: "/especieanimal-edicao/:id?", component: EspecieanimalEdit },
    { path: "/especieanimal-lista", component: EspecieanimalList },
    { path: "/estoque-edicao/:id?", component: EstoqueEdit },
    { path: "/estoque-lista", component: EstoqueList },
    { path: "/estoqueminimo-lista", component: EstoqueMinimoComponent },
    { path: "/formafarmaceutica-edicao/:id?", component: FormafarmaceuticaEdit },
    { path: "/formafarmaceutica-lista", component: FormafarmaceuticaList },
    { path: "/formapagamento-edicao/:id?", component: FormapagamentoEdit },
    { path: "/formapagamento-lista", component: FormapagamentoList },
    { path: "/formarecebimento-edicao/:id?", component: FormarecebimentoEdit },
    { path: "/formarecebimento-lista", component: FormarecebimentoList },
    { path: "/formulapadrao-edicao/:id?", component: FormulapadraoEdit },
    { path: "/formulapadrao-lista", component: FormulapadraoList },
    { path: "/formulapadrao-manipulacao/:ids?", component: FormulapadraomanipulacaoEdit },
    { path: "/formulapadrao-recalculo-valor-produto/:ids?", component: FormulapadraovalorprodutoList },
    { path: "/fornecedor-edicao/:id?", component: FornecedorEdit },
    { path: "/fornecedor-lista", component: FornecedorList },
    { path: "/franquia-edicao/:id?", component: FranquiaEdit },
    { path: "/franquia-lista", component: FranquiaList },
    { path: "/franquia-integracao", component: FranquiaIntegracao },
    { path: "/grupo-edicao/:id?", component: GrupoEdit },
    { path: "/grupo-lista", component: GrupoList },
    { path: "/grupoproduto-edicao/:id?", component: GrupoprodutoEdit },
    { path: "/grupoproduto-lista", component: GrupoprodutoList },
    { path: "/importacaonfe-edicao", component: ImportacaonfeEdit },
    {
        path: "/justificativacontrolereclamacao-edicao/:id?",
        component: JustificativaControleReclamacaoEdit,
        meta: { title: "Reclamações" },
    },
    {
        path: "/justificativacontrolereclamacao-lista",
        component: JustificativaControleReclamacaoList,
        meta: { title: "Reclamações" },
    },
    { path: "/kitembalagem-edicao/:id?", component: KitembalagemEdit },
    { path: "/kitembalagem-lista", component: KitembalagemList },
    { path: "/curvaabc-view", component: CurvaABCProdutos },
    { path: "/curvaabcprescritores-view", component: CurvaABCPrescritores },
    { path: "/listacontrolado-edicao/:id?", component: ListacontroladoEdit },
    { path: "/listacontrolado-lista", component: ListacontroladoList },
    { path: "/manipulacaoordem-lista", component: ManipulacaoList, meta: { title: "Manipulações" } },
    {
        path: "/manipulacaoordem-lista-formula-padrao",
        component: ManipulacaoListFormulaPadrao,
        meta: { title: "Manipulações" },
    },
    { path: "/manipulacaoordem-edicao/:id?", component: ManipulacaoEdit, meta: { title: "Manipulações" } },
    {
        path: "/manipulacaoordem-cancelamento/:id?",
        component: ManipulacaoCancelamento,
        meta: { title: "Manipulações" },
    },
    { path: "/meta-lista", component: MetaList },
    { path: "/meta-edicao/:id?", component: MetaEdit },
    { path: "/movimentacaoconta-edicao/:id?", component: MovimentacaocontaEdit, meta: { title: "Fluxo de Caixa" } },
    { path: "/movimentacaoconta-lista", component: MovimentacaocontaList, meta: { title: "Fluxo de Caixa" } },
    { path: "/movimentacaoestoque-edicao/:id?", component: MovimentacaoestoqueEdit },
    { path: "/movimentacaoestoque-lista", component: MovimentacaoestoqueList },
    { path: "/motivocancelamento-edicao/:id?", component: MotivocancelamentoEdit },
    { path: "/motivocancelamento-lista", component: MotivocancelamentoList },
    { path: "/motivorejeicao-edicao/:id?", component: MotivoRejeicaoEditComponent },
    { path: "/motivorejeicao-lista", component: MotivoRejeicaoListComponent },
    { path: "/metodoPagamento-edicao/:id?", component: MetodoPagamentoEdit },
    { path: "/metodoPagamento-lista", component: MetodoPagamentoList },
    { path: "/molde-edicao/:id?", component: MoldeEdit },
    { path: "/molde-lista", component: MoldeList },
    { path: "/naturezaoperacao-edicao/:id?", component: NaturezaoperacaoEdit },
    { path: "/naturezaoperacao-lista", component: NaturezaoperacaoList },
    { path: "/nbm-edicao/:id?", component: NbmEdit },
    { path: "/nbm-lista", component: NbmList },
    { path: "/ncm-edicao/:id?", component: NcmEdit },
    { path: "/ncm-lista", component: NcmList },
    { path: "/notafiscalentrada-lista", component: NotafiscalentradaList, meta: { title: "Notas" } },
    { path: "/notafiscalentrada-edicao/:id?", component: NotafiscalentradaEdit, meta: { title: "Notas" } },
    { path: "/notafiscalsaida-lista", component: NotafiscalsaidaList, meta: { title: "Notas" } },
    { path: "/notafiscalsaida-emissao-lote", component: NotafiscalsaidaEmissaoLote, meta: { title: "Notas" } },
    { path: "/notafiscalsaida-edicao/:id?", component: NotafiscalsaidaEdit, meta: { title: "Notas" } },
    { path: "/operadoraCartoes-edicao/:id?", component: OperadoraCartoesEdit },
    { path: "/operadoraCartoes-lista", component: OperadoraCartoesList },
    { path: "/paciente-edicao/:id?", component: PacienteEdit },
    { path: "/paciente-lista", component: PacienteList },
    { path: "/posologia-edicao/:id?", component: PosologiaEdit },
    { path: "/posologia-lista", component: PosologiaList },
    { path: "/produtodcb-edicao/:id?", component: ProdutodcbEdit, meta: { title: "Produtos" } },
    { path: "/produtodcb-lista", component: ProdutodcbList, meta: { title: "Produtos" } },
    { path: "/produtodci-edicao/:id?", component: ProdutodciEdit, meta: { title: "Produtos" } },
    { path: "/produtodci-lista", component: ProdutodciList, meta: { title: "Produtos" } },
    { path: "/produto-edicao/:id?", component: ProdutoEdit, meta: { title: "Produtos" } },
    { path: "/produto-lista", component: ProdutoList, meta: { title: "Produtos" } },
    { path: "/produtolote-edicao/:id?", component: ProdutoloteEdit, meta: { title: "Lotes" } },
    { path: "/produtolote-lista", component: ProdutoloteList, meta: { title: "Lotes" } },
    { path: "/relatorios", component: RelatorioList, meta: { title: "Relatórios" } },
    { path: "/servico-lista", component: ServicoList },
    { path: "/servico-edicao/:id?", component: ServicoEdit },
    { path: "/tela-edicao/:id?", component: TelaEdit },
    { path: "/tela-lista", component: TelaList },
    { path: "/telaacao-edicao/:id?", component: TelaacaoEdit },
    { path: "/telaacao-lista", component: TelaacaoList },
    { path: "/unidademedida-edicao/:id?", component: UnidademedidaEdit },
    { path: "/unidademedida-lista", component: UnidademedidaList },
    { path: "/usuario-edicao/:id?", component: UsuarioEdit },
    { path: "/usuario-lista", component: UsuarioList },
    { path: "/orcamento-lista", component: VendaListOrcamento, meta: { title: "Orçamentos" } },
    { path: "/venda-lista", component: VendaList, meta: { title: "Vendas" } },
    { path: "/venda-edicao/:id?/:vendaReplicada?", component: VendaEdit, meta: { title: "Vendas" } },
    {
        path: "/venda-edicao/clienteId?:enderecoEntregaId?:pacienteId?:origem?",
        component: VendaEdit,
        meta: { title: "Vendas" },
    },
    { path: "/venda-cancelamento/:id?", component: VendaCancelamento, meta: { title: "Vendas" } },
    { path: "/prescritor-edicao/:id?", component: PrescritorEdit, meta: { title: "Prescritores" } },
    { path: "/prescritor-lista", component: PrescritorList, meta: { title: "Prescritores" } },
    { path: "/printModel-list/:tela", component: PrintModelList },
    { path: "/printModel-edicao/:tela/:id?", component: PrintModelSessao },
    { path: "/pdv-edicao/:id?", component: PdvEdit },
    { path: "/pdv-lista", component: PdvList },
    { path: "/recompensa-edicao/:id?", component: RecompensaEdit },
    { path: "/recompensa-lista", component: RecompensaList },
    { path: "/sessaoCaixa-abertura", component: SessaoCaixaAberturaCaixa, meta: { title: "Caixa" } },
    { path: "/sessaoCaixa-lista", component: SessaoCaixaListMovimentosCaixa, meta: { title: "Caixa" } },
    { path: "/sessaoCaixa-fechamento", component: SessaoCaixaList, meta: { title: "Caixa" } },
    { path: "/sessaoCaixa-movimentacoespendentes", component: MovimentacoesPendentesList, meta: { title: "Caixa" } },
    { path: "/sessaoCaixa-fechar-caixa", component: SessaoCaixaFechamentoCaixa, meta: { title: "Caixa" } },
    { path: "/cest-edicao/:id?", component: CestEdit },
    { path: "/setor-lista/", component: SetorList },
    { path: "/setor-edicao/:id?", component: SetorEdit },
    { path: "/cest-lista", component: CestList },
    { path: "/venda-continuo-lista", component: VendaContinuoList, meta: { title: "Vendas" } },
    { path: "/sngpc", component: SngpcList },
    { path: "/alterar-senha", component: Alterarsenha },
    { path: "/status-sistema", component: StatusSistema },
    { path: "/email-lista", component: EmailList },
    { path: "/email-edicao/:id?", component: EmailEdit },
    { path: "/historico-envios-dotz-lista", component: HistoricoEnviosDotzList, meta: { title: "Dotz" } },
    { path: "/racaAnimal-lista", component: RacaAnimalList },
    { path: "/racaAnimal-edicao/:id?", component: RacaAnimalEdit },
    { path: "/livro-registro-especifico", component: Livroregistroespecifico },
    { path: "/balanco-completo", component: BalancoCompleto },
    { path: "/mapa-siproquim", component: MapaSiproquim },
    { path: "/pontuarManual-lista", component: PontuacaoManualDotzComponent, meta: { title: "Dotz" } },
    { path: "/veterinario", component: Veterinario },
    { path: "/venda-historico-list", component: HistoricoVendaHistorico, meta: { title: "Vendas" } },
    { path: "/visitador-edicao", component: VisitadorEdit },
    { path: "/visitador-edicao/:id?", component: VisitadorEdit },
    { path: "/visitador-lista", component: VisitadorList },
    { path: "/formulario-Sngpc", component: SngpcManualEdit },
    { path: "/templateWhatsapp-edicao/:id?", component: TemplateWhatsAppEdit },
    { path: "/templateWhatsapp-lista", component: TemplateWhatsAppList },
    { path: "/transportadora-edicao", component: TransportadoraEdit },
    { path: "/transportadora-edicao/:id?", component: TransportadoraEdit },
    { path: "/transportadora-lista", component: TransportadoraList },
    { path: "/transportador-edicao", component: TransportadorEdit },
    { path: "/transportador-edicao/:id?", component: TransportadorEdit },
    { path: "/transportador-lista", component: TransportadorList },
    { path: "/inventario-lista", component: InventarioList },
    { path: "/inventario-edicao", component: InventarioEdit },
    { path: "/inventario-edicao/:id?", component: InventarioEdit },
    { path: "/rede-edicao", component: RedeEdit },
    { path: "/rede-edicao/:id?", component: RedeEdit },
    { path: "/rede-lista", component: RedeList },
    { path: "/configuracaodotz-edicao", component: ConfiguracaodotzEdit, meta: { title: "Dotz" } },
    { path: "/fichatecnica-edicao/:id?", component: FichaTecnicaEdit },
    { path: "/ensaio-edicao/:id?", component: EnsaioEdit },
    { path: "/ensaio-lista", component: EnsaioList },
    { path: "/farmacopeia-edicao/:id?", component: FarmacopeiaEdit },
    { path: "/farmacopeia-lista", component: FarmacopeiaList },
    { path: "/dadosadicionaisfichatecnica-edicao/:id?", component: DadosAdicionaisFichaTecnicaEdit },
    { path: "/dadosadicionaisfichatecnica-lista", component: DadosAdicionaisFichaTecnicaList },
    { path: "/rotulopersonalizado-edicao/:id?", component: RotulopersonalizadoEdit },
    { path: "/rotulopersonalizado-lista", component: RotulopersonalizadoList },
    { path: "/separacao-edicao/:id?", component: SeparacaoEdit },
    { path: "/separacao-lista", component: SeparacaoList },
    { path: "/beneficio-edicao", component: BeneficioEdit },
    { path: "/beneficio-edicao/:id?", component: BeneficioEdit },
    { path: "/beneficio-lista", component: BeneficioList },
    { path: "/nfe-edicao/:id?", component: NfeEdit },
    { path: "/nfe-lista", component: NfeList },
    { path: "/nfse-edicao/:id?", component: NfseEdit },
    { path: "/nfse-lista", component: NfseList },
    { path: "/clinica-edicao", component: ClinicaEdit },
    { path: "/clinica-edicao/:id?", component: ClinicaEdit },
    { path: "/clinica-lista", component: ClinicaList },
    { path: "/cupom-edicao", component: CupomEdit },
    { path: "/cupom-edicao/:id?", component: CupomEdit },
    { path: "/cupom-lista", component: CupomList },
    { path: "/convenio-edicao", component: ConvenioEdit },
    { path: "/convenio-edicao/:id?", component: ConvenioEdit },
    { path: "/convenio-lista", component: ConvenioList },
    { path: "/relatorio-view/:id?", component: Relatorio, meta: { title: "Relatório" } },
    { path: "/cor-edicao/:id?", component: CorEdit },
    { path: "/cor-lista", component: CorList },
    { path: "/motivomovimentacaoestoque-edicao/:id?", component: MotivomovimentacaoestoqueEdit },
    { path: "/motivomovimentacaoestoque-lista", component: MotivomovimentacaoestoqueList },
    { path: "/etapaPCP-edicao/:id?", component: EtapaPCPEdit, meta: { title: "PCP" } },
    { path: "/etapaPCP-lista", component: EtapaPCPList, meta: { title: "PCP" } },
    { path: "/motivoetapaPCP-edicao/:id?", component: MotivoEtapaPCPEdit, meta: { title: "PCP" } },
    { path: "/motivoetapaPCP-lista", component: MotivoEtapaPCPList, meta: { title: "PCP" } },
    { path: "/configuracaoEtapaPCP-edicao", component: ConfiguracaoEtapaPCP, meta: { title: "PCP" } },
    { path: "/colaborador-edicao/:id?", component: ColaboradorEdit },
    { path: "/colaborador-lista", component: ColaboradorList },
    { path: "/pcp-edicao/:id?", component: PcpEdit, meta: { title: "PCP" } },
    { path: "/devolucaocompra-lista", component: DevolucaocompraList },
    { path: "/devolucaocompra-edicao/:id?", component: DevolucaocompraEdit },
    { path: "/devolucaocompra-consulta/:id?", component: DevolucaocompraConsulta },
    { path: "/inutilizacaonotafiscal-lista", component: InutilizacaoNotaFiscalList },
    { path: "/inutilizacaonotafiscal-edicao/:id?", component: InutilizacaoNotaFiscalEdit },
    { path: "/downloadXmls-edicao", component: DownloadXmlsEdit },
    { path: "/siproquim-edicao", component: SiproquimEdit },
    { path: "/recebimentoParcelado-edicao/:id?", component: RecebimentoParceladoEdit },
    { path: "/recebimentoParcelado-lista", component: RecebimentoParceladoList },
    { path: "/capacidadeProducao-edicao", component: CapacidadeProducaoEdit, meta: { title: "PCP" } },
    { path: "/gradePCP-view", component: GradePCP, meta: { title: "PCP" } },
    { path: "/statusPCP-view", component: StatusPCP, meta: { title: "PCP" } },
    { path: "/atualizacao-valores-produto-edicao", component: AtualizacaoValoresProduto },
    { path: "/atualizacao-valores-forma-farmaceutica-edicao", component: AtualizacaoValoresFormaFarmaceutica },
    { path: "/localentrega-lista", component: LocalentregaList },
    { path: "/localentrega-edicao/:id?", component: LocalentregaEdit },
    { path: "/clientesduplicados-lista", component: ClientesDuplicadosList },
    { path: "/prescritoresduplicados-lista", component: PrescritoresDuplicadosList },
    { path: "/login", component: Login },
    { path: "/esqueceu-senha", component: EsqueceuSenha },
    { path: "/relatorio-stimulsoft/:id", component: RelatorioStimulsoftComponent },
    { path: "/log-level", component: LogLevelComponent },
    { path: "/pontuar", component: PontuarComponent },
    { path: "/entrega-lista", component: ListEntrega, meta: { title: "Expedição" } },
    { path: "/coleta-edicao/:id?", component: ColetaEditComponent, meta: { title: "Expedição" } },
    { path: "/coleta-lista", component: ColetaListComponent, meta: { title: "Expedição" } },
    { path: "/compra-edicao/:id?", component: ComprasEditComponent },
    { path: "/compra-lista", component: ComprasListComponent },
    { path: "/declaracao-conteudo", component: DeclaracaoConteudoComponent, meta: { title: "Expedição" } },
    { path: "/compra-cotacao/:id?", component: CompraCotacaoEditComponent },
    { path: "/melhor-compra/:id?", component: MelhorCompraEditComponent },
    { path: "/chatbot", component: ChatBotComponent, meta: { title: "Chat Bot" } },
    { path: "/atendimento", component: AtendimentoComponent, meta: { title: "Atendimento" } },
    { path: "/manipuladores", component: Manipuladores },
    { path: "/etiquetas", component: EtiquetasListComponent, meta: { title: "Expedição" } },
    { path: "/cotacao-fornecedor/:key?", component: CotacaoFornecedorComponent },
    { path: "/configuracaoatendimento-edicao", component: ConfiguracaoAtendimentoEdit },
];

export default routes;
