import Boot from "@/main";
import StatusEnvioNF from "@/models/enum/statusEnvioNF";
import NotaFiscalServicoEletronicaModel from "@/models/nfse/notaFiscalServicoEletronicaModel";
import VendaModel from "@/models/vendaModel";
import { AlertColor } from "@/utils/common/alert";
import nfetch from "@/utils/events/nfetch";

import NotaFiscalSaidaService from "../notaFiscalSaidaService";
import { getCookie } from "../utils/serviceUtils";

export enum VerificaCancelamentoRetorno {
    CANCELANOTA = 1,
    NAOCANCELA = 2,
    CANCELANOTASISTEMA = 3,
}

// Porto Velho, Goiania, São Luis, São José dos Pinhais, Araruama
const municipiosNaoPermiteCancelamento = [1100205, 5208707, 2111300, 4125506, 3300209];

export default class NotaFiscalServicoService {
    private controllerName = "NotaFiscalServicoEmissao";

    private notaFiscalSaidaService = new NotaFiscalSaidaService();

    public emitirByVenda(venda: VendaModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/EmitirByVenda`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(venda),
        });
    }

    public consultarBySearchKey(searchKey: string): Promise<Response> {
        return nfetch(`/${this.controllerName}/ConsultaBySearchKey`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(searchKey),
        });
    }

    public async verificaCancelamento(notaId: number, cidadeId: number): Promise<VerificaCancelamentoRetorno> {
        if (municipiosNaoPermiteCancelamento.some(p => p == cidadeId)) {
            try {
                const response = await Boot.$showQuestion(
                    "Atenção",
                    "O municipio não permite o cancelamento da nota via sistema, somente pelo portal da prefeitura. Não será possível cancelar a nota! Caso já tenha feito o cancelamento na prefeitura, deseja cancelar a nota no sistema?",
                );

                if (response) {
                    await this.notaFiscalSaidaService.cancelaNota(notaId);
                    return VerificaCancelamentoRetorno.CANCELANOTASISTEMA;
                }
            } catch {}
            return VerificaCancelamentoRetorno.NAOCANCELA;
        }
        return VerificaCancelamentoRetorno.CANCELANOTA;
    }

    public async cancelamentoSelecao() {
        return await Boot.$showAlert({
            title: "Ao prosseguir, a rotina enviará uma requisição de cancelamento de nota fiscal. Para prosseguir escolha um motivo de cancelamento.",
            text: "Esta ação não poderá ser revertida!",
            icon: "warning",
            html: `<label>Motivo do Cancelamento:</label>`,
            confirmButtonText: "Cancelar",
            cancelButtonText: "Fechar",
            confirmButtonColor: AlertColor.RED,
            input: "select",
            inputOptions: {
                0: " ",
                1: "1 - Erro de Emissão",
                2: "2 - Serviço Não Prestado",
                3: "3 - Erro de Assinatura",
                4: "4 - Duplicidade de Nota",
            },
            inputValidator: result => {
                if (Number(result) > 0) {
                    return "";
                }
                return "Obrigatório informar o motivo do cancelamento.";
            },
            showCancelButton: true,
        });
    }

    public cancelamento(numero: number, motivoCancelamento: number): Promise<Response> {
        return nfetch(`/${this.controllerName}/Cancelar`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify({
                numero,
                motivoCancelamento,
            }),
        }).then(response => {
            if (response.ok) {
                Boot.$showSuccess("Cancelamento da Nota", "Cancelamento da Nota feito com sucesso.");
            }
            return response;
        });
    }

    public listNotasServico(
        filterKey: string,
        sortKey: string,
        sortOrder: string,
        pageIndex: number,
        pageSize: number,
    ): Promise<Response> {
        const encodedFilter = encodeURIComponent(filterKey);
        return nfetch(
            `/${this.controllerName}/ListNotasServico?filterKey=${encodedFilter}&sortKey=${sortKey}&sortOrder=${sortOrder}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            {
                credentials: "same-origin",
            },
        );
    }

    public async emitirNFSE(nota: NotaFiscalServicoEletronicaModel): Promise<Response> {
        return nfetch(`/${this.controllerName}/EmitirNFSE`, {
            method: "post",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
                "XSRF-TOKEN": getCookie("XSRF-TOKEN"),
            },
            body: JSON.stringify(nota),
        });
    }

    public async showMessageByStatus(statusRetorno: StatusEnvioNF) {
        switch (statusRetorno) {
            case StatusEnvioNF.Pendente:
                await Boot.$showInfo(
                    "Envio Pendente",
                    "Nota fiscal está com retorno pendente da prefeitura, aguarde uns minutos e consulte a emissão da nota para verificar se a emissão ocorreu com sucesso",
                );
                break;
            case StatusEnvioNF.Reprovado:
                await Boot.$showWarning("Envio Reprovado", "Nota fiscal foi reprovada, emita a nota novamente");
                break;
            case StatusEnvioNF.Confirmado:
                await Boot.$showSuccess("Envio Confirmado", "Nota fiscal foi emitida com sucesso");
                break;
        }
    }
}
