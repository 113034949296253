import { mapGetters, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumn, GridColumnSearchColor, GridColumnType } from "@/components/child/grid/gridColumn";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import SituacoesVenda from "@/models/enum/situacoesVenda";
import PaginationModel from "@/models/paginationModel";
import VendaModel from "@/models/vendaModel";
import VendaExpedicaoService from "@/services/venda/vendaExpedicaoService";
import VendaService from "@/services/vendaService";
import { AppState, Getters } from "@/store/store";

import ListComponentBase from "../crud/listComponentBase";
import ShortcutComponent from "../shortcut/shortcut";
import shortcutComponent from "../shortcut/shortcut.vue";

import EnderecoEntregaComponent from "./enderecoEntrega";
import enderecoEntregaComponent from "./enderecoEntrega.vue";

@Component({
    components: {
        comboComponent,
        shortcutComponent,
        enderecoEntregaComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
        }),
        ...mapGetters(["GET_IS_FRACIONAMENTO"] as Getters),
    },
})
export default class VendaExpedicaoListComponent extends ListComponentBase<VendaModel> {
    // State computed props
    GET_IS_FRACIONAMENTO: () => Promise<boolean>;
    preLoadList: PreLoadPackModel;

    private vendaExpedicaoService = new VendaExpedicaoService();
    private vendaService = new VendaService();

    private shortcutComponentEntrega: ShortcutComponent = null;
    private enderecoEntregaComponent: EnderecoEntregaComponent = null;

    extraActions: Array<object> = [];
    gridExtraActionsVisualizar: Array<GridAction> = [];
    gridExtraActionsFinalizar: Array<GridAction> = [];
    gridExtraActionsInformacao: Array<GridAction> = [];

    isFracionamento = false;

    isHidden = false;
    setorId = null;
    setorOptions = [];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumnSearchColor("codigo", this.$t("__.ts.gridCodigo"), GridColumnType.Integer),
            new GridColumnSearchColor("clienteNome", this.$t("__.ts.gridCliente"), GridColumnType.String),
            new GridColumnSearchColor("dataEntrega", this.$t("__.ts.dataEntrega"), GridColumnType.DateTime),
            new GridColumnSearchColor("setorDescricao", this.$t("__.ts.setor"), GridColumnType.String),
            new GridColumnSearchColor("valorFinal", this.$t("__.ts.vlr"), GridColumnType.Money),
        ];
    }

    private beforeMount() {
        this.setProps(this.vendaService, "venda", "codigo");
    }

    public async afterMounted() {
        this.enderecoEntregaComponent = this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent;
        this.shortcutComponentEntrega = this.$refs.shortcutComponentEntrega as ShortcutComponent;

        this.isFracionamento = await this.GET_IS_FRACIONAMENTO();

        this.setorOptions = this.preLoadList.setoresCombo();
    }

    public async loadMethod() {
        return await this.vendaExpedicaoService
            .listExpedicaoPagination(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                this.setorId,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<VendaModel>>();
    }

    public async afterLoad() {
        this.isHidden = false;

        this.gridExtraActionsVisualizar.splice(0);
        this.gridExtraActionsFinalizar.splice(0);
        this.gridExtraActionsInformacao.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            this.gridExtraActionsInformacao.push(
                new GridAction(
                    "adicionar-expedicao",
                    "Adicionar informação da expedição",
                    "fa fa-truck",
                    GridColors.DARKBLUE,
                    true,
                ),
            );

            this.gridExtraActionsVisualizar.push(
                new GridAction(
                    "visualiza-expedicao",
                    this.$t("__.ts.detalhExpedic") as string,
                    "fa fa-eye",
                    GridColors.BLUE,
                    true,
                ),
            );

            this.gridExtraActionsFinalizar.push(
                new GridAction(
                    "finaliza-expedicao",
                    this.$t("__.ts.finalizExpedic") as string,
                    "fa fa-check",
                    GridColors.GREEN,
                    true,
                ),
            );
        }

        this.extraActions.push(this.gridExtraActionsInformacao);
        this.extraActions.push(this.gridExtraActionsVisualizar);
        this.extraActions.push(this.gridExtraActionsFinalizar);
    }

    private async onExtraAction(name: string, model: VendaModel) {
        if (name.trim() == "adicionar-expedicao") {
            await this.onDadosExpedicao(model).withLoading();
        } else if (name.trim() == "visualiza-expedicao") {
            this.$router.push("/venda-edicao/" + model.id);
        } else if (name.trim() == "finaliza-expedicao") {
            await this.onFinaliza(model).withLoading();
        }
    }

    private async onDadosExpedicao(venda: VendaModel) {
        this.enderecoEntregaComponent.setVenda({ ...venda } as VendaModel);
        this.shortcutComponentEntrega.title = this.$t("__.ts.infoEntrega") as string;
        this.shortcutComponentEntrega.show();
    }

    private async onFinaliza(venda: VendaModel) {
        const response = await this.$showQuestion(this.$t("__.ts.atencao"), this.$t("__.ts.expedFinalizMesmoSemDados"));
        if (response) {
            venda.situacao = SituacoesVenda.EmFaturamento;
            const retorno = await this.vendaService.updateExpedicao(venda, true);
            if (retorno.ok) {
                this.$showSuccess(this.$t("__.ts.concluido"), this.$t("__.ts.expedFoiFinaliz"));
                this.load();
            } else {
                this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.ocorrErroAoInclRegis"));
            }
        }
    }

    private async onSalvarExpedicao() {
        const venda = this.enderecoEntregaComponent.getVenda();
        venda.situacao = SituacoesVenda.EmFaturamento;
        const response = await this.vendaService.updateExpedicao(venda);
        if (response.ok) {
            this.$showSuccess(this.$t("__.ts.concluido"), this.$t("__.ts.expedFoiFinaliz"));
            this.shortcutComponentEntrega.hide();
            this.load();
        } else {
            this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.ocorrErroAoInclRegis"));
        }
    }
}
