var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "shortcutComponent",
    {
      ref: "shortcutComponent",
      attrs: { showAddNew: false, modalClass: "ld-modal" },
      on: { "selection-item": _vm.onConfirm },
    },
    [
      _c("div", { attrs: { slot: "component" }, slot: "component" }, [
        _c("form", [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "nomeContato" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("__.Crud.templateWhatsapp.tag_vue_html.origem")
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("combo-component", {
                  attrs: {
                    id: "dados",
                    name: "dados",
                    data: _vm.dadosOptions,
                    searchable: true,
                  },
                  model: {
                    value: _vm.dados,
                    callback: function ($$v) {
                      _vm.dados = $$v
                    },
                    expression: "dados",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    staticClass: "control-label",
                    attrs: { for: "nomeContato" },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.$t("__.Crud.templateWhatsapp.tag_vue_html.campo")
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("combo-component", {
                  attrs: {
                    id: "campos",
                    name: "campos",
                    data: _vm.camposOptions,
                    searchable: true,
                  },
                  model: {
                    value: _vm.campos,
                    callback: function ($$v) {
                      _vm.campos = $$v
                    },
                    expression: "campos",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }