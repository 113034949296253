import ConfiguracaoEtapaPCPModel from "@/models/pcp/configuracaoEtapaPCPModel";
import nfetch from "@/utils/events/nfetch";

import { IService } from "./base/iService";

export default class ConfiguracaoEtapaPCPService extends IService<ConfiguracaoEtapaPCPModel> {
    constructor() {
        super("ConfiguracaoEtapaPCP");
    }

    public getByFranquia(): Promise<Response> {
        return nfetch(`/${this.controllerName}/GetByFranquia`, {
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=UTF-8",
            },
        });
    }
}
