import { mapGetters, mapState } from "vuex";

import comboComponent from "@/components/child/form/combo.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import { GridAction } from "@/components/child/grid/gridAction";
import { GridColors, GridColumnSearchColor, GridColumnType } from "@/components/child/grid/gridColumn";
import ImpressaoComponent from "@/components/child/impressao/impressaoComponent";
import impressaoComponent from "@/components/child/impressao/impressaoComponent.vue";
import { Component } from "@/decorators";
import PreLoadPackModel from "@/models/auxiliar/preLoadPackModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import DestinosManipulacao from "@/models/enum/destinosManipulacao";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import SituacoesManipulacaoOrdem from "@/models/enum/situacoesManipulacaoOrdem";
import ManipulacaoOrdemModel, { ManipulacaoOrdemListParameters } from "@/models/manipulacaoOrdemModel";
import ModeloImpressaoModel from "@/models/modeloImpressao/modeloImpressaoModel";
import PaginationModel from "@/models/paginationModel";
import UsuarioModel from "@/models/usuarioModel";
import ManipulacaoOrdemService from "@/services/manipulacaoOrdemService";
import ModeloImpressaolService from "@/services/modeloImpressaoService";
import { AppState, Getters } from "@/store/store";
import { getEstoqueCombo } from "@/utils/common/combo/combotext";
import { convertToDateInput, convertToDateInputString } from "@/utils/common/date";

import ListComponentBase from "../crud/listComponentBase";
import PrintModel from "../printModel/printModel";

@Component({
    components: {
        comboComponent,
        dateTimePickerComponent,
        impressaoComponent,
    },
    computed: {
        ...mapState({
            preLoadList: (state: AppState) => state.preLoad.preLoadList,
            usuarioLogado: (state: AppState) => state.session.usuarioLogado,
        }),
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO", "GET_CONFIG_FRANQUIA"] as Getters),
    },
})
export default class ManipulacaoOrdemListComponent extends ListComponentBase<ManipulacaoOrdemModel> {
    // State computed props
    preLoadList: PreLoadPackModel;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    usuarioLogado: UsuarioModel;

    private manipulacaoOrdemService = new ManipulacaoOrdemService();

    private impressaoComponent: ImpressaoComponent = null;

    isHidden = false;
    manipulacaoOrdemFiltro?: number = null;
    estoqueId?: number = null;
    dataFilter1: Date = null;
    dataFilter2: Date = null;

    situacaoManipulacaoOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByValues(
        SituacoesManipulacaoOrdem,
    ).filter(item => item.value !== 0);
    estoqueOptions: Array<Object> = [];

    gridSelectedValues: Array<ManipulacaoOrdemModel> = [];

    extraActions: Array<object> = [];
    gridExtraActionsImprimir: Array<GridAction> = [];
    gridExtraActionsImprimirRotulo: Array<GridAction> = [];

    get gridColumns(): Array<GridColumnSearchColor> {
        return [
            new GridColumnSearchColor("codigo", this.$t("__.ts.codigo"), GridColumnType.String, true),
            new GridColumnSearchColor("codigoVenda", this.$t("__.ts.venda"), GridColumnType.Integer, false),
            new GridColumnSearchColor(
                "formaFarmaceuticaDescricao",
                this.$t("__.ts.formfarmceu"),
                GridColumnType.String,
                false,
            ),
            new GridColumnSearchColor("situacaoDescricao", this.$t("__.ts.situacao"), GridColumnType.String, false),
            new GridColumnSearchColor("dataAprovacao", this.$t("__.ts.dataAprovacao"), GridColumnType.Date, false),
            new GridColumnSearchColor("dataConclusao", this.$t("__.ts.dataConclusao"), GridColumnType.Date, false),
            new GridColumnSearchColor(
                "observacao",
                this.$t("__.Crud.cliente.edit_vue_html.obs"),
                GridColumnType.String,
                false,
            ),
        ];
    }

    private beforeMount() {
        this.setProps(new ManipulacaoOrdemService(), "manipulacaoordem", "codigo", false, "desc");
    }

    public async afterMounted() {
        this.estoqueId = this.GET_CONFIG_FRANQUIA(Configuracoes.EstoquePadrao).estoqueId;

        this.estoqueOptions = this.preLoadList.estoques.filter(p => p.permiteVenda).map(getEstoqueCombo);
    }

    private getFormValues(listaFiltro: any) {
        this.filtros = [];
        this.filtros.push(this.manipulacaoOrdemFiltro);
        this.filtros.push(listaFiltro.target.elements.dataFilter1.value);
        this.filtros.push(listaFiltro.target.elements.dataFilter2.value);
        this.filtros.push(this.estoqueId);

        this.pageIndex = 1;
        this.load();
    }

    private onSelectedValuesChanged(values: Array<ManipulacaoOrdemModel>) {
        this.gridSelectedValues = values;
    }

    private async concluirManipulacoes() {
        if (this.gridSelectedValues.length > 0) {
            const ids = this.gridSelectedValues.map(p => p.id);

            const sucesso = await this.manipulacaoOrdemService.concluirLista(ids).withLoading().resolveWithoutJSON();
            if (sucesso) {
                await this.$showSuccess(this.$t("__.ts.conclManipu"), this.$t("__.ts.conclManipuMsg"));
                this.load();
            }
        } else {
            this.$showWarning(this.$t("__.ts.conclManipu"), this.$t("__.ts.nenhumRegisSelec"));
        }
    }

    public async loadMethod() {
        const customParameters: ManipulacaoOrdemListParameters = {
            situacao: this.filtros[0] != null && parseInt(this.filtros[0].toString()),
            destino: DestinosManipulacao.Venda,
            dataInicial: convertToDateInputString(this.filtros[1]),
            dataFinal: convertToDateInputString(this.filtros[2], true),
            estoqueId: this.filtros[3] != null && parseInt(this.filtros[3].toString()),
        };

        return await this.manipulacaoOrdemService
            .listManipulacao(
                this.gridFilterKey,
                this.gridSortKey,
                this.gridSortOrder,
                this.pags ? 1 : this.pageIndex,
                this.pags ? 999999 : this.pageSize,
                customParameters,
            )
            .withLoading()
            .resolveWithJSON<PaginationModel<ManipulacaoOrdemModel>>();
    }

    public async afterLoad() {
        this.gridExtraActionsImprimir.splice(0);
        this.gridExtraActionsImprimirRotulo.splice(0);
        this.extraActions.splice(0);

        for (let i = 0; i < this.gridData.length; i++) {
            if (this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ImprimirManipulacao")) {
                this.gridExtraActionsImprimir.push(
                    new GridAction(
                        "imprimir-manipulacao",
                        this.$t("__.ts.printManipu") as string,
                        "fa fa-print",
                        GridColors.DARKGRAY,
                    ),
                );
            }

            if (this.VALIDAR_PERMISSAO_USUARIO("manipulacoes_venda", "ImprimirRotuloManipulacao")) {
                this.gridExtraActionsImprimirRotulo.push(
                    new GridAction(
                        "imprimir-manipulacao-rotulo",
                        this.$t("__.ts.printRotuloManipu") as string,
                        "fa fa-tag",
                        GridColors.GREEN,
                    ),
                );
            }
        }

        this.extraActions.push(this.gridExtraActionsImprimirRotulo);
        this.extraActions.push(this.gridExtraActionsImprimir);
    }

    public async onRemoveItem(model: ManipulacaoOrdemModel) {
        this.$router.push("/manipulacaoordem-cancelamento/" + model.id);
    }

    public onCreateItem() {
        this.$router.push("/venda-edicao");
    }

    private onExtraAction(name: string, model: ManipulacaoOrdemModel) {
        this.impressaoComponent = this.$refs.impressaoComponent as ImpressaoComponent;

        if (name.trim() == "imprimir-manipulacao") {
            this.impressaoComponent
                .show({
                    modelId: model.id,
                    tipoImpressao: "Manipulacao",
                    impressaoRotulo: false,
                    impressaoSelecionarModelo: true,
                })
                .withLoading();
        } else if (name.trim() == "imprimir-manipulacao-rotulo") {
            this.impressaoComponent
                .show({
                    modelId: model.id,
                    tipoImpressao: "Manipulacao",
                })
                .withLoading();
        }
    }

    public async afterLoadPagination() {
        if (this.paginationParams.filtros != null) {
            this.filtros = this.paginationParams.filtros;

            if (this.filtros.length >= 0) {
                if (this.filtros.length >= 1) {
                    if (this.filtros[1]) {
                        this.dataFilter1 = convertToDateInput(this.filtros[1].toString());
                    }
                }
                if (this.filtros.length >= 2) {
                    if (this.filtros[2]) {
                        this.dataFilter2 = convertToDateInput(this.filtros[2].toString());
                    }
                }
            }
        }
    }

    private async imprimirSelecionados() {
        if (this.gridSelectedValues.length > 0) {
            try {
                const modelo = await new ModeloImpressaolService()
                    .GetPadraoByTelaName("manipulacao")
                    .resolveWithJSON<ModeloImpressaoModel>();

                const pages = await this.manipulacaoOrdemService
                    .getManyHTMLPage(
                        this.gridSelectedValues.map(x => x.id),
                        modelo.id,
                        1,
                        this.usuarioLogado.id,
                    )
                    .withLoading();

                const printModel = new PrintModel();
                printModel.showHTMLOnBrowser(pages.pageHtml, true);
            } catch {}
        } else {
            this.$showWarning(this.$t("__.ts.conclManipu"), this.$t("__.ts.nenhumRegisSelec"));
        }
    }
}
