var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-edit" },
    [
      _c("actionBarComponent", {
        attrs: {
          slots: 2,
          title: _vm.$t(
            "__.Crud.pcp.ConfiguracaoEtapaPCP.configuracaoEtapaPCP"
          ),
        },
        on: { onSave: _vm.save },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "novalidate" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              _vm.save()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { attrs: { slot: "rows" }, slot: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("etapaGradeVendaId"),
                          expression: "errors.first('etapaGradeVendaId')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "etapaGradeVendaId",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.pcp.ConfiguracaoEtapaPCP.etapaGradeVenda"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          id: "etapaGradeVendaId",
                          name: "etapaGradeVendaId",
                          data: _vm.etapaGradeVendaOptions,
                        },
                        model: {
                          value: _vm.model.etapaGradeVendaId,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "etapaGradeVendaId", $$v)
                          },
                          expression: "model.etapaGradeVendaId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("etapaGradePendenteId"),
                          expression: "errors.first('etapaGradePendenteId')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: {
                            for: "etapaGradePendenteId",
                            title: _vm.$t("__.obrigatorio"),
                          },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Crud.pcp.ConfiguracaoEtapaPCP.etapaGradePendente"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("combo-component", {
                        attrs: {
                          id: "etapaGradePendenteId",
                          name: "etapaGradePendenteId",
                          data: _vm.etapaGradePendenteOptions,
                        },
                        model: {
                          value: _vm.model.etapaGradePendenteId,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "etapaGradePendenteId", $$v)
                          },
                          expression: "model.etapaGradePendenteId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12 newbtn" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-save",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.salvar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$t("__.Crud.voltar")) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }