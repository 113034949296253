import BaseModel from "./base/baseModel";
import DestinosManipulacao from "./enum/destinosManipulacao";
import SituacoesManipulacaoOrdem from "./enum/situacoesManipulacaoOrdem";
import FormaFarmaceuticaModel from "./formaFarmaceuticaModel";
import ManipulacaoOrdemItemModel from "./manipulacaoOrdemItemModel";
import ReceitaArquivoModel from "./receitaArquivoModel";
import VendaItemModel from "./vendaItemModel";

export default class ManipulacaoOrdemModel extends BaseModel {
    id?: number = null;
    codigo = 0;
    codigoVenda = 0;
    nomeVendedor: string = null;
    situacao: SituacoesManipulacaoOrdem = SituacoesManipulacaoOrdem.Orcamento;
    situacaoDescricao: string = null;
    descricao: string = null;
    destino: DestinosManipulacao = null;
    destinoDescricao: string = null;
    observacao: string = null;
    observacaoUsuario?: string = null;
    formaFarmaceuticaId = 0;
    formaFarmaceutica: FormaFarmaceuticaModel = null;
    formaFarmaceuticaDescricao: string = null;
    formulaPadraoDescricao: string = null;
    prescritorId?: number = null;
    pacienteId?: number = null;
    pacienteNome: string = null;
    posologiaId: number = null;
    posologiaTexto: string = null;
    servicoId?: number = null;
    kitEmbalagemId?: number = null;
    movimentacaoEstoqueId?: number = null;
    moldeId?: number = null;
    manipuladorId?: number = null;

    manipulacaoOrdemCancelamentoId?: number = null;

    volumeTotal = 0;

    volume?: number = 0;
    unidadeMedidaId?: number = 0;
    quantidade?: number = 0;
    dataValidade: Date = null;
    dataConclusao: Date = null;
    dataEntrega: Date = null;
    dataAprovacao: Date = null;

    itens: ManipulacaoOrdemItemModel[] = [];
    valorFormaFarmaceutica = 0;
    valorProdutos = 0;
    valorTotal = 0;
    acrescimo = 0;
    acrescimoPercentual = 0;
    desconto = 0;
    descontoPercentual = 0;
    descontoConvenio = 0;
    statusEstoque: StatusEstoqueManipulacao = null;
    fatorMultQuantidade?: number = null;
    forcarMultQuantidade = false;
    numeroReceita?: string = null;
    dataValidadeReceita?: Date = null;
    possuiAtivoControlado?: boolean = false;
    isPreVenda?: boolean = false;
    isPreVendaEditavel?: boolean = true;
    formulaPadraoId?: number = null;
    aprovouDesconto = true;
    key: string = null;
    usaMarkupEquinos = false;
    homeopatia?: boolean = false;
    consolidado = false;

    vendaItem?: VendaItemModel = null;
    numeroFormulas = 1;
    estoqueId?: number = null;
    receitas: ReceitaArquivoModel[] = [];
    setorId: number = null;
    dataValidadeAlterada = false;
}

export enum StatusEstoqueManipulacao {
    NaoVerificado = 0,
    Conferido = 1,
    Divergente = 2,
}

export interface ManipulacaoOrdemListParameters {
    situacao?: number;
    destino?: number;
    dataInicial?: string;
    dataFinal?: string;
    estoqueId?: number;
}
