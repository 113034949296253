import { mapGetters, mapMutations } from "vuex";

import buttonIncluirComponent from "@/components/child/form/button/buttonIncluir.vue";
import cepFieldComponent from "@/components/child/form/cepfield.vue";
import checkboxComponent from "@/components/child/form/checkbox.vue";
import cnpjComponent from "@/components/child/form/cnpj.vue";
import comboComponent from "@/components/child/form/combo.vue";
import cpfComponent from "@/components/child/form/cpf.vue";
import dataTooltipComponent from "@/components/child/form/datatooltip.vue";
import dateTimePickerComponent from "@/components/child/form/datetimepicker.vue";
import fieldsetComponent from "@/components/child/form/fieldset.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import gridComponent from "@/components/child/grid/grid.vue";
import { GridColumn, GridColumnType } from "@/components/child/grid/gridColumn";
import infoBagdeComponent from "@/components/child/infoBagde/infoBagde.vue";
import logEntidadeComponent from "@/components/child/logEntidade/logEntidade.vue";
import pacienteComponent from "@/components/parent/crud/paciente/edit.vue";
import ShortcutComponent from "@/components/parent/shortcut/shortcut";
import shortcutComponent from "@/components/parent/shortcut/shortcut.vue";
import { Component, Prop, Watch } from "@/decorators";
import BeneficioModel from "@/models/beneficio/beneficioModel";
import CepModel from "@/models/cepModel";
import ClienteEmailNotaFiscalModel from "@/models/clienteEmailNotaFiscalModel";
import ClienteEnderecoEntregaModel from "@/models/clienteEnderecoEntregaModel";
import ClienteModel from "@/models/clienteModel";
import ConfiguracaoFranquiaModel from "@/models/configuracaoFranquia/configuracaoFranquiaModel";
import AreaAtuacao from "@/models/enum/areaAtuacao";
import Configuracoes from "@/models/enum/configuracao/configuracoes";
import EnumExtensions from "@/models/enum/extensions/enumExtensions";
import GenerosPessoa from "@/models/enum/generosPessoa";
import TiposPessoa from "@/models/enum/tiposPessoa";
import Ufs from "@/models/enum/ufs";
import EspecieAnimalModel from "@/models/paciente/especieAnimalModel";
import PacienteModel from "@/models/paciente/pacienteModel";
import PaginationModel from "@/models/paginationModel";
import VendaModel from "@/models/vendaModel";
import BeneficioService from "@/services/beneficioService";
import ClienteService from "@/services/clienteService";
import CidadeService from "@/services/external/cidadeService";
import EspecieAnimalService from "@/services/paciente/especieAnimalService";
import PaisService from "@/services/paisService";
import PDFService from "@/services/pdf/PDFService";
import VendaService from "@/services/vendaService";
import { Getters, Mutations } from "@/store/store";
import { copyObject } from "@/utils/common/copyObject";
import { editDateWithTime } from "@/utils/common/date";
import Delay from "@/utils/common/delay";

import EditComponentBase from "../editComponentBase";
import PacienteComponent from "../paciente/edit";

import EnderecoEntregaComponent from "./enderecoEntregaEdit";
import enderecoEntregaComponent from "./enderecoEntregaEdit.vue";
import VendasClienteListComponent from "./listVendas";
import vendasClienteListComponent from "./listVendas.vue";

import "../crud.scss";

@Component({
    components: {
        textareaComponent,
        fieldsetComponent,
        comboComponent,
        checkboxComponent,
        cpfComponent,
        cnpjComponent,
        cepFieldComponent,
        dateTimePickerComponent,
        logEntidadeComponent,
        dataTooltipComponent,
        pacienteComponent,
        gridComponent,
        enderecoEntregaComponent,
        buttonIncluirComponent,
        vendasClienteListComponent,
        infoBagdeComponent,
        shortcutComponent,
    },
    computed: {
        ...mapGetters(["VALIDAR_PERMISSAO_USUARIO", "GET_CONFIG_FRANQUIA"] as Getters),
        infoBagdeText() {
            return "Data criação: " + editDateWithTime(this.model.dataCriacao);
        },
    },
    methods: mapMutations(["LOAD_LIST"] as Mutations),
})
export default class ClienteEditComponent extends EditComponentBase<ClienteModel> {
    // State computed props
    VALIDAR_PERMISSAO_USUARIO: (telaDescricao: string, acaoController: string) => boolean;
    GET_CONFIG_FRANQUIA: (configuracao: Configuracoes) => ConfiguracaoFranquiaModel;
    infoBagdeText: string;

    private shortcutComponent: ShortcutComponent = null;
    private clienteService = new ClienteService();
    private pdfService = new PDFService("ClientePDF");
    private cidadeService = new CidadeService();
    private especieAnimalService = new EspecieAnimalService();
    private vendaService = new VendaService();
    private pacienteComponent: PacienteComponent = null;
    private enderecoEntregaComponent: EnderecoEntregaComponent = null;
    private vendasClienteListComponent: VendasClienteListComponent = null;
    private rotuloCarregado = false;
    private cpfObrigatorio = false;
    isFranquiaHumana = false;
    emailNotaFiscal = "";

    oldPaciente: PacienteModel = new PacienteModel();

    @Prop({ type: Boolean, default: false }) CalledByShortCut: boolean;

    private hideExtras = false;

    tiposPessoa = TiposPessoa;
    tiposPessoaOptions: Array<Object> = [
        { text: "F\u00edsica", value: TiposPessoa.Fisica },
        { text: "Jur\u00eddica", value: TiposPessoa.Juridica },
        { text: "Produtor Rural", value: TiposPessoa.ProdutorRural },
    ];

    generosPessoaOptions: Array<Object> = [
        { text: "Masculino", value: GenerosPessoa.Masculino },
        { text: "Feminino", value: GenerosPessoa.Feminino },
    ];

    gridColumnsEmails: Array<GridColumn> = [new GridColumn("email", "E-mail", GridColumnType.String)];

    get gridColumns(): Array<GridColumn> {
        return [
            new GridColumn("nome", "Nome", GridColumnType.String),
            new GridColumn("especieAnimalDescricao", "Esp\u00e9cie", GridColumnType.String),
            new GridColumn("generoDescricao", "G\u00eanero", GridColumnType.String),
            new GridColumn("peso", "Peso", GridColumnType.Decimal),
            new GridColumn("ativo", "Ativo", GridColumnType.Boolean),
        ];
    }

    gridColumnsEndereco: Array<GridColumn> = [
        new GridColumn("cep", "CEP", GridColumnType.String, false, false, "", "", true, false),
        new GridColumn("cidade", "Cidade", GridColumnType.String, false, false, "", "", true, false),
        new GridColumn("bairro", "Bairro", GridColumnType.String, false, false, "", "", true, false),
        new GridColumn("endereco", "Endere\u00e7o", GridColumnType.String, false, false, "", "", true, false),
    ];

    dadosCep: CepModel = new CepModel();
    ufOptions: Array<Object> = EnumExtensions.getNamesAndValuesOrderedByNames(Ufs);
    paisesOptions: Array<Object> = [];
    cidadesOptions: Array<Object> = [];
    beneficiosOptions: Array<Object> = [];
    modelEndereco: ClienteEnderecoEntregaModel = new ClienteEnderecoEntregaModel();

    private async beforeMount() {
        await this.setProps(new ClienteService(), "clientes", new ClienteModel(), this.CalledByShortCut);
    }

    public async load(customId = null) {
        while (!this.$refs.enderecoEntregaComponent) await Delay(5);
        this.enderecoEntregaComponent = this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent;

        while (!this.$refs.pacienteComponent) await Delay(5);
        this.pacienteComponent = this.$refs.pacienteComponent as PacienteComponent;

        this.enderecoEntregaComponent.clear();
        this.model = new ClienteModel();

        if (this.modelId > 0) {
            try {
                const data = await this.service.get(this.modelId).withLoading().resolveWithJSON<ClienteModel>();
                await this.onLoadCidades(data.estadoId);

                this.model.updateFrom(data);
                if (!this.isFranquiaHumana) {
                    this.model.pacientes.forEach(item => {
                        if (item.genero == null) {
                            item.generoDescricao = this.$t("__.ts.naoInfo").toString();
                        } else {
                            item.generoDescricao = item.genero
                                ? this.$t("__.ts.femea").toString()
                                : this.$t("__.ts.macho").toString();
                        }
                    });
                    this.pacienteComponent.carregarCliente(this.model.id);
                }
            } catch {
                this.$showError(this.$t("__.ts.erro"), this.$t("__.ts.erroCarregarDados"));
            }
        } else {
            this.model.tipoPessoa = TiposPessoa.Fisica;
        }
    }

    public async save() {
        try {
            if (await this.$validator.validateAll()) {
                if (this.model.cidadeId) {
                    this.model.cidade = this.cidadesOptions.filter(item => item["value"] == this.model.cidadeId)[0][
                        "text"
                    ];
                }
                if (this.model.codigoPais != null) {
                    this.model.pais = this.paisesOptions.filter(item => item["value"] == this.model.codigoPais)[0][
                        "text"
                    ];
                }

                if (!this.model.cpf) {
                    this.model.cpf = null;
                }
                if (!this.model.cnpj) {
                    this.model.cnpj = null;
                }

                const response = await this.service[!this.model.id ? "insert" : "update"](this.model)
                    .withLoading()
                    .resolveWithResponse();

                const nId = Number(response.headers.get("Id"));

                if (response.ok) {
                    await this.$showInclusaoUpdate(this.model.id);

                    if (this.CalledByShortCut) {
                        if (!this.model.id) {
                            this.model.id = nId;
                        }

                        this.$emit("save-ok", this.model);
                    } else {
                        this.$router.back();
                    }

                    return true;
                }
            } else {
                this.$focusErrorField();
            }
        } catch {}

        return false;
    }

    private async onVerPaciente(paciente: PacienteModel) {
        try {
            while (!this.$refs.pacienteComponent) await Delay(5);
            this.pacienteComponent = this.$refs.pacienteComponent as PacienteComponent;

            this.pacienteComponent.loadWithModel(paciente);

            while (!this.$refs.shortcutComponent) await Delay(10);
            this.shortcutComponent = this.$refs.shortcutComponent as ShortcutComponent;
            this.shortcutComponent.title = this.$t("__.ts.paciente").toString();
            this.oldPaciente = paciente;
        } catch {}

        this.shortcutComponent.show();
    }

    private async onCepChanged(data: CepModel) {
        this.dadosCep = data;
        this.model.bairro = data.bairro;
        this.model.complemento = data.complemento;
        this.model.endereco = data.endereco;
        this.model.estadoId = data.uf;
        await this.onLoadCidades(this.model.estadoId);
        this.model.cidadeId = data.cidadeId;
    }

    private replicarLocalizacao() {
        this.enderecoEntregaComponent.replicar(this.model);
    }

    private onCepInvalid() {
        this.$showInfo(this.$t("__.ts.pesqCEP"), this.$t("__.ts.pesqNaoEncResul"));
    }

    private onAddNewPaciente() {
        this.model.pacienteId = -1;
    }

    private onSavePaciente() {
        this.pacienteComponent.retornaPaciente();
        this.shortcutComponent.hide();
    }

    private async onAddPacienteOk(modelNovoPaciente: PacienteModel) {
        const alreadyExists = this.model.pacientes.some(p => p == this.oldPaciente);

        const paciente = new PacienteModel();

        try {
            const data = await this.especieAnimalService
                .get(modelNovoPaciente.especieAnimalId)
                .withLoading()
                .resolveWithJSON<EspecieAnimalModel>();

            modelNovoPaciente.especieAnimalDescricao = data.descricao;
            paciente.updateFrom(modelNovoPaciente);
            if (paciente.genero == null) {
                paciente.generoDescricao = this.$t("__.ts.naoInfo").toString();
            } else {
                paciente.generoDescricao = paciente.genero
                    ? this.$t("__.ts.femea").toString()
                    : this.$t("__.ts.macho").toString();
            }

            if (alreadyExists) {
                const index = this.model.pacientes.indexOf(this.oldPaciente);
                this.model.pacientes.splice(index, 1);
            }
            this.model.pacientes.push(paciente);

            this.pacienteComponent.clear();
            this.oldPaciente = null;
        } catch {}
    }

    private onAddEmail() {
        if (this.emailNotaFiscal) {
            try {
                const alreadyExists =
                    this.model.emailsNotaFiscal.filter(p => p.email == this.emailNotaFiscal).length > 0;

                if (!alreadyExists) {
                    const email = new ClienteEmailNotaFiscalModel();
                    email.email = this.emailNotaFiscal;
                    email.clienteId = this.model.id;

                    this.model.emailsNotaFiscal.push(email);
                    this.emailNotaFiscal = "";
                }
            } catch {}
        }
    }

    private onRemoverEmail(email: ClienteEmailNotaFiscalModel) {
        this.model.emailsNotaFiscal = this.model.emailsNotaFiscal.filter(p => p != email);
    }

    private async onAdicionarEndereco(endereco: ClienteEnderecoEntregaModel) {
        if (endereco.cep != null && endereco.endereco != null) {
            const alreadyExists = this.model.enderecosEntrega.some(
                p =>
                    p.cidadeId == endereco.cidadeId &&
                    p.cep == endereco.cep &&
                    p.estadoId == endereco.estadoId &&
                    p.endereco == endereco.endereco &&
                    p.numero == endereco.numero,
            );

            if (!alreadyExists) {
                if (endereco.enderecoPadrao) this.atualizaEnderecoPadrao();

                this.model.enderecosEntrega.push(endereco);
                (this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent).clear();
            } else {
                this.$showWarning(this.$t("__.ts.endeEntrega"), this.$t("__.ts.endeInfoJaCadast"));
            }
        } else {
            this.$showWarning(this.$t("__.ts.endeEntrega"), this.$t("__.ts.preenchCorretCamposAddLista"));
        }
    }

    private async onRemoveEndereco(endereco: ClienteEnderecoEntregaModel) {
        if (endereco.id != null)
            try {
                const data = await this.vendaService
                    .buscaVendaByEndereco(endereco)
                    .withLoading()
                    .resolveWithJSON<VendaModel[]>();
                if (data.length == 0) {
                    this.model.enderecosEntrega = this.model.enderecosEntrega.filter(p => p != endereco);
                } else {
                    this.$showWarning("Aviso", this.$t("__.ts.endeNaoRemovPoisVendasEntreg"));
                }
            } catch {}
        else {
            this.model.enderecosEntrega = this.model.enderecosEntrega.filter(p => p != endereco);
        }
    }

    private onEditEndereco(endereco: ClienteEnderecoEntregaModel) {
        this.enderecoEntregaComponent.clear();

        const index = this.model.enderecosEntrega.indexOf(endereco);
        this.modelEndereco = this.model.enderecosEntrega[index];
        const enderecoModel = new ClienteEnderecoEntregaModel();
        enderecoModel.updateFrom(endereco);

        this.enderecoEntregaComponent.load(enderecoModel);
    }

    private onEditEnderecoSubmit(endereco: ClienteEnderecoEntregaModel) {
        if (endereco.enderecoPadrao) {
            this.atualizaEnderecoPadrao();
        }

        const index = this.model.enderecosEntrega.indexOf(this.modelEndereco);
        copyObject(endereco, this.model.enderecosEntrega[index]);

        this.enderecoEntregaComponent.clear();
    }

    private atualizaEnderecoPadrao() {
        this.model.enderecosEntrega.forEach(value => {
            value.enderecoPadrao = false;
        });
    }

    @Watch("model.tipoPessoa")
    private onTipoPessoaChanged(tipoPessoa: TiposPessoa) {
        switch (tipoPessoa) {
            case TiposPessoa.Fisica:
                this.model.nomeFantasia = null;
                this.model.cnpj = null;
                this.model.inscricaoEstadual = null;
                this.model.inscricaoMunicipal = null;
                this.model.razaoSocial = null;
                break;
            case TiposPessoa.Juridica:
                this.model.nome = null;
                this.model.cpf = null;
                this.model.rg = null;
                this.model.dataNascimento = null;
                this.model.genero = null;
                this.model.orgaoExpedidor = null;
                this.model.ufExpedidor = null;
                break;
        }
    }

    private async onLoadCidades(estadoId: number) {
        try {
            this.cidadesOptions = [];
            if (estadoId) {
                this.cidadesOptions = await this.cidadeService.get(estadoId).withLoading();
            }
        } catch {}
    }

    private onChangeEmail() {
        if (this.model.emailsNotaFiscal.length == 0) {
            this.emailNotaFiscal = this.model.email;
            this.onAddEmail();
        }
    }

    @Watch("model.nome")
    private onNomeChanged(newValue, oldValue) {
        try {
            if (this.pacienteComponent) {
                setTimeout(() => this.pacienteComponent.montarRotuloPeloCliente(newValue), 500);
            }

            const primeiroNomeNew = newValue.split(" ")[0];
            const primeiroNomeOld = oldValue.split(" ")[0];
            if (
                !this.model.nomeRotulo ||
                (this.rotuloCarregado &&
                    this.model.nomeRotulo &&
                    (primeiroNomeNew != primeiroNomeOld || (!primeiroNomeNew && primeiroNomeOld)))
            ) {
                this.model.nomeRotulo = primeiroNomeNew;
            }
            this.rotuloCarregado = true;
        } catch {}
    }

    private async onBlurCpf(cpfCliente: string) {
        try {
            if (cpfCliente) {
                const data = await this.clienteService.existsByCPF(cpfCliente).withLoading().resolveWithJSON<boolean>();

                if (data) {
                    this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.cpfExistente"));
                }
            }
        } catch {}
    }

    private loadPaises() {
        const paises = new PaisService().get();
        this.paisesOptions = paises.map(pais => ({ value: pais.codigoSPED, text: pais.descricao }));
    }

    private async loadBeneficios() {
        try {
            const data = await new BeneficioService().combo().resolveWithJSON<PaginationModel<BeneficioModel>>();
            this.beneficiosOptions = data.list.map(item => ({
                value: item.id,
                text: item.descricao,
            }));
        } catch {}
    }

    public atualizarBeneficios() {
        this.loadBeneficios();
    }

    private async onOpenVendasModal() {
        while (!this.$refs.vendasClienteListComponent) await Delay(5);
        this.vendasClienteListComponent = this.$refs.vendasClienteListComponent as VendasClienteListComponent;
        this.vendasClienteListComponent.show(this.model.id, this.model.nome ?? this.model.razaoSocial);
    }

    private async onDelete() {
        try {
            const response = await this.$showQuestion(
                this.$t("__.ts.desejaExcl") + this.model.nome + "?",
                this.$t("__.ts.acaoNaoRevert"),
            );
            if (response) {
                const sucesso = await this.service.delete(this.model.id).withLoading();

                if (sucesso) {
                    await this.$showExclusao();
                    this.$router.back();
                }
            }
        } catch {}
    }

    private async print() {
        if (this.modelId > 0) {
            await this.pdfService.geraPDF(this.modelId);
        } else {
            this.$showWarning(this.$t("__.ts.atencao"), this.$t("__.ts.alertImpressao"));
        }
    }

    public async afterMounted() {
        this.enderecoEntregaComponent = this.$refs.enderecoEntregaComponent as EnderecoEntregaComponent;

        this.hideExtras = this.CalledByShortCut ? true : false;
        this.loadPaises();
        await this.loadBeneficios().withLoading();

        if (this.CalledByShortCut) {
            this.model = new ClienteModel();
        }

        if (this.usuarioLogado.franquia.areaAtuacao === AreaAtuacao.Humana) {
            this.isFranquiaHumana = true;
            if (this.modelId == 0) {
                this.model.pacienteCliente = true;
            }
        }

        this.somenteConsulta = this.VALIDAR_PERMISSAO_SOMENTE_CONSULTA("clientes");
        this.cpfObrigatorio = this.GET_CONFIG_FRANQUIA(Configuracoes.CpfClienteObrigatorio)?.verdadeiro;
    }
}
