var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "crud crud-list" },
    [
      _c("div", { staticClass: "info-div" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v(
            _vm._s(_vm.$t("__.Components.parent.sngpc.list_vue_html.sngpc"))
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "info-panel" }, [
            _c("span", [_vm._v(_vm._s(_vm.total))]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("__.Components.parent.sngpc.list_vue_html.sngpc"))
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$t("__.Components.parent.sngpc.list_vue_html.gerados")
                )
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "grid-component",
        {
          ref: "gridcomponent",
          attrs: {
            columns: _vm.gridColumns,
            data: _vm.gridData,
            extraActions: _vm.extraActions,
            "page-index": _vm.pageIndex,
            "page-size": _vm.pageSize,
            "show-edit-item": false,
            "show-remove-item": false,
            showAddNewButton: false,
            startFilterKey: _vm.startFilterKey,
            tituloPagina: "SNGPC",
            total: _vm.total,
          },
          on: {
            "change-filter-key": _vm.onChangeFilterKey,
            "change-page": _vm.onChangePage,
            "change-sort": _vm.onChangeSort,
            "edit-item": _vm.onEditItem,
            "extra-action": _vm.onExtraAction,
            "gerar-impressao": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 2
            },
            "gerar-pdf": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 1
            },
            "gerar-xls": function ($event) {
              _vm.pags = true
              _vm.tipoDocGerar = 0
            },
            "remove-item": _vm.onRemoveItem,
          },
        },
        [
          _c(
            "div",
            {
              attrs: { slot: "footer-extra-options" },
              slot: "footer-extra-options",
            },
            [
              _vm.total > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.onGerarNovo },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "__.Components.parent.sngpc.list_vue_html.gerarNovoRelatorio"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.onGerarInventario },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "__.Components.parent.sngpc.list_vue_html.gerarInventario"
                        )
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponent",
          attrs: {
            title: _vm.$t(
              "__.Components.parent.sngpc.list_vue_html.visuProdutos"
            ),
            showAddNew: false,
            showBtnOk: false,
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "component" }, slot: "component" },
            [
              _c("sNGPCProdutoListComponent", {
                ref: "sNGPCProdutoListComponent",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "shortcutComponent",
        {
          ref: "shortcutComponentSelecao",
          attrs: { modalClass: "ss-modal", showAddNew: false },
          on: { "selection-item": _vm.onConfirmSelecaoData },
        },
        [
          _c("div", { attrs: { slot: "component" }, slot: "component" }, [
            _c("form", { attrs: { novalidate: "novalidate" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("dataInicio"),
                          expression: "errors.first('dataInicio')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "dataInicio" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.sngpc.list_vue_html.dataInicio"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("date-time-picker-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: { id: "dataInicio", name: "dataInicio" },
                        model: {
                          value: _vm.dataInicio,
                          callback: function ($$v) {
                            _vm.dataInicio = $$v
                          },
                          expression: "dataInicio",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.errors.first("dataFinal"),
                          expression: "errors.first('dataFinal')",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "dataFinal" },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.$t(
                                  "__.Components.parent.sngpc.list_vue_html.dataFinal"
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("date-time-picker-component", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: { id: "dataFinal", name: "dataFinal" },
                        model: {
                          value: _vm.dataFinal,
                          callback: function ($$v) {
                            _vm.dataFinal = $$v
                          },
                          expression: "dataFinal",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }