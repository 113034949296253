import comboComponent from "@/components/child/form/combo.vue";
import textareaComponent from "@/components/child/form/textarea.vue";
import { Component } from "@/decorators";
import TipoTemplateWhatsApp from "@/models/enum/TiposTemplateWhatsApp";
import TemplateWhatsAppModel from "@/models/templateWhatsAppModel";
import TemplateWhatsAppService from "@/services/templateWhatsAppService";

import EditComponentBase from "../editComponentBase";

import TagsComponent from "./tags/tags";
import tagsComponent from "./tags/tags.vue";

@Component({
    components: {
        comboComponent,
        textareaComponent,
        tagsComponent,
    },
})
export default class TemplateWhatsAppEditComponent extends EditComponentBase<TemplateWhatsAppModel> {
    private tagsComponent: TagsComponent = null;

    tiposOptions: Array<Object> = [
        { text: "Envio Orçamento Contínuos", value: TipoTemplateWhatsApp.EnvioOrcamentoContinuos },
        { text: "Envio Manipulação Em produção", value: TipoTemplateWhatsApp.EnvioManipulacaoEmProducao },
        { text: "Envio Manipulação Em execução", value: TipoTemplateWhatsApp.EnvioManipulacaoEmExecucao },
        { text: "Envio Manipulação Saiu para Entrega", value: TipoTemplateWhatsApp.EnvioManipulacaoSaiuParaEntrega },
        { text: "Envio Manipulação Entregue", value: TipoTemplateWhatsApp.EnvioManipulacaoEntregue },
        { text: "Módulo Atendimento: Mensagem de saudação", value: TipoTemplateWhatsApp.MensagemSaudacao },
        { text: "Módulo Atendimento: Mensagem de ausência", value: TipoTemplateWhatsApp.MensagemAusencia },
    ];

    private async onTags() {
        this.tagsComponent = this.$refs.tagsComponent as TagsComponent;
        return this.tagsComponent.show();
    }

    private async beforeMount() {
        await this.setProps(new TemplateWhatsAppService(), "templateWhatsapp", new TemplateWhatsAppModel());
    }

    private onAdicionaCampo(label: string) {
        this.model.texto += ` {${label}}`;
    }
}
