import BaseModel from "./base/baseModel";
import ControleReclamacaoTipo from "./enum/controleReclamacaoTipo";

export default class ControleReclamacaoModel extends BaseModel {
    id?: number = null;
    clienteId: number = null;
    vendaId?: number = null;
    vendaCodigo: number = null;
    responsavel: string = null;
    reclamacao: string = null;
    acao: string = null;
    clienteNome: string = null;
    atendente: string = null;
    status: string = null;
    dataReclamacao: Date = null;
    statusDescricao: string = null;
    setorDescricao: string = null;
    categoriaControleReclamacaoId: number = null;
    categoriaControleReclamacaoDescricao: string = null;
    tipo: ControleReclamacaoTipo = ControleReclamacaoTipo.Venda;
    produtoLoteId?: number = null;
    produtoLoteDescricao: string = null;
    quantidade?: number = null;
    produtoId?: number = null;
    justificativaControleReclamacaoId: number = null;
    justificativaControleReclamacaoDescricao: string = null;
}
